import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Card,
  Divider,
  Table,
  TableBody,
  Container,
  TableContainer,
} from '@mui/material';
import cn from 'classnames';

import SearchBar from 'components/PsychicNotifications/ListingView/SearchBar/SearchBar';
import TableNoData from 'components/Rows/EmptyRow/TableNoData';
import CustomTag from 'components/Tags/CustomTag/CustomTag';
import ListTabs from 'components/PsychicNotifications/ListingView/ListComponents/ListTabs';
import ListFooter from 'components/PsychicNotifications/ListingView/ListComponents/ListFooter';
import { IListingView } from 'types/componentTypes';
import { PNTabs } from 'src/constants/enums';
// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
// import { selectCurrentUser } from 'selectors/selectors';
import CustomTableHead from 'components/Table/TableHeader/CustomTableHead';
import { useIsMobile } from 'src/hooks/useIsMobile';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';
import { IS_LOAD_MORE_BOTTON_CLICKED_KEY } from 'constants/constants';
import CustomScollBar from 'components/CustomScollBar';

const TABS = [
  { name: 'All Notes', id: PNTabs.ALL_NOTES_TAB },
  { name: 'Priority Actions', id: PNTabs.PRIORITY_ACTION_TAB },
  { name: 'Customer List', id: PNTabs.CUSTOMER_LIST_TAB },
];

const ListingView: React.FC<IListingView> = ({
  currentTab,
  onChangeTabs,
  isNotificationTab,
  showMuted,
  onChangeMuted,
  tableHead,
  tableBody,
  listingData,
  totalRowsCount,
  filterCustomerName,
  searchTag,
  setSearchTag,
  handleFilterName,
  page,
  order,
  onSort,
  orderBy,
  selected,
  rowsPerPage,
  setPage,
  onChangePage,
  onSelectAllRowsHandler,
  onRemoveAllSelectedRows,
  setNotificationCustomerDetail,
  onChangeRowsPerPage,
  tableRowsCount,
}) => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const [isTagged, setTagged] = useState<boolean>(false);
  const [isShowMutedInfo, setShowMutedInfoBottom] = useState<boolean>(false);
  const isTableBody = !!totalRowsCount;
  const isMuteInfoShown = isShowMutedInfo && isTableBody;
  const { isMobile } = useIsMobile();
  const isPriorityActionTab = currentTab === PNTabs.PRIORITY_ACTION_TAB;
  const isMobileView = isMobile && (isNotificationTab || isPriorityActionTab);
  const isLoadMoreVisible = sessionStorage.getItem(IS_LOAD_MORE_BOTTON_CLICKED_KEY);
  const isCustomerListTab = !isPriorityActionTab && !isNotificationTab;

  const tagDetails = useMemo(() => ({
    displayname: listingData?.[0]?.displayname || '',
    zodiacSign: listingData[0]?.zodiacSign || '',
  }), [listingData]);

  const toggleMutedInfoHandler = useCallback(() => {
    setShowMutedInfoBottom(!isShowMutedInfo);
  }, [isShowMutedInfo]);

  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
      // const selectedTab = TABS.find((e) => e.id === newValue);

      // if (selectedTab) {
      //   addPsychicMixpanelEventButtonTapped({
      //     button_text: selectedTab.name.toLowerCase(),
      //     button_type: 'tab',
      //     screen_title: 'psychic notifications',
      //     platform: 'psychic desktop app',
      //     ...getPsychicNotificationInfoMixpanel(currentUser),
      //   });
      // }
      let defaultRosPerPage;

      if (newValue === PNTabs.PRIORITY_ACTION_TAB) {
        defaultRosPerPage = isLoadMoreVisible ? rowsPerPage : 5;
      } else {
        defaultRosPerPage = 25;
      }

      onChangeTabs(newValue);
      handleFilterName('');
      onChangeRowsPerPage(defaultRosPerPage);
      onRemoveAllSelectedRows(setNotificationCustomerDetail);
      setPage(0);
    }, [],
  );

  const handleTagDeleteButton = useCallback(() => {
    setSearchTag(false);
    handleFilterName('');
  }, []);

  return (
    <Container className={cn(styles.mainContainer, {
      [styles.mainContainerCustomer]: isCustomerListTab,
    })}
    >
      <div className={cn(styles.positionRelative, {
        [styles.emptyPriorityTab]: (isPriorityActionTab && !tableRowsCount),
      })}
      >
        <ListTabs
          TABS={TABS}
          currentTab={currentTab}
          handleTabsChange={handleTabsChange}
        />
        {!isMobile && <Divider />}
        <>
          <Card className={cn(styles.listContainer, {
            [styles.emptyListContainer]: (!isTableBody && !isPriorityActionTab),
          })}
          >
            <div className={styles.tableContainer}>
              <CustomTag
                isTagged={isTagged}
                onDelete={handleTagDeleteButton}
                tagDetails={tagDetails}
                customClass={styles.customTag}
              />
              <SearchBar
                isTagged={isTagged}
                setTagged={setTagged}
                searchTag={searchTag}
                filterName={filterCustomerName}
                onFilterName={handleFilterName}
                tagName={tagDetails.displayname}
                isMobileView={isMobile}
                isVisible={!isPriorityActionTab}
              />
              {isTableBody
                && (
                  <div className={cn({ [styles.tableBorder]: isMobile && isCustomerListTab })}>
                    <TableContainer
                      className={cn(styles.tableBody, {
                        [styles.tableBodyPriorityAction]: isMobile && isPriorityActionTab,
                      })}
                      ref={contentContainerRef}
                    >
                      <Table className={cn(styles.tableListing, {
                        [styles.tableListingBorder]: !isMobile && isPriorityActionTab,
                      })}
                      >
                        {!isMobileView && (
                          <CustomTableHead
                            order={order}
                            onSort={onSort}
                            orderBy={orderBy}
                            tableHead={tableHead}
                            rowCount={totalRowsCount}
                            numSelected={selected.length}
                            onSelectAllRowsHandler={onSelectAllRowsHandler}
                            isNotificationTab={isNotificationTab}
                            isPriorityActionTab={isPriorityActionTab}
                          />
                        )}
                        {(isCustomerListTab && isMobile)
                        && <CustomScollBar contentContainerRef={contentContainerRef} />}
                        <TableBody>
                          {
                            (listingData.length || isPriorityActionTab) ? tableBody
                              : <TableNoData />
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
            </div>
            <ListFooter
              page={page}
              count={listingData.length}
              rowsPerPage={rowsPerPage}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              toggleMutedInfoHandler={toggleMutedInfoHandler}
              showMuted={showMuted}
              onChangeMuted={onChangeMuted}
              isTableBody={isTableBody}
              isMuteInfoShown={isMuteInfoShown}
              isNotificationTab={isNotificationTab}
              isMobile={isMobile}
              currentTab={currentTab}
              tableRowsCount={tableRowsCount}
            />
          </Card>
        </>
      </div>
    </Container>
  );
};

export default ListingView;
