import React from 'react';
import cn from 'classnames';

import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import ClientNotesDraftButton from 'components/Buttons/ClientNotesDraftButton';
import BackButtonIcon from 'assets/svg/all-inbox-back-arrow.svg';
import styles from 'components/Buttons/SaveDraftButton/SaveDraftButton.module.scss';

const BackButton = ({
  title,
  onClick,
  handleDraftButton,
  isDraftButtonClicked,
  isClientNotes = false,
}) => (

  <div className={cn(styles.wrapper, {
    [styles.clientNotesWrapper]: isClientNotes,
  })}
  >
    <button
      type="button"
      onClick={onClick}
      id={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
      className={cn(isClientNotes ? styles.clientNotesBackButton : styles.saveAndDraftButton)}
    >
      <img src={BackButtonIcon} alt="back" className={cn(isClientNotes ? styles.clientNotesBackButtonIcon : styles.backButtonIcon)} />
      <span className={styles.buttonTitle}>{title}</span>
    </button>
    {
      isClientNotes && (
        <ClientNotesDraftButton
          onClick={handleDraftButton}
          isClicked={isDraftButtonClicked}
        />
      )
    }
  </div>
);

export default BackButton;
