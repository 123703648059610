import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import InfoIcon from 'assets/svg/gamify-info-Icon.svg';
import GamifyInfoTooltip from 'components/Tooltips/GamifyInfoTooltip';
import StarIcon from 'assets/svg/star-Icon.svg';
import { LocalStorage } from 'src/utils/storageHandler';
import { IS_GAMIFY_ANIMATION_SEEN } from 'constants/constants';
import DonutProgressBar from 'components/PsychicNotifications/DonutPregressBar/DonutProgressBar';
import styles from 'components/PsychicNotifications/GamifyStats/GamifyStats.module.scss';

const ProgressBar = ({
  progressBarPercentage,
  innerTextLine1,
  innerTextLine2,
  engagementGoal,
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const {
    goalPercentage,
    clientPool,
    psychicGoal,
    psychicSentCount,
  } = engagementGoal;
  const isAnimationSeen = LocalStorage.getItem(IS_GAMIFY_ANIMATION_SEEN);
  const isGoalCompleted = (progressBarPercentage >= 100) && !isAnimationSeen;
  const psychicNoteSendCount = psychicSentCount <= psychicGoal ? psychicSentCount : psychicGoal;

  useEffect(() => {
    if (progressBarPercentage >= 100) {
      LocalStorage.setItem(IS_GAMIFY_ANIMATION_SEEN, 'seen');
    } else if (isAnimationSeen) {
      LocalStorage.removeItem(IS_GAMIFY_ANIMATION_SEEN);
    }
  }, [isAnimationSeen, progressBarPercentage]);

  const handleIsToolTipOpen = () => {
    setTooltipOpen(!isTooltipOpen);
  };

  const handleCloseToolTip = () => {
    setTooltipOpen(false);
  };

  const gradients = [
    [
      'rgb(97, 209, 181)',
      'rgb(202, 195, 91)',
      'rgb(249, 134, 51)',
    ],
    [
      'rgba(249, 134, 51, 0.2)',
      'rgba(202, 195, 91, 0.2)',
      'rgba(97, 209, 181, 0.2)',
    ],
  ];

  return (
    <div className={styles.doughnutContainer}>
      <DonutProgressBar
        progress={progressBarPercentage}
        gradients={gradients}
      />
      <div className={cn(
        styles.starIcon,
        { [styles.starAnimation]: isGoalCompleted },
        styles.progressBarWrapperWidth,
      )}
      >
        <img src={StarIcon} alt="start-icon" />
        <img src={StarIcon} alt="start-icon" />
      </div>
      <div className={cn(
        styles.chart,
        { [styles.chartAnimation]: isGoalCompleted },
      )}
      >
        <span>{innerTextLine1}</span>
        <span>{innerTextLine2}</span>
      </div>
      <div className={cn(styles.progressBarContainer, styles.progressBarWrapperWidth)}>
        <span className={styles.progressBarGoal}>
          {`${psychicNoteSendCount} sent of ${psychicGoal} send goal`}
          <button
            type="button"
            onClick={handleIsToolTipOpen}
            onBlur={handleCloseToolTip}
          >
            <img
              className={styles.infoTooltip}
              src={InfoIcon}
              alt="info"
            />
          </button>
          <GamifyInfoTooltip
            isOpen={isTooltipOpen}
            clientPool={clientPool}
            goalPercentage={goalPercentage}
          />
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
