import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { selectPriorityActionMessages } from 'selectors/psychicNotificationsSelectors';
import { simplyfyJsonArrayKeys } from 'src/utils/commonUtil';

import styles from './PriorityActionNotification.module.scss';

const TooltipContent = ({ className = '' }) => {
  const priorityActionMessages = useSelector(selectPriorityActionMessages);
  const {
    priorityClientsActionCommonTooltipTitleText,
    priorityClientsActionCommonTooltipDescriptionText,
  } = simplyfyJsonArrayKeys(priorityActionMessages);

  return (
    <div className={cn(styles.tooltipWrapper, className)}>
      <span
        className={styles.tooltipHeading}
      >{priorityClientsActionCommonTooltipTitleText}
      </span>
      <span className={styles.tooltipDescription}>
        {priorityClientsActionCommonTooltipDescriptionText}
      </span>
    </div>
  );
};

export default TooltipContent;
