import { OPEN_DIAGNOSTIC_PAGE, SET_CONSOLE_ERROR_LOGS } from 'actions/actionsTypes';
import { ReducerAction, DiagnosticState } from 'types/objectTypes';

const initialState: DiagnosticState = {
  isPopupOpen: false,
  consoleErrorLogs: [],
};

const diagnosticReducer = (
  state: DiagnosticState = initialState,
  { type, payload }: ReducerAction,
) => {
  switch (type) {
    case OPEN_DIAGNOSTIC_PAGE: {
      return { ...state, isPopupOpen: payload };
    }
    case SET_CONSOLE_ERROR_LOGS: {
      return { ...state, consoleErrorLogs: payload };
    }
    default:
      return state;
  }
};

export default diagnosticReducer;
