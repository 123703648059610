import React, { useState } from 'react';
import {
  Button, Card, Collapse,
} from '@mui/material';
import cn from 'classnames';

import { ReactComponent as SendIcon } from 'assets/svg/gamify-send-Icon.svg';
import MobileSendIcon from 'assets/png/mobileSendIcon.png';
import ComposeNotificationButton from 'components/Buttons/ComposeNotificationButton';
import NotificationStatistics from 'components/PsychicNotifications/StatsView/NotificationStatistics';
import NotificationStatsMobile from 'components/PsychicNotifications/StatsView/NotificationStatsMobile';
import styles from 'components/PsychicNotifications/PsychicNotifications.module.scss';

const StatsView = ({
  notificationStatistics,
  sendCustomerDetail,
  handlePsychicNoteScreens,
  isMobile,
  isGamifyEnabled,
}) => {
  const [isOpen, setisOpen] = useState(false);
  const handleClick = () => {
    setisOpen(!isOpen);
  };

  const { engagementGoal } = notificationStatistics;

  return (
    <>
      <Card className={cn(styles.cardOverflow, styles.collapseRoot, {
        [styles.statsGamify]: isGamifyEnabled,
      })}
      >
        <Button
          onClick={handleClick}
          className={styles.collapseRootHead}
        >
          <span className={styles.statsLabel}>
            <span>Stats</span>
            { (isGamifyEnabled && !isOpen) ? (
              <div className={styles.widthFull}>
                <div className={styles.statsGamifyWrapper}>
                  <span className={styles.statsGamifyLable}>
                    {`${engagementGoal.psychicSentCount}/${engagementGoal.psychicGoal} Notes`}
                  </span>
                  {!isMobile && <SendIcon />}
                  {isMobile && <img src={MobileSendIcon} alt="Send Icon" className={styles.statsNotesArrow} />}
                </div>
              </div>
            )
              : (isGamifyEnabled && isMobile) && (
                <NotificationStatsMobile
                  totalNotifications={notificationStatistics.totalNotificationSent}
                  reactionPercentage={notificationStatistics.reactionPercentage}
                />
              )}
            {
              isOpen
                ? <span className={styles.minusStyle}>_</span>
                : <span className={styles.plusStyle}>+</span>
            }
          </span>
        </Button>
        <Collapse in={isOpen}>
          <NotificationStatistics
            reactionsList={notificationStatistics.customerReactionList}
            reactionPercentage={notificationStatistics.reactionPercentage}
            totalNotifications={notificationStatistics.totalNotificationSent}
            engagementGoal={notificationStatistics.engagementGoal}
            isMobile={isMobile}
            isGamifyEnabled={isGamifyEnabled}
            isOpen={isOpen}
          />
        </Collapse>
      </Card>
      {(!isMobile && !isGamifyEnabled) && (
        <ComposeNotificationButton
          sendCustomerDetail={sendCustomerDetail}
          onClick={handlePsychicNoteScreens}
        />
      )}
    </>
  );
};

export default StatsView;
