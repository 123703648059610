const worker = () => {
  /* eslint-disable-next-line no-restricted-globals */
  const ctx: Worker = self as any;
  let timerId = 0;

  enum InternetCheckerMessage {
    CONNECTION_ALIVE = 'connectionAlive',
    START_INTERVAL = 'startCheckingInterval',
    CONNECTION_DOWN = 'connectionDown',
    REQUEST_NOTIFICATIONS = 'requestNotifications',
    NOTIFICATION_OUT_OF_DATE = 'notificationOutOfDate',
    CLEAR_INTERVAL = 'clearInterval',
  }

  const styles = [
    'color: #ba1b1d',
    'display: block',
    'text-align: center',
    'text-transform: uppercase',
    'font-size: 12px',
    'font-weight: bold',
  ].join(';');

  const checkInternetConnectionByRequest = (url: string) => {
    fetch(url, { cache: 'no-store' })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          ctx.postMessage({ type: InternetCheckerMessage.CONNECTION_ALIVE });
          ctx.postMessage({ type: InternetCheckerMessage.REQUEST_NOTIFICATIONS });
        }
      })
      .catch(() => {
        ctx.postMessage({ type: InternetCheckerMessage.CONNECTION_DOWN });
      });
  };

  ctx.onmessage = (event: any) => {
    const { type, payload } = event.data || {};

    switch (type) {
      case InternetCheckerMessage.START_INTERVAL: {
        const intervalForInternetConnection = payload.interval;
        const { url } = payload;
        const interval = typeof (intervalForInternetConnection) === 'number'
          ? intervalForInternetConnection
          : 10000;

        timerId = setInterval(checkInternetConnectionByRequest.bind(null, url), interval);

        break;
      }
      case InternetCheckerMessage.NOTIFICATION_OUT_OF_DATE: {
        console.log('%c NOTIFICATIONS ARE OUT OF DATE, TWILIO CLIENT RENEWAL', styles);

        break;
      }
      case InternetCheckerMessage.CLEAR_INTERVAL: {
        clearInterval(timerId);

        break;
      }
      default: break;
    }
  };
};

let code = worker.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });

export default URL.createObjectURL(blob);
