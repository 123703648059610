import React from 'react';
import cn from 'classnames';

import loader from 'assets/gif/loaderWithoutBG.gif';
import { SelectPsychicFormProps } from 'types/componentTypes';
import { PSYCHICS_OPTIONS } from 'constants/constants';

import styles from './SelectPsychicForm.module.scss';

const SelectPsychicForm: React.FC<SelectPsychicFormProps> = ({
  handleSubmitButton,
  token,
  isError,
  handleSelect,
  handleRadioButton,
  isDisabled,
  inputValue,
  isSelect,
  isLoading,
  handleInput,
  handleHistoryButton,
}) => {
  const btnSelect = cn(styles.btnSelect, !isDisabled ? styles.btnActive : styles.btnDisabled);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.checkBoxContainer}>
          <input
            id="selectPsychic"
            name="psychic"
            type="radio"
            defaultChecked
            className={styles.radioButton}
            onChange={handleRadioButton}
          />
          <select
            disabled={!isSelect}
            defaultValue={PSYCHICS_OPTIONS[0].id}
            onChange={handleSelect}
            className={styles.inputPsychicId}
            placeholder="psychic ext"
          >
            {PSYCHICS_OPTIONS.map((psychic) => (
              <option
                key={psychic.id}
                value={psychic.id}
              >
                {`${psychic.name} ${psychic.id}`}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.checkBoxContainer}>
          <input
            id="inputPsychic"
            className={styles.radioButton}
            name="psychic"
            type="radio"
            onChange={handleRadioButton}
          />
          <input
            value={inputValue}
            disabled={isSelect}
            onChange={handleInput}
            placeholder="Enter the psychic id"
            className={cn(
              styles.inputPsychicId,
              styles.inputPsychic,
              isSelect && styles.inputDisabled,
            )}
          />
        </div>

        {isError && <p className={styles.alert}>This psychic does not exist</p>}

        <button
          type="button"
          disabled={isDisabled || !token}
          onClick={handleSubmitButton}
          className={btnSelect}
        >
          {isLoading ? <img alt="" src={loader} /> : 'Select'}
        </button>
        <button
          type="button"
          disabled={isDisabled || !token}
          // @ts-ignore
          onClick={handleHistoryButton}
          className={btnSelect}
        >
          See Reading
        </button>
      </div>
    </div>
  );
};

export default SelectPsychicForm;
