import {
  put,
  call,
  take,
  select,
  takeEvery,
  putResolve,
  fork,
  join,
  all,
  takeLeading,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import {
  View,
  Status,
  ChannelEvents,
  ConversationType,
  NotificationsText,
  SdkEvents,
} from 'constants/enums';
import {
  DIFFERENCE_IN_TIME,
  OFFLINE_MESSAGE_BANNER,
  VIEW,
} from 'constants/constants';
import * as types from 'actions/actionsTypes';
import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';
import { descriptionFromError } from 'src/utils/commonUtil';
import {
  setIsLiveChatActive,
  setLoadingState,
  setIsSwitchedConversations,
  emitErrorNotification,
  setIsVisibleHeaderBanner,
  setHeaderBannerContent,
  handleRequestError,
  checkDiffInTime,
  setupNotifications,
  setErrorType,
} from 'actions/appActions';
import * as chatActions from 'actions/chatActions';
import {
  selectCustomerId,
  selectView,
  selectTwilioClient,
  selectCurrentChat,
  selectIsShownIncomingRequest,
  selectIsLiveChatActive,
  selectSystemChannel,
  selectIsLoading,
  selectOfflineChats,
  selectExtId,
  selectSdkCallbackStorage,
} from 'selectors/selectors';
import { ChatType } from 'types/objectTypes';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import { formatToUSMonthDay } from 'src/utils/dateHandler';
import { accumulateActivePsychicChats, filterChats } from 'src/utils/helpers';
import { unsubscribeFromChannelEvents } from 'src/redux/sagas/chat/commonSaga';
import { channelWatcher } from 'src/redux/sagas/chat/channels/chatChannelSaga';
import { systemChannelWatcher } from 'src/redux/sagas/chat/channels/systemChannelSaga';
import { handleAppointment } from 'src/redux/sagas/chat/appointmentSaga';
import { handleStartChatErrors, startChat } from 'src/redux/sagas/chat/createChatSaga';
import {
  loadMessagesWorker,
  loadExtraMessagesWorker,
  sendMessageRequest,
} from 'src/redux/sagas/chat/messagesSaga';
import {
  startChatByLinkForCustomer,
  startChatByLinkForPsychic,
} from 'src/redux/sagas/chat/createChatByLinkSaga';
import {
  receiveNewOfflineChatsWorker,
  handleReceivedOfflineChatsWorker,
} from 'src/redux/sagas/chat/handleReceivedOfflineChatsSaga';
import {
  acceptChatRequestWorker,
  handleCreateChatRequestWorker,
  setCurrentChatRequest,
  cleanAfterIgnoreChatRequestWorker,
  sendMessageLocalSaga,
  setActiveChatSaga,
  onEndChatRequestSaga,
} from 'src/redux/sagas/chat/chatRequestSaga';
import { restoreActiveChat } from 'src/redux/sagas/chat/restoreActiveChat';
import { setChatChannelAndLoadMessages } from 'actions/chatActions';

function* getPsychicRequest({
  payload: { psychicRefId, customerId },
}: ReturnType<typeof chatActions.currentPsychicRequest>) {
  try {
    yield put(setLoadingState(true));

    const response = yield call(
      API.Psychic.getPsychic,
      psychicRefId as string,
      customerId as string,
    );
    const currentDifferenceInTime = LocalStorage.getItem(DIFFERENCE_IN_TIME) || 0;

    yield put(checkDiffInTime(response?.data?.serverDateTime, currentDifferenceInTime));
    yield put(chatActions.setCurrentPsychic(response.data));
  } finally {
    yield put(setLoadingState(false));
  }
}

const clientEventListener = (client) => eventChannel((emit) => {
  client.on(ChannelEvents.CHANNEL_LEFT, (channel) => emit({
    type: ChannelEvents.CHANNEL_LEFT, channel,
  }));

  return () => { };
});

function* wakeApplication(): any {
  const view = yield select(selectView);
  const currentChat = yield select(selectCurrentChat);

  yield put(chatActions.checkIfMissedChat({ view, currentChat }));
}

function* clientWorker({
  payload,
}: ReturnType<typeof chatActions.installClientListeners>) {
  const clientActions = yield call(clientEventListener, payload);

  yield call(wakeApplication);

  while (true) {
    const { type } = yield take(clientActions);

    switch (type) {
      case ChannelEvents.CHANNEL_LEFT: break;
      default: break;
    }
  }
}

function* checkMissedChatWorker({ payload }: ReturnType<typeof chatActions.checkIfMissedChat>) {
  try {
    const isActive = yield select(selectIsLiveChatActive);
    const isLoading = yield select(selectIsLoading);
    const currentOfflineChats = yield select(selectOfflineChats);

    if (isActive || isLoading) return;

    const { view, currentChat } = payload;
    const isPsychicView = view === View.PSYCHIC;
    const { chatId } = currentChat;

    const userId = isPsychicView ? yield select(selectExtId) : yield select(selectCustomerId);
    const chatsReceived = isPsychicView
      ? yield call(API.Psychic.getPsychicActiveChats, userId)
      : yield call(API.Customer.getCustomerActiveChat, userId);

    const [onlineChats, offlineChats] = filterChats(
      chatsReceived.data.chats, (chat) => chat.type === ConversationType.LIVE_CHAT,
    );

    const isOfflineChatsLoaded = offlineChats.length === currentOfflineChats.length;
    const refreshCriteria = (!chatId && onlineChats.length) || !isOfflineChatsLoaded;

    if (refreshCriteria && isPsychicView) {
      yield all([
        putResolve(chatActions.handleReceivedActivePsychicChats(chatsReceived.data.chats)),
        take([types.ACTIVE_CHATS_LOADED, types.EMIT_ERROR_NOTIFICATION]),
      ]);
    }
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  }
}

function* joinChannelSaga({ payload }: ReturnType<typeof chatActions.joinChannelRequest>) {
  try {
    const { chatChannelSid } = payload;
    const client = yield select(selectTwilioClient);
    const channel = yield client.getConversationBySid(chatChannelSid);

    yield all([
      call(unsubscribeFromChannelEvents, channel),
      call(loadMessagesWorker, channel, ConversationType.LIVE_CHAT),
    ]);

    yield put(chatActions.configureChannelEvents(channel));
    yield put(chatActions.joinChannelSuccess(channel));
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(setIsLiveChatActive(false));
    yield put(handleRequestError(requestErrorPayload));
    yield put(handleRequestError());
    yield put(setErrorType(types.RESTORING_TWILIO_FAILED));
  }
}

function* joinAcceptedChatWorker({ payload }: ReturnType<typeof chatActions.joinAcceptedChat>) {
  const { chat } = payload;
  const client = yield select(selectTwilioClient);
  const customerIdFromChat = chat.customerRefIdEnc;
  const customerId = yield select(selectCustomerId);

  if (!customerId) {
    yield put(chatActions.setCustomerId(customerIdFromChat));
  }

  try {
    yield put(setLoadingState(true));
    const channel = yield call([client, client.getConversationBySid], chat.chatChannelSid);

    if (!(customerId || customerIdFromChat) && chat.type === ConversationType.LIVE_CHAT) return;

    const isActiveChatStatus = chat.status === Status.STARTED
      || chat.status === Status.ACCEPTED;

    if (isActiveChatStatus) {
      yield call(unsubscribeFromChannelEvents, channel);
      yield put(setIsVisibleHeaderBanner(false));
      yield put(chatActions.configureChannelEvents(channel));
      yield put(chatActions.joinChannelSuccess(channel));
      yield call(loadMessagesWorker, channel, ConversationType.LIVE_CHAT);

      goTo(`/conversations/${chat.chatId}?${VIEW}=${View.PSYCHIC.toLowerCase()}`);

      yield put(chatActions.joinAcceptedChatSuccess());
    }

    const isActiveOfflineChatStatus = chat.status === Status.PENDING
      && chat?.type === ConversationType.DIRECT_MESSAGE;

    if (isActiveOfflineChatStatus) {
      yield call(unsubscribeFromChannelEvents, channel);
      yield put(chatActions.configureChannelEvents(channel));
      yield put(chatActions.joinChannelSuccess(channel));
      yield call(loadMessagesWorker, channel, ConversationType.DIRECT_MESSAGE);
      goTo(`/conversations/${chat.chatId}?${VIEW}=${View.PSYCHIC.toLowerCase()}`);
      yield put(chatActions.joinAcceptedChatSuccess());
    }

    yield put(setIsSwitchedConversations(true));
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
    yield put(emitErrorNotification());
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleIsChatChannelMissing({ payload }: 
ReturnType<typeof setChatChannelAndLoadMessages>) {
  const client = yield select(selectTwilioClient);

  try {
    const channel = yield call([client, client.getConversationBySid], payload);
    yield call(unsubscribeFromChannelEvents, channel);
    yield put(setIsVisibleHeaderBanner(false));
    yield put(chatActions.configureChannelEvents(channel));
    yield put(chatActions.joinChannelSuccess(channel));
    yield call(loadMessagesWorker, channel, ConversationType.LIVE_CHAT);
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
  }
}

function* clearChatEventsWorker() {
  const currentChat: ChatType = yield select(selectCurrentChat);
  const systemChannel = yield select(selectSystemChannel);

  if (currentChat.currentChannel?.sid) {
    yield call(unsubscribeFromChannelEvents, currentChat.currentChannel);
  }

  if (systemChannel?.sid) {
    yield call(unsubscribeFromChannelEvents, systemChannel);
  }

  yield put(chatActions.clearChat());
}

function* setUpActiveCurrentChatIfExists(
  possibleCurrentChat: ChatType | undefined,
  liveChat: ChatType | undefined,
  offlineChat: ChatType | undefined,
) {
  if (possibleCurrentChat) {
    const currentChat = possibleCurrentChat;
    const sideUserTask = yield fork(API.Customer.getCustomerById, currentChat.customerRefIdEnc);

    if (liveChat) {
      yield put(setIsLiveChatActive(true));
      yield put(chatActions.setCustomerId(currentChat.customerRefIdEnc));
      currentChat.attributes = { requestType: currentChat.type };
    }

    const sideUser = yield join(sideUserTask);
    const user = sideUser.data;
    user.attributes = { participantType: View.PSYCHIC };
    user.friendlyName = user.firstName;

    if (offlineChat && !liveChat) {
      currentChat.attributes = {
        requestType: currentChat.type,
        isStarted: !!DraftedMessages.get(currentChat.chatId),
      };
      yield put(setIsVisibleHeaderBanner(true));
      yield put(setHeaderBannerContent({
        subtitle: `Offline message from ${user.friendlyName}, ${formatToUSMonthDay(user.userFormattedDOB)}, ${user.horoSign}`,
        description: NotificationsText.OFFLINE_MESSAGE_REMINDER,
        type: OFFLINE_MESSAGE_BANNER,
      }));
    }

    yield put(chatActions.setSideUser(user));
    yield put(chatActions.setCurrentChat(currentChat));
    yield put(chatActions.joinAcceptedChat(currentChat));
  } else {
    const view = yield select(selectView);
    goTo(`/conversations?${VIEW}=${view.toLowerCase()}`);
  }
}

function* setUpActiveOfflineChatsIfExist(
  currentChat: ChatType | undefined,
  offlines: Array<ChatType>,
) {
  if (offlines?.length > 0) {
    yield all([
      put(chatActions.handleReceivedOfflineChats(offlines)),
      take([types.OFFLINE_CHATS_LOADED, types.EMIT_ERROR_NOTIFICATION]),
    ]);
  } else if (currentChat) {
    yield take([types.JOIN_ACCEPTED_CHAT_SUCCESS, types.EMIT_ERROR_NOTIFICATION]);
  }
}

function* handleReceiveActivePsychicChatsWorker({
  payload: { chats, chatId },
}: ReturnType<typeof chatActions.handleReceivedActivePsychicChats>) {
  try {
    if (!chats) {
      yield put(emitErrorNotification());

      return;
    }

    const { live, offline, offlines } = accumulateActivePsychicChats(chats, chatId);
    const isActiveChatStatus: boolean = !!(live
      && (live.status === Status.STARTED || live.status === Status.ACCEPTED));

    const isShownIncomingRequest = yield select(selectIsShownIncomingRequest);
    const isAssignedChatStatus: boolean = live?.status === Status.ASSIGNED;

    if (isAssignedChatStatus && !isShownIncomingRequest) yield put(setupNotifications());

    const currentChat: ChatType | undefined = (live && isActiveChatStatus) ? live : offline;

    yield call(setUpActiveCurrentChatIfExists, currentChat, live, offline);
    yield call(setUpActiveOfflineChatsIfExist, currentChat, offlines);

    yield put(chatActions.notifyActiveChatsLoaded());
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(emitErrorNotification());
    yield put(handleRequestError(requestErrorPayload));
  }
}

function* muteChatRequestSaga() {
  const currentChat: ChatType = yield select(selectCurrentChat);
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
  const { chatId } = currentChat;
  const onMuteChatRequestCb = sdkCallbackStorage.get(SdkEvents.ON_MUTE_CHAT_REQUEST);
  try {
    if (!chatId) {
      return;
    }

    if (onMuteChatRequestCb) {
      yield call(onMuteChatRequestCb, {
        name: SdkEvents.ON_MUTE_CHAT_REQUEST,
        data: { chatId },
      });
    }

    yield call(API.Chat.muteChatRequest, chatId);
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);
  }
}

function* setAutoReloadSaga({ payload }: ReturnType<typeof chatActions.setAutoReload>) {
  try {
    const {
      customerRefIdEnc, autoReloadEnabled, location, sourcePlatform,
    } = payload;

    if (!customerRefIdEnc || autoReloadEnabled === undefined || !location || !sourcePlatform) {
      return;
    }

    const autoReloadResponse = yield call(
      API.Customer.autoReloadEnable,
      customerRefIdEnc,
      autoReloadEnabled,
      location,
      sourcePlatform,
    );

    if (autoReloadResponse?.data) {
      yield put(chatActions.setCustomerEnableAutoRecharge(autoReloadEnabled));
    }
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
  }
}

export function* chatSagas() {
  yield takeEvery(types.JOIN_CHANNEL_REQUEST, joinChannelSaga);
  yield takeEvery(types.CONFIGURE_CHANNEL_EVENTS, channelWatcher);
  yield takeEvery(types.CONFIGURE_SYSTEM_CHANNEL_EVENTS, systemChannelWatcher);
  yield takeEvery(types.CHAT_FETCH_REQUESTED, startChat);
  yield takeEvery(types.SEND_MESSAGE, sendMessageRequest);
  yield takeEvery(types.CLEAN_AFTER_IGNORE_CHAT_REQUEST, cleanAfterIgnoreChatRequestWorker);
  yield takeEvery(types.HANDLE_CREATE_CHAT_REQUEST, handleCreateChatRequestWorker);
  yield takeEvery(types.ACCEPT_CHAT_REQUEST, acceptChatRequestWorker);
  yield takeEvery(types.JOIN_ACCEPTED_CHAT, joinAcceptedChatWorker);
  yield takeEvery(types.INSTALL_CLIENT_LISTENERS, clientWorker);
  yield takeEvery(types.CURRENT_PSYCHIC_REQUEST, getPsychicRequest);
  yield takeEvery(types.LOAD_EXTRA_MESSAGES, loadExtraMessagesWorker);
  yield takeEvery(types.START_CHAT_BY_LINK, startChatByLinkForCustomer);
  yield takeEvery(types.SET_UP_PSYCHIC_BY_LINK, startChatByLinkForPsychic);
  yield takeEvery(types.SET_CHAT_BY_ID, setCurrentChatRequest);
  yield takeEvery(types.HANDLE_RECEIVED_OFFLINE_CHATS, handleReceivedOfflineChatsWorker);
  yield takeEvery(types.RECEIVE_NEW_OFFLINE_CHAT, receiveNewOfflineChatsWorker);
  yield takeEvery(types.CLEAR_CHAT_EVENTS, clearChatEventsWorker);
  yield takeEvery(types.CHECK_IF_CHAT_MISSED, checkMissedChatWorker);
  yield takeEvery(types.RESTORE_ACTIVE_CHAT, restoreActiveChat);
  yield takeEvery(types.SEND_MESSAGE_LOCAL, sendMessageLocalSaga);
  yield takeEvery(types.HANDLE_START_CHAT_ERROR, handleStartChatErrors);
  yield takeEvery(types.SET_ACTIVE_CHAT, setActiveChatSaga);
  yield takeEvery(types.END_CHAT_REQUEST, onEndChatRequestSaga);
  yield takeLeading(
    types.HANDLE_RECEIVED_ACTIVE_PSYCHIC_CHATS,
    handleReceiveActivePsychicChatsWorker,
  );
  yield takeLeading(types.SET_APPOINTMENT, handleAppointment);
  yield takeEvery(types.MUTE_CHIME_REQUEST, muteChatRequestSaga);
  yield takeEvery(types.SET_AUTO_RELOAD, setAutoReloadSaga);
  yield takeEvery(types.SET_CHAT_CHANNEL_AND_LOAD_MESSAGE, handleIsChatChannelMissing);
}
