import React from 'react';
import cn from 'classnames';
import Badge from '@mui/material/Badge';

import IndicatorButton from 'components/Buttons/IndicatorButton';

import style from './SideBarButton.module.scss';

const PsychicNoteNavigationButton = ({
  image,
  isActive,
  onChangePage,
  reactionUnreadCount,
  pageId,
  disabled,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={cn(style.sidebarTabButton,
      {
        [style.sidebarTabButtonActive]: isActive,
      })}
    id={pageId}
    onClick={onChangePage}
  >
    <Badge
      className={style.badge}
      badgeContent={reactionUnreadCount}
      overlap="rectangular"
    >
      <img src={image} alt="sidebar_button" />
    </Badge>
    <span>Client <br /> Hub</span>
    {reactionUnreadCount
      ? <IndicatorButton className={style.newLabel} /> : null}
  </button>
);

export default PsychicNoteNavigationButton;
