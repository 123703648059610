import React, { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';

import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
} from 'actions/psychicNotifications';
import { setExtId as _setExtId } from 'actions/chatActions';
import { checkPsychicBusyStatus } from 'src/utils/commonUtil';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { ClientHubStatus, MixpanelEvents } from 'constants/enums';
import MixpanelActions from 'src/utils/mixpanel';

import styles from './PsychicList.module.scss';

const PsychicListItem = ({
  psychicName,
  chatStatus,
  phoneStatus,
  icon,
  pcComId,
  psychic,
  handlePsychicNoteScreens,
}) => {
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setExtId = useBindDispatch(_setExtId);
  const status = useMemo(() => {
    if (checkPsychicBusyStatus(chatStatus) || checkPsychicBusyStatus(phoneStatus)) {
      return ClientHubStatus.Busy;
    }

    if (chatStatus === ClientHubStatus.AVAILABLE || phoneStatus === ClientHubStatus.AVAILABLE) {
      return ClientHubStatus.AVAILABLE;
    }

    return ClientHubStatus.OFFLINE;
  }, [chatStatus, phoneStatus]);

  const psychicInfoProps = {
    psychic_favorite: psychic.psychic.isFavorite ? 'y' : 'n',
    psychic_bio_video: !!psychic.psychic.psychicVideoURL,
    psychic_id: psychic.psychic.extId,
    psychic_rate: psychic.psychic.customerPrice,
    psychic_name: psychic.psychic.lineName,
    psychic_rating: psychic.psychic.overallScore,
    is_test_psychic: psychic.psychic.isTestPsychic,
  };

  const handleOnClick = (e) => {
    setExtId(psychic.extId);
    getClientNoteHistory(pcComId);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail([psychic]);
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'psychic name',
      button_type: 'send a note',
      screen_name: 'inbox',
      screen_title: 'inbox',
      ...psychicInfoProps,
    });
  };

  return (
    <button
      type="button"
      id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
      onClick={handleOnClick}
      className={styles.listWrapper}
    >
      <Avatar
        alt="Psychic"
        src={icon}
        className={styles.psychicImg}
      />
      <div className={styles.list}>
        <span className={styles.name}>{psychicName}</span>
        <span className={styles.status}>{status}</span>
      </div>
    </button>
  );
};

export default PsychicListItem;
