import React from 'react';
import {
  Card,
} from '@mui/material';

import { ReactComponent as StatIcon } from 'assets/svg/notification-graph.svg';
import styles from 'components/PsychicNotifications/StatsView/NotificationStatsMobile.module.scss';
import { INotificationStatsMobile } from 'types/componentTypes';

const NotificationStatsMobile: React.FC<INotificationStatsMobile> = ({
  reactionPercentage,
  totalNotifications,
}) => (
  <>
    <Card className={styles.notification}>
      <span className={styles.notificationSent}>Notes Sent</span>
      <div className={styles.notificationRoot}>
        <div className={styles.notificationPercentageIcon}>
          <StatIcon className={styles.icon} />
          <span className={styles.notificationPercentage}>
            {reactionPercentage}
          </span>
        </div>
        <span className={styles.notificationAmount}>
          {totalNotifications}
        </span>
      </div>
    </Card>
  </>
);

export default NotificationStatsMobile;
