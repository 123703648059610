import React from 'react';
import cn from 'classnames';

import styles from 'components/Buttons/MessageTemplateButton/MessageTemplateButton.module.scss';

const MessageTemplateButton = ({ onClick, templateMessage, isSelected }) => (
  <button
    type="button"
    className={cn(styles.suggestNotification, {
      [styles.suggestNotificationSelected]: isSelected,
    })}
    onClick={onClick}
  >
    {templateMessage}
  </button>
);

export default MessageTemplateButton;
