import React from 'react';

import styles from './HistoryTips.module.scss';

const HistoryTipsBanner = ({ label, isVisible }) => isVisible && (
  <div className={styles.wrapper}>
    <div className={styles.banner}>
      {label}
    </div>
  </div>
);

export default HistoryTipsBanner;
