import React, { useEffect, useRef } from 'react';
import {
  Box, Typography, styled,
} from '@mui/material';
import cn from 'classnames';

import { View } from 'src/constants/enums';
import { reactionsMap } from 'src/utils/reactionImagesUtils';
import { formatDateForClientsNote, formatDateForClientsNoteForCustomer } from 'src/utils/dateHandler';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { tapAndHold } from 'src/hooks/useTapAndHold';
import ReactionTooltip from 'components/ClientHubWeb/ReactionTooltip';

import psychicStyle from './ClientNotes.module.scss';
import customerStyle from './CustomerNotes.module.scss';

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const NoteMessage = ({
  message,
  view,
  customerNotesCount,
  clientSendNoteLimit,
  handleOnSelect = (a = '', b = false) => a || b,
  isSelected = false,
  handleOnReact = (e) => e,
  isPsychicView,
}) => {
  const styles = isPsychicView ? psychicStyle : customerStyle;

  const { isMobile } = useIsMobile();
  const isMe = message.sendBy === view;
  const { reactionType, createdDateUTC, isDeleted } = message;
  const messageCreatedDate = isPsychicView
    ? formatDateForClientsNote(createdDateUTC)
    : formatDateForClientsNoteForCustomer(createdDateUTC);
  const messageRef = useRef<HTMLDivElement>(null);

  const reactionIcon = reactionType && reactionsMap.get(reactionType.toLocaleLowerCase());
  const reactionAvatar = (
    <img
      className={styles.reactionStyle}
      alt={reactionType}
      src={reactionIcon}
    />
  );

  const isRepliedCount = !!customerNotesCount && message.sendBy === View.CUSTOMER;
  const messageWrapperClasses = cn(
    styles.messageWrapper, {
      [styles.withReplyCount]: isRepliedCount,
      [styles.selectedNote]: isSelected,
      [styles.selectedNoteMe]: isSelected && isMe,
      [styles.selectedNoteOther]: isSelected && !isMe,
    },
  );
  const isReliedCountWithSmallMsg = isRepliedCount
  && (message && message.messageBody.length <= 10);
  const replyCountClasses = cn(
    styles.replyCount, {
      [styles.replyNoteCount]: isMobile,
      [styles.smallMessageReplyCount]: isReliedCountWithSmallMsg,
      [styles.replyNoteCountCustomer]: !isPsychicView,
    },
  );

  const onSelect = () => {
    handleOnSelect(message);
  };

  useEffect(() => {
    const isActive = (!isPsychicView && !isDeleted);
    const removeListenerUp = tapAndHold(messageRef.current, onSelect, 1000, isActive);

    return () => {
      removeListenerUp();
    };
  }, []);

  const handleOnblur = () => {
    handleOnSelect(message, true);
  };

  return (
    <div className={messageWrapperClasses}>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && { ml: 'auto' }),
        }}
        ref={messageRef}
        onBlur={handleOnblur}
      >
        <div className={styles.rootMessageStyle}>
          {!isDeleted && (reactionType && isMe) && reactionAvatar}
          <div className={cn(isMe ? styles.bgColor1 : styles.bgColor2)}>
            <ContentStyle
              style={{
                ...(isMe && {
                  color: 'grey.800',
                  bgcolor: '#C8FACD',
                }),
              }}
            >
              <span className={isMe ? styles.messageTimeMe : styles.messageTimeCustomer}>
                {(isPsychicView && isMe) ? `Sent ${messageCreatedDate}` : messageCreatedDate}
              </span>

              <Typography
                className={cn(styles.messageBody, { [styles.deletedMessage]: isDeleted })}
                variant="body2"
              >
                {(!isPsychicView && isDeleted) ? 'Note deleted' : message.messageBody }
              </Typography>

              {
                isRepliedCount && !isSelected && (
                  <span className={replyCountClasses}>
                    {
                      (isMobile || view === View.CUSTOMER)
                        ? `Note ${customerNotesCount} of ${clientSendNoteLimit}`
                        : `${customerNotesCount} of ${clientSendNoteLimit} Replies`
                    }
                  </span>
                )
              }
            </ContentStyle>
          </div>
          {!isDeleted && (reactionType && !isMe) && reactionAvatar}
        </div>
      </Box>
      {isSelected && (
        <ReactionTooltip
          handleOnReact={handleOnReact}
          isMe={isMe}
          handleOnSelect={handleOnblur}
        />
      )}
    </div>
  );
};

export default NoteMessage;
