/* eslint-disable */
import React from 'react';
import icons from 'components/SvgIcon/icons';

const SvgIcon = ({
  color = '',
  className = '',
  id = '',
  ...props
}) => {
  const {
    viewBox,
    width,
    height,
    fill,
    content,
  } = icons[id] || {};

  const settings = {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox,
    width,
    height,
    fill: color || fill || '#000',
    className,
  };

  return (
    <svg {...settings} {...props}>
      {content(color)}
    </svg>
  );
}

export default SvgIcon;
