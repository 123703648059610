import React from 'react';
import cn from 'classnames';

import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import { ICustomTag } from 'types/componentTypes';
import styles from 'components/Tags/CustomTag/CustomTag.module.scss';
import SvgIcon from 'components/SvgIcon';

const CustomTag: React.FC<ICustomTag> = (
  {
    isTagged,
    tagDetails,
    onDelete,
    customClass,
    index,
  },
) => {
  if (!isTagged || !tagDetails) {
    return null;
  }

  const { displayname, zodiacSign } = tagDetails;

  return (
    <div className={cn(styles.tag, customClass)}>
      <div className={styles.wrapper}>
        <img
          src={zodiacMap.get(zodiacSign.toLocaleLowerCase())}
          alt="img"
        />
        <span className={styles.tagName}>
          {displayname}
        </span>
        <SvgIcon id="clearIcon" onClick={() => onDelete(index)} />
      </div>
    </div>
  );
};

export default CustomTag;
