import React from 'react';

const NotEnabledForChatTitle = (
  <p style={{ color: '#284B6E' }}>
    You are not enabled for chat. Please contact Advisor Services
    {' '}
    <b>pm@californiapsychics.com</b>
    {' '}
    or text
    {' '}
    <b>844-394-8561</b>
  </p>
);

export default NotEnabledForChatTitle;
