import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  HEADER_BANNER_TITLE_STORAGE,
  HEADER_BANNER_SUBTITLE_STORAGE,
  HEADER_BANNER_DESCRIPTION_STORAGE,
  HEADER_BANNER_TYPE_STORAGE,
  IS_VISIBLE_HEADER_BANNER_STORAGE,
} from 'extracted-chat-components/constants';
import { SdkEvents } from 'extracted-chat-components/enums';

import { AutoReloadLocation, AutoReloadSourcePlatform } from 'src/constants/enums';
import { IS_CHAT_END_DUE_TO_LOW_FUNDS } from 'constants/constants';
import {
  setIsVisibleHeaderBanner as _setIsVisibleHeaderBanner,
  setHeaderBannerContent as _setHeaderBannerContent,
  destroySessionSdk as _destroySessionSdk,
  setShowInitModal as _setShowInitModal,
} from 'actions/appActions';
import {
  setAutoReload as _setAutoReload,
  clearExtId as _clearExtId,
  deleteCurrentChat as _deleteCurrentChat,
  deleteCurrentPsychic as _deleteCurrentPsychic,
  deleteSystemChannel as _deleteSystemChannel,
  setEndChatDueToLowFundState as _setEndChatDueToLowFundState,
} from 'actions/chatActions';
import {
  selectCurrentUser,
  selectSideUser,
  selectSdkCallbackStorage,
  selectUserAgent,
  selectAutoReloadPromptsLabels,
} from 'selectors/selectors';
import { goTo } from 'route-history';
import { getPlatform, redirectUserToWebsiteOrNativeApp } from 'src/utils/commonUtil';
import { LocalStorage } from 'src/utils/storageHandler';
import useBindDispatch from 'src/hooks/useBindDispatch';

import EndChatDueToLowFundPopup from './EndChatDueToLowFundsPopup';

const EndChatDueToLowFundsPopupContainer = () => {
  const [isAutoReloadEnabled, setAutoReloadEnable] = useState<boolean>(false);
  const [autoReloadLabels, setAutoReloadLabels] = useState<any>(null);

  const customer = useSelector(selectCurrentUser);
  const psychic = useSelector(selectSideUser);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const autoReloadPromptsLabels = useSelector(selectAutoReloadPromptsLabels);
  const userAgent = useSelector(selectUserAgent);

  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const deleteCurrentChat = useBindDispatch(_deleteCurrentChat);
  const deleteCurrentPsychic = useBindDispatch(_deleteCurrentPsychic);
  const clearExtId = useBindDispatch(_clearExtId);
  const deleteSystemChannel = useBindDispatch(_deleteSystemChannel);
  const destroySessionSdk = useBindDispatch(_destroySessionSdk);
  const setIsVisibleHeaderBanner = useBindDispatch(_setIsVisibleHeaderBanner);
  const setHeaderBannerContent = useBindDispatch(_setHeaderBannerContent);
  const setAutoReload = useBindDispatch(_setAutoReload);
  const setEndChatDueToLowFundState = useBindDispatch(_setEndChatDueToLowFundState);

  useEffect(() => {
    if (!autoReloadLabels && Object.keys(autoReloadPromptsLabels).length) {
      setAutoReloadLabels(autoReloadPromptsLabels);
    }
  }, [autoReloadPromptsLabels]);

  const autoReloadClickHandler = () => {
    const payload = {
      customerRefIdEnc: customer.customerRefIdEnc,
      autoReloadEnabled: !isAutoReloadEnabled,
      location: AutoReloadLocation.LOW_FUND_CHAT_END,
      sourcePlatform: getPlatform(
        userAgent, 'auto_reload',
      ) as AutoReloadSourcePlatform,
    };
    setAutoReload(payload);

    setAutoReloadEnable(!isAutoReloadEnabled);
  };

  const onCloseHandler = useCallback(() => {
    setEndChatDueToLowFundState(false);
    LocalStorage.removeItem(IS_CHAT_END_DUE_TO_LOW_FUNDS);

    if (isAutoReloadEnabled) {
      const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);
      setIsVisibleHeaderBanner(false);
      setHeaderBannerContent(null);

      if (redirectUserToWebsiteOrNativeApp()) {
        return;
      }

      if (onLogoutCallback) {
        destroySessionSdk(true);
      } else {
        clearExtId();
        deleteCurrentPsychic();
        deleteCurrentChat();
        setShowInitModal(true);
        deleteSystemChannel();
        goTo('/psychics?view=customer');

        LocalStorage.removeItems([
          HEADER_BANNER_TITLE_STORAGE,
          HEADER_BANNER_SUBTITLE_STORAGE,
          HEADER_BANNER_DESCRIPTION_STORAGE,
          HEADER_BANNER_TYPE_STORAGE,
          IS_VISIBLE_HEADER_BANNER_STORAGE,
        ]);
      }
    }
  }, [isAutoReloadEnabled]);

  if (!autoReloadLabels) {
    return null;
  }

  return (
    <EndChatDueToLowFundPopup
      isOpen
      autoReloadLabels={autoReloadLabels}
      customerName={customer.friendlyName}
      psychicName={psychic.friendlyName}
      psychicImage={psychic.images[4]}
      isAutoReloadEnabled={isAutoReloadEnabled}
      autoReloadClickHandler={autoReloadClickHandler}
      onCloseHandler={onCloseHandler}
    />
  );
};

export default EndChatDueToLowFundsPopupContainer;
