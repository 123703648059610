import {
  all, call, put, putResolve,
} from 'redux-saga/effects';

import {
  handleRequestError,
  setActiveMobilePage,
  setIsPsychicNotificationsEnable,
  setLoadingState,
  setView,
} from 'actions/appActions';
import {
  setAuthToken,
  setBearerToken,
  setTwilioToken,
} from 'actions/authActions';
import { setCurrentUser, setCustomerId, setExtId } from 'actions/chatActions';
import {
  IS_MOBILE_INTEGRATION,
  TOKEN_TWILIO_STORAGE,
  TOKEN_BEARER,
  VIEW,
  MOBILE_PAGE_ID,
  TOKEN_AUTH_STORAGE,
} from 'constants/constants';
import { NotificationsText, PNTabs, View } from 'constants/enums';
import { callRetry, descriptionFromError } from 'src/utils/commonUtil';
import { LocalStorage } from 'src/utils/storageHandler';
import { SentryMethods } from 'src/utils/sentryMethods';
import { goTo } from 'route-history';
import {
  getPriorityActionList,
  setCurrentPCNTab,
  setPriorityActionMessage,
  setPsychicNoteConfigurableData,
  setPsychicNotificationsActive,
  setUpPsychicNotesByLinkForCustomer,
  setUpPsychicNotesByLinkForPsychic,
} from 'actions/psychicNotifications';
import { API } from 'src/utils/api';

import { handleSetSessionByLinkError } from '../chat/createChatByLinkSaga';
import { setUser } from '../app/setUserSaga';

export function* handleSetUpPsychicNotesByLink({
  payload: {
    view,
    token,
    psychicId,
    tokenBearer,
  },
}: ReturnType<typeof setUpPsychicNotesByLinkForPsychic>) {
  try {
    LocalStorage.setItem(IS_MOBILE_INTEGRATION, 'true');

    if (!view || !token) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    if (!psychicId) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    yield put(setLoadingState(true));
    yield put(setActiveMobilePage(MOBILE_PAGE_ID.EMPTY_PAGE));
    const twilioResponse = yield call(callRetry, API.Chat.getTwilioToken);
    const twilioToken = twilioResponse?.data?.twilioToken;
    yield put(setAuthToken(token as string));
    yield put(setBearerToken(tokenBearer));
    LocalStorage.setItem(TOKEN_BEARER, tokenBearer);
    yield put(setTwilioToken(twilioToken));
    LocalStorage.setItem(TOKEN_TWILIO_STORAGE, twilioToken);
    yield put(setExtId(psychicId as string));
    yield put(setView(view));
    const [version, user] = yield all([
      call(API.App.getVersionDetail),
      call(API.Psychic.getPsychic, psychicId),
    ]);

    const {
      clientLastNoteLockedCycle,
      clientSendNoteLimit,
      psychicMaxNotificationsSend,
      psychicNotesSystemMessages,
      psychicNotificationRefreshEveryMins,
      psychicNotificationsEnabled,
      psychicGamifyFeatureEnabled,
      priorityClientsActionMessages,
    } = version;

    yield all([
      putResolve(setIsPsychicNotificationsEnable(psychicNotificationsEnabled)),
      putResolve(setPsychicNotificationsActive(user.data.isClientNotificationsEnabled)),
      putResolve(setPriorityActionMessage(JSON.parse(priorityClientsActionMessages))),
      putResolve(setPsychicNoteConfigurableData({
        clientLastNoteLockedCycle,
        clientSendNoteLimit,
        psychicMaxNotificationsSend,
        psychicGamifyFeatureEnabled,
        psychicNotificationRefreshEveryMins,
        psychicNotesSystemMessages: JSON.parse(psychicNotesSystemMessages),
      })),
    ]);

    yield put(setCurrentUser(user.data));
    yield call(getPriorityActionList, true);
    yield put(setActiveMobilePage(MOBILE_PAGE_ID.PSYCHIC_INBOX));
    yield put(setCurrentPCNTab(PNTabs.PRIORITY_ACTION_TAB));
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '/login',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    const isSetSessionError = yield call(handleSetSessionByLinkError, e?.message);

    if (isSetSessionError) {
      return;
    }

    yield put(handleRequestError(requestErrorPayload));
    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
    goTo(`/conversations?${VIEW}=${view.toLowerCase()}`);
  }
}

export function* handleSetUpPsychicNotesByLinkForCustomer({
  payload: {
    view,
    token,
    customerRefId,
    tokenBearer,
  },
}: ReturnType<typeof setUpPsychicNotesByLinkForCustomer>) {
  try {
    if (!view || !token) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    if (!customerRefId) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    LocalStorage.setItem(TOKEN_BEARER, tokenBearer);
    LocalStorage.setItem(TOKEN_AUTH_STORAGE, token);

    yield put(setLoadingState(true));
    yield put(setAuthToken(token as string));
    yield put(setBearerToken(tokenBearer));
    yield put(setCustomerId(customerRefId as string));
    yield put(setView(view));

    const [version, user] = yield all([
      call(API.App.getVersionDetail),
      call(API.Customer.getCustomerById, customerRefId),
    ]);

    const {
      clientLastNoteLockedCycle,
      clientSendNoteLimit,
      psychicMaxNotificationsSend,
      psychicNotesSystemMessages,
      psychicNotificationRefreshEveryMins,
      psychicNotificationsEnabled,
      psychicGamifyFeatureEnabled,
    } = version;

    yield all([
      putResolve(setIsPsychicNotificationsEnable(psychicNotificationsEnabled)),
      putResolve(setPsychicNoteConfigurableData({
        clientLastNoteLockedCycle,
        clientSendNoteLimit,
        psychicMaxNotificationsSend,
        psychicGamifyFeatureEnabled,
        psychicNotificationRefreshEveryMins,
        psychicNotesSystemMessages: JSON.parse(psychicNotesSystemMessages),
      })),
    ]);
    yield call(setUser, user, View.CUSTOMER);
  } catch (e) {
    console.log(e);

    const isSetSessionError = yield call(handleSetSessionByLinkError, e?.message);

    if (isSetSessionError) {
      return;
    }

    const isInvalidToken = e?.response?.status === 401 || e?.response?.status === 403;
    yield call(SentryMethods.captureException, e);
    const isPsychicView = view === View.PSYCHIC;
    const requestErrorPayload = {
      redirectPath: isPsychicView ? '/login' : '',
      isInvalidToken,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
    goTo('/clienthub?view=customer');
  }
}
