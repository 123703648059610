import React from 'react';
import Modal from 'react-modal';

import styles from 'components/Popups/ClientLabelsOverlay/ClientLabelsOverlay.module.scss';

const LabelErrorPopup = ({
  title,
  popupText,
  isNotificationPopUp,
  renderButtonContainer,
}) => (
  <Modal
    isOpen={isNotificationPopUp}
    className={styles.modal}
    ariaHideApp={false}
    overlayClassName={styles.overlay}
  >
    <div className={styles.errorWrapper}>
      <div className={styles.errorTitle}>{title}</div>
      <div className={styles.errorText}>{popupText}</div>
      {renderButtonContainer()}
    </div>
  </Modal>
);

export default LabelErrorPopup;
