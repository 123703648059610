import {
  put,
  call,
  select,
  putResolve,
  fork,
  join,
  all,
} from 'redux-saga/effects';

import {
  View,
  SdkEvents,
  ConversationType,
} from 'constants/enums';
import { API } from 'src/utils/api';
import { descriptionFromError } from 'src/utils/commonUtil';
import {
  setLoadingState,
  emitErrorNotification,
  handleRequestError,
} from 'actions/appActions';
import {
  handleReceivedOfflineChats,
  setOfflineChats,
  configureChannelEvents,
  notifyOfflineChatsLoaded,
  receiveNewOfflineChat,
  addOfflineChat,
} from 'actions/chatActions';
import {
  selectTwilioClient,
  selectCurrentChat,
  selectSdkCallbackStorage,
  selectOfflineChats,
  selectIsLiveChatActive,
  selectChatId,
} from 'selectors/selectors';
import { ChatType } from 'types/objectTypes';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import { loadMessagesWorker } from 'src/redux/sagas/chat/messagesSaga';
import { unsubscribeFromChannelEvents } from 'src/redux/sagas/chat/commonSaga';
import { SentryMethods } from 'src/utils/sentryMethods';

function* getUniqueItems(
  items: Array<any>,
  data: any,
  uniqueField: string,
  currentElement?: any,
) {
  const map = new Map();
  let wasSomeElementEqualToCurrentStoreElement = false;
  const forkedTasks: Array<any> = items.map((chat) => {
    const chatField = chat[uniqueField];
    const isElementWasBefore = chatField === map.get(chatField);

    if (isElementWasBefore) return '';

    const isElementEqualToCurrentStoreElement = currentElement
      && currentElement[uniqueField] === chatField && currentElement?.type === chat?.type;

    if (isElementEqualToCurrentStoreElement) {
      wasSomeElementEqualToCurrentStoreElement = true;

      return '';
    }

    map.set(chatField, chatField);
    const { fn } = data;

    return fork(fn, chatField);
  }).filter((chat) => chat !== '');
  const tasks = yield all(forkedTasks);
  const waitedTasks: Array<any> = yield join(tasks);

  if (wasSomeElementEqualToCurrentStoreElement && data.token) {
    waitedTasks.push({ data: currentElement });
  }

  if (wasSomeElementEqualToCurrentStoreElement && !data.token) {
    waitedTasks.push(currentElement.currentChannel);
  }

  return waitedTasks;
}

const buildOfflineChats = (
  offlineChats: Array<ChatType>,
  waitedClientTasks: any,
  waitedChannelTasks: any,
) => offlineChats.map((chat) => {
  const newChat: ChatType = chat;
  const customer = waitedClientTasks
    .find((customer) => chat.customerRefIdEnc === customer.data.customerRefIdEnc)?.data;
  const channel = waitedChannelTasks
    .find((channel) => chat.chatChannelSid === channel.sid);

  customer.attributes = { participantType: View.CUSTOMER };
  customer.friendlyName = customer.firstName;
  customer.identity = customer.customerRefIdEnc;
  newChat.attributes = {
    requestType: ConversationType.DIRECT_MESSAGE,
    participantType: View.PSYCHIC,
    isStarted: !!DraftedMessages.get(chat.chatId),
    isClosed: false,
  };
  newChat.currentChannel = channel;
  newChat.sideUser = customer;

  return newChat;
});

function* removeRedundantLastMessages(ids: Array<string>) {
  const isLiveChatActive = yield select(selectIsLiveChatActive);

  if (isLiveChatActive) {
    const currentChatId: string = yield select(selectChatId);
    ids.push(currentChatId);
  }

  const draftedMessages: object = DraftedMessages.getAll();

  if (!draftedMessages) return;

  const messages = Object.entries(draftedMessages);
  messages.forEach((map) => {
    const id = ids.find((id) => id === map[0]);

    if (!id) DraftedMessages.remove(map[0]);
  });
}

export function* handleReceivedOfflineChatsWorker({
  payload,
}: ReturnType<typeof handleReceivedOfflineChats>) {
  try {
    const currentChat: ChatType = yield select(selectCurrentChat);
    const waitedClientTasks: Array<any> = yield getUniqueItems(
      payload,
      { fn: API.Customer.getCustomerById, token: 'token' },
      'customerRefIdEnc',
      currentChat.sideUser,
    );
    const client = yield select(selectTwilioClient);
    const waitedChannelTasks: Array<any> = yield getUniqueItems(
      payload,
      { fn: [client, client.getConversationBySid] },
      'chatChannelSid',
      currentChat,
    );

    const offlineChats: Array<any> = buildOfflineChats(
      payload,
      waitedClientTasks,
      waitedChannelTasks,
    );
    const cleanLastMessages = yield fork(
      removeRedundantLastMessages,
      offlineChats.flatMap((chat) => chat.chatId),
    );
    yield putResolve(setOfflineChats(offlineChats));
    const channelHandling = offlineChats.map((chat) => all([
      put(configureChannelEvents(chat.currentChannel, chat.chatId)),
      call(unsubscribeFromChannelEvents, chat.currentChannel),
      fork(
        loadMessagesWorker,
        chat.currentChannel,
        ConversationType.DIRECT_MESSAGE,
        chat.chatId,
      ),
    ]));
    yield* channelHandling.map(function* (data) {
      const newData = yield data;
      yield join([newData[2]]);
    });
    yield join(cleanLastMessages);

    yield put(notifyOfflineChatsLoaded());
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);
    yield put(emitErrorNotification());
  }
}

export function* receiveNewOfflineChatsWorker({
  payload,
}: ReturnType<typeof receiveNewOfflineChat>) {
  try {
    if (!payload) return;

    const currentOfflineChats = yield select(selectOfflineChats);

    if (currentOfflineChats.map((chat) => chat.chatId).includes(payload)) return;

    yield setLoadingState(true);
    const offlineChatData = yield call(API.Chat.getChatById, payload);
    const client = yield select(selectTwilioClient);
    const offlineChat: ChatType = offlineChatData?.data?.chat;
    const customerTask = yield fork(API.Customer.getCustomerById, offlineChat.customerRefIdEnc);
    const channelTask = yield fork(
      [client, client.getConversationBySid],
      offlineChat.chatChannelSid,
    );
    const tasks = yield all([customerTask, channelTask]);
    const [customerData, channelData] = yield join(tasks);
    const user = { ...customerData.data };
    user.attributes = { participantType: View.CUSTOMER };
    user.friendlyName = user.firstName;
    yield call(unsubscribeFromChannelEvents, channelData);
    offlineChat.attributes = {
      requestType: offlineChat.type,
      participantType: View.PSYCHIC,
      isStarted: !!DraftedMessages.get(offlineChat.chatId),
      isClosed: false,
    };
    offlineChat.sideUser = user;
    yield putResolve(addOfflineChat(offlineChat));

    const messages = yield call(
      loadMessagesWorker,
      channelData,
      ConversationType.DIRECT_MESSAGE,
      offlineChat.chatId,
    );
    yield put(configureChannelEvents(channelData, offlineChat.chatId));

    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
    const onOfflineMessageCallback = sdkCallbackStorage.get(SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED);
    const isSendMessageToSdk = Array.isArray(messages) && onOfflineMessageCallback;

    if (isSendMessageToSdk) {
      onOfflineMessageCallback({
        name: SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED,
        data: messages[messages.length - 1],
      });
    }
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(emitErrorNotification());
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield setLoadingState(false);
  }
}
