import * as types from 'actions/actionsTypes';
import { ReducerAction, SwitchToPhoneType } from 'types/objectTypes';

const initialState: SwitchToPhoneType = {
  isCustomerPhoneValidated: null,
  isCustomerPhoneSaved: false,
  isPhoneNumber: true,
  phoneNumbers: [],
  psychicAppointment: {
    tillStart: null,
    tillEnd: null,
  },
};

const switchToPhoneReducer = (
  state: SwitchToPhoneType = initialState,
  { type, payload }: ReducerAction,
): SwitchToPhoneType => {
  switch (type) {
    case types.SET_CUSTOMER_PHONE_VALIDATED: {
      return { ...state, isCustomerPhoneValidated: payload };
    }
    case types.SET_CUSTOMER_PHONE_SAVED: {
      return { ...state, isCustomerPhoneSaved: payload };
    }
    case types.SET_CUSTOMER_PHONE_NUMBERS: {
      return { ...state, phoneNumbers: payload };
    }
    case types.SET_CALLBACK_APPOINTMENT_INFO: {
      return { ...state, psychicAppointment: payload };
    }
    case types.SET_IS_PHONE_NUMBER: {
      return { ...state, isPhoneNumber: payload };
    }
    default:
      return state;
  }
};

export default switchToPhoneReducer;
