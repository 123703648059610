import { call, put } from 'redux-saga/effects';

import { TOKEN_TWILIO_STORAGE } from 'constants/constants';
import { checkDiffInTime, handleRequestError } from 'actions/appActions';
import { API } from 'src/utils/api';
import { setTwilioToken } from 'actions/authActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { SentryMethods } from 'src/utils/sentryMethods';
import { descriptionFromError, callRetry } from 'src/utils/commonUtil';

export function* handleTwilioToken() {
  try {
    const response = yield call(callRetry, API.Chat.getTwilioToken);
    const { twilioToken, serverDateTime } = response?.data || {};

    LocalStorage.setItem(TOKEN_TWILIO_STORAGE, twilioToken as string);

    yield put(checkDiffInTime(serverDateTime));
    yield put(setTwilioToken(twilioToken as string));

    return twilioToken;
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  }
}
