const MODULE_APP = 'APP';
const MODULE_CHAT = 'CHAT';
const MODULE_OFFLINE_CHAT = 'OFFLINE_CHAT';
const MODULE_AUTH = 'AUTH';
const MODULE_STATUS_TOGGLE = 'STATUS_TOGGLE';
const MODULE_SWITCH_TO_PHONE = 'SWITCH_TO_PHONE';
const DIAGNOSTIC_PAGE = 'DIAGNOSTIC';
const MODULE_PSYCHIC_NOTIFICATION = 'PSYCHIC_NOTIFICATION';
const REMINDER_OPTIONS = 'REMINDER_OPTIONS';

export const SET_COMMAND = `${MODULE_APP}/SET_COMMAND`;
export const SET_DATA_FROM_SDK = `${MODULE_APP}/SET_DATA_FROM_SDK`;
export const SET_BOOTED_DATA_FROM_SDK = `${MODULE_APP}/SET_BOOTED_DATA_FROM_SDK`;
export const SET_SDK_VISIBILITY_MODE = `${MODULE_APP}/SET_SDK_VISIBILITY_MODE`;
export const SET_CONVERSATION_SDK = `${MODULE_APP}/SET_CONVERSATION_SDK`;
export const SET_SESSION_SDK = `${MODULE_APP}/SET_SESSION_SDK`;
export const DESTROY_SESSION_SDK = `${MODULE_APP}/DESTROY_SESSION_SDK`;
export const CLEAR_DATA_FROM_SDK = `${MODULE_APP}/CLEAR_DATA_FROM_SDK`;
export const REMOVE_COMMAND = `${MODULE_APP}/REMOVE_COMMAND`;
export const SET_SWITCHED_CONVERSATION = `${MODULE_APP}/SET_SWITCHED_CONVERSATION`;
export const SET_VIEW = `${MODULE_APP}/SET_VIEW`;
export const SET_IS_LIVE_CHAT_ACTIVE = `${MODULE_APP}/SET_IS_LIVE_CHAT_ACTIVE`;
export const SET_IS_LIVE_CHAT_CANCELED = `${MODULE_APP}/SET_IS_LIVE_CHAT_CANCELED`;
export const SHOW_POPUP_NOTIFICATION = `${MODULE_APP}/SHOW_POPUP_NOTIFICATION`;
export const SHOW_INIT_CHAT_MODAL = `${MODULE_APP}/SHOW_INIT_CHAT_MODAL`;
export const SET_IS_SHOWN_INCOMING_REQUEST = `${MODULE_APP}/SET_IS_SHOWN_INCOMING_REQUEST`;
export const SET_IS_LOADING = `${MODULE_APP}/SET_IS_LOADING`;
export const SET_IS_BACKDROP = `${MODULE_APP}/SET_IS_BACKDROP`;
export const SET_USER_AGENT = `${MODULE_APP}/SET_USER_AGENT`;
export const SET_POPUP_NOTIFICATION_DATA = `${MODULE_APP}/SET_POPUP_NOTIFICATION_DATA`;
export const SET_IS_PHONE_READING = `${MODULE_APP}/SET_IS_PHONE_READING`;
export const RESTART_CHAT = `${MODULE_APP}/RESTART_CHAT`;
export const CLEAR_APP = `${MODULE_APP}/CLEAR_APP`;
export const HIDE_EMOJI_PICKER = `${MODULE_APP}/HIDE_EMOJI_PICKER`;
export const EMIT_ERROR_NOTIFICATION = `${MODULE_APP}/EMIT_ERROR_NOTIFICATION`;
export const SET_IS_VISIBLE_HEADER_BANNER = `${MODULE_APP}/SET_IS_VISIBLE_HEADER_BANNER`;
export const SET_IS_VISIBLE_REMINDER_POPUP = `${MODULE_APP}/SET_IS_VISIBLE_REMINDER_POPUP`;
export const SET_HEADER_BANNER_CONTENT = `${MODULE_APP}/SET_HEADER_BANNER_CONTENT`;
export const HANDLE_PSYCHIC_MISS_CHAT = `${MODULE_APP}/HANDLE_PSYCHIC_MISS_CHAT`;
export const ADD_POP_UP_NOTIFICATION = `${MODULE_APP}/ADD_POP_UP_NOTIFICATION`;
export const REMOVE_POP_UP_NOTIFICATION = `${MODULE_APP}/REMOVE_POP_UP_NOTIFICATION`;
export const ADD_SDK_CALLBACK = `${MODULE_APP}/ADD_SDK_CALLBACK`;
export const REMOVE_SDK_CALLBACK = `${MODULE_APP}/REMOVE_SDK_CALLBACK`;
export const REQUEST_ERROR = `${MODULE_APP}/REQUEST_ERROR`;
export const HANDLE_REQUEST_ERROR = `${MODULE_APP}/HANDLE_REQUEST_ERROR`;
export const CHECK_DIFF_IN_TIME = `${MODULE_APP}/CHECK_DIFF_IN_TIME`;
export const SET_REDIRECT_STATE = `${MODULE_APP}/SET_REDIRECT_STATE`;
export const SET_TWILIO_CONNECTING_STATUS = `${MODULE_APP}/SET_TWILIO_CONNECTING_STATUS`;
export const SET_INTERNET_CONNECTING_STATUS = `${MODULE_APP}/SET_INTERNET_CONNECTING_STATUS`;
export const SWITCH_TO_PHONE_SDK = `${MODULE_APP}/SWITCH_TO_PHONE_SDK`;
export const SET_IS_PENDING_CREATE_CHAT = `${MODULE_APP}/SET_IS_PENDING_CREATE_CHAT`;
export const RESTORE_TWILIO_CLIENT = `${MODULE_APP}/RESTORE_TWILIO_CLIENT`;
export const RESTORING_TWILIO_FAILED = `${MODULE_APP}/RESTORING_TWILIO_FAILED`;
export const SHOW_LOGOUT_PROMPT = `${MODULE_APP}/SHOW_LOGOUT_PROMPT`;
export const SET_INLINE_NOTIFICATION = `${MODULE_APP}/SET_INLINE_NOTIFICATION`;
export const SET_HISTORY_MODE_STATE = `${MODULE_APP}/SET_HISTORY_MODE_STATE`;
export const SET_PSYCHIC_NOTIFICATIONS_ENABLE = `${MODULE_APP}/SET_PSYCHIC_NOTIFICATIONS_ENABLE`;
export const SET_PSYCHIC_DECLINE_OM_ENABLE = `${MODULE_APP}/SET_PSYCHIC_DECLINE_OM_ENABLE`;
export const SET_ACTIVE_TAB = `${MODULE_APP}/SET_ACTIVE_TAB`;
export const SET_ACTIVE_PAGE = `${MODULE_APP}/SET_ACTIVE_PAGE`;
export const SET_COMPOSE_SCREEN_VISIBILITY = `${MODULE_APP}/SET_COMPOSE_SCREEN_VISIBILITY`;
export const SET_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT = `${MODULE_APP}/SET_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT`;
export const CHECK_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT = `${MODULE_APP}/CHECK_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT`;
export const SET_CONFIRMATION_SCREEN = `${MODULE_APP}/SET_CONFIRMATION_SCREEN`;
export const SET_IS_ELECTRON_APP = `${MODULE_APP}/SET_IS_ELECTRON_APP`;
export const SET_DASHBOARD_PSYCHIC_NEWS = `${MODULE_APP}/SET_DASHBOARD_PSYCHIC_NEWS`;
export const GET_DASHBOARD_PSYCHIC_NEWS = `${MODULE_APP}/GET_DASHBOARD_PSYCHIC_NEWS`;
export const SET_PSYCHIC_PHONE_STATUS = `${MODULE_APP}/SET_PSYCHIC_PHONE_STATUS`;

export const SET_CALLBACK_QUEUE = `${MODULE_APP}/SET_CALLBACK_QUEUE`;
export const SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE = `${MODULE_APP}/SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE`;
export const SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_EDIT_MODE = `${MODULE_APP}/SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_EDIT_MODE`;
export const SET_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_MSG = `${MODULE_APP}/SET_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_MSG`;
export const SET_PSYCHIC_LOGIN_OUTSIDE_LAST_ACTIVITY = `${MODULE_APP}/SET_PSYCHIC_LOGIN_OUTSIDE_LAST_ACTIVITY`;
export const SET_PSYCHIC_LOGIN_OUTSIDE_POPUP_SHOW = `${MODULE_APP}/SET_PSYCHIC_LOGIN_OUTSIDE_POPUP_SHOW`;
export const SET_ACTIVE_MOBILE_PAGE = `${MODULE_APP}/SET_ACTIVE_MOBILE_PAGE`;
export const SET_IS_CHAT_AUTO_RELOAD = `${MODULE_APP}/SET_IS_CHAT_AUTO_RELOAD`;
export const SET_AUTO_RELOAD_PROMPT_LABELS = `${MODULE_APP}/SET_AUTO_RELOAD_PROMPT_LABELS`;
export const SET_TOAST_BANNER_DATA = `${MODULE_APP}/SET_TOAST_BANNER_DATA`;
export const REMOVE_TOAST_BANNER_DATA = `${MODULE_APP}/REMOVE_TOAST_BANNER_DATA`;
export const SET_LOCATION = `${MODULE_APP}/SET_LOCATION`;

export const SET_PSYCHIC_STATUS = `${MODULE_STATUS_TOGGLE}/SET_PSYCHIC_STATUS`;
export const UPDATE_PSYCHIC_STATUS = `${MODULE_STATUS_TOGGLE}/UPDATE_PSYCHIC_STATUS`;
export const SET_BREAK = `${MODULE_STATUS_TOGGLE}/SET_BREAK`;
export const UPDATE_CHAT_AND_PHONE_STATUS = `${MODULE_STATUS_TOGGLE}/UPDATE_CHAT_AND_PHONE_STATUS`;
export const UPDATE_PHONE_ONLY_STATUS = `${MODULE_STATUS_TOGGLE}/UPDATE_PHONE_ONLY_STATUS`;
export const UPDATE_CHAT_ONLY = `${MODULE_STATUS_TOGGLE}/UPDATE_CHAT_ONLY_STATUS`;
export const UPDATE_CHAT_ENABLED = `${MODULE_STATUS_TOGGLE}/UPDATE_CHAT_ONLY`;
export const UPDATE_BREAK = `${MODULE_STATUS_TOGGLE}/UPDATE_BREAK_STATUS`;
export const UPDATE_OFFLINE_MESSAGES_STATUS = `${MODULE_STATUS_TOGGLE}/UPDATE_OFFLINE_MESSAGES_STATUS`;
export const UPDATE_OFFLINE_MESSAGES_AVAILABILITY = `${MODULE_STATUS_TOGGLE}/UPDATE_OFFLINE_MESSAGES_AVAILABILITY`;
export const END_BREAK = `${MODULE_STATUS_TOGGLE}/END_BREAK`;
export const UPDATE_IS_ON_CALL = `${MODULE_STATUS_TOGGLE}/UPDATE_IS_ON_CALL`;
export const UPDATE_IS_ON_CHAT = `${MODULE_STATUS_TOGGLE}/UPDATE_IS_ON_CHAT`;
export const EARLY_LOGOUT = `${MODULE_STATUS_TOGGLE}/EARLY_LOGOUT`;
export const UPDATE_PSYCHIC_SCHEDULE = `${MODULE_STATUS_TOGGLE}/UPDATE_PSYCHIC_SCHEDULE`;
export const SET_IS_CHAT_IN_INIT_STATE = `${MODULE_APP}/SET_IS_CHAT_IN_INIT_STATE`;

export const RECEIVE_SWITCH_TO_PHONE_INFO = `${MODULE_SWITCH_TO_PHONE}/RECEIVE_SWITCH_TO_PHONE_INFO`;
export const SET_CALLBACK_APPOINTMENT_INFO = `${MODULE_SWITCH_TO_PHONE}/SET_CALLBACK_APPOINTMENT_INFO`;
export const SAVE_CUSTOMER_PHONE = `${MODULE_SWITCH_TO_PHONE}/SAVE_CUSTOMER_PHONE`;
export const SET_CUSTOMER_PHONE_NUMBERS = `${MODULE_SWITCH_TO_PHONE}/SET_CUSTOMER_PHONE_NUMBERS`;
export const VALIDATE_PHONE_NUMBER = `${MODULE_SWITCH_TO_PHONE}/VALIDATE_PHONE_NUMBER`;
export const SWITCH_TO_PHONE = `${MODULE_SWITCH_TO_PHONE}/SWITCH_TO_PHONE`;

export const SET_IS_OFFLINE_CHAT_START = `${MODULE_CHAT}/SET_IS_OFFLINE_CHAT_START`;
export const SYSTEM_CHANNEL_SUCCEEDED = `${MODULE_CHAT}/SYSTEM_CHANNEL_SUCCEEDED`;
export const JOIN_CHANNEL_REQUEST = `${MODULE_CHAT}/JOIN_CHANNEL_REQUEST`;
export const CONFIGURE_CHANNEL_EVENTS = `${MODULE_CHAT}/CONFIGURE_CHANNEL_EVENTS`;
export const CONFIGURE_SYSTEM_CHANNEL_EVENTS = `${MODULE_CHAT}/CONFIGURE_SYSTEM_CHANNEL_EVENTS`;
export const ADD_PARTICIPANT_OFFLINE_MESSAGE = `${MODULE_CHAT}/ADD_PARTICIPANT_OFFLINE_MESSAGE`;
export const ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND = `${MODULE_CHAT}/ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND`;
export const UPDATE_PARTICIPANT_MESSAGE = `${MODULE_CHAT}/UPDATE_PARTICIPANT_MESSAGE`;
export const UPDATE_PARTICIPANT_OFFLINE_MESSAGE = `${MODULE_CHAT}/UPDATE_PARTICIPANT_OFFLINE_MESSAGE`;
export const UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND = `${MODULE_CHAT}/UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND`;
export const ADD_PARTICIPANT_MESSAGE = `${MODULE_CHAT}/ADD_PARTICIPANT_MESSAGE`;
export const ADD_SYSTEM_NOTIFICATION = `${MODULE_CHAT}/ADD_SYSTEM_NOTIFICATION`;
export const ADD_SYSTEM_NOTIFICATIONS = `${MODULE_CHAT}/ADD_SYSTEM_NOTIFICATIONS`;
export const JOIN_CHANNEL_SUCCESS = `${MODULE_CHAT}/JOIN_CHANNEL_SUCCESS`;
export const RECEIVE_AUTH_DATA = `${MODULE_CHAT}/RECEIVE_AUTH_DATA`;
export const RECEIVE_SDK_AUTH_DATA = `${MODULE_CHAT}/RECEIVE_SDK_AUTH_DATA`;
export const SET_CUSTOMER_ID = `${MODULE_CHAT}/SET_CUSTOMER_ID`;
export const SET_EXT_ID = `${MODULE_CHAT}/SET_EXT_ID`;
export const SET_ENCRYPTED_PASSWORD = `${MODULE_CHAT}/SET_ENCRYPTED_PASSWORD`;
export const SET_CUSTOMER_PHONE_VALIDATED = `${MODULE_CHAT}/SET_CUSTOMER_PHONE_VALIDATED`;
export const SET_IS_PHONE_NUMBER = `${MODULE_CHAT}/SET_IS_PHONE_NUMBER`;
export const SET_CUSTOMER_PHONE_SAVED = `${MODULE_CHAT}/SET_CUSTOMER_PHONE_SAVED`;
export const SET_TWILIO_USER = `${MODULE_CHAT}/SET_TWILIO_USER`;
export const ACCEPT_CHAT_REQUEST = `${MODULE_CHAT}/ACCEPT_CHAT_REQUEST`;
export const HANDLE_CREATE_CHAT_REQUEST = `${MODULE_CHAT}/HANDLE_CREATE_CHAT_REQUEST`;
export const CLEAN_AFTER_IGNORE_CHAT_REQUEST = `${MODULE_CHAT}/CLEAN_AFTER_IGNORE_CHAT_REQUEST`;
export const SET_COUNTRY_CODES = `${MODULE_CHAT}/SET_COUNTRY_CODES`;
export const JOIN_ACCEPTED_CHAT = `${MODULE_CHAT}/JOIN_ACCEPTED_CHAT`;
export const JOIN_ACCEPTED_CHAT_SUCCESS = `${MODULE_CHAT}/JOIN_ACCEPTED_CHAT_SUCCESS`;
export const SET_MESSAGES = `${MODULE_CHAT}/SET_MESSAGES`;
export const SET_MESSAGES_OFFLINE = `${MODULE_CHAT}/SET_MESSAGES_OFFLINE`;
export const SET_MESSAGES_BEFORE = `${MODULE_CHAT}/SET_MESSAGES_BEFORE`;
export const SET_MESSAGES_BEFORE_OFFLINE = `${MODULE_CHAT}/SET_MESSAGES_BEFORE_OFFLINE`;
export const SET_CHAT_ID = `${MODULE_CHAT}/SET_CHAT_ID`;
export const SET_SIDE_USER = `${MODULE_CHAT}/SET_SIDE_USER`;
export const SET_CURRENT_USER = `${MODULE_CHAT}/SET_CURRENT_USER`;
export const SET_CHANNEL_SID = `${MODULE_CHAT}/SET_CHANNEL_SID`;
export const INSTALL_CLIENT_LISTENERS = `${MODULE_CHAT}/INSTALL_CLIENT_LISTENERS`;
export const DELETE_SIDE_USER = `${MODULE_CHAT}/DELETE_SIDE_USER`;
export const DELETE_CURRENT_CHAT = `${MODULE_CHAT}/DELETE_CURRENT_CHAT`;
export const INIT_APPLICATION = `${MODULE_CHAT}/INIT_APPLICATION`;
export const END_CHAT_REQUEST = `${MODULE_CHAT}/END_CHAT_REQUEST`;
export const DELETE_CURRENT_CHANNEL = `${MODULE_CHAT}/DELETE_CURRENT_CHANNEL`;
export const DELETE_CUSTOMER_ID = `${MODULE_CHAT}/DELETE_CUSTOMER_ID`;
export const DELETE_NOTIFICATIONS = `${MODULE_CHAT}/DELETE_NOTIFICATIONS`;
export const RESET_CHAT = `${MODULE_CHAT}/RESET_CHAT`;
export const CHAT_FETCH_REQUESTED = `${MODULE_CHAT}/CHAT_FETCH_REQUESTED`;
export const CHAT_FETCH_SUCCEEDED = `${MODULE_CHAT}/CHAT_FETCH_SUCCEEDED`;
export const SET_OFFLINE_CURRENT_CHAT = `${MODULE_CHAT}/SET_OFFLINE_CURRENT_CHAT`;
export const RECEIVE_NEW_OFFLINE_CHAT = `${MODULE_CHAT}/ADD_NEW_OFFLINE_CHAT_CHAT`;
export const SEND_MESSAGE = `${MODULE_CHAT}/SEND_MESSAGE`;
export const SET_CURRENT_CHANNEL_SID = `${MODULE_CHAT}/SET_CURRENT_CHANNEL_SID`;
export const SET_TWILIO_CLIENT = `${MODULE_CHAT}/SET_TWILIO_CLIENT`;
export const SET_CURRENT_PSYCHIC = `${MODULE_CHAT}/SET_CURRENT_PSYCHIC`;
export const CURRENT_PSYCHIC_REQUEST = `${MODULE_CHAT}/CURRENT_PSYCHIC_REQUEST`;
export const SET_CURRENT_PSYCHIC_STATUS = `${MODULE_CHAT}/SET_CURRENT_PSYCHIC_STATUS`;
export const LOAD_EXTRA_MESSAGES = `${MODULE_CHAT}/LOAD_EXTRA_MESSAGES`;
export const SET_TYPING_STARTED_MESSAGE = `${MODULE_CHAT}/SET_TYPING_STARTED_MESSAGE`;
export const REMOVE_CHAT_ID = `${MODULE_CHAT}/REMOVE_CHAT_ID`;
export const START_CHAT_BY_LINK = `${MODULE_CHAT}/START_CHAT_BY_LINK`;
export const SET_UP_PSYCHIC_BY_LINK = `${MODULE_CHAT}/SET_UP_PSYCHIC_BY_LINK`;
export const DELETE_SYSTEM_CHANNEL = `${MODULE_CHAT}/DELETE_SYSTEM_CHANNEL`;
export const DELETE_TWILIO_USER = `${MODULE_CHAT}/DELETE_TWILIO_USER`;
export const DELETE_CURRENT_PSYCHIC = `${MODULE_CHAT}/DELETE_CURRENT_PSYCHIC`;
export const CLEAR_EXT_ID = `${MODULE_CHAT}/CLEAR_EXT_ID`;
export const SET_CHAT_MINUTES_ACTIVE = `${MODULE_CHAT}/SET_CHAT_MINUTES_ACTIVE`;
export const SET_CHAT_BY_ID = `${MODULE_CHAT}/SET_CHAT_BY_ID`;
export const SET_CHAT_CHANNEL_AND_LOAD_MESSAGE = `${MODULE_CHAT}/SET_CHAT_CHANNEL_AND_LOAD_MESSAGE`;
export const HANDLE_RECEIVED_OFFLINE_CHATS = `${MODULE_CHAT}/HANDLE_RECEIVED_OFFLINE_CHATS`;
export const HANDLE_RECEIVED_ACTIVE_PSYCHIC_CHATS = `${MODULE_CHAT}/HANDLE_RECEIVED_ACTIVE_PSYCHIC_CHATS`;
export const SET_OFFLINE_CHATS = `${MODULE_CHAT}/SET_OFFLINE_CHATS`;
export const ADD_OFFLINE_CHAT = `${MODULE_CHAT}/ADD_OFFLINE_CHAT`;
export const REMOVE_CURRENT_OFFLINE_CHAT = `${MODULE_CHAT}/REMOVE_CURRENT_OFFLINE_CHAT`;
export const REMOVE_OFFLINE_CHAT = `${MODULE_CHAT}/REMOVE_OFFLINE_CHAT`;
export const CLOSE_OFFLINE_CHAT = `${MODULE_CHAT}/CLOSE_OFFLINE_CHAT`;
export const OPEN_OFFLINE_CHAT = `${MODULE_CHAT}/OPEN_OFFLINE_CHAT`;
export const OPEN_CURRENT_OFFLINE_CHAT = `${MODULE_CHAT}/OPEN_CURRENT_OFFLINE_CHAT`;
export const OFFLINE_CHATS_LOADED = `${MODULE_CHAT}/OFFLINE_CHATS_LOADED`;
export const READ_OFFLINE_MESSAGE_HANDLER = `${MODULE_CHAT}/READ_OFFLINE_MESSAGE_HANDLER`;
export const ACTIVE_CHATS_LOADED = `${MODULE_CHAT}/ACTIVE_CHATS_LOADED`;
export const SIDE_USER_REQUEST = `${MODULE_CHAT}/SIDE_USER_REQUEST`;
export const CLEAR_CHAT = `${MODULE_CHAT}/CLEAR_CHAT`;
export const CLEAR_CHAT_EVENTS = `${MODULE_CHAT}/CLEAR_CHAT_EVENTS`;
export const SET_CHAT_STATUS = `${MODULE_CHAT}/SET_CHAT_STATUS`;
export const SET_CURRENT_CHAT_ID = `${MODULE_CHAT}/SET_CURRENT_CHAT_ID`;
export const CHANGE_MAX_DURATION = `${MODULE_CHAT}/CHANGE_MAX_DURATION`;
export const SET_USER_CANCEL_OFFLINE_CHAT = `${MODULE_CHAT}/SET_USER_CANCEL_OFFLINE_CHAT`;
export const SET_USER_CANCEL_CURRENT_OFFLINE_CHAT = `${MODULE_CHAT}/SET_USER_CANCEL_CURRENT_OFFLINE_CHAT`;
export const SET_VISIBILITY_NOTIFICATION = `${MODULE_CHAT}/SET_VISIBILITY_NOTIFICATION`;
export const SET_CURRENT_PSYCHIC_PHONE_STATUS = `${MODULE_CHAT}/SET_CURRENT_PSYCHIC_PHONE_STATUS`;
export const REMOVE_PREV_CHAT_DATE = `${MODULE_CHAT}/REMOVE_PREV_CHAT_DATE`;
export const SET_FUNDS = `${MODULE_CHAT}/SET_FUNDS`;
export const SET_CUSTOMER_BALANCE = `${MODULE_CHAT}/SET_CUSTOMER_BALANCE`;
export const SET_DIFF_IN_TIME = `${MODULE_CHAT}/SET_DIFF_IN_TIME`;
export const GET_SYSTEM_CHANNEL = `${MODULE_CHAT}/GET_SYSTEM_CHANNEL`;
export const CHECK_IF_CHAT_MISSED = `${MODULE_CHAT}/CHECK_IF_CHAT_MISSED`;
export const SETUP_NOTIFICATIONS = `${MODULE_CHAT}/SETUP_NOTIFICATIONS`;
export const SET_APPOINTMENT = `${MODULE_CHAT}/SET_APPOINTMENT`;
export const RESTORE_ACTIVE_CHAT = `${MODULE_CHAT}/RESTORE_ACTIVE_CHAT`;
export const HANDLE_START_CHAT_ERROR = `${MODULE_CHAT}/HANDLE_START_CHAT_ERROR`;
export const SET_PHONE_STATUS_WAITING_STATE = `${MODULE_CHAT}/SET_PHONE_STATUS_WAITING_STATE`;
export const SEND_MESSAGE_LOCAL = `${MODULE_CHAT}/SEND_MESSAGE_LOCAL`;
export const SET_ACTIVE_CHAT = `${MODULE_CHAT}/SET_ACTIVE_CHAT`;
export const SET_IS_ACCEPT_CHAT = `${MODULE_CHAT}/SET_IS_ACCEPT_CHAT`;
export const UPDATE_CALLBACK_BANNER = `${MODULE_CHAT}/UPDATE_CALLBACK_BANNER`;
export const MUTE_CHIME_REQUEST = `${MODULE_CHAT}/MUTE_CHIME_REQUEST`;
export const SET_PSYCHIC_SCHEDULES = `${MODULE_CHAT}/SET_PSYCHIC_SCHEDULES`;
export const SET_PSYCHIC_CALLBACK_QUEUE = `${MODULE_CHAT}/SET_PSYCHIC_CALLBACK_QUEUE`;
export const SET_EXPECTEDLOGIN_MIN = `${MODULE_CHAT}/SET_EXPECTEDLOGIN_MIN`;
export const SET_INIT_CHAT_NOTIFICATION = `${MODULE_CHAT}/SET_INIT_CHAT_NOTIFICATION`;
export const SET_INIT_CHAT_BANNER = `${MODULE_CHAT}/SET_INIT_CHAT_BANNER`;
export const SHOW_AUTO_RELOAD_HEADER = `${MODULE_CHAT}/SHOW_AUTO_RELOAD_HEADER`;
export const SET_AUTO_RELOAD = `${MODULE_CHAT}/SET_AUTO_RELOAD`;
export const ENABLE_CUSTOMER_AUTO_RELOAD = `${MODULE_CHAT}/ENABLE_CUSTOMER_AUTO_RELOAD`;
export const SET_END_CHAT_DUE_TO_LOW_FUND = `${MODULE_CHAT}/SET_END_CHAT_DUE_TO_LOW_FUND`;
export const SET_CUSTOMER_ENABLE_AUTO_RECHARGE = `${MODULE_CHAT}/SET_CUSTOMER_ENABLE_AUTO_RECHARGE`;

export const LOGIN_START = `${MODULE_AUTH}/LOGIN_START`;
export const LOGIN_SUCCESS = `${MODULE_AUTH}/LOGIN_SUCCESS`;
export const SET_TWILIO_TOKEN = `${MODULE_AUTH}/SET_TWILIO_TOKEN`;
export const SET_AUTH_TOKEN = `${MODULE_AUTH}/SET_AUTH_TOKEN`;
export const SET_BEARER_TOKEN = `${MODULE_AUTH}/SET_BEARER_TOKEN`;
export const CLEAR_SESSION = `${MODULE_AUTH}/CLEAR_SESSION`;
export const LOGOUT = `${MODULE_AUTH}/LOGOUT`;

export const SET_IS_OFFLINE_CHAT_START_HANDLER = `${MODULE_OFFLINE_CHAT}/SET_IS_OFFLINE_CHAT_START_HANDLER`;
export const ADD_PARTICIPANT_OFFLINE_MESSAGE_HANDLER = `${MODULE_OFFLINE_CHAT}/ADD_PARTICIPANT_OFFLINE_MESSAGE_HANDLER`;
export const ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER = `${MODULE_OFFLINE_CHAT}/ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER`;
export const UPDATE_PARTICIPANT_MESSAGE_HANDLER = `${MODULE_OFFLINE_CHAT}/UPDATE_PARTICIPANT_MESSAGE_HANDLER`;
export const UPDATE_PARTICIPANT_OFFLINE_MESSAGE_HANDLER = `${MODULE_OFFLINE_CHAT}/UPDATE_PARTICIPANT_OFFLINE_MESSAGE_HANDLER`;
export const UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER = `${MODULE_OFFLINE_CHAT}/UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER`;
export const SET_MESSAGES_OFFLINE_HANDLER = `${MODULE_OFFLINE_CHAT}/SET_MESSAGES_OFFLINE_HANDLER`;
export const SET_MESSAGES_BEFORE_OFFLINE_HANDLER = `${MODULE_OFFLINE_CHAT}/SET_MESSAGES_BEFORE_OFFLINE_HANDLER`;
export const REMOVE_CURRENT_OR_NOT_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/REMOVE_CURRENT_OR_NOT_OFFLINE_CHAT_HANDLER`;
export const REMOVE_CURRENT_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/REMOVE_CURRENT_OFFLINE_CHAT_HANDLER`;
export const REMOVE_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/REMOVE_OFFLINE_CHAT_HANDLER`;
export const CLOSE_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/CLOSE_OFFLINE_CHAT_HANDLER`;
export const SET_USER_CANCEL_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/SET_USER_CANCEL_OFFLINE_CHAT_HANDLER`;
export const SET_PSYCHIC_CANCEL_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/SET_PSYCHIC_CANCEL_OFFLINE_CHAT_HANDLER`;
export const HANDLE_OFFLINE_MESSAGE_SENDING = `${MODULE_OFFLINE_CHAT}/HANDLE_OFFLINE_MESSAGE_SENDING`;
export const OPEN_OFFLINE_CHAT_BY_ID = `${MODULE_OFFLINE_CHAT}/OPEN_OFFLINE_CHAT_BY_ID`;

export const OPEN_OFFLINE_CHAT_HANDLER = `${MODULE_OFFLINE_CHAT}/OPEN_OFFLINE_CHAT_HANDLER`;
export const DECLINE_OFFLINE_MESSAGE_REQUEST = `${MODULE_OFFLINE_CHAT}/DECLINE_OFFLINE_MESSAGE_REQUEST`;

export const OPEN_DIAGNOSTIC_PAGE = `${DIAGNOSTIC_PAGE}/OPEN_DIAGNOSTIC_PAGE`;
export const RUN_DIAGNOSTIC_PAGE = `${DIAGNOSTIC_PAGE}/RUN_DIAGNOSTIC_PAGE`;
export const SET_CONSOLE_ERROR_LOGS = `${DIAGNOSTIC_PAGE}/SET_CONSOLE_ERROR_LOGS`;

export const SET_PSYCHIC_NOTIFICATIONS_ACTIVE = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PSYCHIC_NOTIFICATIONS_ACTIVE`;
export const SET_PSYCHIC_NOTES_STATS = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PSYCHIC_NOTES_STATS`;
export const GET_PSYCHIC_ALL_NOTIFICATIONS_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/GET_PSYCHIC_ALL_NOTIFICATIONS_LIST`;
export const SET_PSYCHIC_ALL_NOTIFICATIONS_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PSYCHIC_ALL_NOTIFICATIONS_LIST`;
export const GET_READING_CUSTOMERS_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/GET_READING_CUSTOMERS_LIST`;
export const SET_READING_CUSTOMERS_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/SET_READING_CUSTOMERS_LIST`;
export const SEND_NOTIFICATION_TO_CUSTOMER = `${MODULE_PSYCHIC_NOTIFICATION}/SEND_NOTIFICATION_TO_CUSTOMER`;
export const SET_NOTIFICATION_CUSTOMER_DETAIL = `${MODULE_PSYCHIC_NOTIFICATION}/SET_NOTIFICATION_CUSTOMER_DETAIL`;
export const SEND_BLOCKED_CONTENT_TO_CS = `${MODULE_PSYCHIC_NOTIFICATION}/SEND_BLOCKED_CONTENT_TO_CS`;
export const SET_NOTIFICATION_REACTION_IDS = `${MODULE_PSYCHIC_NOTIFICATION}/SET_NOTIFICATION_REACTION_IDS`;
export const UPDATE_REACTION_READ_STATUS = `${MODULE_PSYCHIC_NOTIFICATION}/UPDATE_REACTION_READ_STATUS`;
export const CLEAR_PSYCHIC_NOTIFICATION = `${MODULE_PSYCHIC_NOTIFICATION}/CLEAR_PSYCHIC_NOTIFICATION`;
export const GET_CLIENT_NOTE_HISTORY = `${MODULE_PSYCHIC_NOTIFICATION}/GET_CLIENT_NOTE_HISTORY`;
export const SET_NOTES_HISTORY = `${MODULE_PSYCHIC_NOTIFICATION}/SET_NOTES_HISTORY`;
export const SET_ACTIVE_SCREEN = `${MODULE_PSYCHIC_NOTIFICATION}/SET_ACTIVE_SCREEN`;
export const SET_PSYCHIC_NOTE_CONFIGURABLE_DATA = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PSYCHIC_NOTE_CONFIGURABLE_DATA`;
export const SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC = `${MODULE_PSYCHIC_NOTIFICATION}/SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC`;
export const SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER = `${MODULE_PSYCHIC_NOTIFICATION}/SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER`;
export const UPDATE_CLIENT_NOTES = `${MODULE_PSYCHIC_NOTIFICATION}/UPDATE_CLIENT_NOTES`;
export const UPDATE_CUSTOMER_NOTIFICATION = `${MODULE_PSYCHIC_NOTIFICATION}/UPDATE_CUSTOMER_NOTIFICATION`;
export const UPDATE_NOTIFICATION_STATUS = `${MODULE_PSYCHIC_NOTIFICATION}/UPDATE_NOTIFICATION_STATUS`;
export const SET_TODAY_CUSTOMER_NOTE_COUNT = `${MODULE_PSYCHIC_NOTIFICATION}/SET_TODAY_CUSTOMER_NOTE_COUNT`;
export const SET_ALL_PSYCHIC_NOTIFICATION_MUTE = `${MODULE_PSYCHIC_NOTIFICATION}/SET_ALL_PSYCHIC_NOTIFICATION_MUTE`;
export const UNMUTE_ALL_PSYCHIC_NOTIFICATION = `${MODULE_PSYCHIC_NOTIFICATION}/UNMUTE_ALL_PSYCHIC_NOTIFICATION`;
export const SET_CURRENT_PCN_TAB = `${MODULE_PSYCHIC_NOTIFICATION}/SET_CURRENT_PCN_TAB`;
export const SET_PRIORITY_ACTION_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PRIORITY_ACTION_LIST`;
export const SET_PRIORITY_ACTION_COUNT = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PRIORITY_ACTION_COUNT`;
export const GET_PRIORITY_ACTION_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/GET_PRIORITY_ACTION_LIST`;
export const GET_PRIORITY_ACTION_COUNT = `${MODULE_PSYCHIC_NOTIFICATION}/GET_PRIORITY_ACTION_COUNT`;
export const SET_PRIORITY_ACTION_MESSAGES = `${MODULE_PSYCHIC_NOTIFICATION}/SET_PRIORITY_ACTION_MESSAGES`;
export const SET_CLIENT_NOTES_DRAFT_TOAST = `${MODULE_PSYCHIC_NOTIFICATION}/SET_CLIENT_NOTES_DRAFT_TOAST`;
export const UPDATE_CLIENT_LABELS = `${MODULE_PSYCHIC_NOTIFICATION}/UPDATE_CLIENT_LABELS`;
export const SET_CLIENT_LABELS_LIST = `${MODULE_PSYCHIC_NOTIFICATION}/SET_CLIENT_LABELS_LIST`;

export const SET_VISIBLE_REMINDER_SETTINGS_POPUP = `${REMINDER_OPTIONS}/SET_VISIBLE_REMINDER_SETTINGS_POPUP`;

export const UPDATE_REMINDER_CONFIGS = `${REMINDER_OPTIONS}/UPDATE_REMINDER_CONFIGS`;
export const SET_REMINDER_CONFIGS = `${REMINDER_OPTIONS}/SET_REMINDER_CONFIGS`;

export const SET_SMS_ALERT_PHONE_NUMBER = `${REMINDER_OPTIONS}/SET_SMS_ALERT_PHONE_NUMBER`;
