import React from 'react';
import cn from 'classnames';

import styles from 'src/components/Tags/LabelTags/LabelTags.module.scss';

const LabelTags = ({
  labels,
  isEditButton = false,
  handelOnClickedLabel = () => {},
  isClientNotes = false,
}) => {
  const displayedLabels = isClientNotes ? labels : labels?.slice(0, 2);
  const remainingCount = !isClientNotes && labels?.length - 2;

  const isButtonVisible = !isClientNotes && (isEditButton || (!isEditButton && labels?.length > 2));

  if ((isClientNotes && !labels)) {
    return null;
  }

  return (
    <div className={cn(styles.wrapper, {
      [styles.isClientNotes]: isClientNotes,
    })}
    >
      {
        displayedLabels?.map((label) => (
          <span key={label} className={styles.tag}>
            {label}
          </span>
        ))
      }
      {isButtonVisible && (
        <button
          type="button"
          onClick={handelOnClickedLabel}
          className={styles.editButton}
        >
          {labels?.length > 2 ? `+${remainingCount}` : '+'}
        </button>
      )}
    </div>
  );
};

export default LabelTags;
