import {
  takeEvery,
  call,
  select,
  put,
} from 'redux-saga/effects';

import { SentryMethods } from 'src/utils/sentryMethods';
import { ErrorRequestType, Status } from 'constants/enums';
import { API } from 'src/utils/api';
import {
  saveCustomerPhone,
  setCustomerPhoneIsSaved,
  setCustomerPhoneNumbers,
  setCustomerPhoneValidated,
  setIsPhoneNumber,
  setSwitchToPhoneAppointments,
  switchToPhone,
  validateCustomerPhone,
} from 'actions/switchToPhoneActions';
import {
  RECEIVE_SWITCH_TO_PHONE_INFO,
  SAVE_CUSTOMER_PHONE,
  SWITCH_TO_PHONE,
  VALIDATE_PHONE_NUMBER,
} from 'actions/actionsTypes';
import {
  selectChatId,
  selectCurrentUser,
  selectCustomerId,
  selectExtId,
  selectIsHistoryMode,
  selectIsChatEnd,
  selectIsPsychicNotificationsEnable,
  selectPsychicPhoneStatus,
} from 'selectors/selectors';
import { selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import { selectCustomerPhoneNumbers, selectSwitchToPhoneAppointment } from 'selectors/switchToPhoneSelectors';
import {
  setIsLiveChatActive,
  setIsVisibleHeaderBanner,
  setLoadingState,
  setRequestErrorState,
  showPopUpNotification,
} from 'actions/appActions';
import { deleteNotifications, removeChatId, updateCallbackBanner } from 'actions/chatActions';

function* receiveSwitchToPhoneInfoHandler() {
  try {
    const customerRefIdEnc = yield select(selectCustomerId);
    const isPsychicNotificationsEnable = yield select(selectIsPsychicNotificationsEnable);
    const sendCustomerDetail = yield select(selectSendCustomerDetail);
    let extId = yield select(selectExtId);

    if (isPsychicNotificationsEnable && sendCustomerDetail?.length) {
      extId = sendCustomerDetail[0]?.extId;
    }

    const payload = yield call(
      API.Customer.getCustomerPhones,
      customerRefIdEnc,
      extId,
    );
    const {
      data: { recentNumbers, hasAppointment },
    } = payload;

    yield put(setCustomerPhoneValidated(null));
    yield put(setCustomerPhoneIsSaved(null));
    yield put(setCustomerPhoneNumbers(recentNumbers));
    yield put(setSwitchToPhoneAppointments(hasAppointment));
  } catch (e) {
    console.error('Error while receiving switch to phone data', e);
  }
}

function* validateCustomerPhoneHandler({
  payload,
}: ReturnType<typeof validateCustomerPhone>) {
  const customerRefIdEnc = yield select(selectCustomerId);
  yield put(setCustomerPhoneValidated(null));

  const requestData = { ...payload, customerRefIdEnc };
  const {
    data: { isPhoneValid, isPhoneNumber },
  } = yield call(API.Customer.validatePhoneNumber, requestData);

  yield put(setCustomerPhoneValidated(isPhoneValid));
  yield put(setIsPhoneNumber(isPhoneNumber));
}

function* saveCustomerPhoneHandler({
  payload,
}: ReturnType<typeof saveCustomerPhone>) {
  const { payload: data, addMixpanelEventAlertTapped } = payload;
  const customerRefIdEnc = yield select(selectCustomerId);
  const isHistoryMode = yield select(selectIsHistoryMode);
  const customerPhoneNumbers = yield select(selectCustomerPhoneNumbers);

  const {
    data: { recentNumbers, savedPhone },
  } = yield call(API.Customer.addPhoneNumber, { 
    ...data, 
    customerRefIdEnc, 
    isPrimaryNumber: !customerPhoneNumbers.length,
  });
  addMixpanelEventAlertTapped({
    alertType: 'add/select number and callback',
    view: 'chat window',
    alertSelection: 'add new',
    screenName: isHistoryMode ? 'chat history mode' : 'switch to phone',
  });

  if (customerPhoneNumbers.length) {
    [recentNumbers[0], recentNumbers[1]] = [recentNumbers[1], recentNumbers[0]];
  }

  yield put(setCustomerPhoneNumbers(recentNumbers));
  yield put(setCustomerPhoneIsSaved(savedPhone.isSuccess));
  yield put(setCustomerPhoneValidated(null));
}

export function* callHandler({ payload }: ReturnType<typeof switchToPhone>) {
  try {
    const { addMixpanelEventAlertTapped, sideUser, phoneData } = payload;
    const chatId = yield select(selectChatId);
    const psychicId = yield select(selectExtId);
    const { customerRefId } = yield select(selectCurrentUser);
    const isChatEnd = yield select(selectIsChatEnd);
    const customerRefIdEnc = yield select(selectCustomerId);
    const { untilStart, untilEnd } = yield select(selectSwitchToPhoneAppointment);
    const isHistoryMode = yield select(selectIsHistoryMode);
    const currentPhonePsychicStatus = yield select(selectPsychicPhoneStatus);
    const isCallback = (currentPhonePsychicStatus?.toLocaleLowerCase() === Status.OFFLINE);
    yield put(setLoadingState(true));

    const callPayload = {
      chatId,
      customerRefId,
      customerRefIdEnc,
      psychicId,
      isCallback,
      ...phoneData,
    };

    yield call(
      (!isChatEnd && chatId)
        ? API.Customer.enterCallbackQueue
        : API.Chat.phoneCallback, callPayload,
    );

    addMixpanelEventAlertTapped({
      alertType: 'add/select number and callback',
      view: 'chat window',
      alertSelection:
        (untilStart && untilStart <= 5)
          ? 'Enter Callback Queue'
          : 'Start Call',
      user: sideUser,
      screenName: isHistoryMode ? 'chat history mode' : 'switch to phone',
    });

    yield put(updateCallbackBanner());
    yield put(showPopUpNotification(false));

    if (!untilStart && !untilEnd) {
      yield put(setIsVisibleHeaderBanner(false));
      yield put(deleteNotifications());
      yield put(setIsLiveChatActive(false));
      yield put(removeChatId());
    }
  } catch (e) {
    console.error(e);

    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
    yield put(showPopUpNotification(false));

    SentryMethods.captureException(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* switchToPhoneSagaWatcher() {
  yield takeEvery(
    RECEIVE_SWITCH_TO_PHONE_INFO,
    receiveSwitchToPhoneInfoHandler,
  );
  yield takeEvery(SAVE_CUSTOMER_PHONE, saveCustomerPhoneHandler);
  yield takeEvery(VALIDATE_PHONE_NUMBER, validateCustomerPhoneHandler);
  yield takeEvery(SWITCH_TO_PHONE, callHandler);
}
