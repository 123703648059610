import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

import {
  setActiveScreen as _setActiveScreen,
  getClientNoteHistory as _getClientNoteHistory,
  updateClientLabelsList as _updateClientLabelsList,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import { PNTabs } from 'constants/enums';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  selectCurrentPCNTab,
  selectClientLabelsList,
  selectSendCustomerDetail,
  selectPsychicNoteConfigurableData,
} from 'selectors/psychicNotificationsSelectors';
import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { simplyfyJsonArrayKeys } from 'src/utils/commonUtil';
import LabelErrorPopup from 'components/Popups/ClientLabelsOverlay/LabelError';
import styles from 'components/Popups/ClientLabelsOverlay/ClientLabelsOverlay.module.scss';

const ClientLabelsOverlay = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
  removePopUpNotification,
}) => {
  const [labels, setLabels] = useState<Array<any>>([]);
  const [isEnable, setIsEnable] = useState<boolean>(true);

  const currentTab = useSelector(selectCurrentPCNTab);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail) || [];
  const clientLabelsList = useSelector(selectClientLabelsList);
  const {
    psychicNotesSystemMessages,
  } = useSelector(selectPsychicNoteConfigurableData);
  const { title, callback, description } = popUpNotificationData;
  const customer = callback?.args && Array.isArray(callback.args) && callback?.args[1];
  const pCComID = customer?.pCComID;
  const isPcComID = pCComID && pCComID !== '0';
  const buttonText = isPcComID ? `Save Client Tags for ${customer?.displayname}`
    : `Send Note to ${customer?.displayname}`;
  const {
    clientLabelsErrorPopupTitle,
    clientLabelsErrorPopupMessageText,
  } = simplyfyJsonArrayKeys(psychicNotesSystemMessages);

  const updateClientLabelsList = useBindDispatch(_updateClientLabelsList);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setActiveScreen = useBindDispatch(_setActiveScreen);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  useEffect(() => {
    if (callback?.args && Array.isArray(callback.args) && callback.args.length > 0) {
      const selectedCustomerLabels = callback.args[0] || [];
      const currentLabels = selectedCustomerLabels
        .map((labelText) => clientLabelsList.find((label) => label.clientLabelsText === labelText))
        .filter(Boolean);

      setLabels(currentLabels);
    }
  }, [callback?.args, clientLabelsList]);

  useEffect(() => {
    if (callback?.args && Array.isArray(callback.args) && callback.args.length > 0) {
      const currentLabels = callback.args[0];

      const areEqual = (currentLabels.length === labels.length
        && currentLabels.every(
          (label) => labels.some((selectedLabel) => selectedLabel.clientLabelsText === label),
        )) || !!sendCustomerDetail?.length;

      setIsEnable(areEqual && isPcComID);
    }
  }, [callback?.args, labels, isPcComID, sendCustomerDetail]);

  const handleOptionSelected = (label) => {
    setLabels((prevLabels) => {
      const isSelected = prevLabels.some((item) => item.clientLabelsId === label.clientLabelsId);

      if (isSelected) {
        return prevLabels.filter((item) => item.clientLabelsId !== label.clientLabelsId);
      }

      return [...prevLabels, label];
    });
  };

  const onClose = () => {
    showPopUpNotification(false);
    removePopUpNotification();
    setLabels([]);
  };

  const handleOnclick = () => {
    if (isPcComID) {
      updateClientLabelsList(labels, customer, callback?.func);
    } else {
      setNotificationCustomerDetail([customer]);
      getClientNoteHistory(0);
      setActiveScreen(PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION);
    }

    setLabels([]);
    onClose();
  };

  const renderButtonContainer = () => (
    <div className={styles.buttonWrapper}>
      <button
        type="button"
        onClick={handleOnclick}
        disabled={isEnable}
        className={cn(styles.buttonSave, {
          [styles.disable]: isEnable,
        })}
      >
        {buttonText}
      </button>
      <button
        type="button"
        onClick={onClose}
        className={styles.buttonClose}
      >
        Close
      </button>
    </div>
  );

  if (currentTab !== PNTabs.CUSTOMER_LIST_TAB && !clientLabelsList) {
    return null;
  }

  if (!isPcComID) {
    return (
      <LabelErrorPopup
        title={clientLabelsErrorPopupTitle}
        popupText={clientLabelsErrorPopupMessageText}
        isNotificationPopUp={isNotificationPopUp}
        renderButtonContainer={renderButtonContainer}
      />
    );
  }

  return (
    <Modal
      isOpen={isNotificationPopUp}
      className={styles.modal}
      ariaHideApp={false}
      overlayClassName={styles.overlay}
    >
      <div className={styles.wrapper}>
        <span className={styles.title}>{title}</span>
        <div className={styles.tagContainers}>
          <div className={styles.tagHead}>{description}</div>
          {clientLabelsList.map((label) => (
            <button
              key={label.clientLabelsId}
              className={cn(styles.tag, {
                [styles.tagSelected]: labels.some(
                  (selectedLabel) => selectedLabel.clientLabelsId === label.clientLabelsId,
                ),
              })}
              type="button"
              disabled={!!sendCustomerDetail?.length}
              onClick={() => handleOptionSelected(label)}
            >
              {label.clientLabelsText}
            </button>
          ))}
        </div>
        {renderButtonContainer()}
      </div>
    </Modal>
  );
};

export default ClientLabelsOverlay;
