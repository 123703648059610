import React, { memo } from 'react';
import cn from 'classnames';

import { formatToUSTime } from 'src/utils/dateHandler';
import { MessageInterface } from 'types/componentTypes';
import { MessageAttributes, MessageType } from 'types/objectTypes';
import styles from 'components/Messages/Messages.module.scss';

const UserMessage: React.FC<MessageInterface> = ({
  message,
  author,
  view,
  isPreviousOffline,
}) => {
  const {
    attributes,
    body,
    timestamp,
  } = message as MessageType;
  const { participantType } = attributes as MessageAttributes;
  const isCurrentUserMessage = participantType === view;

  if (!participantType) {
    return <></>;
  }

  const data = formatToUSTime(timestamp);

  const messageContainer: string = cn(
    styles.messageContainer,
    styles.messagesMt,
    styles.userMessage,
    isCurrentUserMessage ? styles.alignSelfEnd : styles.alignSelfStart,
  );

  const messageBox: string = cn(
    styles.messageBox,
    isCurrentUserMessage
      ? cn(styles.backgroundBlue, styles.justifySelfEnd)
      : cn(styles.backgroundLight, styles.justifySelfStart),
  );

  const messageAuthor: string = cn(styles.sentText, isCurrentUserMessage
    ? styles.colorWhite
    : styles.colorDark);

  const messageText: string = cn(styles.messageText, isCurrentUserMessage
    ? styles.colorWhite
    : styles.colorDark);

  const messageTime: string = cn(styles.sentTime, isCurrentUserMessage
    ? styles.justifySelfEnd
    : styles.justifySelfStart);

  if (!isPreviousOffline) {
    return (
      <div className={messageContainer}>
        <div className={messageBox}>
          <span className={messageAuthor}>{author}</span>
          <span className={messageText}>{body}</span>
        </div>
        <span className={messageTime}>{data}</span>
      </div>
    );
  }

  return (
    <>
      <div className={styles.dividedLine} />
      <div className={messageContainer}>
        <div className={messageBox}>
          <span className={messageAuthor}>{author}</span>
          <span className={messageText}>{body}</span>
        </div>
        <span className={messageTime}>{data}</span>
      </div>
    </>
  );
};

export default memo(UserMessage);
