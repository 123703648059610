import React, { useMemo } from 'react';
import cn from 'classnames';

import SwitchToPhoneBanner from 'components/Banners/SwitchToPhoneBanner';
import { View } from 'constants/enums';
import { HeaderNotificationProps } from 'types/componentTypes';
import { SUPPORT_PHONE_NUMBER, SWITCH_TO_PHONE_BANNER } from 'constants/constants';
import InitChatCustomerPopup from 'components/Popups/InitChatCustomerPopup';
import InitChatInfoBanner from 'components/Banners/InitChatInfoBanner';

import style from './HeaderNotification.module.scss';

const HeaderNotification: React.FC<HeaderNotificationProps> = ({
  view,
  isVisible,
  notification,
  handleOnClick,
  isHistoryMode,
  isInitChatModal,
  isInitChatBanner,
}) => {
  const isPhoneNotification = notification?.type === SWITCH_TO_PHONE_BANNER;
  const isCustomerView = view === View.CUSTOMER;

  const descriptionSupport = useMemo(() => (
    typeof notification?.description === 'string'
      && notification?.description?.includes(SUPPORT_PHONE_NUMBER)
      && notification?.description?.replace(SUPPORT_PHONE_NUMBER, '')
  ), [notification]);

  return (
    <>
      { isPhoneNotification && <SwitchToPhoneBanner handleOnClick={handleOnClick} /> }
      <InitChatInfoBanner isVisible={isInitChatBanner && isCustomerView && !isHistoryMode} />
      {(isInitChatModal && !isHistoryMode && isCustomerView) && <InitChatCustomerPopup />}
      {
        !isPhoneNotification && (
          <div className={cn({
            [style.wrapper]: isVisible && isCustomerView && !isPhoneNotification,
            [style.wrapperPsychicView]: isVisible && !isCustomerView,
            [style.hideNotification]: !isVisible,
          })}
          >
            <div className={style.notification}>
              <h4>
                {notification?.title}
              </h4>
              <p>
                {notification?.subtitle}
              </p>
              <p>
                {descriptionSupport || notification?.description}
                {descriptionSupport && <span>{SUPPORT_PHONE_NUMBER}</span>}
              </p>
            </div>
            <button
              type="button"
              onClick={handleOnClick}
              className={style.btnClose}
              aria-label="close"
            >
              <span className={style.close} />
            </button>
          </div>
        )
      }
    </>
  );
};

export default HeaderNotification;
