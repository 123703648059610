import React from 'react';
import cn from 'classnames';

import { MessageInterface } from 'types/componentTypes';
import styles from 'components/Messages/Messages.module.scss';

const TimestampMessage: React.FC<MessageInterface> = ({ message }) => {
  const messageContainer = cn(
    styles.messageContainer,
    styles.justifyCenter,
    styles.messagesMt,
    styles.timestamp,
  );

  return (
    <div className={messageContainer}>
      <span className={styles.timestampMessageBox}>{message}</span>
    </div>
  );
};

export default TimestampMessage;
