import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import {
  NotificationType,
  Status,
  View,
} from 'constants/enums';
import { getTimeActiveChat } from 'src/utils/dateHandler';
import Timer from 'components/Timer/Timer';
import PsychicTimer from 'components/Timer/PsychicTimer';
import {
  INITIAL_CHAT_TIMER_VALUE,
  STEP_FOR_BILLING_TIMER,
} from 'constants/constants';
import { removeOfflineChatHandler as _removeOfflineChatHandler } from 'actions/offlineChatActions';
import {
  selectCurrentChat,
  selectDiffInTime,
  selectIsChatInInitState,
  selectIsLiveChatActive,
  selectNotifications,
  selectOfflineChats,
  selectTimeLimit,
  selectView,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import styles from 'components/Buttons/ConversationButton/styles.module.scss';

const TimerContainer = ({
  isConversation = false,
  isLiveChat = false,
  timeLeft = null,
}) => {
  const [min, setMin] = useState(0);
  const [second, setSecond] = useState(0);
  const [isStartTimer, setIsStartTimer] = useState<boolean>(false);
  const [isCloseEnd, setIsCloseEnd] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const timer = useRef<number>(null);

  const removeOfflineChatHandler = useBindDispatch(_removeOfflineChatHandler);

  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const currentChat = useSelector(selectCurrentChat);
  const view = useSelector(selectView);
  const timeLimit = useSelector(selectTimeLimit);
  const diffInTime = useSelector(selectDiffInTime);
  const offlineChats = useSelector(selectOfflineChats);
  const notifications = useSelector(selectNotifications);
  const isChatInInitState = useSelector(selectIsChatInInitState);

  const checkChatEndNotification = (notificationType): boolean => {
    switch (notificationType) {
      case NotificationType.INACTIVE_CHAT_ENDED:
        return true;
      case NotificationType.CHAT_ENDED_INSUFFICIENT_FUNDS:
        return true;
      case NotificationType.CHAT_ENDED_LOW_FUNDS:
        return true;
      case NotificationType.CHAT_ENDED:
        return true;
      default: return false;
    }
  };

  const removeOfflineChat = (customerRefIdEnc) => {
    const chat = offlineChats.find((chat) => {
      if (chat?.customerRefIdEnc === customerRefIdEnc) {
        return chat;
      }
    });

    if (!!chat && !!chat.chatId) {
      removeOfflineChatHandler(chat.chatId);
    }
  };

  useEffect(() => {
    const { messages, customerRefIdEnc } = currentChat;

    if (!messages || !messages?.length || isStartTimer) {
      return undefined;
    }

    const billingMessages = messages.filter((message) => message?.attributes
        && message?.attributes?.isBillingStarted);

    const finishedChatMessages = messages.filter((message) => {
      if (!message?.attributes) return false;

      const { notificationType } = message?.attributes;

      if (!notificationType) {
        return false;
      }

      return checkChatEndNotification(notificationType);
    });

    // Checking the last billing message and the
    // last finished chat message to start the timer
    if (billingMessages?.length) {
      const lastBillingMessage = billingMessages[billingMessages.length - 1];
      const dateLastBillingMessage = new Date(lastBillingMessage?.timestamp);

      if (!finishedChatMessages?.length) {
        setIsStartTimer(true);
        setStartDate(dateLastBillingMessage?.getTime());
      } else {
        const lastFinishedMessage = finishedChatMessages[finishedChatMessages?.length - 1];
        const dateLastFinishedChatMessage = new Date(lastFinishedMessage?.timestamp);
        const chatEndedNotification = notifications?.length
          && notifications[notifications.length - 1].attributes.type
          === NotificationType.CHAT_COMPLETED;
        const isBilling = dateLastBillingMessage > dateLastFinishedChatMessage
          && !chatEndedNotification;

        setIsStartTimer(isBilling);
        setStartDate(isBilling ? dateLastBillingMessage?.getTime() : null);

        // Remove offline message if billing was started
        if (isBilling) {
          removeOfflineChat(customerRefIdEnc);
        }
      }
    }
  }, [currentChat?.messages, isStartTimer]);

  /**
   * Restore timer after page refresh
  */
  useEffect(() => {
    const { startedDate, status } = currentChat || {};

    if (startedDate && status === Status.STARTED) {
      const chatActivityTimeInSeconds = getTimeActiveChat(startedDate);
      const initialChatTimerValueInSeconds = INITIAL_CHAT_TIMER_VALUE / 100;

      if (chatActivityTimeInSeconds !== 0 && chatActivityTimeInSeconds > 60) {
        setMin(Math.floor((chatActivityTimeInSeconds + initialChatTimerValueInSeconds) / 60));
        setIsStartTimer(true);
      }

      console.log('Timer condition 148', chatActivityTimeInSeconds);

      setStartDate(new Date(startedDate).getTime());
      setSecond(chatActivityTimeInSeconds);
    }
  }, [currentChat?.startedDate]);

  useEffect(() => {
    const initialChatTimerValueInSeconds = INITIAL_CHAT_TIMER_VALUE / 100;
    const isNotCurrentMin = Math.trunc((second + initialChatTimerValueInSeconds) / 60) !== min;
    const isAddMinute = second > min * 60;

    if (isAddMinute && isNotCurrentMin) {
      setMin((prevValue) => prevValue + 1);
    }
  }, [second, min]);

  /**
   * Start the self-regulating timer
  */
  useEffect(() => {
    if (min === 0 && isStartTimer) {
      setMin(1);
    }

    const startedChatDate = currentChat?.startedDate || startDate;
    const activeChatTime = startedChatDate && getTimeActiveChat(startedChatDate);

    let time = activeChatTime
      ? (Math.floor(activeChatTime * 1000) + INITIAL_CHAT_TIMER_VALUE)
      : STEP_FOR_BILLING_TIMER;

    const step = () => {
      if (!isStartTimer || !startedChatDate) {
        return;
      }

      time += STEP_FOR_BILLING_TIMER;

      let diff = 0;
      const currentTime = new Date();
      const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffInTime);

      diff = (new Date(correctedTime).getTime() - startDate) - time;

      if (timer.current) {
        clearTimeout(timer.current);
      }

      const sec = time / 1000;

      setSecond(sec);

      timer.current = setTimeout(
        step,
        (STEP_FOR_BILLING_TIMER - diff),
      );
    };

    step();

    return () => clearTimeout(timer.current);
  }, [isStartTimer, startDate, diffInTime, timeLimit, currentChat]);

  useEffect(() => {
    if (timeLimit) {
      setIsCloseEnd((min + 2 >= timeLimit));

      if (min >= timeLimit) setMin(timeLimit);
    }
  }, [min, timeLimit]);

  useEffect(() => {
    if (!isLiveChatActive) {
      setSecond(0);
      setMin(0);
      setIsStartTimer(false);
    }
  }, [isLiveChatActive]);

  if (view === View.PSYCHIC && !isLiveChat) {
    return (
      <span className={styles.conversationTimeRemaining}>{`${timeLeft}`}</span>
    );
  }

  if (view === View.PSYCHIC && timeLimit) {
    return (
      <PsychicTimer
        isConversation={isConversation}
        isLiveChatActive={isLiveChatActive}
        timeLimit={timeLimit}
        isCloseEnd={isCloseEnd}
        timeAmount={min}
      />
    );
  }

  return <Timer min={min} isDisabled={isChatInInitState} />;
};

export default TimerContainer;
