import React, { useMemo, useRef } from 'react';
import { Button } from '@mui/material';
import ContentEditable from 'react-contenteditable';
import cn from 'classnames';

import disabledSendButton from 'src/assets/png/clientNoteSend.png';
import activeSendButton from 'src/assets/svg/active-send-button.svg';
import activeSendCustomerButton from 'src/assets/svg/active-send-customer-button.svg';
import { checkPhoneOrEmailInMessageWithoutHotlineNumber } from 'src/utils/messageHandler';
import { PHONE_REGEX } from 'constants/constants';
import HistoryTipsBanner from 'components/PsychicNotifications/HistoryTipsBanner';
import styles from 'components/Forms/ClientNotesForm/ClientNoteForm.module.scss';

const ClientNoteForm = ({
  isValidMessage,
  handleSendButton,
  setMessageBody,
  messageBody,
  isPsychicView,
  psychicDetail,
  isTextBoxVisible,
  isHistoryTipsBannerVisible,
  priorityClientsActionTooltipText5,
}) => {
  const fileInputRef = useRef(null);
  const targetWord = checkPhoneOrEmailInMessageWithoutHotlineNumber(messageBody);

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !isValidMessage) {
      handleSendButton();
    }
  };

  const filterMessage = useMemo(() => (message) => {
    if (!targetWord?.[0]) {
      return message;
    }

    const regexPhone = new RegExp(`(${PHONE_REGEX.source})`, 'gi');

    return message.replace(regexPhone, (match) => (targetWord.includes(match)
      ? `<span style="background-color: #fbe586">${match}</span>`
      : match));
  }, [targetWord]);

  const handleChangeNotificationText = (e) => {
    const message = e.currentTarget.innerText;
    setMessageBody(message);
  };

  const sendButtonIcon = useMemo(() => {
    if (messageBody && !isValidMessage) {
      return isPsychicView
        ? activeSendButton
        : activeSendCustomerButton;
    }

    return disabledSendButton;
  }, [isValidMessage, messageBody, isPsychicView]);

  if (!isTextBoxVisible) {
    return null;
  }

  return (
    <div className={styles.clientNoteInputWrapper}>
      <HistoryTipsBanner
        isVisible={isHistoryTipsBannerVisible}
        label={priorityClientsActionTooltipText5}
      />
      <div className={styles.clientNoteInputContainer}>
        <ContentEditable
          className={styles.messageBody}
          html={filterMessage(messageBody)}
          onKeyUp={handleKeyUp}
          onChange={handleChangeNotificationText}
        />
        {!messageBody
          && (
            <div className={cn(styles.placeholder, { [styles.customer]: !isPsychicView })}>
              <span>{isPsychicView ? 'Type your note…' : `Send Note to ${psychicDetail.lineName}`}</span>
            </div>
          )}
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        <div className={styles.messageLimit}>
          <Button
            onClick={handleSendButton}
            disabled={isValidMessage}
          >
            <img alt="send button" src={sendButtonIcon} className={styles.clientNoteSend} /> <br />
          </Button>
          {isPsychicView
            ? <span>{`${300 - messageBody.length}`}/300</span>
            : <span>{`${160 - messageBody.length}`}/160</span>}
        </div>
      </div>
    </div>
  );
};

export default ClientNoteForm;
