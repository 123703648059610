import React, {
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IncomingChatRequestPsychic } from 'extracted-chat-components';

import { LocalStorage } from 'src/utils/storageHandler';
import Appointment from 'components/Appointment';
import { SdkEvents, InternetConnectionEvents } from 'constants/enums';
import {
  CHAT_REMAINING_TIME,
  START_TIME_VALUE,
  IS_MOBILE_INTEGRATION,
} from 'constants/constants';
import {
  acceptCustomerChatRequest as _acceptCustomerChatRequest,
  deleteCurrentChannel as _deleteCurrentChannel,
  handleCreateChatRequest as _handleCreateChatRequest,
  cleanAfterIgnoreChatRequest as _cleanAfterIgnoreChatRequest,
  muteChimeRequest as _muteChimeRequest,
} from 'actions/chatActions';
import { useChatCreatedRequest } from 'src/hooks/notification.hook';
import { formatToUSMonthDay, formatToUSShortMonth } from 'src/utils/dateHandler';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import {
  selectAppointment,
  selectChatId,
  selectCommand,
  selectInternetConnectionStatus,
  selectIsShownIncomingRequest,
  selectNotifications,
  selectSdkCallbackStorage,
  selectUserAgent,
} from 'selectors/selectors';
import { setIsShownIncomingRequest as _setIsShownIncomingRequest } from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { getPlatform } from 'src/utils/commonUtil';
import { selectClientNotesDraftToast } from 'selectors/psychicNotificationsSelectors';

const SET_USER_DATA = 'setUserData';
const CHANGE_TIME = 'changeTime';
const SET_TIME = 'setTime';

const initialState = {
  seconds: CHAT_REMAINING_TIME,
  tens: START_TIME_VALUE,
  units: START_TIME_VALUE,
  dateOfBirth: '-',
  lastReading: '-',
  name: '-',
  horoSign: '-',
};

// TODO Add to store

const applyTens = (seconds: number) => ((seconds > 9)
  ? seconds.toString()[0]
  : '0');
const applyUnits = (seconds: number) => ((seconds > 9)
  ? seconds.toString()[1]
  : seconds.toString()[0]);

const reducer = (state, action: { type: string, payload?: any }) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TIME: return {
      ...state,
      seconds: payload,
      tens: applyTens(payload),
      units: applyUnits(payload),
    };
    case CHANGE_TIME: {
      const currentSeconds = state.seconds - 1;

      return {
        ...state,
        seconds: currentSeconds,
        tens: applyTens(currentSeconds),
        units: applyUnits(currentSeconds),
      };
    }
    case SET_USER_DATA: return {
      ...state,
      dateOfBirth: payload.dateOfBirth,
      lastReading: payload.lastReading,
      name: payload.firstName,
      horoSign: payload.horoSign,
    };
    default: return state;
  }
};

let interval;
const InitChatPsychicPopup = () => {
  const acceptCustomerChatRequest = useBindDispatch(_acceptCustomerChatRequest);
  const deleteCurrentChannel = useBindDispatch(_deleteCurrentChannel);
  const handleCreateChatRequest = useBindDispatch(_handleCreateChatRequest);
  const cleanAfterIgnoreChatRequest = useBindDispatch(_cleanAfterIgnoreChatRequest);
  const muteChimeRequest = useBindDispatch(_muteChimeRequest);
  const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

  const userAgent = useSelector(selectUserAgent);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const isShownIncomingRequest = useSelector(selectIsShownIncomingRequest);
  const notifications = useSelector(selectNotifications);
  const chatId = useSelector(selectChatId);
  const command = useSelector(selectCommand);
  const appointment = useSelector(selectAppointment);
  const clientNotesDraftToast = useSelector(selectClientNotesDraftToast);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showMuted, setShowMuted] = useState(false);
  const internetConnectingStatus = useSelector(selectInternetConnectionStatus);
  const setIsShownIncomingRequest = useBindDispatch(_setIsShownIncomingRequest);
  const platform = getPlatform(userAgent);

  const { isToastVisible, draftedCutomerName } = clientNotesDraftToast;

  const cleanUpTimer = () => {
    clearInterval(interval);
    dispatch({ type: SET_TIME, payload: CHAT_REMAINING_TIME });
  };

  useEffect(() => {
    if (isShownIncomingRequest) {
      interval = setInterval(() => dispatch({ type: CHANGE_TIME }), 1000);
      setShowMuted(false);
    } else {
      cleanUpTimer();
    }

    return () => clearInterval(interval);
  }, [cleanAfterIgnoreChatRequest, isShownIncomingRequest]);

  useEffect(() => {
    if (state.seconds === 0) {
      setIsShownIncomingRequest(false);
      setTimeout(cleanAfterIgnoreChatRequest, 8000);
      cleanUpTimer();
    }
  }, [state.seconds, cleanAfterIgnoreChatRequest]);

  useEffect(() => {
    if (internetConnectingStatus === InternetConnectionEvents.OFFLINE) {
      setIsShownIncomingRequest(false);
    }
  }, [internetConnectingStatus]);

  useChatCreatedRequest((attributes, time: number) => {
    const setSecond = (currentTime) => dispatch({ type: SET_TIME, payload: currentTime });
    dispatch({
      type: SET_USER_DATA,
      payload: { ...attributes },
    });

    const receiveChatCb = sdkCallbackStorage.get(SdkEvents.ON_RECEIVE_CHAT);

    if (receiveChatCb) {
      const data = {
        seconds: time,
        tens: applyTens(time),
        units: applyUnits(time),
        isShownIncomingRequest: true,
        user: {
          dateOfBirth: `DOB: ${formatToUSMonthDay(attributes.dateOfBirth) || '-'}`,
          horoSign: attributes.horoSign,
          imageSrc: zodiacMap.get(state.horoSign?.toLocaleLowerCase()),
          name: attributes.firstName,
          lastReading: `Last Reading: ${attributes.lastReading
            ? formatToUSShortMonth(attributes.lastReading)
            : 'New to you'}`,
        },
      };
      receiveChatCb({ name: SdkEvents.ON_RECEIVE_CHAT, data });
    }

    handleCreateChatRequest({ attributes, setSecond, time });
  }, notifications, internetConnectingStatus);

  const handleAccept = () => {
    clearInterval(interval);
    deleteCurrentChannel();
    const timePassed = Math.trunc(CHAT_REMAINING_TIME - state.seconds);

    if (chatId) {
      acceptCustomerChatRequest({ chatId, timePassed, platform });
    }
  };

  const muteChime = () => {
    if (chatId) {
      muteChimeRequest();
      setShowMuted(true);
    }
  };

  useEffect(() => {
    if (isShownIncomingRequest) {
      document.body.style.overflowY = 'hidden';
    }
  }, [isShownIncomingRequest]);

  useEffect(() => {
    if (command === SdkEvents.ON_CHAT_EVENT) {
      const acceptCallback = sdkCallbackStorage.get(SdkEvents.ACCEPT_CHAT);

      if (acceptCallback) {
        clearInterval(interval);
        deleteCurrentChannel();
        const timePassed = Math.trunc(CHAT_REMAINING_TIME - state.seconds);

        if (chatId) acceptCustomerChatRequest({ chatId, timePassed, platform });
      }
    }
  }, [command, sdkCallbackStorage, chatId]);

  // TODO move to helpers
  const buildedDateOfBirth = useMemo(
    () => `DOB: ${formatToUSMonthDay(state.dateOfBirth) || '-'}`,
    [state.dateOfBirth],
  );

  const buildedLastReading = useMemo(
    () => `Last Reading: ${state.lastReading
      ? formatToUSShortMonth(state.lastReading)
      : 'New to you'}`,
    [state.lastReading],
  );

  if (!isShownIncomingRequest) {
    return null;
  }

  return (
    <IncomingChatRequestPsychic
      tens={state.tens}
      isMobileIntegration={isMobileIntegration}
      units={state.units}
      isShownIncomingRequest={isShownIncomingRequest}
      handleAccept={handleAccept}
      dateOfBirth={buildedDateOfBirth}
      horoSign={state.horoSign}
      muteChime={muteChime}
      showMuted={showMuted}
      // @ts-ignore
      imageSrc={zodiacMap.get(state.horoSign?.toLocaleLowerCase())}
      name={state.name}
      lastReading={buildedLastReading}
      appointment={appointment}
      isToastVisible={isToastVisible}
      draftedCutomerName={draftedCutomerName}
      AppointmentComponent={Appointment}
    />
  );
};

export default InitChatPsychicPopup;
