import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsNotificationActive } from 'selectors/psychicNotificationsSelectors';
import { setActivePage as _setActivePage } from 'src/redux/actions/appActions';
import { PAGE_ID } from 'constants/constants';
import useBindDispatch from 'src/hooks/useBindDispatch';
import styles from 'components/Popups/PsychicNotificationNotActivePopup/PsychicNotificationNotActivePopup.module.scss';

const PsychicNotificationNotActivePopup = () => {
  const isNotificationActive = useSelector(selectIsNotificationActive);
  const setActivePage = useBindDispatch(_setActivePage);

  if (isNotificationActive) {
    return null;
  }

  const onChangeTabHandler = (e) => {
    const { id } = e.currentTarget;
    setActivePage(id);
  };

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div>
          <span>
            This is a new feature that we are working on that will enable
          </span>
          <span>Psychics to send notifications to their clients.</span>
        </div>
        <div>
          <span>If you would like to find out more please contact</span>
          <a
            className={styles.mailAddress}
            href="mailto:pm@californiapsychics.com"
          >
            pm@californiapsychics.com
          </a>
        </div>
        <button
          type="button"
          id={PAGE_ID.CHATS_PAGE}
          className={styles.okButton}
          onClick={onChangeTabHandler}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default PsychicNotificationNotActivePopup;
