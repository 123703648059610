import { API } from 'src/utils/api';

export const getActiveChatsForPsychic = async (userId: string) => {
  const responseActiveChats = await API.Psychic.getPsychicActiveLiveChat(userId);
  const responseOfflineChats = await API.Psychic.fetchOfflineChats(userId);

  const offlineChats = responseOfflineChats?.data?.chats?.map((chat) => {
    const {
      chatId,
      psychicRefId,
      customerRefIdEnc,
    } = chat || {};

    return {
      chatId,
      psychicRefId,
      customerRefIdEnc,
    };
  });

  return {
    activeChats: {
      isLiveChatActive: Boolean(responseActiveChats?.data?.chats?.length),
      offlineChats: {
        chats: offlineChats,
        length: offlineChats?.length || 0,
      },
    },
  };
};
