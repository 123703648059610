/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import cn from 'classnames';
import { InteractChatButton } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import BackArrow from 'assets/svg/icon-arrow_left.svg';
import { formatToUSMonthDay } from 'src/utils/dateHandler';
import { useWindowSize } from 'src/hooks/useIsMobile';

import style from '../ChatHeader/SecondaryHeader.module.scss';

const DirectMessageHeader = ({
  isSwitchedConversations,
  headerRef,
  setIsSwitchedConversations,
  sideUser,
  clientStatus,
  onKeyDown,
  isCurrentOfflineStarted,
  showDeclineOMPopUp,
  isDeclineOMEnabled,
}) => {
  const { width } = useWindowSize();

  const buildedDateOfBirth = `DOB: ${formatToUSMonthDay(sideUser?.userFormattedDOB!) || '-'}`;

  return (
    <div
      className={cn(
        style.pathToConversations,
        !isSwitchedConversations && style.none,
      )}
      ref={headerRef}
    >
      <div className={style.pathToConversationsOffline}>
        <img
          src={BackArrow}
          tabIndex={-1}
          onClick={() => setIsSwitchedConversations(false)}
          onKeyDown={onKeyDown}
          className={style.pathToConversationsOfflineBack}
          alt="<"
        />
        <span title={sideUser.friendlyName} className={style.pathToConversationsOfflineName}>
          {sideUser.friendlyName}
        </span>
        <div className={style.pathToConversationsOfflineBlock}>
          <span title={clientStatus} className={style.pathToConversationsOfflineBlockDOB}>
            {buildedDateOfBirth}
          </span>
          <img
            className={style.pathToConversationsOfflineBlockImage}
            src={zodiacMap.get(sideUser.horoSign?.toLocaleLowerCase() || '')}
            alt="Zodiac"
          />
          <span title={clientStatus} className={style.pathToConversationsOfflineBlockZodiacName}>
            {sideUser.horoSign}
          </span>
        </div>
      </div>
      {!!isCurrentOfflineStarted && isDeclineOMEnabled && (
        <InteractChatButton
          text="Decline Offline Message"
          onClick={showDeclineOMPopUp}
          isActive
          className={style.headerDeclineButton}
          shape={ButtonShape.ROUNDED}
          color={ButtonColor.ORANGE_OUTLINE}
          size={width <= 500 ? ButtonSize.XS : ButtonSize.S}
        />
      )}
    </div>
  );
};

export default DirectMessageHeader;
