import React from 'react';

import ReactionCount from 'components/PsychicNotifications/StatsView/ReactionCount';
import { reactionsMap } from 'src/utils/reactionImagesUtils';

const SequenceReaction = ({ reactionsList, isGamifyEnabled }) => {
  const reactionSequence = Array.from(reactionsMap.keys());

  return reactionsList
    .sort((a, b) => reactionSequence.indexOf(
      a.reactionType.toLowerCase(),
    ) - reactionSequence.indexOf(b.reactionType.toLowerCase()))
    .map((reaction) => <ReactionCount reaction={reaction} isGamifyEnabled={isGamifyEnabled} />);
};

export default SequenceReaction;
