import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { PNSendNotification } from 'types/objectTypes';
import {
  EMAIL_REGEX,
  PHONE_REGEX,
  NOTIFICATION_DRAFT_KEY,
  CUSTOMER_DETAILS_DRAFT_KEY,
  HOTLINE_NUMBER,
} from 'src/constants/constants';
import { selectConfirmationScreen } from 'selectors/selectors';
import { selectPsychicNoteConfigurableData, selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  getReadingCustomersList as _getReadingCustomersList,
  sendNotificationToCustomer as _sendNotificationToCustomer,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  sendBlockedContentToCs as _sendBlockedContentToCs,
  setCurrentPCNTab as _setCurrentPCNTab,
} from 'actions/psychicNotifications';
import ComposeNotification from 'components/Forms/CreatePsychicNotificationForm';
import {
  getCookie,
  deleteCookie,
  // addPsychicMixpanelEventButtonTapped,
  // addPsychicMixpanelEventAlertTapped,
  setCookie,
  simplyfyJsonArrayKeys,
  getValuesFromList,
  getCommonClients,
} from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
import { NotesSentLocation, PNTabs, View } from 'constants/enums';

const regexEmail = new RegExp(`${EMAIL_REGEX.source}`, 'gm');
const regexPhone = new RegExp(`${PHONE_REGEX.source}`, 'gm');

const CreatePsychicNotificationFormContainer = ({
  customerList,
  handlePsychicNoteScreens,
  setCustomerName,
}) => {
  const storedMessage = getCookie(NOTIFICATION_DRAFT_KEY);
  const storedCustomerDetails = getCookie(CUSTOMER_DETAILS_DRAFT_KEY);
  const draftMsg = storedMessage || null;
  const draftCustomer = storedCustomerDetails
    ? getCommonClients(customerList, JSON.parse(storedCustomerDetails)) : null;

  const [messageBody, setMessageBody] = useState<string>(draftMsg || '');
  const [flagPopupInfo, setFlagPopupInfo] = useState<string>('');
  const [fetchCustomer, setFetchCustomer] = useState<boolean>(false);

  const sendCustomerDetail = useSelector(selectSendCustomerDetail) || [];
  const isConfirmationScreenVisible = useSelector(selectConfirmationScreen);
  // const currentUser = useSelector(selectCurrentUser);
  const { psychicNotesSystemMessages } = useSelector(selectPsychicNoteConfigurableData);

  const getReadingCustomersList = useBindDispatch(_getReadingCustomersList);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const sendNotificationToCustomer = useBindDispatch(_sendNotificationToCustomer);
  const sendBlockedContentToCs = useBindDispatch(_sendBlockedContentToCs);
  const setCurrentPCNTab = useBindDispatch(_setCurrentPCNTab);

  useEffect(() => {
    if (isConfirmationScreenVisible) {
      setMessageBody('');
      setNotificationCustomerDetail(null);
      deleteCookie(NOTIFICATION_DRAFT_KEY);
      deleteCookie(CUSTOMER_DETAILS_DRAFT_KEY);
    }
  }, [isConfirmationScreenVisible]);

  useEffect(() => {
    if (!customerList.length && !fetchCustomer) {
      getReadingCustomersList();
      setFetchCustomer(true);
    }
  }, [customerList, fetchCustomer]);

  useEffect(() => {
    if (!sendCustomerDetail.length && draftCustomer) {
      setNotificationCustomerDetail(draftCustomer);
    } else {
      setCookie(CUSTOMER_DETAILS_DRAFT_KEY, JSON.stringify(sendCustomerDetail));
    }
  }, [customerList]);

  const handleSaveDraftButton = (e) => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'all notes',
    //   button_type: 'text',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
    handlePsychicNoteScreens(e);
    setCookie(CUSTOMER_DETAILS_DRAFT_KEY, JSON.stringify(sendCustomerDetail));
    setNotificationCustomerDetail(null);
    setCurrentPCNTab(PNTabs.ALL_NOTES_TAB);
  };

  const handleChangeNotificationText = (e) => {
    if (flagPopupInfo) {
      setFlagPopupInfo('');
    }

    const message = e.currentTarget.innerText;
    setMessageBody(message);
  };

  const handleDeleteDraftButton = (e) => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'delete draft & return to all notes',
    //   button_type: 'button',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
    deleteCookie(NOTIFICATION_DRAFT_KEY);
    deleteCookie(CUSTOMER_DETAILS_DRAFT_KEY);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail(null);
    setCurrentPCNTab(PNTabs.ALL_NOTES_TAB);
    setMessageBody('');
  };

  const handleSendButton = () => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'send',
    //   button_type: 'button',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
    const nonEmptyMessage = messageBody.trim();

    if (nonEmptyMessage) {
      const emails = messageBody.match(regexEmail) || [];

      const phoneNumbers = messageBody.replaceAll('+', '').match(regexPhone) || [];
      const hotlineNumbers = phoneNumbers.filter((phoneNumber) => {
        const phoneNumberWithoutSpaceChar = phoneNumber?.replace(/[^A-Za-z0-9]/g, '');

        return HOTLINE_NUMBER.includes(phoneNumberWithoutSpaceChar);
      });

      if (emails.length || (phoneNumbers.length !== hotlineNumbers.length)) {
        const {
          psychicMessageContentViolation,
        } = simplyfyJsonArrayKeys(psychicNotesSystemMessages);
        const customerIds = getValuesFromList(sendCustomerDetail, 'customerId', sendCustomerDetail.length);

        sendBlockedContentToCs(
          messageBody,
          customerIds,
        );
        setFlagPopupInfo(psychicMessageContentViolation);
      } else if (sendCustomerDetail && sendCustomerDetail[0].customerId) {
        const customersName = getValuesFromList(sendCustomerDetail, 'displayname', 5);

        const pushNotification: PNSendNotification = {
          sendBy: View.PSYCHIC,
          messageBody: nonEmptyMessage,
          notesSentLocation: NotesSentLocation.ComposeScreen,
          sendToCustomerList: sendCustomerDetail,
        };

        setCustomerName(customersName);
        sendNotificationToCustomer(pushNotification);
      }
    }
  };

  return (
    <ComposeNotification
      flagPopupInfo={flagPopupInfo}
      messageBody={messageBody}
      customerList={customerList}
      draftMsg={draftMsg}
      setMessageBody={setMessageBody}
      handleSendButton={handleSendButton}
      handleChangeNotificationText={handleChangeNotificationText}
      handleSaveDraftButton={handleSaveDraftButton}
      handleDeleteDraftButton={handleDeleteDraftButton}
    />
  );
};

export default CreatePsychicNotificationFormContainer;
