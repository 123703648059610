import React, { useState } from 'react';

import { SETTING_TABS, SETTING_TABS_ID } from 'constants/constants';
import TabPanel from 'components/TabPanel';
import { setIsDiagnosticPopupOpen as _setIsDiagnosticPopupOpen } from 'actions/diagnosticAction';
import useBindDispatch from 'src/hooks/useBindDispatch';

const Setting = () => {
  const [activeTab, setActiveTab] = useState(null);
  const setIsDiagnosticPopupOpen = useBindDispatch(_setIsDiagnosticPopupOpen);

  const onOpenDiagnosticPopup = () => {
    setIsDiagnosticPopupOpen(true);
  };

  const onClickHandler = (e) => {
    const { id } = e.currentTarget;

    if (id === SETTING_TABS_ID.RUN_DIAGNOSTIC) {
      onOpenDiagnosticPopup();
    } else {
      setActiveTab(id);
    }
  };

  return (
    <TabPanel
      activeTab={activeTab}
      onChangeTab={onClickHandler}
      tabs={SETTING_TABS}
      withBadges={false}
    >
      {/* TODO uncomment this component when you need configuration logic */}
      {/* Don’t forget to add tabs to constants SETTING_TABS_ID and SETTING_TABS */}
      {/* <SettingForm key={SETTING_TABS_ID.SETTINGS} /> */}
    </TabPanel>
  );
};

export default Setting;
