import { View } from 'extracted-chat-components/enums';
import { put, putResolve, take } from 'redux-saga/effects';

import { RESTORING_TWILIO_FAILED } from 'actions/actionsTypes';
import { getSystemChannel, restoreTwilioClient } from 'actions/appActions';
import { joinChannelRequest, setTwilioClient } from 'actions/chatActions';
import { TwilioError } from 'constants/enums';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* restoreTwilioClientWorker({
  payload,
}: ReturnType<typeof restoreTwilioClient>) {
  const {
    prevClient,
    newTwilioClient,
    channelSid,
    viewMode,
    missedNotificationsAmount,
  } = payload;

  try {
    yield putResolve(setTwilioClient(newTwilioClient));
    yield put(getSystemChannel(viewMode, missedNotificationsAmount));

    if (viewMode === View.PSYCHIC && channelSid) {
      joinChannelRequest(channelSid);
    }

    yield take(RESTORING_TWILIO_FAILED);

    yield put(setTwilioClient(prevClient));
  } catch (error) {
    SentryMethods.captureMessage(TwilioError.CANNOT_UPDATE_CLIENT);
  }
}
