import React from 'react';
import { ButtonSize, ButtonColor } from 'extracted-chat-components/enums';
import Modal from 'react-modal';
import cn from 'classnames';
import InteractChatButton from 'extracted-chat-components/src/components/InteractChatButton';
import { CloseButton } from 'extracted-chat-components';

import style from './styles.module.scss';

const LogoutPopup = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
  logout,
}) => {
  const { title = 'Logout', description = null } = popUpNotificationData || {};

  const onLogoutHandler = () => {
    showPopUpNotification(false);
    logout();
  };

  const onCancelHandler = () => {
    showPopUpNotification(false);
  };

  return (
    <Modal
      isOpen={isNotificationPopUp}
      className={style.modal}
      overlayClassName={style.overlay}
      ariaHideApp={false}
    >
      <div className={cn(style.wrapper, style.wrapperWidthBi)}>
        <CloseButton
          closeHandler={onCancelHandler}
          isShape={false}
          className={style.closeButton}
        />
        <span className={style.title}>{title}</span>
        <p className={style.description}>{description}</p>
        <div className={style.containerModalButtons}>
          <div className={style.containerModalButtonsAccept}>
            <InteractChatButton
              className={style.acceptButton}
              isActive
              text="Log Out"
              onClick={onLogoutHandler}
              size={ButtonSize.S}
              color={ButtonColor.WARNING}
            />
          </div>
          <div className={style.containerModalButtonsReject}>
            <InteractChatButton
              isActive
              className={style.rejectButton}
              text="Cancel"
              onClick={onCancelHandler}
              color={ButtonColor.WHITE_OUTLINE}
              size={ButtonSize.S}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutPopup;
