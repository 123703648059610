import { ChatType, MessageType } from 'types/objectTypes';

export const returnNewUpdatedMessages = (payload) => (message: MessageType) => {
  if (message.sid === payload?.message.sid) {
    return payload?.message;
  }

  return message;
};

export const returnArrayWithNewUpdatedMessage = (
  offlineChats: Array<ChatType>,
  payload: { chatId: string, message: any },
) => offlineChats.map((offlineChat) => {
  const newOfflineChat = offlineChat;

  if (newOfflineChat.chatId === payload.chatId) {
    newOfflineChat.messages = newOfflineChat.messages.map(returnNewUpdatedMessages(payload));
  }

  return newOfflineChat;
});

export const returnArrayWithNewMessage = (
  offlineChats: Array<ChatType>,
  payload: { chatId: string, message: any },
) => offlineChats.map((offlineChat) => {
  const newOfflineChat = offlineChat;

  if (newOfflineChat.chatId === payload.chatId) {
    newOfflineChat.messages = [...newOfflineChat.messages, payload.message];
  }

  return newOfflineChat;
});
