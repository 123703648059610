import React from 'react';

import { removeInlineNotificationFromLocalStorage } from 'src/utils/commonUtil';

export const useLowFundsHandler = (isLiveChatActive, setInlineNotification) => {
  const [isVisible, setVisibilityState] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!isLiveChatActive) {
      setVisibilityState(false);
      removeInlineNotificationFromLocalStorage();
      setInlineNotification('', null);
    }
  }, [isLiveChatActive]);

  return [isVisible, setVisibilityState];
};
