import React from 'react';

import NotificationListForMobile from 'components/PsychicNotifications/AllNotes/NotificationListForMobile';
import NotificationListForTable from 'components/PsychicNotifications/AllNotes/NotificationListForTable';

const NotificationList = ({
  isMobile,
  notification,
  rowIndex,
  isSelected,
  selectedLength,
  onSelectRowHandler,
  handlePsychicNoteScreens,
}) => (
  isMobile
    ? (
      <NotificationListForMobile
        rowIndex={rowIndex}
        key={notification.pCComID}
        notification={notification}
        handlePsychicNoteScreens={handlePsychicNoteScreens}
      />
    ) : (
      <NotificationListForTable
        rowIndex={rowIndex}
        selected={isSelected}
        key={notification.pCComID}
        notification={notification}
        selectedLength={selectedLength}
        onSelectRow={onSelectRowHandler}
        handlePsychicNoteScreens={handlePsychicNoteScreens}
      />
    )
);

export default NotificationList;
