import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import { ButtonColor, ButtonShape, ButtonSize } from 'constants/enums';
import { PaymentFailedDueToReachingLimitProps } from 'types/componentTypes';

const PaymentFailedDueToReachingLimit: React.FC<PaymentFailedDueToReachingLimitProps> = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
}) => {
  const acceptButton = {
    text: 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: showPopUpNotification.bind(null, false),
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
      isBoldText
    />
  );
};

export default PaymentFailedDueToReachingLimit;
