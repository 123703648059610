import React from 'react';
import cn from 'classnames';
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';

import StyledCheckbox from 'components/PsychicNotifications/CustomStyled/StyledCheckbox';
import styles from 'components/Table/TableHeader/CustomTableHead.module.scss';
import { PNOrderType } from 'src/constants/enums';
import { ICustomTableHead } from 'types/componentTypes';
import { useIsMobile } from 'src/hooks/useIsMobile';

import ClientTableHeader from '../ClientListTable/ClientTableHeader';

const CustomTableHead: React.FC<ICustomTableHead> = ({
  order,
  orderBy,
  rowCount = 0,
  tableHead,
  numSelected = 0,
  onSort,
  onSelectAllRowsHandler,
  isNotificationTab,
  isPriorityActionTab,
}) => {
  const isIndeterminate = numSelected && numSelected < rowCount;
  const isChecked = rowCount && numSelected === rowCount;
  const { isMobile } = useIsMobile();

  const checkAndApplyHeadRowClasses = (columnId) => (cn(
    styles.headRow,
    { [styles.headRowActiveSort]: (orderBy === columnId && !!order) || isPriorityActionTab },
  ));

  const renderSortableHeadLabel = ({ columnId, label }) => {
    const isOrderByColumn = orderBy === columnId;

    return (
      <TableSortLabel
        hideSortIcon={!order}
        active={isOrderByColumn && !!order}
        direction={isOrderByColumn ? order : PNOrderType.ASCENDING}
        onClick={() => onSort(columnId)}
      >
        {label}
      </TableSortLabel>
    );
  };

  if (isMobile) {
    return (
      <ClientTableHeader
        order={order}
        onSort={onSort}
        orderBy={orderBy}
        tableHead={tableHead}
        rowCount={rowCount}
        numSelected={numSelected}
        onSelectAllRowsHandler={onSelectAllRowsHandler}
        isNotificationTab={isNotificationTab}
        isPriorityActionTab={isPriorityActionTab}
      />
    );
  }

  return (
    <TableHead>
      {tableHead.length && (
        <TableRow className={styles.rowWrapper}>
          {(onSelectAllRowsHandler && !isPriorityActionTab) && (
            <TableCell align="left" padding="checkbox">
              {!isNotificationTab && !isMobile && (
                <StyledCheckbox
                  indeterminate={isIndeterminate}
                  checked={isChecked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
                    onSelectAllRowsHandler(event.target.checked)
                  )}
                />
              )}
            </TableCell>
          )}
          {tableHead.map((headCell) => (
            <TableCell
              key={headCell.columnId}
              align={isMobile ? 'left' : headCell.align}
              sortDirection={orderBy === headCell.columnId && !isPriorityActionTab && order}
              sx={{ width: headCell.width, minWidth: headCell.minWidth }}
              className={checkAndApplyHeadRowClasses(headCell.columnId)}
            >
              {(onSort && !isPriorityActionTab)
                ? renderSortableHeadLabel(headCell) : headCell.label}
            </TableCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
};

export default CustomTableHead;
