import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import {
  ClickAwayListener, Container, Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { ReactComponent as Icon } from 'src/assets/svg/info-icon-gradient.svg';
import { PAGE_ID, PRIORITY_ACTION_WIDGET_TEXT, PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { selectPriorityActionCount } from 'selectors/psychicNotificationsSelectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { selectIsLiveChatActive } from 'selectors/selectors';
import {
  getPriorityActionList as _getPriorityActionList,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  setActiveScreen as _setActiveScreen,
  setCurrentPCNTab as _setCurrentPCNTab,
} from 'src/redux/actions/psychicNotifications';
import {
  setConfirmationScreen as _setConfirmationScreen,
} from 'actions/appActions';
import { PNTabs } from 'constants/enums';
import TooltipContent from 'components/PsychicNotifications/PriorityActionWidget/TooltipContent';

import styles from './PriorityActionNotification.module.scss';

const PriorityActionNotification = () => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const priorityActionCount = useSelector(selectPriorityActionCount);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);

  const getPriorityActionList = useBindDispatch(_getPriorityActionList);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const setActiveScreen = useBindDispatch(_setActiveScreen);
  const setConfirmationScreen = useBindDispatch(_setConfirmationScreen);
  const setCurrentPCNTab = useBindDispatch(_setCurrentPCNTab);

  useEffect(() => {
    if (isTooltipOpen) {
      setTimeout(() => setIsTooltipOpen(false), 3000);
    }
  }, [isTooltipOpen]);

  const buttonText = useMemo(() => {
    let actionText = PRIORITY_ACTION_WIDGET_TEXT;

    if (priorityActionCount === 1) {
      actionText = actionText.replace('XX', '1').replace('Actions', 'Action');
    } else if (priorityActionCount > 25) {
      actionText = actionText.replace('XX', '+25');
    } else {
      actionText = actionText.replace('XX', priorityActionCount.toString());
    }

    return actionText;
  }, [priorityActionCount]);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const onChangePage = useCallback(() => {
    if (!isLiveChatActive) {
      getPriorityActionList();
      setActiveScreen(PSYCHIC_NOTE_TAB_ID.ALL_NOTE);
      setNotificationCustomerDetail(null);
      setConfirmationScreen(false);
      setCurrentPCNTab(PNTabs.PRIORITY_ACTION_TAB);
    }
  }, [isLiveChatActive]);

  if (!priorityActionCount) {
    return null;
  }

  return (
    <Container
      className={styles.wrapper}
    >
      <Button
        id={PAGE_ID.PSYCHIC_INBOX}
        className={styles.customButton}
        onClick={onChangePage}
      >
        <Icon className={styles.infoIcon} />
        {buttonText}
      </Button>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className={styles.tooltipContainer}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={(<TooltipContent />)}
            classes={{
              tooltip: styles.tooltip,
              popper: styles.tooltipPopper,
            }}
          >
            <Button
              className={styles.tooltipButton}
              onClick={handleTooltipOpen}
            >
              i
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Container>
  );
};

export default PriorityActionNotification;
