import { useEffect } from 'react';
import { View } from 'extracted-chat-components/enums';

import { SentryMethods } from 'src/utils/sentryMethods';
import { setCountryCodes as _setCountryCodes } from 'actions/chatActions';
import useBindDispatch from 'src/hooks/useBindDispatch';

export const useObtainCountryCodes = (view: View) => {
  const setCountryCodes = useBindDispatch(_setCountryCodes);

  const obtainCountryCodes = async (url: string): Promise<void> => {
    try {
      const response = await fetch(url);
      const data = await response.json();

      setCountryCodes(data);
    } catch (e) {
      SentryMethods.captureException(e);
    }
  };

  useEffect(() => {
    const isPsychic = view === View.PSYCHIC;
    const url = (
      isPsychic
        ? process.env.PSYCHIC_COUNTRY_CODES_URL : process.env.CUSTOMER_COUNTRY_CODES_URL
    ) as string;

    obtainCountryCodes(url);
  }, [view]);
};
