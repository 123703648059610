import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import { CustomerCancelOfflineChatInterface } from 'types/componentTypes';

const CustomerCancelOfflineChat: React.FC<CustomerCancelOfflineChatInterface> = ({
  chatId,
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
  removeCurrentOfflineChatHandler,
}) => {
  const okHandler = () => {
    showPopUpNotification(false);
    removeCurrentOfflineChatHandler(chatId);
  };

  const acceptButton = {
    text: 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: okHandler,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
    />
  );
};

export default CustomerCancelOfflineChat;
