import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import BreakPopUp from 'extracted-chat-components/src/components/BreakPopUp/BreakPopUp';

import { showPopUpNotification } from 'actions/appActions';
import { setBreak } from 'actions/statusToggleActions';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import { IAppState } from 'store';

const TakeBreak = ({
  popUpNotificationData,
}) => {
  const dispatch = useDispatch();
  const isNotificationPopUp = useSelector((state: IAppState) => state.app.isNotificationPopUp);
  const okHandler = (value: string) => {
    dispatch(setBreak(value));
    dispatch(showPopUpNotification(false));
  };

  const rejectHandler = () => {
    dispatch(showPopUpNotification(false));
  };

  const acceptButton = {
    text: 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: okHandler,
  };

  const rejectButton = {
    text: 'Cancel',
    color: ButtonColor.ONLY_TEXT,
    size: ButtonSize.XS,
    handler: rejectHandler,
  };

  return (
    <BreakPopUp
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      isTextNewLine={popUpNotificationData.isTextNewLine}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default TakeBreak;
