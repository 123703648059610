import React from 'react';
import { useSelector } from 'react-redux';

import { ENTER_KEY } from 'constants/constants';
import {
  setIsSwitchedConversations as _setIsSwitchedConversations,
  addPopUpNotification as _addPopUpNotification,
} from 'actions/appActions';
import { SecondaryHeaderInterface } from 'types/componentTypes';
import {
  selectIsLiveChatActive,
  selectIsSwitchedConversations,
  selectSideUser,
  selectCurrentChat,
  selectIsDeclineOMEnabled,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { ConversationType, PopUpNotificationType } from 'constants/enums';
import LiveChatHeader from 'components/Header/LiveChatHeader';
import DirectMessageHeader from 'components/Header/DirectMessageHeader';
import useStickyHeader from 'src/hooks/useStickyHeader';

const ChatHeader: React.FC<SecondaryHeaderInterface> = ({ requestType }) => {
  const setIsSwitchedConversations = useBindDispatch(_setIsSwitchedConversations);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);

  const currentChat = useSelector(selectCurrentChat);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const sideUser = useSelector(selectSideUser);
  const isDeclineOMEnabled = useSelector(selectIsDeclineOMEnabled);

  const clientStatus = isLiveChatActive ? 'connected' : 'not connected';
  const headerRef = React.useRef<HTMLDivElement>(null);

  useStickyHeader(headerRef);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      setIsSwitchedConversations(false);
    }
  };

  const isCurrentOfflineStarted = currentChat?.attributes?.isStarted;

  const showDeclineOMPopUp = () => {
    addPopUpNotification({
      isVisible: true,
      title: 'decline OM',
      notificationType: PopUpNotificationType.DECLINE_OFFLINE_MESSAGE,
    });
  };

  if (!sideUser) {
    return null;
  }

  if (requestType === ConversationType.LIVE_CHAT) {
    return (
      <LiveChatHeader
        friendlyName={sideUser.friendlyName}
        clientStatus={clientStatus}
        isLiveChatActive={isLiveChatActive}
        isSwitchedConversations={isSwitchedConversations}
        headerRef={headerRef}
      />
    );
  }

  return (
    <DirectMessageHeader
      headerRef={headerRef}
      clientStatus={clientStatus}
      isSwitchedConversations={isSwitchedConversations}
      setIsSwitchedConversations={setIsSwitchedConversations}
      onKeyDown={onKeyDown}
      isCurrentOfflineStarted={isCurrentOfflineStarted}
      showDeclineOMPopUp={showDeclineOMPopUp}
      isDeclineOMEnabled={isDeclineOMEnabled}
      sideUser={sideUser}
    />
  );
};

export default ChatHeader;
