import React from 'react';
import cn from 'classnames';

import { CustomTooltipProps } from 'types/componentTypes';
import styles from 'src/components/Tooltips/CustomTooltip/CustomTooltip.module.scss';

const CustomTooltip: React.FC<CustomTooltipProps> = ({ isOpen, classNames = '', children }) => {
  if (!isOpen) {
    return null;
  }

  const wrapperClass = cn(styles.tooltip, classNames);

  return (
    <div className={wrapperClass}>
      {children}
    </div>
  );
};

export default CustomTooltip;
