import React, {
  useCallback,
  useState,
} from 'react';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'extracted-chat-components/enums';
import { InteractChatButton } from 'extracted-chat-components';

import { setIsDiagnosticPopupOpen as _setIsDiagnosticPopupOpen } from 'actions/diagnosticAction';
import useBindDispatch from 'src/hooks/useBindDispatch';

import style from './styles.module.scss';

const SettingsPopover = () => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const setIsDiagnosticPopupOpen = useBindDispatch(_setIsDiagnosticPopupOpen);

  // useEffect(() => {
  //   if (scrollElement && isTooltipVisible && window.innerWidth <= MOBILE_MAX_WIDTH) {
  //     scrollElement.scroll(0, scrollElement.scrollHeight);
  //   }
  // }, []);

  const onOpenTooltipHandler = useCallback(() => {
    setTooltipVisible(!isTooltipVisible);
  }, [isTooltipVisible]);

  const onOpenDiagnosticPopup = () => {
    onOpenTooltipHandler();
    setIsDiagnosticPopupOpen(true);
  };

  return (
    <div className={style.container}>
      <button
        type="button"
        onClick={onOpenTooltipHandler}
        className={style.link}
      >
        Settings
        <i className={style.arrow} />
      </button>
      {isTooltipVisible && (
        <div className={style.tooltipContainer}>
          <InteractChatButton
            isActive
            text="Run Diagnostic"
            onClick={onOpenDiagnosticPopup}
            size={ButtonSize.M}
            shape={ButtonShape.ROUNDED}
            color={ButtonColor.LIGHT_OUTLINE}
            className={style.diagnosticButtonWrapper}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsPopover;
