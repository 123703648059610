import React from 'react';

import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import styles from 'components/Buttons/ComposeNotificationButton/ComposeNotificationButton.module.scss';

const ComposeNotificationButton = ({ sendCustomerDetail, onClick }) => {
  const buttonText = sendCustomerDetail 
  && sendCustomerDetail[0] 
  && (sendCustomerDetail.length === 1
    ? ` to ${sendCustomerDetail[0].displayname}`
    : ` to ${sendCustomerDetail.length}`);

  return (
    <button
      type="button"
      className={styles.sendNotificationButton}
      id={PSYCHIC_NOTE_TAB_ID.COMPOSE}
      onClick={onClick}
    >
      <span className={styles.customerName}>
        Send A Note{buttonText}
      </span>
    </button>
  );
};

export default ComposeNotificationButton;
