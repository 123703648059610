import React from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from 'store';
import BackgroundImage from 'assets/png/background-no_chats.png';

import style from './styles.module.scss';

const NoLiveChatBanner = () => {
  const name = useSelector((state: IAppState) => state.chat.currentUser.friendlyName);

  if (!name) {
    return <div className={style.displayNoneMediaPsychic} />;
  }

  return (
    <div className={style.notificationScreen}>
      <span className={style.notificationScreenTitle}>
        Hi
        {' '}
        {name}
        ! No Live Chat at the moment, but I see a Chat in your future.
      </span>
      <img
        className={style.notificationScreenImage}
        src={BackgroundImage}
        alt="No active Live Chat"
      />
    </div>
  );
};

export default NoLiveChatBanner;
