import React from 'react';
import {
  ListItem, ListItemAvatar, Avatar, Divider,
} from '@mui/material';
import cn from 'classnames';
import { MixpanelEvents } from 'extracted-chat-components/enums';

import Arrow from 'assets/svg/arrow-right-orange.svg';
import { setExtId as _setExtId } from 'actions/chatActions';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
} from 'actions/psychicNotifications';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { truncateString } from 'src/utils/commonUtil';
import { PNNotificationStatus } from 'constants/enums';
import { useIsMobile } from 'src/hooks/useIsMobile';
import MixpanelActions from 'src/utils/mixpanel';

import style from './PsychicNoteList.module.scss';

const PsychicNoteListItem = ({
  clientIcon,
  psychicName,
  messageBody,
  sendDate,
  psychic,
  handlePsychicNoteScreens,
  isRead,
  notificationStatus,
  isMobileForClientHub,
  sendBy,
}) => {
  const { isMobile } = useIsMobile();
  const setExtId = useBindDispatch(_setExtId);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const isCustomerMuted = notificationStatus === PNNotificationStatus.MUTED;
  const isMe = sendBy.toLowerCase() === 'customer';

  const psychicInfoProps = {
    psychic_favorite: psychic.psychic.isFavorite ? 'y' : 'n',
    psychic_bio_video: !!psychic.psychic.psychicVideoURL,
    psychic_id: psychic.psychic.extId,
    psychic_rate: psychic.psychic.customerPrice,
    psychic_name: psychic.psychic.lineName,
    psychic_rating: psychic.psychic.overallScore,
    is_test_psychic: psychic.psychic.isTestPsychic,
  };

  const handleOnClick = (e) => {
    setExtId(psychic.extId);
    setNotificationCustomerDetail([psychic]);
    getClientNoteHistory(psychic.pcComId);
    handlePsychicNoteScreens(e);

    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'psychic note',
      button_type: 'psychic note item',
      screen_name: 'psychic notes',
      screen_title: 'psychic notes',
      ...psychicInfoProps,
    });
  };

  const listItemClasses = cn(
    isCustomerMuted ? style.listFade : style.listItem,
    {
      [style.listMobile]: isMobile,
      [style.listUnread]: !isRead,
    },
  );

  return (
    <>
      <ListItem alignItems="center" className={listItemClasses}>
        <ListItemAvatar>
          <Avatar
            alt="Psychic"
            src={clientIcon}
            style={{ height: '50px', width: '50px', borderRadius: '10px' }}
          />
        </ListItemAvatar>
        <button
          type="button"
          id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
          className={style.listWrapper}
          onClick={handleOnClick}
        >
          <div className={style.list}>
            <div className={style.nameTextMsg}>
              <span className={style.listName}>{psychicName}</span>
              {isCustomerMuted
              && <span className={cn(style.mutedTag, style.mutedTagMobile)}>Muted</span>}
            </div>
            <div className={cn(style.messageBodyWrapper, { [style.unreadTagMessage]: !isRead })}>
              <div className={cn({ [style.unreadTag]: !isRead })} />
              <span className={style.sender}>{`${isMe ? 'You' : psychicName}`}</span>
              <span className={style.messageBody}>{`: ${truncateString(messageBody, 100)}`}</span>
            </div>
          </div>
          <span className={style.dateAndTime}>{sendDate}
            { isMobileForClientHub
            && (
              <img
                src={Arrow}
                className={style.arrow}
                alt=">"
              />
            )}
          </span>
        </button>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default PsychicNoteListItem;
