import React, { useMemo } from 'react';
import {
  Box, FormControlLabel, MenuItem, Select,
} from '@mui/material';
// import { useSelector } from 'react-redux';
import cn from 'classnames';

// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
// import { selectCurrentUser } from 'selectors/selectors';
import MuteSwitch from 'components/PsychicNotifications/CustomStyled/MuteSwitch';
import IButton from 'src/assets/svg/notification-mute.svg';
import { PNMuteStatus } from 'constants/enums';
import { ReactComponent as DropDownIcon } from 'src/assets/svg/drop-down-icon-mobile.svg';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';
import { IListFooterForMobile } from 'types/componentTypes';

import MuteInformation from './MuteInformation';

const ListFooterForMobile: React.FC<IListFooterForMobile> = (({
  page,
  count,
  showMuted,
  rowsPerPage,
  onChangePage,
  onChangeMuted,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  toggleMutedInfoHandler,
  isMuteInfoShown,
}) => {
  const endRowNumber = useMemo(() => {
    const rowNumber = page * rowsPerPage + rowsPerPage;

    return rowNumber > count ? count : rowNumber;
  }, [count, page, rowsPerPage]);

  const isNextButtonDisable = useMemo(() => {
    if ((count / rowsPerPage) < (page + 1)) {
      return true;
    }

    if (count % rowsPerPage === 0 && (count / rowsPerPage) < (page + 2)) {
      return true;
    }

    return false;
  }, [count, rowsPerPage, page]);

  const formLabelStyle = {
    px: 3,
    py: 1.5,
    top: 0,
    position: {
      md: 'absolute',
    },
  };

  const nextButton = cn(styles.nextButton, { [styles.disableButton]: isNextButtonDisable });
  const prevButton = cn(styles.prevButton, { [styles.disableButton]: page === 0 });
  // const currentUser = useSelector(selectCurrentUser);

  const nextButtonHandler = () => {
    onChangePage(page + 1);
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'next',
    //   button_type: 'button',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
  };

  const prevButtonHandler = () => {
    onChangePage(page - 1);
  };

  return (
    <>
      <Box className={styles.listFooterForMobile}>
        <div className={styles.contentWrapper}>
          <div className={styles.muteButton}>
            <img
              src={IButton}
              alt="toggle"
              role="presentation"
              className={styles.iButton}
              onClick={toggleMutedInfoHandler}
            />
            <FormControlLabel
              sx={formLabelStyle}
              control={<MuteSwitch checked={!showMuted} onChange={onChangeMuted} />}
              label={showMuted ? PNMuteStatus.SHOW : PNMuteStatus.HIDE}
              className={styles.toggleButton}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              onClick={nextButtonHandler}
              className={nextButton}
              disabled={isNextButtonDisable}
            >
              Next
            </button>

            <button
              type="button"
              onClick={prevButtonHandler}
              className={prevButton}
              disabled={!page}
            >
              Previous
            </button>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.dropDownWrapper}>
            <span className={styles.rowPerPage}>Rows per page: </span>
            <Select
              defaultValue={rowsPerPage}
              IconComponent={DropDownIcon}
              value={rowsPerPage}
              className={styles.dropDownBorder}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              classes={{
                selectMenu: styles.dropDown,
                icon: styles.dropDownIcon,
              }}
              MenuProps={{
                classes: {
                  paper: styles.dropDownMenu,
                  list: styles.dropDownList,
                },
              }}
              onChange={onChangeRowsPerPage}
            >
              {rowsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.rowsCount}>
            {`Showing ${endRowNumber} of ${count}`}
          </div>
        </div>
      </Box>

      <MuteInformation
        isMuteInfoShown={isMuteInfoShown}
        toggleMutedInfoHandler={toggleMutedInfoHandler}
      />
    </>
  );
}
);
export default ListFooterForMobile;
