import React from 'react';
import { PopUpNotificationType } from 'extracted-chat-components/enums';

import { messages } from 'src/services/messages';
import { API } from 'src/utils/api';
import { ChatDataType } from 'types/objectTypes';
import { useShowPopUpNotification } from 'src/hooks/useShowPopUpNotification';
import {
  setShowInitModal as _setShowInitModal,
  setIsChatInInitState as _setIsChatInInitState,
} from 'actions/appActions';
import { setInitChatBanner as _setInitChatBanner } from 'actions/chatActions';
import { UPCOMING_APPOINTMENT_REMINDER_TIME } from 'constants/constants';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { correctNow } from 'src/utils/dateHandler';
import { MixpanelMessages } from 'constants/enums';

export const useEndChatDueToAppointment = () => {
  const showPopUpNotification = useShowPopUpNotification();
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const setIsChatInInitState = useBindDispatch(_setIsChatInInitState);
  const setInitChatBanner = useBindDispatch(_setInitChatBanner);
  const [isCanceled, setCancel] = React.useState(false);

  const callback = React.useCallback(
    (
      appointment: ChatDataType['appointment'],
      chatId: string,
      psychicName: string,
    ) => {
      const appointmentTimestamp = new Date(
        +(appointment?.appointmentTimestamp as string),
      ).getTime();
      const timeToDefer = appointmentTimestamp - correctNow();
      const beforeFiveMinutesDelay = timeToDefer - UPCOMING_APPOINTMENT_REMINDER_TIME;
      const hasUpcomingAppointment = timeToDefer > 0 && beforeFiveMinutesDelay <= 0;

      if (hasUpcomingAppointment) {
        if (!isCanceled) {
          setShowInitModal(false);
          setIsChatInInitState(false);
          setInitChatBanner(false);
          showPopUpNotification({
            title: messages.notAvailablePsychic(psychicName),
            notificationType: PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
            reason: MixpanelMessages.UPCOMING_APPOINTMENT,
          });
          setCancel(true);
        }

        if (chatId) {
          API.Chat.cancelChat(chatId);
        }

        return true;
      }

      return false;
    },
    [setShowInitModal, showPopUpNotification, isCanceled],
  );

  return callback;
};
