import * as types from 'src/redux/actions/actionsTypes';
import { DeclineBodyType, MessageType } from 'types/objectTypes';

export const setIsOfflineChatStartHandler = (isOfflineChatStart: boolean, chatId: string) => ({
  type: types.SET_IS_OFFLINE_CHAT_START_HANDLER,
  payload: { isOfflineChatStart, chatId },
});

export const addParticipantOfflineMessageHandler = (message: any, chatId: string) => ({
  type: types.ADD_PARTICIPANT_OFFLINE_MESSAGE_HANDLER,
  payload: { message, chatId },
});

export const addParticipantOfflineMessageBackgroundHandler = (message: any, chatId: string) => ({
  type: types.ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER,
  payload: { message, chatId },
});

export const updateParticipantMessageHandler = (message: any) => ({
  type: types.UPDATE_PARTICIPANT_MESSAGE_HANDLER,
  payload: { message },
});

export const updateParticipantOfflineMessageHandler = (message: any, chatId: string) => ({
  type: types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_HANDLER,
  payload: { message, chatId },
});

export const updateParticipantOfflineMessageBackgroundHandler = (message: any, chatId: string) => ({
  type: types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER,
  payload: { message, chatId },
});

export const setMessagesOfflineHandler = (messages: Array<MessageType>, chatId: string) => ({
  type: types.SET_MESSAGES_OFFLINE_HANDLER,
  payload: { messages, chatId },
});

export const setMessagesBeforeOfflineHandler = (messages: Array<MessageType>, chatId: string) => ({
  type: types.SET_MESSAGES_BEFORE_OFFLINE_HANDLER,
  payload: { messages, chatId },
});

export const removeCurrentOrNotOfflineChatHandler = (chatId: string) => ({
  type: types.REMOVE_CURRENT_OR_NOT_OFFLINE_CHAT_HANDLER,
  payload: chatId,
});

export const removeCurrentOfflineChatHandler = (chatId: string) => ({
  type: types.REMOVE_CURRENT_OFFLINE_CHAT_HANDLER,
  payload: chatId,
});

export const removeOfflineChatHandler = (chatId: string) => ({
  type: types.REMOVE_OFFLINE_CHAT_HANDLER,
  payload: chatId,
});

export const closeOfflineChatHandler = (chatId: string) => ({
  type: types.CLOSE_OFFLINE_CHAT_HANDLER,
  payload: chatId,
});

export const setUserCancelOfflineChatHandler = (chatId: string) => ({
  type: types.SET_USER_CANCEL_OFFLINE_CHAT_HANDLER,
  payload: chatId,
});

export const handleOfflineMessageSending = (chatId: string, message: string) => ({
  type: types.HANDLE_OFFLINE_MESSAGE_SENDING,
  payload: { chatId, message },
});

export const setOfflineChatById = (chat, chatId, isCurrentChat, type) => ({
  type: types.OPEN_OFFLINE_CHAT_BY_ID,
  payload: {
    chat,
    chatId,
    isCurrentChat,
    type,
  },
});

export const declineOfflineMessage = (payload: DeclineBodyType) => ({
  type: types.DECLINE_OFFLINE_MESSAGE_REQUEST,
  payload,
});

export const readOfflineMessageHandler = (chatId: string) => ({
  type: types.READ_OFFLINE_MESSAGE_HANDLER,
  payload: { chatId },
});
