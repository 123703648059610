import { View } from 'extracted-chat-components/enums';
import {
  call,
  select,
  put,
  take,
} from 'redux-saga/effects';
import { PSYCHIC_ID_STORAGE } from 'extracted-chat-components/constants';

import {
  handleRequestError,
  setIsChatInInitState,
  setIsLiveChatActive,
  setShowInitModal,
  showPopUpNotification,
} from 'actions/appActions';
import { setCurrentChat, setExtId, sideUserRequest } from 'actions/chatActions';
import { selectCustomerId } from 'selectors/selectors';
import { API } from 'src/utils/api';
import { SET_SIDE_USER } from 'actions/actionsTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import { descriptionFromError } from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* restoreActiveChat() {
  try {
    const customerId = yield select(selectCustomerId);

    yield put(showPopUpNotification(false));

    const response = yield call(API.Customer.getCustomerActiveChat, customerId as string);
    const chat = response?.data?.chats[0];

    LocalStorage.setItem(PSYCHIC_ID_STORAGE, chat.psychicRefId);

    const isLightRequest = false;
    const isForce = true;

    yield put(sideUserRequest(chat.psychicRefId, View.CUSTOMER, isLightRequest, isForce));

    yield take(SET_SIDE_USER);
    yield put(setCurrentChat(chat));
    yield put(setExtId(chat.psychicRefId));
    yield put(setShowInitModal(false));
    yield put(setIsChatInInitState(false));
    yield put(setIsLiveChatActive(true));
  } catch (e) {
    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  }
}
