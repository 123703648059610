import React from 'react';
import cn from 'classnames';

import { IHighlightLabel } from 'types/componentTypes';

import styles from './HighlightedLabel.module.scss';

const HighlightLabel: React.FC<IHighlightLabel> = ({
  text,
  wordToBold,
  className = '',
}) => {
  const parts = text?.split(new RegExp(`(${wordToBold})`, 'gi'));

  return (
    <div className={cn(styles.labelText, className)}>
      {parts?.map((part) => (part.toLowerCase() === wordToBold.toLowerCase() ? (
        <strong key={part}>{part}</strong>
      ) : (
        part
      )))}
    </div>
  );
};

export default HighlightLabel;
