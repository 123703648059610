import { put } from 'redux-saga/effects';

import { View } from 'constants/enums';
import { SentryMethods } from 'src/utils/sentryMethods';
import { setCurrentUser } from 'actions/chatActions';

function setDataForSentry(user) {
  SentryMethods.setUser({
    id: user.customerRefIdEnc || user.extId,
    name: user.firstName || user.lineName,
  });
}

export function* setUser(user: any, participantType: View) {
  const currentUserData = user.data;

  setDataForSentry({ ...currentUserData });

  currentUserData.attributes = { participantType };
  participantType === View.CUSTOMER
    ? currentUserData.friendlyName = currentUserData?.firstName
    : currentUserData.friendlyName = currentUserData?.lineName;

  yield put(setCurrentUser(currentUserData));
}
