import React, { useMemo, useState } from 'react';

import { IMessageTemplate } from 'types/componentTypes';
import MessageTemplateButton from 'components/Buttons/MessageTemplateButton';

const MessageTemplate: React.FC<IMessageTemplate> = ({
  template,
  handleTemplateSelection,
  mixPanelText,
}) => {
  const [isSelected, setSelected] = useState<boolean>(false);

  const templateMessage = useMemo(() => (
    template.length > 30
      ? `${template.substring(0, 30)}...`
      : template
  ), [template]);

  const onClickTemplateHandler = () => {
    handleTemplateSelection(template, mixPanelText);

    setSelected(true);
    setTimeout(() => {
      setSelected(false);
    }, 5000);
  };

  return (
    <MessageTemplateButton
      isSelected={isSelected}
      templateMessage={templateMessage}
      onClick={onClickTemplateHandler}
    />
  );
};

export default MessageTemplate;
