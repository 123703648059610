import React from 'react';
import cn from 'classnames';

import style from './SideBarButton.module.scss';

const SideBarButton = ({
  title,
  image,
  isActive,
  onChangePage,
  pageId,
  disabled,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={cn(style.sidebarTabButton,
      {
        [style.sidebarTabButtonActive]: isActive,
      })}
    id={pageId}
    onClick={onChangePage}
  >
    <img src={image} alt="sidebar_button" />
    <span>{title}</span>
  </button>
);

export default SideBarButton;
