import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import { PopUpCaseCommonInterface } from 'types/componentTypes';

const LackOfCharacters: React.FC<PopUpCaseCommonInterface> = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
}) => {
  const okHandler = () => {
    showPopUpNotification(false);
  };

  const acceptButton = {
    text: 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: okHandler,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth
    />
  );
};

export default LackOfCharacters;
