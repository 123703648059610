import { MINIMUM_CHAT_DURATION } from 'constants/constants';

export const messages = {
  notAvailablePsychic: (
    name: string,
  ) => `We’re sorry, ${name} is not currently available for Chat. 
    Please try chatting with another Psychic or try again later.`,
  psychicOnBreak: `We’re sorry, the Psychic you are trying to connect with is currently on break. 
  Please check back later.`,
  logoutPrompt: () => 'Logging out of PDA will not change your current availability.\n'
    + 'If you wish to change your availability status, please use\n'
    + 'the ‘My Status’ toggles on the top left of this screen.',
  psychicEarlylogoutPrompt: () => `You are logging off prior to the end of your shift.
    To complete the log out process and help us set
    proper customer expectations, please select one
    of the options below regarding your status:`,
  switchToPhoneNotifier: (
    customerPrice: string | number,
  ) => `You will be charged $${customerPrice}/min for this call.`,
  lowFounds: (funds, time) => ({
    title: `Low Funds (${time} ${time > 1 ? 'minutes' : 'minute'} left)`,
    description: `Please add $${funds} to continue this reading for another ${MINIMUM_CHAT_DURATION} minutes.`,
  }),
  lowFundsInlineInChatCustomerSide: (
    sum,
    mins,
    time,
  ) => (`$${sum} added (${mins || MINIMUM_CHAT_DURATION} mins) ${time} \n thank you, enjoy your reading!`),
  lowFundsInlineInChatPsychicSide: (
    mins,
    time,
  ) => (`funds added (${mins || MINIMUM_CHAT_DURATION} mins) ${time}`),
  lowFundsSystemNotification: (time) => (`low funds notification ${time}`),
  insufficientFundsMessage: () => 'add funds request',
  addFundsInsufficientCase: (funds: number | string) => `$${funds} ADDED`,
  activeChatCustomerSide: (psychicName: string) => `You currently have an active Chat with Psychic ${psychicName}.
  You must finish your current reading before starting a new one.`,
  activePhoneCallCustomerSide: (psychicName: string) => `You currently have an active Call with Psychic ${psychicName}.
  You must finish your current reading before starting a new one.`,
  lowFundsPopupNotification: () => 'You don’t have enough in your account for this reading. Please add funds to your account balance to continue.',
};
