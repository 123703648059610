import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Card } from '@mui/material';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import {
  selectNotesHistory,
  selectSendCustomerDetail,
  selectPsychicNoteConfigurableData,
  selectNotesHistoryList,
  selectAllPsychicNotificationMute,
  selectCurrentPCNTab,
  selectPriorityActionMessages,
} from 'selectors/psychicNotificationsSelectors';
import {
  ClientHubStatus,
  ClientType,
  MixpanelEvents,
  NotesSentLocation,
  PNNotificationStatus, PNTabs, PopUpNotificationType, Status, View,
} from 'constants/enums';
import {
  CLIENT_NOTES_DRAFT_KEY,
  DRAFTED_CLIENT_NAME_KEY,
  EMAIL_REGEX,
  HOTLINE_NUMBER,
  IS_MOBILE_INTEGRATION,
  ONE_MINUTE,
  PHONE_REGEX,
  PYSCHIC_OPTED_OUT_MESSAGE,
} from 'constants/constants';
import { PNSendNotification } from 'types/objectTypes';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  sendNotificationToCustomer as _sendNotificationToCustomer,
  updateNoteReadStatus as _updateNoteReadStatus,
  getClientNoteHistory as _getClientNoteHistory,
  sendBlockedContentToCs as _sendBlockedContentToCs,
  updateCustomerNotification as _updateCustomerNotification,
  updateNotificationStatus as _updateNotificationStatus,
  setClientNotesDraftToast as _setClientNotesDraftToast,
} from 'actions/psychicNotifications';
import {
  sideUserRequest as _sideUserRequest,
  setCurrentPsychicPhoneStatus as _setCurrentPsychicPhoneStatus,
  setCurrentPsychicStatus as _setCurrentPsychicStatus,
} from 'actions/chatActions';
import {
  setConfirmationScreen as _setConfirmationScreen,
  addPopUpNotification as _addPopUpNotification,
} from 'actions/appActions';
import {
  selectConfirmationScreen,
  selectCustomerId,
  selectIsLoading,
  selectIsShownIncomingRequest,
  selectSideUser,
  selectView,
} from 'selectors/selectors';
import {
  deleteCookie,
  getDraftedNote,
  saveClientNoteToDraft,
  setCookie,
  simplyfyJsonArrayKeys,
} from 'src/utils/commonUtil';
import { formatDateForClientsNote } from 'src/utils/dateHandler';
import { LocalStorage } from 'src/utils/storageHandler';
import BackButton from 'components/Buttons/SaveDraftButton';
import FlaggedClientNotesPopup from 'components/Popups/FlaggedClientNotesPopup';
import ClientBlockInfoPopup from 'components/Popups/ClientBlockInfoPopup';
import ClientNoteForm from 'components/Forms/ClientNotesForm';
import ClientNotesHistory from 'components/PsychicNotifications/ClientNotes/ClientNotesHistory';
import ClientNotesHeaderForPsychic from 'components/Header/ClientNotesHeader/Psychic/ClientNotesHeaderForPsychic';
import ClientsHeaderForCustomer from 'components/Header/ClientNotesHeader/Customer/ClientNotesHeaderForCustomer';
import ChatBottomButtons from 'components/ChatBottomButtons';
import { useIsMobile } from 'src/hooks/useIsMobile';
import MixpanelActions from 'src/utils/mixpanel';
import { reactionsNameMap } from 'src/utils/reactionImagesUtils';
import { getPsychicInfoMixpanelForCustomer } from 'src/utils/helpers';
import NoteMessage from 'src/components/PsychicNotifications/ClientNotes/NoteMessage';

import styles from './ClientNotes.module.scss';

const regexEmail = new RegExp(`${EMAIL_REGEX.source}`, 'gm');
const regexPhone = new RegExp(`${PHONE_REGEX.source}`, 'gm');

const ClientNotesContainer = ({
  handlePsychicNoteScreens,
  nextPriorityAction,
  setCustomerName,
}) => {
  const sendCustomerDetail = useSelector(selectSendCustomerDetail) || [];
  const [messageBody, setMessageBody] = useState<string>(
    getDraftedNote(sendCustomerDetail[0]?.customerId),
  );
  const [isValidMessage, setValidMessage] = useState<boolean>(false);
  const [isNewUnReadMessage, setIsNewUnReadMessage] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const scrollableMessageWrapper = useRef<HTMLDivElement>(null);
  const [isDraftButtonClicked, setIsDraftButtonCliked] = useState<boolean>(false);

  const [isMuted, setMuted] = useState<boolean>(sendCustomerDetail[0].notificationStatus
    === PNNotificationStatus.MUTED);
  const isConfirmationScreenVisible = useSelector(selectConfirmationScreen);
  const {
    customerNotesCount,
    customerLastSendDateUTC,
    isClientAccessToPsychicNotes,
    isCustomerOnCall,
    clientLabels,
  } = useSelector(selectNotesHistory);
  const notesHistoryList = useSelector(selectNotesHistoryList);
  const {
    clientLastNoteLockedCycle,
    psychicNotesSystemMessages,
    clientSendNoteLimit,
    psychicNotificationRefreshEveryMins,
  } = useSelector(selectPsychicNoteConfigurableData);
  const view = useSelector(selectView);
  const sideUser = useSelector(selectSideUser);
  const customerId = useSelector(selectCustomerId);
  const isAllPsychicNotificationMute = useSelector(selectAllPsychicNotificationMute);
  const currentTab = useSelector(selectCurrentPCNTab);
  const isLoading = useSelector(selectIsLoading);
  const { isMobile } = useIsMobile();
  const priorityActionMessages = useSelector(selectPriorityActionMessages);
  const isShownIncomingRequest = useSelector(selectIsShownIncomingRequest);

  const sendPushNotification = useBindDispatch(_sendNotificationToCustomer);
  const setConfirmationScreen = useBindDispatch(_setConfirmationScreen);
  const updateNoteReadStatus = useBindDispatch(_updateNoteReadStatus);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const sendBlockedContentToCs = useBindDispatch(_sendBlockedContentToCs);
  const sideUserRequest = useBindDispatch(_sideUserRequest);
  const setCurrentPsychicStatus = useBindDispatch(_setCurrentPsychicStatus);
  const setCurrentPsychicPhoneStatus = useBindDispatch(_setCurrentPsychicPhoneStatus);
  const updateCustomerNotification = useBindDispatch(_updateCustomerNotification);
  const updateNotificationStatus = useBindDispatch(_updateNotificationStatus);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setClientNotesDraftToast = useBindDispatch(_setClientNotesDraftToast);

  const {
    pCComID, psychic, customerType,
  } = sendCustomerDetail[0];
  const lastSeen = customerLastSendDateUTC && formatDateForClientsNote(customerLastSendDateUTC);
  const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);
  const isPsychicView = View.PSYCHIC === view;
  const isPriorityActionTab = currentTab === PNTabs.PRIORITY_ACTION_TAB;
  const notesSentLocation = isPriorityActionTab ? NotesSentLocation.PriorityAction
    : NotesSentLocation.AllNotes;
  const backButtonText = isPriorityActionTab ? 'Priority Actions'
    : 'Back to All Inbox';

  const pcComIDForHistory = useMemo(() => {
    if (!sendCustomerDetail.length) return 0;

    const {
      pCComID: psychicPCComId,
      pcComId: customerPCComId,
    } = sendCustomerDetail[0];

    return isPsychicView ? psychicPCComId : customerPCComId;
  }, [isPsychicView, sendCustomerDetail]);

  const isHistoryTipsBannerVisible = useMemo(() => (
    isPsychicView && !notesHistoryList.length && customerType === ClientType.NEW && !isLoading
  ), [customerType, isLoading, isPsychicView, notesHistoryList.length]);

  useEffect(() => {
    if (!isPsychicView) {
      sideUserRequest(psychic.extId, View.CUSTOMER, false, true);
    }

    if (isMobileIntegration || !isPsychicView) {
      const refreshIntervalId = setInterval(async () => {
        getClientNoteHistory(pcComIDForHistory as number, false);

        if (!isPsychicView) {
          sideUserRequest(psychic.extId, View.CUSTOMER, false, true);
        }
      }, (ONE_MINUTE * psychicNotificationRefreshEveryMins));

      return () => clearInterval(refreshIntervalId);
    }
  }, [
    psychic,
    isPsychicView,
    pcComIDForHistory,
    isMobileIntegration,
    psychicNotificationRefreshEveryMins,
  ]);

  useEffect(() => {
    if (sideUser) {
      const { chatStatus, phoneStatus } = sideUser;

      setCurrentPsychicPhoneStatus(phoneStatus);
      setCurrentPsychicStatus(chatStatus);
    }
  }, [sideUser]);

  useEffect(() => {
    if (isConfirmationScreenVisible) {
      setMessageBody('');

      if (!nextPriorityAction) {
        setConfirmationScreen(false);
      }
    } else if (isPsychicView) {
      setCustomerName('');
    }
  }, [isConfirmationScreenVisible]);

  useEffect(() => {
    if (isValidMessage) {
      setValidMessage(false);
    }

    setMessageBody(messageBody.substring(0, isPsychicView ? 300 : 160));
  }, [messageBody]);

  useEffect(() => {
    if (isPsychicView && isNewUnReadMessage) {
      updateNoteReadStatus(pCComID);
    }
  }, [
    isNewUnReadMessage,
    isPsychicView,
    notesHistoryList,
    pCComID, psychic,
    updateCustomerNotification,
    updateNoteReadStatus,
  ]);

  useEffect(() => {
    if (!isPsychicView && pcComIDForHistory) {
      const payload = {
        action: 'Read',
        pcComId: pcComIDForHistory,
        pcComHistoryId: 0,
      };

      updateCustomerNotification(payload);
    }
  }, []);

  const {
    customerMessageRestrictedContentAlert,
    psychicMessageContentViolation,
    psychicMessageBlockClient,
    psychicMessageClientReachMaxSendnotesLimit,
    customerMessageReachMaxSendnotesLimit,
    customerMessageBlockClient,
    customerMessageFaqUrl: customerMessageFAQUrl,
    customerMessageAllpsychicMutedPopup,
  } = simplyfyJsonArrayKeys(psychicNotesSystemMessages);

  useEffect(() => {
    if (isPsychicView && isShownIncomingRequest && messageBody.trim()) {
      setClientNotesDraftToast(true, sendCustomerDetail[0]?.displayname);
      saveClientNoteToDraft(sendCustomerDetail[0]?.customerId, messageBody);
      setCookie(DRAFTED_CLIENT_NAME_KEY, sendCustomerDetail[0]?.displayname);
    }
  }, [isShownIncomingRequest]);

  useEffect(() => {
    if (isDraftButtonClicked) {
      setIsDraftButtonCliked(false);
    }
  }, [messageBody]);

  const handleMuteUnmutePsychic = (callBack = (e) => e) => {
    const payload = {
      customerId,
      extId: psychic.extId,
      psychicNotificationsAlert: isMuted,
      setMuted,
      setTooltipVisible: callBack,
    };

    if (isAllPsychicNotificationMute) {
      addPopUpNotification({
        isVisible: true,
        title: customerMessageAllpsychicMutedPopup.replace('PsychicName', psychic.lineName),
        notificationType: PopUpNotificationType.ALL_PSYCHIC_MUTED,
      });
    } else {
      updateNotificationStatus(payload);
    }
  };

  const handleSendButton = () => {
    const nonEmptyMessage = messageBody.trim();

    if (nonEmptyMessage) {
      const emails = messageBody.match(regexEmail) || [];

      const phoneNumbers = messageBody.replaceAll('+', '').match(regexPhone) || [];
      const hotlineNumbers = phoneNumbers.filter((phoneNumber) => {
        const phoneNumberWithoutSpaceChar = phoneNumber?.replace(/[^A-Za-z0-9]/g, '');

        return HOTLINE_NUMBER.includes(phoneNumberWithoutSpaceChar);
      });

      if (emails.length || phoneNumbers.length !== hotlineNumbers.length) {
        setValidMessage(true);
        sendBlockedContentToCs(
          messageBody,
          sendCustomerDetail[0].customerId,
        );
      } else if (!isPsychicView && isAllPsychicNotificationMute) {
        handleMuteUnmutePsychic();
      } else if (sendCustomerDetail
        && (sendCustomerDetail[0].customerId || sendCustomerDetail[0].extId)
        && isPsychicView ? !isMuted : true) {
        const pushNotification: PNSendNotification = {
          sendBy: isPsychicView ? View.PSYCHIC : View.CUSTOMER,
          messageBody: nonEmptyMessage,
          sendToCustomerList: sendCustomerDetail,
          notesSentLocation,
          extId: sendCustomerDetail[0].extId,
        };

        sendPushNotification(pushNotification, pcComIDForHistory);

        if (isPsychicView) {
          setCustomerName(sendCustomerDetail[0].displayname);

          if (!!getDraftedNote(sendCustomerDetail[0].customerId)) {
            deleteCookie(CLIENT_NOTES_DRAFT_KEY);
          }
        }

        if (!isPsychicView && isMuted) { handleMuteUnmutePsychic(); }
      }
    }
  };

  const systemMessage = useMemo(() => {
    const messages: Array<string> = [];

    if (psychicNotesSystemMessages) {
      if (!isPsychicView && !psychic.isClientNotificationsEnabled) {
        messages.push(PYSCHIC_OPTED_OUT_MESSAGE.replace('PsychicName', psychic.lineName));
      }

      if (customerNotesCount >= clientSendNoteLimit) {
        const message = isPsychicView
          ? psychicMessageClientReachMaxSendnotesLimit
          : customerMessageReachMaxSendnotesLimit;

        messages.push(message
          .replace('ClientLastNoteLockedCycle', clientLastNoteLockedCycle)
          .replace('ClientSendNoteLimit', clientSendNoteLimit));
      }

      if (isPsychicView && !isClientAccessToPsychicNotes && !isLoading) {
        messages.push(psychicMessageBlockClient);
      }

      if (!isPsychicView && !isClientAccessToPsychicNotes) {
        messages.push(customerMessageBlockClient);
      }
    }

    return isPsychicView ? [messages[0]] : messages.slice(0, 2);
  }, [
    clientLastNoteLockedCycle,
    clientSendNoteLimit,
    customerMessageBlockClient,
    customerMessageReachMaxSendnotesLimit,
    customerNotesCount,
    isPsychicView,
    psychicMessageBlockClient,
    psychicMessageClientReachMaxSendnotesLimit,
    psychicNotesSystemMessages,
    sendCustomerDetail,
    isClientAccessToPsychicNotes,
    psychic,
  ]);

  const { currentPsychicStatus, currentPhonePsychicStatus } = useMemo(() => {
    const status = {
      currentPsychicStatus: psychic?.chatStatus,
      currentPhonePsychicStatus: psychic?.lineStatus,
    };

    if (!isPsychicView && sideUser) {
      status.currentPsychicStatus = sideUser.chatStatus;
      status.currentPhonePsychicStatus = sideUser.phoneStatus;
    }

    return status;
  }, [isPsychicView, psychic, sideUser]);

  const psychicStatus = useMemo(() => !isPsychicView
  && !((currentPsychicStatus.toLowerCase() === Status.AVAILABLE
  && (currentPhonePsychicStatus === ClientHubStatus.ONCALL
    || currentPhonePsychicStatus === ClientHubStatus.OFFLINE))
  || currentPhonePsychicStatus.toLowerCase() === Status.AVAILABLE),
  [currentPhonePsychicStatus, currentPsychicStatus, isPsychicView]);

  const isTextBoxVisible = useMemo(() => (
    (!isMuted && isPsychicView) || (
      !isPsychicView
      && isClientAccessToPsychicNotes
      && psychicStatus
      && !(customerNotesCount >= clientSendNoteLimit)
      && psychic?.isClientNotificationsEnabled
      && !isCustomerOnCall
    )
  ), [
    isMuted,
    isPsychicView,
    customerNotesCount,
    clientSendNoteLimit,
    psychicStatus,
    isClientAccessToPsychicNotes,
    psychic,
    isCustomerOnCall,
  ]);

  const isCTAButtonVisible = useMemo(() => (
    (sideUser && !isTextBoxVisible) && !isPsychicView
  ), [isPsychicView, isTextBoxVisible, sideUser]);

  const handleNewUnreadMessage = (isNewUnRead: boolean) => {
    if (!isNewUnReadMessage) {
      setIsNewUnReadMessage(isNewUnRead);
    }
  };

  const makeEmergencyScroll = useCallback(() => {
    const element = scrollableMessageWrapper.current;

    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [scrollableMessageWrapper, scrollableMessageWrapper.current]);

  const handleOnSelect = useCallback((message, onBlur = false) => {
    if (onBlur) {
      setTimeout(() => {
        setSelectedMessage('');
        setIsSelected(false);
      }, 400);
    } else {
      setSelectedMessage(message);
      setIsSelected(true);
    }

    return true;
  }, []);

  const handleOnReact = useCallback((e) => {
    const { id } = e.currentTarget;
    const contentRating = reactionsNameMap.get(id);
    MixpanelActions.track(MixpanelEvents.CONTENT_RATED, {
      content_type: 'psychic note',
      content_id: selectedMessage.pCComID,
      content_rating: id ? contentRating : 'delete note',
      ...getPsychicInfoMixpanelForCustomer(sideUser),
    });

    const action = id ? 'AddReactions' : 'Delete';
    const payload = {
      action,
      pcComId: selectedMessage.pCComID,
      pcComHistoryId: selectedMessage.pCComHistoryId,
      reactionTypeId: id,
      handleOnSelect: handleOnSelect('', true),
    };

    updateCustomerNotification(payload);
  }, [handleOnSelect, selectedMessage, updateCustomerNotification]);

  const {
    priorityClientsActionTooltipText5,
  } = simplyfyJsonArrayKeys(priorityActionMessages);

  const handleDraftButton = () => {
    if (messageBody.trim()) {
      saveClientNoteToDraft(sendCustomerDetail[0].customerId, messageBody);
      setIsDraftButtonCliked(true);
    }
  };

  return (
    <div className={cn(styles.clientNotesMainContainer,
      { [styles.clientNotesMainContainerCustomer]: !isPsychicView })}
    >
      {isPsychicView ? (
        <>
          <BackButton
            isClientNotes
            title={backButtonText}
            onClick={handlePsychicNoteScreens}
            handleDraftButton={handleDraftButton}
            isDraftButtonClicked={isDraftButtonClicked}
          />
          <ClientNotesHeaderForPsychic
            lastSeen={lastSeen}
            clientLabels={clientLabels}
            headerDetails={sendCustomerDetail[0]}
          />
        </>
      ) : (
        <ClientsHeaderForCustomer
          headerDetails={sendCustomerDetail[0]}
          customerMessageFAQUrl={customerMessageFAQUrl}
          handlePsychicNoteScreens={handlePsychicNoteScreens}
          isMuted={isMuted || isAllPsychicNotificationMute}
          handleMuteUnmutePsychic={handleMuteUnmutePsychic}
        />
      )}
      {
        isSelected && (
          <NoteMessage
            message={selectedMessage}
            view={view}
            customerNotesCount={customerNotesCount}
            clientSendNoteLimit={clientSendNoteLimit}
            isSelected={isSelected}
            handleOnSelect={handleOnSelect}
            handleOnReact={handleOnReact}
            isPsychicView={isPsychicView}
          />
        )
      }
      <div className={cn(styles.clientNotesWrapper, { [styles.bgBlur]: selectedMessage })}>
        <Card className={cn(styles.clientNotesContainer, {
          [styles.clientNotesContainerPsychic]: isPsychicView,
        })}
        >
          <ClientNotesHistory
            notesHistoryList={notesHistoryList}
            isClientBlocked={systemMessage[0] && systemMessage.length}
            handleNewUnreadMessage={handleNewUnreadMessage}
            handleOnSelect={handleOnSelect}
            isTextBoxVisible={isTextBoxVisible}
            isPsychicView={isPsychicView}
            isCTAButtonVisible={isCTAButtonVisible}
          />
          <FlaggedClientNotesPopup
            isVisible={isValidMessage}
            popupInfo={isPsychicView
              ? psychicMessageContentViolation
              : customerMessageRestrictedContentAlert}
            className={cn(isMobile ? '' : styles.flaggedClientNote, {
              [styles.flaggedClientNoteClient]: !isPsychicView,
            })}
          />
          <div className={cn(styles.bottomPanel, {
            [styles.psychicForm]: isPsychicView,
            [styles.alertPopup]: !isTextBoxVisible && psychicStatus,
            [styles.customerForm]: !isCTAButtonVisible && !isPsychicView,
          })}
          >
            <ClientBlockInfoPopup
              systemMessages={systemMessage}
              isCustomer={!isPsychicView}
            />
            <div className={styles.customerButton}>
              {isCTAButtonVisible
                && <ChatBottomButtons makeEmergencyScroll={makeEmergencyScroll} isClientHub />}
            </div>
            <ClientNoteForm
              handleSendButton={handleSendButton}
              messageBody={messageBody}
              setMessageBody={setMessageBody}
              isValidMessage={isValidMessage}
              isPsychicView={isPsychicView}
              isTextBoxVisible={isTextBoxVisible}
              psychicDetail={sendCustomerDetail[0].psychic}
              isHistoryTipsBannerVisible={isHistoryTipsBannerVisible}
              priorityClientsActionTooltipText5={priorityClientsActionTooltipText5}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ClientNotesContainer;
