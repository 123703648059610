import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import { replaceBrTag } from 'src/utils/helpers';
import AutoReloadInfoTooltip from 'components/Tooltips/AutoReloadInfoTooltip';
import { simplyfyJsonArrayKeys } from 'src/utils/commonUtil';
import InfoIcon from 'src/assets/svg/info-icon.svg';

import style from './style.module.scss';

const EndChatDueToLowFundPopup = ({
  isOpen,
  customerName,
  psychicName,
  psychicImage,
  autoReloadLabels,
  isAutoReloadEnabled,
  onCloseHandler,
  autoReloadClickHandler,
}) => {
  const [isTooltip, setToolTip] = useState<boolean>(false);
  const elementRef = useRef(null);
  const closeButtonClass = isAutoReloadEnabled
    ? style.autoReloadEnabledCloseBtn
    : style.autoReloadDisabledCloseBtn;

  const {
    autoreloadOffBannerText1,
    autoreloadOffBannerText2,
    autoreloadOffTooltipText,
    autoreloadOffBannerText3,
    autoreloadOffBannerText31,
    autoreloadBuypackageOffText,
    autoreloadTurnOnButtonText,
    autoreloadOnBannerText1,
    autoreloadOnBannerText2,
    autoreloadOnBannerText21,
    autoreloadOnTooltipText,
    autoreloadBuypackageOnText,
    autoreloadTurnOffButtonText,
  } = simplyfyJsonArrayKeys(autoReloadLabels);

  const handleOnBlur = () => {
    setToolTip(false);
  };

  const toolTipFun = () => {
    setToolTip(!isTooltip);
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={style.overlay}
      ariaHideApp={false}
      className={style.modal}
    >
      <div className={style.modalInner}>
        <div className={style.headerWrapper}>
          <div className={cn(style.headerBg, style.headerBgImg)} />
          <div className={cn(style.headerBg, style.headerBgGradient)} />
          <div className={style.header}>
            <h1>Thank you, <span>{customerName}!</span></h1>
            <h2>Your Chat has now ended.</h2>
          </div>
        </div>
        <div className={style.psychicWrapper}>
          <div className={style.psychicImgWrapper}>
            <img
              src={psychicImage}
              className={style.psychicImg}
              alt="Ellipse"
            />
          </div>
          <div className={style.psychicHeading}>
            <p>You can rate your reading with {psychicName}.</p>
          </div>
        </div>
        <div className={style.gradientBottom}>
          <div className={style.autoReloadWrapper}>
            {
              isAutoReloadEnabled ? (
                <div className={style.autoReloadEnabled}>
                  <div>
                    <h2
                      className={style.autoReloadEnabledH2}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: autoreloadOnBannerText1,
                      }}
                    />
                  </div>
                  <h3 className={style.autoReloadEnabledH3}>
                    {autoreloadOnBannerText2}
                    {autoreloadOnBannerText21}
                    <AutoReloadInfoTooltip
                      isOpen={isTooltip}
                      text={autoreloadOnTooltipText}
                      isAutoReloadEnabled={isAutoReloadEnabled}
                    />
                    <button
                      type="button"
                      key="tooltip"
                      onClick={toolTipFun}
                      className={style.autoReloadTooltipEnabled}
                      onBlur={handleOnBlur}
                    ><img src={InfoIcon} alt="Info" />
                    </button>
                  </h3>
                  <h4 className={style.autoReloadEnabledH4}>{autoreloadBuypackageOnText}</h4>
                  <button
                    className={style.autoReloadDisabledButton}
                    onClick={autoReloadClickHandler}
                    type="button"
                  >
                    {autoreloadTurnOffButtonText}
                  </button>
                </div>
              ) : (
                <div className={style.autoReloadDisabled}>
                  <div ref={elementRef}>
                    <h1 className={style.autoReloadDisabledH1}>{autoreloadOffBannerText1}
                      <AutoReloadInfoTooltip
                        isOpen={isTooltip}
                        isAutoReloadEnabled={isAutoReloadEnabled}
                        text={replaceBrTag(autoreloadOffTooltipText)}
                      />
                      <button
                        type="button"
                        key="tooltip"
                        onBlur={handleOnBlur}
                        onClick={toolTipFun}
                        className={style.autoReloadTooltip}
                      >
                        <img src={InfoIcon} alt="Info" />
                      </button>
                    </h1>
                  </div>
                  <h2 className={style.autoReloadDisabledH2}>
                    {autoreloadOffBannerText2}
                  </h2>
                  <h3 className={style.autoReloadDisabledH3}>
                    {autoreloadOffBannerText3}
                    {autoreloadOffBannerText31}
                  </h3>
                  <h4 className={style.autoReloadDisabledH4}>{autoreloadBuypackageOffText}</h4>
                  <button
                    type="button"
                    className={style.autoReloadButton}
                    onClick={autoReloadClickHandler}
                  >
                    {autoreloadTurnOnButtonText}
                  </button>
                </div>
              )
            }
          </div>
          <div className={style.autoReloadDisabledCloseWrapper}>
            <button type="button" className={closeButtonClass} onClick={onCloseHandler}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EndChatDueToLowFundPopup;
