import React from 'react';
import cn from 'classnames';

import styles from 'components/Buttons/SendNotificationButton/SendNotificationButton.module.scss';

const SendNotificationButton = ({ onClick, isValidMessage }) => (
  <div className={styles.sendButtonWrapper}>
    <button
      type="button"
      className={cn(styles.sendButton, {
        [styles.sendButtonDisable]: isValidMessage,
      })}
      onClick={onClick}
    >
      Send
    </button>
  </div>
);

export default SendNotificationButton;
