import React from 'react';

import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import styles from 'components/Buttons/DeleteDraftButton/DeleteDraftButton.module.scss';

const DeleteDraftButton = ({ onClick }) => (
  <div className={styles.deleteDraftWrapper}>
    <button
      type="button"
      className={styles.deleteDraft}
      id={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
      onClick={onClick}
    >
      Delete Draft & Return to All Notes
    </button>
  </div>
);

export default DeleteDraftButton;
