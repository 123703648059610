import { LISTING_VIEW_TABLE_HEAD } from 'constants/constants';
import { DefaultOrderBy, PNTabs, View } from 'constants/enums';

import { formatDateForClientsNote } from './dateHandler';

export const getTableHead = (currentTab, isMobile) => {
  switch (currentTab) {
    case (PNTabs.ALL_NOTES_TAB):
      return LISTING_VIEW_TABLE_HEAD.NOTIFICATION_LIST;
    case PNTabs.PRIORITY_ACTION_TAB:
      return LISTING_VIEW_TABLE_HEAD.PRIORITY_ACTION_LIST;
    default:
      return isMobile ? LISTING_VIEW_TABLE_HEAD.CUSTOMER_LIST_MOBILE
        : LISTING_VIEW_TABLE_HEAD.CUSTOMER_LIST;
  }
};

export const tableUtil = (currentTab, isMobile, tableData, { page, rowsPerPage }) => {
  const tableHead = getTableHead(currentTab, isMobile);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentTableBody = tableData.slice(startIndex, endIndex);

  return { tableHead, currentTableBody };
};

export const getSeletctedTabData = (
  currentTab, {
    notificationList,
    priorityActionList,
    customerList,
  },
) => {
  switch (currentTab) {
    case PNTabs.ALL_NOTES_TAB:
      return {
        defaultOrderBy: DefaultOrderBy.ALL_NOTES_TAB,
        defaultRowsPerPage: 25,
        currentTabData: notificationList,
        rowCount: notificationList?.length,
      };
    case PNTabs.PRIORITY_ACTION_TAB:
      return {
        defaultOrderBy: DefaultOrderBy.PRIORITY_ACTION_TAB,
        defaultRowsPerPage: 5,
        currentTabData: priorityActionList,
        rowCount: priorityActionList?.length,
      };
    default:
      return {
        defaultOrderBy: DefaultOrderBy.CUSTOMER_LIST_TAB,
        defaultRowsPerPage: 25,
        currentTabData: customerList,
        rowCount: customerList?.length,
      };
  }
};

export const getReadReceiptTextAndFlag = (notesHistoryList, isPsychicView) => {
  if (!isPsychicView || notesHistoryList.length === 0) {
    return {
      isReadReceiptVisible: false,
      readReceiptText: '',
    };
  }

  const lastMessage = notesHistoryList[notesHistoryList.length - 1];

  const isReadReceiptVisible = lastMessage?.sendBy === View.PSYCHIC && !!lastMessage?.isRead;
  const readReceiptText = isReadReceiptVisible
    ? `Read ${formatDateForClientsNote(lastMessage.readDateUTC)}`
    : '';

  return {
    isReadReceiptVisible,
    readReceiptText,
  };
};
