import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import query from 'query-string';
import { LocationDescriptor } from 'history';

import { goTo } from 'route-history';
import { View } from 'constants/enums';
import {
  IS_SWITCH_TO_PHONE_BANNER,
  LOGIN_FIELDS_NAME,
  REDIRECT_PATH,
  TOKEN_AUTH_STORAGE,
  TOKEN_BEARER,
  TOKEN_TWILIO_STORAGE,
  VIEW,
} from 'constants/constants';
import {
  setRequestErrorState as _setRequestErrorState,
  setView as _setView,
} from 'actions/appActions';
import { receiveAuthData as _receiveAuthData } from 'actions/authActions';
import LoaderContainer from 'components/Loader';
import { IAppState } from 'store';
import { LocalStorage } from 'src/utils/storageHandler';
import { selectIsLoading, selectView } from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import Login from 'components/Login/Login';

const LoginFormContainer = () => {
  const isDev = process.env.NODE_ENV === 'development';
  const receiveAuthData = useBindDispatch(_receiveAuthData);
  const setView = useBindDispatch(_setView);
  const setRequestErrorState = useBindDispatch(_setRequestErrorState);

  const view = useSelector(selectView);
  const isLoading = useSelector(selectIsLoading);
  const isRequestError = useSelector((state: IAppState) => state.app.errorRequest?.isError);
  const errorRequest = useSelector((state: IAppState) => state.app.errorRequest);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isRedirect, setRedirect] = useState<boolean>(false);
  const [localView, setSelectedView] = useState<View | string>(view || View.CUSTOMER);
  const [redirectPath, setRedirectPath] = useState<LocationDescriptor | null>(null);
  const [isVisiblePassword, showPassword] = useState<boolean>(false);
  const { search } = useLocation();
  const params = query.parse(search);
  const tokenAsQueryParam = params.t;

  useEffect(() => {
    if (email.length !== 0 && password.length !== 0) {
      setRedirect(true);
    }
  }, [email, password]);

  useEffect(() => {
    LocalStorage.removeItems([
      TOKEN_AUTH_STORAGE,
      TOKEN_TWILIO_STORAGE,
      IS_SWITCH_TO_PHONE_BANNER,
      TOKEN_BEARER,
      REDIRECT_PATH,
    ]);

    if (!isDev && process.env.PSYCHIC_BASE_URL) {
      const isPsychic = window.location.href.includes(process.env.PSYCHIC_BASE_URL as string);
      setSelectedView(isPsychic ? View.PSYCHIC : View.CUSTOMER);

      return;
    }

    if (!isDev && !process.env.VIEW_MODE) {
      setSelectedView(View.PSYCHIC);
    }
  }, []);

  const handleOnClickImg = () => {
    showPassword((prevValue) => !prevValue);
  };

  const handleInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (fieldName === LOGIN_FIELDS_NAME.EMAIL) {
      setEmail(fieldValue);
    }

    if (fieldName === LOGIN_FIELDS_NAME.PASSWORD) {
      setPassword(fieldValue);
    }

    if (fieldName === LOGIN_FIELDS_NAME.VIEW) {
      const view = fieldValue && fieldValue.toLowerCase();
      goTo(`/login?view=${view}`);
      fieldValue === View.CUSTOMER
        ? setSelectedView(View.CUSTOMER)
        : setSelectedView(View.PSYCHIC);
    }
  };

  useEffect(() => {
    if (localView) setView(localView as View);
  }, [localView]);

  useEffect(() => {
    if (!isLoading && redirectPath) {
      goTo(redirectPath as string);
    }
  }, [isLoading, redirectPath]);

  useEffect(() => {
    const params = query.parse(search);

    if (!params[VIEW]) {
      return;
    }

    params[VIEW] === View.PSYCHIC.toLowerCase()
      ? setSelectedView(View.PSYCHIC)
      : setSelectedView(View.CUSTOMER);
  }, [search]);

  useEffect(() => {
    const { isError, typeErrorNotification } = errorRequest || {};

    if (isError && typeErrorNotification !== 'authError') {
      setRequestErrorState(false, '');
    }

    if (isError && typeErrorNotification === 'authError') {
      setTimeout(() => {
        setRequestErrorState(false, '');
      }, 3000);
    }
  }, [isRequestError]);

  const handleSubmitButton = () => {
    receiveAuthData(email, password, localView as View);

    switch (localView.toLowerCase() || params[VIEW]) {
      case View.CUSTOMER.toLowerCase(): {
        setRedirectPath('/psychics?view=customer');

        break;
      }
      case View.PSYCHIC.toLowerCase(): {
        setRedirectPath('/conversations?view=psychic');

        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <LoaderContainer withBackground />
      {!tokenAsQueryParam && (
        <Login
          isDev={isDev}
          view={view as View}
          isRequestError={isRequestError}
          isVisiblePassword={isVisiblePassword}
          isRedirect={isRedirect}
          handleSubmitButton={handleSubmitButton}
          handleInput={handleInput}
          selectDefault={localView as View}
          handleOnClickImg={handleOnClickImg}
        />
      )}
    </>
  );
};

export default LoginFormContainer;
