import React from 'react';
import cn from 'classnames';

import styles from 'components/Timer/Timer.module.scss';

interface TimerProps {
  min: number
  isDisabled: boolean,
}

const Timer: React.FC<TimerProps> = ({ min, isDisabled }) => (
  <div className={cn(
    styles.wrapper,
    min < 1 ? styles.defaultBackground
      : styles.startChat,
    { [styles.disableBackground]: isDisabled },
  )}
  >
    <p>
      {min}
    </p>
    <p>
      min
    </p>
  </div>
);

export default Timer;
