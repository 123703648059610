import {
  call,
  put,
  putResolve,
  select,
} from 'redux-saga/effects';

import { DIFFERENCE_IN_TIME } from 'constants/constants';
import { View } from 'constants/enums';
import {
  configureSystemChannelEvents,
  getSystemChannelSucceeded,
  installClientListeners,
} from 'actions/chatActions';
import {
  selectCustomerId,
  selectExtId,
  selectTwilioClient,
} from 'selectors/selectors';
import {
  checkDiffInTime,
  handleRequestError,
  setErrorType,
  setupNotifications,
} from 'actions/appActions';
import { API } from 'src/utils/api';
import * as types from 'actions/actionsTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import { descriptionFromError } from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* getSystemChannelWorker({ payload }: ReturnType<any>) {
  const client = yield select(selectTwilioClient);
  try {
    const { view, missedNotificationsAmount = 0 } = payload || {};
    const extId = yield select(selectExtId);
    const customerId = yield select(selectCustomerId);
    const currentDifferenceInTime = LocalStorage.getItem(DIFFERENCE_IN_TIME) || 0;
    const response = (view === View.CUSTOMER)
      ? yield call(API.Customer.getCustomerSystemChannel, customerId)
      : yield call(API.Psychic.getPsychicSystemChannel, extId);

    yield put(checkDiffInTime(response?.data?.serverDateTime, currentDifferenceInTime));
    const systemChannel = yield client.getConversationBySid(response.data.channelSid);

    yield putResolve(getSystemChannelSucceeded(systemChannel));
    yield put(configureSystemChannelEvents({ systemChannel, view }));
    yield put(installClientListeners(client));

    if (missedNotificationsAmount) yield put(setupNotifications(missedNotificationsAmount));
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
    yield put(setErrorType(types.RESTORING_TWILIO_FAILED));
  }
}
