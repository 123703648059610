import React from 'react';
import { useDispatch } from 'react-redux';

import { setDiffInTime } from 'actions/appActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { DIFFERENCE_IN_TIME } from 'constants/constants';

export function useResetDiffInTime() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setDiffInTime(0));
    LocalStorage.setItem(DIFFERENCE_IN_TIME, '0');
  }, [dispatch]);
}
