import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  SdkEvents,
} from 'constants/enums';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { NotAvailablePsychicProps } from 'types/componentTypes';
import { redirectUserToWebsiteOrNativeApp } from 'src/utils/commonUtil';

const NotAvailablePsychic: React.FC<NotAvailablePsychicProps> = ({
  sdkCallbackStorage,
  isNotificationPopUp,
  popUpNotificationData,
  deleteCurrentChat,
  deleteCurrentPsychic,
  clearExtId,
  setShowInitModal,
  deleteSystemChannel,
  showPopUpNotification,
  removePopUpNotification,
  destroySessionSdk,
}) => {
  const okHandler = async () => {
    const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);

    if (redirectUserToWebsiteOrNativeApp()) {
      return;
    }

    if (onLogoutCallback) {
      destroySessionSdk(false);
    } else {
      clearExtId();
      deleteCurrentPsychic();
      deleteSystemChannel();
      deleteCurrentChat();
      setShowInitModal(true);
      removePopUpNotification();
      showPopUpNotification(false);
      goTo('/psychics?view=customer');
    }

    LocalStorage.setItem('isVisibleNotification', '');
  };

  const acceptButton = {
    text: 'Close',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: okHandler,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
    />
  );
};

export default NotAvailablePsychic;
