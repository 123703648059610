import React from 'react';

import style from './styles.module.scss';

const SwitchToPhoneBanner: React.FC<any> = ({ handleOnClick }) => (
  <div className={style.callBanner}>
    <span>
      Sometimes you just need to hear a human voice.
      <br />
      Tap “Switch to Phone” to turn this Chat into a phone call.
    </span>
    <button
      type="button"
      onClick={handleOnClick}
      className={style.btnClose}
      aria-label="close"
    >
      <span className={style.close} />
    </button>
  </div>
);

export default SwitchToPhoneBanner;
