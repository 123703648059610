import * as types from 'actions/actionsTypes';
import { CHAT_TABS_ID, MOBILE_PAGE_ID, PAGE_ID } from 'constants/constants';
import { ReducerAction, InitialStateType } from 'types/objectTypes';

const initialState: InitialStateType = {
  isLoading: false,
  isThinking: false,
  command: '',
  view: '',
  sdkConfiguration: undefined,
  dataFromSdk: undefined,
  sdkCallbackStorage: new Map(),
  sdkVisibilityMode: '',
  diffInTime: 0,
  onPsychicPresenceChange: () => { },
  isSwitchedConversations: false,
  currentId: '',
  isNotificationPopUp: false,
  isHideEmojiPicker: false,
  isInitChatModal: true,
  isShownIncomingRequest: false,
  isLiveChatActive: false,
  isLiveChatCanceled: false,
  isPhoneReading: false,
  internetConnectingStatus: 'online',
  twilioConnectingStatus: null,
  isVisibleHeaderBanner: false,
  headerBannerContent: null,
  redirectState: {
    isRedirect: false,
    path: '',
  },
  errorRequest: {
    isError: false,
    typeErrorNotification: '',
  },
  userAgent: undefined,
  popUpNotificationData: {
    title: '',
    description: '',
    notificationType: '',
    reason: '',
    callback: { func: () => { } },
  },
  toastBannerData: {
    isVisible: false,
    toastMessage: '',
    isAnimation: false,
  },
  isCreateChatPending: false,
  inlineNotification: {
    type: '',
    messageIndex: null,
  },
  isHistoryMode: false,
  isPsychicNotificationsEnable: false,
  isDeclineOMEnabled: false,
  activeTab: CHAT_TABS_ID.MY_STATUS,
  activePage: PAGE_ID.CHATS_PAGE,
  notificationReactionUnreadCount: 0,
  isConfirmationScreen: false,
  isVisibleReminderPopup: false,
  dashboardPsychicNews: '',
  isElectronApp: false,
  isChatAutoReloadEnabled: false,
  callBackQueue: {
    callBackQueueData: [],
    expectedLoginMin: 0,
  },
  isPsychicLoginOutsideOfSchedule: false,
  isPsychicLoginOutsideOfScheduleEditMode: false,
  psychicLoginOutsideOfScheduleMsg: '',
  psychicLoginOutsideLastActivityId: 0,
  psychicLoginOutsidePopupShow: false,
  activeMobilePage: MOBILE_PAGE_ID.CHATS_PAGE,
  isChatInInitState: false,
  autoReloadPromptsLabels: {},
  location: '',
};

const appReducer = (
  state: InitialStateType = initialState,
  { type, payload }: ReducerAction,
): InitialStateType => {
  switch (type) {
    case types.SET_COMMAND:
      return { ...state, command: payload.command };
    case types.SET_DATA_FROM_SDK:
      return {
        ...state,
        command: payload.command,
        dataFromSdk: payload.dataFromSdk,
      };
    case types.SET_BOOTED_DATA_FROM_SDK:
      return {
        ...state,
        sdkConfiguration: payload.sdkConfiguration,
      };
    case types.SET_SDK_VISIBILITY_MODE:
      return { ...state, sdkVisibilityMode: payload.mode };
    case types.CLEAR_DATA_FROM_SDK:
      return {
        ...state,
        dataFromSdk: undefined,
        sdkConfiguration: undefined,
      };
    case types.REMOVE_COMMAND:
      return { ...state, command: '' };
    case types.SET_SWITCHED_CONVERSATION:
      return { ...state, isSwitchedConversations: payload };
    case types.SET_VIEW:
      return { ...state, view: payload };
    case types.SHOW_POPUP_NOTIFICATION: {
      return { ...state, isNotificationPopUp: payload };
    }
    case types.SHOW_INIT_CHAT_MODAL: {
      return { ...state, isInitChatModal: payload };
    }
    case types.SET_IS_LIVE_CHAT_ACTIVE: {
      return { ...state, isLiveChatActive: payload };
    }
    case types.SET_IS_LIVE_CHAT_CANCELED: {
      return { ...state, isLiveChatCanceled: payload };
    }
    case types.SET_IS_SHOWN_INCOMING_REQUEST: {
      return { ...state, isShownIncomingRequest: payload };
    }
    case types.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload.condition,
        isThinking: payload.isThinking,
      };
    }
    case types.SET_IS_BACKDROP: {
      return {
        ...state,
        isBackdrop: payload,
      };
    }
    case types.SET_USER_AGENT: {
      return { ...state, userAgent: payload };
    }
    case types.SET_POPUP_NOTIFICATION_DATA: {
      return { ...state, popUpNotificationData: payload };
    }
    case types.SET_IS_PHONE_READING: {
      return { ...state, isPhoneReading: payload };
    }
    case types.HIDE_EMOJI_PICKER: {
      return { ...state, isHideEmojiPicker: payload };
    }
    case types.SET_IS_VISIBLE_HEADER_BANNER: {
      return { ...state, isVisibleHeaderBanner: payload };
    }
    case types.SET_IS_VISIBLE_REMINDER_POPUP: {
      return { ...state, isVisibleReminderPopup: payload };
    }
    case types.SET_HEADER_BANNER_CONTENT: {
      return { ...state, headerBannerContent: payload };
    }
    case types.CLEAR_APP: {
      const {
        view,
        sdkCallbackStorage,
        sdkConfiguration,
        userAgent,
      } = state;

      return {
        ...initialState,
        view,
        sdkCallbackStorage,
        sdkConfiguration,
        userAgent,
      };
    }
    case types.REMOVE_POP_UP_NOTIFICATION: {
      return {
        ...state,
        popUpNotificationData: {
          title: '',
          description: '',
          notificationType: '',
          callback: { func: () => { } },
        },
      };
    }
    case types.ADD_SDK_CALLBACK: {
      return {
        ...state,
        sdkCallbackStorage: new Map(state.sdkCallbackStorage.set(payload.key, payload.value)),
      };
    }
    case types.REMOVE_SDK_CALLBACK: {
      state.sdkCallbackStorage.delete(payload.key);

      return { ...state, sdkCallbackStorage: new Map(state.sdkCallbackStorage) };
    }
    case types.SET_DIFF_IN_TIME: {
      return { ...state, diffInTime: payload };
    }
    case types.REQUEST_ERROR: {
      return { ...state, errorRequest: payload };
    }
    case types.SET_REDIRECT_STATE: {
      return { ...state, redirectState: payload };
    }
    case types.SET_TWILIO_CONNECTING_STATUS: {
      return { ...state, twilioConnectingStatus: payload };
    }
    case types.SET_INTERNET_CONNECTING_STATUS: {
      return { ...state, internetConnectingStatus: payload };
    }
    case types.SET_IS_PENDING_CREATE_CHAT: {
      return { ...state, isCreateChatPending: payload };
    }
    case types.SET_INLINE_NOTIFICATION: {
      return {
        ...state,
        inlineNotification: {
          type: payload.type,
          messageIndex: payload.messageIndex,
        },
      };
    }
    case types.SET_HISTORY_MODE_STATE: {
      return { ...state, isHistoryMode: payload };
    }

    case types.SET_PSYCHIC_NOTIFICATIONS_ENABLE: {
      return { ...state, isPsychicNotificationsEnable: payload };
    }
    case types.SET_PSYCHIC_DECLINE_OM_ENABLE: {
      return { ...state, isDeclineOMEnabled: payload };
    }
    case types.SET_ACTIVE_TAB: {
      return { ...state, activeTab: payload };
    }
    case types.SET_ACTIVE_PAGE: {
      return { ...state, activePage: payload };
    }
    case types.SET_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT: {
      return { ...state, notificationReactionUnreadCount: payload };
    }
    case types.SET_CONFIRMATION_SCREEN: {
      return { ...state, isConfirmationScreen: payload };
    }
    case types.SET_IS_ELECTRON_APP: {
      return { ...state, isElectronApp: payload };
    }
    case types.SET_DASHBOARD_PSYCHIC_NEWS: {
      return { ...state, dashboardPsychicNews: payload };
    }
    case types.SET_CALLBACK_QUEUE: {
      return { ...state, callBackQueue: payload };
    }
    case types.SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE: {
      return { ...state, isPsychicLoginOutsideOfSchedule: payload };
    }
    case types.SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_EDIT_MODE: {
      return { ...state, isPsychicLoginOutsideOfScheduleEditMode: payload };
    }
    case types.SET_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_MSG: {
      return { ...state, psychicLoginOutsideOfScheduleMsg: payload };
    }
    case types.SET_PSYCHIC_LOGIN_OUTSIDE_LAST_ACTIVITY: {
      return { ...state, psychicLoginOutsideLastActivityId: payload };
    }
    case types.SET_PSYCHIC_LOGIN_OUTSIDE_POPUP_SHOW: {
      return { ...state, psychicLoginOutsidePopupShow: payload };
    }
    case types.SET_ACTIVE_MOBILE_PAGE: {
      return { ...state, activeMobilePage: payload };
    }
    case types.SET_IS_CHAT_IN_INIT_STATE: {
      return { ...state, isChatInInitState: payload };
    }
    case types.SET_IS_CHAT_AUTO_RELOAD: {
      return { ...state, isChatAutoReloadEnabled: payload };
    }
    case types.SET_AUTO_RELOAD_PROMPT_LABELS: {
      return { ...state, autoReloadPromptsLabels: payload };
    }
    case types.SET_TOAST_BANNER_DATA: {
      return { ...state, toastBannerData: payload };
    }
    case types.REMOVE_TOAST_BANNER_DATA: {
      return {
        ...state,
        toastBannerData: {
          isVisible: false,
          toastMessage: '',
          isAnimation: false,
        },
      };
    }
    case types.SET_LOCATION: {
      return { ...state, location: payload };
    }
    default:
      return state;
  }
};

export default appReducer;
