import React from 'react';

import ComposeNotificationButton from '.';

const ComposeNotificationButtonForMobile = ({
  isBottomCTAVisible,
  sendCustomerDetail,
  handlePsychicNoteScreens,
  className,
}) => isBottomCTAVisible && (
  <div className={className}>
    <ComposeNotificationButton
      sendCustomerDetail={sendCustomerDetail}
      onClick={handlePsychicNoteScreens}
    />
  </div>
);

export default ComposeNotificationButtonForMobile;
