import React from 'react';
import cn from 'classnames';

import styles from 'components/Tooltips/AutoReloadInfoTooltip/AutoReload.module.scss';

const AutoReloadInfoTooltip = ({
  isOpen,
  text,
  isAutoReloadEnabled,
}) => {
  if (!isOpen) {
    return null;
  }

  const tooltipClass = cn(styles.tooltip, {
    [styles.tooltipUl]: isAutoReloadEnabled,
  });

  const tooltipTextClass = cn(styles.tooltipText, {
    [styles.tooltipTextUl]: isAutoReloadEnabled,
  });

  return (
    <div className={tooltipClass}>
      <div
        className={tooltipTextClass}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default AutoReloadInfoTooltip;
