import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import {
  ChatInitMessages,
  ChatRequest,
  ChatStatus,
  ErrorRequestType,
  MixpanelEvents,
  PopUpNotificationType,
  SdkEvents,
  Status,
} from 'constants/enums';
import { ButtonTapped } from 'types/objectTypes';
import { goTo } from 'route-history';
import ClientHeader from 'components/Header/ClientHeader/ClientHeader';
import {
  addPopUpNotification as _addPopUpNotification,
  destroySessionSdk as _destroySessionSdk,
  setHeaderBannerContent as _setHeaderBannerContent,
  setIsPhoneReading as _setIsPhoneReading,
  setIsVisibleHeaderBanner as _setIsVisibleHeaderBanner,
  setLoadingState as _setLoadingState,
  setRequestErrorState as _setRequestErrorState,
} from 'actions/appActions';
import {
  clearExtId as _clearExtId,
  deleteCurrentChat as _deleteCurrentChat,
  deleteCurrentPsychic as _deleteCurrentPsychic,
  deleteSystemChannel as _deleteSystemChannel,
  setCurrentPsychicStatus as _setCurrentPsychicStatus,
  setInitChatNotification as _setInitChatNotification,
} from 'actions/chatActions';
import { receiveSwitchToPhoneInfo as _receiveSwitchToPhoneInfo } from 'actions/switchToPhoneActions';
import { IAppState } from 'store';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  ERROR_DUE_TO_LOW_FUNDS,
  FUNDS_TO_ADD,
  HEADER_BANNER_DESCRIPTION_STORAGE,
  HEADER_BANNER_SUBTITLE_STORAGE,
  HEADER_BANNER_TITLE_STORAGE,
  HEADER_BANNER_TYPE_STORAGE,
  IS_VISIBLE_HEADER_BANNER_STORAGE,
  SWITCH_TO_PHONE_VISIBLE,
} from 'constants/constants';
import MixpanelActions from 'src/utils/mixpanel';
import {
  handleSwitchToPhoneNotifications,
  redirectUserToWebsiteOrNativeApp,
} from 'src/utils/commonUtil';
import useStickyHeader from 'src/hooks/useStickyHeader';
import {
  selectCurrentChat,
  selectCurrentUser,
  selectInternetConnectionStatus,
  selectIsChatInInitState,
  selectIsHistoryMode,
  selectIsLiveChatActive,
  selectNotifications,
  selectSdkCallbackStorage,
  selectTwilioClient,
  selectUserAgent,
  selectIsShowAutoReloadHeader,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { API } from 'src/utils/api';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';
import { usePrevious } from 'src/hooks/prevValue.hook';

const ClientHeaderComponent = () => {
  const setCurrentPsychicStatus = useBindDispatch(_setCurrentPsychicStatus);
  const deleteCurrentChat = useBindDispatch(_deleteCurrentChat);
  const deleteCurrentPsychic = useBindDispatch(_deleteCurrentPsychic);
  const clearExtId = useBindDispatch(_clearExtId);
  const deleteSystemChannel = useBindDispatch(_deleteSystemChannel);
  const setIsPhoneReading = useBindDispatch(_setIsPhoneReading);
  const destroySessionSdk = useBindDispatch(_destroySessionSdk);
  const setIsVisibleHeaderBanner = useBindDispatch(_setIsVisibleHeaderBanner);
  const setHeaderBannerContent = useBindDispatch(_setHeaderBannerContent);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setLoadingState = useBindDispatch(_setLoadingState);
  const receiveSwitchToPhoneInfo = useBindDispatch(_receiveSwitchToPhoneInfo);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);

  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const currentChat = useSelector(selectCurrentChat);
  const notifications = useSelector(selectNotifications);
  const currentUser = useSelector(selectCurrentUser);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isHistoryMode: boolean = useSelector(selectIsHistoryMode);
  const currentPsychicPhoneStatus = useSelector(
    (state: IAppState) => state.chat.currentPsychicPhoneStatus,
  );
  const isVisibleHeaderBanner = useSelector((state: IAppState) => state.app.isVisibleHeaderBanner);
  const headerBannerContent = useSelector((state: IAppState) => state.app.headerBannerContent);
  const client = useSelector(selectTwilioClient);
  const userAgent = useSelector(selectUserAgent);
  const internetConnectingStatus = useSelector(selectInternetConnectionStatus);
  const isInitChatModal = useSelector((state: IAppState) => state.app.isInitChatModal);
  const isChatInInitState = useSelector(selectIsChatInInitState);
  const isShowAutoReloadHeader = useSelector(selectIsShowAutoReloadHeader);
  const fundsToAdd = useSelector((state: IAppState) => state.chat.fundsToAdd);

  const setRequestErrorState = useBindDispatch(_setRequestErrorState);
  const [isVisibleEndChat, setIsisVisibleEndChat] = useState<boolean>(false);
  const [isVisibleBtnPhone, setIsVisibleBtnPhone] = useState<boolean>(false);
  const [imgClient, setImgClient] = useState<string>(client);
  const [psychicName, setPsychicName] = useState<string>('Psychic');
  const UAPlatform = useMemo(() => userAgent?.getPlatformType(), [userAgent]);
  const [isLostConnection, setConnectionState] = useState<boolean>(false);
  const { sideUser, chatId } = currentChat;
  const headerRef = useRef<HTMLElement>(null);
  const [isShowAutoReloadToggle, setShowAutoReloadToggle] = useState<boolean>(false);

  const prevEnableAutoRecharge = usePrevious(currentUser?.enableAutoRecharge) || false;

  const addMixpanelEventButtonTapped = ({
    buttonText, screenName, buttonType, user,
  }: ButtonTapped) => {
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: buttonText,
      button_type: buttonType,
      screen_name: screenName,
      screen_title: 'chat window',
      ...getPsychicInfoMixpanel(user),
    });
  };

  const handleEndChatButton = useCallback(() => {
    addPopUpNotification({
      isVisible: true,
      title: `Are you sure you want to end this Chat with ${sideUser?.friendlyName}?`,
      notificationType: PopUpNotificationType.END_CHAT_REQUEST,
    });
  }, [sideUser]);

  const handleSwitchToPhoneError = (response: any) => {
    const errorCode = response?.error?.code;

    if (errorCode === ERROR_DUE_TO_LOW_FUNDS) {
      const fundsToAdd = response.data?.fundsToAdd;

      LocalStorage.setItem(FUNDS_TO_ADD, fundsToAdd);
      addPopUpNotification({
        isVisible: true,
        reason: 'switchToPhone',
        title: 'You don’t have enough in your account for this reading. Please add funds to your account balance to continue.',
        notificationType: PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS,
      });
    } else {
      setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT);
    }
  };

  const handleCallButton = useCallback(async () => {
    try {
      setLoadingState(true);
      addMixpanelEventButtonTapped({
        buttonText: 'switch to phone', screenName: 'chat window', buttonType: 'button', user: sideUser,
      });

      receiveSwitchToPhoneInfo();

      await API.Chat.validateSwitchToPhone(chatId);

      handleSwitchToPhoneNotifications(
        {
          addPopUpNotification,
          sideUser,
        },
      );
    } catch (e) {
      const response = e?.response?.data;

      handleSwitchToPhoneError(response);
    } finally {
      setLoadingState(false);
    }
  }, [sideUser, UAPlatform, chatId]);

  const handleCloseChat = useCallback(async () => {
    if (isHistoryMode) {
      addMixpanelEventButtonTapped({
        buttonText: 'close', screenName: 'chat history mode', buttonType: 'button', user: sideUser,
      });
    } else {
      addMixpanelEventButtonTapped({
        buttonText: 'close chat', screenName: 'chat end mode', buttonType: 'button', user: sideUser,
      });
    }

    if (chatId && isInitChatModal && !isHistoryMode) {
      await API.Chat.cancelChat(chatId);
    }

    const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);
    setIsVisibleHeaderBanner(false);
    setHeaderBannerContent(null);

    if (redirectUserToWebsiteOrNativeApp()) {
      return;
    }

    if (onLogoutCallback) {
      destroySessionSdk(true);
    } else {
      clearExtId();
      deleteCurrentPsychic();
      deleteCurrentChat();
      deleteSystemChannel();
      goTo('/psychics?view=customer');

      LocalStorage.removeItems([
        HEADER_BANNER_TITLE_STORAGE,
        HEADER_BANNER_SUBTITLE_STORAGE,
        HEADER_BANNER_DESCRIPTION_STORAGE,
        HEADER_BANNER_TYPE_STORAGE,
        IS_VISIBLE_HEADER_BANNER_STORAGE,
      ]);
    }
  }, [chatId]);

  useEffect(() => {
    setConnectionState(internetConnectingStatus === 'offline');
  }, [internetConnectingStatus]);

  useEffect(() => {
    const isShowAutoReload = isLiveChatActive
      && (currentUser?.enableAutoRecharge || prevEnableAutoRecharge)
      && isShowAutoReloadHeader as boolean;

    setShowAutoReloadToggle(isShowAutoReload);
  }, [isLiveChatActive, currentUser, isShowAutoReloadHeader, prevEnableAutoRecharge]);

  useEffect(() => {
    const isSwitchToPhoneVisible = LocalStorage.getItem(SWITCH_TO_PHONE_VISIBLE);

    if (sideUser?.extId) {
      setImgClient(sideUser?.images[5]);
      setPsychicName(sideUser?.lineName);
    }

    if (!notifications.length || !chatId) {
      return;
    }

    const lastNotification = notifications[notifications?.length - 1];
    const attributes = lastNotification?.attributes || {};
    const isPsychicAvailable = currentPsychicPhoneStatus
      && currentPsychicPhoneStatus?.toLowerCase() !== Status.OFFLINE;
    const isTheSameChat = chatId === attributes.chatId;
    const isPhoneAvailable = isPsychicAvailable;
    const isChatRequestAccepted = attributes.type === ChatRequest.CHAT_REQUEST_ACCEPTED
    || attributes?.notificationType === ChatStatus.CHAT_STARTED;
    const isBtnSwitchToPhone = isPhoneAvailable && isChatRequestAccepted;

    if (isChatRequestAccepted) {
      setInitChatNotification(ChatInitMessages.PSYCHIC_ACCEPTED_CHAT_REQUEST);
      setCurrentPsychicStatus(Status.CONNECTED);
    }

    if (isBtnSwitchToPhone && isLiveChatActive && isTheSameChat) {
      setIsVisibleBtnPhone(true);
    }

    if (!isSwitchToPhoneVisible && isLiveChatActive && isVisibleBtnPhone) {
      MixpanelActions.track(MixpanelEvents.BUTTON_VISIBLE, {
        button_text: 'switch to phone',
        button_type: 'button',
        screen_title: 'chat window',
        ...getPsychicInfoMixpanel(sideUser),
      });
      LocalStorage.setItem(SWITCH_TO_PHONE_VISIBLE, 'true');
    }

    // @ts-ignore
    if (isBtnSwitchToPhone && !currentUser.customerHasPhoneReading) {
      setIsPhoneReading(true);
    }
  }, [
    notifications,
    sideUser,
    currentUser,
    isVisibleBtnPhone,
    isLiveChatActive,
    currentPsychicPhoneStatus,
    chatId,
  ]);

  useEffect(() => {
    if (!isLiveChatActive || isChatInInitState) {
      setIsisVisibleEndChat(false);
      setIsVisibleBtnPhone(false);
    } else {
      setIsisVisibleEndChat(true);
    }
  }, [isChatInInitState, isLiveChatActive]);

  useStickyHeader(headerRef);

  return (
    <ClientHeader
      isVisibleEndChat={isVisibleEndChat}
      isVisibleBtnPhone={isVisibleBtnPhone}
      isLiveChatActive={isLiveChatActive}
      imgClient={imgClient}
      handleCallButton={handleCallButton}
      handleEndChatButton={handleEndChatButton}
      handleCloseChat={handleCloseChat}
      name={psychicName}
      isLostConnection={isLostConnection}
      typeHeaderBanner={headerBannerContent?.type}
      isVisibleHeaderBanner={isVisibleHeaderBanner}
      rate={sideUser?.customerPrice || sideUser?.basePrice}
      baseRate={sideUser.basePrice}
      headerRef={headerRef}
      isHistoryMode={isHistoryMode}
      isInitChatModal={isInitChatModal}
      isChatInInitState={isChatInInitState}
      fundsToAdd={fundsToAdd}
      isShowAutoReloadToggle={isShowAutoReloadToggle}
    />
  );
};

export default ClientHeaderComponent;
