import React, { useCallback, useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
// import { useSelector } from 'react-redux';

import Iconify from 'components/PsychicNotifications/CustomStyled/Iconify';
import { ISearchBar } from 'src/types/componentTypes';
// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
// import { selectCurrentUser } from 'selectors/selectors';
import styles from 'components/PsychicNotifications/ListingView/SearchBar/SearchBar.module.scss';
import SearchIcon from 'src/assets/png/searchIcon.png';

const SearchBar: React.FC<ISearchBar> = ({
  filterName,
  onFilterName,
  searchTag,
  tagName,
  setTagged,
  isTagged,
  isMobileView,
  isVisible,
}) => {
  // const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (filterName && tagName) {
      if (searchTag) {
        setTagged(true);
      }
    } else if (isTagged) {
      setTagged(false);
    }
  }, [filterName, tagName]);

  useEffect(() => {
    if (isTagged) {
      onFilterName(tagName || filterName);
    }
  }, [isTagged]);

  const tagEventHandler = () => {
    if (filterName && tagName) {
      if (!isTagged) {
        setTagged(true);
      }
    }
  };

  const onClickEventHandler = () => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'search client',
    //   button_type: 'search bar',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
  };

  const onPressEnterEventHandler = useCallback((e) => {
    if (e.key === 'Enter') {
      tagEventHandler();
    }
  }, [tagEventHandler]);

  const onChangeSearchTextHandler = (e) => {
    onFilterName(e.target.value);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.searchWrapper}>
      <TextField
        value={filterName}
        className={styles.searchBar}
        disabled={isTagged}
        onKeyPress={onPressEnterEventHandler}
        onBlur={tagEventHandler}
        onChange={onChangeSearchTextHandler}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiOutlinedInput-input': {
            padding: '5px',
          },
          '& .MuiOutlinedInput-root': {
            padding: '0',
          },
        }}
        onClick={onClickEventHandler}
        placeholder="Search client..."
        InputProps={{
          disableUnderline: true,
          startAdornment: isMobileView ? (
            <InputAdornment position="start">
              <img src={SearchIcon} alt="Search Icon" />
            </InputAdornment>
          ) : (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: '#C0C0C0', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchBar;
