import React, {
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import {
  MixpanelEvents,
  PopUpNotificationType,
  Status,
  View,
  MixpanelUserTypes,
  PopUpNotification,
  ChatInitMessages,
  ChatRequest,
} from 'constants/enums';
import { API } from 'src/utils/api';
import {
  handleRequestError as _handleRequestError,
  setIsLiveChatActive as _setIsLiveChatActive,
  setPopUpNotificationData as _setPopUpNotificationData,
  setShowInitModal as _setShowInitModal,
  showPopUpNotification as _showPopUpNotification,
  setIsChatInInitState as _setIsChatInInitState,
} from 'actions/appActions';
import {
  handleCreateChatErrors as _handleCreateChatErrors,
  sideUserRequest as _sideUserRequest,
  setInitChatNotification as _setInitChatNotification,
  setChatChannelAndLoadMessages as _setChatChannelAndLoadMessages,
} from 'actions/chatActions';
import { IAppState } from 'store';
import MixpanelActions from 'src/utils/mixpanel';
import { SentryMethods } from 'src/utils/sentryMethods';
import {
  descriptionFromError,
  getPlatform,
} from 'src/utils/commonUtil';
import { checkCustomerIsBusyNotification } from 'src/utils/helpers';
import {
  CHAT_REMAINING_TIME,
  ChatErrors,
  NC_FLOW,
  SCREEN_VIEWED_MIXPANEL,
} from 'constants/constants';
import { useEndChatDueToAppointment } from 'src/hooks/useEndChatDueToAppointment';
import {
  selectAppointment,
  selectChannel,
  selectCurrentChat,
  selectExtId,
  selectInitChatNotification,
  selectInternetConnectionStatus,
  selectIsLoading,
  selectNotifications,
  selectPopNotification,
  selectUserAgent,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { LocalStorage } from 'src/utils/storageHandler';
import MixpanelPsychicActions from 'src/utils/mixpanelPsychicActions';
import Loader from 'assets/svg/reskin-loader-spin.svg';
import { PopUpNotificationData } from 'types/objectTypes';
import { useChatCreatedRequest } from 'src/hooks/notification.hook';

import style from './InitChat.module.scss';

const InitChatCustomerPopup = () => {
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const setIsLiveChatActive = useBindDispatch(_setIsLiveChatActive);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const handleRequestError = useBindDispatch(_handleRequestError);
  const sideUserRequest = useBindDispatch(_sideUserRequest);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);
  const setIsChatInInitState = useBindDispatch(_setIsChatInInitState);
  const setChatChannelAndLoadMessages = useBindDispatch(_setChatChannelAndLoadMessages);

  const handleCreateChatErrors = useBindDispatch(_handleCreateChatErrors);

  const isInitChatModal = useSelector((state: IAppState) => state.app.isInitChatModal);
  const currentChat = useSelector(selectCurrentChat);
  const isLoading = useSelector(selectIsLoading);
  const userAgent = useSelector(selectUserAgent);
  const extId = useSelector(selectExtId);
  const appointment = useSelector(selectAppointment);
  const popUpNotification = useSelector(selectPopNotification) as PopUpNotificationData;
  const initChatNotification = useSelector(selectInitChatNotification);
  const notifications = useSelector(selectNotifications);
  const internetConnectingStatus = useSelector(selectInternetConnectionStatus);
  const channel = useSelector(selectChannel);

  const [second, setSecond] = useState<number>(5);
  const [countDown, setCountDown] = useState<number>(30);
  const [units, setUnits] = useState<string>('-');

  const {
    sideUser,
    chatId,
    status,
    chatChannelSid,
  } = currentChat;

  const resetSecondsAndUnits = () => {
    setSecond(5);
    setUnits('-');
  };

  useEffect(() => {
    const notificationType = LocalStorage.getItem('notificationType') as PopUpNotification;

    if (checkCustomerIsBusyNotification(notificationType)) {
      setSecond(0);
      setUnits('');
    }
  }, []);

  useEffect(() => {
    if (isInitChatModal) {
      MixpanelActions.track(MixpanelEvents.SCREEN_VIEWED, SCREEN_VIEWED_MIXPANEL);
    }
  }, [isInitChatModal]);

  useEffect(() => {
    if (initChatNotification === ChatInitMessages.CONTACTING_PSYCHIC) {
      setCountDown(CHAT_REMAINING_TIME);
    }
  }, [initChatNotification]);

  useEffect(() => {
    if (extId && isInitChatModal && !sideUser.customerPrice) {
      sideUserRequest(extId, View.CUSTOMER);
    }
  }, [extId, isInitChatModal, sideUser]);

  /**
   * register psychic data for all mixpanel events
   */
  useEffect(() => {
    if (!sideUser) {
      return;
    }

    const {
      extId,
      friendlyName,
      customerPrice,
    } = sideUser;

    MixpanelPsychicActions.register({
      extId,
      friendlyName,
      customerPrice,
    });

    MixpanelActions.register({
      extId,
      friendlyName,
      customerPrice,
      chatSource: getPlatform(userAgent, 'mixpanel'),
      userType: MixpanelUserTypes.EXISTING_CUSTOMER,
    });
  }, [sideUser]);

  useEffect(() => {
    let interval;

    if (isInitChatModal && !isLoading) {
      setUnits(second.toString());
      interval = setInterval(() => {
        setSecond((prevValue) => (prevValue - 1));
      }, 1000);
    }

    if (second === 0) {
      setUnits('');
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    second,
    isInitChatModal,
    isLoading,
    status,
  ]);

  useEffect(() => {
    let interval;

    if (isInitChatModal) {
      interval = setInterval(() => {
        setCountDown((prevValue) => (prevValue - 1));
      }, 1000);
    }

    if (countDown === 0) {
      clearInterval(interval);

      if (initChatNotification !== ChatInitMessages.WAITING_FOR_PSYCHIC_TO_ACCEPT) {
        resetSecondsAndUnits();
      }
    }

    return () => clearInterval(interval);
  }, [
    countDown,
    isInitChatModal,
    isLoading,
    status,
  ]);

  const trackChatWindowMixpanelEvents = () => {
    const screen = {
      screen_name: 'chat window',
      screen_title: 'chat window',
    };

    MixpanelActions.track(MixpanelEvents.SCREEN_VIEWED, { ...screen });
    MixpanelActions.track(MixpanelEvents.BUTTON_VISIBLE, {
      button_text: 'end chat',
      button_type: 'button',
      ...screen,
    });
  };

  const startChat = async (chatId: string, notificationType: PopUpNotification) => {
    if (checkCustomerIsBusyNotification(notificationType) || !isInitChatModal) {
      return;
    }

    // TODO move to saga
    try {
      await API.Chat.startChatRequest(chatId);
      setIsChatInInitState(false);
      trackChatWindowMixpanelEvents();
      setInitChatNotification(ChatInitMessages.CONTACTING_PSYCHIC);
    } catch (e) {
      console.log(e);
      SentryMethods.captureException(e);
      setIsLiveChatActive(false);
      setShowInitModal(false);

      const errorData = e.response?.data;

      if (ChatErrors.includes(errorData.error?.code)) {
        handleCreateChatErrors(errorData);
      } else {
        const requestErrorPayload = {
          redirectPath: '',
          isInvalidToken: false,
          errorText: e?.message,
          description: descriptionFromError(e),
        };

        handleRequestError(requestErrorPayload);
      }
    }
  };

  const endChatIfAppointment = useEndChatDueToAppointment();

  useEffect(() => {
    let isChatEndedDueToAppointment = false;

    if (units === '' && status !== NC_FLOW) {
      isChatEndedDueToAppointment = endChatIfAppointment(
        appointment,
        chatId,
        sideUser.friendlyName,
      );
    }

    if (!isChatEndedDueToAppointment && units === '' && chatId && status !== NC_FLOW) {
      if (status === Status.INSUFFICIENT_FUNDS) {
        setIsChatInInitState(false);
        setShowInitModal(false);
        trackChatWindowMixpanelEvents();
        showPopUpNotification(true);
        setPopUpNotificationData({
          title: 'You don’t have enough in your account for this reading. Please add funds to your account balance to continue.',
          notificationType: PopUpNotificationType.INSUFFICIENT_FUNDS,
        });
      } else if (initChatNotification !== ChatInitMessages.WAITING_FOR_PSYCHIC_TO_ACCEPT) {
        startChat(chatId, popUpNotification.notificationType as PopUpNotification);

        // reload chat channel and Messages in case if they are not loaded
        if (!channel) {
          setChatChannelAndLoadMessages(chatChannelSid);
        }
      }
    }
  }, [units, chatId, status, appointment, chatId, sideUser.friendlyName, popUpNotification]);

  useChatCreatedRequest((attributes, time: number) => {
    const chatNotification = attributes.type
      || attributes.notificationType;

    if (chatNotification === ChatRequest.CHAT_REQUEST_CREATED && countDown === 30) {
      setCountDown(time);
    }
  }, notifications, internetConnectingStatus);

  return (
    <div className={style.container}>
      <span className={style.notification}>{initChatNotification}</span>
      <div className={style.timerWrapper}>
        <div className={style.timer}>
          <span className={style.second}>{countDown}</span>
        </div>
        <img
          alt="loader"
          className={style.loader}
          src={Loader}
        />
      </div>
    </div>
  );
};

export default InitChatCustomerPopup;
