import { put, select } from 'redux-saga/effects';

import { setPsychicStatus, updatePsychicStatus } from 'actions/statusToggleActions';
import { PsychicStatusChannel } from 'constants/enums';
import { selectIsDelayed, selectStatusToggle } from 'selectors/statusToggleSelectors';

export function* endBreakSaga() {
  const { chatOnly, phoneOnly } = yield select(selectStatusToggle);
  const isDelayed = yield select(selectIsDelayed);

  if (isDelayed) {
    yield put(updatePsychicStatus(true, PsychicStatusChannel.BREAK));
  }

  let psychicStatus = PsychicStatusChannel.BREAK;

  if (chatOnly && phoneOnly) {
    psychicStatus = PsychicStatusChannel.PHONE_CHAT;
  } else if (!chatOnly && phoneOnly) {
    psychicStatus = PsychicStatusChannel.PHONE;
  } else if (chatOnly && !phoneOnly) {
    psychicStatus = PsychicStatusChannel.CHAT;
  }

  yield put(setPsychicStatus(true, psychicStatus));
}
