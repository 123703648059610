import grateful from 'src/assets/svg/reactions/Grateful.svg';
import happy from 'src/assets/svg/reactions/Happy.svg';
import inspired from 'src/assets/svg/reactions/Inspiring.svg';
import like from 'src/assets/svg/reactions/Like.svg';
import love from 'src/assets/svg/reactions/Heart.svg';
import surprised from 'src/assets/svg/reactions/Surprised.svg';

export const reactionsMap = new Map<string, any>();
// Setting the images in the sequence that has to be
// shown in the Psychic Notification's statistics view.
reactionsMap.set('inspired', inspired);
reactionsMap.set('like', like);
reactionsMap.set('happy', happy);
reactionsMap.set('love', love);
reactionsMap.set('grateful', grateful);
reactionsMap.set('surprised', surprised);

export const reactionListForCustomer = [
  {
    reactionId: 327,
    reactionType: 'Like',
  },
  {
    reactionId: 328,
    reactionType: 'Love',
  },
  {
    reactionId: 329,
    reactionType: 'Happy',
  },
  {
    reactionId: 330,
    reactionType: 'Grateful',
  },
  {
    reactionId: 331,
    reactionType: 'Inspired',
  },
  {
    reactionId: 332,
    reactionType: 'Surprised',
  },
];

export const reactionsNameMap = new Map<string, string>();

reactionsNameMap.set('327', 'like');
reactionsNameMap.set('328', 'heart');
reactionsNameMap.set('329', 'happy');
reactionsNameMap.set('330', 'grateful');
reactionsNameMap.set('331', 'inspiring');
reactionsNameMap.set('332', 'surprised');
