import React, { useMemo } from 'react';
import { Card } from '@mui/material';
import cn from 'classnames';

import ProgressBar from 'components/PsychicNotifications/GamifyStats/ProgressBar';
import styles from 'components/PsychicNotifications/GamifyStats/GamifyStats.module.scss';

const GamifyStats = ({ engagementGoal }) => {
  const sentGoalPercentage = (engagementGoal.psychicSentCount / engagementGoal.psychicGoal) * 100;
  const progressBarPercentage = sentGoalPercentage >= 100 ? 100 : sentGoalPercentage;

  const { status, innerTextLine1, innerTextLine2 } = useMemo(() => {
    let status = '';
    let innerTextLine1 = '';
    let innerTextLine2 = '';

    if (progressBarPercentage >= 0 && progressBarPercentage <= 9) {
      status = 'Sending a personalized note makes a client feel that you care!';
      innerTextLine1 = 'Let\'s get';
      innerTextLine2 = 'started!';
    }

    if (progressBarPercentage >= 10 && progressBarPercentage <= 19) {
      status = 'Psychics that engage with their clients see more repeat readings.';
      innerTextLine1 = 'Great';
      innerTextLine2 = 'start!';
    }

    if (progressBarPercentage >= 20 && progressBarPercentage <= 29) {
      status = 'Clients appreciate knowing when their favorite psychics will be online.';
      innerTextLine1 = 'Here';
      innerTextLine2 = 'we go!';
    }

    if (progressBarPercentage >= 30 && progressBarPercentage <= 39) {
      status = 'You can build a strong relationship with your clients with notes.';
      innerTextLine1 = 'Now';
      innerTextLine2 = 'we\'re talking!';
    }

    if (progressBarPercentage >= 40 && progressBarPercentage <= 49) {
      status = 'Clients appreciate a ‘next steps’ follow up after readings.';
      innerTextLine1 = 'You\'re';
      innerTextLine2 = 'doing great!';
    }

    if (progressBarPercentage >= 50 && progressBarPercentage <= 59) {
      status = 'What were the main points of a reading? Tell the client you hear them!';
      innerTextLine1 = 'Wow,';
      innerTextLine2 = 'keep it up!';
    }

    if (progressBarPercentage >= 60 && progressBarPercentage <= 69) {
      status = 'Time invested in building a relationship with clients will pay for itself.';
      innerTextLine1 = 'Incredible';
      innerTextLine2 = 'well done!';
    }

    if (progressBarPercentage >= 70 && progressBarPercentage <= 79) {
      status = 'Clients can send three notes in response per day.';
      innerTextLine1 = 'You\'re';
      innerTextLine2 = 'crushing it!';
    }

    if (progressBarPercentage >= 80 && progressBarPercentage <= 89) {
      status = 'Clients can initiate a note after a reading with you.';
      innerTextLine1 = 'Almost';
      innerTextLine2 = 'there!';
    }

    if (progressBarPercentage >= 90 && progressBarPercentage <= 99) {
      status = 'You can send a group note to up to 5 clients you’ve recently read with.';
      innerTextLine1 = 'Goal is';
      innerTextLine2 = 'in sight! 😀';
    }

    if (progressBarPercentage >= 100) {
      status = 'Your clients will appreciate your personal attention.';
      innerTextLine1 = 'Congrats,';
      innerTextLine2 = 'you did it!';
    }

    return { status, innerTextLine1, innerTextLine2 };
  }, [progressBarPercentage]);

  return (
    <Card className={cn(styles.wrapper, styles.wrapperOverflow)}>
      <div className={styles.infoWrapper}>
        <div className={styles.infoContent}>
          <span className={styles.infoHead}>Engagement Goal</span>
          <span className={styles.infoWeek}>{engagementGoal.periodicDate}</span>
        </div>
        <span className={styles.infoMessage}>
          {status}
        </span>
      </div>
      {(engagementGoal.psychicGoal !== 0) && (
        <ProgressBar
          progressBarPercentage={progressBarPercentage}
          innerTextLine1={innerTextLine1}
          innerTextLine2={innerTextLine2}
          engagementGoal={engagementGoal}
        />
      )}
    </Card>
  );
};

export default GamifyStats;
