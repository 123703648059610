import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CHAT_ID,
  CHAT_ID_STORAGE,
  FUNDS_TO_ADD,
  MIN_TIME_CHAT_IS_ACTIVE,
  NC_FLOW,
} from 'constants/constants';
import {
  ChatInitMessages,
  ChatRequest,
  NotificationType,
  SdkEvents,
  Status,
  View,
} from 'constants/enums';
import { goTo } from 'route-history';
import {
  joinChannelRequest as _joinChannelRequest,
  setChatRequest as _setChatRequest,
  initApplication as _initApplication,
  setCurrentPsychicStatus as _setCurrentPsychicStatus,
  setVisibilityNotification as _setVisibilityNotification,
  setCurrentPsychicPhoneStatus as _setCurrentPsychicPhoneStatus,
  setCurrentChat as _setCurrentChat,
  sideUserRequest as _sideUserRequest,
  setInitChatBanner as _setInitChatBanner,
  setInitChatNotification as _setInitChatNotification,
} from 'actions/chatActions';
import {
  addPopUpNotification as _addPopUpNotification,
  setIsLiveChatActive as _setIsLiveChatActive,
  setIsChatInInitState as _setIsChatInInitState,
  setShowInitModal as _setShowInitModal,
} from 'actions/appActions';
import ClientView from 'src/components/Views/ClientView/ClientView';
import { usePsychicStatuses } from 'src/hooks/firestore.hook';
import { IAppState } from 'store';
import { useRestoreNotification } from 'src/hooks/notification.hook';
import { LocalStorage } from 'src/utils/storageHandler';
import { checkCustomerActiveChat } from 'src/utils/commonUtil';
import { roundToWhole } from 'src/utils/helpers';
import {
  selectAuthToken,
  selectCommand,
  selectCurrentChat,
  selectCustomerId,
  selectExtId,
  selectIsHistoryMode,
  selectNotifications,
  selectSdkCallbackStorage,
  selectSideUser,
  selectTwilioClient,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { addPopupNotificationUtils } from 'src/utils/addPopupNotificationUtils';

const ClientViewContainer = () => {
  const [isStart, setActiveChatCustomers] = useState<boolean>(false);
  const [isVisibleChatComponents, setComponentsVisibility] = useState<boolean>(false);

  const joinChannelRequest = useBindDispatch(_joinChannelRequest);
  const initApplication = useBindDispatch(_initApplication);
  const setChatRequest = useBindDispatch(_setChatRequest);
  const setCurrentPsychicStatus = useBindDispatch(_setCurrentPsychicStatus);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setVisibilityNotification = useBindDispatch(_setVisibilityNotification);
  const setCurrentPsychicPhoneStatus = useBindDispatch(_setCurrentPsychicPhoneStatus);
  const setIsLiveChatActive = useBindDispatch(_setIsLiveChatActive);
  const setCurrentChat = useBindDispatch(_setCurrentChat);
  const sideUserRequest = useBindDispatch(_sideUserRequest);
  const setIsChatInInitState = useBindDispatch(_setIsChatInInitState);
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const setInitChatBanner = useBindDispatch(_setInitChatBanner);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);

  const command = useSelector(selectCommand);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const extId = useSelector(selectExtId);
  const customerId = useSelector(selectCustomerId);
  const tokenAuth = useSelector(selectAuthToken);
  const sideUser = useSelector(selectSideUser);
  const currentChat = useSelector(selectCurrentChat);
  const twilioClient = useSelector(selectTwilioClient);
  const notifications = useSelector(selectNotifications);
  const isVisibleNotification = useSelector((state: IAppState) => state.app.isNotificationPopUp);
  const isInitChatModal = useSelector((state: IAppState) => state.app.isInitChatModal);
  const isHistoryMode = useSelector(selectIsHistoryMode);

  useEffect(() => {
    (async () => {
      try {
        const isReturnCondition = !customerId || !extId || !isInitChatModal
          || currentChat?.currentChannel?.sid;

        if (isReturnCondition) {
          return;
        }

        const chat = await checkCustomerActiveChat(customerId as string, extId as string);

        if (!chat?.chatId || !chat?.status) {
          LocalStorage.removeItem(CHAT_ID);
          sideUserRequest(extId, View.CUSTOMER);
          setIsLiveChatActive(false);

          return;
        }

        LocalStorage.setItem(CHAT_ID, chat.chatId);

        chat.attributes = { requestType: chat.type };
        sideUserRequest(extId, View.CUSTOMER);
        setCurrentChat(chat);
        setIsLiveChatActive(true);

        if (chat.status === NC_FLOW) {
          setIsChatInInitState(false);
          setShowInitModal(true);
          setInitChatBanner(true);
          setInitChatNotification(ChatInitMessages.CONTACTING_PSYCHIC);
          setChatRequest(customerId as string, extId as string);

          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setComponentsVisibility(true);
      }
    })();
  }, [customerId, extId, isInitChatModal, currentChat?.currentChannel?.sid]);

  useRestoreNotification(isVisibleNotification);

  usePsychicStatuses(
    setCurrentPsychicStatus,
    setCurrentPsychicPhoneStatus,
    extId as string,
  );

  useEffect(() => {
    if (!tokenAuth) {
      return;
    }

    const isActiveCondition = !command
      || (command && Boolean(sdkCallbackStorage.get(SdkEvents.SET_SESSION)))
      || isInitChatModal;

    if (isActiveCondition) {
      setActiveChatCustomers(true);
    }
  }, [
    tokenAuth,
    command,
    sdkCallbackStorage.get(SdkEvents.SET_SESSION),
    isInitChatModal,
  ]);

  useEffect(() => {
    const isRequireApplicationInitialization = extId
      && tokenAuth
      && sideUser?.extId
      && isStart
      && customerId
      && command !== SdkEvents.SET_SESSION
      && command !== SdkEvents.LOGOUT;

    if (isRequireApplicationInitialization) {
      initApplication();
    }
  }, [
    extId,
    customerId,
    isStart,
    sideUser?.extId,
    command,
    tokenAuth,
  ]);

  const setVisibilityForCustomerNotifications = (notifications) => {
    const { attributes, index } = notifications;

    if (attributes && index) {
      setVisibilityNotification(attributes, index);
    }
  };

  const setNotification = (lastNotification: any, currentChatId: string) => {
    const {
      type,
      chatId,
      fundsToAdd,
    } = lastNotification?.attributes || {};
    const funds = fundsToAdd
      ? roundToWhole(fundsToAdd)
      : sideUser?.customerPrice * MIN_TIME_CHAT_IS_ACTIVE;

    LocalStorage.setItem(FUNDS_TO_ADD, funds as string);
    setVisibilityForCustomerNotifications(lastNotification);
    addPopupNotificationUtils(
      type,
      currentChatId === chatId,
      addPopUpNotification,
      sideUser.friendlyName,
    );
  };

  useEffect(() => {
    const notificationLength = notifications?.length;

    if (!notificationLength || !sideUser) {
      return;
    }

    const customerNotifications = [
      ChatRequest.CHAT_REQUEST_EXPIRED,
      NotificationType.CHAT_PAYMENT_REQUIRED,
      NotificationType.CHAT_ENDED_INSUFFICIENT_FUNDS,
    ];

    const lastNotification = notifications[notificationLength - 1];
    const { type, isSeen } = lastNotification?.attributes;

    if (!isSeen && customerNotifications.includes(type)) {
      setNotification(lastNotification, currentChat.chatId);
      setIsChatInInitState(false);
    }
  }, [notifications, sideUser, currentChat.chatId]);

  useEffect(() => {
    const isActiveChat = currentChat.status !== Status.COMPLETED
      && currentChat.status !== Status.EXPIRED;

    if (currentChat.chatId && (isActiveChat || isHistoryMode) && twilioClient?.conversations) {
      if (isHistoryMode) {
        setIsChatInInitState(false);
        setShowInitModal(false);
        setInitChatBanner(false);
      }

      goTo(`/conversations/${currentChat.chatId}?view=customer`);
      joinChannelRequest(currentChat.chatChannelSid);
      LocalStorage.setItem(CHAT_ID_STORAGE, currentChat.chatId);
    }
  }, [currentChat.chatId, twilioClient?.conversations]);

  return (
    <ClientView
      isVisibleChatComponents={isVisibleChatComponents}
      sideUser={sideUser}
      requestType={currentChat?.attributes?.requestType}
    />
  );
};

export default ClientViewContainer;
