import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { setRequestErrorState as _setRequestErrorState } from 'actions/appActions';
import { ErrorRequestType, View } from 'constants/enums';
import { IAppState } from 'store';
import { selectView } from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import style from 'components/Notifications/ErrorAlert/ErrorAlert.module.scss';

const ErrorAlert = ({ isChatBottomButtons = false }) => {
  const setRequestErrorState = useBindDispatch(_setRequestErrorState);

  const errorRequest = useSelector((state: IAppState) => state.app.errorRequest);
  const view = useSelector(selectView);

  const isError = errorRequest.isError && errorRequest.typeErrorNotification
    === ErrorRequestType.ALERT_ABOVE_INPUT;

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setRequestErrorState(false, '');
      }, 3000);
    }
  }, [errorRequest, isError]);

  if (!isError) {
    return null;
  }

  const classes = cn(
    view === View.PSYCHIC ? style.wrapperForPsychicView : style.wrapper,
    { [style.withChatBottomButtons]: isChatBottomButtons },
  );

  return (
    <div className={classes}>
      <p>
        We’re sorry, we seem to be experiencing some technical difficulty.
      </p>
    </div>
  );
};

export default ErrorAlert;
