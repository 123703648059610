import React from 'react';

import {
  setPopUpNotificationData as _setPopUpNotificationData,
  showPopUpNotification as _showPopUpNotification,
} from 'actions/appActions';
import { PopUpNotificationData } from 'types/objectTypes';
import useBindDispatch from 'src/hooks/useBindDispatch';

const popupActions = {
  showPopUpNotification: _showPopUpNotification,
  setPopUpNotificationData: _setPopUpNotificationData,
};

export const useShowPopUpNotification = () => {
  const { showPopUpNotification, setPopUpNotificationData } = useBindDispatch(popupActions);

  const cb = React.useCallback(
    (data: PopUpNotificationData | PopUpNotificationData[]) => {
      showPopUpNotification(true);
      setPopUpNotificationData(data);
    },
    [showPopUpNotification, setPopUpNotificationData],
  );

  return cb;
};
