import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { Status } from 'extracted-chat-components/enums';

import {
  ErrorRequestType,
  NotificationsText,
  PopUpNotificationType,
  View,
} from 'constants/enums';
import { selectView, selectExtId } from 'selectors/selectors';
import {
  addPopUpNotification,
  handleRequestError,
  setLoadingState,
  setRedirectState,
  setRequestErrorState,
  setDashboardPsychicNews,
  setPsychicPhoneStatus,
} from 'actions/appActions';
import { API } from 'src/utils/api';
import { setIsDiagnosticPopupOpen } from 'actions/diagnosticAction';
import * as types from 'actions/actionsTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import { SentryMethods } from 'src/utils/sentryMethods';
import { restoreTwilioClientWorker } from 'src/redux/sagas/app/restoreTwilioClientSaga';
import { initApplicationWorker } from 'src/redux/sagas/app/initApplicationSaga';
import { restartChatWorker } from 'src/redux/sagas/app/restartChatSaga';
import { setSideUserWorker } from 'src/redux/sagas/app/setSideUserSaga';
import {
  destroySessionSdkWorker,
  setConversationSdkWorker,
  setSessionSdkWorker,
} from 'src/redux/sagas/app/createChatSdkSaga';
import { handlePsychicMissChatWorker } from 'src/redux/sagas/app/handlePsychicMissChatSaga';
import { handleNotificationsVisibility, handlePopUpNotification } from 'src/redux/sagas/app/handleNotificationsSaga';
import { checkClientTimeWorker } from 'src/redux/sagas/app/checkClientTimeSaga';
import { getSystemChannelWorker } from 'src/redux/sagas/app/getSystemChannelSaga';
import { setUpNotifications } from 'src/redux/sagas/app/setUpNotificationsSaga';
import { messages } from 'src/services/messages';
import { descriptionFromError } from 'src/utils/commonUtil';
import { setPsychicStatus } from 'actions/statusToggleActions';

function* runDiagnosticSdkWorker() {
  try {
    yield put(setIsDiagnosticPopupOpen(true));
  } catch (e) {
    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleRequestErrorWorker({
  payload: {
    isInvalidToken = false,
    redirectPath,
    description,
  },
}: ReturnType<any>) {
  const view = yield select(selectView);
  const isPsychicView = view === View.PSYCHIC;

  if (isInvalidToken) {
    yield put(addPopUpNotification({
      isVisible: true,
      title: 'Your session has expired.',
      notificationType: PopUpNotificationType.EXPIRED_TOKEN,
    }));
    yield put(setLoadingState(false));

    return;
  }

  if (redirectPath) {
    yield put(setRedirectState(true, redirectPath));
  }

  const isUnauthorized = description.includes('401') && !isPsychicView;

  LocalStorage.setItem('isVisibleNotification', 'visible');
  yield put(setRequestErrorState(true, ErrorRequestType.POP_UP_NOTIFICATION));
  yield put(addPopUpNotification({
    isVisible: true,
    title: isUnauthorized ? NotificationsText.LOGGED_OUT : NotificationsText.TECHNICAL_DIFFICULTY,
    notificationType: PopUpNotificationType.REQUEST_ERROR,
    description: isPsychicView && description,
    reason: isUnauthorized && 'unauthorized',
  }));
}

function* handleLogoutPrompt() {
  const view = yield select(selectView);
  const isPsychicView = view === View.PSYCHIC;

  if (isPsychicView) {
    yield put(addPopUpNotification({
      isVisible: true,
      title: 'Are you sure you want to log out?',
      description: messages.logoutPrompt(),
      notificationType: PopUpNotificationType.LOGOUT,
    }));
  }
}

function* setPsychicPhoneStatusWorker({ payload }: ReturnType<typeof setPsychicPhoneStatus>) {
  const { status, channel } = payload;
  let isPsychicPendingSchedule = false;

  if (!status) {
    const extId = yield select(selectExtId);
    const response = yield call(
      API.Psychic.checkPsychicCurrentSchedules, extId,
    );
    isPsychicPendingSchedule = !!response.isCurrentHourScheduled;
  }

  if (isPsychicPendingSchedule) {
    yield put(addPopUpNotification({
      isVisible: true,
      title: 'Logging Out',
      description: messages.psychicEarlylogoutPrompt(),
      callback: {
        func: () => {},
        args: [status, channel],
      },
      notificationType: PopUpNotificationType.PSYCHIC_EARLY_LOGOUT,
    }));
  } else {
    yield put(setPsychicStatus(status, channel));
  }
}

function* fetchDashboardPsychicNews() {
  try {
    yield put(setLoadingState(true));

    const { content } = yield call(API.Psychic.getNewsData);

    yield put(setDashboardPsychicNews(content));
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleChangedPhoneStatus({ payload }: any) {
  try {
    if (payload === Status.AVAILABLE) {
      yield put(setLoadingState(false));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* appSagas() {
  yield takeEvery(types.INIT_APPLICATION, initApplicationWorker);
  yield takeEvery(types.RESET_CHAT, restartChatWorker);
  yield takeEvery(types.SIDE_USER_REQUEST, setSideUserWorker);
  yield takeEvery(types.SET_CONVERSATION_SDK, setConversationSdkWorker);
  yield takeEvery(types.SET_SESSION_SDK, setSessionSdkWorker);
  yield takeEvery(types.DESTROY_SESSION_SDK, destroySessionSdkWorker);
  yield takeEvery(types.HANDLE_PSYCHIC_MISS_CHAT, handlePsychicMissChatWorker);
  yield takeEvery(types.ADD_POP_UP_NOTIFICATION, handlePopUpNotification);
  yield takeEvery(types.SET_VISIBILITY_NOTIFICATION, handleNotificationsVisibility);
  yield takeEvery(types.HANDLE_REQUEST_ERROR, handleRequestErrorWorker);
  yield takeEvery(types.CHECK_DIFF_IN_TIME, checkClientTimeWorker);
  yield takeEvery(types.GET_SYSTEM_CHANNEL, getSystemChannelWorker);
  yield takeEvery(types.SETUP_NOTIFICATIONS, setUpNotifications);
  yield takeEvery(types.RUN_DIAGNOSTIC_PAGE, runDiagnosticSdkWorker);
  yield takeEvery(types.RESTORE_TWILIO_CLIENT, restoreTwilioClientWorker);
  yield takeEvery(types.SHOW_LOGOUT_PROMPT, handleLogoutPrompt);
  yield takeEvery(types.GET_DASHBOARD_PSYCHIC_NEWS, fetchDashboardPsychicNews);
  yield takeEvery(types.SET_PSYCHIC_PHONE_STATUS, setPsychicPhoneStatusWorker);
  yield takeEvery(types.SET_CURRENT_PSYCHIC_PHONE_STATUS, handleChangedPhoneStatus);
}
