import React, { useEffect, useState } from 'react';

import { TIP_BANNER_MESSAGES } from 'constants/constants';
import styles from 'src/components/Notifications/ContentBanner/TipBanner.module.scss';

const TipBanner = () => {
  const [message, setMessage] = useState(TIP_BANNER_MESSAGES[0]);
  let index = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (index < 2) {
        index += 1;
      } else {
        index = 0;
      }

      setMessage(TIP_BANNER_MESSAGES[index]);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {message}
      </div>
    </div>
  );
};

export default TipBanner;
