import {
  put,
  select,
} from 'redux-saga/effects';

import {
  View,
  NotificationType,
  MixpanelEvents,
} from 'constants/enums';
import {
  INLINE_NOTIFICATION,
  INLINE_NOTIFICATION_INDEX,
  INLINE_NOTIFICATION_TIMESTAMP,
} from 'constants/constants';
import { selectView } from 'selectors/selectors';
import { LocalStorage } from 'src/utils/storageHandler';
import { setInlineNotification } from 'actions/appActions';
import MixpanelActions from 'src/utils/mixpanel';

const sendAddFundsMixpanelEventPromptDelivered = () => {
  MixpanelActions.track(MixpanelEvents.PROMPT_DELIVERED, {
    prompt_text: 'add funds to continue',
    prompt_type: 'add funds inline banner',
    screen_title: 'chat window',
  });
};

export function* handleChatSystemMessages(message) {
  const index: number = message?.index;

  if (!index) {
    return;
  }

  const view = yield select(selectView);
  const type: string = message?.attributes?.notificationType;

  switch (type) {
    case NotificationType.ADD_FUNDS_REQUEST: {
      if (view === View.CUSTOMER) {
        yield put(setInlineNotification(type, index));
        sendAddFundsMixpanelEventPromptDelivered();

        LocalStorage.setItem(INLINE_NOTIFICATION, type);
        LocalStorage.setItem(INLINE_NOTIFICATION_INDEX, String(index));
        LocalStorage.setItem(INLINE_NOTIFICATION_TIMESTAMP, message.timestamp);
      }

      break;
    }
    default: break;
  }
}
