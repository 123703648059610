import React from 'react';

import SvgIcon from 'components/SvgIcon';

const SendMessageButton = ({
  disabled, onClick, className, sendButtonColor,
}) => (
  <button
    id="sendMessageBtn"
    disabled={disabled}
    type="button"
    onClick={onClick}
    className={className}
    aria-label="send button"
  >
    <SvgIcon
      id="sendButton"
      color={sendButtonColor}
    />
  </button>
);

export default SendMessageButton;
