import { applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'react-router-redux';

import { offlineMiddleware } from 'src/redux/middlewares/offlineMiddleware';
import { Environment } from 'constants/enums';
import routeHistory from 'route-history';

export const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    console.log(err);
    Sentry.captureException(err);
  },
});

const routerMid = routerMiddleware(routeHistory);

const middleware = [
  offlineMiddleware,
  sagaMiddleware,
];

const applyEnhancer = (nodeEnv) => {
  if (nodeEnv === Environment.DEVELOPMENT) {
    middleware.push(logger);

    return composeWithDevTools(applyMiddleware(...middleware, routerMid));
  }

  return applyMiddleware(...middleware, routerMid);
};

const enhancer = applyEnhancer(process.env.NODE_ENV);

export default enhancer;
