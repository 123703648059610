import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectDashboardPsychicNews } from 'selectors/selectors';
import { ANCHOR_REGEX } from 'src/constants/constants';

import style from './style.module.scss';

const Dashboard = () => {
  const dashboardNewsRef = useRef<HTMLDivElement>(null);
  const dashboardPsychicNews = useSelector(selectDashboardPsychicNews);
  const regexAnchorLink = new RegExp(`${ANCHOR_REGEX.source}`, 'gm');

  useEffect(() => {
    const dashboardNewsRefCurrent = dashboardNewsRef.current;
    const newsHTMLWithAnchorLink = dashboardPsychicNews.replace(
      regexAnchorLink, (anchorLink) => anchorLink.replace('<a', '<a target="_blank"'),
    );

    if (dashboardNewsRefCurrent && newsHTMLWithAnchorLink) {
      dashboardNewsRefCurrent.innerHTML = newsHTMLWithAnchorLink;
    }
  }, [dashboardPsychicNews]);

  return (
    <div className={style.dashboardNewsSection}>
      <div className={style.newsSection}>
        <div ref={dashboardNewsRef} />
      </div>
    </div>
  );
};

export default Dashboard;
