import React from 'react';
import Modal from 'react-modal';
import { InteractChatButton } from 'extracted-chat-components';

import style from './styles.module.scss';

const ReportIssuePopupModal = ({ isOpen, onClose, link }) => (
  <Modal
    isOpen={isOpen}
    overlayClassName={style.overlay}
    ariaHideApp={false}
    className={style.modal}
  >
    <div className={style.wrapper}>
      <h3 className={style.title}>Report an Issue</h3>
      <p className={style.description}>
        Email will open in native browser.
      </p>
      <p className={style.description}>
        If browser doesn’t open,
        <a href={link} target="_blank" rel="noopener noreferrer">click here.</a>
      </p>
      <InteractChatButton
        className={style.button}
        isActive
        text="OK"
        onClick={onClose}
      />
    </div>
  </Modal>
);

export default ReportIssuePopupModal;
