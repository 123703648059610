import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';

interface IconifyComponent {
  icon: any,
  sx: any,
}

const Iconify: React.FC<IconifyComponent> = ({ sx, ...other }) => (
  <Box component={Icon} sx={{ ...sx }} {...other} />
);

export default Iconify;
