import React from 'react';

import styles from 'components/Chats/ChatItem/style.module.scss';
import SvgIcon from 'components/SvgIcon';

const ChatItemTitle = ({ isLiveChat }) => (
  <div className={styles.chatItemTitle}>
    <SvgIcon
      id={isLiveChat ? 'liveChatIcon' : 'offlineMessageIcon'}
    />
    <h4>{isLiveChat ? 'Live Chat' : 'Offline Message'}</h4>
  </div>
);

export default ChatItemTitle;
