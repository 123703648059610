import React from 'react';

import CustomerListForTable from 'components/PsychicNotifications/CustomerList/CustomerListForTable';
import { addPopUpNotification as _addPopUpNotification } from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { NotificationsText, PopUpNotificationType } from 'constants/enums';
import ClientListForMobile from 'components/Table/ClientListTable/ClientListForMobile';

const CustomerList = ({
  isMobile,
  customer,
  isSelected,
  labelTag,
  selectedLength,
  index,
  updateLabelTag,
  onSelectRowHandler,
}) => {
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);

  const handelOnClickedLabel = () => {
    addPopUpNotification({
      isVisible: true,
      title: 'Client Labels',
      description: NotificationsText.CLIENT_LABELS,
      notificationType: PopUpNotificationType.CLIENT_LABEL_OVERLAY,
      callback: { args: [labelTag, customer], func: updateLabelTag },
    });
  };

  return (
    isMobile
      ? (
        <ClientListForMobile
          key={customer.customerId}
          customer={customer}
          selected={isSelected}
          onSelectRow={onSelectRowHandler}
          selectedLength={selectedLength}
          labelTag={labelTag}
          index={index}
          handelOnClickedLabel={handelOnClickedLabel}
        />
      ) : (
        <CustomerListForTable
          key={customer.customerId}
          customer={customer}
          selected={isSelected}
          onSelectRow={onSelectRowHandler}
          selectedLength={selectedLength}
          labelTag={labelTag}
          handelOnClickedLabel={handelOnClickedLabel}
        />
      )
  );
};

export default CustomerList;
