import React from 'react';
import cn from 'classnames';

import styles from 'src/components/Labels/ClientTypeLabel/ClientTypeLable.module.scss';
import { ClientType } from 'constants/enums';

const ClientTypeLable = ({ clientType, className = '' }) => {
  const wrapperStyle = cn(styles.wrapper,
    clientType === ClientType.EC ? styles.bgPrimary : styles.bgSecondary,
    { [className]: !!className });

  return (
    <div className={wrapperStyle}>
      <span className={styles.label}>{clientType}</span>
    </div>
  );
};

export default ClientTypeLable;
