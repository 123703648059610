import React from 'react';
import cn from 'classnames';

import styles from 'components/Timer/Timer.module.scss';

interface TimerProps {
  timeAmount: number,
  isConversation: boolean,
  isCloseEnd: boolean,
  timeLimit: number,
  isLiveChatActive: boolean,
}

const PsychicTimer: React.FC<TimerProps> = ({
  timeAmount,
  isConversation,
  isCloseEnd,
  timeLimit,
  isLiveChatActive,
}) => {
  const isAlertColor = isLiveChatActive && isCloseEnd;
  const containerStyles = () => {
    if (isConversation) {
      return cn(styles.psychicTimerConversation, isAlertColor && styles.psychicTimerEnd);
    }

    return cn(styles.psychicTimerHeader, isAlertColor && styles.psychicTimerEnd);
  };

  const time = (isLiveChatActive)
    ? `${timeAmount} of ${timeLimit} min`
    : 'Ended';

  return (
    <span className={containerStyles()} title={time}>
      {time}
    </span>
  );
};

export default PsychicTimer;
