import * as types from 'actions/actionsTypes';

export const setVisibleReminderOptionsPopup = (isPopupOpen: boolean) => ({
  type: types.SET_VISIBLE_REMINDER_SETTINGS_POPUP,
  payload: isPopupOpen,
});

export const setReminderOptions = (options) => ({
  type: types.SET_REMINDER_CONFIGS,
  payload: options,
});

export const setSMSAlertPhoneNumber = (phoneNumber) => ({
  type: types.SET_SMS_ALERT_PHONE_NUMBER,
  payload: phoneNumber,
});

export const setReminderOptionsRequest = (options) => ({
  type: types.UPDATE_REMINDER_CONFIGS,
  payload: options,
});
