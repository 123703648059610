import React from 'react';
import cn from 'classnames';

import ChatEndImg from 'assets/svg/icon-end_chat.svg';
import ChatPhoneImg from 'assets/svg/icon-phone.svg';
import { HeaderButtonInterface } from 'types/componentTypes';

import styles from './HeaderButton.module.scss';

const HeaderButton: React.FC<HeaderButtonInterface> = ({
  id,
  isVisible,
  isChatEnd,
  handleEndChatButton,
}) => {
  const stylesBtn = cn(styles.button, isChatEnd ? styles.btnEndChat : styles.btnCall);

  return (
    <button
      id={id}
      type="button"
      aria-pressed="false"
      onClick={handleEndChatButton}
      onKeyDown={handleEndChatButton}
      className={isVisible ? stylesBtn : styles.hidden}
    >
      <img
        alt="header button"
        className={styles.buttonImg}
        src={isChatEnd ? ChatEndImg : ChatPhoneImg}
      />
      <p className={isChatEnd ? styles.btnEndChatText : styles.btnCallText}>
        {isChatEnd ? 'End Chat' : 'Switch to Phone'}
      </p>
    </button>
  );
};

export default HeaderButton;
