import React from 'react';
import cn from 'classnames';

import { reactionListForCustomer, reactionsMap } from 'src/utils/reactionImagesUtils';

import styles from './ReactionTooltip.module.scss';

const ReactionTooltip = ({ handleOnReact, isMe, handleOnSelect }) => (
  <div className={cn(isMe ? styles.tooltip : styles.tooltipOther, { [styles.isMe]: !isMe })}>
    <div className={styles.reactionWrapper}>
      {
        reactionListForCustomer.map((reaction) => (
          <button
            type="button"
            key={reaction.reactionId}
            id={reaction.reactionId}
            onClick={handleOnReact}
            className={styles.reactionButton}
          >
            <img
              src={reactionsMap.get(reaction.reactionType.toLocaleLowerCase())}
              alt={reaction.reactionType}
            />
            <div className={styles.tooltipText}>
              <span>{reaction.reactionType}</span>
            </div>
          </button>
        ))
      }
    </div>
    <button
      type="button"
      className={styles.deleteButton}
      onClick={handleOnReact}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      onBlur={handleOnSelect}
    >
      Delete Note
    </button>
  </div>
);

export default ReactionTooltip;
