import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  addParticipantOfflineMessage,
  addParticipantOfflineMessageBackground,
  updateParticipantOfflineMessage,
  updateParticipantOfflineMessageBackground,
  setIsOfflineChatStart,
  setMessagesOffline,
  setMessagesBeforeOffline,
  removeCurrentOfflineChat,
  removeOfflineChat,
  closeOfflineChat,
  setUserCancelCurrentOfflineChat,
  setUserCancelOfflineChat,
  updateParticipantMessage,
  setOfflineCurrentChat,
  deleteCurrentChat,
} from 'actions/chatActions';
import {
  selectCurrentChat,
  selectCurrentOfflineChats,
  selectIsLiveChatActive,
  selectOfflineChats,
} from 'selectors/selectors';
import * as types from 'src/redux/actions/actionsTypes';
import {
  addParticipantOfflineMessageBackgroundHandler,
  addParticipantOfflineMessageHandler,
  setIsOfflineChatStartHandler,
  setMessagesOfflineHandler,
  removeCurrentOfflineChatHandler,
  setMessagesBeforeOfflineHandler,
  setUserCancelOfflineChatHandler,
  closeOfflineChatHandler,
  removeOfflineChatHandler,
  updateParticipantOfflineMessageHandler,
  handleOfflineMessageSending,
  removeCurrentOrNotOfflineChatHandler,
  readOfflineMessageHandler,
} from 'src/redux/actions/offlineChatActions';
import { ChatType } from 'types/objectTypes';
import {
  handleRequestError,
  setHeaderBannerContent,
  setIsSwitchedConversations,
  setIsVisibleHeaderBanner,
  setLoadingState,
  setPopUpNotificationData,
  setRequestErrorState,
  showPopUpNotification,
} from 'actions/appActions';
import {
  ConversationType,
  ErrorRequestType,
  NotificationsText,
  PopUpNotificationType,
  View,
} from 'constants/enums';
import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';
import { returnChatsWithModifyingParameter } from 'src/redux/sagas/offlineChat/chatModification';
import {
  returnArrayWithNewMessage,
  returnArrayWithNewUpdatedMessage,
  returnNewUpdatedMessages,
} from 'src/redux/sagas/offlineChat/updateMessages';
import { goTo } from 'route-history';
import { OFFLINE_MESSAGE_BANNER, VIEW } from 'constants/constants';
import { formatToUSMonthDay } from 'src/utils/dateHandler';
import { descriptionFromError } from 'src/utils/commonUtil';

function* setIsOfflineChatStartHandlerWorker({
  payload,
}: ReturnType<typeof setIsOfflineChatStartHandler>) {
  const parameter = 'isStarted';
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);
  const chats = { currentChat, offlineChats };
  const newPayload = { [parameter]: payload.isOfflineChatStart, chatId: payload.chatId };
  const { newCurrentChat, newOfflineChats } = returnChatsWithModifyingParameter(
    chats,
    parameter,
    newPayload,
  );
  yield put(setIsOfflineChatStart(newCurrentChat, newOfflineChats));
}

function* addParticipantOfflineMessageHandlerWorker({
  payload,
}: ReturnType<typeof addParticipantOfflineMessageHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);
  const newOfflineChats = returnArrayWithNewMessage(offlineChats, payload);
  const newCurrentChat = { ...currentChat, messages: [...currentChat.messages, payload.message] };
  yield put(addParticipantOfflineMessage(newCurrentChat, newOfflineChats));
}

function* addParticipantOfflineMessageBackgroundHandlerWorker({
  payload,
}: ReturnType<typeof addParticipantOfflineMessageBackgroundHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const newOfflineChats = returnArrayWithNewMessage(offlineChats, payload);
  yield put(addParticipantOfflineMessageBackground(newOfflineChats));
}

function* updateParticipantMessageHandlerWorker({
  payload,
}: ReturnType<typeof updateParticipantMessage>) {
  const currentChat: ChatType = yield select(selectCurrentChat);
  const messages = currentChat.messages.map(returnNewUpdatedMessages(payload));
  const newCurrentChat = { ...currentChat, messages };
  yield put(updateParticipantMessage(newCurrentChat));
}

function* updateParticipantOfflineMessageHandlerWorker({
  payload,
}: ReturnType<typeof updateParticipantOfflineMessageHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);
  const newOfflineChats = returnArrayWithNewUpdatedMessage(offlineChats, payload);
  const messages = currentChat.messages.map(returnNewUpdatedMessages(payload));
  const newCurrentChat = { ...currentChat, messages };
  yield put(updateParticipantOfflineMessage(newCurrentChat, newOfflineChats));
}

function* updateParticipantOfflineMessageBackgroundHandlerWorker({
  payload,
}: ReturnType<typeof addParticipantOfflineMessageBackgroundHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const newOfflineChats = returnArrayWithNewUpdatedMessage(offlineChats, payload);
  yield put(updateParticipantOfflineMessageBackground(newOfflineChats));
}

function* setMessagesOfflineHandlerWorker({
  payload,
}: ReturnType<typeof setMessagesOfflineHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const newOfflineChats = offlineChats.map((offlineChat) => {
    const newOfflineChat = offlineChat;

    if (newOfflineChat.chatId === payload.chatId) {
      newOfflineChat.messages = [...payload.messages];
    }

    return newOfflineChat;
  });
  yield put(setMessagesOffline(newOfflineChats));
}

function* setMessagesBeforeOfflineHandlerWorker({
  payload,
}: ReturnType<typeof setMessagesBeforeOfflineHandler>) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);
  const newOfflineChats = offlineChats.map((offlineChat: ChatType) => {
    const newOfflineChat = offlineChat;

    if (newOfflineChat.chatId === payload.chatId) {
      newOfflineChat.messages = [...payload.messages, ...newOfflineChat.messages];
    }

    return newOfflineChat;
  });
  const newCurrentChat = {
    ...currentChat,
    messages: [...payload.messages, ...currentChat.messages],
  };
  yield put(setMessagesBeforeOffline(newCurrentChat, newOfflineChats));
}

function* removeCurrentOrNotOfflineChatHandlerWorker({
  payload: chatId,
}: ReturnType<typeof removeCurrentOfflineChatHandler>) {
  const currentChat: ChatType = yield select(selectCurrentChat);

  if (chatId === currentChat.chatId) {
    yield put(removeCurrentOfflineChatHandler(chatId));
  } else {
    yield put(removeOfflineChatHandler(chatId));
  }
}

function* removeOfflineChatsCommon(chatId: string) {
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);

  return offlineChats.filter((offlineChat) => offlineChat.chatId !== chatId);
}

function* removeCurrentOfflineChatHandlerWorker({
  payload: chatId,
}: ReturnType<typeof removeCurrentOfflineChatHandler>) {
  const newOfflineChats = yield removeOfflineChatsCommon(chatId);
  yield put(removeCurrentOfflineChat(newOfflineChats));
}

function* removeOfflineChatHandlerWorker({
  payload: chatId,
}: ReturnType<typeof removeOfflineChatHandler>) {
  const newOfflineChats = yield removeOfflineChatsCommon(chatId);

  yield put(removeOfflineChat(newOfflineChats));
}

function* closeOfflineChatHandlerWorker({
  payload: chatId,
}: ReturnType<typeof closeOfflineChatHandler>) {
  const parameter = 'isClosed';
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);
  const chats = { currentChat, offlineChats };
  const newPayload = { [parameter]: true, chatId };
  const { newCurrentChat, newOfflineChats } = returnChatsWithModifyingParameter(
    chats,
    parameter,
    newPayload,
  );
  yield put(closeOfflineChat(newCurrentChat, newOfflineChats));
}

function* setUserCancelOfflineChatHandlerWorker({
  payload: chatId,
}: ReturnType<typeof setUserCancelOfflineChatHandler>) {
  const parameter = 'isUserCanceled';
  const offlineChats: Array<ChatType> = yield select(selectOfflineChats);
  const currentChat: ChatType = yield select(selectCurrentChat);

  if (currentChat.chatId === chatId) {
    const chats = { currentChat, offlineChats };
    const newPayload = { [parameter]: true, chatId };
    const { newCurrentChat, newOfflineChats } = returnChatsWithModifyingParameter(
      chats,
      parameter,
      newPayload,
    );
    yield put(setUserCancelCurrentOfflineChat(newCurrentChat, newOfflineChats));

    yield put(deleteCurrentChat());
    goTo(`/conversations?${VIEW}=${View.PSYCHIC.toLowerCase()}`);

    yield put(setPopUpNotificationData({
      title: 'This message has been cancelled by the Customer.',
      notificationType: PopUpNotificationType.CUSTOMER_CANCEL_OM,
    }));
    yield put(showPopUpNotification(true));
  } else {
    const newOfflineChats = offlineChats.map((chat: ChatType) => {
      const newChat = chat;

      if (newChat.chatId === chatId) {
        newChat.attributes[parameter] = true;
      }

      return newChat;
    });
    yield put(setUserCancelOfflineChat(newOfflineChats));
  }
}

function* handleOfflineMessageSendingWorker({
  payload,
}: ReturnType<typeof handleOfflineMessageSending>) {
  const { chatId, message } = payload;
  try {
    yield put(setLoadingState(true));
    yield all([
      call(API.Chat.sendOfflineMessage, chatId, message),
      put(closeOfflineChatHandler(chatId)),
    ]);

    yield put(removeCurrentOrNotOfflineChatHandler(chatId));
    yield put(setLoadingState(false));
    yield put(setIsSwitchedConversations(false));
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
    yield put(setLoadingState(false));
  }
}

function* openCurrentOfflineChatSaga({ payload }: ReturnType<any>) {
  try {
    const {
      chat,
      chatId,
      isCurrentChat,
      type,
    } = payload;

    yield put(setLoadingState(true));

    const isLiveChatActive = yield select(selectIsLiveChatActive);

    const isOfflineChat = type === ConversationType.DIRECT_MESSAGE;
    const isLiveChat = type === ConversationType.LIVE_CHAT;

    const isOnlyOfflineChats = !isLiveChatActive && !isLiveChat;

    if (!chat || (isLiveChatActive && isOfflineChat)) {
      return;
    }

    const currentOfflineChat = yield select(selectCurrentOfflineChats, chatId);

    if (currentOfflineChat) {
      yield put(setOfflineCurrentChat(currentOfflineChat));
    }

    if (!isCurrentChat) {
      yield call(API.Chat.openDM, chat.chatId);
    }

    yield put(setIsSwitchedConversations(true));

    if (!isOnlyOfflineChats || !chat.chatId) {
      return;
    }

    if (!isCurrentChat && chat.sideUser?.dateOfBirth) {
      yield put(setIsVisibleHeaderBanner(true));
      yield put(setHeaderBannerContent({
        subtitle: `Offline message from ${chat.sideUser.friendlyName}, ${formatToUSMonthDay(chat.sideUser.userFormattedDOB)}, ${chat.sideUser.horoSign}`,
        description: NotificationsText.OFFLINE_MESSAGE_REMINDER,
        type: OFFLINE_MESSAGE_BANNER,
      }));
    }

    goTo(`/conversations/${chat.chatId}?${VIEW}=${View.PSYCHIC.toLowerCase()}`);
  } catch (e) {
    const { isCurrentChat, chat } = payload;

    if (!chat?.attributes) {
      return;
    }

    const {
      isUserCanceled,
    } = chat.attributes;

    const isCanceled = !isCurrentChat && isUserCanceled;

    if (isCanceled) {
      yield put(setPopUpNotificationData({
        title: 'This message has been cancelled by the Customer.',
        notificationType: PopUpNotificationType.CUSTOMER_CANCEL_OM,
      }));
      yield put(showPopUpNotification(true));
    } else {
      console.log(e);

      const requestErrorPayload = {
        redirectPath: '',
        isInvalidToken: false,
        errorText: e?.message,
        description: descriptionFromError(e),
      };

      yield put(handleRequestError(requestErrorPayload));
      SentryMethods.captureException(e);
    }
  } finally {
    yield put(setLoadingState(false));
  }
}

function* callDeclineOfflineMessageSaga({
  payload: {
    data,
    callbacks,
  },
}: ReturnType<any>) {
  try {
    const offlineChats = yield select(selectOfflineChats);

    yield call(API.Chat.declineMessage, data);
    yield put(closeOfflineChatHandler(data.chatRequestId));
    callbacks.forEach((item) => item());

    if (offlineChats.length > 1) {
      const currentChatIndex = offlineChats.findIndex(
        (item) => item.chatId === data.chatRequestId,
      );
      const nextChat = currentChatIndex < (offlineChats.length - 1)
        ? offlineChats[currentChatIndex + 1]
        : offlineChats[0];

      yield put(setOfflineCurrentChat(nextChat));
      yield put(setIsSwitchedConversations(true));

      goTo(
        `/conversations/${
          nextChat.chatRequestId
        }?${VIEW}=${View.PSYCHIC.toLowerCase()}`,
      );
    } else {
      yield put(setOfflineCurrentChat({
        messages: [],
        currentChannel: {},
        sideUser: null,
      }));
    }

    yield put(removeOfflineChatHandler(data.chatRequestId));
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);
  }
}

function* callReadOfflineMessageSaga({
  payload,
}: ReturnType<typeof readOfflineMessageHandler>) {
  const { chatId } = payload;
  try {
    yield call(API.Chat.openDM, chatId);
    yield put(setIsOfflineChatStartHandler(true, chatId));
    yield put(setIsVisibleHeaderBanner(false));
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
  }
}

export function* offlineChatSagas() {
  yield takeEvery(
    types.SET_IS_OFFLINE_CHAT_START_HANDLER,
    setIsOfflineChatStartHandlerWorker,
  );
  yield takeEvery(
    types.ADD_PARTICIPANT_OFFLINE_MESSAGE_HANDLER,
    addParticipantOfflineMessageHandlerWorker,
  );
  yield takeEvery(
    types.ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER,
    addParticipantOfflineMessageBackgroundHandlerWorker,
  );
  yield takeEvery(
    types.UPDATE_PARTICIPANT_MESSAGE_HANDLER,
    updateParticipantMessageHandlerWorker,
  );
  yield takeEvery(
    types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_HANDLER,
    updateParticipantOfflineMessageHandlerWorker,
  );
  yield takeEvery(
    types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND_HANDLER,
    updateParticipantOfflineMessageBackgroundHandlerWorker,
  );
  yield takeEvery(
    types.SET_MESSAGES_OFFLINE_HANDLER,
    setMessagesOfflineHandlerWorker,
  );
  yield takeEvery(
    types.SET_MESSAGES_BEFORE_OFFLINE_HANDLER,
    setMessagesBeforeOfflineHandlerWorker,
  );
  yield takeEvery(
    types.REMOVE_CURRENT_OR_NOT_OFFLINE_CHAT_HANDLER,
    removeCurrentOrNotOfflineChatHandlerWorker,
  );
  yield takeEvery(
    types.REMOVE_CURRENT_OFFLINE_CHAT_HANDLER,
    removeCurrentOfflineChatHandlerWorker,
  );
  yield takeEvery(
    types.REMOVE_OFFLINE_CHAT_HANDLER,
    removeOfflineChatHandlerWorker,
  );
  yield takeEvery(
    types.CLOSE_OFFLINE_CHAT_HANDLER,
    closeOfflineChatHandlerWorker,
  );
  yield takeEvery(
    types.SET_USER_CANCEL_OFFLINE_CHAT_HANDLER,
    setUserCancelOfflineChatHandlerWorker,
  );
  yield takeEvery(
    types.HANDLE_OFFLINE_MESSAGE_SENDING,
    handleOfflineMessageSendingWorker,
  );
  yield takeEvery(
    types.OPEN_OFFLINE_CHAT_BY_ID,
    openCurrentOfflineChatSaga,
  );
  yield takeEvery(
    types.DECLINE_OFFLINE_MESSAGE_REQUEST,
    callDeclineOfflineMessageSaga,
  );
  yield takeEvery(
    types.READ_OFFLINE_MESSAGE_HANDLER,
    callReadOfflineMessageSaga,
  );
}
