import { put } from 'redux-saga/effects';

import {
  MAXIMUM_ALLOWABLE_SECONDS_DIFFERENCE,
} from 'constants/constants';
import {
  checkDiffInTime,
  setDiffInTime,
} from 'actions/appActions';
import { getDiffTime } from 'src/utils/dateHandler';

export function* checkClientTimeWorker({
  payload: { date, currentDifferenceInTime },
}: ReturnType<typeof checkDiffInTime>) {
  const result = getDiffTime(date);
  const maxAllowableDifference = result > 0
    ? MAXIMUM_ALLOWABLE_SECONDS_DIFFERENCE
    : -MAXIMUM_ALLOWABLE_SECONDS_DIFFERENCE;

  const currenDiff = Number(currentDifferenceInTime);
  const timeValue = currenDiff ? (result + currenDiff) / 2 : result;

  const isMoreThanZero = timeValue > 0;
  const isSetDiffTimeValue = isMoreThanZero
    ? timeValue > maxAllowableDifference
    : timeValue < maxAllowableDifference;
  const difference = isSetDiffTimeValue ? timeValue : 0;
  yield put(setDiffInTime(difference));
}
