import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';
import { useSelector } from 'react-redux';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import {
  restoreActiveChat as _restoreActiveChat,
  setChatRequest as _setChatRequest,
} from 'actions/chatActions';
import { selectCustomerId, selectExtId } from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';

const ActiveChat = ({
  popUpNotificationData,
  showPopUpNotification,
  isNotificationPopUp,
}) => {
  const customerId = useSelector(selectCustomerId);
  const extId = useSelector(selectExtId);
  const setChatRequest = useBindDispatch(_setChatRequest);
  const restoreActiveChat = useBindDispatch(_restoreActiveChat);

  const handleAcceptButton = () => {
    restoreActiveChat();
  };

  const handleRejectButton = async () => {
    const isFinishCustomerActiveChats = true;

    setChatRequest(
      customerId,
      extId,
      isFinishCustomerActiveChats,
    );

    showPopUpNotification(false);
  };

  const acceptButton = {
    text: 'Return to Active Chat',
    color: ButtonColor.ORANGE,
    size: ButtonSize.S,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  };

  const rejectButton = {
    text: 'End Active Chat',
    color: ButtonColor.GREEN_OUTLINE,
    size: ButtonSize.S,
    shape: ButtonShape.ROUNDED,
    handler: handleRejectButton,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default ActiveChat;
