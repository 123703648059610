import * as types from 'actions/actionsTypes';
import { UpdateSchedule } from 'types/objectTypes';

export const updateChatAndPhoneStatus = (setEnabled: boolean) => ({
  type: types.UPDATE_CHAT_AND_PHONE_STATUS,
  payload: setEnabled,
});

export const updateChatEnabled = (setEnabled: boolean) => ({
  type: types.UPDATE_CHAT_ENABLED,
  payload: setEnabled,
});

export const updateChatStatus = (setEnabled: boolean) => ({
  type: types.UPDATE_CHAT_ONLY,
  payload: setEnabled,
});

export const updatePhoneStatus = (setEnabled: boolean) => ({
  type: types.UPDATE_PHONE_ONLY_STATUS,
  payload: setEnabled,
});

export const updateOfflineMessagesAvailability = (setEnabled: boolean) => ({
  type: types.UPDATE_OFFLINE_MESSAGES_AVAILABILITY,
  payload: setEnabled,
});

export const updateOfflineMessagesStatus = (setEnabled: boolean) => ({
  type: types.UPDATE_OFFLINE_MESSAGES_STATUS,
  payload: setEnabled,
});

export const setBreak = (command: string, duration?: number) => ({
  type: types.SET_BREAK,
  payload: {
    command,
    duration,
  },
});

export const updateBreak = (breakEnds, command?: string, isDelayed?: boolean) => ({
  type: types.UPDATE_BREAK,
  payload: {
    breakEnds,
    command,
    isDelayed,
  },
});

export const setPsychicStatus = (status: boolean, channel: number, command?: string, reason?) => ({
  type: types.SET_PSYCHIC_STATUS,
  payload: {
    status,
    channel,
    command,
    reason,
  },
});

export const endBreak = () => ({
  type: types.END_BREAK,
});

export const updatePsychicStatus = (status: boolean, channel: number, breakData?: object) => ({
  type: types.UPDATE_PSYCHIC_STATUS,
  payload: {
    status,
    channel,
    breakData,
  },
});

export const updateIsOnCall = (status: boolean) => ({
  type: types.UPDATE_IS_ON_CALL,
  payload: status,
});

export const updateIsOnChat = (status: boolean) => ({
  type: types.UPDATE_IS_ON_CHAT,
  payload: status,
});

export const earlyLogout = (payload: any) => ({
  type: types.EARLY_LOGOUT,
  payload,
});

export const updatePsychicSchedule = (schedule: UpdateSchedule) => ({
  type: types.UPDATE_PSYCHIC_SCHEDULE,
  payload: schedule,
});
