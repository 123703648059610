const worker = () => {
  /* eslint-disable-next-line no-restricted-globals */
  const ctx: Worker = self as any;
  let timerId = 0;

  enum WebWorkerMessage {
    WATCH_TOKEN_LIFETIME = 'watchTokenLifeTime',
    TOKEN_UPDATED = 'tokenUpdated',
    TOKEN_ABOUT_EXPIRED = 'tokenAboutExpired',
    CLEAR_INTERVAL = 'clearInterval',
  }

  const styles = [
    'color: #ba1b1d',
    'display: block',
    'text-align: center',
    'text-transform: uppercase',
    'font-size: 12px',
    'font-weight: bold',
  ].join(';');

  ctx.onmessage = (event: any) => {
    const { messageType, payload } = event.data || {};
    const TOKEN_CHAT_EXPIRATION_CHECK_PERIOD = payload.tokenChatExpirationCheckPeriod * 1000;
    const TOKEN_CHAT_EXPIRATION_GRACE_PERIOD = payload.tokenChatExpirationGracePeriod;

    switch (messageType) {
      case WebWorkerMessage.WATCH_TOKEN_LIFETIME: {
        const iatTwilioToken = payload.iatTime;

        if (timerId) {
          return;
        }

        timerId = setInterval(() => {
          const currentTime = Date.now() / 1000;
          const isLessThenFifteenHours = currentTime - iatTwilioToken
            < TOKEN_CHAT_EXPIRATION_GRACE_PERIOD;

          if (!isLessThenFifteenHours) {
            clearInterval(timerId);
            timerId = 0;
            console.log('%c TWILIO TOKEN WAS UPDATED AFTER HALF AN HOUR', styles);
            console.log(new Date());

            ctx.postMessage({ messageType: WebWorkerMessage.TOKEN_ABOUT_EXPIRED });
          } else {
            console.log('%c TWILIO TOKEN IS VALID', styles);
            console.log(new Date());
          }
        }, TOKEN_CHAT_EXPIRATION_CHECK_PERIOD);

        break;
      }
      default: break;
    }
  };
};

let code = worker.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });

export default URL.createObjectURL(blob);
