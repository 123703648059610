import React from 'react';
import { Button } from '@mui/material';

import { ReactComponent as DoneIcon } from 'src/assets/svg/done-icon.svg';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import HighlightLabel from 'components/Labels/HighlightedLabel';
import { truncateString } from 'src/utils/commonUtil';
import { PRIORITY_ACTION_CHARACTER_LIMIT, PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { INoteSentPopup } from 'types/componentTypes';
import styles from 'components/Popups/ConfirmationScreenPopup/ConfirmationScreenPopup.module.scss';

const NoteSentPopup: React.FC<INoteSentPopup> = ({
  handleReturnButton,
  handleComposeNewButton,
  handleSendNoteToPriorityClient,
  nextPriorityAction,
  customerName,
}) => {
  const {
    clientType,
    customerDisplayName,
    actionItem,
  } = nextPriorityAction;

  return (
    <div className={styles.newWrapper}>
      <Button
        onClick={handleComposeNewButton}
        className={styles.backButton}
      >
        +
      </Button>
      <div className={styles.priorityActionText}>
        <div className={styles.clientNameWrapper}>
          <DoneIcon className={styles.priorityActionIcon} />
          <span className={styles.clientName}>{`Sent note to ${customerName} 🎉`}</span>
        </div>
        <Button
          onClick={(e) => handleReturnButton(e, true)}
          className={styles.priorityActionBack}
          id={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
        >
          Go to Priority Actions
        </Button>
      </div>
      <div className={styles.priorityActionContainer}>
        <span className={styles.priorityActionNext}>Up next:</span>
        <div className={styles.priorityActionWrapper}>
          <ClientTypeLable clientType={clientType} />
          <HighlightLabel
            text={truncateString(actionItem, PRIORITY_ACTION_CHARACTER_LIMIT)}
            wordToBold={customerDisplayName}
            className={styles.priorityActionLabel}
          />
          <Button
            onClick={handleSendNoteToPriorityClient}
            className={styles.sendButton}
            id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
          >
            Send Note
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoteSentPopup;
