import axios from 'axios';
import jwt from 'jwt-decode';
import {
  DIFFERENCE_IN_TIME,
  REFRESH_TOKEN,
  TOKEN_BEARER,
} from 'extracted-chat-components/constants';

import {
  Resp,
  CustomerResp,
  PsychicResp,
} from 'types/objectTypes';
import { TEN_SECONDS } from 'constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';

export const receivePsychicAuthData = async (
  username: string,
  password: string,
  refreshToken?: string,
) => {
  const url = `${process.env.PSYCHIC_API}/auth?platform=PCP`;
  let authData: Resp;
  const getAuthDataWithCreds = async (): Promise<Resp> => axios.get(url, {
    headers: {
      Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
    },
  });

  const getAuthDataWithRefresh = async (): Promise<Resp> => axios.get(url, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (refreshToken) {
    authData = await getAuthDataWithRefresh();
  } else {
    authData = await getAuthDataWithCreds();
  }

  return authData.data;
};

export const receivePsychicToken = async (id, bearerToken: string) => {
  const body = {
    deviceId: '3410a42b8f68cf08',
    extId: id,
  };
  const url = `${process.env.PSYCHIC_API}/twilio/token`;
  const token: PsychicResp = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });

  return token.data.result.twilioToken;
};

export const receiveCustomerAuthData = async (username: string, password: string) => {
  const url = `${process.env.CALIFORNIA_PSYCHICS_API}/auth`;
  const authData: Resp = await axios.get(url, {
    headers: {
      Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
    },
  });

  return authData.data;
};

export const receiveCustomerToken = async (id, bearerToken: string) => {
  const body = {
    DeviceId: '3410a42b8f68cf08',
    customerId: id,
  };
  const url = `${process.env.CALIFORNIA_PSYCHICS_API}/chat/token`;

  const token: CustomerResp = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  });

  return token.data.token;
};

export const parseToken = async (token) => {
  const tokenDecoded = await jwt(token);

  return tokenDecoded;
};

const refreshBearerToken = async (refreshToken) => {
  const authData: any = await receivePsychicAuthData('', '', refreshToken);
  const { bearerToken } = authData || {};
  const newRefreshToken = authData?.meta?.refreshToken;

  LocalStorage.setItem(TOKEN_BEARER, bearerToken);
  LocalStorage.setItem(REFRESH_TOKEN, newRefreshToken);

  return bearerToken;
};

export const validateBearer = async (token, errorThrown?: boolean) => {
  const refreshToken = LocalStorage.getItem(REFRESH_TOKEN) as string;

  if (errorThrown) {
    return refreshBearerToken(refreshToken);
  }

  const { exp } = await parseToken(token);

  const diffInTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME));
  const currentTime = new Date();
  const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffInTime);
  const time = new Date(correctedTime);
  const isExpired = exp * 1000 < time.getTime() - TEN_SECONDS;

  if (isExpired) {
    return refreshBearerToken(refreshToken);
  }

  return token;
};
