import React from 'react';

import Illustration from 'assets/png/Illustration.png';
import ArrowDownSign from 'assets/png/arrowDownSign.png';
import styles from 'components/Banners/EmptyListBanner/EmptyListBanner.module.scss';

const EmptyListBanner = () => (
  <div className={styles.wrapper}>
    <div className={styles.textWrapper}>
      <span className={styles.firstText}>
        How it works:
      </span>
      <ul className={styles.lastTextWrapper}>
        <li> inform clients when you will be available </li>
        <li> follow up to encourage future readings </li>
        <li> build strong relationships with your clients </li>
      </ul>
    </div>
    <img
      className={styles.illustrationImage}
      src={Illustration}
      alt="Illustration"
    />
    <img
      className={styles.arrowUpSignImage}
      src={ArrowDownSign}
      alt="ArrowDownSign"
    />
  </div>
);

export default EmptyListBanner;
