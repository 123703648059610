import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import DashboardIcon from 'assets/svg/dashboard-icon.svg';
import CalendarIcon from 'assets/svg/calendar-icon.svg';
import LogoutButton from 'components/Buttons/LogoutButton';
import LiveChatIcon from 'assets/svg/Live-chat-icon.svg';
import EditSettingsIcon from 'assets/svg/edit-settings-icon.svg';
import PsychicInbox from 'assets/svg/psychic-inbox-icon.svg';
import ReportIssue from 'assets/svg/report-issue-icon.svg';
import { PAGE_ID } from 'constants/constants';
import SideBarButton from 'components/Buttons/SideBarButton';
import ReportIssuePopup from 'components/Popups/ReportIssuePopup';
import { selectIsLiveChatActive, selectNotificationReactionUnreadCount } from 'selectors/selectors';
import { getDashboardPsychicNews as _getDashboardPsychicNews } from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import PsychicNoteNavigationButton from 'components/Buttons/SideBarButton/PsychicNoteNavigationButton';

import style from './Sidebar.module.scss';

const SideBar = ({
  onChangePage,
  activePage,
  isOpen,
  children,
}) => {
  const [issueEmailLink, setIssueEmailLink] = useState<string>('');
  const [isVisibleReportPopup, setIsVisibleReportPopup] = useState(false);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const reactionUnreadCount = useSelector(selectNotificationReactionUnreadCount);

  const getDashboardPsychicNews = useBindDispatch(_getDashboardPsychicNews);

  const onClick = (e) => {
    e.preventDefault();

    if (!isLiveChatActive) {
      const { id } = e.currentTarget;

      if (id === PAGE_ID.DASHBOARD_PAGE) {
        getDashboardPsychicNews();
      }

      onChangePage(id);
    }
  };

  const onClickReportIssue = useCallback(() => {
    if (!isLiveChatActive) {
      setIsVisibleReportPopup(true);
      // eslint-disable-next-line no-restricted-globals
      location.href = issueEmailLink;
    }
  }, [issueEmailLink, isLiveChatActive]);

  return (
    <div className={style.sidebarWrapper}>
      <div
        className={cn(style.sidebarContent, {
          [style.sidebarContentOpen]: isOpen,
        })}
      >
        <div className={style.sidebarButtonContainer}>
          <SideBarButton
            disabled={isLiveChatActive}
            title="Chats, OM & Notifications"
            isActive={activePage === PAGE_ID.CHATS_PAGE}
            image={LiveChatIcon}
            pageId={PAGE_ID.CHATS_PAGE}
            onChangePage={onClick}
          />
          <PsychicNoteNavigationButton
            disabled={isLiveChatActive}
            isActive={activePage === PAGE_ID.PSYCHIC_INBOX}
            reactionUnreadCount={reactionUnreadCount}
            image={PsychicInbox}
            pageId={PAGE_ID.PSYCHIC_INBOX}
            onChangePage={onClick}
          />
          <SideBarButton
            disabled={isLiveChatActive}
            title="Manage Schedules"
            isActive={activePage === PAGE_ID.MANAGE_SCHEDULES}
            image={CalendarIcon}
            pageId={PAGE_ID.MANAGE_SCHEDULES}
            onChangePage={onClick}
          />
          <SideBarButton
            disabled={isLiveChatActive}
            title="Dashboard"
            isActive={activePage === PAGE_ID.DASHBOARD_PAGE}
            image={DashboardIcon}
            pageId={PAGE_ID.DASHBOARD_PAGE}
            onChangePage={onClick}
          />
          <SideBarButton
            disabled={isLiveChatActive}
            title="Edit Settings"
            isActive={activePage === PAGE_ID.SETTINGS_PAGE}
            image={EditSettingsIcon}
            pageId={PAGE_ID.SETTINGS_PAGE}
            onChangePage={onClick}
          />
          <SideBarButton
            disabled={isLiveChatActive}
            title="Report an Issue"
            isActive={isVisibleReportPopup}
            image={ReportIssue}
            pageId={PAGE_ID.REPORT_ISSUE}
            onChangePage={onClickReportIssue}
          />
        </div>
        <LogoutButton className={style.logoutButton} />
        <ReportIssuePopup
          issueEmailLink={issueEmailLink}
          setIssueEmailLink={setIssueEmailLink}
          isVisibleReportPopup={isVisibleReportPopup}
          setIsVisibleReportPopup={setIsVisibleReportPopup}
        />
      </div>
      {children.find((element) => element.key === activePage)}
    </div>
  );
};

export default SideBar;
