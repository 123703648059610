import { ChatCompletedReason, MixpanelEvents } from 'constants/enums';

import MixpanelActions from './mixpanel';

const setMixpanelEventButtonTapped = (screenName: string, buttonText?: string) => {
  MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
    button_text: buttonText || 'x close',
    button_type: 'x close icon',
    screen_name: screenName,
    screen_title: 'chat window',
  });
};

export const setMixpanelPromptDeliveredEvent = (promptText: string, promptType: string) => {
  MixpanelActions.track(MixpanelEvents.PROMPT_DELIVERED, {
    prompt_text: promptText,
    prompt_type: promptType,
    screen_title: 'chat window',
  });
};
export const setMixPanelEventsChatCompleted = (reason: string, type: string) => {
  const isPromptDelivered = type === MixpanelEvents.PROMPT_DELIVERED;

  switch (reason) {
    case ChatCompletedReason.CUSTOMER_FINISHED_CHAT: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent('thank you', 'chat ended by customer banner')
        : setMixpanelEventButtonTapped('chat ended by customer banner');

      break;
    }
    case ChatCompletedReason.INACTIVITY: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent(
          'your chat was ended due to inactivity',
          'chat ended due to inactivity banner',
        )
        : setMixpanelEventButtonTapped('chat ended due to inactivity banner');

      break;
    }
    case ChatCompletedReason.CHAT_TASK_CANCELLED_LOW_FUNDS: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent(
          'your chat has been ended automatically/cs',
          'chat ended automatically/cs banner',
        )
        : setMixpanelEventButtonTapped('chat ended automatically/cs banner', 'x');

      break;
    }
    case ChatCompletedReason.LOW_FUNDS: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent(
          'your chat was ended due to low funds',
          'chat ended due to low funds banner',
        )
        : setMixpanelEventButtonTapped('chat ended due to low funds banner');

      break;
    }
    case ChatCompletedReason.PSYCHIC_FINISHED_CHAT: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent(
          'your chat has been ended by the psychic',
          'chat ended by psychic banner',
        )
        : setMixpanelEventButtonTapped('chat ended by psychic banner');

      break;
    }
    case ChatCompletedReason.UPCOMING_APPOINTMENT: {
      isPromptDelivered
        ? setMixpanelPromptDeliveredEvent(
          'chat has ended automatically',
          'chat ended by upcoming appointment',
        )
        : setMixpanelEventButtonTapped('chat ended by upcoming appointment');

      break;
    }
    default: break;
  }
};
