import React from 'react';

import { ReactComponent as BackArrow } from 'src/assets/svg/back-button.svg';
import styles from 'src/components/Popups/InsufficientFundsPopup/PopupComponents/commonStyles.module.scss';

const HeaderButton = ({ onClick, psychicName }) => (
  <div className={styles.wrapper}>
    <button
      type="button"
      onClick={onClick}
      className={styles.backButton}
      aria-label="back"
    >
      <BackArrow />
    </button>
    <span className={styles.psychicName}>
      {psychicName}
    </span>
  </div>
);

export default HeaderButton;
