import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation } from 'react-router';

import ClientViewContainer from 'containers/ClientViewContainer';
import PsychicViewContainer from 'containers/PsychicViewContainer';
import { useInternetChecker } from 'src/hooks/useInternetChecker';
import { useObtainCountryCodes } from 'src/hooks/useObtainCountryCodes';
import { useTwilioCheckerWebworker } from 'src/hooks/useTwilioTokenCheckerWorker';
import { NotificationsText, View } from 'constants/enums';
import { IS_MOBILE_INTEGRATION } from 'constants/constants';
import {
  setView as _setView,
  handleRequestError as _handleRequestError,
} from 'actions/appActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { descriptionFromError } from 'src/utils/commonUtil';
import { selectTwilioClient, selectView } from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import LoginFormContainer from 'containers/LoginFormContainer';

const ChatAppContainer = () => {
  const { search } = useLocation();

  const viewMode = useSelector(selectView) as View;
  const twilioClient = useSelector(selectTwilioClient);

  const setView = useBindDispatch(_setView);
  const handleRequestError = useBindDispatch(_handleRequestError);
  const [isHotJarLoaded, setHotJarScriptLoad] = useState<boolean>(false);
  const isCustomer = viewMode === View.CUSTOMER;

  useInternetChecker(twilioClient, viewMode, handleRequestError);
  useTwilioCheckerWebworker(twilioClient, descriptionFromError, handleRequestError);

  useObtainCountryCodes(viewMode);
  useInternetChecker(
    twilioClient,
    viewMode,
    handleRequestError,
  );

  useEffect(() => {
    const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);
    const isHotJarEnabled = process.env.IS_ENABLE_HOT_JAR;
    const hotJarID = process.env.HOT_JAR_ID;

    if (!isHotJarLoaded && isHotJarEnabled && isCustomer && !isMobileIntegration) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
      (function(h,o,t,j,a,r) {
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotJarID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
      document.body.appendChild(script);
      setHotJarScriptLoad(true);
    }
  }, [isHotJarLoaded]);

  useEffect(() => {
    const errorMessages: string[] = [
      NotificationsText.USER_WAS_N0T_FOUND,
    ];
    const titleNotification = LocalStorage.getItem('title');
    const errorMessage = errorMessages.find((message) => message === titleNotification);

    if (errorMessage) {
      const requestErrorPayload = {
        redirectPath: '',
        isInvalidToken: false,
        errorText: errorMessage,
        description: descriptionFromError(errorMessage),
      };

      handleRequestError(requestErrorPayload);
    }
  }, []);

  useEffect(() => {
    const params = qs.parse(search);
    const view = params.view as string;

    if (view && !viewMode) {
      setView(view === View.CUSTOMER.toLowerCase() ? View.CUSTOMER : View.PSYCHIC);
    }
  }, [search]);

  if (viewMode === View.CUSTOMER) {
    return <ClientViewContainer />;
  }

  if (viewMode === View.PSYCHIC) {
    return <PsychicViewContainer />;
  }

  return <LoginFormContainer />;
};

export default ChatAppContainer;
