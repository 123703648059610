import {
  put,
  select,
} from 'redux-saga/effects';

import { PopUpNotificationType } from 'constants/enums';
import {
  selectPopNotification,
  selectSystemChannel,
} from 'selectors/selectors';
import {
  setPopUpNotificationData,
  showPopUpNotification,
} from 'actions/appActions';

export function* handleNotificationsVisibility({ payload }: ReturnType<any>) {
  const { attributes, index } = payload;
  const systemChannel = yield select(selectSystemChannel);

  if (!systemChannel) {
    return;
  }

  const messagesItems = yield systemChannel.getMessages(1, index);
  const message = messagesItems.items[0];

  const newAttributes = { ...attributes, isSeen: true };

  message.updateAttributes(newAttributes);
}

export function* handlePopUpNotification({ payload }: ReturnType<any>) {
  const {
    isVisible,
    title,
    description,
    reason,
    notificationType,
    callback,
  } = payload;

  const currentPopUpNotification = yield select(selectPopNotification);
  const { notificationType: currentNotificationType } = currentPopUpNotification;
  const isLowFundsPopUp = notificationType === PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS;
  const isInactivityPopUp = currentNotificationType === PopUpNotificationType.DUE_TO_INACTIVITY;
  const isDueToInactivityPopUp = notificationType === PopUpNotificationType.DUE_TO_INACTIVITY;
  const isNotHaveEnoughPopUp = currentPopUpNotification.notificationType
    === PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS;
  const newNotification = {
    title,
    description,
    notificationType,
    reason: reason || '',
    callback: callback || null,
  };

  let notifications;

  if (isLowFundsPopUp && isInactivityPopUp) {
    notifications = [currentPopUpNotification, newNotification];
  } else if (isDueToInactivityPopUp && isNotHaveEnoughPopUp) {
    notifications = [newNotification, currentPopUpNotification];
  } else {
    notifications = newNotification;
  }

  yield put(setPopUpNotificationData(notifications));
  yield put(showPopUpNotification(isVisible));
}
