import { select, takeEvery } from 'redux-saga/effects';

import { providePhoneNumber } from 'actions/appActions';
import { SdkChatEvent } from 'constants/enums';
import { selectSdkCallbackStorage } from 'selectors/selectors';
import { SWITCH_TO_PHONE_SDK } from 'src/redux/actions/actionsTypes';

function* handleSwitchToPhoneSdk({ phoneNumber }: ReturnType<typeof providePhoneNumber>) {
  const callbacks = yield select(selectSdkCallbackStorage);
  const switchToPhoneCallback = callbacks.get(SdkChatEvent.ON_SWITCH_TO_PHONE);

  if (switchToPhoneCallback) {
    switchToPhoneCallback({
      name: SdkChatEvent.ON_SWITCH_TO_PHONE,
      phoneNumber,
    });
  } else {
    window.open(`tel:${phoneNumber}`, '_parent');
  }
}

export function* sdkSagas() {
  yield takeEvery(SWITCH_TO_PHONE_SDK, handleSwitchToPhoneSdk);
}
