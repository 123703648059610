import React from 'react';

import { PNNotificationStatus } from 'constants/enums';
import { formatToUSDateOnly, formatToUSTime } from 'src/utils/dateHandler';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import { truncateString } from 'src/utils/commonUtil';
import { CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT } from 'constants/constants';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import LabelTags from 'components/Tags/LabelTags';

import styles from './ClientNotesHeaderForPsychic.module.scss';

const ClientNotesHeaderForPsychic = ({ headerDetails, lastSeen, clientLabels }) => {
  const {
    displayname,
    lastReadingDate,
    zodiacSign,
    notificationStatus,
    customerType,
  } = headerDetails;
  const zodiacImage = zodiacMap.get(zodiacSign.toLocaleLowerCase());
  const lastReadingShortDate = `${formatToUSDateOnly(lastReadingDate)}  ${formatToUSTime(lastReadingDate)}`;
  const isMuted = notificationStatus === PNNotificationStatus.MUTED;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.zodiacIconWrapper}>
          <div>
            <img src={zodiacImage} alt="img" className={styles.zodiacIcon} />
          </div>
          <div className={styles.headerNameMuteWrapper}>
            <div className={styles.headerNameMute}>
              <span className={styles.customerName}>
                {truncateString(displayname, CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT)}
              </span>
              { isMuted && <span className={styles.muteFlag}>Muted</span> }
              { lastSeen && <br /> }
              <span className={styles.customerLastSeen}>
                { lastSeen }
              </span>
            </div>
            <ClientTypeLable clientType={customerType} className={styles.customerTypeLabel} />
          </div>
        </div>
        <div className={styles.infoLastReading}>
          Last reading:
          <br />
          { lastReadingShortDate }
        </div>
      </div>
      <LabelTags
        isClientNotes
        labels={clientLabels}
      />
    </div>
  );
};

export default ClientNotesHeaderForPsychic;
