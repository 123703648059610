import mixpanel from 'mixpanel-browser';

import { Environment } from 'constants/enums';
import { deepLowerCase } from 'src/utils/commonUtil';

const isProd = process.env.NODE_ENV === Environment.PRODUCTION;

const filterPsychicRelatedPropsFromPersistentCache = () => {
  mixpanel.PSYCHIC_MIX_PANEL.unregister('chat_platform', { persistent: true });
  mixpanel.PSYCHIC_MIX_PANEL.unregister('chat_source', { persistent: true });
  mixpanel.PSYCHIC_MIX_PANEL.unregister('user_type', { persistent: true });
  mixpanel.PSYCHIC_MIX_PANEL.unregister('customerId', { persistent: true });
  mixpanel.PSYCHIC_MIX_PANEL.unregister('psychic_circle', { persistent: true });
  mixpanel.PSYCHIC_MIX_PANEL.unregister('psychic_favorite', { persistent: true });
};

const MixpanelPsychicActions = {
  init: () => {
    if (isProd) {
      mixpanel.init(process.env.MIXPANEL_PSYCHIC_TOKEN, null, 'PSYCHIC_MIX_PANEL');
    }
  },

  register: async ({
    extId,
    friendlyName,
    customerPrice,
  }) => {
    if (isProd) {
      mixpanel.PSYCHIC_MIX_PANEL.register(deepLowerCase({
        psychic_id: extId,
        psychic_name: friendlyName,
        psychic_rate: customerPrice,
      }));
    }
  },

  identify: (id) => {
    if (isProd) mixpanel.PSYCHIC_MIX_PANEL.identify(id);
  },

  alias: (id) => {
    if (isProd) mixpanel.PSYCHIC_MIX_PANEL.alias(id);
  },

  track: async (name, props = {}) => {
    const lowerCaseProps = deepLowerCase(props);

    if (isProd) {
      filterPsychicRelatedPropsFromPersistentCache();
      mixpanel.PSYCHIC_MIX_PANEL.track(name, {
        ...lowerCaseProps,
      });
    }
  },

  people: {
    set: (props) => {
      if (isProd) mixpanel.PSYCHIC_MIX_PANEL.people.set(props);
    },
  },

};

export default MixpanelPsychicActions;
