import React from 'react';

import PsychicLoginOutsideSchedule from './LoginOutsideSchedule';

const PsychicEarlyLogin = ({ isOpen, setTrigger, reason }) => (
  <PsychicLoginOutsideSchedule
    isOpen={isOpen}
    setTrigger={setTrigger}
    reason={reason}
  />
);

export default PsychicEarlyLogin;
