/* eslint-disable no-param-reassign */
import React from 'react';

import { NotificationType, View } from 'src/constants/enums';
import { SystemMessage } from 'components/Messages/Message';

const SystemMessageCases = ({
  isPreviousOffline,
  attributes,
  message,
  view,
  getBillingMessage,
  getEndChatWithTimestampNotification,
  getJoinedAndTimestampNotification,
  getMessageNotifications,
}) => {
  const isPrevOffline = isPreviousOffline.current;
  isPreviousOffline.current = false;

  if (!attributes) {
    return;
  }

  if (attributes.isBillingStarted) {
    return getBillingMessage(message, isPrevOffline);
  }

  if (attributes.timestamp && attributes.completionReason) {
    return getEndChatWithTimestampNotification(
      attributes,
      isPrevOffline,
      message.sid,
    );
  }

  const isJoinedSystemMessage = attributes.notificationType?.includes('joined');

  if (isJoinedSystemMessage) {
    const isOfflineWasPreviousForCustomer = view === View.CUSTOMER
      && attributes.notificationType === NotificationType.CUSTOMER_JOINED
      && isPrevOffline;

    if (isOfflineWasPreviousForCustomer) {
      isPreviousOffline.current = true;
    }

    return getJoinedAndTimestampNotification(attributes, isPrevOffline, message.sid)
      || null;
  }

  const messageNotification = getMessageNotifications(
    attributes.notificationType,
    message,
    attributes,
  );

  if (attributes.notificationType === NotificationType.ADD_FUNDS_REQUEST) {
    return messageNotification;
  }

  if (!messageNotification) return;

  return (
    <SystemMessage
      key={message.sid}
      message={messageNotification}
      isPreviousOffline={isPrevOffline}
    />
  );
};

export default React.memo(SystemMessageCases);
