import * as Bowser from 'bowser';

import { IS_ELECTRON_STORAGE } from 'constants/constants';
import {
  ARNotificationType,
  ChatRequest,
  ConversationType,
  NotificationType,
  PopUpNotification,
  PopUpNotificationType,
  View,
} from 'src/constants/enums';
import { ChatType, AccumulatedActiveMessages } from 'src/types/objectTypes';

import { LocalStorage } from './storageHandler';

export const convertQueryParamsToLowercase = (params: object) => {
  const newParamsObject: any = {};

  Object.keys(params).forEach((key) => {
    newParamsObject[key?.toLowerCase()] = params[key];
  });

  return newParamsObject;
};

export const resizeWindowForAndroid = () => {
  const userAgent = Bowser.parse(window.navigator.userAgent);
  const browserName = userAgent.browser.name;

  if (browserName === 'Samsung Internet for Android') {
    const root: HTMLDivElement | null = document.querySelector('#root');

    if (root) root.style.height = `${window.innerHeight}`;

    window.addEventListener('resize', () => {
      if (root) root.style.height = `${window.innerHeight}`;
    });
  }
};

export const accumulateActivePsychicChats = (
  chats: Array<ChatType>,
  currentChatId?: string,
): AccumulatedActiveMessages => {
  const reducer = (accumulator: AccumulatedActiveMessages, currentChat: ChatType) => {
    if (currentChat.type === ConversationType.DIRECT_MESSAGE) {
      accumulator.offlines.push(currentChat);

      if (currentChat.chatId === currentChatId) {
        accumulator.offline = currentChat;
      }
    } else if (currentChat.type === ConversationType.LIVE_CHAT) {
      accumulator.live = currentChat;
    }

    return accumulator;
  };
  const initAccumulator: AccumulatedActiveMessages = { offlines: [] };

  return chats.reduce(reducer, initAccumulator);
};

export const deepEqual = (firstObject: any, secondObject: any) => {
  if (firstObject === secondObject) {
    return true;
  }

  if ((typeof firstObject === 'object' && firstObject != null)
    && (typeof secondObject === 'object' && secondObject != null)) {
    const firstKeys = Object.keys(firstObject);
    const secondKeys = Object.keys(secondObject);

    if (firstKeys.length !== secondKeys.length) return false;

    for (let i = 0; i < firstKeys.length; i += 1) {
      if (Object.prototype.hasOwnProperty.call(secondObject, firstKeys[i])) {
        if (!deepEqual(firstObject[firstKeys[i]], secondObject[firstKeys[i]])) return false;
      } else {
        return false;
      }
    }

    return true;
  }

  return false;
};

export function findLastElementIndex<T>(
  array: Array<T>,
  func: (element: T) => boolean,
): number {
  let i = array.length - 1;

  while (i > 0) {
    const element = array[i];
    const isFitCondition = func(element);

    if (isFitCondition) return i;

    i -= 1;
  }

  return -1;
}

export const roundToWhole = (sum: string) => {
  const funds = String(sum);
  const arrNumbers = funds.split('');
  const pointIndex = arrNumbers.findIndex((e) => e === '.');

  return arrNumbers[pointIndex + 1] === '0' ? +funds : funds;
};

export const filterChats = (chats, callback) => chats.reduce((result, element, i) => {
  callback(element, i, chats) ? result[0].push(element) : result[1].push(element);

  return result;
}, [[], []]);

export const filterByExistingChat = (main, filterBy) => main.filter((chatFromState) => (
  filterBy.filter((chatFromPayload) => chatFromPayload.id === chatFromState.id).length === 0));

export const getPsychicInfoMixpanel = (user) => {
  const participantType = user?.attributes?.participantType;

  if (!participantType || participantType !== View.PSYCHIC) {
    return {};
  }

  return {
    psychic_bio_video: user.psychicBioVideo,
    psychic_circle: user.psychicCircle,
    psychic_favorite: user.psychicIsFavorite,
    psychic_rating: user.psychicRating,
    is_test_psychic: user?.isTestPsychic === 'y',
    psychic_rate: user.customerPrice,
    psychic_name: user.lineName,
    psychic_id: user.extId,
  };
};

export const getPsychicInfoMixpanelForCustomer = (sideUser) => {
  if (!sideUser) {
    return {};
  }

  return {
    psychic_bio_video: sideUser.psychicBioVideo,
    psychic_favorite: sideUser.psychicIsFavorite,
    psychic_id: sideUser.extId,
    psychic_name: sideUser.lineName,
    psychic_rate: sideUser.customerPrice,
    psychic_rating: sideUser.psychicRating,
    is_test_psychic: sideUser.isTestPsychic === 'y',
  };
};

export const getPsychicNotificationInfoMixpanel = (user) => {
  const participantType = user?.attributes?.participantType;

  if (!participantType || participantType !== View.PSYCHIC) {
    return {};
  }

  const isTestPsychic = user?.isTestPsychic === 'y';

  return {
    psychic_bio_video: user.psychicBioVideo,
    psychic_rating: user.psychicRating,
    is_test_psychic: isTestPsychic,
  };
};

export const checkCustomerIsBusyNotification = (notificationType: PopUpNotification): boolean => {
  const notifications = [
    PopUpNotification.ACTIVE_CHAT,
    PopUpNotification.ACTIVE_PHONE_CALL,
  ];

  return notifications.includes(notificationType);
};

export const checkIsChatInInitState = (notificationType) => {
  const notifications = [
    NotificationType.CHECKING_FOR_BALANCE,
    NotificationType.CONNECTING_CHAT,
    NotificationType.CHAT_START,
    NotificationType.CHAT_COMPLETED,
    NotificationType.CONNECTING_CHAT,
    ChatRequest.CHAT_REQUEST_CREATED,
    ChatRequest.CHAT_REQUEST_ACCEPTED,
    ChatRequest.CHAT_REQUEST_EXPIRED,
    ARNotificationType.AUTO_RELOAD_RUNS,
  ];

  return notifications.includes(notificationType);
};

export const getMixpanelScreenName = (screenName) => {
  switch (screenName) {
    case PopUpNotificationType.SWITCH_TO_PHONE:
      return 'switch to phone';
    default:
      return 'chat window';
  }
};

/**
 * iPad with IOS 12 treat height: 100% at .plainContainer class in wrong way.
 * It better works with -webkit height. But other devices with not 12 IOS
 * process height 100% right. Additionally, IOS 10 do not treat -webkit height.
 * Due those facts iPad with IOS 12 has separate styles.
 */
export const isIPadOs12Plus = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const os = browser.getOS();
  const device = browser.getPlatform();

  const intVersion = os?.version && +os.version.substring(0, 2);

  return intVersion === 12 && device?.model?.toLowerCase() === 'ipad';
};

// @ts-ignore
export const isElectron: boolean = JSON.parse(LocalStorage.getItem(IS_ELECTRON_STORAGE));

/**
 * This method is used to replace the HTML BR Tag from string.
 */
export const replaceBrTag = (string: string): string => (
  string.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '')
);
