import process from 'process';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InternetConnectionEvents } from 'extracted-chat-components/enums';

import { Environment } from 'constants/enums';
import {
  selectCurrentUser,
  selectInternetConnectionStatus,
  selectAuthToken,
  selectTwilioToken,
} from 'selectors/selectors';
import { selectConsoleErrorLogs } from 'selectors/diagnosticSelector';
import { setConsoleErrorLogs as _setConsoleErrorLogs } from 'actions/diagnosticAction';
import {
  formatDateTimeIn2Digit,
  calculateDateDiffSeconds,
  formatTokenTimeStampToDate,
} from 'src/utils/dateHandler';
import { parseToken } from 'src/utils/authHandler';
import useBindDispatch from 'src/hooks/useBindDispatch';

import ReportIssuePopupModal from './ReportIssuePopupModal';

const ReportIssuePopup = ({
  issueEmailLink,
  setIssueEmailLink,
  isVisibleReportPopup,
  setIsVisibleReportPopup,
}) => {
  const { friendlyName } = useSelector(selectCurrentUser);
  const authToken = useSelector(selectAuthToken);
  const twilioToken = useSelector(selectTwilioToken);
  const internetConnection = useSelector(selectInternetConnectionStatus);
  const consoleErrorLogs = useSelector(selectConsoleErrorLogs);

  const environment = process.env.DATADOG_ENVIRONMENT;
  const envName = environment !== Environment.PRODUCTION ? ` - ${environment?.toUpperCase()}` : '';
  const emailSubject = `PDA${envName} | Report a bug from ${friendlyName}`;

  const [authCreateDate, setAuthCreateDate] = useState<string>('');
  const [authExpireDate, setAuthExpireDate] = useState<string>('');
  const [twilioCreateDate, setTwilioCreateDate] = useState<string>('');
  const [twilioExpireDate, setTwilioExpireDate] = useState<string>('');
  const [internetStatus, setInternetStatus] = useState<string>('Connected');

  const setConsoleErrorLogs = useBindDispatch(_setConsoleErrorLogs);

  useEffect(() => {
    setInternetStatus(
      internetConnection === InternetConnectionEvents.ONLINE ? 'Connected' : 'Not Connected',
    );
  }, [internetConnection]);

  const decodeTokens = async () => {
    let authDetails: any;
    let twilioDetails: any;

    if (authToken !== '' && twilioToken !== '') {
      authDetails = await parseToken(authToken);
      twilioDetails = await parseToken(twilioToken);
      setAuthExpireDate(formatTokenTimeStampToDate(authDetails.exp));
      setAuthCreateDate(formatTokenTimeStampToDate(authDetails.iat));
      setTwilioExpireDate(formatTokenTimeStampToDate(twilioDetails.exp));
      setTwilioCreateDate(formatTokenTimeStampToDate(twilioDetails.iat));
    }
  };

  useEffect(() => {
    decodeTokens();
  }, [authToken, twilioToken]);

  const getFormatErrorLogs = (errors) => {
    const logsList: any = [];

    // eslint-disable-next-line no-console
    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i += 1) {
        const errorLog = errors[i];

        if (Array.isArray(errorLog) && errorLog.length) {
          const errorLogDetails = errorLog[0];

          if (errorLogDetails?.config) {
            const { url, method, data: payload } = errorLogDetails.config;
            const formattedLog = {
              url,
              method,
              ...(payload && { payload }),
              errorStack: errorLogDetails.toJSON().stack,
            };
            logsList.push(formattedLog);
          } else {
            logsList.push(errorLogDetails);
          }
        } else {
          logsList.push(errorLog);
        }
      }
    }

    return logsList.length ? JSON.stringify(logsList) : null;
  };

  useEffect(() => {
    let errorLog = '';
    const formatErrorLogs = getFormatErrorLogs(consoleErrorLogs);

    if (formatErrorLogs) {
      errorLog = `%0D%0A-----------------------Error Logs-----------------------%0D%0A${formatErrorLogs}%0D%0A`;
    }

    const validAuthToken = calculateDateDiffSeconds(
      new Date(authExpireDate),
      new Date(),
    ) > 0 ? 'Valid' : 'Invalid';

    const validTwilioToken = calculateDateDiffSeconds(
      new Date(twilioExpireDate),
      new Date(),
    ) > 0 ? 'Valid' : 'Invalid';

    const link = `mailto:pm@californiapsychics.com?subject=${emailSubject}
      &body=Please describe in your own words what issues you are experiencing
      %0D%0A%0D%0A-------------- Diagnostic Information --------------
      %0D%0A Internet Status: ${internetStatus}
      %0D%0A Chat Access Token:
      %0D%0A # Token Status: ${validAuthToken}
      %0D%0A # Created Date: ${formatDateTimeIn2Digit(authCreateDate)}
      %0D%0A # Expire Date: ${formatDateTimeIn2Digit(authExpireDate)}
      %0D%0A Twilio Access Token:
      %0D%0A # Token Status: ${validTwilioToken} 
      %0D%0A # Created Date: ${formatDateTimeIn2Digit(twilioCreateDate)}
      %0D%0A # Expire Date: ${formatDateTimeIn2Digit(twilioExpireDate)}
      %0D%0A${errorLog}%0D%0A`;

    setIssueEmailLink(link);
  }, [
    internetStatus,
    authCreateDate,
    authExpireDate,
    twilioCreateDate,
    twilioExpireDate,
    consoleErrorLogs,
  ]);

  const onCloseHandler = () => {
    setIsVisibleReportPopup(false);
    setConsoleErrorLogs([]);
  };

  return (
    <ReportIssuePopupModal
      link={issueEmailLink}
      isOpen={isVisibleReportPopup}
      onClose={onCloseHandler}
    />
  );
};

export default ReportIssuePopup;
