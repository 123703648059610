import React from 'react';

import styles from 'components/Titles/PsychicNotificationTitle/PsychicNotificationTitle.module.scss';
import ComposeNotificationButton from 'components/Buttons/ComposeNotificationButton';

const PsychicNotificationTitle = ({
  isVisible,
  sendCustomerDetail,
  handlePsychicNoteScreens,
  totalNotificationSent,
  isMobile,
  isGamifyEnabled,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Client Hub
      </span>
      {(!isMobile && (!totalNotificationSent || isGamifyEnabled))
         && (
           <ComposeNotificationButton
             sendCustomerDetail={sendCustomerDetail}
             onClick={handlePsychicNoteScreens}
           />
         )}
    </div>
  );
};

export default PsychicNotificationTitle;
