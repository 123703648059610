import React from 'react';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';

import { selectAllPsychicNotificationMute } from 'selectors/psychicNotificationsSelectors';
import { formatDateForClientsNoteForCustomer } from 'src/utils/dateHandler';
import { getPsychicIcon } from 'src/utils/commonUtil';
import { useIsMobile } from 'src/hooks/useIsMobile';
import EmptyNoteScreen from 'src/components/ClientHubWeb/EmptyNoteList';
import PsychicNoteListItem from 'src/components/ClientHubWeb/PsychicNoteList/PsychicNotesListItem';
import GlobalMutePopup from 'src/components/ClientHubWeb/PsychicNoteList/GlobalMutePopup';

import style from './PsychicNoteList.module.scss';

const PsychicNoteList = ({
  notificationList,
  customerMessageFAQUrl,
  handlePsychicNoteScreens,
}) => {
  const { isMobileForClientHub } = useIsMobile();

  const isAllPsychicNotificationMute = useSelector(selectAllPsychicNotificationMute);

  if (!notificationList.length) {
    return <EmptyNoteScreen customerMessageFAQUrl={customerMessageFAQUrl} />;
  }

  return (
    <>
      <div className={style.underLineWrapper}>
        <span className={style.underLineText}>{isAllPsychicNotificationMute
         && <span className={style.mutedTag}>Muted</span> }  Psychic Notes
        </span>
        <div className={style.underLine} />
      </div>
      <List className={style.listContainer}>
        {notificationList && notificationList.map((notification) => (
          <PsychicNoteListItem
            psychicName={notification.lineName}
            messageBody={notification.messageBody}
            key={notification.pcComId}
            sendDate={formatDateForClientsNoteForCustomer(
              notification.sentDateUTCStringFormat, true,
            )}
            clientIcon={getPsychicIcon(notification, 4)}
            psychic={notification}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
            isRead={notification.isRead}
            notificationStatus={notification.notificationStatus}
            isMobileForClientHub={isMobileForClientHub}
            sendBy={notification.sendBy}
          />
        ))}
      </List>
      { isAllPsychicNotificationMute && <GlobalMutePopup /> }
    </>
  );
};

export default PsychicNoteList;
