import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';
import Bowser from 'bowser';

import { View } from 'constants/enums';
import {
  IS_ELECTRON_STORAGE,
  DIFFERENCE_IN_TIME,
  TOKEN_AUTH_STORAGE,
  URL_TO_NATIVE_APP,
  CHAT_PLATFORM_QUERY,
  DEBUG_MODE,
} from 'constants/constants';
import {
  startChatByLinkRequest as _startChatByLinkRequest,
  setUpPsychicByLink as _setUpPsychicByLink,
  setCustomerId as _setCustomerId,
  setExtId as _setExtId,
} from 'actions/chatActions';
import {
  setDataFromSdk as _setDataFromSdk,
  setCommand as _setCommand,
  setConversationSdk as _setConversationSdk,
  setSessionSdk as _setSessionSdk,
  setView as _setView,
  destroySessionSdk as _destroySessionSdk,
  showLogoutPrompt as _showLogoutPrompt,
  restartChat as _restartChat,
  addSdkCallback as _addSdkCallback,
  setBootDataFromSdk as _setBootDataFromSdk,
  setUserAgent as _setUserAgent,
  setIsElectronApp as _setIsElectronApp,
  setLocation as _setLocation,
} from 'actions/appActions';
import {
  setUpPsychicNotesByLinkForPsychic as _setUpPsychicNotesByLinkForPsychic,
  setUpPsychicNotesByLinkForCustomer as _setUpPsychicNotesByLinkForCustomer,
} from 'actions/psychicNotifications';
import { setAuthToken as _setAuthToken } from 'actions/authActions';
import { openDiagnosticsModel as _openDiagnosticsModel } from 'actions/diagnosticAction';
import { useSdkCases, useSdkConfigurationListener } from 'src/sdk/hooks/sdk.hook';
import { useLazyEmojiMart, useLazyLoader } from 'src/hooks/optimization.hook';
import { LocalStorage } from 'src/utils/storageHandler';
import { useResetDiffInTime } from 'src/hooks/useResetDiffInTime';
import {
  selectCommand,
  selectDataFromSdk,
  selectDiffInTime,
  selectSdkCallbackStorage,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { convertQueryParamsToLowercase } from 'src/utils/helpers';

const MainContainer = ({ children }) => {
  const startChatByLinkRequest = useBindDispatch(_startChatByLinkRequest);
  const setUpPsychicByLink = useBindDispatch(_setUpPsychicByLink);
  const setDataFromSdk = useBindDispatch(_setDataFromSdk);
  const setBootDataFromSdk = useBindDispatch(_setBootDataFromSdk);
  const setCommand = useBindDispatch(_setCommand);
  const setView = useBindDispatch(_setView);
  const setSessionSdk = useBindDispatch(_setSessionSdk);
  const destroySessionSdk = useBindDispatch(_destroySessionSdk);
  const showLogoutPrompt = useBindDispatch(_showLogoutPrompt);
  const setConversationSdk = useBindDispatch(_setConversationSdk);
  const restartChat = useBindDispatch(_restartChat);
  const setUserAgent = useBindDispatch(_setUserAgent);
  const addSdkCallback = useBindDispatch(_addSdkCallback);
  const openDiagnosticsModel = useBindDispatch(_openDiagnosticsModel);
  const setAuthToken = useBindDispatch(_setAuthToken);
  const setCustomerId = useBindDispatch(_setCustomerId);
  const setExtId = useBindDispatch(_setExtId);
  const setIsElectronApp = useBindDispatch(_setIsElectronApp);
  const setUpPsychicNotesByLinkForPsychic = useBindDispatch(_setUpPsychicNotesByLinkForPsychic);
  const setUpPsychicNotesByLinkForCustomer = useBindDispatch(_setUpPsychicNotesByLinkForCustomer);
  const setLocation = useBindDispatch(_setLocation);

  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const command = useSelector(selectCommand);
  const dataFromSdk = useSelector(selectDataFromSdk);
  const diffInTime = useSelector(selectDiffInTime);

  const { search, pathname } = useLocation();
  const params = convertQueryParamsToLowercase(qs.parse(search));

  const uAPlatform = useMemo(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    const uAPlatform = browser.getPlatformType();
    setUserAgent(browser);

    return uAPlatform;
  }, [setUserAgent]);

  const sagaConfigMethods = {
    setBootDataFromSdk,
    setDataFromSdk,
    setCommand,
    addSdkCallback,
    openDiagnosticsModel,
  };

  const handleDataToLocalStorage = ({
    chatEndUrl,
    token,
    platform,
  }: any) => {
    // this is for the case when chatEndUrl and the platform are not used in the link
    if (token) {
      LocalStorage.setItem(TOKEN_AUTH_STORAGE, token);
    }

    if (chatEndUrl && platform) {
      LocalStorage.setItems([
        { key: URL_TO_NATIVE_APP, value: chatEndUrl || process.env.IOS_APP_URL },
        { key: CHAT_PLATFORM_QUERY, value: platform?.toLowerCase() },
      ]);
    }
  };

  const startChatByLink = (
    view: string,
    token: string,
    psychicId: string,
    isHistoryMode: boolean,
    tokenBearer: string,
  ) => {
    if (view?.toLowerCase() === View.CUSTOMER.toLowerCase()) {
      const customerRefId = params.cid;

      startChatByLinkRequest({
        token,
        customerRefId,
        psychicId,
        view: View.CUSTOMER,
        isHistoryMode,
      });
    }

    if (view?.toLowerCase() === View.PSYCHIC.toLowerCase()) {
      setUpPsychicByLink({
        token,
        psychicId,
        view: View.PSYCHIC,
        tokenBearer,
      });
    }
  };

  const startPsychicNotesByLink = (
    view: string,
    token: string,
    tokenBearer: string,
    psychicId: string,
  ) => {
    if (view.toLowerCase() === View.PSYCHIC.toLowerCase()) {
      // Set auth details for Psychic Notes for Psychic
      setUpPsychicNotesByLinkForPsychic({
        token,
        psychicId,
        view: View.PSYCHIC,
        tokenBearer,
      });
    } else if (view.toLowerCase() === View.CUSTOMER.toLowerCase()) {
      // Set auth details for Psychic Notes for Customer
      const customerRefId = params.cid;
      setUpPsychicNotesByLinkForCustomer({
        token,
        customerRefId,
        view: View.CUSTOMER,
        tokenBearer,
      });
    }
  };

  useSdkCases(sdkCallbackStorage, sagaConfigMethods);
  useLazyEmojiMart(uAPlatform);
  useLazyLoader();

  const sagaMethods = useMemo(() => ({
    setConversationSdk,
    setSessionSdk,
    setView,
    destroySessionSdk,
    showLogoutPrompt,
    setCommand,
    restartChat,
    setAuthToken,
    setCustomerId,
    setExtId,
  }), [
    setConversationSdk,
    setSessionSdk,
    setView,
    destroySessionSdk,
    showLogoutPrompt,
    setCommand,
    restartChat,
    setAuthToken,
    setCustomerId,
    setExtId,
  ]);

  useSdkConfigurationListener({
    command,
    dataFromSdk,
    sdkCallbackStorage,
  }, sagaMethods);

  useResetDiffInTime();

  useEffect(() => {
    LocalStorage.setItem(DIFFERENCE_IN_TIME, String(diffInTime));
  }, [diffInTime]);

  useEffect(()=> {
    setLocation(pathname)
  }, [])

  useEffect(() => {
    const token = params.t;
    const psychicId = params.pid;
    const view = params.v as string;
    const chatEndUrl = params.chatendurl;
    const debugMode = params.debug;
    const isHistoryMode: boolean = (params.ishistorymode === 'true');
    const { platform } = params;
    const isElectron = params.iselectron;
    const tokenBearer = params.bt;
    const page = params.pg as string;

    LocalStorage.setItem(DEBUG_MODE, debugMode as string || '');

    if (isElectron) {
      LocalStorage.setItem(IS_ELECTRON_STORAGE, isElectron as string);
      setIsElectronApp(true);

      return;
    }

    if (!token || !view) {
      return;
    }

    handleDataToLocalStorage({
      chatEndUrl: chatEndUrl || process.env.IOS_APP_URL,
      token,
      platform,
    });

    if (page && view) {
      startPsychicNotesByLink(view, token, tokenBearer, psychicId);

      return;
    }

    startChatByLink(view, token, psychicId, isHistoryMode, tokenBearer);
  }, []);

  return (
    <>{ children }</>
  );
};

export default MainContainer;
