import React, { memo, useEffect } from 'react';
import cn from 'classnames';

import { View, Message } from 'constants/enums';
import { formatToUSTime, getDate, getDateAndTime } from 'src/utils/dateHandler';
import { MessageAttributes, MessageType } from 'types/objectTypes';
import TimestampMessage from 'components/Messages/Message/TimestampMessage';
import { MessageOfflineInterface } from 'types/componentTypes';
import { SystemMessage } from 'components/Messages/Message';
import styles from 'components/Messages/Messages.module.scss';

const UserOfflineMessage: React.FC<MessageOfflineInterface> = ({
  message,
  author,
  view,
  isLastMessageInChat,
  isPreviousOffline,
  makeEmergencyScroll,
}) => {
  const {
    attributes,
    body,
    timestamp,
  } = message as MessageType;
  const {
    participantType,
    userCanceled,
    canceledDate,
    canceled,
    canceledBy,
    declinedByPsychic,
  } = attributes as MessageAttributes;

  useEffect(() => {
    if (userCanceled || canceled) {
      makeEmergencyScroll();
    }
  }, [userCanceled, canceled]);

  if (!participantType) {
    return <></>;
  }

  const isCurrentUserMessage = participantType === view;

  const data = formatToUSTime(timestamp);

  let messageContainer: string;
  let messageBox: string;
  let messageRequestType: string;
  let messageAuthor: string;
  let messageText: string;
  let messageTime: string;

  const isPsychicsLastMessage = isLastMessageInChat
    && participantType === View.PSYCHIC;
  const isCustomerFirstInRowMessage = participantType === View.CUSTOMER;

  if (isCurrentUserMessage) {
    messageContainer = cn(
      styles.messageContainer,
      styles.alignSelfEnd,
      (!isCustomerFirstInRowMessage) && styles.messagesMt,
    );
    messageBox = cn(
      styles.messageBox,
      styles.backgroundBlue,
      styles.justifySelfEnd,
      {
        [styles.messageDeclinedByPsychic]: declinedByPsychic,
      },
    );
    messageRequestType = cn(styles.offlineMessageTitle, styles.colorWhite);
    messageAuthor = cn(styles.sentText, styles.colorWhite);
    messageText = cn(styles.messageText, styles.colorWhite);
    messageTime = cn(styles.sentTime, styles.justifySelfEnd, styles.sentTimeOfflineMessage);
  } else {
    messageContainer = cn(
      styles.messageContainer,
      styles.alignSelfStart,
      (!isPsychicsLastMessage && !isCustomerFirstInRowMessage) && styles.messagesMt,
    );
    messageBox = cn(
      styles.messageBox,
      styles.backgroundLight,
      styles.justifySelfStart,
      {
        [styles.messageDeclinedByPsychic]: declinedByPsychic,
      },
    );
    messageRequestType = cn(styles.offlineMessageTitle, styles.colorDark);
    messageAuthor = cn(styles.sentText, styles.colorDark);
    messageText = cn(styles.messageText, styles.colorDark);
    messageTime = cn(styles.sentTime, styles.justifySelfStart, styles.sentTimeOfflineMessage);
  }

  const commonTsxPart = (
    <div className={messageContainer}>
      <div className={messageBox}>
        <span className={messageRequestType}>offline message</span>
        <span className={messageAuthor}>{author}</span>
        <span className={messageText}>{body}</span>
      </div>
      <span className={messageTime}>{data}</span>
    </div>
  );

  if (isCustomerFirstInRowMessage) {
    const applyTimestampIfUserCancel = () => {
      if (canceled && canceledBy !== Message.PSYCHIC) {
        if (canceledDate) {
          const { date, time } = getDateAndTime(canceledDate);
          const timestampMessage = `Canceled by Customer on ${date} at ${time}`;

          return <TimestampMessage message={timestampMessage} />;
        }

        return <TimestampMessage message="Offline Message canceled by Customer" />;
      }

      return null;
    };

    return (
      <div className={styles.multipleMessageContainer}>
        <div className={styles.dividedLine} />
        <span className={styles.offlineMessageTimestamp}>{getDate(timestamp)}</span>
        {commonTsxPart}
        {applyTimestampIfUserCancel()}
      </div>
    );
  }

  if (!isPreviousOffline && participantType === View.PSYCHIC) {
    return (
      <div className={styles.multipleMessageContainer}>
        <div className={styles.dividedLine} />
        <span className={styles.offlineMessageTimestamp}>{getDate(timestamp)}</span>
        {commonTsxPart}
      </div>
    );
  }

  if (isPsychicsLastMessage) {
    return (
      <div className={styles.multipleMessageContainer}>
        {commonTsxPart}
        <div className={styles.dividedLine} />
      </div>
    );
  }

  if (!!declinedByPsychic && canceledDate) {
    const { date, time } = getDateAndTime(canceledDate);
    const timestampMessage = `Psychic declined reading request on ${date} at ${time}`;

    return (
      <>
        {view !== View.PSYCHIC && <SystemMessage message="You were not charged for this message" /> }
        {commonTsxPart}
        <TimestampMessage message={timestampMessage} />
      </>
    );
  }

  return (
    <>
      {commonTsxPart}
    </>
  );
};

export default memo(UserOfflineMessage);
