import { createSelector } from 'reselect';

import { INACTIVE_CHAT_STATUSES } from 'constants/constants';
import { SdkNotificationMode } from 'constants/enums';
import { IAppState } from 'store';

export const selectChat = (state: IAppState) => state.chat;
export const selectApp = (state: IAppState) => state.app;
export const selectAuth = (state: IAppState) => state.auth;
export const selectRouter = (state: IAppState) => state.router;
export const selectStatusToggle = (state: IAppState) => state.statusToggle;

export const selectChannel = (state: IAppState) => selectChat(state).currentChat?.currentChannel;

export const selectExtId = (state: IAppState) => selectChat(state).extId;

export const selectEncryptedPassword = (state: IAppState) => selectChat(state).encryptedPassword;

export const selectAppointment = (state: IAppState) => selectChat(state).appointment;

export const selectCustomerId = (state: IAppState) => selectChat(state).customerId;

export const selectCurrentPsychicStatus = (state: IAppState) => (
  selectChat(state).currentPsychicStatus
);

export const selectCurrentChannelSid = (state: IAppState) => (
  selectChat(state).currentChat?.currentChannel.sid
);
export const selectChatId = (state: IAppState) => selectChat(state).currentChat.chatId;

export const selectChatStatus = (state: IAppState) => selectChat(state).currentChat.status;

export const selectTwilioClient = (state: IAppState) => selectChat(state).client;

export const selectTimeLimit = (state: IAppState) => selectChat(state).currentChat.maxDuration;

export const selectInternetConnectionStatus = (state: IAppState) => (
  selectApp(state).internetConnectingStatus
);

export const selectCurrentChat = (state: IAppState) => selectChat(state).currentChat;

export const selectMessages = (state: IAppState) => selectChat(state).currentChat.messages;

export const selectSystemChannel = (state: IAppState) => selectChat(state).systemChannel;

export const selectOfflineChats = (state: IAppState) => selectChat(state).offlineChats;

export const selectIsChatEndedDueToLowFund = (state: IAppState) => selectChat(state).isChatEnded;

export const selectPsychicStatusControl = (state: IAppState) => (
  selectStatusToggle(state).psychicStatusControl
);

export const selectIsInitChatBanner = (state: IAppState) => selectChat(state).isInitChatBanner;

export const selectCurrentOfflineChats = createSelector(
  selectChat,
  (state, props) => props,
  (state, chatId) => state.offlineChats.find((chat) => chat.chatId === chatId),
);

export const selectCurrentUser = (state: IAppState) => selectChat(state).currentUser;

export const selectSideUser = (state: IAppState) => selectChat(state).currentChat.sideUser;

export const selectNotifications = (state: IAppState) => selectChat(state).notifications;

export const selectInitChatNotification = (state: IAppState) => (
  selectChat(state).initChatNotification);

export const selectView = (state: IAppState) => selectApp(state).view;

export const selectIsHistoryMode = (state: IAppState) => selectApp(state).isHistoryMode;

export const selectIsLoading = (state: IAppState) => selectApp(state).isLoading;

export const selectIsBackdrop = (state: IAppState) => selectApp(state).isBackdrop;

export const selectIsShownIncomingRequest = (state: IAppState) => (
  selectApp(state).isShownIncomingRequest
);

export const selectIsLiveChatActive = (state: IAppState) => selectApp(state).isLiveChatActive;

export const selectPopUpNotificationData = (state: IAppState) => state.app.popUpNotificationData;

export const selectIsChatEnd = (state: IAppState) => {
  const chatStatus = selectChatStatus(state);

  return !chatStatus || (chatStatus && INACTIVE_CHAT_STATUSES.includes(chatStatus));
};

export const selectIsLiveChatCanceled = (state: IAppState) => selectApp(state).isLiveChatCanceled;

export const selectCommand = (state: IAppState) => selectApp(state).command;

export const selectIsSwitchedConversations = (state: IAppState) => (
  selectApp(state).isSwitchedConversations
);

export const selectIsAcceptChat = (state: IAppState) => selectChat(state).isAcceptChat;

export const selectSdkCallbackStorage = (state: IAppState) => selectApp(state).sdkCallbackStorage;

export const selectPopNotification = (state: IAppState) => selectApp(state).popUpNotificationData;

export const selectDataFromSdk = (state: IAppState) => selectApp(state).dataFromSdk;

export const selectUserAgent = (state: IAppState) => selectApp(state).userAgent;

export const selectSdkVisibilityMode = (state: IAppState) => selectApp(state).sdkVisibilityMode;

export const selectTwilioConnectingStatus = (state: IAppState) => (
  selectApp(state).twilioConnectingStatus
);

export const selectIsModeForShowingModals = createSelector(
  selectApp,
  (app) => {
    const { sdkConfiguration } = app;

    if (!sdkConfiguration) return true;

    const { settings } = sdkConfiguration;
    const notifications = settings?.presentationMode?.notifications;
    const isNoModeOrCustomMode = !notifications || notifications === SdkNotificationMode.CUSTOM;

    return isNoModeOrCustomMode;
  },
);

export const selectSdkConfiguration = (state: IAppState) => selectApp(state).sdkConfiguration;

export const selectAuthToken = (state: IAppState) => selectAuth(state).authToken;

export const selectTwilioToken = (state: IAppState) => selectAuth(state).twilioToken;

export const selectBearerToken = (state: IAppState) => selectAuth(state).bearerToken;

export const selectLocation = (state: IAppState) => selectRouter(state).location.pathname;

export const selectRedirectState = (state: IAppState) => selectApp(state).redirectState;

export const selectInitChatModalState = (state: IAppState) => selectApp(state).isInitChatModal;

export const selectDiffInTime = (state: IAppState) => selectApp(state).diffInTime || 0;

export const selectInlineNotification = (state: IAppState) => selectApp(state).inlineNotification;

export const selectPsychicPhoneStatus = (state: IAppState) => (
  selectChat(state).currentPsychicPhoneStatus
);

export const selectPhoneStatusWaitingState = (state: IAppState) => (
  selectChat(state).isWaitingPhoneStatus
);

export const selectIsPsychicNotificationsEnable = (state: IAppState) => (
  selectApp(state).isPsychicNotificationsEnable
);

export const selectActiveTab = (state: IAppState) => (
  selectApp(state).activeTab
);

export const selectIsDeclineOMEnabled = (state: IAppState) => (
  selectApp(state).isDeclineOMEnabled
);

export const selectActivePage = (state: IAppState) => (
  selectApp(state).activePage
);

export const selectNotificationReactionUnreadCount = (state: IAppState) => (
  selectApp(state).notificationReactionUnreadCount
);

export const selectConfirmationScreen = (state: IAppState) => (
  selectApp(state).isConfirmationScreen
);

export const selectIsElectronApp = (state: IAppState) => (
  selectApp(state).isElectronApp
);

export const selectDashboardPsychicNews = (state: IAppState) => (
  selectApp(state).dashboardPsychicNews
);

export const selectCallBackQueue = (state: IAppState) => (
  selectApp(state).callBackQueue
);

export const selectSchedules = (state: IAppState) => selectChat(state).schedules;

export const selectCallBackQueueData = (state: IAppState) => selectChat(state).callBackQueueData;

export const selectIsPsychicLoginOutsideOfSchedule = (state: IAppState) => (
  selectApp(state).isPsychicLoginOutsideOfSchedule
);

export const selectIsPsychicLoginOutsideOfScheduleEditMode = (state: IAppState) => (
  selectApp(state).isPsychicLoginOutsideOfScheduleEditMode
);

export const selectPsychicLoginOutsideOfScheduleMsg = (state: IAppState) => (
  selectApp(state).psychicLoginOutsideOfScheduleMsg
);

export const selectPsychicLoginOutsideLastActivityId = (state: IAppState) => (
  selectApp(state).psychicLoginOutsideLastActivityId
);

export const selectPsychicLoginOutsidePopupShow = (state: IAppState) => (
  selectApp(state).psychicLoginOutsidePopupShow
);

export const selectActiveMobilePage = (state: IAppState) => (
  selectApp(state).activeMobilePage
);

export const selectIsChatInInitState = (state: IAppState) => (
  selectApp(state).isChatInInitState
);

export const selectIsChatAutoReloadEnable = (state: IAppState) => (
  selectApp(state).isChatAutoReloadEnabled
);

export const selectIsShowAutoReloadHeader = (state: IAppState) => (
  selectChat(state).isShowAutoReloadHeader
);

export const selectAutoReloadPromptsLabels = (state: IAppState) => (
  selectApp(state).autoReloadPromptsLabels
);

export const selectToastBannerData = (state: IAppState) => (
  selectApp(state).toastBannerData
);
