import React from 'react';

import style from './styles.module.scss';

const BreakButton = ({ disabled = false, onClick, isDelayed = true }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    type="button"
    className={style.breakButton}
  >
    {isDelayed ? 'Break' : 'End Break Now'}
  </button>
);

export default BreakButton;
