import React from 'react';
import { useSelector } from 'react-redux';

import SvgIcon from 'components/SvgIcon';
import { selectStatusToggle } from 'selectors/statusToggleSelectors';

const StatusBadge = () => {
  const {
    phoneOnly,
    chatOnly,
    offlineMessages,
    isOnCall,
    isOnChat,
  } = useSelector(selectStatusToggle);

  const activeColor = '#2cd660';
  const disableColor = '#bcbcbc';
  const busyColor = '#e57400';

  const setColor = (isActive, isBusy = false) => {
    if (isActive && !isBusy) {
      return activeColor;
    }

    if (isBusy) {
      return busyColor;
    }

    return disableColor;
  };

  return (
    <>
      <SvgIcon
        id="talkStatusIcon"
        color={setColor(phoneOnly, isOnCall)}
      />
      <SvgIcon
        id="chatStatusIcon"
        color={setColor(chatOnly, isOnChat)}
      />
      <SvgIcon
        id="DMStatusIcon"
        color={setColor(offlineMessages)}
      />
    </>
  );
};

export default StatusBadge;
