import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { selectToastBannerData } from 'selectors/selectors';
import {
  removeToastBannerData as _removeToastBannerData,
} from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import styles from 'src/components/ToastBanners/ToastBanner.module.scss';

const ToastBanner = () => {
  const {
    isVisible,
    toastMessage,
    isAnimation,
  } = useSelector(selectToastBannerData);

  const removeToastBannerData = useBindDispatch(_removeToastBannerData);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => removeToastBannerData(), 6000);
    }
  }, [isVisible, removeToastBannerData]);

  if (!isVisible) return null;

  const messageStyle = cn(
    styles.toastMessage, {
      [styles.bannerAnimation]: isAnimation,
    },
  );

  return (
    <div className={styles.wrapper}>
      <div className={messageStyle}>
        {toastMessage}
      </div>
    </div>
  );
};

export default ToastBanner;
