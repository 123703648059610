import React from 'react';

import styles from './Tooltip.module.scss';

const MuteButtonTooltip = ({
  isVisible,
  label,
  onClick,
  onClose,
  onFaqsHref,
  triggerPsychicNoteFAQMixPanel,
}) => {
  if (!isVisible) {
    return null;
  }

  const handleOnBlur = () => {
    setTimeout(() => {
      onClose(false);
    }, 400);
  };

  const handleOnClose = () => {
    onClose(true);
  };

  return (
    <div className={styles.tooltip}>
      <button
        className={styles.btnMute}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="button"
        onBlur={handleOnBlur}
        onClick={onClick}
      >
        {label}
      </button>
      <a
        href={onFaqsHref}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.btnAnchorTag}
      >
        <button
          className={styles.btnFaq}
          type="button"
          onClick={triggerPsychicNoteFAQMixPanel}
        >
          Psychic Notes FAQs
        </button>
      </a>
      <button
        className={styles.btnClose}
        type="button"
        onClick={handleOnClose}
      >
        Close
      </button>
    </div>
  );
};

export default MuteButtonTooltip;
