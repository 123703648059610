import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';

const SendMessageConfirmation = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
}) => {
  const acceptHandler = () => {
    const { func, args } = popUpNotificationData.callback;

    if (args) {
      func(...args);
    } else {
      func();
    }

    showPopUpNotification(false);
  };

  const rejectHandler = () => {
    showPopUpNotification(false);
  };

  const acceptButton = {
    text: 'Send',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: acceptHandler,
  };

  const rejectButton = {
    text: 'Cancel',
    color: ButtonColor.ONLY_TEXT,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: rejectHandler,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default SendMessageConfirmation;
