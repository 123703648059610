import React from 'react';
import cn from 'classnames';

import ErrorIcon from 'src/assets/png/error-icon.png';
import styles from 'components/Popups/FlaggedNotificationPopup/FlaggedNotificationPopup.module.scss';

const FlaggedNotificationPopup = ({ isVisible, popupInfo, className = '' }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={cn(styles.validationMessageWrapper, className)}>
      <img src={ErrorIcon} alt="errorIcon" />
      <span className={styles.validationMessage}>
        {popupInfo}
      </span>
    </div>
  );
};
export default FlaggedNotificationPopup;
