import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  SdkEvents,
} from 'constants/enums';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { redirectUserToWebsiteOrNativeApp } from 'src/utils/commonUtil';

const ActivePhoneCall = ({
  isNotificationPopUp,
  popUpNotificationData,
  sdkCallbackStorage,
  destroySessionSdk,
  clearApp,
  clearChatEvents,
  clearChat,
  showPopUpNotification,
}) => {
  const handleAcceptButton = () => {
    const tokenExpiredCb = sdkCallbackStorage.get(SdkEvents.ON_TOKEN_EXPIRED);

    if (redirectUserToWebsiteOrNativeApp()) {
      return;
    }

    if (tokenExpiredCb) {
      destroySessionSdk(false);
      tokenExpiredCb({ name: SdkEvents.ON_TOKEN_EXPIRED, data: 'Your session has expired.' });
    } else {
      clearChatEvents();
      clearApp();
      clearChat();
      showPopUpNotification(false);
      LocalStorage.setItem('isVisibleNotification', '');
      goTo('/login');
    }
  };

  const acceptButton = {
    text: 'OK',
    color: ButtonColor.ORANGE,
    size: ButtonSize.S,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
    />
  );
};

export default ActivePhoneCall;
