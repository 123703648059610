import React from 'react';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { Route, Router, Switch } from 'react-router-dom';

import routeHistory from 'src/routeHistory';
import { SentryMethods } from 'src/utils/sentryMethods';
import MixpanelActions from 'src/utils/mixpanel';
import MixpanelPsychicActions from 'src/utils/mixpanelPsychicActions';
import MainContainer from 'containers/MainContainer';
import LoginFormContainer from 'containers/LoginFormContainer';
import ChatAppContainer from 'containers/ChatAppContainer';
import ClientHubContainer from 'containers/ClientHubContainer';
import Logout from 'components/Logout';
import SelectPsychicFormContainer from 'src/components/Forms/SelectPsychicForm/SelectPsychicFormContainer';
import PrivateRouteSdk from 'src/routes/PrivateRouteSdk';
import PrivateRoute from 'src/routes/PrivateRoute';
import { resizeWindowForAndroid } from 'src/utils/helpers';
import 'src/styles/App.scss';

SentryMethods.init();
MixpanelActions.init();
MixpanelPsychicActions.init();
polyfillCountryFlagEmojis();
resizeWindowForAndroid();

const App = () => (
  <Router history={routeHistory}>
    <MainContainer>
      <Switch>
        <Route exact path="/" component={LoginFormContainer} />
        <Route path="/login" component={LoginFormContainer} />
        <Route path="/logout" component={Logout} />
        <PrivateRouteSdk path="/sdk" />
        <PrivateRoute path="/psychics" component={SelectPsychicFormContainer} />
        <PrivateRoute path="/conversations/:id?" component={ChatAppContainer} />
        <PrivateRoute path="/clienthub" component={ClientHubContainer} />
      </Switch>
    </MainContainer>
  </Router>
);

export default App;
