/* eslint-disable max-len */
import { IAppState } from 'store';

export const selectCountryCodes = (state: IAppState) => state.chat.countryCodes;

export const selectCustomerPhoneNumbers = (state: IAppState) => state.switchToPhone.phoneNumbers;

export const selectSwitchToPhoneAppointment = (state: IAppState) => state.switchToPhone.psychicAppointment;

export const selectIsPhoneValidated = (state: IAppState) => state.switchToPhone.isCustomerPhoneValidated;

export const selectIsNumberSaved = (state: IAppState) => state.switchToPhone.isCustomerPhoneSaved;

export const selectIsPhoneNumber = (state: IAppState) => state.switchToPhone.isPhoneNumber;
