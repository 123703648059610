import {
  put,
  call,
  take,
  select,
  putResolve,
  fork,
} from 'redux-saga/effects';

import {
  View,
  Status,
  SdkEvents,
  ChannelEvents,
  ChatCompletedReason,
  PopUpNotificationType,
  ChatRequest,
  NotificationType,
  ButtonColor,
  ButtonSize,
  ButtonShape,
  NotificationsText,
  ReasonUpdateOfflineMessage,
} from 'constants/enums';
import {
  IS_CHAT_END_DUE_TO_LOW_FUNDS,
  LATEST_NOTIFICATION_INDEX,
  SHOW_AUTO_RELOAD_HEADER,
  FUNDS_TO_ADD,
  VIEW,
} from 'constants/constants';
import { API } from 'src/utils/api';
import {
  setIsLiveChatActive,
  setPopUpNotificationData,
  showPopUpNotification,
  setIsSwitchedConversations,
  setIsShownIncomingRequest,
  setShowInitModal,
} from 'actions/appActions';
import { setBreak } from 'actions/statusToggleActions';
import * as chatActions from 'actions/chatActions';
import {
  selectView,
  selectChatId,
  selectIsShownIncomingRequest,
  selectSdkCallbackStorage,
  selectNotifications,
  selectUserAgent,
  selectSideUser,
  selectIsChatAutoReloadEnable,
  selectCurrentUser,
} from 'selectors/selectors';
import { selectBreakCommand } from 'selectors/statusToggleSelectors';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  removeOfflineChatHandler,
  setUserCancelOfflineChatHandler,
  closeOfflineChatHandler,
  removeCurrentOrNotOfflineChatHandler,
} from 'actions/offlineChatActions';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import { SdkEventHandlers } from 'src/sdk/service/sdk.service';
import { eventListener } from 'src/redux/sagas/chat/commonSaga';
import { getPlatform } from 'src/utils/commonUtil';
import { roundToWhole } from 'src/utils/helpers';

function* handleCreateOfflineChat(notification) {
  if (notification?.attributes.expiresDate) return;

  if (notification?.attributes.type === ChatRequest.CHAT_REQUEST_CREATED) {
    yield put(chatActions.receiveNewOfflineChat(notification?.attributes.chatId));
  }
}

function* handleCustomerAddedFunds(notification) {
  if (notification?.attributes?.type === NotificationType.CHAT_FUNDS_ADDED) {
    yield put(chatActions.changeMaxDuration(notification?.attributes?.maxDuration));
  }
}

function* handleCloseOfflineMessage(notification) {
  const notificationType = notification?.attributes?.notificationType;
  const { chatId } = notification.attributes;
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

  if (notificationType === NotificationType.CUSTOMER_CANCEL_OM) {
    yield put(setUserCancelOfflineChatHandler(chatId));
    yield put(removeOfflineChatHandler(chatId));
    yield call(SdkEventHandlers.onOfflineMessageUpdated, {
      sdkCallbackStorage,
      chatId,
      reason: ReasonUpdateOfflineMessage.CANCEL_OFFLINE_MESSAGE,
      payload: notification,
    });

    return;
  }

  if (notificationType === NotificationType.ANSWERED_TO_DM) {
    yield put(closeOfflineChatHandler(chatId));
    yield put(removeCurrentOrNotOfflineChatHandler(chatId));

    yield call(SdkEventHandlers.onOfflineMessageUpdated, {
      sdkCallbackStorage,
      chatId,
      reason: ReasonUpdateOfflineMessage.ANSWERED_ON_OFFLINE_MESSAGE,
      payload: notification,
    });
  }
}

function* handleAcceptedLiveChat(chatId: string) {
  const userAgent = yield select(selectUserAgent);
  const platform = yield call(getPlatform, userAgent);

  const { data } = yield call(API.Chat.getChatByIdLight, chatId) || {};
  const status = data?.chat?.status;

  if (status === Status.COMPLETED) {
    return;
  }

  yield put(chatActions.acceptCustomerChatRequest({ chatId, alreadyAccepted: true, platform }));
}

function* handleEndChatPsychic(notification) {
  const { type, reason, billedMinutes } = notification?.attributes;
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

  if (type === NotificationType.CHAT_COMPLETED) {
    yield fork(chatActions.setIsAcceptChat, false);
    yield put(setIsLiveChatActive(false));
    yield put(chatActions.setTypingStartedMessage(''));
    const chatId = yield select(selectChatId);

    DraftedMessages.remove(chatId);
    const data = {
      acceptButton: {
        text: 'OK',
        color: ButtonColor.WARNING,
        size: ButtonSize.S,
        shape: ButtonShape.ROUNDED,
      },
      isBigWidth: false,
      title: '',
    };

    const view = yield select(selectView);

    switch (reason) {
      case ChatCompletedReason.INACTIVITY: {
        const title = `This Chat has ended due to inactivity.
         ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}m` : '0m'}`;
        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.INACTIVE_CHAT_ENDED,
        }));

        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      case ChatCompletedReason.LOW_FUNDS: {
        const title = `This Chat has ended because the Customer ran out of funds.
         ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}m` : '0m'}`;
        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.LOW_FUNDS_CHAT_ENDED,
        }));

        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      case ChatCompletedReason.SWITCH_TO_PHONE: {
        const { friendlyName } = yield select(selectSideUser);
        const isShownIncomingRequest = yield select(selectIsShownIncomingRequest);
        const title = `Your chat session has ended because ${friendlyName} is calling you on the phone instead.
         ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}m` : '0m'}`;

        if (isShownIncomingRequest) yield putResolve(chatActions.cleanAfterIgnoreChatRequest());

        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.CUSTOMER_FINISHED_CHAT,
        }));
        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      case ChatCompletedReason.CUSTOMER_FINISHED_CHAT: {
        const isShownIncomingRequest = yield select(selectIsShownIncomingRequest);
        const title = `This Chat has been ended by the Customer.
         ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}m` : '0m'}`;

        if (isShownIncomingRequest) yield putResolve(chatActions.cleanAfterIgnoreChatRequest());

        yield put(showPopUpNotification(true));
        yield put(setIsShownIncomingRequest(false));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.CUSTOMER_FINISHED_CHAT,
        }));

        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      case ChatCompletedReason.PSYCHIC_FINISHED_CHAT: {
        yield put(chatActions.deleteCurrentChat());
        yield put(setIsSwitchedConversations(false));
        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);
        const popupTitle = `This Chat has been ended by the Psychic.
        ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}m` : '0m'}`;

        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title: popupTitle,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.PSYCHIC_FINISHED_CHAT,
        }));

        if (!!onEndCallback) {
          data.title = 'This Chat has been ended by the Psychic.';
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }

      case ChatCompletedReason.UPCOMING_APPOINTMENT: {
        const title = `Chat Has Ended.
          Your Chat has ended automatically because you have an appointment starting in 2 minutes.
          ${NotificationsText.CHAT_DURATION_TEXT}: ${billedMinutes ? `${billedMinutes}` : 0}`;

        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.DUE_TO_APPOINTMENT,
        }));

        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      case ChatCompletedReason.CHAT_TASK_CANCELLED_LOW_FUNDS: {
        const title = 'This Chat has been ended automatically by Customer Care';
        yield put(showPopUpNotification(true));
        yield put(setPopUpNotificationData({
          title,
          isTextNewLine: true,
          notificationType: PopUpNotificationType.CHAT_TASK_CANCELLED_LOW_FUNDS,
        }));

        const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

        if (!!onEndCallback) {
          data.title = title;
          onEndCallback({ name: SdkEvents.ON_END_CHAT, data });
        }

        break;
      }
      default: {
        yield put(chatActions.setAppointment({ appointmentTimestamp: null, customerName: null }));

        break;
      }
    }

    goTo(`/conversations?${VIEW}=${view.toLowerCase()}`);
    yield put(chatActions.deleteNotifications());

    const breakCommand = yield select(selectBreakCommand);

    if (breakCommand) yield put(setBreak(breakCommand));
  }
}

function* handleMutedNotification(notification) {
  const notificationType = notification?.attributes?.type;
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

  if (notificationType === NotificationType.MUTE_CHAT_REQUEST) {
    const onMuteChatRequestCb = sdkCallbackStorage.get(SdkEvents.ON_MUTE_CHAT_REQUEST);

    if (onMuteChatRequestCb) {
      yield call(onMuteChatRequestCb, {
        name: SdkEvents.ON_MUTE_CHAT_REQUEST,
        data: notification.attributes,
      });
    }
  }
}

export function* handlePsychicNotifications(notification) {
  if (!notification) return;

  yield fork(handleEndChatPsychic, notification);
  yield fork(handleCreateOfflineChat, notification);
  yield fork(handleCustomerAddedFunds, notification);
  yield fork(handleCloseOfflineMessage, notification);
  yield fork(handleMutedNotification, notification);
}

function* handleChatEndedNotifications(notification) {
  const isChatAutoReloadEnabled = yield select(selectIsChatAutoReloadEnable);
  const currentUser = yield select(selectCurrentUser);

  // OA-7349 - If Chat Auto Reload feature is enabled then only chat
  // ended due to low fund state set to show the AR Prompt popup.
  const isLowFund = isChatAutoReloadEnabled
    && notification?.attributes?.completionReason === ChatCompletedReason.LOW_FUNDS;

  const isEnableAutoRecharge = currentUser?.enableAutoRecharge;
  const isSwitchToPhoneReason = notification?.attributes?.completionReason
    === ChatCompletedReason.SWITCH_TO_PHONE;

  if (isLowFund && !isEnableAutoRecharge) {
    LocalStorage.setItem(IS_CHAT_END_DUE_TO_LOW_FUNDS, 'true');
    yield put(chatActions.setEndChatDueToLowFundState(true));
  }

  if (isSwitchToPhoneReason) {
    yield put(chatActions.setPhoneStatusWaitingState(true));
  }
}

function* handleCustomerNotifications(notification) {
  const type = notification?.attributes?.type;
  yield fork(handleCustomerAddedFunds, notification);
  const notificationType = notification?.attributes?.notificationType;

  switch (type || notificationType) {
    case NotificationType.CHAT_COMPLETED: {
      yield fork(handleChatEndedNotifications, notification);
      yield put(chatActions.setInitChatBanner(false));
      yield put(setShowInitModal(false));
      yield put(showPopUpNotification(false));

      break;
    }
    case ChatRequest.CHAT_REQUEST_EXPIRED: {
      yield put(chatActions.setInitChatBanner(false));
      yield put(setShowInitModal(false));

      break;
    }
    case NotificationType.AUTO_RELOAD_FUNDS: {
      const fundsToAdd = notification?.attributes?.fundsToAdd;

      if (fundsToAdd) {
        const funds: number | string = roundToWhole(fundsToAdd);
        LocalStorage.setItem(FUNDS_TO_ADD, funds as string);
        yield put(chatActions.setFunds(+funds > 0 ? funds as string : ''));
      }

      LocalStorage.setItem(SHOW_AUTO_RELOAD_HEADER, 'true');
      yield put(chatActions.setShowAutoReloadHeader(true));

      break;
    }

    default: break;
  }
}

export function* systemChannelWatcher({
  payload,
}: ReturnType<typeof chatActions.configureSystemChannelEvents>) {
  const { systemChannel, view } = payload;
  const channel = yield call(eventListener, systemChannel);

  while (true) {
    const { payload, type } = yield take(channel);

    switch (type) {
      case ChannelEvents.MESSAGE_ADDED: {
        const messageState = payload?.state || {};

        /* CP-14192: Log every single system message into the console */
        console.log('System message:');
        console.log(messageState);

        const currentNotifications = yield select(selectNotifications);
        const notificationIds = currentNotifications.map((notification) => notification.index);
        const { appointmentDetails = null } = messageState.attributes || {};
        const { appointmentTimestamp = null, customerName = null } = appointmentDetails || {};

        if (appointmentTimestamp && customerName) {
          yield put(chatActions.setAppointment({ appointmentTimestamp, customerName }));
        }

        if (notificationIds.includes(messageState.index)) return;

        LocalStorage.setItem(LATEST_NOTIFICATION_INDEX, messageState.index);
        yield putResolve(chatActions.addSystemNotification(messageState));

        const isChatRequestAccepted = messageState.attributes?.type
          === ChatRequest.CHAT_REQUEST_ACCEPTED;

        if (view === View.CUSTOMER) {
          yield fork(handleCustomerNotifications, messageState);
        }

        if (view === View.PSYCHIC) {
          yield fork(handlePsychicNotifications, messageState);

          if (isChatRequestAccepted) {
            yield fork(chatActions.setIsAcceptChat, true);
            yield fork(handleAcceptedLiveChat, messageState.attributes.chatId);
          }
        }

        break;
      }
      default: break;
    }
  }
}
