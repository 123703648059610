import {
  SdkEvents,
  View,
  SdkChatEvent,
} from 'constants/enums';
import { goTo } from 'route-history';
import { PostMessageType } from 'types/objectTypes';

export const onSendMessage = (message: string, sdkCallbackStorage: Map<string, Function>) => {
  if (sdkCallbackStorage.get(SdkEvents.ON_SEND_MESSAGE)) {
    // @ts-ignore
    sdkCallbackStorage.get(SdkEvents.ON_SEND_MESSAGE)(message);
  }
};

const prepareOnChatEventCallbacks = (postMessage: (data: PostMessageType) => void) => {
  const onTokenExpiredCb = (data: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: data });
  };

  const onReceiveChatCb = (event: string) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onMuteChatRequestCb = (event: string) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onEndChatCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onIgnoreChatCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onAcceptChatCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onMessageReceivedCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onOfflineMessageReceivedCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onOfflineMessageUpdatedCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  const onLogoutCb = (view: View) => {
    postMessage({ command: SdkEvents.ON_LOGOUT, payload: view });
  };

  const onErrorCb = (reason: object) => {
    postMessage({ command: SdkEvents.ON_ERROR, payload: reason });
  };

  const onSwitchToPhoneCb = (event: any) => {
    postMessage({ command: SdkEvents.ON_CHAT_EVENT, payload: event });
  };

  return {
    onTokenExpiredCb,
    onReceiveChatCb,
    onEndChatCb,
    onIgnoreChatCb,
    onAcceptChatCb,
    onMessageReceivedCb,
    onOfflineMessageReceivedCb,
    onOfflineMessageUpdatedCb,
    onLogoutCb,
    onErrorCb,
    onSwitchToPhoneCb,
    onMuteChatRequestCb,
  };
};

export const getSdkCallbacks = (event: MessageEvent) => {
  const { command } = event.data;

  const postMessage = (data: PostMessageType) => {
    (<Window>event.source)?.postMessage(data, event.origin);
  };

  const bootCb = (data: any) => {
    postMessage({ command, payload: data });
  };

  const onSendMessageCb = (message: string) => {
    postMessage({ command, payload: message });
  };

  const onChatEventCb = (event: string) => {
    postMessage({ command, payload: event });
  };

  const acceptChatCb = (event: string) => {
    postMessage({ command, payload: event });
  };

  const ignoreChatConfirmCb = (data: any) => {
    postMessage({ command, payload: data });
  };

  const onChangeVisibilityCb = () => {
    postMessage({ command });
  };

  const getMessagesCb = (messageArray: Array<any>) => {
    postMessage({ command, payload: messageArray });
  };

  const sendMessageCb = (message: object) => {
    postMessage({ command, payload: message });
  };

  const logoutCb = (view: View) => {
    postMessage({ command, payload: view });
  };

  const onPsychicsPresenceCb = (record: object) => {
    postMessage({ command, payload: record });
  };

  const setConversationCb = (conversation?: object) => {
    postMessage({ command, payload: conversation });
  };

  const setSessionCb = (session?: object) => {
    postMessage({ command, payload: session });
  };

  const onErrorCb = (reason?: object) => {
    postMessage({ command, payload: reason });
  };

  const {
    onTokenExpiredCb,
    onReceiveChatCb,
    onEndChatCb,
    onIgnoreChatCb,
    onAcceptChatCb,
    onMessageReceivedCb,
    onOfflineMessageReceivedCb,
    onOfflineMessageUpdatedCb,
    onLogoutCb,
    onSwitchToPhoneCb,
    onMuteChatRequestCb,
  } = prepareOnChatEventCallbacks(postMessage);

  return {
    postMessage,
    bootCb,
    onSendMessageCb,
    onChatEventCb,
    acceptChatCb,
    ignoreChatConfirmCb,
    onTokenExpiredCb,
    onReceiveChatCb,
    onEndChatCb,
    onIgnoreChatCb,
    onAcceptChatCb,
    onMessageReceivedCb,
    onOfflineMessageReceivedCb,
    onOfflineMessageUpdatedCb,
    onLogoutCb,
    onChangeVisibilityCb,
    getMessagesCb,
    sendMessageCb,
    logoutCb,
    onPsychicsPresenceCb,
    setConversationCb,
    setSessionCb,
    onErrorCb,
    onSwitchToPhoneCb,
    onMuteChatRequestCb,
  };
};

export const setOnChatEventCallback = (event: MessageEvent, addSdkCallback: Function) => {
  const callbacks = getSdkCallbacks(event);
  const { payload } = event.data;

  switch (payload.name) {
    case SdkEvents.ACCEPT_CHAT:
    case SdkEvents.ON_MUTE_CHAT_REQUEST:
    case SdkEvents.IGNORE_CHAT_CONFIRM:
    case SdkEvents.ON_TOKEN_EXPIRED:
    case SdkEvents.ON_RECEIVE_CHAT:
    case SdkEvents.ON_END_CHAT:
    case SdkEvents.ON_IGNORE_CHAT:
    case SdkEvents.ON_ACCEPT_CHAT:
    case SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED:
    case SdkEvents.ON_OFFLINE_MESSAGE_UPDATED:
    case SdkEvents.ON_ERROR:
    case SdkEvents.ON_MESSAGE_RECEIVED:
    case SdkChatEvent.ON_SWITCH_TO_PHONE: {
      addSdkCallback(payload.name, callbacks[`${payload.name}Cb`]);

      break;
    }
    default: break;
  }
};

export const handleImmediateChatInvents = (event: MessageEvent) => {
  const { payload } = event.data;

  switch (payload.name) {
    case SdkEvents.IGNORE_CHAT_CONFIRM:
      goTo('/conversations');

      break;

    default: break;
  }
};

export const SdkEventHandlers = {
  onOfflineMessageUpdated: ({
    sdkCallbackStorage,
    reason,
    payload,
    chatId,
  }) => {
    const onOfflineMessageCallback = sdkCallbackStorage.get(SdkEvents.ON_OFFLINE_MESSAGE_UPDATED);

    if (onOfflineMessageCallback) {
      onOfflineMessageCallback({
        name: SdkEvents.ON_OFFLINE_MESSAGE_UPDATED,
        data: {
          chatId,
          reason,
          payload,
        },
      });
    }
  },
};
