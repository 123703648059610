import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { InteractChatButton } from 'extracted-chat-components';

import SvgIcon from 'components/SvgIcon';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  MixpanelEvents,
  Status,
  PopUpNotificationType,
  ErrorRequestType,
  ChatInitMessages,
} from 'constants/enums';
import {
  setHeaderBannerContent as _setHeaderBannerContent,
  setIsVisibleHeaderBanner as _setIsVisibleHeaderBanner,
  setShowInitModal as _setShowInitModal,
  setHistoryModeState as _setHistoryModeState,
  setLoadingState as _setLoadingState,
  addPopUpNotification as _addPopUpNotification,
  setRequestErrorState as _setRequestErrorState,
  showPopUpNotification as _showPopUpNotification,
  setPopUpNotificationData as _setPopUpNotificationData,
  setIsChatInInitState as _setIsChatInInitState,
} from 'actions/appActions';
import {
  setChatRequest as _setChatRequest,
  setPhoneStatusWaitingState as _setPhoneStatusWaitingState,
  setOfflineCurrentChat as _setOfflineCurrentChat,
  updateCallbackBanner as _updateCallbackBanner,
  setInitChatNotification as _setInitChatNotification,
} from 'actions/chatActions';
import { receiveSwitchToPhoneInfo as _receiveSwitchToPhoneInfo } from 'actions/switchToPhoneActions';
import { ChatAgainButtonContainerProps } from 'types/componentTypes';
import {
  HEADER_BANNER_DESCRIPTION_STORAGE,
  HEADER_BANNER_SUBTITLE_STORAGE,
  HEADER_BANNER_TITLE_STORAGE,
  HEADER_BANNER_TYPE_STORAGE,
  IS_VISIBLE_HEADER_BANNER_STORAGE,
  ERROR_DUE_TO_LOW_FUNDS,
  FUNDS_TO_ADD,
  THIRTY_SECONDS_IN_MILLIS,
  MAX_PEOPLE_IN_QUEUE,
} from 'constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';
import MixpanelActions from 'src/utils/mixpanel';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  selectAuthToken,
  selectCurrentPsychicStatus,
  selectCustomerId,
  selectExtId,
  selectPhoneStatusWaitingState,
  selectPsychicPhoneStatus,
  selectSideUser,
  selectIsHistoryMode,
  selectCurrentUser,
  selectIsPsychicNotificationsEnable,
} from 'selectors/selectors';
import { selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import {
  wait,
  handleSwitchToPhoneNotifications,
  logWithStyles,
} from 'src/utils/commonUtil';
import { getPsychicInfoMixpanel, getPsychicInfoMixpanelForCustomer } from 'src/utils/helpers';
import { MixpanelTrackProps } from 'types/objectTypes';
import { API } from 'src/utils/api';
import CallbackBanner from 'components/CallbackBanner';

import styles from './ChatBottomButtons.module.scss';

let isWaiting = false;

const ChatAgainButtonContainer: React.FC<ChatAgainButtonContainerProps> = ({
  makeEmergencyScroll,
  isClientHub = false,
}) => {
  const [isShowChatButton, setIsShowChatButton] = useState<boolean>(false);
  const [isShowTalkButton, setIsShowTalkButton] = useState<boolean>(false);

  const extId = useSelector(selectExtId);
  const customerRefIdEnc = useSelector(selectCustomerId);

  const { customerRefId } = useSelector(selectCurrentUser);
  const { customerPrice } = useSelector(selectSideUser);

  const token = useSelector(selectAuthToken);
  const currentPsychicStatus = useSelector(selectCurrentPsychicStatus);
  const sideUser = useSelector(selectSideUser);
  const isHistoryMode = useSelector(selectIsHistoryMode);
  const currentPhonePsychicStatus = useSelector(selectPsychicPhoneStatus);
  const isPhoneStatusWaitingState = useSelector(selectPhoneStatusWaitingState);
  const isPsychicNotificationsEnable = useSelector(selectIsPsychicNotificationsEnable);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail);

  const setLoadingState = useBindDispatch(_setLoadingState);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setChatRequest = useBindDispatch(_setChatRequest);
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const setIsVisibleHeaderBanner = useBindDispatch(_setIsVisibleHeaderBanner);
  const setHeaderBannerContent = useBindDispatch(_setHeaderBannerContent);
  const setHistoryModeState = useBindDispatch(_setHistoryModeState);
  const setOfflineCurrentChat = useBindDispatch(_setOfflineCurrentChat);
  const updateCallbackBanner = useBindDispatch(_updateCallbackBanner);
  const setPhoneStatusWaitingState = useBindDispatch(_setPhoneStatusWaitingState);
  const receiveSwitchToPhoneInfo = useBindDispatch(_receiveSwitchToPhoneInfo);
  const setRequestErrorState = useBindDispatch(_setRequestErrorState);
  const setIsChatInInitState = useBindDispatch(_setIsChatInInitState);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);

  const isPendingPhoneStatus = currentPhonePsychicStatus === Status.PENDING
    || currentPhonePsychicStatus === Status.BUSY;

  const isPeopleInQueue = (!!sideUser?.peopleInQueue && !!sideUser?.estimatedWaitTime);

  const isOfflinePhoneStatus = (currentPhonePsychicStatus.toLocaleLowerCase()
  === Status.OFFLINE) && isPeopleInQueue;

  const isAvailableChatStatus = currentPsychicStatus.toLowerCase() === Status.AVAILABLE;

  const isCallback = isPeopleInQueue || isPendingPhoneStatus || isOfflinePhoneStatus;
  const isCallbackFull = isCallback && (sideUser?.peopleInQueue || 0) >= MAX_PEOPLE_IN_QUEUE;
  const isCustomerInQueue = !!sideUser?.customerPlaceInQueue;
  const psychicName = useMemo(() => (sendCustomerDetail?.length
    ? sendCustomerDetail[0]?.lineName : ''), [sendCustomerDetail]);

  const chatRedirectUrl = useMemo(() => {
    if (isPsychicNotificationsEnable && sendCustomerDetail?.length) {
      const psychicExtId = sendCustomerDetail[0]?.extId;

      if (process.env.BIO_PAGE_URL && psychicExtId && psychicName) {
        return `${process.env.BIO_PAGE_URL + psychicName?.toLowerCase()}-${psychicExtId}?isTriggerChat=true`;
      }
    }

    return '';
  }, [isPsychicNotificationsEnable, sendCustomerDetail, psychicName]);

  const isBottomMessage = useMemo(() => (
    isPsychicNotificationsEnable && sendCustomerDetail?.length && (!isOfflinePhoneStatus
    || isAvailableChatStatus)
  ), [isOfflinePhoneStatus,
    isPsychicNotificationsEnable,
    sendCustomerDetail?.length,
    isAvailableChatStatus]);

  const handleChatButton = useCallback(() => {
    const psychicMixpanelProps = isClientHub
      ? getPsychicInfoMixpanelForCustomer(sideUser)
      : getPsychicInfoMixpanel(sideUser);

    let eventObj: MixpanelTrackProps = {
      cta_content: 'chat',
      cta_location: isClientHub ? 'psychic notes' : 'chat window',
      cta_position: isClientHub ? 'bottom' : 'reading cta shelf',
      cta_type: 'button',
      tab_name: isClientHub ? '' : 'chat end mode',
      ...psychicMixpanelProps,
    };

    if (isHistoryMode) {
      eventObj = { ...eventObj, tab_name: 'chat history mode', cta_position: 'reading cta shelf' };

      setHistoryModeState(false);
      setOfflineCurrentChat({
        messages: [],
        currentChannel: {},
        sideUser: null,
      });
    }

    MixpanelActions.track(MixpanelEvents.CTA_TAPPED, eventObj);

    if (isPsychicNotificationsEnable && sendCustomerDetail?.length) {
      logWithStyles(`Redirect to psychic bio page ${chatRedirectUrl}`);

      return;
    }

    LocalStorage.removeItems([
      HEADER_BANNER_TITLE_STORAGE,
      HEADER_BANNER_SUBTITLE_STORAGE,
      HEADER_BANNER_DESCRIPTION_STORAGE,
      HEADER_BANNER_TYPE_STORAGE,
      IS_VISIBLE_HEADER_BANNER_STORAGE,
    ]);

    setShowInitModal(true);
    setIsChatInInitState(true);
    setInitChatNotification(ChatInitMessages.RESERVING_PSYCHIC);
    setIsVisibleHeaderBanner(false);
    setHeaderBannerContent(null);
    setChatRequest(customerRefIdEnc as string, extId as string);
  }, [customerRefIdEnc, extId, token, sideUser, isPsychicNotificationsEnable]);

  const renderChatButton = () => {
    const chatButton = (
      <InteractChatButton
        isActive
        isBold
        text="Chat"
        className={styles.chatButton}
        color={ButtonColor.GREEN}
        size={ButtonSize.SM}
        shape={ButtonShape.RECTANGULAR}
        icon={(
          <SvgIcon
            id="clientHubChatIcon"
            color="transparent"
          />
        )}
        onClick={handleChatButton}
      />
    );

    if (!chatRedirectUrl.length) {
      return chatButton;
    }

    return (
      <a
        target="_blank"
        href={chatRedirectUrl}
        rel="noopener noreferrer"
        className={cn(styles.chatAnchorTag, {
          [styles.withoutTalkButton]: !isShowTalkButton,
        })}
      >
        { chatButton }
      </a>
    );
  };

  const handleSwitchToPhoneError = (response: any) => {
    const errorCode = response?.error?.code || JSON.parse(response?.errorMessage)?.error?.code;

    if (errorCode === ERROR_DUE_TO_LOW_FUNDS) {
      const fundsToAdd = response.data?.fundsToAdd
        || JSON.parse(response?.errorMessage)?.data?.fundsToAdd;

      LocalStorage.setItem(FUNDS_TO_ADD, fundsToAdd);
      addPopUpNotification({
        isVisible: true,
        reason: 'switchToPhone',
        title: 'You don’t have enough in your account for this reading. Please add funds to your account balance to continue.',
        notificationType: PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS,
      });
    } else {
      setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT);
    }
  };

  const handleTalkButton = useCallback(async () => {
    try {
      let psychicExtId: any = extId;

      if (isPsychicNotificationsEnable && sendCustomerDetail?.length) {
        psychicExtId = sendCustomerDetail[0]?.extId;
      }

      setLoadingState(true);

      receiveSwitchToPhoneInfo();
      const result = await API.Chat.validatePhoneCallback({
        customerId: customerRefId,
        customerIdEnc: customerRefIdEnc,
        psychicId: psychicExtId,
        isCallback,
      });

      if (result?.data?.message === 'Low funds') {
        LocalStorage.setItem(FUNDS_TO_ADD, result.data.data.fundsToAdd || customerPrice * 10);
        showPopUpNotification(true);
        setPopUpNotificationData({
          title: 'You don’t have enough in your account for this reading. Please add funds to your account balance to continue.',
          notificationType: PopUpNotificationType.INSUFFICIENT_FUNDS,
          reason: 'talk',
        });
      } else {
        const psychicMixpanelProps = isClientHub
          ? getPsychicInfoMixpanelForCustomer(sideUser)
          : getPsychicInfoMixpanel(sideUser);

        const eventObj: MixpanelTrackProps = {
          cta_content: isCallback ? 'callback' : 'talk',
          cta_location: isClientHub ? 'psychic notes' : 'chat window',
          cta_position: isClientHub ? 'bottom' : 'reading cta shelf',
          cta_type: 'button',
          tab_name: isClientHub ? '' : 'chat end mode',
          ...psychicMixpanelProps,
        };

        MixpanelActions.track(MixpanelEvents.CTA_TAPPED, eventObj);
        handleSwitchToPhoneNotifications(
          {
            addPopUpNotification,
            sideUser,
          },
        );
      }
    } catch (e) {
      const response = e?.response?.data;

      if (response) {
        handleSwitchToPhoneError(response);
      }
    } finally {
      setLoadingState(false);
    }
  }, [sideUser, isCallback, customerRefId, customerRefIdEnc, extId, isPsychicNotificationsEnable]);

  useEffect(() => {
    (async () => {
      if (!currentPsychicStatus || !currentPhonePsychicStatus) {
        return;
      }

      const isAvailablePhoneStatus = currentPhonePsychicStatus.toLowerCase() === Status.AVAILABLE;

      if (isPhoneStatusWaitingState && !isWaiting) {
        isWaiting = true;

        await wait(process.env.WAITING_PHONE_STATUS);
        setPhoneStatusWaitingState(false);
      }

      if (!isPhoneStatusWaitingState) {
        isWaiting = false;

        setIsShowChatButton((isAvailableChatStatus
          && (!isPendingPhoneStatus || isOfflinePhoneStatus)));
        setIsShowTalkButton(isAvailablePhoneStatus
          || !!isPendingPhoneStatus || isOfflinePhoneStatus);
      }
    })();
  }, [
    currentPsychicStatus,
    currentPhonePsychicStatus,
    isPhoneStatusWaitingState,
    isOfflinePhoneStatus,
  ]);

  const isShow = isShowChatButton || isShowTalkButton;
  useEffect(() => {
    if (isShow) {
      makeEmergencyScroll();
    }
  }, [isShow]);

  useEffect(() => {
    let refreshCallbackInterval;

    if (isCallback) {
      refreshCallbackInterval = setInterval(() => {
        updateCallbackBanner(customerRefIdEnc as string);
      }, THIRTY_SECONDS_IN_MILLIS);
    }

    return () => clearInterval(refreshCallbackInterval);
  }, [isCallback, customerRefIdEnc]);

  if (!isShow) {
    return <></>;
  }

  return (
    <div className={styles.chatBottom}>
      { isCallback && !isCallbackFull && (
        <CallbackBanner
          mins={sideUser.estimatedWaitTime}
          peopleCount={sideUser.peopleInQueue || 0}
          placeInQueue={sideUser?.customerPlaceInQueue}
        />
      )}
      <div
        className={cn(styles.chatCustomerButtons, {
          [styles.isClientHub]: isClientHub,
        })}
      >
        {
          isShowTalkButton && !isCustomerInQueue ? (
            <InteractChatButton
              className={cn(
                styles.chatButton,
                { [styles.chatButtonCallback]: isCallback }
              )}
              isActive
              isBold
              text={isCallback ? 'Callback' : 'Talk'}
              color={isCallback ? ButtonColor.ORANGE : ButtonColor.GREEN}
              size={ButtonSize.SM}
              shape={ButtonShape.RECTANGULAR}
              icon={(
                <SvgIcon
                  id="clientHubTalkIcon"
                  color="transparent"
                />
              )}
              onClick={handleTalkButton}
            />
          ) : (
            isCallback && isCustomerInQueue && (
              <InteractChatButton
                className={styles.chatButton}
                isActive
                isBold
                text="In Queue"
                color={ButtonColor.SYSTEM}
                size={ButtonSize.SM}
                shape={ButtonShape.RECTANGULAR}
              />
            )
          )
        }
        { isShowChatButton && renderChatButton() }
      </div>
      { isBottomMessage && (
        <div className={styles.chatBottomButtonPsychicText}>
          <span>
            {`${psychicName}`} is online, connect with psychic to get a reading.
          </span>
        </div>
      )}
    </div>
  );
};

export default ChatAgainButtonContainer;
