import React from 'react';
import cn from 'classnames';

import {
  showLogoutPrompt as _showLogoutPrompt,
} from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { LogoutBtn } from 'types/componentTypes';

import styles from './LogoutButton.module.scss';

const LogoutButton = ({ handleTooltipVisibility, className = '' }: LogoutBtn) => {
  const showLogoutPrompt = useBindDispatch(_showLogoutPrompt);

  const handleLogoutClick = (e) => {
    if (handleTooltipVisibility) {
      handleTooltipVisibility(e);
    }

    showLogoutPrompt();
  };

  return (
    <div className={styles.logoutButtonWrapper}>
      <button
        type="button"
        className={cn(styles.logoutLink, {
          [className]: className,
        })}
        onClick={handleLogoutClick}
      >
        Logout
      </button>
    </div>
  );
};

export default LogoutButton;
