import React, { useMemo } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { InteractChatButton } from 'extracted-chat-components';

import { InsufficientFunds } from 'types/componentTypes';
import CustomTooltip from 'components/Tooltips/CustomTooltip';
import HeaderButton from 'components/Popups/InsufficientFundsPopup/PopupComponents/headerButtons';
import ARStatusBar from 'components/Popups/InsufficientFundsPopup/PopupComponents/StatusBar';

import style from './style.module.scss';

const InsufficientFundsPopup: React.FC<InsufficientFunds> = ({
  isOpen,
  sideUser,
  psychicChatStatus,
  fundsToAdd,
  acceptButton,
  rejectButton,
  psychicImage,
  isAutoReloadEnabled,
  autoReloadLabels,
  isInfoTooltipVisible,
  isToggleTooltipVisible,
  infoTooltipButtonRef,
  toggleTooltipButtonRef,
  handleAutoReloadChange,
  CurrentBalanceRowLabel,
  RequiredBalanceRowLabel,
  handleToggleTooltipVisible,
  handleInfoTooltipVisible,
}) => {
  const {
    autoreloadChatButtonText,
    autoreloadChatHeaderText1,
    autoreloadChatToggleOnText1,
    autoreloadChatAsteriskInfoText,
    autoreloadChatHeadTooltipText1,
    autoreloadChatToggleTurnOnText1,
    autoreloadChatToggleTurnOffText1,
    autoreloadChatToggleTooltipText1,
    autoreloadChatToggleTooltipText2,
    autoreloadChatToggleTurnOffInfoText1,
  } = autoReloadLabels;

  const acceptButtonText = useMemo(() => (
    autoreloadChatButtonText?.replace('XX.XX', parseFloat(fundsToAdd).toFixed(2))
  ), [fundsToAdd, autoreloadChatButtonText]);

  const toggleButtonLabel = useMemo(() => (
    isAutoReloadEnabled
      ? autoreloadChatToggleTurnOnText1
      : autoreloadChatToggleTurnOffText1),
  [
    isAutoReloadEnabled,
    autoreloadChatToggleTurnOffText1,
    autoreloadChatToggleTurnOnText1,
  ]);

  const toggleInfoLabel = useMemo(() => (isAutoReloadEnabled
    ? autoreloadChatToggleOnText1
    : autoreloadChatToggleTurnOffInfoText1),
  [
    isAutoReloadEnabled,
    autoreloadChatToggleOnText1,
    autoreloadChatToggleTurnOffInfoText1,
  ]);

  const sliderCn = cn(
    style.autoReloadSlider,
    isAutoReloadEnabled ? style.autoReloadOn : style.autoReloadOff,
  );
  const sliderInner = cn(
    style.autoReloadSliderContent,
    isAutoReloadEnabled ? style.autoReloadTextOn : style.autoReloadTextOff,
  );

  const toggleContainer = cn(
    style.toggleContainer, {
      [style.toggleContainerHeight]: isAutoReloadEnabled,
      [style.toggleContainerOverflow]: isToggleTooltipVisible,
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      className={style.modal}
      overlayClassName={style.overlay}
      ariaHideApp={false}
    >
      <div className={cn(style.wrapper, style.wrapperWidthBig)}>
        <HeaderButton onClick={rejectButton.handler} psychicName={sideUser?.friendlyName} />
        <img
          alt="psychic"
          src={psychicImage}
          className={style.psychicImg}
        />
        <div className={style.container}>
          <ARStatusBar
            status={psychicChatStatus}
            basePrice={sideUser?.basePrice}
            rate={sideUser?.customerPrice || sideUser?.basePrice}
          />
          <div>
            <div className={style.topMessage}>
              {autoreloadChatHeaderText1}
              <button
                type="button"
                className={style.topMessageBtn}
                ref={infoTooltipButtonRef}
                onClick={handleInfoTooltipVisible()}
                onBlur={handleInfoTooltipVisible(true)}
              >
                <span className={style.topMessageInfo}>i</span>
                <CustomTooltip
                  isOpen={isInfoTooltipVisible}
                  classNames={style.topMessageTooltip}
                >
                  <span className={style.tooltipCol}>
                    {autoreloadChatHeadTooltipText1}
                  </span>
                  <CurrentBalanceRowLabel style={style} />
                  <RequiredBalanceRowLabel style={style} />
                </CustomTooltip>
              </button>
            </div>
            <div className={toggleContainer}>
              <div className={style.toggleWrapper}>
                <div className={cn(style.toggleMessage, {
                  [style.toggleMessageSize]: isAutoReloadEnabled,
                })}
                >
                  {toggleButtonLabel}
                  <button
                    type="button"
                    className={style.toggleInfoBtn}
                    ref={toggleTooltipButtonRef}
                    onClick={handleToggleTooltipVisible()}
                    onBlur={handleToggleTooltipVisible(true)}
                  >
                    <span className={style.topMessageInfo}>i</span>
                    <CustomTooltip
                      isOpen={isToggleTooltipVisible}
                      classNames={cn(style.toggleTooltip, {
                        [style.toggleTooltipOn]: isAutoReloadEnabled,
                      })}
                    >
                      <span>
                        {autoreloadChatToggleTooltipText1}
                      </span>
                      <span>
                        {autoreloadChatToggleTooltipText2}
                      </span>
                    </CustomTooltip>
                  </button>
                </div>
                <div className={style.autoReloadToggleContainer}>
                  <div className={style.sliderContainer}>
                    <label htmlFor="autoReloadToggle" className={style.autoReloadToggleButton}>
                      <input
                        checked={isAutoReloadEnabled}
                        type="checkbox"
                        id="autoReloadToggle"
                        onChange={handleAutoReloadChange}
                      />
                      <span className={sliderCn}>
                        <div className={sliderInner}>
                          {isAutoReloadEnabled ? 'ON' : 'OFF'}
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <span className={style.toggleInfo}>
                {toggleInfoLabel}
              </span>
            </div>
            <div className={style.containerModalButtons}>
              <div className={style.containerModalButtonsAccept}>
                <InteractChatButton
                  isActive
                  icon={null}
                  text={acceptButtonText}
                  onClick={acceptButton.handler}
                  size={acceptButton.size}
                  color={acceptButton.color}
                  className={style.containerModalButtonsAddFunds}
                />
              </div>
            </div>
          </div>
          <span className={style.bottomMessage}>
            {autoreloadChatAsteriskInfoText}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default InsufficientFundsPopup;
