import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from 'constants/enums';
import { PsychicMissChatInterface } from 'types/componentTypes';
import { goTo } from 'route-history';
import { VIEW } from 'constants/constants';

const PsychicMissChat: React.FC<PsychicMissChatInterface> = ({
  view,
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
}) => {
  const okHandler = () => {
    showPopUpNotification(false);
    goTo(`/conversations?${VIEW}=${view?.toLowerCase()}`);
  };

  const acceptButton = {
    text: 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: okHandler,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
    />
  );
};

export default PsychicMissChat;
