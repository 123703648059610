import { useSelector } from 'react-redux';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { PopUpNotification } from 'extracted-chat-components';
import cn from 'classnames';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  MixpanelEvents,
  SdkEvents,
  Status,
  ChatInitMessages,
  AutoReloadLocation,
} from 'constants/enums';
import {
  ChatErrors,
  CUSTOMER_BALANCE,
  FUNDS_TO_ADD,
  MINIMUM_CHAT_DURATION,
} from 'constants/constants';
import { API } from 'src/utils/api';
import { goTo } from 'route-history';
import {
  setInlineNotification as _setInlineNotification,
  addPopUpNotification as _addPopUpNotification,
  setToastBannerData as _setToastBannerData,
  handleRequestError as _handleRequestError,
} from 'actions/appActions';
import {
  setCustomerEnableAutoRecharge as _setCustomerEnableAutoRecharge,
  handleCreateChatErrors as _handleCreateChatErrors,
} from 'actions/chatActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { InsufficientFundsProps } from 'types/componentTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import { AddFundsPayload, PaymentDetail } from 'src/types/objectTypes';
import MixpanelActions from 'src/utils/mixpanel';
import { SentryMethods } from 'src/utils/sentryMethods';
import {
  getPlatform,
  kountSessionIDHandler,
  redirectUserToWebsiteOrNativeApp,
  removeInlineNotificationFromLocalStorage,
  handleSwitchToPhoneNotifications,
  simplyfyJsonArrayKeys,
  getGA4Properties,
  descriptionFromError,
} from 'src/utils/commonUtil';
import {
  selectCustomerId,
  selectUserAgent,
  selectIsChatAutoReloadEnable,
  selectCurrentUser,
  selectAutoReloadPromptsLabels,
  selectCurrentPsychicStatus,
} from 'selectors/selectors';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';

import InsufficientFundsPopup from './InsufficientFundsPopup';

const InsufficientFundsPopupContainer: React.FC<InsufficientFundsProps> = ({
  isNotificationPopUp,
  popUpNotificationData,
  chatId,
  fundsToAdd,
  customerBalance,
  sdkCallbackStorage,
  sideUser,
  destroySessionSdk,
  showPopUpNotification,
  setPopUpNotificationData,
  setChatStatus,
  clearExtId,
  removeChatId,
  deleteCurrentChat,
  setIsLiveChatActive,
  setShowInitModal,
  handleAddFundsError,
  setActivity,
  addMixpanelEventAlertTapped,
  setLoadingState,
  setFunds,
  setInitChatNotification,
  setInitChatBanner,
  setCustomerBalance,
}) => {
  const [psychicImage, setPsychicImage] = useState<any>();
  const [isInfoTooltipVisible, setInfoTooltipVisible] = useState<boolean>(false);
  const [isToggleTooltipVisible, setToggleTooltipVisible] = useState<boolean>(false);

  const infoTooltipButtonRef = useRef(null);
  const toggleTooltipButtonRef = useRef(null);

  const setInlineNotification = useBindDispatch(_setInlineNotification);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setCustomerEnableAutoRecharge = useBindDispatch(_setCustomerEnableAutoRecharge);
  const setToastBannerData = useBindDispatch(_setToastBannerData);
  const handleCreateChatErrors = useBindDispatch(_handleCreateChatErrors);
  const handleRequestError = useBindDispatch(_handleRequestError);

  const autoReloadPromptsLabels = useSelector(selectAutoReloadPromptsLabels);
  const customerId = useSelector(selectCustomerId);
  const userAgent = useSelector(selectUserAgent);
  const currentUser = useSelector(selectCurrentUser);
  const isChatAutoReloadEnable = useSelector(selectIsChatAutoReloadEnable);
  const isCustomerAutoReloadExist = sideUser?.isCustomerAutoReloadExist;
  const psychicChatStatus = useSelector(selectCurrentPsychicStatus);

  const [isAutoReloadEnabled, setAutoReloadEnable] = useState<boolean>(!isCustomerAutoReloadExist);

  const isTalk = popUpNotificationData.reason === 'talk' || popUpNotificationData.reason === 'switchToPhone';
  const psychicRate: any = sideUser?.basePrice || 0;
  const bal = +customerBalance;
  const custBalance = bal < 0 ? `-$${Math.abs(bal).toFixed(2)}` : `$${parseFloat(customerBalance).toFixed(2)}`;
  const rate = sideUser?.customerPrice || 0;
  const isDiscountedRate = rate && psychicRate !== rate;
  const billingRate = isDiscountedRate ? rate : psychicRate;
  const oneTimeCharge = (billingRate * MINIMUM_CHAT_DURATION);

  const { enableAutoRecharge } = currentUser;

  const autoReloadLabels = simplyfyJsonArrayKeys(autoReloadPromptsLabels);

  const isNonARPopup = useMemo(() => !isChatAutoReloadEnable || enableAutoRecharge || isTalk,
    [enableAutoRecharge, isChatAutoReloadEnable, isTalk]);

  useEffect(() => {
    if (isInfoTooltipVisible && infoTooltipButtonRef) {
      infoTooltipButtonRef?.current?.focus();
    }
  }, [isInfoTooltipVisible]);

  useEffect(() => {
    if (isToggleTooltipVisible && toggleTooltipButtonRef) {
      toggleTooltipButtonRef?.current?.focus();
    }
  }, [isToggleTooltipVisible]);

  useEffect(() => {
    const funds = LocalStorage.getItem(FUNDS_TO_ADD) || 0;
    const balance = LocalStorage.getItem(CUSTOMER_BALANCE) || 0;

    if (!fundsToAdd && +funds > 0) {
      setFunds(funds as string);
    }

    if (!customerBalance) {
      setCustomerBalance(balance as string);
    }
  }, [fundsToAdd, customerBalance]);

  useEffect(() => {
    if (!isNotificationPopUp) {
      setAutoReloadEnable(true);
    }
  }, [isNotificationPopUp]);

  useEffect(() => {
    if (sideUser?.extId && sideUser?.images) {
      setPsychicImage(sideUser.images[3]);
    }
  }, [sideUser]);

  const handleAutoReloadChange = () => {
    setAutoReloadEnable(!isAutoReloadEnabled);
  };

  const sendAddFundsMixpanelEventFundDeposited = (paymentDetail: PaymentDetail) => {
    if (paymentDetail) {
      MixpanelActions.track(MixpanelEvents.FUNDS_DEPOSITED_FE, {
        added_amount: paymentDetail.addedFunds,
        karma_rewards_points: paymentDetail.karmaPoints,
        new_amount: paymentDetail.availableBalance,
        payment: paymentDetail.payment,
        payment_type: paymentDetail.paymentMethod,
        promo_applied: paymentDetail.promoCode || '',
        payment_frequency: 'insufficient funds',
      });
    }
  };

  const handleInfoTooltipVisible = (isOnBlur = false) => {
    if (isOnBlur) {
      return () => setInfoTooltipVisible(false);
    }

    return () => setInfoTooltipVisible(!isInfoTooltipVisible);
  };

  const handleToggleTooltipVisible = (isOnBlur = false) => {
    if (isOnBlur) {
      return () => setToggleTooltipVisible(false);
    }

    return () => setToggleTooltipVisible(!isToggleTooltipVisible);
  };

  const handleAcceptButton = async () => {
    try {
      setLoadingState(true);
      showPopUpNotification(false);
      const kountSessionID = await kountSessionIDHandler();
      MixpanelActions.track(MixpanelEvents.ALERT_TAPPED, {
        alert_type: 'add funds to continue',
        alert_selection: 'add $x to continue',
        alert_option: `${fundsToAdd}`,
        screen_name: 'chat window',
        screen_title: 'chat window',
        ...getPsychicInfoMixpanel(sideUser),
      });

      if (chatId && !isTalk) {
        const addFundsPayload: AddFundsPayload = {
          sourcePlatform: getPlatform(userAgent) as string,
          KountSessionID: kountSessionID,
          GA4Parameters: getGA4Properties(),
        };

        if (isChatAutoReloadEnable && !isTalk) {
          addFundsPayload.autoReloadEnable = isAutoReloadEnabled;
          addFundsPayload.location = AutoReloadLocation.LOW_FUND_CHAT_START;
        }

        const result = await API.Chat.addFunds(chatId, addFundsPayload);
        const paymentDetail = result?.data?.paymentDetail;

        sendAddFundsMixpanelEventFundDeposited(paymentDetail);
        await API.Chat.startChatRequest(chatId);
        setActivity();
        setShowInitModal(true);
        setInitChatBanner(true);
        setInitChatNotification(ChatInitMessages.CONTACTING_PSYCHIC);
        setChatStatus(Status.PREPARING);

        if (isChatAutoReloadEnable && !isTalk && result?.data) {
          setCustomerEnableAutoRecharge(addFundsPayload?.autoReloadEnable as boolean);
        }

        if (isAutoReloadEnabled && !isTalk && result?.data) {
          setToastBannerData({
            isVisible: true,
            toastMessage: autoReloadLabels?.autoreloadChatToastText1,
            isAnimation: true,
          });
        }
      } else {
        await API.Customer.topUpBalance({ custId: customerId, amount: +fundsToAdd });

        handleSwitchToPhoneNotifications(
          {
            addPopUpNotification,
            sideUser,
          },
        );
      }
    } catch (e) {
      console.log(e);
      setShowInitModal(false);
      setInitChatBanner(false);
      SentryMethods.captureException(e);

      const errorData = e.response?.data;

      if (ChatErrors.includes(errorData.error?.code)) {
        handleCreateChatErrors(errorData);
      } else {
        handleAddFundsError(e, (data) => {
          setPopUpNotificationData(data);
        });
      }

      showPopUpNotification(true);
    } finally {
      removeInlineNotificationFromLocalStorage();
      setInlineNotification('', null);
      LocalStorage.removeItem('fundsToAdd');
      setLoadingState(false);
    }
  };

  const acceptButton = useMemo(() => ({
    text: `Add $${fundsToAdd} to continue`,
    color: ButtonColor.WARNING,
    size: isNonARPopup ? ButtonSize.M : ButtonSize.L,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  }), [isAutoReloadEnabled, fundsToAdd, isNonARPopup]);

  const handleCancelButton = async () => {
    const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);

    try {
      addMixpanelEventAlertTapped({ alertType: 'add funds to continue', alertSelection: 'cancel' });

      if (redirectUserToWebsiteOrNativeApp()) {
        return;
      }

      if (onLogoutCallback) {
        setLoadingState(true);
        await API.Chat.cancelChat(chatId);
      } else {
        showPopUpNotification(false);
        clearExtId();
        deleteCurrentChat();
        setIsLiveChatActive(false);
        setShowInitModal(false);
        await API.Chat.cancelChat(chatId);
        removeChatId();
        goTo('/psychics?view=customer');
      }
    } catch (e) {
      console.log(e);
      SentryMethods.captureException(e);
      const requestErrorPayload = {
        redirectPath: '',
        isInvalidToken: false,
        errorText: e?.message,
        description: descriptionFromError(e),
      };

      handleRequestError(requestErrorPayload);
    } finally {
      LocalStorage.removeItem('fundsToAdd');

      if (onLogoutCallback) {
        setLoadingState(false);
        destroySessionSdk(false);
      }
    }
  };

  const rejectButton = {
    text: 'Cancel',
    color: ButtonColor.ONLY_TEXT,
    shape: ButtonShape.ROUNDED,
    handler: handleCancelButton,
  };

  const CurrentBalanceRowLabel = ({ style }) => (
    <div className={style.tooltipRow}>
      <span>
        Your current account balance:
      </span>
      <span>
        {custBalance}
      </span>
    </div>
  );

  const RequiredBalanceRowLabel = ({ style }) => (
    <div className={style.tooltipRow}>
      <span className={style.tooltipCol}>
        {sideUser?.friendlyName} <br />
        <span>{isDiscountedRate && `$${billingRate.toFixed(2)}/min`}</span>
        {isDiscountedRate && <br />}
        <span className={cn({
          [style.baseRate]: isDiscountedRate,
        })}
        >
          {`$${psychicRate?.toFixed(2)}/min`}
        </span> for 5 mins
      </span>
      <span>
        {`$${parseFloat(`${oneTimeCharge}`).toFixed(2)}`}
      </span>
    </div>
  );

  if (isNonARPopup) {
    return (
      <PopUpNotification
        isOpen={isNotificationPopUp}
        title={popUpNotificationData.title}
        acceptButton={acceptButton}
        rejectButton={rejectButton}
        isBigWidth={false}
      />
    );
  }

  return (
    <InsufficientFundsPopup
      isOpen={isNotificationPopUp}
      sideUser={sideUser}
      psychicChatStatus={psychicChatStatus}
      fundsToAdd={isAutoReloadEnabled ? fundsToAdd : oneTimeCharge}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      psychicImage={psychicImage}
      isAutoReloadEnabled={isAutoReloadEnabled}
      autoReloadLabels={autoReloadLabels}
      isInfoTooltipVisible={isInfoTooltipVisible}
      isToggleTooltipVisible={isToggleTooltipVisible}
      infoTooltipButtonRef={infoTooltipButtonRef}
      toggleTooltipButtonRef={toggleTooltipButtonRef}
      handleAutoReloadChange={handleAutoReloadChange}
      CurrentBalanceRowLabel={CurrentBalanceRowLabel}
      RequiredBalanceRowLabel={RequiredBalanceRowLabel}
      handleToggleTooltipVisible={handleToggleTooltipVisible}
      handleInfoTooltipVisible={handleInfoTooltipVisible}
    />
  );
};

export default InsufficientFundsPopupContainer;
