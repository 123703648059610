import React from 'react';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import {
  selectExtId,
  selectEncryptedPassword,
} from 'selectors/selectors';
import Calendar from 'components/PsychicManageSchedule/Calendar';

const ManageSchedule = () => {
  const extId = useSelector(selectExtId);
  const encryptPassword = useSelector(selectEncryptedPassword);
  const password = CryptoJS.AES
    .decrypt(
      encryptPassword,
      process.env.PSYCHIC_PASSWORD_SECRET,
    ).toString(CryptoJS.enc.Utf8);

  return (
    <Calendar password={password} extId={extId} />
  );
};

export default ManageSchedule;
