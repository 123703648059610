import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import cn from 'classnames';
import { MixpanelEvents } from 'extracted-chat-components/enums';

import { setBackdropState as _setBackdropState } from 'actions/appActions';
import ComposeIcon from 'src/assets/png/ComposeNoteIcon.png';
import vector from 'src/assets/png/Vector1.png';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { PSYCHIC_LIST_SORT_OPTIONS } from 'constants/constants';
import PsychicList from 'src/components/ClientHubWeb/PsychicList';
import SortButtonDropDown from 'src/components/ClientHubWeb/HeaderButton/DropDown';
import HeaderButton from 'src/components/ClientHubWeb/HeaderButton/buttons';
import MixpanelActions from 'src/utils/mixpanel';
import useBindDispatch from 'src/hooks/useBindDispatch';

import style from './HeaderButtons.module.scss';

const HeaderButtons = ({
  customerList,
  handleOnSort,
  handlePsychicNoteScreens,
  customerMessageFAQUrl,
  notificationList,
  sortButtonName,
}) => {
  const { isMobileForClientHub } = useIsMobile();

  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);
  const [isPsychicList, setIsPsychicList] = useState<boolean>(false);
  const setBackdropState = useBindDispatch(_setBackdropState);
  const sortButtonRef = useRef(null);
  const sendButtonRef = useRef(null);

  useEffect(() => {
    if (isPsychicList && sendButtonRef) {
      sendButtonRef?.current?.focus();
    }
  }, [isPsychicList]);

  useEffect(() => {
    if (isDropDownVisible && sortButtonRef) {
      sortButtonRef?.current?.focus();
    }
  }, [isDropDownVisible]);

  const handleDropDownVisible = useCallback((isOnBlur = false) => {
    if (isOnBlur) {
      return () => {
        setTimeout(() => {
          setIsDropDownVisible(false);
          setBackdropState(false);
        }, 500);
      };
    }

    return () => {
      setIsDropDownVisible(!isDropDownVisible);
      setBackdropState(notificationList.length && !isDropDownVisible);

      if (isPsychicList) {
        setIsPsychicList(false);
      }
    };
  }, [isDropDownVisible, isPsychicList]);

  const handlePsychicListVisible = useCallback((isOnBlur = false) => {
    if (isOnBlur) {
      return () => {
        setTimeout(() => {
          setIsPsychicList(false);
          setBackdropState(false);
        }, 500);
      };
    }

    if (isPsychicList) {
      MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
        button_text: 'send a note',
        button_type: 'button',
        screen_name: 'inbox',
        screen_title: 'inbox',
      });
    }

    return () => {
      setIsPsychicList(!isPsychicList);
      setBackdropState(!isPsychicList);

      if (isDropDownVisible) {
        setIsDropDownVisible(false);
      }
    };
  }, [isPsychicList, isDropDownVisible]);

  const triggerPsychicNoteFAQMixPanel = () => {
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'psychic notes FAQs',
      button_type: 'kabob menu item',
      screen_name: 'psychic notes',
      screen_title: 'psychic notes',
    });
  };

  return (
    <div className={style.header}>
      <div className={style.btnNewestWrapper}>
        <HeaderButton
          text={sortButtonName}
          classesName={cn(style.btn, style.btnNewest)}
          isLeft={false}
          buttonRef={sortButtonRef}
          onClick={handleDropDownVisible()}
          onBlur={handleDropDownVisible(true)}
          icon={(
            <img
              src={vector}
              alt="vector"
            />
          )}
        />
        <SortButtonDropDown
          isVisible={isDropDownVisible && notificationList.length}
          options={PSYCHIC_LIST_SORT_OPTIONS}
          onClick={handleOnSort}
        />
      </div>
      <div className={style.btnWrapper}>
        <a
          href={customerMessageFAQUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={style.btnAnchorTag}
          aria-label="faq"
        >
          <HeaderButton
            text="Psychic Notes FAQs"
            classesName={cn(style.btn, style.btnNoteFAQ)}
            isVisible={!isMobileForClientHub}
            onClick={triggerPsychicNoteFAQMixPanel}
          />
        </a>
        <HeaderButton
          text="Send a Note"
          classesName={cn(style.btn, style.btnSendNote)}
          onClick={handlePsychicListVisible()}
          onBlur={handlePsychicListVisible(true)}
          buttonRef={sendButtonRef}
          icon={(
            <img
              src={ComposeIcon}
              alt="ComposeIcon"
              className={style.btnSendNoteImg}
            />
          )}
        >
          <PsychicList
            isVisible={isPsychicList}
            customerList={customerList}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
          />
        </HeaderButton>
      </div>
    </div>
  );
};

export default HeaderButtons;
