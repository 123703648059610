import React from 'react';
import { Card, Grid, Box } from '@mui/material';
import cn from 'classnames';

import SequenceReaction from 'components/PsychicNotifications/StatsView/SequenceReaction';
import { ReactComponent as StatIcon } from 'assets/svg/notification-graph.svg';
import { INotificationStatistics } from 'types/componentTypes';
import GamifyStats from 'components/PsychicNotifications/GamifyStats';
import styles from 'components/PsychicNotifications/StatsView/NotificationStatistics.module.scss';
import mobileStyle from 'components/PsychicNotifications/StatsView/NotificationStatsMobile.module.scss';

const NotificationStatistics: React.FC<INotificationStatistics> = ({
  totalNotifications,
  reactionPercentage,
  reactionsList,
  engagementGoal,
  isMobile,
  isGamifyEnabled,
  isOpen,
}) => (
  <div className={styles.statisticsContainer}>
    <Card className={cn(styles.notification, { [styles.hide]: isMobile && isGamifyEnabled })}>
      {!isMobile && (
        <>
          <span className={styles.notificationSent}>Notes Sent</span>
          <div className={styles.iconWrapper}>
            <StatIcon className={styles.icon} />
            <span className={styles.notificationPercentage}>
              {reactionPercentage}
            </span>
          </div>
          <span className={styles.notificationAmount}>
            {totalNotifications}
          </span>
        </>
      )}

      {(isMobile && !isGamifyEnabled) && (
        <>
          <span className={styles.notificationSent}>Notes Sent</span>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StatIcon className={styles.icon} />
              </Grid>
              <Grid item xs={6}>
                <span className={styles.notificationPercentage}>
                  {reactionPercentage}
                </span>
              </Grid>
              <Grid item xs={3}>
                <span className={styles.notificationAmount}>
                  {totalNotifications}
                </span>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Card>
    {(!isGamifyEnabled || !isMobile) && (
      <Card className={styles.reactions}>
        <span className={styles.reactionsReceived}>
          Reactions Received
        </span>
        <div className={styles.reactionsList}>
          <SequenceReaction
            reactionsList={reactionsList}
            isGamifyEnabled={isGamifyEnabled}
          />
        </div>
      </Card>
    )}

    {(isGamifyEnabled && isMobile) && (
      <Card className={mobileStyle.reactions}>
        <Grid item xs={3}>
          <span className={mobileStyle.reactionsReceived}>
            Reactions Received
          </span>
        </Grid>
        <SequenceReaction
          reactionsList={reactionsList}
          isGamifyEnabled={isGamifyEnabled}
        />

      </Card>
    )}
    {(isGamifyEnabled && isOpen)
    && <GamifyStats engagementGoal={engagementGoal} />}
  </div>
);

export default NotificationStatistics;
