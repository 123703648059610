import React from 'react';

import { IBreakPlaceHolder } from 'types/componentTypes';

const BreakPlaceHolder: React.FC<IBreakPlaceHolder> = ({ isDelayed = false, breakMinutes = 0 }) => {
  if (isDelayed) {
    return (
      <i>
        You have an active call or chat.
        Your break will begin as soon as you
        complete your reading.
      </i>
    );
  }

  return (
    <i>
      If you don’t return within {breakMinutes} minutes
      from the time you went on break,
      you will be automatically logged out.
    </i>
  );
};

export default BreakPlaceHolder;
