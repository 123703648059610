import { DRAFTED_MESSAGE } from 'src/constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';

export class DraftedMessages {
  static save(message: string, chatId: string): void {
    try {
      const storageItemJson = LocalStorage.getItem(DRAFTED_MESSAGE);
      const storageItem = (storageItemJson)
        ? JSON.parse(storageItemJson)
        : {};

      storageItem[chatId] = message;
      LocalStorage.setItem(DRAFTED_MESSAGE, JSON.stringify(storageItem));
    } catch (error) {
      this.removeAll();
    }
  }

  static get(chatId: string): string {
    try {
      const storageItemJson = LocalStorage.getItem(DRAFTED_MESSAGE);

      if (!storageItemJson) return '';

      const storageItem = JSON.parse(storageItemJson);

      return storageItem ? storageItem[chatId] : '';
    } catch (error) {
      this.removeAll();
    }

    return '';
  }

  static getAll(): object {
    try {
      const storageItemJson = LocalStorage.getItem(DRAFTED_MESSAGE);

      if (!storageItemJson) return {};

      const storageItem = JSON.parse(storageItemJson);

      return storageItem || {};
    } catch (error) {
      this.removeAll();
    }

    return {};
  }

  static remove(chatId: string): void {
    try {
      const storageItemJson = LocalStorage.getItem(DRAFTED_MESSAGE);

      if (!storageItemJson) return;

      const storageItem = JSON.parse(storageItemJson);

      if (!storageItem && !storageItem[chatId]) return;

      delete storageItem[chatId];
      LocalStorage.setItem(DRAFTED_MESSAGE, JSON.stringify(storageItem));
    } catch (error) {
      this.removeAll();
    }
  }

  static removeAll(): void {
    LocalStorage.removeItem(DRAFTED_MESSAGE);
  }
}
