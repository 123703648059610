import { EMAIL_REGEX, HOTLINE_NUMBER, PHONE_REGEX } from 'constants/constants';

export const checkPhoneOrEmailInMessage = (message: string) => {
  const regExpEmailAndPhone = new RegExp(`${EMAIL_REGEX.source}|${PHONE_REGEX.source}`, 'gm');

  return message?.match(regExpEmailAndPhone);
};

export const checkPhoneOrEmailInMessageWithoutHotlineNumber = (
  message: string, isPsyshic: boolean = true,
) => {
  const phoneOrEmail = checkPhoneOrEmailInMessage(message);
  const phoneOrEmailWithoutHotlineNumber = phoneOrEmail?.filter((phoneNumberOrEmail) => {
    const phoneorEmailWithoutSpaceChar = phoneNumberOrEmail?.replace(/[^A-Za-z0-9+]/g, '');

    return !(isPsyshic && HOTLINE_NUMBER.includes(phoneorEmailWithoutSpaceChar.replaceAll('+', '')));
  });

  return phoneOrEmailWithoutHotlineNumber;
};
