import {
  call,
  put,
  putResolve,
  select,
} from 'redux-saga/effects';

import {
  PASSWORD_STORAGE,
  PSYCHIC_ID_STORAGE,
} from 'constants/constants';
import {
  ErrorRequestType,
  NotificationsText,
  SdkEvents,
} from 'constants/enums';
import {
  clearChatEvents,
  setEncryptedPassword,
  setExtId,
} from 'actions/chatActions';
import {
  selectCurrentChat,
  selectIsLiveChatActive,
  selectSdkCallbackStorage,
  selectView,
} from 'selectors/selectors';
import {
  clearApp,
  destroySessionSdk,
  handleRequestError,
  setConversationSdk,
  setLoadingState,
  setRequestErrorState,
  setSessionSdk,
} from 'actions/appActions';
import { clearPsychicNotification } from 'actions/psychicNotifications';
import { API } from 'src/utils/api';
import {
  clearSession,
  receiveSdkAuthData,
} from 'actions/authActions';
import { clearSessionSdk, LocalStorage } from 'src/utils/storageHandler';
import { goTo } from 'route-history';
import { ChatType } from 'types/objectTypes';
import { descriptionFromError } from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* setConversationSdkWorker({
  payload: dataFromSdk,
}: ReturnType<typeof setConversationSdk>) {
  try {
    yield put(setLoadingState(true));

    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

    yield put(setExtId(dataFromSdk.psychicId));
    LocalStorage.setItem(PSYCHIC_ID_STORAGE, dataFromSdk.psychicId);

    const conversationCallback = sdkCallbackStorage.get(SdkEvents.SET_CONVERSATION);

    if (conversationCallback) {
      conversationCallback();
      sdkCallbackStorage.delete(SdkEvents.SET_CONVERSATION);
    }
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* setSessionSdkWorker({
  payload: dataFromSdk,
}: ReturnType<typeof setSessionSdk>) {
  try {
    if (!dataFromSdk) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    const {
      token,
      tokenBearer,
      userId,
      refreshToken,
      sessionId,
      cipherPassword,
    } = dataFromSdk;

    if (cipherPassword) {
      yield put(setEncryptedPassword(cipherPassword));
      LocalStorage.setItem(PASSWORD_STORAGE, cipherPassword);
    }

    if (!token) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    if (!userId) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    clearSessionSdk();
    yield put(receiveSdkAuthData(
      token,
      tokenBearer,
      refreshToken,
      userId,
      sessionId,
    ));
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield call(SentryMethods.captureException, e);
    yield put(setRequestErrorState(true, ErrorRequestType.POP_UP_NOTIFICATION));
    yield put(handleRequestError(requestErrorPayload));
  }
}

export function* destroySessionSdkWorker({
  payload: isShouldEnd,
}: ReturnType<typeof destroySessionSdk>) {
  try {
    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

    yield put(setLoadingState(true));
    const currentChat: ChatType = yield select(selectCurrentChat);
    const view = yield select(selectView);
    const isLiveChatActive = yield select(selectIsLiveChatActive);

    if (currentChat.chatId && isShouldEnd && isLiveChatActive) {
      yield call(API.Chat.endChatRequest, currentChat.chatId);
    }

    LocalStorage.clear();
    yield putResolve(clearSession());
    yield putResolve(clearChatEvents());
    yield putResolve(clearPsychicNotification());
    yield putResolve(clearApp());
    const logoutCallback = sdkCallbackStorage.get(SdkEvents.LOGOUT);

    if (logoutCallback) {
      yield call(logoutCallback, view);
      sdkCallbackStorage.delete(SdkEvents.LOGOUT);
    }

    const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);

    if (onLogoutCallback) {
      yield call(onLogoutCallback, { name: SdkEvents.ON_LOGOUT });
    }

    goTo(`/sdk?queryView=${view}&isDestroyed=true`);
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
  }
}
