import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import ChatItem from 'components/Chats/ChatItem';
import {
  selectCurrentChat,
  selectIsSwitchedConversations,
  selectView,
} from 'selectors/selectors';
import Plain from 'components/Plain';
import NoLiveChatBanner from 'components/Banners/NoLiveChatBanner';
import { ChatType } from 'types/objectTypes';
import { ConversationType, View } from 'constants/enums';
import { useIsMobile } from 'src/hooks/useIsMobile';
import ChatItemTitle from 'components/Titles/ChatItemTitle';

import styles from './styles.module.scss';

interface IChats {
  conversationType: ConversationType,
  // eslint-disable-next-line react/require-default-props
  chatItems?: Array<ChatType>
}

const Chats: React.FC<IChats> = ({ conversationType, chatItems }) => {
  const currentChat = useSelector(selectCurrentChat);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const view = useSelector(selectView) as View;

  const { isMobile } = useIsMobile();

  const isLiveChat = currentChat?.attributes?.requestType === ConversationType.LIVE_CHAT;
  const isLiveChatTab = conversationType === ConversationType.LIVE_CHAT;

  if (!isLiveChat && isLiveChatTab) {
    return (
      <div className={styles.wrapper}>
        <NoLiveChatBanner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.chatItems, {
        [styles.hide]: isMobile && isSwitchedConversations,
      })}
      >
        <ChatItemTitle isLiveChat={isLiveChatTab} />
        {chatItems?.length ? chatItems.map((chat) => (
          <ChatItem
            key={chat.chatId}
            chat={chat}
            currentChatId={currentChat.chatId}
            type={conversationType}
          />
        )) : (
          <ChatItem
            chat={isLiveChat && isLiveChatTab && currentChat}
            type={conversationType}
          />
        )}
      </div>
      <div className={cn(styles.chatWrapper, {
        [styles.chatWrapperSwitchedConversation]:
        isMobile && isSwitchedConversations && view === View.PSYCHIC,
        [styles.hide]: isMobile && !isSwitchedConversations && view === View.PSYCHIC,
      })}
      >
        <Plain requestType={conversationType} />
      </div>
    </div>
  );
};

export default Chats;
