import React from 'react';
import cn from 'classnames';

import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import PsychicNotificationTitle from 'components/Titles/PsychicNotificationTitle';
import ComposeNotificationButtonForMobile from 'components/Buttons/ComposeNotificationButton/ComposeNotificationButtonForMobile';
import { IPsychicNotification } from 'types/componentTypes';
import ConfirmationScreenPopupContainer from 'components/Popups/ConfirmationScreenPopup';
import StatsViewContainer from 'components/PsychicNotifications/StatsView/StatsViewContainer';
import styles from 'components/PsychicNotifications/PsychicNotifications.module.scss';

const PsychicNotifications: React.FC<IPsychicNotification> = ({
  isMobile,
  activeScreen,
  customerName,
  isGamifyEnabled,
  isAllNotesScreen,
  nextPriorityAction,
  sendCustomerDetail,
  isBottomCTAVisible,
  handleReturnButton,
  handleComposeNewButton,
  notificationStatistics,
  handlePsychicNoteScreens,
  isConfirmationScreenVisible,
  children,
}) => (
  <div className={cn(styles.wrapper, {
    [styles.wrapperClientNotes]: activeScreen === PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION,
  })}
  >
    <ConfirmationScreenPopupContainer
      customerName={customerName}
      handleReturnButton={handleReturnButton}
      handleComposeNewButton={handleComposeNewButton}
      isConfirmationScreenVisible={isConfirmationScreenVisible}
      nextPriorityAction={nextPriorityAction}
      handlePsychicNoteScreens={handlePsychicNoteScreens}
    />
    <PsychicNotificationTitle
      sendCustomerDetail={sendCustomerDetail}
      handlePsychicNoteScreens={handlePsychicNoteScreens}
      totalNotificationSent={notificationStatistics.totalNotificationSent}
      isVisible={isAllNotesScreen}
      isMobile={isMobile}
      isGamifyEnabled={isGamifyEnabled}
    />
    <StatsViewContainer
      notificationStatistics={notificationStatistics}
      sendCustomerDetail={sendCustomerDetail}
      handlePsychicNoteScreens={handlePsychicNoteScreens}
      isMobile={isMobile}
      isGamifyEnabled={isGamifyEnabled}
      isAllNotesTab={isAllNotesScreen}
    />
    { children && children?.find((element) => element.key === activeScreen) }
    <ComposeNotificationButtonForMobile
      className={styles.composeButton}
      sendCustomerDetail={sendCustomerDetail}
      handlePsychicNoteScreens={handlePsychicNoteScreens}
      isBottomCTAVisible={isBottomCTAVisible}
    />
  </div>
);

export default PsychicNotifications;
