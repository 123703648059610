import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import TimerContainer from 'components/Timer/TimerContainer';
import SendMessageButton from 'components/Buttons/SendMessageButton';
import ChatInput from 'components/Inputs/ChatInput';
import {
  DESKTOP_PLATFORM, ENTER_KEY,
  LIVE_MESSAGE_LIMIT,
  MAX_RETRY_VALUE, MIN_OFFLINE_CHARACTERS_AMOUNT,
  MIN_TIME_CHAT_IS_ACTIVE,
  OFFLINE_MESSAGE_LIMIT,
} from 'constants/constants';
import { ConversationType, PopUpNotificationType } from 'constants/enums';
import { importAndRetryChunk } from 'src/utils/commonUtil';
import { selectIsChatInInitState, selectUserAgent } from 'selectors/selectors';
import {
  hideEmojiPicker as _hideEmojiPicker,
  setPopUpNotificationData as _setPopUpNotificationData,
  showPopUpNotification as _showPopUpNotification,
} from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';

import styles from './MessageForm.module.scss';

const MessageForm = ({
  isCustomerView,
  isButtonDisabled,
  onSendMessage,
  fakeInputRef,
  area,
  currentChat,
  handleKeyPress,
  handleKeyDown,
  areaValue,
  setAreaValue,
  onChange,
}) => {
  const isOfflineChat = currentChat.attributes?.requestType === ConversationType.DIRECT_MESSAGE;

  const userAgent = useSelector(selectUserAgent);
  const isChatInInitState = useSelector(selectIsChatInInitState);

  const UAPlatform = useMemo(() => userAgent?.getPlatformType(), [userAgent]);
  const maxLength = useMemo(() => (isOfflineChat ? OFFLINE_MESSAGE_LIMIT : LIVE_MESSAGE_LIMIT),
    [isOfflineChat]);

  const [limitCounter, setLimitCounter] = useState<number>(maxLength);
  const [EmojiContainer, setEmojiContainer] = useState<React.FC<any>>();

  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const hideEmojiPicker = useBindDispatch(_hideEmojiPicker);

  const selectionStartRef = useRef<number>(0);

  const formStyles = cn(
    styles.containerForm,
    isCustomerView ? styles.containerFormCustomer : styles.containerFormPsychic,
  );

  const isDesktop = UAPlatform === DESKTOP_PLATFORM;

  useEffect(() => {
    setLimitCounter(isOfflineChat ? areaValue.length : LIVE_MESSAGE_LIMIT - areaValue.length);
  }, [areaValue, isOfflineChat]);

  useEffect(() => {
    (async () => {
      if (!EmojiContainer) {
        const { default: loaded } = await importAndRetryChunk(
          () => import('components/Emoji/EmojiContainer'),
          MAX_RETRY_VALUE,
          'Emoji loading failed',
        );
        setEmojiContainer(loaded);
      }
    })();
  }, [EmojiContainer]);

  const onSendMessageHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (currentChat.attributes?.requestType === ConversationType.DIRECT_MESSAGE) {
      if (limitCounter < MIN_OFFLINE_CHARACTERS_AMOUNT && limitCounter > 0) {
        showPopUpNotification(true);
        setPopUpNotificationData({
          title: `${'That’s a good start! Please include a little bit more for a '
          + 'minimum of 140 characters, including spaces. The customer '
          + 'is paying for a '}${MIN_TIME_CHAT_IS_ACTIVE} minute response.`,
          notificationType: PopUpNotificationType.LACK_OF_CHARACTERS,
        });
      } else if (limitCounter <= OFFLINE_MESSAGE_LIMIT
        && limitCounter >= MIN_OFFLINE_CHARACTERS_AMOUNT) {
        showPopUpNotification(true);
        setPopUpNotificationData({
          title: 'Are you sure you want to send this Message?',
          notificationType: PopUpNotificationType.SEND_MESSAGE_CONFIRMATION,
          callback: { func: onSendMessage },
        });
      }
    } else {
      // @ts-ignore
      onSendMessage();
    }
  };

  const focusTextAreaOnClick = useCallback((event: React.MouseEvent<any>) => {
    if (UAPlatform !== DESKTOP_PLATFORM) {
      if (event.currentTarget !== event.target) {
        hideEmojiPicker(true);
        area.current?.focus();
      }
    }
  }, [UAPlatform]);

  const handleTextareaFocusOnClick = useCallback((event: React.MouseEvent<any>) => {
    event.currentTarget.blur();
    event.currentTarget.focus();

    hideEmojiPicker(true);
  }, []);

  const keyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === ENTER_KEY) {
      onSendMessageHandler(e);
    } else {
      handleKeyDown(e);
    }
  };

  const handleSelect = useCallback((): void => {
    selectionStartRef.current = area.current!.selectionStart;
  }, []);

  const setEmoji = useCallback(async (emoji: any) => {
    const selectionStart = selectionStartRef.current;
    const output = `${areaValue.substr(0, selectionStart)}${emoji.native}${areaValue.substr(selectionStart)}`;
    const elem = area.current as HTMLTextAreaElement;
    const selectionToMakeAfterValueUpdate = area.current!.selectionStart + emoji.native.length;
    setAreaValue(output);
    await elem.focus();
    elem.setSelectionRange(selectionToMakeAfterValueUpdate, selectionToMakeAfterValueUpdate);
    selectionStartRef.current = selectionToMakeAfterValueUpdate;
  }, [areaValue]);

  const buttonColor = isCustomerView ? '#F18536' : '#0099a7';
  const sendButtonColor = (isChatInInitState && isCustomerView) ? '#B9BBBA' : buttonColor;

  return (
    // eslint-disable-next-line
    <form className={formStyles} onClick={focusTextAreaOnClick}>
      {isCustomerView && <TimerContainer />}
      <ChatInput
        fakeInputRef={fakeInputRef}
        area={area}
        onSelect={handleSelect}
        maxLength={maxLength}
        onChange={onChange}
        handleKeyPress={handleKeyPress}
        onKeyDown={keyDownHandler}
        value={areaValue}
        friendlyName={currentChat?.sideUser?.friendlyName}
        onFocusTextarea={handleTextareaFocusOnClick}
        onFocus={focusTextAreaOnClick}
        isOfflineChat={isOfflineChat}
        limitCounter={limitCounter}
        UAPlatform={UAPlatform}
        disabled={isChatInInitState && isCustomerView}
      />
      {isDesktop && EmojiContainer && !(isChatInInitState && isCustomerView) && (
        <EmojiContainer
          setEmoji={setEmoji}
          textareaRef={area}
        />
      )}
      <SendMessageButton
        className={isCustomerView
          ? styles.containerFormCustomerSendButton
          : styles.containerFormPsychicSendButton}
        onClick={onSendMessageHandler}
        disabled={isButtonDisabled}
        sendButtonColor={sendButtonColor}
      />
    </form>
  );
};

export default MessageForm;
