import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PsychicView from 'components/Views/PsychicView';
import {
  PopUpNotificationType,
  SdkEvents,
  SdkNotificationMode,
} from 'constants/enums';
import { IS_SWITCHED_CONVERSATIONS } from 'constants/constants';
import { setStorageItem } from 'src/utils/storageHandler';
import {
  setPopUpNotificationData as _setPopUpNotificationData,
  showPopUpNotification as _showPopUpNotification,
  handlePsychicMissChat as _handlePsychicMissChat,
} from 'actions/appActions';
import { updatePsychicStatus as _updatePsychicStatus } from 'actions/statusToggleActions';
import {
  initApplication as _initApplication,
  setCurrentChannelSid as _setCurrentChannelSid,
  setCurrentPsychicPhoneStatus as _setCurrentPsychicPhoneStatus,
  setCurrentPsychicStatus as _setCurrentPsychicStatus,
} from 'actions/chatActions';
import { IAppState } from 'store';
import {
  useMissChatRequest,
  useRestoreNotification,
} from 'src/hooks/notification.hook';
import {
  setConsoleErrorLogs as _setConsoleErrorLogs,
} from 'actions/diagnosticAction';
import { usePsychicStatuses } from 'src/hooks/firestore.hook';
import {
  selectAuthToken,
  selectCommand,
  selectCurrentChat,
  selectExtId,
  selectIsLiveChatActive,
  selectIsLiveChatCanceled,
  selectIsSwitchedConversations,
  selectNotifications,
  selectSdkCallbackStorage,
  selectSdkConfiguration,
  selectTwilioToken,
} from 'selectors/selectors';
import { selectConsoleErrorLogs } from 'selectors/diagnosticSelector';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { selectIsNotificationActive } from 'selectors/psychicNotificationsSelectors';

const PsychicViewContainer = () => {
  useSelector((state: IAppState) => state.chat.currentChannelSid);
  useSelector(selectAuthToken);

  const setCurrentPsychicPhoneStatus = useBindDispatch(_setCurrentPsychicPhoneStatus);
  const setCurrentChannelSid = useBindDispatch(_setCurrentChannelSid);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const initApplication = useBindDispatch(_initApplication);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const handlePsychicMissChat = useBindDispatch(_handlePsychicMissChat);
  const setCurrentPsychicStatus = useBindDispatch(_setCurrentPsychicStatus);
  const updatePsychicStatus = useBindDispatch(_updatePsychicStatus);
  const setConsoleErrorLogs = useBindDispatch(_setConsoleErrorLogs);

  const sdkConfiguration = useSelector(selectSdkConfiguration);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const command = useSelector(selectCommand);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isLiveChatCanceled = useSelector(selectIsLiveChatCanceled);
  const token = useSelector(selectTwilioToken);
  const extId = useSelector(selectExtId);
  const notifications = useSelector(selectNotifications);
  const currentChat = useSelector(selectCurrentChat);
  const isVisibleNotification = useSelector((state: IAppState) => state.app.isNotificationPopUp);
  const isNotificationActive = useSelector(selectIsNotificationActive);
  const consoleErrorLogs = useSelector(selectConsoleErrorLogs);

  useEffect(() => {
    if (token && extId) initApplication(token);
  }, [token, initApplication, extId]);

  useRestoreNotification(isVisibleNotification);

  useEffect(() => {
    setStorageItem(IS_SWITCHED_CONVERSATIONS, isSwitchedConversations);
  }, [isSwitchedConversations]);

  const onEndChat = (flag: boolean) => {
    showPopUpNotification(flag);
    setPopUpNotificationData({
      title: `Are you sure you want to end this Сhat with ${currentChat.sideUser?.friendlyName}?`,
      notificationType: PopUpNotificationType.END_CHAT_REQUEST,
    });
  };

  useMissChatRequest((attributes: any, index: number) => {
    handlePsychicMissChat(attributes, index);
  }, notifications);

  useEffect(() => {
    if (command === SdkEvents.ON_CHAT_EVENT) {
      const onIgnoreChatConfirmation = sdkCallbackStorage.get(SdkEvents.IGNORE_CHAT_CONFIRM);

      if (onIgnoreChatConfirmation) {
        // @ts-ignore
        const notifications = sdkConfiguration?.presentationMode?.notifications;
        onIgnoreChatConfirmation();
        const isNoModeOrCustomMode = !notifications || notifications === SdkNotificationMode.CUSTOM;

        if (isNoModeOrCustomMode) showPopUpNotification(false);

        sdkCallbackStorage.delete(SdkEvents.IGNORE_CHAT_CONFIRM);
      }
    }
  }, [command, sdkCallbackStorage, sdkConfiguration]);

  // eslint-disable-next-line no-console
  console.error = function (...args) {
    const errorLogs = [
      ...consoleErrorLogs,
      Array.from(args),
    ];
    setConsoleErrorLogs(errorLogs);
  };

  usePsychicStatuses(
    setCurrentPsychicStatus,
    setCurrentPsychicPhoneStatus,
    extId as string,
    updatePsychicStatus,
  );

  return (
    <PsychicView
      onEndChat={onEndChat}
      isLiveChatActive={isLiveChatActive}
      isLiveChatCanceled={isLiveChatCanceled}
      currentChat={currentChat}
      setCurrentChannelSid={setCurrentChannelSid}
      isSwitchedConversations={isSwitchedConversations}
      isNotificationActive={isNotificationActive}
    />
  );
};

export default PsychicViewContainer;
