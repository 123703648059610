import {
  put,
  call,
  select,
} from 'redux-saga/effects';

import {
  View,
  ConversationType,
  ErrorRequestType,
  Status,
} from 'constants/enums';
import { MESSAGES_LOAD_SIZE, PLATFORM_CHAT_COMMUNICATION } from 'constants/constants';
import {
  emitErrorNotification,
  setRequestErrorState,
  setIsLiveChatActive,
} from 'actions/appActions';
import {
  setMessages,
  sendMessage,
  loadExtraMessages,
  setMessagesBefore,
} from 'actions/chatActions';
import {
  selectView,
  selectChatId,
  selectCurrentChat,
  selectChannel,
  selectCurrentChannelSid,
  selectTwilioClient,
} from 'selectors/selectors';
import { TwilioChannel } from 'types/objectTypes';
import {
  removeCurrentOfflineChatHandler,
  setMessagesOfflineHandler,
  removeOfflineChatHandler,
  setMessagesBeforeOfflineHandler,
} from 'actions/offlineChatActions';
import { API } from 'src/utils/api';
import { handleEndChatForCustomer } from 'src/redux/sagas/chat/commonSaga';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* loadMessagesWorker(
  channel: TwilioChannel,
  requestType: ConversationType,
  chatId?: string,
) {
  try {
    const messagesItems = yield call(
      // @ts-ignore
      [channel, channel?.getMessages],
      MESSAGES_LOAD_SIZE,
    );
    const messages = yield messagesItems.items.map((message) => message.state);
    const lastMessage = messages?.length > 0 && messages[messages.length - 1];
    const participantType = lastMessage?.attributes?.participantType?.toLowerCase();
    const lastMessageChatId = lastMessage?.attributes?.chatId;

    if (chatId) {
      if (participantType === View.PSYCHIC.toLowerCase() && lastMessageChatId === chatId) {
        yield put(removeOfflineChatHandler(chatId));
      } else {
        yield put(setMessagesOfflineHandler([...messages], chatId));

        return messages;
      }
    } else {
      if (requestType === ConversationType.DIRECT_MESSAGE) {
        if (participantType === View.PSYCHIC.toLowerCase()) {
          const chatId = yield select(selectChatId);
          yield put(removeCurrentOfflineChatHandler(chatId));
        } else {
          yield put(setMessages([...messages]));

          return messages;
        }
      }

      if (requestType === ConversationType.LIVE_CHAT) {
        yield put(setMessages([...messages]));
      }
    }
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    yield put(emitErrorNotification());
  }
}

export function* loadExtraMessagesWorker({
  payload: currentMessagesCount,
}: ReturnType<typeof loadExtraMessages>) {
  try {
    const currentChat: any = yield select(selectCurrentChat);
    const view: View = yield select(selectView);
    const messagesCount = yield currentChat?.currentChannel.getMessagesCount();

    if (messagesCount > currentMessagesCount) {
      const firstMessageIndex = currentChat?.messages[0]?.index;

      if (firstMessageIndex === 0) return;

      const messagesItems = yield currentChat?.currentChannel
        .getMessages(MESSAGES_LOAD_SIZE, firstMessageIndex);
      const messages = messagesItems.items.map((message) => message.state);

      if (currentChat?.attributes?.requestType === ConversationType.LIVE_CHAT
        || view === View.CUSTOMER) {
        yield put(setMessagesBefore([...messages]));
      } else {
        yield put(setMessagesBeforeOfflineHandler([...messages], currentChat.chatId));
      }
    }
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
  }
}

function* handleWebhookErrorForMessages(e: any) {
  const chatId = yield select(selectChatId);
  const view = yield select(selectView);
  const isWebhookError = e?.message === 'Webhook cancelled processing of command';

  if (isWebhookError && chatId) {
    const { data } = yield call(API.Chat.getChatByIdLight, chatId) || {};
    const status = data?.chat?.status;

    if (status === Status.COMPLETED) {
      if (view === View.CUSTOMER) {
        yield call(handleEndChatForCustomer);
      }

      if (view === View.PSYCHIC) {
        yield call(setIsLiveChatActive, false);
      }
    }

    return true;
  }

  if (isWebhookError) {
    return true;
  }

  return false;
}

export function* sendMessageRequest({
  payload: { message },
}: ReturnType<typeof sendMessage>) {
  try {
    const view = yield select(selectView);
    const chatId = yield select(selectChatId);
    let channel = yield select(selectChannel);
    const messageAttributes = message.attributes;

    if (!channel) {
      const sid = yield select(selectCurrentChannelSid);
      const client = yield select(selectTwilioClient);
      channel = yield client.getConversationBySid(sid);
    }

    yield call(
      [channel, channel.sendMessage],
      message.body,
      {
        author: message.author,
        messageType: messageAttributes?.messageType,
        participantType: messageAttributes?.participantType || view,
        platform: PLATFORM_CHAT_COMMUNICATION,
        requestType: messageAttributes?.requestType,
        chatId,
      },
    );
  } catch (e) {
    const isWebhookError = yield call(handleWebhookErrorForMessages, e);

    if (isWebhookError) return;

    console.log(e);

    yield call(SentryMethods.captureException, e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
  }
}
