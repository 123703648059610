import React from 'react';

import Illustration from 'src/assets/png/Illustration.png';
import { IConfirmationScreenPopup } from 'types/componentTypes';
import ReturnToNotificationsButton from 'components/Buttons/ReturnToNotificationsButton';
import ComposeNewButton from 'components/Buttons/ComposeNewButton';
import styles from 'components/Popups/ConfirmationScreenPopup/ConfirmationScreenPopup.module.scss';

const ConfirmationScreenPopup: React.FC<IConfirmationScreenPopup> = ({
  customerName,
  handleComposeNewButton,
  handleReturnButton,
}) => (
  <div className={styles.wrapper}>
    <div>
      <div className={styles.textWrapper}>
        <span className={styles.notificationText}>Notification Sent!</span>
        <span className={styles.customerName}>
          {`To: ${customerName}`}
        </span>
      </div>
      <img
        className={styles.img}
        src={Illustration}
        alt="img"
      />
    </div>
    <div className={styles.buttonsWrapper}>
      <ReturnToNotificationsButton onClick={handleReturnButton} />
      <ComposeNewButton onClick={handleComposeNewButton} />
    </div>
  </div>
);

export default ConfirmationScreenPopup;
