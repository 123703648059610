import React from 'react';
import cn from 'classnames';
import { Button, TableCell, TableRow } from '@mui/material';

import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import { formatToUSDateOnly } from 'src/utils/dateHandler';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
} from 'actions/psychicNotifications';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { PRIORITY_ACTION_CHARACTER_LIMIT, PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import HighlightLabel from 'components/Labels/HighlightedLabel';
import { truncateString } from 'src/utils/commonUtil';
import { IPriorityActionList } from 'types/componentTypes';

import styles from './PriorityActionList.module.scss';

const PriorityActionList: React.FC<IPriorityActionList> = ({
  action,
  isMobile,
  rowIndex,
  handlePsychicNoteScreens,
}) => {
  const {
    readingType,
    clientType,
    readingDateUTC,
    customerDisplayName,
    pCComID,
    actionItem,
  } = action;
  const readingDate = formatToUSDateOnly(readingDateUTC);
  const client = {
    ...action,
    lastReadingDate: readingDateUTC,
    displayname: customerDisplayName,
    customerType: clientType,
  };
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  const handleOnClick = (e) => {
    getClientNoteHistory(pCComID);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail([client]);
  };

  if (isMobile) {
    return (
      <TableRow>
        <div className={cn(styles.listWrapper, { [styles.oddBackground]: rowIndex % 2 !== 0 })}>
          <div className={styles.list}>
            <div className={styles.listHead}>
              <ClientTypeLable clientType={clientType} />
              <span className={styles.lastReading}>{`${readingType}: ${readingDate}`}</span>
            </div>
            <HighlightLabel
              text={truncateString(actionItem, PRIORITY_ACTION_CHARACTER_LIMIT)}
              wordToBold={customerDisplayName}
            />
          </div>
          <Button
            className={styles.ctaButton}
            onClick={handleOnClick}
            id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
          >
            Send Note
          </Button>
        </div>
      </TableRow>
    );
  }

  return (
    <TableRow className={styles.tableRowPr}>
      <TableCell align="center">
        <div className={styles.customerLable}>
          <ClientTypeLable clientType={clientType} />
        </div>
      </TableCell>
      <TableCell align="right" className={styles.reading}>
        {`${readingType}: ${readingDate}`}
      </TableCell>
      <TableCell className={styles.actionItem}>
        <HighlightLabel
          text={truncateString(actionItem, PRIORITY_ACTION_CHARACTER_LIMIT)}
          wordToBold={customerDisplayName}
        />
      </TableCell>
      <TableCell>
        <Button
          className={styles.ctaButton}
          id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
          onClick={handleOnClick}
        >
          Send Note
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PriorityActionList;
