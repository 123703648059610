/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  TableRow,
  TableCell,
} from '@mui/material';

import style from './style.module.scss';

const CustomerList = ({ rows, updateRowsData }) => (
  <>
    {rows.map((row, index) => (
      <TableRow key={row.customerPosition}>
        <TableCell align="center" className={style.userDetails}>
          <input
            type="checkbox"
            id={`checkbox_${index}`}
            checked={row.screened}
            onChange={() => updateRowsData(index)}
            className={style.selectCaller}
          />
          {row.customerPosition}
          <label htmlFor={`checkbox_${index}`} />
        </TableCell>

        <TableCell align="center" className={style.userDetails}>
          {row.customerName}
        </TableCell>

        <TableCell align="center" className={style.userDetails}>
          {row.minimumTalkTime} mins
        </TableCell>

        <TableCell align="center" className={style.userDetails}>
          {row.identifiers}
        </TableCell>
      </TableRow>
    ))}
  </>
);

export default CustomerList;
