import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';

import { deepEqual } from 'src/utils/helpers';
import NotesList from 'components/PsychicNotifications/ClientNotes/NotesList';
import styles from 'components/PsychicNotifications/ClientNotes/ClientNotes.module.scss';

const ClientNotesHistory = ({
  notesHistoryList,
  isClientBlocked,
  handleNewUnreadMessage,
  handleOnSelect,
  isTextBoxVisible,
  isPsychicView,
  isCTAButtonVisible,
}) => {
  const [prevLastMessage, setPrevLastMessage] = useState<any>(null);
  const [prevClientHeight, setPrevClintHeight] = useState<number>(0);

  const messageContainer = useRef<HTMLDivElement>(null);
  const scrollableMessageWrapper = useRef<HTMLDivElement>(null);

  const isBottomIgnoreLastElementSize = (messageDiv: HTMLDivElement) => {
    const messageContainer = messageDiv.firstChild as HTMLDivElement;
    const lastMessageElement = messageContainer?.lastElementChild! as HTMLDivElement;
    const lastMessageElementHeight = lastMessageElement.offsetHeight;
    const styles = window.getComputedStyle(lastMessageElement);
    const lastMessageElementMargin = parseFloat(styles.marginTop)
      + parseFloat(styles.marginBottom);
    // eslint-disable-next-line prefer-destructuring
    const clientHeight = messageDiv.clientHeight;
    const lastElementHeight = Math.ceil(lastMessageElementHeight + lastMessageElementMargin);
    const computedClientHeight = Math.ceil(messageDiv.scrollHeight)
      - Math.floor(messageDiv.scrollTop)
      - Math.floor(lastElementHeight);
    const clientHeightDifference = computedClientHeight - clientHeight;
    const isNotSignificantDifference = clientHeightDifference < 10;

    return isNotSignificantDifference;
  };

  const scrollOnMessagesSendOrReceive = useCallback((messagesRef: HTMLDivElement) => {
    const isBottomNow = messagesRef.scrollHeight - messagesRef.scrollTop
      === messagesRef.clientHeight;
    const lastMessage = notesHistoryList[notesHistoryList.length - 1];

    if (deepEqual(lastMessage, prevLastMessage)) return;

    setPrevLastMessage(lastMessage);

    const isTopNow = messagesRef.scrollTop === 0;

    if (!isBottomNow && !isTopNow) {
      const isBottom = isBottomIgnoreLastElementSize(messagesRef);

      if (isBottom) {
        // eslint-disable-next-line no-param-reassign
        messagesRef.scrollTop = messagesRef.scrollHeight;

        return;
      }

      return;
    }

    // eslint-disable-next-line no-param-reassign
    messagesRef.scrollTop = messagesRef.scrollHeight;

    if (messagesRef.clientHeight !== prevClientHeight) {
      setPrevClintHeight(messagesRef.clientHeight);
    }
  }, [notesHistoryList, prevLastMessage]);

  useEffect(() => {
    const messagesRef = scrollableMessageWrapper.current;

    if (messagesRef && notesHistoryList?.length > 0) {
      scrollOnMessagesSendOrReceive(messagesRef);
    }
  }, [
    notesHistoryList,
    messageContainer,
    scrollableMessageWrapper,
    scrollOnMessagesSendOrReceive,
  ]);

  const wrapperClasses = cn(
    styles.clientNoteHistoryWrapper,
    {
      [styles.psychicNoteHistory]: isPsychicView,
      [styles.withClientBlocked]: !!isClientBlocked,
      [styles.isTextBox]: !isTextBoxVisible,
      [styles.isOneMessage]: (isClientBlocked === 2) && !isPsychicView,
      [styles.withClientBlockedPsychic]: isPsychicView && !!isClientBlocked,
      [styles.withCTAButtons]: isCTAButtonVisible,
    },
  );

  return (
    <div ref={scrollableMessageWrapper} className={wrapperClasses}>
      <div ref={messageContainer} className={styles.clientNoteHistoryContainer}>
        <Box className={styles.internalStack}>
          <NotesList
            notesHistoryList={notesHistoryList}
            handleNewUnreadMessage={handleNewUnreadMessage}
            handleOnSelect={handleOnSelect}
          />
        </Box>
      </div>
    </div>
  );
};

export default ClientNotesHistory;
