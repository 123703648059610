import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
  useTable,
  getComparator,
  applySortFilter,
} from 'src/hooks/useTable';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { IListingViewContainer } from 'types/componentTypes';
import { PNOrderType, PNTabs } from 'src/constants/enums';
import { PNCustomerListType, PNNotificationListType } from 'types/objectTypes';
import {
  setCurrentPCNTab as _setCurrentPCNTab,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import { tableUtil, getSeletctedTabData } from 'src/utils/listingViewUtils';
// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
// import { LISTING_VIEW_TABLE_HEAD } from 'constants/constants';
// import { selectCurrentUser } from 'selectors/selectors';
import { useIsMobile } from 'src/hooks/useIsMobile';
import ListingView from 'components/PsychicNotifications/ListingView/ListingView';
import NotificationList from 'components/PsychicNotifications/AllNotes';
import CustomerList from 'components/PsychicNotifications/CustomerList';
import { selectCurrentPCNTab } from 'selectors/psychicNotificationsSelectors';

import PriorityActionList from '../PriorityActionList';

const ListingViewContainer: React.FC<IListingViewContainer> = ({
  notificationList,
  customerList,
  priorityActionList,
  handlePsychicNoteScreens,
}) => {
  const { isMobile } = useIsMobile();

  const currentTab = useSelector(selectCurrentPCNTab);

  const setCurrentPCNTab = useBindDispatch(_setCurrentPCNTab);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  const [filterCustomerName, setFilterCustomerName] = useState<string>('');
  const [searchTag, setSearchTag] = useState<boolean>(false);
  const [labelTags, setLabelTags] = useState<{ [key: number]: string[] }>({});

  const isNotificationTab = useMemo(() => currentTab === PNTabs.ALL_NOTES_TAB, [currentTab]);
  const {
    defaultOrderBy,
    currentTabData,
    rowCount,
    defaultRowsPerPage,
  } = getSeletctedTabData(currentTab, {
    notificationList,
    customerList,
    priorityActionList,
  });

  const {
    page,
    order,
    orderBy,
    showMuted,
    rowsPerPage,
    setPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    setOrder,
    setOrderBy,
    onChangeMuted,
    onChangePage,
    onRemoveAllSelectedRows,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy,
    defaultRowsPerPage,
  });

  useEffect(() => {
    setOrder(PNOrderType.DESCENDING);
    setOrderBy(defaultOrderBy);
  }, [defaultOrderBy]);

  useEffect(() => {
    if (customerList && customerList.length > 0) {
      const initialLabelTags = customerList.reduce((acc, customer) => {
        const labels = customer?.clientLabels ? customer.clientLabels.split(',') : {};
        acc[customer.customerId] = labels;

        return acc;
      }, {});
      setLabelTags(initialLabelTags);
    }
  }, [customerList]);

  const handleFilterName = (filterName: string) => {
    setFilterCustomerName(filterName);
    setPage(0);
  };

  const tableData = applySortFilter({
    tableData: currentTabData,
    comparator: getComparator(order, orderBy),
    filterCustomerName,
    showMuted,
  });

  const onDataSort = (columnId: string) => {
    // const customerListHeader = isMobile ? LISTING_VIEW_TABLE_HEAD.CUSTOMER_LIST_MOBILE
    //   : LISTING_VIEW_TABLE_HEAD.CUSTOMER_LIST;
    // const tableHead = isNotificationTab
    //   ? LISTING_VIEW_TABLE_HEAD.NOTIFICATION_LIST : customerListHeader;
    // const sortedColumn = tableHead.find((col) => col.columnId === columnId);

    // if (sortedColumn) {
    //   addPsychicMixpanelEventButtonTapped({
    //     button_text: sortedColumn.label.toLowerCase(),
    //     button_type: 'column sort',
    //     screen_title: 'psychic notifications',
    //     platform: 'psychic desktop app',
    //     ...getPsychicNotificationInfoMixpanel(currentUser),
    //   });
    // }

    onSort(columnId);
  };

  const onSelectAllRowsHandler = (checked: boolean) => {
    const id = isNotificationTab ? 'pCComHistoryId' : 'customerId';

    return onSelectAllRows(
      checked, currentTabData.map((row) => row[id]),
    );
  };

  const updateLabelTag = (customerId, newLabelTag) => {
    setLabelTags((prevTags) => ({
      ...prevTags,
      [customerId]: newLabelTag,
    }));
  };

  const getClientLabelTag = (customer) => {
    const labels = labelTags[customer.customerId];

    if (!labels?.length) {
      return [];
    }

    return labelTags[customer.customerId];
  };

  const getTableBody = (currentTableBody) => {
    switch (currentTab) {
      case PNTabs.ALL_NOTES_TAB:
        return currentTableBody.map((notification: PNNotificationListType, index) => (
          <NotificationList
            rowIndex={index}
            isMobile={isMobile}
            notification={notification}
            selectedLength={selected.length}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
            isSelected={selected.includes(notification.pCComID)}
            onSelectRowHandler={onSelectRow}
          />
        ));
      case PNTabs.PRIORITY_ACTION_TAB:
        return currentTableBody.map((action, index) => (
          <PriorityActionList
            action={action}
            isMobile={isMobile}
            rowIndex={index}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
          />
        ));
      default:
        return currentTableBody.map((customer: PNCustomerListType, index) => (
          <CustomerList
            isMobile={isMobile}
            customer={customer}
            selectedLength={selected}
            index={index}
            labelTag={getClientLabelTag(customer)}
            updateLabelTag={updateLabelTag}
            isSelected={selected.includes(customer.customerId)}
            onSelectRowHandler={onSelectRow}
          />
        ));
    }
  };

  const {
    tableHead,
    tableBody,
    tableRowsCount,
  } = useMemo(() => {
    const {
      tableHead,
      currentTableBody,
    } = tableUtil(currentTab, isMobile, tableData, { page, rowsPerPage });
    const tableBody = getTableBody(currentTableBody);

    return {
      tableHead,
      tableBody,
      tableRowsCount: currentTableBody?.length,
    };
  }, [isNotificationTab, tableData]);

  return (
    <ListingView
      currentTab={currentTab}
      onChangeTabs={setCurrentPCNTab}
      isNotificationTab={isNotificationTab}
      showMuted={showMuted}
      onChangeMuted={onChangeMuted}
      tableHead={tableHead}
      tableBody={tableBody}
      listingData={tableData}
      totalRowsCount={rowCount}
      filterCustomerName={filterCustomerName}
      searchTag={searchTag}
      setSearchTag={setSearchTag}
      handleFilterName={handleFilterName}
      page={page}
      order={order}
      orderBy={orderBy}
      onSort={onDataSort}
      selected={selected}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      onChangePage={onChangePage}
      onRemoveAllSelectedRows={onRemoveAllSelectedRows}
      onSelectAllRowsHandler={onSelectAllRowsHandler}
      setNotificationCustomerDetail={setNotificationCustomerDetail}
      onChangeRowsPerPage={onChangeRowsPerPage}
      tableRowsCount={tableRowsCount}
    />
  );
};

export default ListingViewContainer;
