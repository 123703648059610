import {
  CUSTOMER_ID_STORAGE,
  PSYCHIC_ID_STORAGE,
  TOKEN_AUTH_STORAGE,
} from 'extracted-chat-components/constants';
import { View } from 'extracted-chat-components/enums';

import { setAuthToken as _setAuthToken } from 'actions/authActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { SdkConfiguration } from 'types/objectTypes';
import { setCustomerId as _setCustomerId, setExtId as _setExtId } from 'actions/chatActions';

export const updateUserData = (
  {
    setAuthToken,
    setCustomerId,
    setExtId,
  }: {
    setAuthToken: typeof _setAuthToken;
    setCustomerId: typeof _setCustomerId;
    setExtId: typeof _setExtId;
  },
  data?: SdkConfiguration['settings'],
) => {
  if (!data) {
    return;
  }

  const { token, userId } = data;

  if (token) {
    LocalStorage.setItem(TOKEN_AUTH_STORAGE, token);
    setAuthToken(token);
  }

  if (!userId) {
    return;
  }

  if (data.presentationMode?.view.toLowerCase() === View.CUSTOMER.toLowerCase()) {
    LocalStorage.setItem(CUSTOMER_ID_STORAGE, userId);
    setCustomerId(userId);
  } else {
    LocalStorage.setItem(PSYCHIC_ID_STORAGE, userId);
    setExtId(userId);
  }
};
