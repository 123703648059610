import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { ConversationType } from 'extracted-chat-components/enums';

import {
  selectCurrentChat,
  selectIsSwitchedConversations,
  selectOfflineChats,
} from 'selectors/selectors';
import ChatItemTitle from 'components/Titles/ChatItemTitle';
import ChatItem from 'components/Chats/ChatItem';
import Plain from 'components/Plain';
import SettingsPopover from 'components/Popovers/SettingPopover';

import style from './styles.module.scss';

const MobileIntegrationChat = () => {
  const scrollElement = useRef(null);

  const currentChat = useSelector(selectCurrentChat);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const offlineChats = useSelector(selectOfflineChats);

  if (!isSwitchedConversations) {
    return (
      <div
        ref={scrollElement}
        className={style.wrapper}
      >
        <div className={style.chatItems}>
          <ChatItemTitle isLiveChat />
          <ChatItem
            chat={currentChat}
            type={ConversationType.LIVE_CHAT}
          />
        </div>
        <div className={style.chatItems}>
          <ChatItemTitle isLiveChat={false} />
          {offlineChats?.length ? offlineChats.map((chat) => (
            <ChatItem
              key={chat.chatId}
              chat={chat}
              currentChatId={currentChat.chatId}
              type={ConversationType.DIRECT_MESSAGE}
            />
          )) : (
            <ChatItem
              type={ConversationType.DIRECT_MESSAGE}
            />
          )}
        </div>
        <SettingsPopover scrollElement={scrollElement} />
      </div>
    );
  }

  return (
    <Plain requestType={currentChat?.attributes?.requestType} />
  );
};

export default MobileIntegrationChat;
