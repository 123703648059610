import {
  ReducerAction,
} from 'types/objectTypes';
import * as types from 'actions/actionsTypes';

const initialState = {
  isPopupOpen: false,
  options: {
    isAlertByPDAEnabled: true,
    isAlertByPRTAppEnabled: true,
    isAlertByEmailEnabled: true,
    isAlertBySMSEnabled: true,
    isDefaultReminderEnabled: true,
    SMSAlertPhoneNumber: '',
  },
};

const reminderOptionsReducer = (
  state = initialState,
  { type, payload }: ReducerAction,
) => {
  switch (type) {
    case types.SET_VISIBLE_REMINDER_SETTINGS_POPUP: {
      return { ...state, isPopupOpen: payload };
    }
    case types.SET_REMINDER_CONFIGS: {
      return { ...state, options: payload };
    }
    case types.SET_SMS_ALERT_PHONE_NUMBER: {
      return { ...state, options: { ...state.options, SMSAlertPhoneNumber: payload } };
    }
    default:
      return state;
  }
};

export default reminderOptionsReducer;
