import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';

import { View } from 'constants/enums';
import { 
  setExtId as _setExtId, 
  setCurrentChat as _setCurrentChat, 
  setChatChannelAndLoadMessages as _setChatChannelAndLoadMessages,
} from 'actions/chatActions';
import { setHistoryModeState as _setHistoryModeState } from 'actions/appActions';
import SelectPsychicForm from 'src/components/Forms/SelectPsychicForm/SelectPsychicForm';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { PSYCHIC_ID_STORAGE, REDIRECT_PATH, CHAT_ID } from 'constants/constants';
import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';
import { checkCustomerCompletedExpiredChat } from 'src/utils/commonUtil';
import {
  selectAuthToken,
  selectCustomerId,
  selectIsLoading,
  selectView,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';

const authPsychic = '5376';
const SelectPsychicFormContainer = () => {
  const setExtId = useBindDispatch(_setExtId);
  const setCurrentChat = useBindDispatch(_setCurrentChat);
  const setHistoryModeState = useBindDispatch(_setHistoryModeState);
  const setChatChannelAndLoadMessages = useBindDispatch(_setChatChannelAndLoadMessages);

  const customerRefIdEnc = useSelector(selectCustomerId);
  useSelector(selectIsLoading);
  const token = useSelector(selectAuthToken);
  const viewMode = useSelector(selectView);

  const [id, setId] = useState(authPsychic);
  const [selectValue, setSelectValue] = useState<string>(authPsychic);
  const [isDisabled, setDisabledState] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSelect, setSelectState] = useState<boolean>(true);
  const [isError, setErrorState] = useState<boolean>(false);
  const [isVisible, showLoader] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    LocalStorage.removeItem(REDIRECT_PATH);
  }, []);

  const handleSelect = (e) => {
    setSelectValue(e.target.value);
  };

  const handleInput = (e) => {
    setDisabledState(!e.target.value);
    setInputValue(e.target.value);
  };

  const handleRadioButton = (e) => {
    setSelectState(e.target.id === 'selectPsychic');
  };

  useEffect(() => {
    if (isSelect) {
      setId(selectValue);
      setDisabledState(false);
    }

    if (!isSelect && !inputValue) {
      setDisabledState(true);
    }

    if (!isSelect && inputValue) {
      setId(inputValue);
    }
  }, [isSelect, inputValue, selectValue]);

  const handleSubmitButton = async () => {
    try {
      const { search } = location;
      const params = qs.parse(search);
      const view = params.view as string || viewMode;

      showLoader(true);
      await API.Psychic.getPsychic(id);

      LocalStorage.setItem(PSYCHIC_ID_STORAGE, id);
      setExtId(id);

      if (view && view === View.CUSTOMER.toLowerCase()) {
        setHistoryModeState(false);
        goTo('/conversations?view=customer');
      } else {
        goTo('/login?view=customer');
      }
    } catch (e) {
      console.log(e);
      SentryMethods.captureException(e);
      setErrorState(true);
      setTimeout(setErrorState.bind(null, false), 3000);
    } finally {
      showLoader(false);
    }
  };

  const handleHistoryButton = async () => {
    try {
      const { search } = location;
      const params = qs.parse(search);
      const view = params.view as string || viewMode;

      await API.Psychic.getPsychic(id);

      LocalStorage.setItem(PSYCHIC_ID_STORAGE, id);
      setExtId(id);

      if (view && view === View.CUSTOMER.toLowerCase()) {
        setHistoryModeState(true);
        const chat = await checkCustomerCompletedExpiredChat(
          customerRefIdEnc as string,
          id as string,
        );

        if (!chat?.chatId || !chat?.status) {
          LocalStorage.removeItem(CHAT_ID);
        } else {
          setCurrentChat(chat);
          setChatChannelAndLoadMessages(chat.chatChannelSid);
        }

        goTo('/conversations?view=customer');
      } else {
        goTo('/login?view=customer');
      }
    } catch (e) {
      console.log(e);
      SentryMethods.captureException(e);
      setErrorState(true);
      setTimeout(setErrorState.bind(null, false), 3000);
    } finally {
      showLoader(false);
    }
  };

  return (
    <SelectPsychicForm
      token={token}
      isError={isError}
      isLoading={isVisible}
      isSelect={isSelect}
      inputValue={inputValue}
      isDisabled={isDisabled}
      handleInput={handleInput}
      handleSubmitButton={handleSubmitButton}
      handleSelect={handleSelect}
      handleRadioButton={handleRadioButton}
      handleHistoryButton={handleHistoryButton}
    />
  );
};

export default SelectPsychicFormContainer;
