import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsBackdrop } from 'selectors/selectors';

import style from './styles.module.scss';

const BackdropContainer = () => {
  const isBackdrop = useSelector(selectIsBackdrop);

  const [isVisible, setIsVisible] = useState<boolean>(isBackdrop);

  useEffect(() => {
    setIsVisible(isBackdrop);
  }, [isBackdrop]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={style.backdropWrapper} />
  );
};

export default BackdropContainer;
