import * as types from 'actions/actionsTypes';
import { ChatInitMessages, Status } from 'constants/enums';
import {
  ChatDataType,
  ReducerAction,
} from 'types/objectTypes';
import { filterByExistingChat } from 'src/utils/helpers';

const initialState: ChatDataType = {
  customerId: '',
  extId: '',
  encryptedPassword: '',
  currentChatId: '',
  // @ts-ignore
  currentUser: {},
  appointment: {
    customerName: null,
    appointmentTimestamp: null,
  },
  countryCodes: [],
  customerBalance: '',
  fundsToAdd: '',
  chatActiveMinutes: 0,
  currentPsychicStatus: Status.CONNECTING,
  currentPsychicPhoneStatus: '',
  isWaitingPhoneStatus: false,
  typingMessage: '',
  currentChat: {
    attributes: {
      requestType: null,
    },
    messages: [],
    currentChannel: {},
    sideUser: null,
  },
  notifications: [],
  offlineChats: [],
  systemChannel: {},
  client: {},
  schedules: {
    schedule: {},
    totalTimeInMinute: '',
  },
  callBackQueueData: [],
  isAcceptChat: false,
  initChatNotification: ChatInitMessages.RESERVING_PSYCHIC,
  isInitChatBanner: false,
  isChatEnded: false,
  isShowAutoReloadHeader: false,
};

const channelReducer = (
  state = initialState,
  { type, payload }: ReducerAction,
) => {
  switch (type) {
    case types.SET_CURRENT_CHANNEL_SID:
      return { ...state, currentChannelSid: payload };
    case types.CHAT_FETCH_SUCCEEDED: {
      return { ...state, currentChat: { ...state.currentChat, ...payload } };
    }
    case types.SET_OFFLINE_CURRENT_CHAT: {
      return { ...state, currentChat: payload };
    }
    case types.SYSTEM_CHANNEL_SUCCEEDED: {
      return { ...state, systemChannel: payload };
    }
    case types.JOIN_CHANNEL_SUCCESS: {
      return {
        ...state,
        currentChat: { ...state.currentChat, currentChannel: payload },
      };
    }
    case types.ADD_PARTICIPANT_MESSAGE: {
      const messages = state.currentChat.messages || [];

      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: [...messages, payload],
        },
      };
    }
    case types.UPDATE_PARTICIPANT_MESSAGE: {
      return {
        ...state,
        currentChat: payload.currentChat,
      };
    }
    case types.OPEN_OFFLINE_CHAT:
    case types.SET_MESSAGES_OFFLINE:
    case types.SET_USER_CANCEL_OFFLINE_CHAT:
    case types.ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND:
    case types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND: {
      return {
        ...state,
        offlineChats: payload.offlineChats,
      };
    }
    case types.CLOSE_OFFLINE_CHAT:
    case types.OPEN_CURRENT_OFFLINE_CHAT:
    case types.SET_MESSAGES_BEFORE_OFFLINE:
    case types.SET_USER_CANCEL_CURRENT_OFFLINE_CHAT:
    case types.SET_IS_OFFLINE_CHAT_START:
    case types.ADD_PARTICIPANT_OFFLINE_MESSAGE:
    case types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE: {
      return {
        ...state,
        currentChat: payload.currentChat,
        offlineChats: payload.offlineChats,
      };
    }
    case types.ADD_SYSTEM_NOTIFICATION: {
      return { ...state, notifications: [...state.notifications, payload] };
    }
    case types.ADD_SYSTEM_NOTIFICATIONS: {
      return { ...state, notifications: [...state.notifications, ...payload] };
    }
    case types.SET_CUSTOMER_ID: {
      return { ...state, customerId: payload };
    }
    case types.SET_EXT_ID: {
      return { ...state, extId: payload };
    }
    case types.SET_ENCRYPTED_PASSWORD: {
      return { ...state, encryptedPassword: payload };
    }
    case types.SET_SIDE_USER: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          sideUser: payload,
        },
      };
    }
    case types.SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
      };
    }
    case types.SET_CHAT_ID: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          chatId: payload,
        },
      };
    }
    case types.SET_MESSAGES: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: [...payload],
        },
      };
    }
    case types.SET_MESSAGES_BEFORE: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: [...payload, ...state.currentChat.messages],
        },
      };
    }
    case types.DELETE_NOTIFICATIONS: {
      return { ...state, notifications: [] };
    }
    case types.DELETE_CURRENT_CHANNEL: {
      return { ...state, currentChat: { ...state.currentChat, currentChannel: {} } };
    }
    case types.DELETE_CUSTOMER_ID: {
      return { ...state, customerId: '' };
    }
    case types.DELETE_SIDE_USER: {
      return {
        ...state,
        currentChat: {
          sideUser: null,
        },
      };
    }
    case types.SET_CHANNEL_SID: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          chatChannelSid: payload,
        },
      };
    }
    case types.SET_TWILIO_CLIENT: {
      return { ...state, client: payload };
    }
    case types.DELETE_CURRENT_CHAT: {
      return {
        ...state,
        appointment: {
          customerName: null,
          appointmentTimestamp: null,
        },
        currentChat: {
          messages: [],
          currentChannel: {},
          sideUser: null,
        },
      };
    }
    case types.SET_CURRENT_PSYCHIC_STATUS: {
      return { ...state, currentPsychicStatus: payload };
    }
    case types.SET_TYPING_STARTED_MESSAGE:
      return { ...state, typingMessage: payload };
    case types.REMOVE_CHAT_ID: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          chatId: '',
        },
      };
    }
    case types.DELETE_TWILIO_USER: {
      return {
        ...state,
        client: null,
      };
    }
    case types.CLEAR_EXT_ID: {
      return { ...state, extId: '' };
    }
    case types.SET_CHAT_MINUTES_ACTIVE: {
      return { ...state, chatActiveMinutes: payload };
    }
    case types.ADD_OFFLINE_CHAT: {
      return { ...state, offlineChats: [...state.offlineChats, payload] };
    }
    case types.SET_OFFLINE_CHATS: {
      return {
        ...state,
        offlineChats: [...filterByExistingChat(state.offlineChats, payload), ...payload],
      };
    }
    case types.REMOVE_CURRENT_OFFLINE_CHAT: {
      return {
        ...state,
        currentChat: initialState.currentChat,
        offlineChats: payload.offlineChats,
      };
    }
    case types.REMOVE_OFFLINE_CHAT: {
      return {
        ...state,
        offlineChats: payload.offlineChats,
      };
    }
    case types.SET_CURRENT_CHAT_ID: {
      return { ...state, currentChatId: payload };
    }
    case types.SET_CHAT_STATUS: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          status: payload,
        },
      };
    }
    case types.CHANGE_MAX_DURATION: {
      return { ...state, currentChat: { ...state.currentChat, maxDuration: payload } };
    }
    case types.CLEAR_CHAT: {
      return initialState;
    }
    case types.SET_CURRENT_PSYCHIC_PHONE_STATUS: {
      return { ...state, currentPsychicPhoneStatus: payload };
    }
    case types.REMOVE_PREV_CHAT_DATE: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          ...{
            chatId: '',
            startedDate: '',
            acceptedDate: '',
          },
        },
      };
    }
    case types.SET_FUNDS: {
      return { ...state, fundsToAdd: payload };
    }
    case types.SET_CUSTOMER_BALANCE: {
      return { ...state, customerBalance: payload };
    }
    case types.SET_APPOINTMENT: {
      return { ...state, appointment: payload };
    }
    case types.SET_PHONE_STATUS_WAITING_STATE: {
      return { ...state, isWaitingPhoneStatus: payload };
    }
    case types.SET_COUNTRY_CODES: {
      return { ...state, countryCodes: payload };
    }
    case types.SET_IS_ACCEPT_CHAT: {
      return { ...state, isAcceptChat: payload };
    }
    case types.SET_PSYCHIC_SCHEDULES: {
      return { ...state, schedules: payload };
    }
    case types.SET_PSYCHIC_CALLBACK_QUEUE: {
      return { ...state, callBackQueueData: payload };
    }
    case types.SET_INIT_CHAT_NOTIFICATION: {
      return { ...state, initChatNotification: payload };
    }
    case types.SET_INIT_CHAT_BANNER: {
      return { ...state, isInitChatBanner: payload };
    }
    case types.SET_END_CHAT_DUE_TO_LOW_FUND: {
      return { ...state, isChatEnded: payload };
    }
    case types.SHOW_AUTO_RELOAD_HEADER: {
      return { ...state, isShowAutoReloadHeader: payload };
    }
    case types.SET_CUSTOMER_ENABLE_AUTO_RECHARGE: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          enableAutoRecharge: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default channelReducer;
