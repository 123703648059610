import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import EmptyStateBanner from 'components/Banners/EmptyStateBanner/index';
import {
  IS_LOAD_MORE_BOTTON_CLICKED_KEY,
  IS_PRIORITY_TOOLTIP_SEEN,
  PRIORITY_ACTION_COMPLETED_KEY,
  PriorityActionLabel,
} from 'constants/constants';
import { getCookie, simplyfyJsonArrayKeys } from 'src/utils/commonUtil';
import TooltipContent from 'components/PsychicNotifications/PriorityActionWidget/TooltipContent';
import { IListFooterForPriorityAction } from 'types/componentTypes';
import { selectIsLoading } from 'selectors/selectors';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';
import { selectPriorityActionMessages } from 'selectors/psychicNotificationsSelectors';

const ListFooterForPriorityAction: React.FC<IListFooterForPriorityAction> = ({
  count,
  tableRowsCount,
  onChangeRowsPerPage,
}) => {
  const isTooltipSeen = sessionStorage.getItem(IS_PRIORITY_TOOLTIP_SEEN);
  const [isTooltipVisible, setIsTooltipVisible] = useState(!isTooltipSeen);
  const isLoading = useSelector(selectIsLoading);
  const isPsychicFinishedList = getCookie(PRIORITY_ACTION_COMPLETED_KEY);
  const priorityActionMessages = useSelector(selectPriorityActionMessages);
  const isLoadMoreVisible = sessionStorage.getItem(IS_LOAD_MORE_BOTTON_CLICKED_KEY);
  const {
    priorityClientsActionTooltipText2,
    priorityClientsActionTooltipText3,
    priorityClientsActionTooltipText4,
    priorityClientsActionLoadMoreCtaText,
  } = simplyfyJsonArrayKeys(priorityActionMessages);

  useEffect(() => {
    if (isTooltipVisible && !isLoading && !count) {
      setTimeout(() => {
        setIsTooltipVisible(false);
        sessionStorage.setItem(IS_PRIORITY_TOOLTIP_SEEN, 'true');
      }, 3000);
    }
  }, [count, isLoading, isTooltipVisible]);

  useEffect(() => {
    if (isLoadMoreVisible) {
      onChangeRowsPerPage(count);
    }
  }, []);

  const {
    emoji,
    labelHead,
    labelText,
  } = useMemo(() => {
    if (!count) {
      if (isPsychicFinishedList) {
        return {
          emoji: '🎉',
          labelHead: 'Way to go! ',
          labelText: priorityClientsActionTooltipText2
          || PriorityActionLabel.DONE,
        };
      }

      return {
        emoji: '🎱',
        labelHead: '',
        labelText: priorityClientsActionTooltipText3
        || PriorityActionLabel.EMPTY,
      };
    }

    const labelText = count > 1
      ? priorityClientsActionTooltipText4?.replace('XX', count.toString())
      : priorityClientsActionTooltipText4?.replace('XX', count.toString()).replace('notes', 'note')
    || PriorityActionLabel.ALMOST_DONE.replace('XX', count.toString());

    return {
      emoji: '🙌',
      labelHead: '',
      labelText,
    };
  }, [count, isPsychicFinishedList]);

  const loadMoreCtaText = useMemo(() => (priorityClientsActionLoadMoreCtaText
    ? priorityClientsActionLoadMoreCtaText?.replace('XX', count.toString())
    : PriorityActionLabel.CTA.replace('XX', count.toString())),
  [count, priorityClientsActionLoadMoreCtaText]);

  const handleLoadMoreCTA = () => {
    onChangeRowsPerPage(count);
    sessionStorage.setItem(IS_LOAD_MORE_BOTTON_CLICKED_KEY, 'true');
  };

  const emptyStateClassName = !count && !isPsychicFinishedList ? {
    label: styles.label,
    emoji: styles.emoji,
  } : {
    label: styles.label2,
  };

  const renderCountLabel = () => !!count
  && (
    <span className={styles.bottomButtonCount}>
      {`Showing ${tableRowsCount} of ${count}`}
    </span>
  );

  const renderBottomLabel = () => count <= 5
  && (
    <EmptyStateBanner
      emoji={emoji}
      labelText={labelText}
      labelHead={labelHead}
      classNames={emptyStateClassName}
    />
  );

  const renderTooltip = () => (isTooltipVisible && !count) && (
    <TooltipContent className={styles.tooltip} />
  );

  return (
    <div className={cn(styles.bottomButtonWrapper, {
      [styles.bottomButtonWrapperEmpty]: !count && !isPsychicFinishedList,
      [styles.tooltipWrapper]: !count && isTooltipVisible,
    })}
    >
      {renderCountLabel()}
      {(tableRowsCount < count) && (
        <Button
          onClick={handleLoadMoreCTA}
          className={styles.bottomButton}
        >
          {loadMoreCtaText}
        </Button>
      )}
      {renderBottomLabel()}
      {renderTooltip()}
    </div>
  );
};

export default ListFooterForPriorityAction;
