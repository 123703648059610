import {
  OPEN_DIAGNOSTIC_PAGE,
  RUN_DIAGNOSTIC_PAGE,
  SET_CONSOLE_ERROR_LOGS,
} from 'src/redux/actions/actionsTypes';

export const setIsDiagnosticPopupOpen = (
  isPopupOpen: boolean,
) => ({
  type: OPEN_DIAGNOSTIC_PAGE,
  payload: isPopupOpen,
});

export const openDiagnosticsModel = () => ({
  type: RUN_DIAGNOSTIC_PAGE,
});

export const setConsoleErrorLogs = (
  errorLogs: any,
) => ({
  type: SET_CONSOLE_ERROR_LOGS,
  payload: errorLogs,
});
