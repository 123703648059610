import React from 'react';
import cn from 'classnames';
import {
  TableRow,
  Card,
  List,
  ListItemText,
} from '@mui/material';

import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import {
  formatForPsychicNote, formatToUSDateOnly,
} from 'src/utils/dateHandler';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
} from 'actions/psychicNotifications';
import { PNNotificationStatus } from 'src/constants/enums';
import RightArrow from 'src/assets/svg/arrow-right-icon.svg';
import { PSYCHIC_NOTE_TAB_ID, CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST } from 'constants/constants';
import IndicatorButton from 'components/Buttons/IndicatorButton';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { PNCustomerListType } from 'types/objectTypes';
import { getDraftedNote, truncateString } from 'src/utils/commonUtil';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import styles from 'components/PsychicNotifications/AllNotes/NotificationListForMobile.module.scss';

const NotificationListForMobile: React.FC<any> = ({
  rowIndex,
  notification,
  handlePsychicNoteScreens,
}) => {
  const {
    customerId,
    displayname,
    firstName,
    isRead,
    zodiacSign,
    lastname,
    messageBody,
    sentDateUTC,
    notificationStatus,
    zodiacSignId,
    lastReadingDateUTC,
    lastReadingType,
    notificationStatusId,
    pCComID,
    sendBy,
    isClientAccessToPsychicNotes,
    isPsychicNotificationsEnabled,
    isPsychicNotificationsEmailEnabled,
    customerType,
  } = notification;

  const customer: PNCustomerListType = {
    customerId,
    callrecId: 0,
    displayname,
    firstName,
    lastname,
    notificationStatus,
    readingType: 'Message',
    customerType,
    lastReadingDate: lastReadingDateUTC,
    zodiacSign,
    pCComID,
    zodiacSignId,
    notificationStatusId,
    customerTypeId: 0,
    isPsychicNotificationsEnabled,
    isPsychicNotificationsEmailEnabled,
    isClientAccessToPsychicNotes,
  };

  const isCustomerMuted = notificationStatus === PNNotificationStatus.MUTED;
  const zodiacImage = zodiacMap.get(zodiacSign.toLocaleLowerCase());
  const shortSentDate = formatForPsychicNote(sentDateUTC);
  const lastReadingDetails = `Last reading: ${lastReadingType} - ${formatToUSDateOnly(lastReadingDateUTC)}`;
  const isDraftSaved = !!getDraftedNote(customerId);

  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  const tableRowClasses = cn(
    styles.tableRow,
    {
      [styles.mutedRow]: isCustomerMuted,
    },
  );

  const handleOnClick = (e) => {
    getClientNoteHistory(pCComID);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail([customer]);
  };

  const primaryText = {
    color: 'black',
    fontSize: '13px',
    fontWeight: '500',
  };

  const secondaryText = {
    color: 'black',
    marginTop: '7px',
    fontSize: '12px',
    marfinRight: '7px',
  };

  return (
    <TableRow
      hover
      className={tableRowClasses}
      id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
      onClick={handleOnClick}
    >
      <div className={styles.cardBorder}>
        <Card className={cn(styles.rawContainer, { [styles.oddBackground]: rowIndex % 2 !== 0 })}>
          <List component="nav" aria-label="mailbox folders">
            <div className={styles.listHead}>
              <div className={styles.nameAndNew}>
                <div className={styles.customerNamesContainer}>
                  <span className={cn(styles.customerNames, {
                    [styles.mutedText]: isCustomerMuted,
                  })}
                  >
                    {
                      truncateString(
                        displayname,
                        CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST,
                      )
                    }
                  </span>
                  {isCustomerMuted && <span className={styles.mutedTag}>muted</span>}
                </div>
                <ClientTypeLable clientType={customerType} />
                {!isRead && (<IndicatorButton className={styles.newTagStyle} />)}
                {isDraftSaved && <span className={styles.draftTag}>Draft</span>}
              </div>
              <div className={styles.sentDateTimeWrapper}>
                <span className={cn(styles.sentDateTime, {
                  [styles.mutedText]: isCustomerMuted,
                })}
                >
                  {shortSentDate}
                </span>
                <img
                  alt="arrow"
                  src={RightArrow}
                  className={cn(styles.rightArrow, {
                    [styles.mutedText]: isCustomerMuted,
                  })}
                />
              </div>
            </div>
            <div className={styles.noteWrapper}>
              <div className={styles.zodiacIconWrapper}>
                <img src={zodiacImage} alt="zodiac" className={styles.zodiacIcon} />
              </div>
              <ListItemText
                className={cn(styles.fullMessageLine, styles.messageLine, {
                  [styles.mutedText]: isCustomerMuted,
                })}
                primary={lastReadingDetails}
                secondary={`${sendBy}: ${messageBody}`}
                primaryTypographyProps={{ style: isCustomerMuted ? { ...primaryText, color: 'grey' } : primaryText }}
                secondaryTypographyProps={{ style: isCustomerMuted ? { ...secondaryText, color: 'grey' } : secondaryText }}
              />
            </div>
          </List>
        </Card>
      </div>
    </TableRow>
  );
};

export default NotificationListForMobile;
