import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { View } from 'constants/enums';
import { selectView } from 'selectors/selectors';
import {
  DESKTOP_PLATFORM,
  LOW_CHARACTER_NOTIFICATION,
  OFFLINE_MESSAGE_LIMIT,
} from 'constants/constants';

import styles from './styles.module.scss';

const ChatInput = ({
  onChange,
  onKeyDown,
  onFocus,
  value,
  fakeInputRef,
  area,
  onSelect,
  maxLength,
  onFocusTextarea,
  handleKeyPress,
  friendlyName,
  isOfflineChat,
  limitCounter,
  UAPlatform,
  disabled,
}) => {
  const view = useSelector(selectView);
  const isCustomer = view === View.CUSTOMER;
  const isLimitValueOfflineChat = isOfflineChat
    && (limitCounter > 2490 || (limitCounter < 140 && limitCounter > 0));

  const onKeyPressHandler = (e) => {
    if (isCustomer) {
      handleKeyPress(e);
    }
  };

  const onChangeHandler = (e) => {
    onChange(e, maxLength);
  };

  const textareaWrapperStyles = cn(view === View.CUSTOMER
    ? styles.containerFormCustomerWrapper
    : styles.containerFormPsychicWrapper);

  const textareaStyles = cn(view === View.CUSTOMER
    ? styles.containerFormCustomerWrapperTextarea
    : styles.containerFormPsychicWrapperTextarea);

  const textareaStylesWithoutEmoji = cn(view === View.CUSTOMER
    ? styles.textareaWithoutEmojiCustomer
    : styles.textareaWithoutEmojiPsychic);

  const textareaClasses = cn(textareaStyles, UAPlatform !== DESKTOP_PLATFORM
    && textareaStylesWithoutEmoji);

  return (
    // eslint-disable-next-line
    <div
      className={textareaWrapperStyles}
      onClick={onFocus}
    >
      <input className={styles.fakeInput} ref={fakeInputRef} />
      <textarea
        id="chatInputField"
        spellCheck="true"
        className={textareaClasses}
        value={value}
        ref={area}
        onSelect={onSelect}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPressHandler}
        onClick={onFocusTextarea}
        disabled={disabled}
        rows={1}
        placeholder={`Send ${friendlyName} your message`}
      />
      {isOfflineChat ? (
        <p className={cn(styles.limitAlert, {
          [styles.limitAlertWarning]: isLimitValueOfflineChat,
        })}
        >
          {limitCounter}
          <div>
            /
            {OFFLINE_MESSAGE_LIMIT}
          </div>
        </p>
      ) : (
        <p className={cn(styles.limitAlert, {
          [styles.limitAlertCustomer]: view === View.CUSTOMER,
          [styles.limitAlertWarning]: limitCounter < LOW_CHARACTER_NOTIFICATION,
        })}
        >
          {limitCounter}
        </p>
      )}
    </div>
  );
};

export default ChatInput;
