/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { View } from 'constants/enums';
import { LoginFormProps } from 'types/componentTypes';
import ShowPasswordImg from 'src/assets/png/showPasswordImgBig.png';
import HidePasswordImg from 'src/assets/png/hidePasswordImgBig.png';
import { LOGIN_FIELDS_NAME } from 'constants/constants';

import styles from './LoginForm.module.scss';

const LoginForm: React.FC<LoginFormProps> = ({
  handleSubmitButton,
  handleInput,
  handleOnClickImg,
  selectDefault,
  isRedirect,
  isDev,
  view,
  isRequestError,
  isVisiblePassword,
}) => (
  <form className={styles.wrapper}>
    <div className={styles.container}>
      <input
        onChange={handleInput}
        name={LOGIN_FIELDS_NAME.EMAIL}
        type="text"
        className={styles.inputEmail}
        placeholder={view === View.CUSTOMER ? 'email' : 'User ID'}
      />
      <div className={styles.passwordContainer}>
        <input
          onChange={handleInput}
          name={LOGIN_FIELDS_NAME.PASSWORD}
          type={isVisiblePassword ? 'text' : 'password'}
          className={styles.inputPassword}
          placeholder="Password"
        />
        <img
          alt=""
          className={styles.visibilityPassword}
          onClick={handleOnClickImg}
          src={isVisiblePassword ? HidePasswordImg : ShowPasswordImg}
        />
      </div>
      {isRequestError && <p className={styles.alert}>User ID or password is incorrect</p>}
      {isDev && (
        <select
          value={selectDefault}
          name={LOGIN_FIELDS_NAME.VIEW}
          onChange={handleInput}
          className={styles.inputPsychicId}
        >
          <option value={View.CUSTOMER}>Log as customer</option>
          <option value={View.PSYCHIC}>Log as psychic</option>
        </select>
      )}
      <button
        type="button"
        disabled={!isRedirect}
        onClick={handleSubmitButton}
        className={styles.btnSubmit}
      >
        Log in
      </button>
    </div>
  </form>
);

export default LoginForm;
