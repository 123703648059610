import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import HeaderNavigation from 'components/Buttons/NavigationButton';
import InternetConnectionBanner from 'components/Banners/InternetConnectionBanner';
import { selectCurrentUser, selectIsPsychicNotificationsEnable } from 'selectors/selectors';
import { selectIsNotificationActive } from 'selectors/psychicNotificationsSelectors';
import useStickyHeader from 'src/hooks/useStickyHeader';
import BurgerButton from 'components/Buttons/BurgerButton';
import StatusBadge from 'components/Badges/StatusBadge';

import styles from './Header.module.scss';

const Header = ({
  onOpenSidebarMenu,
  isActive,
  isMobile,
  activePage,
  isMobileIntegration,
  onChangePage,
}) => {
  const [image, setImage] = useState<string>('');

  const currentUser = useSelector(selectCurrentUser);
  const isNotificationActive = useSelector(selectIsNotificationActive);
  const isPsychicNotificationsEnable = useSelector(selectIsPsychicNotificationsEnable);

  const headerRef = useRef<HTMLDivElement>(null);
  const isPsychicNotification = isNotificationActive && isPsychicNotificationsEnable;

  useStickyHeader(headerRef);

  useEffect(() => {
    const { images } = currentUser;

    if (images) {
      setImage(images[images.length - 1]);
    }
  }, [currentUser, setImage]);

  return (
    <div
      ref={headerRef}
      className={cn(styles.containerHeader, styles.containerHeaderPsychic)}
    >
      <div className={cn(styles.wrapper, {
        [styles.psychicNotification]: isPsychicNotification,
      })}
      >
        {!isMobileIntegration && <BurgerButton onClick={onOpenSidebarMenu} isActive={isActive} />}
        <div className={cn(styles.containerHeaderContent, {
          [styles.containerHeaderContentMobile]: isActive,
          [styles.containerHeaderContentSDKIntegration]: isMobileIntegration,
        })}
        >
          {isMobile && isActive && !isMobileIntegration && <StatusBadge />}
          <img
            src={image}
            alt="Psychic"
            className={cn(styles.containerHeaderAvatar, {
              [styles.containerHeaderAvatarHide]: !image,
              [styles.containerHeaderAvatarMobile]: (isActive && isMobile) || isMobileIntegration,
            })}
          />
          <HeaderNavigation onChangePage={onChangePage} />
        </div>
        <div className={cn(styles.containerHeaderLogo, {
          [styles.containerHeaderLogoMobile]: (isActive && isMobile) || isMobileIntegration,
        })}
        />
        {!isActive && !isMobileIntegration
          && <h3 className={styles.containerHeaderMobileTitle}>{activePage}</h3>}
      </div>
      <InternetConnectionBanner />
    </div>
  );
};

export default Header;
