import React from 'react';
import cn from 'classnames';

import SavedDraftButtonIcon from 'assets/svg/draft-save-icon.svg';
import styles from 'components/Buttons/ClientNotesDraftButton/ClientNotesDraftButton.module.scss';

const ClientNotesDraftButton = ({ onClick, isClicked = false }) => (

  <div className={styles.draftButtonWrapper}>
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.draftButton, {
        [styles.draftButtonClicked]: isClicked,
      })}
    >
      <span
        className={styles.draftButtonTitle}
      >
        {isClicked ? 'Draft Saved' : 'Save Draft'}
      </span>
      {isClicked && (
        <img
          src={SavedDraftButtonIcon}
          alt="draft"
          className={styles.draftButtonIcon}
        />
      )}
    </button>
  </div>
);
export default ClientNotesDraftButton;
