import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import InitChatPsychicPopup from 'components/Popups/InitChatPsychicPopup';
import {
  PAGE_ID,
  IS_MOBILE_INTEGRATION,
  PSYCHIC_NOTE_TAB_ID,
  MOBILE_PAGE_ID,
  DRAFTED_CLIENT_NAME_KEY,
} from 'constants/constants';
import LoaderContainer from 'components/Loader';
import Header from 'components/Header';
import SideBar from 'components/SideBar';
import Setting from 'components/Pages/Setting';
import ChatPage from 'components/Pages/ChatPage';
import ManageSchedule from 'components/Pages/ManageSchedule';
import DashboardPage from 'components/Pages/DashboardPage';
import PopUpNotificationContainer from 'components/Popups/PopUpNotification/PopUpNotificationContainer';
import PsychicMobileView from 'components/Views/PsychicMobileView/PsychicMobileView';
import {
  selectActiveMobilePage,
  selectCurrentChat,
  selectIsLiveChatActive,
  selectIsElectronApp,
  selectIsSwitchedConversations,
  selectSdkCallbackStorage,
  selectActivePage,
  selectIsShownIncomingRequest,
} from 'selectors/selectors';
import {
  selectClientNotesDraftToast,
  selectIsNotificationActive,
} from 'selectors/psychicNotificationsSelectors';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { LocalStorage } from 'src/utils/storageHandler';
import MobileIntegrationChat from 'components/Chats/MobileIntegrationChat';
import DiagnosticPopup from 'components/Popups/DiagnosticPopup';
import { isElectron } from 'src/utils/helpers';
// import { getPsychicNotificationInfoMixpanel} from 'src/utils/helpers';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setActivePage as _setActivePage,
  setConfirmationScreen as _setConfirmationScreen,
  setActiveMobilePage as _setActiveMobilePage,
} from 'actions/appActions';
import PsychicNotificationsContainer from 'containers/PsychicNotificationsContainer';
import {
  getPsychicAllNotificationsList as _getPsychicAllNotificationsList,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  setActiveScreen as _setActiveScreen,
  getPriorityActionCount as _getPriorityActionCount,
  setClientNotesDraftToast as _setClientNotesDraftToast,
} from 'src/redux/actions/psychicNotifications';
// import { addPsychicMixpanelEventScreenViewed } from 'src/utils/commonUtil';
import { deleteCookie, getCookie } from 'src/utils/commonUtil';

import style from './PsychicView.module.scss';

const PsychicView = () => {
  const [isOpenSidebarMenu, setIsOpenSidebarMenu] = useState(false);
  // PsychicEarlyLogin useState
  // const [psychicEarlyLogin, setPsychicEarlyLogin] = useState(false);
  // const [psychicEarlyLoginTimer, setPsychicEarlyLoginTimer] = useState(false);

  const { isMobile } = useIsMobile();

  const currentChat = useSelector(selectCurrentChat);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isElectronApp = useSelector(selectIsElectronApp);
  // const currentUser = useSelector(selectCurrentUser);
  const isNotificationActive = useSelector(selectIsNotificationActive);
  const activePage = useSelector(selectActivePage);
  const activeMobilePage = useSelector(selectActiveMobilePage);
  const isShownIncomingRequest = useSelector(selectIsShownIncomingRequest);
  const clientNotesDraftToast = useSelector(selectClientNotesDraftToast);

  const getPsychicAllNotificationsList = useBindDispatch(_getPsychicAllNotificationsList);
  const setActivePage = useBindDispatch(_setActivePage);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const setActiveScreen = useBindDispatch(_setActiveScreen);
  const setConfirmationScreen = useBindDispatch(_setConfirmationScreen);
  const getPriorityActionCount = useBindDispatch(_getPriorityActionCount);
  const setClientNotesDraftToast = useBindDispatch(_setClientNotesDraftToast);
  const setActiveMobilePage = useBindDispatch(_setActiveMobilePage);

  const isSdk = sdkCallbackStorage.size;
  const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

  // PsychicEarlyLogin useEffect
  // useEffect(() => {
  //   setTimeout(() => {
  //     setPsychicEarlyLoginTimer(true);
  //   }, 3000);
  // }, []);

  useEffect(() => {
    if (isLiveChatActive) {
      setActivePage(PAGE_ID.CHATS_PAGE);
    }
  }, [isLiveChatActive]);

  useEffect(() => {
    const draftedClientName = getCookie(DRAFTED_CLIENT_NAME_KEY);

    if (!!draftedClientName) {
      setClientNotesDraftToast(!!draftedClientName, draftedClientName);
    }

    if (!isShownIncomingRequest && clientNotesDraftToast.isToastVisible) {
      setClientNotesDraftToast(false);
      deleteCookie(DRAFTED_CLIENT_NAME_KEY);
    } else if (isShownIncomingRequest && isMobileIntegration) {
      setActiveMobilePage(MOBILE_PAGE_ID.CHATS_PAGE);
    }
  }, [isShownIncomingRequest]);

  const onOpenSidebarMenu = useCallback(() => {
    setIsOpenSidebarMenu(!isOpenSidebarMenu);
  }, [isOpenSidebarMenu]);

  const onChangePage = useCallback((key) => {
    if (!isLiveChatActive) {
      if (key === PAGE_ID.PSYCHIC_INBOX && isNotificationActive) {
        getPsychicAllNotificationsList();
        setActiveScreen(PSYCHIC_NOTE_TAB_ID.ALL_NOTE);
        setNotificationCustomerDetail(null);
        setConfirmationScreen(false);
        // addPsychicMixpanelEventScreenViewed({
        //   screen_title: 'psychic notifications',
        //   platform: 'psychic desktop app',
        //   ...getPsychicNotificationInfoMixpanel(currentUser),
        // });
      } else if (key === PAGE_ID.CHATS_PAGE) {
        getPriorityActionCount();
        setActivePage(key);
      } else {
        setActivePage(key);
      }
    }

    if (isMobile) {
      onOpenSidebarMenu();
    }
  }, [isLiveChatActive, isMobile, onOpenSidebarMenu, isNotificationActive]);

  const plainContainerStyleName = useMemo(() => (isElectron
    ? 'plainContainerFullElectron'
    : 'plainContainerFull'), []);

  const psychicViewStyles = useMemo((): string => {
    if (currentChat?.attributes && !currentChat.attributes.isClosed) {
      if (isSwitchedConversations) {
        if (isMobile && !isSdk && !isMobileIntegration) {
          return cn(style[plainContainerStyleName], style.switchedGridAreaMobile);
        }

        return cn(style[plainContainerStyleName], style.switchedGridArea);
      }

      return cn(style[plainContainerStyleName], style.defaultGridArea);
    }

    if (isSwitchedConversations) {
      return cn(style[plainContainerStyleName], style.noLiveChatGridAreaSwitched);
    }

    return cn(style[plainContainerStyleName], style.noLiveChatGridArea);
  }, [currentChat?.attributes, isSwitchedConversations, plainContainerStyleName]);

  return (
    <div className={style.wrapper}>
      <div className={cn({
        [style.container]: !(isSdk || isMobileIntegration),
        [style.wrapperLock]: isOpenSidebarMenu,
        [style.wrapperPcn]: isMobileIntegration
        && activeMobilePage === MOBILE_PAGE_ID.PSYCHIC_INBOX,
      }, (isSdk || isMobileIntegration || isMobile) && psychicViewStyles)}
      >
        <Header
          isMobileIntegration={(isSdk || isMobileIntegration) && !isElectronApp}
          onOpenSidebarMenu={onOpenSidebarMenu}
          isActive={isOpenSidebarMenu}
          activePage={activePage}
          isMobile={isMobile}
          onChangePage={onChangePage}
        />
        {(isSdk || isMobileIntegration) && !isElectronApp ? (
          <PsychicMobileView>
            <MobileIntegrationChat key={MOBILE_PAGE_ID.CHATS_PAGE} />
            <PsychicNotificationsContainer key={MOBILE_PAGE_ID.PSYCHIC_INBOX} />
          </PsychicMobileView>
        ) : (
          <SideBar
            isOpen={isOpenSidebarMenu}
            onChangePage={onChangePage}
            activePage={activePage}
          >
            <ChatPage key={PAGE_ID.CHATS_PAGE} />
            <PsychicNotificationsContainer key={PAGE_ID.PSYCHIC_INBOX} />
            <Setting key={PAGE_ID.SETTINGS_PAGE} />
            <ManageSchedule key={PAGE_ID.MANAGE_SCHEDULES} />
            <DashboardPage key={PAGE_ID.DASHBOARD_PAGE} />
          </SideBar>
        )}
        {/* Popups */}
        <LoaderContainer withBackground />
        <PopUpNotificationContainer />
        <InitChatPsychicPopup />
        <DiagnosticPopup />
      </div>
    </div>
  );
};

export default PsychicView;
