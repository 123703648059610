import React, { useEffect, useRef } from 'react';

import styles from './CustomScrollBar.module.scss';

const CustomScollBar = ({ contentContainerRef }) => {
  const customScrollRef = useRef<HTMLDivElement | null>(null);
  const customScrollThumbRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const contentContainer = contentContainerRef.current;
    const customScroll = customScrollRef.current;
    const customScrollThumb = customScrollThumbRef.current;

    if (!contentContainer || !customScroll || !customScrollThumb) {
      return;
    }

    const syncCustomScrollbar = () => {
      const scrollPercentage = contentContainer.scrollLeft
      / (contentContainer.scrollWidth - contentContainer.clientWidth);
      const thumbPosition = scrollPercentage * (customScroll.clientWidth
        - customScrollThumb.clientWidth);
      customScrollThumb.style.left = `${thumbPosition}px`;
    };

    const syncContentScroll = (deltaX) => {
      const thumbPercentage = deltaX / (customScroll.clientWidth - customScrollThumb.clientWidth);
      const scrollPosition = thumbPercentage * (contentContainer.scrollWidth
        - contentContainer.clientWidth);
      contentContainer.scrollLeft = scrollPosition;
    };

    const handleMouseDown = (event) => {
      event.preventDefault();
      const initialMouseX = event.clientX;
      const initialThumbLeft = customScrollThumb.offsetLeft;

      const onMouseMove = (moveEvent) => {
        const deltaX = moveEvent.clientX - initialMouseX;
        const newThumbLeft = Math.min(
          customScroll.clientWidth - customScrollThumb.clientWidth,
          Math.max(0, initialThumbLeft + deltaX),
        );
        customScrollThumb.style.left = `${newThumbLeft}px`;
        syncContentScroll(newThumbLeft);
      };

      const onMouseUp = () => {
        document?.removeEventListener('mousemove', onMouseMove);
        document?.removeEventListener('mouseup', onMouseUp);
      };

      document?.addEventListener('mousemove', onMouseMove);
      document?.addEventListener('mouseup', onMouseUp);
    };

    const handleTouchStart = (event) => {
      const initialTouchX = event.touches[0].clientX;
      const initialThumbLeft = customScrollThumb.offsetLeft;

      const onTouchMove = (moveEvent) => {
        const deltaX = moveEvent.touches[0].clientX - initialTouchX;
        const newThumbLeft = Math.min(
          customScroll.clientWidth - customScrollThumb.clientWidth,
          Math.max(0, initialThumbLeft + deltaX),
        );
        customScrollThumb.style.left = `${newThumbLeft}px`;
        syncContentScroll(newThumbLeft);
      };

      const onTouchEnd = () => {
        document?.removeEventListener('touchmove', onTouchMove);
        document?.removeEventListener('touchend', onTouchEnd);
      };

      document?.addEventListener('touchmove', onTouchMove);
      document?.addEventListener('touchend', onTouchEnd);
    };

    customScrollThumb?.addEventListener('mousedown', handleMouseDown);
    customScrollThumb?.addEventListener('touchstart', handleTouchStart);

    contentContainer?.addEventListener('scroll', syncCustomScrollbar);

    return () => {
      customScrollThumb?.removeEventListener('mousedown', handleMouseDown);
      customScrollThumb?.removeEventListener('touchstart', handleTouchStart);
      contentContainer?.removeEventListener('scroll', syncCustomScrollbar);
    };
  }, [contentContainerRef]);

  return (
    <div className={styles.customScroll} ref={customScrollRef}>
      <div className={styles.customScrollThumb} ref={customScrollThumbRef} />
    </div>
  );
};

export default CustomScollBar;
