import React from 'react';

import LoginForm from 'src/components/Forms/LoginForm';
import Logo from 'src/assets/png/CPLogo.png';
import { ILoginProps } from 'types/componentTypes';
import style from 'src/components/Login/Login.module.scss';

const Login: React.FC<ILoginProps> = ({
  isRedirect,
  selectDefault,
  isDev,
  view,
  isRequestError,
  isVisiblePassword,
  handleOnClickImg,
  handleSubmitButton,
  handleInput,
}) => (
  <div className={style.wrapper}>
    <div className={style.firstColumn}>
      <img alt="" src={Logo} />
    </div>
    <div className={style.secondColumn}>
      <div className={style.container}>
        <p className={style.welcomeText}>Welcome back</p>
        <h3 className={style.loginTitle}>Login to your account</h3>
        <LoginForm
          isRedirect={isRedirect}
          selectDefault={selectDefault}
          isDev={isDev}
          view={view}
          isRequestError={isRequestError}
          isVisiblePassword={isVisiblePassword}
          handleOnClickImg={handleOnClickImg}
          handleSubmitButton={handleSubmitButton}
          handleInput={handleInput}
        />
        <div className={style.supportInfo}>
          <p className={style.helpText}>
            If you experience any issues logging into this app,
            please contact our Advisor Services Admin Department
            at
            {' '}
            <b>1.866.292.5801</b>
            {' '}
            or by email at
            {' '}
            <a href="mailto:pm@californiapsychics.com">pm@californiapsychics.com</a>
            .
            Call
            {' '}
            <b>1.866.400.7227</b>
            {' '}
            to login by phone.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Login;
