import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MixpanelEvents } from 'extracted-chat-components/enums';

import { useLowFundsHandler } from 'src/hooks/useLowFundsHandler';
import {
  selectCurrentChat,
  selectIsLiveChatActive,
  selectSideUser,
  selectUserAgent,
} from 'selectors/selectors';
import { messages } from 'src/services/messages';
import style from 'src/components/Notifications/InlineNotification/LowFunds/LowFunds.module.scss';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setLoadingState as _setLoadingState,
  setPopUpNotificationData as _setPopUpNotificationData,
  showPopUpNotification as _showPopUpNotification,
  setInlineNotification as _setInlineNotification,
} from 'actions/appActions';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  getGA4Properties,
  getPlatform,
  kountSessionIDHandler,
  removeInlineNotificationFromLocalStorage,
} from 'src/utils/commonUtil';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';
import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';
import { FUNDS_TO_ADD } from 'constants/constants';
import MixpanelActions from 'src/utils/mixpanel';
import { handlePaymentError } from 'src/utils/payments';
import { useTimer } from 'src/hooks/useTimer';

const sendAddFundsMixpanelEventCTATapped = (psychicInfo) => {
  MixpanelActions.track(MixpanelEvents.CTA_TAPPED, {
    cta_content: 'add $x to continue',
    cta_location: 'chat window',
    cta_position: 'add funds inline banner',
    cta_type: 'button',
    ...psychicInfo,
  });
};

const sendAddFundsMixpanelEventFundDeposited = (paymentDetail) => {
  MixpanelActions.track(MixpanelEvents.FUNDS_DEPOSITED_FE, {
    added_amount: paymentDetail.addedFunds,
    karma_rewards_points: paymentDetail.karmaPoints,
    new_amount: paymentDetail.availableBalance,
    payment: paymentDetail.payment,
    payment_type: paymentDetail.paymentMethod,
    promo_applied: paymentDetail.promoCode ? paymentDetail.promoCode : '',
    payment_frequency: 'insufficient funds',
  });
};

const LowFunds = ({ funds }) => {
  const fundsToAddForCall = LocalStorage.getItem(FUNDS_TO_ADD) || funds;

  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const sideUser = useSelector(selectSideUser);
  const currentChat = useSelector(selectCurrentChat);
  const userAgent = useSelector(selectUserAgent);

  const psychicInfo = useMemo(() => getPsychicInfoMixpanel(sideUser), [sideUser]);

  const setLoadingState = useBindDispatch(_setLoadingState);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const setInlineNotification = useBindDispatch(_setInlineNotification);

  const [
    isVisible,
    setVisibilityState,
  ] = useLowFundsHandler(isLiveChatActive, setInlineNotification);
  const time = useTimer(isLiveChatActive);

  const isVisibleLowFunds = isVisible ? style.container : style.hidden;
  const { title, description } = messages.lowFounds(fundsToAddForCall, time);

  const handleAddFundsButton = async () => {
    try {
      setLoadingState(true);
      const kountSessionID = await kountSessionIDHandler();
      sendAddFundsMixpanelEventCTATapped(psychicInfo);

      const sourcePlatform = getPlatform(userAgent);
      const addFundsPayload = {
        sourcePlatform,
        fundsToAdd: fundsToAddForCall as string,
        KountSessionID: kountSessionID,
        GA4Parameters: getGA4Properties(),
      };
      const result = await API.Chat.addFunds(currentChat?.chatId, addFundsPayload);
      const paymentDetail = result?.data?.paymentDetail;
      sendAddFundsMixpanelEventFundDeposited(paymentDetail);
    } catch (e) {
      SentryMethods.captureException(e);
      handlePaymentError(e, (data) => {
        setPopUpNotificationData(data);
        showPopUpNotification(true);
      });
    } finally {
      removeInlineNotificationFromLocalStorage();
      setInlineNotification('', null);
      setVisibilityState(false);
      setLoadingState(false);
    }
  };

  return (
    <div className={isVisibleLowFunds}>
      <div className={style.wrapper}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.description}>{description}</p>
        <button
          type="button"
          onClick={handleAddFundsButton}
          className={style.buttonAddFunds}
        >
          {`Add $${fundsToAddForCall} to Continue`}
        </button>
      </div>
    </div>
  );
};

export default memo(LowFunds);
