import React from 'react';
import { List, ListItem } from '@mui/material';
import { useSelector } from 'react-redux';

import ListDetails from 'components/Selects/CustomSelect/ListDetails';
import { PNNotificationStatus } from 'constants/enums';
import { ICustomSelect } from 'src/types/componentTypes';
import { selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import { isSelectedCustomer } from 'src/utils/commonUtil';
import styles from 'components/Selects/CustomSelect/CustomSelect.module.scss';

const CustomSelect: React.FC<ICustomSelect> = ({
  isSelectVisible,
  filterCustomer,
  options,
  onSelect,
}) => {
  const sendCustomerDetail = useSelector(selectSendCustomerDetail);

  if (!isSelectVisible && !filterCustomer) {
    return null;
  }

  if (!options.length) {
    return (
      <div className={styles.main}>
        <div className={styles.emptyState}>
          <span className={styles.emptyStateText}>
            Not Found
          </span>
          <span className={styles.emptyStateMessage}>
            No result found for
            <span>{` "${filterCustomer}". `}</span>
            Try checking for typos or using complete words.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <List>
        {options.map((customer) => (
          <ListItem
            key={customer.customerId}
            className={styles.listItem}
            onClick={() => onSelect(customer)}
          >
            <ListDetails
              customer={customer}
              isSelected={isSelectedCustomer(sendCustomerDetail, customer) || false}
              isMuted={customer.notificationStatus === PNNotificationStatus.MUTED}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default CustomSelect;
