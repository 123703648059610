import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveMobilePage } from 'selectors/selectors';

const PsychicMobileView = ({ children }) => {
  const activeMobilePage = useSelector(selectActiveMobilePage);

  return (
    <>
      { children.find((element) => element.key === activeMobilePage)}
    </>
  );
};

export default PsychicMobileView;
