import {
  put,
  call,
  take,
  select,
  putResolve,
  all,
} from 'redux-saga/effects';

import {
  SdkEvents,
  View,
  ConversationType,
  ErrorRequestType,
  PopUpNotificationType,
} from 'constants/enums';
import * as types from 'actions/actionsTypes';
import { API } from 'src/utils/api';
import { descriptionFromError } from 'src/utils/commonUtil';
import {
  setIsLiveChatActive,
  setLoadingState,
  setIsShownIncomingRequest,
  setIsSwitchedConversations,
  setSdkVisibilityMode,
  handleRequestError,
  showPopUpNotification,
  setRequestErrorState,
  setPopUpNotificationData,
} from 'actions/appActions';
import * as chatActions from 'actions/chatActions';
import {
  selectCustomerId,
  selectSdkCallbackStorage,
  selectIsModeForShowingModals,
  selectChannel,
  selectCurrentChat,
  selectIsAcceptChat,
  selectCurrentUser,
  selectView,
} from 'selectors/selectors';
import { checkTwilioTokenLifetime } from 'src/redux/sagas/chat/commonSaga';
import { handleCreateChatRequest, sendMessage, setChatMinutesActive } from 'actions/chatActions';
import { loadMessagesWorker } from 'src/redux/sagas/chat/messagesSaga';
import { handleOfflineMessageSending } from 'actions/offlineChatActions';
import { checkPhoneOrEmailInMessage } from 'src/utils/messageHandler';
import { getChatMessage } from 'src/utils/getChatMessage';
import { onSendMessage } from 'src/sdk/service/sdk.service';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import { SentryMethods } from 'src/utils/sentryMethods';

function* getCustomer(id) {
  const sideUser = yield call(API.Customer.getCustomerById, String(id));
  const user = sideUser?.data;
  user.attributes = { participantType: View.CUSTOMER };
  user.friendlyName = user?.firstName;

  return user;
}

function* handleSdkCallbacks(acceptResponse) {
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

  const acceptCallback = sdkCallbackStorage.get(SdkEvents.ACCEPT_CHAT);

  if (acceptCallback) {
    yield call(acceptCallback, { data: acceptResponse.data.chat, name: SdkEvents.ACCEPT_CHAT });
    sdkCallbackStorage.delete(SdkEvents.ACCEPT_CHAT);
    yield put(setSdkVisibilityMode(''));
  }

  const onAcceptCallback = sdkCallbackStorage.get(SdkEvents.ON_ACCEPT_CHAT);

  if (onAcceptCallback) {
    yield call(onAcceptCallback, {
      data: acceptResponse.data.chat,
      name: SdkEvents.ON_ACCEPT_CHAT,
    });
  }
}

function* handleMuteChimeSdk(acceptResponse) {
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
  const muteChatRequestCallback = sdkCallbackStorage.get(SdkEvents.ON_MUTE_CHAT_REQUEST);

  if (muteChatRequestCallback) {
    yield call(muteChatRequestCallback, {
      data: acceptResponse.data,
      name: SdkEvents.ON_MUTE_CHAT_REQUEST,
    });
  }
}

function* handleSdkError(reason) {
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
  const onErrorCallback = sdkCallbackStorage.get(SdkEvents.ON_ERROR);

  if (onErrorCallback) {
    onErrorCallback({ name: SdkEvents.ON_ERROR, data: reason });
    sdkCallbackStorage.delete(SdkEvents.ON_ERROR);
    yield put(setSdkVisibilityMode(''));
  }
}

export function* acceptChatRequestWorker({
  payload,
}: ReturnType<typeof chatActions.acceptCustomerChatRequest>) {
  const {
    chatId,
    timePassed = 0,
    platform,
    alreadyAccepted = false,
  } = payload || {};

  const isNoModeOrCustomMode: boolean = yield select(selectIsModeForShowingModals);
  try {
    yield put(setLoadingState(true));

    if (alreadyAccepted) yield putResolve(setIsShownIncomingRequest(false));

    let customerId = yield select(selectCustomerId);
    let chat;
    let acceptResponse;

    if (!customerId) {
      chat = yield call(API.Chat.getChatByIdLight, chatId);
      const { customerRefIdEnc } = chat.data.chat;
      customerId = customerRefIdEnc;
    }

    yield put(chatActions.setIsAcceptChat(true));

    if (alreadyAccepted) {
      acceptResponse = yield call(API.Chat.getChatById, chatId);
    } else {
      acceptResponse = yield call(API.Chat.acceptChatRequest, chatId, platform, timePassed);
    }

    const user = yield call(getCustomer, customerId);
    yield call(checkTwilioTokenLifetime);

    const { chatId: currentChatId } = yield select(selectCurrentChat);

    if (!currentChatId) {
      return;
    }

    yield put(chatActions.setSideUser(user));
    yield put(setIsSwitchedConversations(true));
    yield putResolve(setIsLiveChatActive(true));
    yield call(handleSdkCallbacks, acceptResponse);
    const currentChat = acceptResponse?.data?.chat;
    currentChat.attributes = { requestType: currentChat.type };

    yield call(handleMuteChimeSdk, acceptResponse);
    yield put(chatActions.joinAcceptedChat(currentChat));
    yield put(chatActions.setCurrentChat(currentChat));
    yield take([types.JOIN_ACCEPTED_CHAT_SUCCESS, types.EMIT_ERROR_NOTIFICATION]);
  } catch (e) {
    console.log(e);

    const apiName = e?.response?.config?.url.split('/');

    if (e?.response?.data?.error?.code !== 1001 || apiName[3] !== 'accept') {
      const requestErrorPayload = {
        redirectPath: '/conversations?view=psychic',
        isInvalidToken: false,
        errorText: e?.message,
        description: descriptionFromError(e),
      };
      yield put(handleRequestError(requestErrorPayload));
    }

    yield call(handleSdkError, e);
    yield call(SentryMethods.captureException, e);
    yield put(setIsLiveChatActive(false));
  } finally {
    yield putResolve(setLoadingState(false));

    if (isNoModeOrCustomMode) yield putResolve(setIsShownIncomingRequest(false));
  }
}

export function* handleCreateChatRequestWorker({
  payload,
}: ReturnType<typeof handleCreateChatRequest>) {
  const { attributes, setSecond, time } = payload;
  try {
    yield put(setLoadingState(true));
    yield put(showPopUpNotification(false));
    yield put(chatActions.setChatId(attributes.chatId));
    yield put(chatActions.setCustomerId(attributes.customerId));

    const isNoModeOrCustomMode: boolean = yield select(selectIsModeForShowingModals);

    if (isNoModeOrCustomMode) yield putResolve(setIsShownIncomingRequest(true));

    yield call(setSecond, time);
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* setCurrentChatRequest({ payload }: ReturnType<any>) {
  const channel = yield select(selectChannel);
  const { chatId } = payload;
  const [response] = yield all([
    call(API.Chat.getChatById, chatId),
    call(loadMessagesWorker, channel, ConversationType.LIVE_CHAT),
  ]);

  yield chatActions.setCurrentChat(response?.data?.chat);
}

export function* cleanAfterIgnoreChatRequestWorker() {
  try {
    const isAcceptChat = yield select(selectIsAcceptChat);

    if (!isAcceptChat) {
      yield put(setLoadingState(true));
      yield put(chatActions.deleteCurrentChat());
      yield put(chatActions.deleteCustomerId());
    }
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* sendMessageLocalSaga({ payload }: ReturnType<any>) {
  try {
    const { areaValue } = payload;
    const currentChat = yield select(selectCurrentChat);
    const currentUser = yield select(selectCurrentUser);
    const view = yield select(selectView);
    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);

    const chatData = {
      chatId: currentChat.chatId,
      participantType: view,
      customerRefIdEnc: currentChat.customerRefIdEnc,
      psychicRefId: currentChat.psychicRefId,
    };

    let userMessage: string = areaValue;
    const validMessage: RegExpMatchArray | null = checkPhoneOrEmailInMessage(areaValue);
    const isContainsPhoneOrEmail = Array.isArray(validMessage);

    if (isContainsPhoneOrEmail) {
      const result = yield call(API.Chat.validateUserMessage, chatData, areaValue);

      userMessage = result?.data?.message;
    }

    if (currentChat?.attributes?.requestType === ConversationType.DIRECT_MESSAGE) {
      yield put(handleOfflineMessageSending(currentChat.chatId, userMessage.trim()));
    } else {
      const body = yield getChatMessage(userMessage, currentChat, currentUser, view);

      yield put(sendMessage(body, currentChat.chatId));

      onSendMessage(userMessage, sdkCallbackStorage);
      DraftedMessages.remove(currentChat.chatId);
    }
  } catch (e) {
    console.log(e);

    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
  }
}

export function* setActiveChatSaga() {
  try {
    const currentChat = yield select(selectCurrentChat);
    const view = yield select(selectView);

    if (currentChat.chatId) {
      yield call(API.Chat.setActiveChat, currentChat.chatId, view);
      yield put(setChatMinutesActive(0));
    }
  } catch (e) {
    console.log(e);
    SentryMethods.captureException(e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
  }
}

export function* onEndChatRequestSaga({ payload }: ReturnType<any>) {
  try {
    const { friendlyName, flag } = payload;

    yield put(setLoadingState(true));

    yield put(showPopUpNotification(flag));
    yield put(setPopUpNotificationData({
      title: `Are you sure you want to end this Сhat with ${friendlyName}?`,
      notificationType: PopUpNotificationType.END_CHAT_REQUEST,
    }));
  } catch (e) {
    console.log(e);

    SentryMethods.captureException(e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
  } finally {
    yield put(setLoadingState(false));
  }
}
