import React from 'react';

import InfoIcon from 'src/assets/svg/warning-info-icon.svg';
import styles from 'src/components/Banners/InitChatInfoBanner/InitChatInfoBanner.module.scss';

const InitChatInfoBanner = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <img src={InfoIcon} alt="info" />
      <span>You will not be charged until
        your psychic sends their first
        response.
      </span>
    </div>
  );
};

export default InitChatInfoBanner;
