import React from 'react';

import styles from 'components/Buttons/CompleteTrainingButton/CompleteTrainingButton.module.scss';

const CompleteTrainingButton = () => (
  <div className={styles.buttonWrap}>
    <p>Need help knowing how to make the most of Psychic Notes?</p>
    <a
      href={process.env.PCN_TRAINING_URL}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.sendNotificationButton}
    >
      <span>Complete Training</span>
    </a>
  </div>
);

export default CompleteTrainingButton;
