export * from 'extracted-chat-components/enums';

export enum ErrorRequestType {
  ALERT_ABOVE_INPUT = 'alert-above-input',
  POP_UP_NOTIFICATION = 'pop-up-notification',
}

export enum Platform {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
}

export enum Environment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export enum ChatPlatform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum PopUpNotification {
  PAYMENT_FAILED_DUE_TO_REACHING_LIMIT = 'PaymentFailedDueToReachingLimit',
  ACTIVE_CHAT = 'customerIsBusy',
  ACTIVE_PHONE_CALL = 'activePhoneCall',
}

export enum IdentityPrefix {
  IDENTITY_PREFIX_CUSTOMER = 'cp-customer-',
  IDENTITY_PREFIX_PSYCHIC = 'cp-advisor-',
}

export enum NotificationsText {
  TECHNICAL_DIFFICULTY = 'We’re sorry, we seem to be experiencing some technical difficulty.',
  USER_WAS_N0T_FOUND = 'User wasn\'t found.',
  SET_SESSION_ERROR = 'Something went wrong during session setup.',
  OFFLINE_MESSAGE_REMINDER = `Reminder: Rule of thumb is to send back at least 3-5 sentences
    on the subject, or more if you feel inclined. The sooner you answer,
    the more likely the customer will call or message you back. (Note: This 
    notice is displayed to all psychics upon receiving an Offline Message).`,
  SESSION_EXPIRED = 'Request failed with status code 401',
  CHAT_DURATION_TEXT = 'Billable Chat Minutes',
  UPCOMING_APPOINTMENT_REASON = 'Chat ended due to Psychic having upcoming appointment.',
  BREAK = 'Please select the length of your break:',
  LOGGED_OUT = 'You must be logged in to view Psychic Notes.',
  CLIENT_LABELS = 'View, Add or Edit labels',
}

export enum ChatSourceForMixpanel {
  APP_ANDROID = 'app android',
  APP_IOS = 'app ios',
  MOBILE_WEB_ANDROID = 'mobile web android',
  MOBILE_WEB_IOS = 'mobile web ios',
  DESKTOP = 'desktop',
}

export enum ReasonUpdateOfflineMessage {
  ANSWERED_ON_OFFLINE_MESSAGE = 'answeredOnDm',
  CANCEL_OFFLINE_MESSAGE = 'cancelOfflineMessage',
}

export enum Events {
  MESSAGE = 'message',
}

export enum WebWorkerMessage {
  WATCH_TOKEN_LIFETIME = 'watchTokenLifeTime',
  TOKEN_UPDATED = 'tokenUpdated',
  TOKEN_ABOUT_EXPIRED = 'tokenAboutExpired',
  CLEAR_INTERVAL = 'clearInterval',
}

export enum InternetCheckerMessage {
  START_INTERVAL = 'startCheckingInterval',
  CONNECTION_ALIVE = 'connectionAlive',
  CONNECTION_DOWN = 'connectionDown',
  REQUEST_NOTIFICATIONS = 'requestNotifications',
  NOTIFICATION_OUT_OF_DATE = 'notificationOutOfDate',
  CLEAR_INTERVAL = 'clearInterval',
}

export enum BreadcrumbCategory {
  UI_CLICK = 'ui.click',
  XHR = 'xhr',
  CONSOLE = 'console',
}

export enum SdkChatEvent {
  ON_SWITCH_TO_PHONE = 'onSwitchToPhone',
}
export enum ToggleTypes {
  ONLINE_OFFLINE,
  ENABLED_DISABLED,
}

export enum StatusChannel {
  PhoneAndChat = 3,
  Phone = 1,
  Chat = 2,
}

export enum TwilioError {
  CANNOT_GET_TOKEN = 'Cannot get a twilio token',
  CANNOT_GET_CLIENT = 'Cannot create a twilio client',
  CANNOT_UPDATE_CLIENT = 'Cannot update a twilio client'
}

export enum MixpanelMessages {
  PSYCHIC_UNAVAILABLE = 'psychic unavailable',
  UPCOMING_APPOINTMENT = 'upcoming appointment',
}

export enum TwilioConnectingStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected'
}

export enum MixpanelUserTypes {
  EXISTING_CUSTOMER = 'ec',
  NEW_CUSTOMER = 'nc',
  CUSTOMER = 'customer',
  PSYCHIC = 'psychic',
}

export enum Notification {
  LOW_FUNDS = 'low-funds'
}
export enum CustomerBusyReason {
  CHAT = 'chat',
  PHONE = 'phone',
}

export enum PNTabs {
  ALL_NOTES_TAB = 'allNotes',
  PRIORITY_ACTION_TAB = 'priorityAction',
  CUSTOMER_LIST_TAB = 'customerList',
}

export enum PNNotificationStatus {
  ACTIVE = 'Active',
  MUTED = 'Muted',
}

export enum PNOrderType {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum PNMuteStatus {
  SHOW = 'Show Muted',
  HIDE = 'Hide Muted',
}

export enum DeclineSample {
  NEED_MORE_INFO_VALUE = 'need-more-info',
  NO_REPLY_NEEDED_VALUE = 'no-reply-needed',
  OM_ABOUT_RESTRICTED_VALUE = 'om-about-restricted',
  COULD_NOT_PSYCHICALLY_CONNECT_VALUE = 'could-not-psychically-connect'
}
export enum DeclineSampleLabels {
  NEED_MORE_INFO_LABEL = 'Need more information.',
  NO_REPLY_NEEDED_LABEL = 'No reply needed.',
  OM_ABOUT_RESTRICTED_LABEL = 'OM is about restricted topics.',
  COULD_NOT_PSYCHICALLY_CONNECT_LABEL = 'Could not psychically connect.'
}
export enum KountSdkConfig {
  CLIENT_ID = '100062',
  ENV_TEST = 'TEST',
  ENV_PROD = 'PROD',
}

export enum BreakButtonTypes {
  BREAK = 'Break',
  CANCEL_BREAK = 'Cancel Break',
  END_BREAK = 'End Break Now',
}

export enum TimeDuration {
  SPECIAL_REQUEST = '*Special request - 1st call in queue only',
  ONE_TO_TWO = '1-2 hrs',
  THREE_TO_FOUR = '3-4 hrs',
  FIVE_PLUS = '5+ hrs',
  UNTIL_COVERAGE_IMPORVES = 'Until coverage improves',
}

export enum FlaggedPopupText {
  PHONE_AND_EMAIL_VALIDATION = 'This notes contains copy that has been flagged. Please change the highlighted copy to send.',
  CUSTOMER_LIMIT_REACHED = 'Client limit reached.',
}

export enum MobilePages {
  PSYCHIC_NOTES = 'psychicnotes',
}

export enum ChatInitMessages {
  RESERVING_PSYCHIC = 'Reserving Psychic...',
  CHECKING_FOR_BALANCE = 'Checking for Balance...',
  CONNECTING_CHAT = 'Connecting Chat...',
  CONTACTING_PSYCHIC = 'Contacting Psychic...',
  WAITING_FOR_PSYCHIC_TO_ACCEPT = 'Waiting for Psychic to accept...',
  PSYCHIC_ACCEPTED_CHAT_REQUEST = 'Psychic Accepted Chat Request...',
}

export enum AutoReloadSourcePlatform {
  WEB_DESKTOP = 361,
  WEB_MOBILE = 362,
  iOS = 363,
  ANDROID = 364,
}

export enum AutoReloadLocation {
  LOW_FUND_CHAT_START = 367,
  LOW_FUND_CHAT_END = 368,
  BANNER_TOGGLE = 369,
}

export enum ClientHubSource {
  APP = 'App',
  ELECTRON = 'Electron',
  WEB = 'Web',
}

export enum ClientHubStatus {
  Busy = 'Busy',
  AVAILABLE = 'Available',
  OFFLINE = 'Offline',
  ONCALL = 'OnCall'
}

export enum ARNotificationType {
  AUTO_RELOAD_RUNS = 'auto-reload-runs',
}

export enum ClientType {
  EC = 'Existing Client',
  NEW = 'New Client',
}

export enum NotesSentLocation {
  PriorityAction = 421,
  AllNotes = 422,
  ComposeScreen = 423,
}

export enum DefaultOrderBy {
  ALL_NOTES_TAB = 'sentDateUTC',
  PRIORITY_ACTION_TAB = 'readingDateUTC',
  CUSTOMER_LIST_TAB = 'lastReadingDate',
}
