import React, { useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';

import {
  selectIsLiveChatActive,
  selectIsPsychicNotificationsEnable,
  selectIsSwitchedConversations,
  selectNotificationReactionUnreadCount,
  selectActivePage,
} from 'selectors/selectors';
import { ReactComponent as NotificationIcon } from 'src/assets/svg/notification-header.svg';
import {
  selectActiveScreen,
  selectIsNotificationActive,
  selectPsychicNoteConfigurableData,
  selectSendCustomerDetail,
} from 'selectors/psychicNotificationsSelectors';
import {
  setActivePage as _setActivePage,
  checkNotificationReactionUnreadCount as _checkNotificationReactionUnreadCount,
} from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { ONE_MINUTE, PAGE_ID, PSYCHIC_NOTE_TAB_ID } from 'src/constants/constants';
import {
  getPsychicAllNotificationsList as _getPsychicAllNotificationsList,
  getClientNoteHistory as _getClientNoteHistory,
} from 'src/redux/actions/psychicNotifications';
import styles from 'components/Buttons/NavigationButton/HeaderNavigation.module.scss';
// import { addPsychicMixpanelEventScreenViewed } from 'src/utils/commonUtil';
// import { getPsychicInfoMixpanel } from 'src/utils/helpers';

const HeaderNavigation = () => {
  // const currentUser = useSelector(selectCurrentUser);
  const isPsychicNotificationsEnable = useSelector(selectIsPsychicNotificationsEnable);
  const isNotificationActive = useSelector(selectIsNotificationActive);
  const reactionUnreadCount = useSelector(selectNotificationReactionUnreadCount);
  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const activePage = useSelector(selectActivePage);
  const activeScreen = useSelector(selectActiveScreen);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail) || [];
  const {
    psychicNotificationRefreshEveryMins,
  } = useSelector(selectPsychicNoteConfigurableData);

  const getPsychicAllNotificationsList = useBindDispatch(_getPsychicAllNotificationsList);
  const setActivePage = useBindDispatch(_setActivePage);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const checkNotificationReactionUnreadCount = useBindDispatch(
    _checkNotificationReactionUnreadCount,
  );

  const isPsychicNotification = isPsychicNotificationsEnable && isNotificationActive;

  useEffect(() => {
    if (isPsychicNotification) {
      const refreshIntervalId = setInterval(async () => {
        checkNotificationReactionUnreadCount();

        if (activePage === PAGE_ID.PSYCHIC_INBOX) {
          if (activeScreen === PSYCHIC_NOTE_TAB_ID.ALL_NOTE) {
            getPsychicAllNotificationsList(false);
          }

          if (activeScreen === PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION
            && sendCustomerDetail[0].pCComID) {
            getClientNoteHistory(sendCustomerDetail[0].pCComID, false);
          }
        }
      }, (ONE_MINUTE * psychicNotificationRefreshEveryMins));

      return () => clearInterval(refreshIntervalId);
    }
  }, [isPsychicNotification, activePage, activeScreen, sendCustomerDetail]);

  if (!isPsychicNotification) {
    return null;
  }

  const onChangePageHandler = (e) => {
    if (isPsychicNotification && !isLiveChatActive) {
      const { id } = e.currentTarget;
      getPsychicAllNotificationsList();
      setActivePage(id);
      // addPsychicMixpanelEventScreenViewed({
      //   screen_title: 'psychic notifications',
      //   platform: 'psychic desktop app',
      //   ...getPsychicInfoMixpanel(currentUser),
      // });
    }
  };
  const renderUnreadBadge = () => {
    if (reactionUnreadCount) {
      return (
        <Badge
          className={styles.badge}
          badgeContent={reactionUnreadCount}
          overlap="rectangular"
        >
          <NotificationIcon />
        </Badge>
      );
    }

    return <NotificationIcon />;
  };

  return (
    <div className={styles.wrapper}>
      {renderUnreadBadge()}
      <div className={styles.bar}>
        <button
          type="button"
          id={PAGE_ID.PSYCHIC_INBOX}
          onClick={onChangePageHandler}
          className={cn(styles.sendAndSeeNotifications,
            { [styles.disabled]: isSwitchedConversations })}
        >
          Send and See Notes
        </button>
        {!!reactionUnreadCount && (
          <div>
            <span className={styles.newReactionsMessage}>
              You have new updates!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderNavigation;
