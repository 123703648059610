import process from 'process';

import qs from 'qs';
import {
  forOwn,
  isString,
  isObject,
  isEmpty,
} from 'lodash';
import kountSDK from '@kount/kount-web-client-sdk/kount-web-client-sdk';
import axios from 'axios';

import {
  CHAT_ID,
  CHAT_PLATFORM_QUERY,
  DEBUG_MODE,
  FIFTEEN_HOURS_IN_SECONDS,
  IS_MOBILE_INTEGRATION,
  TOKEN_AUTH_STORAGE,
  URL_TO_NATIVE_APP,
  IS_ELECTRON_STORAGE,
  FUNDS_TO_ADD,
  INLINE_NOTIFICATION,
  INLINE_NOTIFICATION_INDEX,
  CLIENT_NOTES_DRAFT_KEY,
} from 'constants/constants';
import {
  ChatPlatform,
  ChatSourceForMixpanel,
  DeclineSample,
  Platform,
  PopUpNotificationType,
  SourcePlatforms,
  TwilioError,
  MixpanelEvents,
  KountSdkConfig,
  Environment,
  NotificationType,
  ChatInitMessages,
  ChatRequest,
  AutoReloadSourcePlatform,
  ClientHubStatus,
  ARNotificationType,
  PNNotificationStatus,
} from 'constants/enums';
import { API } from 'src/utils/api';
import { LocalStorage } from 'src/utils/storageHandler';
import { parseToken } from 'src/utils/authHandler';
import { styles } from 'src/utils/twilio';
import { SentryMethods } from 'src/utils/sentryMethods';
import { messages } from 'src/services/messages';
import MixpanelActions from 'src/utils/mixpanel';
import MixpanelPsychicActions from 'src/utils/mixpanelPsychicActions';
import {
  MixpanelTrackProps,
  PNCustomerListArray,
  PNCustomerListType,
  retryConfig,
} from 'types/objectTypes';

const modelDevices = ['ios', 'android'];

const defaultStyles = [
  'color: #ba1b1d',
  'display: block',
  'text-align: center',
  'text-transform: uppercase',
  'font-size: 12px',
  'font-weight: bold',
].join(';');

// export const deepClone = (obj: any): any => {
//   if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) return obj;

//   const temp = (obj instanceof Date) ? new Date(obj) : obj.constructor();

//   Object.keys(obj).forEach((key) => {
//     const newObject = obj;

//     if (Object.prototype.hasOwnProperty.call(newObject, key)) {
//       newObject.isActiveClone = null;
//       temp[key] = deepClone(newObject[key]);
//       delete newObject.isActiveClone;
//     }
//   });

//   return temp;
// };

export function deepLowerCase(obj: {}) {
  if (!isObject(obj)) return obj;

  const loweredObj = {};
  forOwn(obj, (val, key) => {
    loweredObj[key] = isString(val)
      ? val.toLowerCase() : deepLowerCase(val);
  });

  return isEmpty(loweredObj) ? obj : loweredObj;
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return '';
};

export const setCookie = (cname: string, cvalue: string, cDomain?: string) => {
  const currentDate = new Date();
  const expiresDate = new Date(
    currentDate.setFullYear(currentDate.getFullYear() + 1),
  );
  const cookieName = encodeURIComponent(cname);
  const cookieValue = encodeURIComponent(cvalue);

  if (cDomain) {
    document.cookie = `${cookieName}=${cookieValue}; domain=${cDomain}; expires=${expiresDate}`;
  } else {
    document.cookie = `${cookieName}=${cookieValue}; expires=${expiresDate}`;
  }
};

export const setCookieWithExpireDay = (name: string, value: string, days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const deleteCookie = (cname: string) => {
  const expiresDate = new Date('01 Jan 1970');
  document.cookie = `${encodeURIComponent(cname)}=; expires=${expiresDate}`;
};

// export function findLastElement<T>(array: Array<T>, func: (element: T) => boolean):
// T | undefined {
//   let i = array.length - 1;

//   while (i > 0) {
//     const element = array[i];
//     const isFitCondition = func(element);

//     if (isFitCondition) return element;

//     i -= 1;
//   }

//   return undefined;
// }

export const redirectUserToWebsiteOrNativeApp = (platform?: string): boolean => {
  const isDebugMode = LocalStorage.getItem(DEBUG_MODE);

  if (isDebugMode) {
    return false;
  }

  const chatPlatformQuery = LocalStorage.getItem(CHAT_PLATFORM_QUERY);
  const isWebPlatform = chatPlatformQuery === ChatPlatform.WEB;

  if (isWebPlatform) {
    return true;
  }

  const isMobileIntegration = modelDevices.includes(chatPlatformQuery as string)
    && !!LocalStorage.getItem(IS_MOBILE_INTEGRATION);

  const isRedirect = !platform && isMobileIntegration;

  if (isRedirect) {
    const url = LocalStorage.getItem(URL_TO_NATIVE_APP) || process.env.IOS_APP_URL;

    window.location.href = url as string;

    return true;
  }

  return false;
};

// this is not handled in native app but can be used in the future
// export const sendMessageToWebView = (message: any) => {
//   window.webkit.messageHandlers.onChatEvent.postMessage(message);
// };

export const checkCustomerActiveChat = async (customerId: string, extId: string) => {
  const activeChat = await API.Customer.getCustomerActiveChat(customerId);
  const chat = activeChat?.data?.chats[0];

  if (extId === chat?.psychicRefId) {
    return chat;
  }

  return null;
};

export const checkCustomerCompletedExpiredChat = async (customerId: string, extId: string) => {
  const completedExpiredChat = await API.Customer.getCustomerCompletedExpiredChat(
    customerId,
    extId,
  );
  const chat = completedExpiredChat?.data?.chats[0];

  if (extId === chat?.psychicRefId) {
    return chat;
  }

  return null;
};

export const checkStartingChat = (location: string) => {
  const isNotAvailablePsychic = LocalStorage.getItem('notificationType')
    === PopUpNotificationType.NOT_AVAILABLE_PSYCHIC;
  const chatId = location.split('/')[2] || LocalStorage.getItem(CHAT_ID);

  return !isNotAvailablePsychic && !chatId;
};

export const handleSwitchToPhoneNotifications = (
  {
    sideUser,
    addPopUpNotification,
  },
) => {
  const { lineName, customerPrice, basePrice } = sideUser;

  if (!lineName || !addPopUpNotification) {
    return;
  }

  const notification = {
    description: messages.switchToPhoneNotifier(customerPrice || basePrice),
    notificationType: PopUpNotificationType.SWITCH_TO_PHONE,
  };

  addPopUpNotification({
    isVisible: true,
    title: '',
    ...notification,
  });
};

const getPlatformForMixpanel = (platform: string, os: string): string => {
  switch (platform) {
    case Platform.TABLET:
    case Platform.MOBILE: {
      const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

      if (isMobileIntegration) {
        return os === 'iOS' ? ChatSourceForMixpanel.APP_IOS : ChatSourceForMixpanel.APP_ANDROID;
      }

      return os === 'iOS' ? ChatSourceForMixpanel.MOBILE_WEB_IOS : ChatSourceForMixpanel.MOBILE_WEB_ANDROID;
    }
    case Platform.DESKTOP: {
      return ChatSourceForMixpanel.DESKTOP;
    }
    default: return '';
  }
};

const getPlatformFor800CS = (platform: string, os: string): string => {
  switch (platform) {
    case Platform.TABLET:
    case Platform.MOBILE: {
      const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

      if (isMobileIntegration) {
        return os === 'iOS' ? SourcePlatforms.AppIOS : SourcePlatforms.AppAndroid;
      }

      return os === 'iOS' ? SourcePlatforms.MobileWebIOS : SourcePlatforms.MobileWebAndroid;
    }
    case Platform.DESKTOP: {
      return SourcePlatforms.WebDesktop;
    }
    default: return '';
  }
};

const getPlatformForAutoReload = (platform: string, os: string): number => {
  switch (platform) {
    case Platform.TABLET:
    case Platform.MOBILE: {
      const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

      if (isMobileIntegration) {
        return os === 'iOS'
          ? AutoReloadSourcePlatform.iOS
          : AutoReloadSourcePlatform.ANDROID;
      }

      return AutoReloadSourcePlatform.WEB_MOBILE;
    }
    default: return AutoReloadSourcePlatform.WEB_DESKTOP;
  }
};

export const getPlatform = (userAgent, service?: string): string | number => {
  const platform = userAgent?.getPlatformType();
  const { name } = userAgent?.getOS() || {};

  switch (service) {
    case 'mixpanel': {
      return getPlatformForMixpanel(platform, name);
    }
    case 'auto_reload': {
      return getPlatformForAutoReload(platform, name);
    }
    default: {
      return getPlatformFor800CS(platform, name);
    }
  }
};

export const getPlatformForChatToggleApplication = (userAgent) => {
  const platform = userAgent?.getPlatformType();
  const { name } = userAgent?.getOS() || {};
  const isElectron = LocalStorage.getItem(IS_ELECTRON_STORAGE);

  if (isElectron) {
    return SourcePlatforms.ELECTRON;
  }

  return getPlatformFor800CS(platform, name);
};

/** gets an identity from a token and return identity without a prefix */
export const getCustomerIdentity = async () => {
  const token: string = LocalStorage.getItem(TOKEN_AUTH_STORAGE) as string;

  if (!token) {
    return;
  }

  const data: any = await parseToken(token);

  return data?.reference_id;
};

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const importAndRetryChunk = async (
  callback: Function,
  counter: number,
  errorMessage: string,
) => {
  try {
    return await callback();
  } catch (e) {
    if (counter > 0) {
      await wait(1000);
      const result = await importAndRetryChunk(
        callback,
        counter - 1,
        errorMessage,
      );

      return result;
    }

    throw Error(errorMessage);
  }
};

export const validateTwilioToken = async (twilioToken) => {
  if (!twilioToken) {
    return false;
  }

  const result = await parseToken(twilioToken);
  const iatTime = result?.iat;
  const currentTime = Date.now() / 1000;

  return currentTime - iatTime < FIFTEEN_HOURS_IN_SECONDS;
};

export const descriptionFromError = (e) => (
  JSON.stringify({
    stack: e.stack.substring(0, 200),
    timestamp: new Date(),
  }, null, 2)
);

export const logWithStyles = (message, styles = defaultStyles) => {
  console.log(`%c ${message}`, styles);
};

export const captureException = (e: any) => {
  SentryMethods.captureException(e);
};

export const callRetry = async (callback: any, config?: retryConfig): Promise<any> => {
  const {
    attempts = process.env.MAX_TWILIO_TOKEN_OBTAIN_ATTEMPTS,
    timeout = process.env.TWILIO_TOKEN_OBTAIN_TIMEOUT,
  } = config || {};

  try {
    const response = await callback();

    return response;
  } catch (e) {
    if (attempts as number > 0) {
      console.log(`%c Retry attempt number: ${attempts}`, styles);
      console.log(`%c Seconds: ${timeout}`, styles);

      await wait(timeout);

      return callRetry(callback, {
        attempts: attempts as number - 1,
        timeout: timeout as number,
      });
    }

    if (e.message === TwilioError.CANNOT_GET_TOKEN) {
      SentryMethods.captureException(TwilioError.CANNOT_GET_TOKEN);
    }

    throw e;
  }
};

export const removeInlineNotificationFromLocalStorage = () => {
  LocalStorage.removeItem(FUNDS_TO_ADD);
  LocalStorage.removeItem(INLINE_NOTIFICATION);
  LocalStorage.removeItem(INLINE_NOTIFICATION_INDEX);
};

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (!b[orderBy]) {
    return -1;
  }

  if (!a[orderBy]) {
    return 1;
  }

  if (typeof b[orderBy] === 'boolean') {
    if (b[orderBy]) {
      return 1;
    }

    if (a[orderBy]) {
      return -1;
    }
  }

  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }

  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }

  return 0;
};

export const addPsychicMixpanelEventButtonTapped = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, props);
};

export const addPsychicMixpanelEventAlertViewed = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.ALERT_VIEWED, props);
};

export const addPsychicMixpanelEventAlertTapped = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.ALERT_TAPPED, props);
};

export const addPsychicMixpanelEventScreenViewed = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.SCREEN_VIEWED, props);
};

export const addPsychicMixpanelEventDropdownSelected = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.DROPDOWN_SELECTED, props);
};

export const getMixpanelScreenName = (screenName) => {
  switch (screenName) {
    case PopUpNotificationType.SWITCH_TO_PHONE:
      return 'switch to phone';
    default:
      return 'chat window';
  }
};

export const addMixpanelEventDropdownSelected = (props: MixpanelTrackProps) => {
  MixpanelActions.track(MixpanelEvents.DROPDOWN_SELECTED, props);
};

export const addPsychicMixpanelEventContentSent = (props: MixpanelTrackProps) => {
  MixpanelPsychicActions.track(MixpanelEvents.CONTENT_SEND, props);
};

export const getFramedUrl = (url, parameters) => `${url}?${qs.stringify(parameters)}`;

export const getRandomValue = (min, max) => Math.ceil(Math.random() * (max - min) + min);

export const setDeclineOMReason = (value, callback) => {
  switch (value) {
    case DeclineSample.NO_REPLY_NEEDED_VALUE: {
      callback('Thank you for your message.');

      break;
    }
    case DeclineSample.OM_ABOUT_RESTRICTED_VALUE: {
      callback(
        'Sorry, your message contained a restricted topic that is against policy for me to advise on. Please see https://www.californiapsychics.com/about/terms-of-use for more information',
      );

      break;
    }
    case DeclineSample.COULD_NOT_PSYCHICALLY_CONNECT_VALUE: {
      callback(
        'After carefully considering your message, I don\'t feel that I will be able to provide you with the best possible reading.',
      );

      break;
    }
    default:
    {
      callback('I need more information to answer your message accurately.');
    }
  }
};

export const getUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });

  return uuid.replaceAll('-', '');
};
export const kountSessionIDHandler = async () => {
  const kountSDKSSID = getUUID();
  const { ENVIRONMENT_NAME } = process.env;
  const isProd = ENVIRONMENT_NAME?.toUpperCase() === Environment.PRODUCTION.toUpperCase();

  const kountConfig = {
    clientID: KountSdkConfig.CLIENT_ID,
    environment: isProd ? KountSdkConfig.ENV_PROD : KountSdkConfig.ENV_TEST,
    isSinglePageApp: false,
  };
  const url = `${process.env.CALIFORNIA_PSYCHICS_API}/chat/version`;
  const res = await axios.get(url);

  if (res?.data?.kountEnabled) {
    logWithStyles(`KOUNT ${kountConfig.environment} INIT FOR ${ENVIRONMENT_NAME}`);
    kountSDK(kountConfig, kountSDKSSID);
  }

  return kountSDKSSID;
};

export const checkIsPsychicLoginInsideOfSchedule = (schedules) => {
  for (let i = 0; i < schedules.length; i += 1) {
    const { remainingMinutesToStartScheduled } = schedules[i];

    if (remainingMinutesToStartScheduled >= -60 && remainingMinutesToStartScheduled <= 0) {
      return true;
    }
  }

  return false;
};

export const simplyfyJsonArrayKeys = (systemMessages) => {
  const alertMessages: any = {};

  if (!systemMessages.length) {
    return alertMessages;
  }

  systemMessages.map((message) => {
    const key = (Object.keys(message))[0];
    const messageKey = key
      .split('_')
      .map((word, i) => (
        i === 0 ? word : word[0].toUpperCase() + word.substr(1)
      ))
      .join('');
    alertMessages[messageKey] = message[key];
  });

  return alertMessages;
};

export const truncateString = (str, maxLength) => {
  if (str?.length > maxLength) {
    return `${str?.slice(0, maxLength)}...`;
  }

  return str;
};

export const getValuesFromList = (list, parameterName, maxLimit = 0) => {
  let parameterValue = '';
  const limit = maxLimit || (list && list.length);

  for (let i = 0; i < list.length && i < limit; i += 1) {
    parameterValue += `${list[i][parameterName]}, `;
  }

  if (list.length > limit && parameterName === 'displayname') {
    return `${parameterValue} +[${list.length - limit}]`;
  }

  return parameterValue.substring(0, parameterValue.length - 2);
};

export const setChatInitNotification = (
  setInitChatBanner: Function,
  setInitChatNotification: Function,
  setIsChatInInitState: Function,
  notificationType,
  setShowInitModal: Function,
  setIsLiveChatActive: Function,
  setHistoryModeState: Function,
  isHistoryMode: boolean,
  isLiveChatActive: boolean,
  setToastBannerData: Function,
  toastMessage: string,
) => {
  switch (notificationType) {
    case NotificationType.CHAT_START: {
      setInitChatBanner(false);

      break;
    }
    case NotificationType.CHECKING_FOR_BALANCE: {
      setInitChatBanner(true);
      setIsChatInInitState(true);
      setInitChatNotification(ChatInitMessages.CHECKING_FOR_BALANCE);

      break;
    }
    case NotificationType.CONNECTING_CHAT: {
      setInitChatNotification(ChatInitMessages.CONNECTING_CHAT);
      setShowInitModal(true);
      setInitChatBanner(true);

      break;
    }
    case ChatRequest.CHAT_REQUEST_CREATED: {
      setIsChatInInitState(false);
      setShowInitModal(true);
      setInitChatBanner(true);
      setInitChatNotification(ChatInitMessages.WAITING_FOR_PSYCHIC_TO_ACCEPT);

      break;
    }
    case ChatRequest.CHAT_REQUEST_ACCEPTED: {
      setTimeout(() => setShowInitModal(false), 2000);
      setInitChatBanner(true);
      setInitChatNotification(ChatInitMessages.PSYCHIC_ACCEPTED_CHAT_REQUEST);

      if (isHistoryMode || !isLiveChatActive) {
        setHistoryModeState(false);
        setIsLiveChatActive(true);
      }

      break;
    }
    case ARNotificationType.AUTO_RELOAD_RUNS: {
      setToastBannerData({
        isVisible: true,
        toastMessage,
        isAnimation: true,
      });

      break;
    }
    default: break;
  }
};

export const getPsychicIcon = (notification, index = 0) => {
  if (
    notification
    && notification.psychic
    && notification.psychic?.images
    && notification.psychic.images.length
    && notification.psychic.images.length >= index + 1
  ) {
    return notification.psychic.images[index];
  }

  return null;
};

export const getProfileBorderStyle = (status: String, styles) => {
  switch (status) {
    case 'Busy':
      return styles.statusBusy;
    case 'Available':
      return styles.statusAvailable;
    default:
      return styles.statusOffline;
  }
};

export const checkPsychicBusyStatus = (status: String) => (
  status === ClientHubStatus.Busy || status === ClientHubStatus.ONCALL
);

export const getGA4Properties = () => {
  const cname = `_ga_${process.env.GA4_COOKIE_ID}`;
  const gaClientID = getCookie('_ga').split('.');
  const gaCoockies = getCookie(cname).split('.');

  if (!gaClientID.length || !gaCoockies.length) {
    return null;
  }

  return {
    ClientID: `${gaClientID[2]}.${gaClientID[3]}`,
    GA4SessionID: gaCoockies[2],
    GA4Timestamp: gaCoockies[5],
  };
};

export const removeActionFromList = (
  priorityActionList,
  clientsList,
) => {
  let isPriorityAction;
  const newList = priorityActionList.filter(
    (action) => !clientsList.some(
      (customer) => {
        if (customer.customerId === action.customerId) {
          isPriorityAction = true;

          return true;
        }

        return false;
      },
    ),
  );

  return {
    isPriorityAction,
    newList,
  };
};

export const isSelectedCustomer = (
  clientsList: PNCustomerListArray | null, client: PNCustomerListType,
) => clientsList?.some((customer) => customer?.customerId === client?.customerId);

export const getCommonClients = (
  firstClientsList: PNCustomerListArray,
  secoundClientsList: PNCustomerListArray,
) => firstClientsList?.filter(
  (clients) => secoundClientsList.some(
    (client) => client.customerId === clients.customerId
    && clients.notificationStatus === PNNotificationStatus.ACTIVE,
  ),
);

export const saveClientNoteToDraft = (customerId, messageBody) => setCookie(CLIENT_NOTES_DRAFT_KEY,
  JSON.stringify({
    customerId,
    messageBody,
  }));

export const getDraftedNote = (customerId) => {
  const draftedNote = getCookie(CLIENT_NOTES_DRAFT_KEY);
  const noteObject = draftedNote && JSON.parse(getCookie(CLIENT_NOTES_DRAFT_KEY));

  return (noteObject && noteObject?.customerId === customerId && noteObject?.messageBody) || '';
};

export const extractValuesFromArray = (arr, key) => arr.map((label) => label[key]);
