import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { View } from 'extracted-chat-components/enums';

import {
  INACTIVITY_NOTIFICATION,
  IS_CHAT_END_DUE_TO_LOW_FUNDS,
  POPUP_NOTIFICATION_EXCEPTION,
  THROTTLE_INACTIVITY_DELAY,
  THROTTLE_KEYPRESS_DELAY,
  SWITCH_TO_PHONE_BANNER,
} from 'constants/constants';
import { ConversationType, PopUpNotificationType } from 'constants/enums';
import {
  addPopUpNotification as _addPopUpNotification,
} from 'actions/appActions';
import {
  loadExtraMessages as _loadExtraMessages,
  sendMessageLocal as _sendMessageLocal,
  setActiveChat as _setActiveChat,
  setChatMinutesActive as _setChatMinutesActive,
} from 'actions/chatActions';
import {
  useResizableTextarea,
  useScrollListenerToLoadMessages,
  useScrollWithMessages,
} from 'src/hooks/resizeHandler.hook';
import { usePrevious } from 'src/hooks/prevValue.hook';
import { useThrottle } from 'src/hooks/activeChat.hook';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import { IAppState } from 'store';
import { isElectron, isIPadOs12Plus } from 'src/utils/helpers';
import {
  selectCurrentChat,
  selectCurrentUser,
  selectIsChatInInitState,
  selectIsHistoryMode,
  selectIsLiveChatActive,
  selectIsSwitchedConversations,
  selectPopUpNotificationData,
  selectSideUser,
  selectView,
  selectIsChatEndedDueToLowFund,
  selectIsChatAutoReloadEnable,
  selectIsInitChatBanner,
  selectIsShowAutoReloadHeader,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { PopUpNotificationData } from 'types/objectTypes';
import HeaderNotificationContainer from 'components/Notifications/HeaderNotification/HeaderNotficationContainer';
import MessagesContainer from 'components/Messages';
import ChatHeader from 'components/Header/ChatHeader';
import BillingNotification from 'components/Notifications/BillingNotification';
import ErrorAlert from 'components/Notifications/ErrorAlert';
import MessageForm from 'components/Forms/MessageForm';
import ChatBottomButtons from 'components/ChatBottomButtons';
import ChatOptionButtonGroup from 'components/Buttons/ChatOptionButtonGroup';
import EndChatDueToLowFundPopup from 'components/Popups/EndChatDueToLowFundsPopup';
import InternetConnectionBanner from 'components/Banners/InternetConnectionBanner';
import { LocalStorage } from 'src/utils/storageHandler';
import { addMixpanelEventInactivity } from 'src/utils/mixpanelHelper';
import { useIsMobile } from 'src/hooks/useIsMobile';
import style from 'src/components/Views/PsychicView/PsychicView.module.scss';
import TipBanner from 'components/Notifications/ContentBanner';

import styles from './Plain.module.scss';

const Plain = ({ requestType }) => {
  const sendMessageLocal = useBindDispatch(_sendMessageLocal);
  const loadExtraMessages = useBindDispatch(_loadExtraMessages);
  const setChatMinutesActive = useBindDispatch(_setChatMinutesActive);
  const setActiveChat = useBindDispatch(_setActiveChat);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);

  const isSwitchedConversations = useSelector(selectIsSwitchedConversations);
  const view = useSelector(selectView) as View;
  const currentChat = useSelector(selectCurrentChat);
  const currentUser = useSelector(selectCurrentUser);
  const isSwitchScreen = useSelector(selectIsSwitchedConversations);
  const chatMinutesChat = useSelector((state: IAppState) => state.chat.chatActiveMinutes);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isInitChatModal = useSelector((state: IAppState) => state.app.isInitChatModal);
  const headerBannerContent = useSelector((state: IAppState) => state.app.headerBannerContent);
  const notifications = useSelector(selectPopUpNotificationData) as PopUpNotificationData;
  const sideUser = useSelector(selectSideUser);
  const isInitChatState = useSelector(selectIsChatInInitState);
  const isInitChatBanner = useSelector(selectIsInitChatBanner);
  const isHistoryMode = useSelector(selectIsHistoryMode);
  const isChatAutoReloadEnable = useSelector(selectIsChatAutoReloadEnable);
  const isChatEndedDueToLowFund = useSelector(selectIsChatEndedDueToLowFund);
  const isShowAutoReloadHeader = useSelector(selectIsShowAutoReloadHeader);
  const isChatEndedDueToLowFundLS = LocalStorage.getItem(IS_CHAT_END_DUE_TO_LOW_FUNDS);
  const isEndedDueToLowFunds = isChatEndedDueToLowFundLS === 'true' || isChatEndedDueToLowFund;

  const messageContainer = useRef<HTMLDivElement>(null);
  const scrollableMessageWrapper = useRef<HTMLDivElement>(null);
  const area = useRef<HTMLTextAreaElement>(null);
  const fakeInputRef = useRef<HTMLInputElement>(null);

  const [areaValue, setAreaValue] = useState<string>('');
  const [isButtonDisabled, setStateOfSendButton] = useState<boolean>(false);

  const isPsychicView = useMemo(() => view === View.PSYCHIC, [view]);
  const isStartedDMChat = useMemo(() => currentChat?.attributes?.isStarted, [currentChat]);
  const isCustomerChat = useMemo(() => isInitChatModal && !isPsychicView && !isHistoryMode,
    [isHistoryMode, isInitChatModal, isPsychicView]);
  const isPhoneBanner = useMemo(() => (
    headerBannerContent?.type === SWITCH_TO_PHONE_BANNER
  ), [headerBannerContent]);

  const isChatIsActive = useMemo(() => isLiveChatActive && !isHistoryMode,
    [isHistoryMode, isLiveChatActive]);

  const { isMobile } = useIsMobile();
  const prevMessageValue = usePrevious(areaValue);

  useScrollWithMessages(
    messageContainer,
    scrollableMessageWrapper,
    currentChat.messages,
    currentChat,
    view,
  );

  useResizableTextarea(
    messageContainer,
    area,
    isSwitchScreen,
    areaValue,
    view,
  );

  useEffect(() => {
    const message = DraftedMessages.get(currentChat.chatId);

    setAreaValue(message || '');
  }, [currentChat.chatId]);

  const onChangeHandler = useCallback((e: ChangeEvent<HTMLTextAreaElement>, maxLength) => {
    const targetValue = e.target.value || '';
    const currentValue = targetValue.length > maxLength
      ? targetValue.substring(0, maxLength)
      : targetValue;

    setAreaValue(currentValue);
    DraftedMessages.save(currentValue, currentChat.chatId);
  }, [currentChat.chatId, areaValue]);

  const throttledMakeActiveState = useThrottle(() => {
    setActiveChat();
  }, THROTTLE_KEYPRESS_DELAY);

  const throttledSetChatMinutesActive = useThrottle((minutes) => {
    setChatMinutesActive(minutes);
  }, THROTTLE_INACTIVITY_DELAY);

  useEffect(() => {
    let interval;

    if (prevMessageValue && prevMessageValue !== areaValue) {
      throttledSetChatMinutesActive(0);
    }

    const isInactivity = chatMinutesChat === INACTIVITY_NOTIFICATION;
    const isEqual = prevMessageValue === areaValue;
    // @ts-ignore TODO: add enums to extracted-chat-components
    const isShow = POPUP_NOTIFICATION_EXCEPTION.has(notifications?.notificationType);
    const isInitChatModalPassed = (isPsychicView || !isInitChatModal);

    if (isLiveChatActive && isInitChatModalPassed) {
      interval = setInterval(() => {
        setChatMinutesActive(chatMinutesChat + 1);
      }, 60000);
    } else {
      clearInterval(interval);
    }

    if (isShow) {
      clearInterval(interval);
    }

    if (isInactivity && isEqual && isInitChatModalPassed && !isShow) {
      addMixpanelEventInactivity(isPsychicView, currentUser, sideUser);

      addPopUpNotification({
        isVisible: true,
        title: 'Are you still there?',
        description: 'This Chat will end in 3 minutes due to inactivity. Please tap "I\'m Still Here" if you wish to continue this Chat.',
        notificationType: PopUpNotificationType.DUE_TO_INACTIVITY,
      });
      setChatMinutesActive(0);
    }

    return () => clearInterval(interval);
  }, [
    areaValue,
    chatMinutesChat,
    isLiveChatActive,
    isInitChatModal,
    notifications,
    isPsychicView,
  ]);

  const onSendMessage = () => {
    if (!areaValue.trim()) {
      return;
    }

    if (isInitChatState && !isPsychicView) {
      return;
    }

    setStateOfSendButton(true);
    sendMessageLocal({ areaValue });

    fakeInputRef.current?.focus();
    area.current?.focus();

    setAreaValue('');
    setStateOfSendButton(false);
  };

  useEffect(() => {
    const [notificationType, isVisible] = LocalStorage
      .getItems(['notificationType', 'isVisibleNotification']);
    const isSendMessageConfirmation = notificationType
      === PopUpNotificationType.SEND_MESSAGE_CONFIRMATION;

    if (notificationType && isSendMessageConfirmation) {
      addPopUpNotification({
        isVisible: !!isVisible,
        title: 'Are you sure you want to send this Message?',
        notificationType: PopUpNotificationType.SEND_MESSAGE_CONFIRMATION,
        callback: { func: onSendMessage },
      });
    }
  }, []);

  useScrollListenerToLoadMessages(
    currentChat,
    loadExtraMessages,
    messageContainer.current,
  );

  const handleKeyDown = useCallback((): void => {
    // @ts-ignore
    currentChat.currentChannel?.typing();
  }, [currentChat.currentChannel]);

  const makeEmergencyScroll = useCallback(() => {
    const element = scrollableMessageWrapper.current;

    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [scrollableMessageWrapper, scrollableMessageWrapper.current]);

  const isOfflineClosed = useMemo(() => currentChat
      && isPsychicView
      && currentChat.attributes
      && currentChat?.attributes?.requestType === ConversationType.DIRECT_MESSAGE
      && (currentChat.attributes.isUserCanceled || currentChat.attributes.canceled),
  [isPsychicView, currentChat]);

  const plainContainerStyleName = useMemo(() => (isElectron
    ? 'plainContainerFullElectron'
    : 'plainContainerFull'), []);

  const isChatBottomButtons = useMemo(() => (
    (!isLiveChatActive || isHistoryMode) && !isPsychicView && !isInitChatModal
  ), [isLiveChatActive, isHistoryMode, isPsychicView, isInitChatModal]);

  const plainClasses = cn(
    style[`${plainContainerStyleName}Wrapper`],
    !isSwitchedConversations && isPsychicView && style.invisible,
  );

  if (isOfflineClosed
    || (requestType !== currentChat?.attributes?.requestType)
    || (isMobile && !isSwitchedConversations && view !== View.CUSTOMER)) {
    return null;
  }

  if (isChatAutoReloadEnable && isEndedDueToLowFunds) {
    return <EndChatDueToLowFundPopup />;
  }

  const gradientClass = cn(styles.headerGradientBg, {
    [styles.withPhoneBanner]: (isPhoneBanner && !isPsychicView && !isHistoryMode),
    [styles.withInfoTip]: (isInitChatBanner && !isPsychicView && !isHistoryMode),
    [styles.withChatInitState]: isInitChatModal && !isHistoryMode && isCustomerChat,
    [styles.withAutoReload]: isShowAutoReloadHeader && !isPsychicView,
  });

  return (
    <>
      {!isPsychicView && <div id="gradient" className={gradientClass} />}
      {isPsychicView && <ChatHeader requestType={requestType} />}
      <div className={plainClasses}>
        <div className={cn(
          styles.plainContainer,
          {
            [styles.customerView]: !isPsychicView,
            [styles.plainContainerWebkitHeight]: isIPadOs12Plus(),
          },
        )}
        >
          <HeaderNotificationContainer />
          <InternetConnectionBanner />
          {(isInitChatState && isCustomerChat) && <TipBanner />}
          <MessagesContainer
            messageContainer={messageContainer}
            scrollableMessageWrapper={scrollableMessageWrapper}
            messages={currentChat.messages}
            makeEmergencyScroll={makeEmergencyScroll}
          />
          <div className={cn(styles.messageFormWrapper, {
            [styles.customerChat]: !isPsychicView,
          })}
          >
            {isChatIsActive && !isPsychicView && <BillingNotification />}
            <ErrorAlert isChatBottomButtons={isChatBottomButtons} />
            {(isChatIsActive || isStartedDMChat || isCustomerChat) && (
              <MessageForm
                fakeInputRef={fakeInputRef}
                isButtonDisabled={isButtonDisabled}
                onSendMessage={onSendMessage}
                areaValue={areaValue}
                setAreaValue={setAreaValue}
                area={area}
                onChange={onChangeHandler}
                handleKeyDown={handleKeyDown}
                handleKeyPress={throttledMakeActiveState}
                isCustomerView={!isPsychicView}
                currentChat={currentChat}
              />
            )}
            {isChatBottomButtons && (
              <div className={styles.messageFormWrapperCustomerButton}>
                <ChatBottomButtons makeEmergencyScroll={makeEmergencyScroll} />
              </div>
            )}
            <ChatOptionButtonGroup
              currentChat={currentChat}
              isLiveChatActive={isLiveChatActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Plain;
