import React from 'react';

import { INLINE_NOTIFICATION_TIMESTAMP, LOW_FUNDS_TIME } from 'constants/constants';
import { getDiffTime } from 'src/utils/dateHandler';

export const useTimer = (isLiveChatActive) => {
  const inlineNotificationTime = localStorage.getItem(INLINE_NOTIFICATION_TIMESTAMP);
  const diff: number = inlineNotificationTime ? getDiffTime(inlineNotificationTime) : 0;
  const [time, setTime] = React.useState(LOW_FUNDS_TIME);
  const timer = React.useRef<number>();

  React.useEffect(() => {
    let min;
    let interval;

    if (!isLiveChatActive && timer) {
      clearInterval(timer.current);
    }

    if (diff && diff >= 60) {
      min = Math.floor(diff / 60);
      interval = 60000 - (diff - min * 60) * 1000;
      setTime(LOW_FUNDS_TIME - min);
    } else {
      interval = 60000 - Math.floor(diff) * 1000;
    }

    timer.current = setInterval(() => {
      setTime((prevValue) => (prevValue === 1 ? 1 : prevValue - 1));
    }, interval);

    return () => {
      clearInterval(timer.current);
    };
  }, [diff, isLiveChatActive]);

  return time;
};
