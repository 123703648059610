import React from 'react';
import cn from 'classnames';

import { useIsMobile } from 'src/hooks/useIsMobile';

import styles from './styles.module.scss';

const BurgerButton = ({ isActive, className = '', onClick }) => {
  const { isMobile } = useIsMobile();

  if (!isMobile) {
    return null;
  }

  return (
    <button
      className={cn(styles.burgerMenu, className, {
        [styles.active]: isActive,
      })}
      type="button"
      onClick={onClick}
      aria-label="Toggle menu"
    >
      <span className={styles.burgerMenuItem} />
    </button>
  );
};

export default BurgerButton;
