import { PopUpNotification, PopUpNotificationType } from 'constants/enums';

export const handlePaymentError = (e, callback) => {
  if (!e?.response?.data?.error) {
    return;
  }

  const { message, code } = e.response.data.error;

  if (message === 'Could not add funds, something went wrong.') {
    callback({
      title: 'Sorry, we are unable to process your payment. Please add or choose a new Payment method in your account and try again.',
      notificationType: PopUpNotificationType.PAYMENT_FAILED,
    });

    return;
  }

  if (code === 108) {
    callback({
      title: 'You have exceeded your purchase limit. Please set up PayPal in your Payment Settings or call Customer Care at',
      notificationType: PopUpNotification.PAYMENT_FAILED_DUE_TO_REACHING_LIMIT,
    });
  }
};
