import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConversationType } from 'extracted-chat-components/enums';

import StatusBar from 'components/StatusBar';
import TabPanel from 'components/TabPanel';
import { CHAT_TABS, CHAT_TABS_ID } from 'constants/constants';
import {
  selectActiveTab,
  selectIsLiveChatActive,
  selectOfflineChats,
  selectPsychicStatusControl,
} from 'selectors/selectors';
import Chats from 'components/Chats';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { setActiveTab as _setActiveTab } from 'src/redux/actions/appActions';

const ChatPage = () => {
  const [disabledTabs, setDisabledTabs] = useState(false);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const offlineChats = useSelector(selectOfflineChats);
  const activeTab = useSelector(selectActiveTab);
  const { offlineMessages } = useSelector(selectPsychicStatusControl);

  const setActiveTab = useBindDispatch(_setActiveTab);

  useEffect(() => {
    if (isLiveChatActive) {
      setActiveTab(CHAT_TABS_ID.LIVE_CHAT);
      setDisabledTabs(true);
    } else {
      setDisabledTabs(false);
    }
  }, [isLiveChatActive]);

  const onClickHandler = (e) => {
    if (!disabledTabs) {
      e.preventDefault();
      e.stopPropagation();

      const tabId = e.currentTarget.id;
      setActiveTab(tabId);
    }
  };

  const goToMyStatusHandler = () => {
    setDisabledTabs(false);
    setActiveTab(CHAT_TABS_ID.MY_STATUS);
  };

  const checkIsTabDisabled = (tab) => {
    const isMyStatusTab = tab.id === CHAT_TABS_ID.MY_STATUS;
    const isLiveChatTab = tab.id === CHAT_TABS_ID.LIVE_CHAT;
    const isOfflineMassageTab = tab.id === CHAT_TABS_ID.OFFLINE_MESSAGE;

    return disabledTabs
    || (isLiveChatActive && !(isMyStatusTab || isLiveChatTab))
    || (isOfflineMassageTab && !offlineMessages);
  };

  return (
    <TabPanel
      disabled={isLiveChatActive}
      checkIsTabDisabled={checkIsTabDisabled}
      activeTab={activeTab}
      onChangeTab={onClickHandler}
      goToMyStatusHandler={goToMyStatusHandler}
      tabs={CHAT_TABS}
      withBadges
    >
      <StatusBar key={CHAT_TABS_ID.MY_STATUS} />
      <Chats
        key={CHAT_TABS_ID.LIVE_CHAT}
        conversationType={ConversationType.LIVE_CHAT}
      />
      <Chats
        key={CHAT_TABS_ID.OFFLINE_MESSAGE}
        conversationType={ConversationType.DIRECT_MESSAGE}
        chatItems={offlineChats}
      />
    </TabPanel>
  );
};

export default ChatPage;
