import React, { useEffect } from 'react';

const useStickyHeader = <T extends HTMLElement>(headerRef: React.RefObject<T>) => (

  useEffect(() => {
    const scrollHandler = () => {
      const header = headerRef.current;

      if (header) {
        header.style.marginTop = `${window.scrollY}px`;
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  })
);

export default useStickyHeader;
