import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { ConversationContainerInterface } from 'types/componentTypes';
import { ConversationType } from 'constants/enums';
import { calcRemainingTime, buildingTimeRemainingLine } from 'src/utils/dateHandler';
import {
  selectChatId,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import ConversationButton from 'components/Buttons/ConversationButton';
import {
  removeCurrentOfflineChatHandler as _removeCurrentOfflineChatHandler,
  removeOfflineChatHandler as _removeOfflineChatHandler,
  setOfflineChatById as _setOfflineChatById,
} from 'actions/offlineChatActions';
import {
  setIsSwitchedConversations as _setIsSwitchedConversations,
} from 'actions/appActions';

const ChatItem: React.FC<ConversationContainerInterface> = ({
  chat,
  type,
  currentChatId,
  setCurrentChat,
}) => {
  const chatIdFromState = useSelector(selectChatId);

  const isLiveChat = type === ConversationType.LIVE_CHAT;

  const removeCurrentOfflineChatHandler = useBindDispatch(_removeCurrentOfflineChatHandler);
  const removeOfflineChatHandler = useBindDispatch(_removeOfflineChatHandler);
  const setOfflineChatById = useBindDispatch(_setOfflineChatById);
  const setIsSwitchedConversations = useBindDispatch(_setIsSwitchedConversations);

  const remainingTime = useMemo(() => calcRemainingTime(
    chat,
    currentChatId,
    removeCurrentOfflineChatHandler,
    removeOfflineChatHandler,
  ), [
    chat,
    currentChatId,
    removeCurrentOfflineChatHandler,
    removeOfflineChatHandler,
  ]);

  const [timeLeft, setTimeLeft] = useState<string>(remainingTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (chat?.directMessageExpiresAt && chat?.chatId) {
        setTimeLeft(
          buildingTimeRemainingLine(
            chat,
            currentChatId,
            removeCurrentOfflineChatHandler,
            removeOfflineChatHandler,
          ),
        );
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [
    chat,
    currentChatId,
    removeCurrentOfflineChatHandler,
    removeOfflineChatHandler,
    setTimeLeft,
  ]);

  const onClick = useCallback(() => {
    if (isLiveChat) {
      setIsSwitchedConversations(true);
    } else {
      setOfflineChatById(chat, chat?.chatId, chat?.chatId === currentChatId, type);
    }
  }, [currentChatId, chat, type, isLiveChat]);

  const onPressEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    const isEnterPressed = event.key === 'Enter'
      && chat?.chatId
      && setCurrentChat;

    if (isEnterPressed) {
      return setCurrentChat!(chat!.chatId);
    }

    return null;
  };

  return (
    <ConversationButton
      timeLeft={timeLeft}
      sideUser={chat?.sideUser}
      onClick={onClick}
      onPressEnter={onPressEnter}
      isCurrentChat={chat?.chatId === (currentChatId || chatIdFromState)}
      isLiveChat={isLiveChat}
    />
  );
};

export default ChatItem;
