import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Avatar from '@mui/material/Avatar';

import calender from 'src/assets/png/scheduleIcon.png';
import group from 'src/assets/png/groupIcon.png';
import BackButtonIcon from 'assets/svg/BackArrowGreen.svg';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { ClientHubStatus, MixpanelEvents, Status } from 'constants/enums';
import { getProfileBorderStyle, getPsychicIcon, truncateString } from 'src/utils/commonUtil';
import { CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT, PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { selectSideUser } from 'selectors/selectors';
import MuteButtonTooltip from 'src/components/Header/ClientNotesHeader/MuteButtonTooltip';
import HeaderButton from 'src/components/ClientHubWeb/HeaderButton/buttons';
import MixpanelActions from 'src/utils/mixpanel';
import { getPsychicInfoMixpanelForCustomer } from 'src/utils/helpers';

import styles from './ClientNotesHeaderForCustomer.module.scss';

const ClientsHeaderForCustomer = ({
  headerDetails,
  customerMessageFAQUrl,
  handlePsychicNoteScreens,
  isMuted,
  handleMuteUnmutePsychic,
}) => {
  const { isMobileForClientHub } = useIsMobile();

  const sideUser = useSelector(selectSideUser);

  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const { psychic } = headerDetails;

  const buttonLabel = useMemo(() => {
    const psychicLineName = truncateString(
      psychic.lineName,
      CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT,
    );

    return `${isMuted ? 'Unmute' : 'Mute'} Notes from ${psychicLineName}`;
  }, [isMuted, psychic.lineName]);

  const psychicStatus = useMemo(() => {
    let chatStatus = psychic?.chatStatus.toLowerCase();
    let phoneStatus = psychic?.lineStatus.toLowerCase();

    if (sideUser) {
      chatStatus = sideUser.chatStatus.toLowerCase();
      phoneStatus = sideUser.phoneStatus.toLowerCase();
    }

    if (chatStatus === Status.BUSY || phoneStatus === Status.BUSY) {
      return ClientHubStatus.Busy;
    }

    if (chatStatus === Status.AVAILABLE || phoneStatus === Status.AVAILABLE) {
      return ClientHubStatus.AVAILABLE;
    }

    if (chatStatus === ClientHubStatus.ONCALL.toLocaleLowerCase()
    || phoneStatus === ClientHubStatus.ONCALL.toLocaleLowerCase()) {
      return ClientHubStatus.Busy;
    }

    return chatStatus[0].toUpperCase() + chatStatus.slice(1);
  }, [psychic.chatStatus, psychic.lineStatus, sideUser]);

  const bioPageURL = useMemo(() => (
    `${process.env.BIO_PAGE_URL}${psychic.lineName}-${psychic.extId}?calanderIconClick=true`
  ), [psychic]);

  const psychicStatusStyle = () => {
    let style;

    switch (psychicStatus.toLowerCase()) {
      case Status.BUSY:
        style = styles.psychicBusy;

        break;
      case Status.AVAILABLE:
        style = styles.psychicAvailable;

        break;
      default:
        style = styles.psychicOffline;

        break;
    }

    return cn(styles.psychicStatus, style);
  };

  const handleupdateNotificationStatus = (e) => {
    e.preventDefault();
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: isMuted ? 'Unmute notes' : 'Mute notes',
      button_type: 'kabob menu item',
      screen_name: 'psychic notes',
      screen_title: 'psychic notes',
      ...getPsychicInfoMixpanelForCustomer(sideUser),
    });

    handleMuteUnmutePsychic(setTooltipVisible);
  };

  const handleCalenderIcon = () => {
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'psychic schedule',
      button_type: 'calendar icon',
      screen_name: 'psychic notes',
      screen_title: 'psychic notes',
      ...getPsychicInfoMixpanelForCustomer(sideUser),
    });
  };

  const handleKabobIcon = () => {
    if (!isTooltipVisible) {
      MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
        button_text: 'kabob menu',
        button_type: 'kabob icon',
        screen_name: 'psychic notes',
        screen_title: 'psychic notes',
        ...getPsychicInfoMixpanelForCustomer(sideUser),
      });
    }

    setTooltipVisible(!isTooltipVisible);
  };

  const handleTooltip = (isClose = false) => {
    if (isClose) {
      MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
        button_text: 'close',
        button_type: 'kabob menu item',
        screen_name: 'psychic notes',
        screen_title: 'psychic notes',
      });
    }

    setTooltipVisible(!isTooltipVisible);
  };

  const triggerPsychicNoteFAQMixPanel = () => {
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'psychic notes FAQs',
      button_type: 'kabob menu item',
      screen_name: 'psychic notes',
      screen_title: 'psychic notes',
    });
  };

  return (
    <div>
      <div className={cn(styles.wrapper, styles.row)}>
        <div className={styles.headerContentWrapper}>
          <button
            type="button"
            id={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
            onClick={handlePsychicNoteScreens}
            className={styles.backArrow}
          >
            <img src={BackButtonIcon} alt="" className={styles.clientNotesBackButtonIcon} />
            <p>My Inbox</p>
          </button>
          <div className={styles.zodiacIconWrapper}>
            <div>
              <Avatar
                alt="Psychic"
                src={getPsychicIcon(headerDetails, 4)}
                className={cn(styles.zodiacIcon,
                  getProfileBorderStyle(psychicStatus, styles), {
                    [styles.faded]: isMuted,
                  })}
              />
            </div>
            <div className={styles.headerNameMute}>
              <div className={cn(styles.psychicLineNameWrapper, { [styles.faded]: isMuted })}>
                <span className={styles.psychicLineName}>
                  {truncateString(psychic.lineName, CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT)}
                </span>
                <span className={psychicStatusStyle()}>
                  {psychicStatus}
                </span>
              </div>
              { isMuted && <span className={cn(styles.muteFlag, styles.muteFlagCu)}>Muted</span> }
            </div>
          </div>
        </div>
        <div className={styles.holdMessage}>
          <p>Click and hold on message to react</p>
        </div>
        <div className={styles.btnWrapper}>
          <a
            href={customerMessageFAQUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btnAnchorTag}
            aria-label="faq"
          >
            <HeaderButton
              text="Psychic Notes FAQs"
              classesName={styles.btnNoteFAQ}
              isVisible={!isMobileForClientHub}
              onClick={triggerPsychicNoteFAQMixPanel}
            />
          </a>
          <a
            href={bioPageURL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btnAnchorTag}
            onClick={handleCalenderIcon}
          >
            <img src={calender} alt="img" />
          </a>
          <button
            type="button"
            onClick={handleKabobIcon}
          >
            <img src={group} alt="img" className={styles.btnWrapperImg} />
          </button>
          <MuteButtonTooltip
            label={buttonLabel}
            isVisible={isTooltipVisible}
            onFaqsHref={customerMessageFAQUrl}
            onClose={handleTooltip}
            onClick={handleupdateNotificationStatus}
            triggerPsychicNoteFAQMixPanel={triggerPsychicNoteFAQMixPanel}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientsHeaderForCustomer;
