import React from 'react';
import cn from 'classnames';

import { MessageInterface } from 'types/componentTypes';
import styles from 'components/Messages/Messages.module.scss';

const SystemMessage: React.FC<MessageInterface> = ({ message, isPreviousOffline }) => {
  const messageContainer = cn(
    styles.messageContainer,
    styles.justifyCenter,
    styles.messagesMt,
    styles.systemMessage,
  );

  if (!isPreviousOffline) {
    return (
      <div className={messageContainer}>
        <span className={styles.systemMessageBox}>{message}</span>
      </div>
    );
  }

  return (
    <>
      <div className={styles.dividedLine} />
      <div className={messageContainer}>
        <span className={styles.systemMessageBox}>{message}</span>
      </div>
    </>
  );
};

export default SystemMessage;
