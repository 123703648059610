import React from 'react';

import styles from 'components/Titles/StandbyTitle/StandbyTitle.module.scss';

const NotEnabledForChatTitle = (
  <>
    <h3 className={styles.popupTitle}> Please stand by.</h3>
    <div className={styles.lineStyle} />
    <p className={styles.messageText}>
      You are either currently on a call or a call is routing
      through to you. If you’re on a call/chat, you will
      be logged off once it ends. If a call is being routed
      through, you should receive it shortly. If not, you
      will be logged off by the system after 5 mins.
    </p>
  </>
);
export default NotEnabledForChatTitle;
