import React from 'react';

import style from './Callback.module.scss';

const Callbacks = ({ callBackQueueServerData }) => (
  <div className={style.callbacks}>
    <p className={style.callbacksP}>
      Calls in Queue:
      <span className={style.callbackNumber}>
        {callBackQueueServerData.length}
      </span>
    </p>
  </div>
);
export default Callbacks;
