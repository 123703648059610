import React from 'react';
import cn from 'classnames';

import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import { IListDetails } from 'types/componentTypes';
import ClearIcon from 'src/assets/svg/check-mark-icon.svg';
import styles from 'components/Selects/CustomSelect/CustomSelect.module.scss';

const ListDetails: React.FC<IListDetails> = ({ customer, isMuted, isSelected }) => (
  <div className={cn(styles.customerList, { [styles.selected]: isSelected })}>
    <div>
      <img
        src={isSelected ? ClearIcon : zodiacMap.get(customer?.zodiacSign.toLocaleLowerCase())}
        className={styles.zodiacIcons}
        alt="img"
      />
      <span className={cn(styles.userName, { [styles.userNameMuted]: isMuted })}>
        {customer.displayname}
      </span>
    </div>
    {isMuted
      && (
        <span className={styles.mutedLabel}>
          Muted
        </span>
      )}
  </div>
);

export default ListDetails;
