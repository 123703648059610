import { put, select, call } from 'redux-saga/effects';

import { View } from 'constants/enums';
import { LATEST_NOTIFICATION_INDEX, NOTIFICATION_LOAD_SIZE } from 'constants/constants';
import * as chatActions from 'actions/chatActions';
import { addSystemNotifications } from 'actions/chatActions';
import { selectView, selectSystemChannel } from 'selectors/selectors';
import { LocalStorage } from 'src/utils/storageHandler';
import { handlePsychicNotifications } from 'src/redux/sagas/chat/channels/systemChannelSaga';
import { descriptionFromError } from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';
import { handleRequestError } from 'actions/appActions';

export function* setUpNotifications(data?: any) {
  try {
    const { missedNotificationsAmount } = data?.payload || {};
    const amountCondition = missedNotificationsAmount > 10 || !missedNotificationsAmount;
    const notificationsAmount = amountCondition ? NOTIFICATION_LOAD_SIZE
      : missedNotificationsAmount;
    const currentView = yield select(selectView);
    const systemChannel = yield select(selectSystemChannel);
    const notificationPaginator = yield systemChannel?.getMessages(notificationsAmount);
    let appointmentTimestamp = null;
    let customerName = null;
    const notifications = yield notificationPaginator
      .items.map((notification) => {
        const notificationState = notification.state;
        const { appointmentDetails = null } = notificationState.attributes || {};
        appointmentTimestamp = appointmentDetails?.appointmentTimestamp;
        customerName = appointmentDetails?.customerName;

        return notificationState;
      });

    const now = new Date().getTime();

    if (appointmentTimestamp && customerName && appointmentTimestamp > now) {
      yield put(chatActions.setAppointment({ appointmentTimestamp, customerName }));
    }

    const latestNotificationIndex = notifications[notifications.length - 1]?.index || '';

    if (missedNotificationsAmount) {
      console.info('RESTORED NOTIFICATIONS AMOUNT', notifications.length);

      if (currentView === View.PSYCHIC) {
        for (let i = 0; i < notifications.length; i += 1) {
          yield call(handlePsychicNotifications, notifications[i]);
        }
      }
    }

    LocalStorage.setItem(LATEST_NOTIFICATION_INDEX, latestNotificationIndex);
    yield put(addSystemNotifications(notifications));
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    const requestErrorPayload = {
      redirectPath: '/login',
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  }
}
