import React from 'react';

import styles from 'components/Tooltips/GamifyInfoTooltip/InfoTooltip.module.scss';

const GamifyInfoTooltip = ({
  isOpen,
  clientPool,
  goalPercentage,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.tooltip}>
      <span className={styles.tooltipHeading}>How is my Engagement Goal calculated?</span>
      <span className={styles.tooltipText}>
        Your personal engagement goal is calculated from a
        percentage of clients you can currently send notes to.
      </span>
      <span className={styles.tooltipText}>
        {`Your goal is ${goalPercentage}% of the ${clientPool} clients in your client list now.`}
      </span>
    </div>
  );
};

export default GamifyInfoTooltip;
