import React from 'react';
import { useSelector } from 'react-redux';
import { InternetConnectionEvents } from 'extracted-chat-components/enums';

import { selectInternetConnectionStatus } from 'selectors/selectors';

import style from './styles.module.scss';

const InternetConnectionBanner = () => {
  const internetConnectionStatus = useSelector(selectInternetConnectionStatus);
  const isVisible = internetConnectionStatus === InternetConnectionEvents.OFFLINE;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={style.internetConnectionBanner}>
      <p className={style.internetConnectionText}>
        No internet connection.<br />
        Please check your internet connection and try again.
      </p>
    </div>
  );
};

export default InternetConnectionBanner;
