import { MixpanelEvents } from 'constants/enums';
import { INACTIVITY_NOTIFICATION } from 'constants/constants';

import MixpanelActions from './mixpanel';
import { getPsychicInfoMixpanel } from './helpers';

export const addMixpanelEventInactivity = (isPsychicView: boolean, currentUser, sideUser) => {
  if (isPsychicView) {
    MixpanelActions.track(MixpanelEvents.CHAT_PSYCHIC_INACTIVITY_PERIOD, {
      inactive_minutes: INACTIVITY_NOTIFICATION,
      customerId: sideUser.customerRefId,
      ...getPsychicInfoMixpanel(currentUser),
    }, sideUser.customerRefId);
  } else {
    MixpanelActions.track(MixpanelEvents.CHAT_CUSTOMER_INACTIVITY_PERIOD, {
      inactive_minutes: INACTIVITY_NOTIFICATION,
      ...getPsychicInfoMixpanel(sideUser),
    });
  }
};
