/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import {
  ButtonSize,
  PsychicStatusChannel,
} from 'extracted-chat-components/enums';
import cn from 'classnames';
import InteractChatButton from 'extracted-chat-components/src/components/InteractChatButton';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

import { TimeDuration } from 'constants/enums';
import CallBackListPopup from 'components/Popups/CallBackListPopup/index';
import {
  setPsychicCallbackQueue as _setPsychicCallbackQueue,
} from 'actions/chatActions';
import {
  setLoadingState as _setLoadingState,
  setPsychicLoginOutsideOfScheduleMsg as _setPsychicLoginOutsideOfScheduleMsg,
  setPsychicLoginOutsideLastActivityId as _setPsychicLoginOutsideLastActivityId,
} from 'actions/appActions';
import {
  updatePsychicSchedule as _updatePsychicSchedule,
} from 'actions/statusToggleActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { API } from 'src/utils/api';
import {
  selectCallBackQueueData,
  selectExtId,
  selectPsychicLoginOutsideLastActivityId,
  selectPsychicLoginOutsidePopupShow,
  selectIsPsychicLoginOutsideOfScheduleEditMode,
  selectPsychicLoginOutsideOfScheduleMsg,
} from 'selectors/selectors';
import CalendarIcon from 'assets/svg/calendar-icon.svg';
import { CallBackQueueData } from 'types/objectTypes';

import style from './style.module.scss';

const StyledTrack = styled.div`
  background: ${(props) => {
    switch (props.index) {
      case 1:
        return '#0099a8';
      case 2:
        return '#f00';
      default:
        return 'linear-gradient(to right, red , yellow);';
    }
  }};
`;

// eslint-disable-next-line react/destructuring-assignment
const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const PsychicLoginOutsideSchedule = ({ isOpen, setTrigger, reason }) => {
  const [callBackPopup, setCallBackPopup] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sliderValue, setSliderValue] = useState(-1);
  const [totalExpectedTimeForAll, setTotalExpectedTimeForAll] = useState(0);
  const [expectedLoginMin, setExpectedLoginMin] = useState(0);
  const [callBackQueueData, setCallBackQueueData] = useState<Array<CallBackQueueData>>([]);

  const callBackQueueServerData = useSelector(selectCallBackQueueData);
  const psychicLoginOutsidePopupShow = useSelector(selectPsychicLoginOutsidePopupShow);

  useEffect(() => {
    const stringifyCallbackData = JSON.stringify(callBackQueueServerData);
    const parsedData = JSON.parse(stringifyCallbackData);
    setCallBackQueueData(parsedData);

    if (!parsedData.length) {
      setChecked(false);
    }
  }, [callBackQueueServerData]);

  useEffect(() => {
    let total = 0;
    let totalForAll = 0;
    for (let index = 0; index < callBackQueueData.length; index += 1) {
      const calData = callBackQueueData[index];

      if (calData.screened) {
        total += calData.minimumTalkTime;
      }

      totalForAll += calData.minimumTalkTime;
    }
    setExpectedLoginMin(total);
    setTotalExpectedTimeForAll(totalForAll);
  }, [callBackQueueData]);

  const extId = useSelector(selectExtId);
  const psychicLoginOutsideLastActivityId = useSelector(selectPsychicLoginOutsideLastActivityId);
  const isPsychicLoginOutsideOfScheduleEditMode = useSelector(
    selectIsPsychicLoginOutsideOfScheduleEditMode,
  );
  const psychicLoginOutsideOfScheduleMsg = useSelector(selectPsychicLoginOutsideOfScheduleMsg);

  const setPsychicCallbackQueue = useBindDispatch(_setPsychicCallbackQueue);
  const updatePsychicSchedule = useBindDispatch(_updatePsychicSchedule);
  const setLoadingState = useBindDispatch(_setLoadingState);
  const setPsychicLoginOutsideOfScheduleMsg = useBindDispatch(_setPsychicLoginOutsideOfScheduleMsg);
  const setPsychicLoginOutsideLastActivityId = useBindDispatch(
    _setPsychicLoginOutsideLastActivityId,
  );

  const updateSliderValueBasedOnActivityId = (activityId) => {
    switch (activityId) {
      case 8:
        setSliderValue(1);

        break;
      case 9:
        setSliderValue(2);

        break;
      case 10:
        setSliderValue(3);

        break;
      case 13:
        setSliderValue(0);

        break;
      case 11:
        if (callBackQueueData.length) {
          setChecked(true);
        } else {
          setChecked(false);
        }

        setSliderValue(-1);

        break;

      default:
        setSliderValue(-1);

        break;
    }
  };

  useEffect(() => {
    updateSliderValueBasedOnActivityId(psychicLoginOutsideLastActivityId);
  }, [psychicLoginOutsideLastActivityId]);

  const getTimeBaseValByselection = (val) => {
    let durationStr = '';
    let activityId = -1;

    switch (val) {
      case 0:
        durationStr = TimeDuration.SPECIAL_REQUEST;
        activityId = 13;

        break;
      case 1:
        durationStr = TimeDuration.ONE_TO_TWO;
        activityId = 8;

        break;
      case 2:
        durationStr = TimeDuration.THREE_TO_FOUR;
        activityId = 9;

        break;
      case 3:
        durationStr = TimeDuration.FIVE_PLUS;
        activityId = 10;

        break;
      default:
        durationStr = TimeDuration.UNTIL_COVERAGE_IMPORVES;

        break;
    }

    return {
      durationStr,
      activityId,
    };
  };

  const submitClickHandler = () => {
    if (checked) {
      setCallBackPopup(true);
    } else {
      setTrigger(false);
      const chatStatus = reason === PsychicStatusChannel.PHONE_CHAT.toString();
      const timeBaseData = getTimeBaseValByselection(sliderValue);

      updatePsychicSchedule({
        phone: true,
        chat: chatStatus,
        status: 'Available',
        comment: timeBaseData.durationStr,
        psychicActivityID: timeBaseData.activityId,
      });
      setPsychicLoginOutsideOfScheduleMsg(timeBaseData.durationStr);
      setPsychicLoginOutsideLastActivityId(timeBaseData.activityId);
    }
  };

  const cancelHandler = () => {
    updateSliderValueBasedOnActivityId(psychicLoginOutsideLastActivityId);
    setTrigger(false);
  };

  const checkedHandler = () => {
    if (!checked) {
      setSliderValue(-1);
    }

    setChecked(!checked);
  };

  const updateRowsData = (index) => {
    const updatedArray = JSON.parse(JSON.stringify(callBackQueueData));
    let checkedInSeq = 0;
    let isIndexafterChangeIndex = false;

    for (let idx = 0; idx < updatedArray.length; idx += 1) {
      if (idx !== index) {
        const calData = updatedArray[idx];

        if (idx > index && calData.screened) {
          isIndexafterChangeIndex = true;
        } else if (calData.screened) {
          checkedInSeq += 1;
        }
      }
    }

    if (checkedInSeq === index && !isIndexafterChangeIndex) {
      updatedArray[index].screened = updatedArray[index].screened ? 0 : 1;
      setCallBackQueueData(updatedArray);
    }
  };

  const onConfirmLogin = async () => {
    const outdialIDs: any = [];
    let endId = -1;
    for (let index = 0; index < callBackQueueData.length; index += 1) {
      const queueData: any = callBackQueueData[index];

      if (queueData.screened) {
        outdialIDs.push(queueData.outdialID);
        endId = index;
      }
    }

    const callbackData = callBackQueueData.map((queDat) => (
      { ...queDat, screened: 1 }
    ));

    setPsychicCallbackQueue(callbackData);

    setTrigger(false);
    setLoadingState(true);
    await API.Psychic.addCallbackqueue(
      extId,
      outdialIDs,
    );
    const chatStatus = reason === PsychicStatusChannel.PHONE_CHAT.toString();

    updatePsychicSchedule({
      phone: true,
      chat: chatStatus,
      status: 'Available',
      comment: 'Until I clear my queue',
      psychicActivityID: 11,
    });

    setPsychicLoginOutsideLastActivityId(11);

    if (endId === -1) {
      setPsychicLoginOutsideOfScheduleMsg('');
    } else if (endId === 0) {
      setPsychicLoginOutsideOfScheduleMsg(`Until I clear my queue, caller ${endId + 1}`);
    } else {
      setPsychicLoginOutsideOfScheduleMsg(`Until I clear my queue, callers 1 - ${endId + 1}`);
    }
  };

  const backButtonHandler = () => {
    setCallBackPopup(false);
    setChecked(true);
    setCallBackQueueData([...callBackQueueServerData]);
  };

  const handleSliderChange = (val) => {
    if (!(checked && callBackQueueData.length)) {
      setSliderValue(val);
    }
  };

  const callersText = useMemo(() => {
    if (callBackQueueData.length === 1) {
      return `${callBackQueueData.length} caller`;
    }

    return `${callBackQueueData.length} callers`;
  }, [callBackQueueData]);

  if (checked === true && callBackPopup === true) {
    return (
      <CallBackListPopup
        isOpen={checked && callBackPopup && isOpen && psychicLoginOutsidePopupShow}
        rows={callBackQueueData}
        expectedLoginMin={expectedLoginMin}
        updateRowsData={updateRowsData}
        onConfirmLogin={onConfirmLogin}
        backButtonHandler={backButtonHandler}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen && psychicLoginOutsidePopupShow}
      className={style.modal}
      ariaHideApp={false}
      overlayClassName={style.overlay}
    >
      <div className={cn(style.wrapper, style.wrapperWidthBi)}>
        <div className={style.noSchedulePopUp}>
          <div className={style.calendarIconDiv}>
            <img src={CalendarIcon} alt="" className={style.calendarIconDivImage} />
          </div>
          <p className={style.title}>
            {isPsychicLoginOutsideOfScheduleEditMode
              ? 'Are you sure you want to change your estimated time online?'
              : 'How long do you plan on being online?'}
          </p>
          <p className={style.description}>
            {isPsychicLoginOutsideOfScheduleEditMode
              ? (
                <p>Currently logged in as:
                  {psychicLoginOutsideLastActivityId === 11
                    ? (
                      <span className={style.currentlyLoggedInMsg}>
                        {psychicLoginOutsideOfScheduleMsg}
                      </span>
                    )
                    : (
                      <span className={style.currentlyLoggedInMsg}>
                        &nbsp;{`'Until ${getTimeBaseValByselection(sliderValue).durationStr}'`}
                      </span>
                    )}
                </p>
              )
              : `We see that you are logging in outside of your scheduled hours you
                must select an option from below to confirm log in.`}
          </p>
          <hr className={style.dividerLine} />
          <p className={style.secondryTitle}>Time Based</p>
        </div>
        <div className={style.sliderDiv}>
          {sliderValue === -1 && (
            <div
              className={style.sliderNotSelected}
              draggable="true"
              onDrag={() => handleSliderChange(0)}
            />
          )}
          <ReactSlider
            className={style.ReactSlider}
            defaultValue={sliderValue}
            renderTrack={Track}
            min={0}
            max={3}
            marks={1}
            markClassName={style.mark}
            thumbClassName={cn(style.thumb, {
              [style.hide]: sliderValue === -1,
            })}
            trackClassName={cn(style.track, {
              [style.trackDefaultBackground]: sliderValue === -1,
            })}
            onChange={handleSliderChange}
          />

          <div className={cn(style.timeStamp, {
            [style.timeStampBrfore]: sliderValue === -1,
          })}
          >
            <div className={style.timeStampInner}>
              <p>{TimeDuration.SPECIAL_REQUEST}</p>
            </div>
            <div className={style.timeStampInner}>
              <p>{TimeDuration.ONE_TO_TWO}</p>
            </div>
            <div className={style.timeStampInner}>
              <p>{TimeDuration.THREE_TO_FOUR}</p>
            </div>
            <div className={style.timeStampInner}>
              <p>{TimeDuration.FIVE_PLUS}</p>
            </div>
          </div>
        </div>

        <div className={style.queueBased}>
          <h3 className={style.queueBasedHeader}>
            Queue Based
            <span className={style.callbackLength}>
              {(!callBackQueueData.length) ? '(disabled)' : ''}
            </span>
          </h3>

          <div className={style.clearQueueCheck}>
            <input
              type="checkbox"
              id="checkbox_Queue"
              checked={checked && callBackQueueData.length}
              onChange={checkedHandler}
              className={style.queueCheckbox}
              disabled={!callBackQueueData.length}
            />
            <label htmlFor="checkbox_Queue" />
            <p className={style.displayInline}>
              Until I clear my queue
              <p className={style.queueCheckboxTitle}>{
                (!callBackQueueData.length) ? '(No one in Queue)'
                  : (` (${callersText} • ${
                    totalExpectedTimeForAll
                  } minutes)`)
              }
              </p>
            </p>
          </div>
        </div>

        <div className={style.containerModalButtons}>
          <div className={cn(style.containerModalButtonsAccept,
            { [style.buttonEditMode]: isPsychicLoginOutsideOfScheduleEditMode })}
          >
            <InteractChatButton
              className={cn(style.acceptButton, {
                [style.buttonEditMode]: isPsychicLoginOutsideOfScheduleEditMode,
              })}
              isActive={sliderValue !== -1 || (checked && callBackQueueData.length)}
              text="Submit"
              onClick={submitClickHandler}
              size={ButtonSize.S}
            />
            {isPsychicLoginOutsideOfScheduleEditMode && (
              <InteractChatButton
                className={cn(style.cancelButton, {
                  [style.buttonEditMode]: isPsychicLoginOutsideOfScheduleEditMode,
                })}
                isActive="true"
                text="Cancel"
                onClick={cancelHandler}
                size={ButtonSize.S}
              />
            )}
          </div>
        </div>
        {!isPsychicLoginOutsideOfScheduleEditMode
        && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onClick={cancelHandler}
            className={style.cancelLoginButton}
          >
            {' Cancel and don\'t sign in'}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PsychicLoginOutsideSchedule;
