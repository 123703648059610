import { put, call } from 'redux-saga/effects';

import {
  View,
  NotificationsText,
} from 'constants/enums';
import {
  IS_MOBILE_INTEGRATION,
  PSYCHIC_ID_STORAGE,
  TOKEN_TWILIO_STORAGE,
  VIEW,
  CHAT_ID,
  TOKEN_BEARER,
} from 'constants/constants';
import { API } from 'src/utils/api';
import {
  callRetry,
  descriptionFromError,
  checkCustomerCompletedExpiredChat,
} from 'src/utils/commonUtil';
import {
  setLoadingState,
  setView,
  handleRequestError,
  setHistoryModeState,
} from 'actions/appActions';
import {
  setCustomerId,
  setExtId,
  setUpPsychicByLink,
  setCurrentChat,
} from 'actions/chatActions';
import { setAuthToken, setTwilioToken, setBearerToken } from 'actions/authActions';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* handleSetSessionByLinkError(errorMessage: string) {
  if (!errorMessage) {
    return null;
  }

  const errorMessages: string[] = [
    NotificationsText.SET_SESSION_ERROR,
    NotificationsText.USER_WAS_N0T_FOUND,
  ];

  if (errorMessages.includes(errorMessage)) {
    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: errorMessage,
    };
    yield put(handleRequestError(requestErrorPayload));

    return errorMessage;
  }

  return null;
}

export function* startChatByLinkForCustomer({
  payload: {
    view,
    token,
    customerRefId,
    psychicId,
    isHistoryMode,
  },
}: ReturnType<any>) {
  try {
    LocalStorage.setItem(IS_MOBILE_INTEGRATION, 'true');

    if (!view || !token) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    if (!customerRefId) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    yield put(setLoadingState(true));

    if (view === View.CUSTOMER) {
      yield put(setAuthToken(token as string));
      LocalStorage.setItem(PSYCHIC_ID_STORAGE, psychicId);
      yield put(setCustomerId(customerRefId as string));
      yield put(setExtId(psychicId as string));
      yield put(setView(view));

      if (isHistoryMode) {
        yield put(setHistoryModeState(true));
        const chat = yield call(
          checkCustomerCompletedExpiredChat,
          customerRefId as string,
          psychicId as string,
        );

        if (!chat?.chatId || !chat?.status) {
          LocalStorage.removeItem(CHAT_ID);
        } else {
          yield put(setCurrentChat(chat));
        }
      }
    }
  } catch (e) {
    console.log(e);

    const isSetSessionError = yield call(handleSetSessionByLinkError, e?.message);

    if (isSetSessionError) {
      return;
    }

    const isInvalidToken = e?.response?.status === 401 || e?.response?.status === 403;
    yield call(SentryMethods.captureException, e);
    const isPsychicView = view === View.PSYCHIC;
    const requestErrorPayload = {
      redirectPath: isPsychicView ? '/login' : '',
      isInvalidToken,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));

    if (view && view === View.CUSTOMER) {
      goTo(`/conversations?view=${view.toLowerCase()}`);
    }
  }
}

export function* startChatByLinkForPsychic({
  payload: {
    view,
    token,
    psychicId,
    tokenBearer,
  },
}: ReturnType<typeof setUpPsychicByLink>) {
  try {
    LocalStorage.setItem(IS_MOBILE_INTEGRATION, 'true');

    if (!view || !token) {
      throw new Error(NotificationsText.SET_SESSION_ERROR);
    }

    if (!psychicId) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    yield put(setLoadingState(true));
    const twilioResponse = yield call(callRetry, API.Chat.getTwilioToken);
    const twilioToken = twilioResponse?.data?.twilioToken;
    yield put(setAuthToken(token as string));
    yield put(setBearerToken(tokenBearer));
    LocalStorage.setItem(TOKEN_BEARER, tokenBearer);
    yield put(setTwilioToken(twilioToken));
    LocalStorage.setItem(TOKEN_TWILIO_STORAGE, twilioToken);
    yield put(setExtId(psychicId as string));
    yield put(setView(view));
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '/login',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    const isSetSessionError = yield call(handleSetSessionByLinkError, e?.message);

    if (isSetSessionError) {
      return;
    }

    yield put(handleRequestError(requestErrorPayload));
    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
    goTo(`/conversations?${VIEW}=${view.toLowerCase()}`);
  }
}
