import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import { DueToInactivityProps } from 'types/componentTypes';
import { ButtonColor, ButtonSize, ErrorRequestType } from 'constants/enums';
import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';

const DueToInactivity: React.FC<DueToInactivityProps> = ({
  isNotificationPopUp,
  popUpNotificationData,
  currentChat,
  showPopUpNotification,
  deleteNotifications,
  removeChatId,
  setIsLiveChatActive,
  setLoadingState,
  setPopUpNotificationData,
  removePopUpNotification,
  addMixpanelEventAlertTapped,
  setRequestErrorState,
  setActivity,
}) => {
  const isArray = Array.isArray(popUpNotificationData);
  const { title, description } = isArray ? popUpNotificationData[0] : popUpNotificationData;

  const handleAcceptButton = async () => {
    const user = currentChat.sideUser;

    addMixpanelEventAlertTapped('chat inactivity three minute warning', 'i’m still here', user);

    if (isArray) {
      setPopUpNotificationData(popUpNotificationData.pop());
    } else {
      showPopUpNotification(false);
      removePopUpNotification();
    }

    setActivity();
  };

  const handleRejectButton = async () => {
    const user = currentChat.sideUser;

    try {
      addMixpanelEventAlertTapped('chat inactivity three minute warning', 'end chat now', user);
      removePopUpNotification();
      showPopUpNotification(false);
      deleteNotifications();
      setLoadingState(true);
      await API.Chat.endChatRequest(currentChat?.chatId);
      setIsLiveChatActive(false);
      removeChatId();
    } catch (e) {
      console.log(e);
      SentryMethods.captureException(e);
      setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT);
    } finally {
      setLoadingState(false);
    }
  };

  const acceptButton = {
    text: 'I\'m Still Here',
    color: ButtonColor.WARNING,
    handler: handleAcceptButton,
    size: ButtonSize.SM
  };

  const rejectButton = {
    text: 'End Chat Now',
    color: ButtonColor.ONLY_TEXT,
    handler: handleRejectButton,
    size: ButtonSize.SM
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={title}
      description={description}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default DueToInactivity;
