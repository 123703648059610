import React from 'react';
import Modal from 'react-modal';
import { ButtonSize, ButtonColor } from 'extracted-chat-components/enums';
import cn from 'classnames';
import InteractChatButton from 'extracted-chat-components/src/components/InteractChatButton';
import {
  Table,
  TableBody,
} from '@mui/material';

import BackQueue from 'src/assets/png/backQueue.png';

import CallBackCustomerList from './CallBackCustomerList';
import style from './style.module.scss';

const CallBackList = ({
  isOpen,
  rows,
  updateRowsData,
  onConfirmLogin,
  backButtonHandler,
  expectedLoginMin,
}) => (
  <Modal
    isOpen={isOpen}
    className={style.modal}
    ariaHideApp={false}
    overlayClassName={style.overlay}
  >
    <div className={cn(style.wrapper, style.wrapperWidthBi)}>
      <div className={style.noSchedulePopUp}>
        <div className={style.queueContainer}>
          <button type="button" className={style.backButton} onClick={backButtonHandler}>
            <img alt="back button" src={BackQueue} className={style.backimage} />
            <div className={style.back}>Back</div>
          </button>
          <div className={style.flexbox}>
            <p className={style.title}>Queue Based log in, please confirm.</p>
          </div>
        </div>
        <hr className={cn(style.description, style.dividerLine)} />
        <p className={style.description}>
          You have indicated you would like to log in to clear your queue.
          Please select which callers you would like to take:
        </p>
      </div>

      <Table className={style.callBackUserList}>
        <TableBody>
          <CallBackCustomerList rows={rows} updateRowsData={updateRowsData} />
        </TableBody>
      </Table>

      <hr className={cn(style.description, style.dividerLine)} />
      <div className={style.totalLoginTime}>
        <p className={style.titleExpectedLogin}>Expected log in: {expectedLoginMin} min</p>
      </div>

      <div className={style.containerModalButtons}>
        <div className={style.containerModalButtonsAccept}>
          <InteractChatButton
            className={style.acceptButton}
            isActive={rows.length && expectedLoginMin}
            text="Confirm Log In"
            onClick={onConfirmLogin}
            size={ButtonSize.S}
            color={ButtonColor.WARNING}
          />
        </div>
      </div>
    </div>
  </Modal>
);
export default CallBackList;
