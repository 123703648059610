import React from 'react';
import cn from 'classnames';

import TimerContainer from 'components/Timer/TimerContainer';

import styles from './styles.module.scss';

const ConversationButton = ({
  sideUser,
  onClick,
  onPressEnter,
  timeLeft,
  isCurrentChat,
  isLiveChat,
}) => (
  <button
    type="button"
    tabIndex={0}
    className={cn(styles.conversation, {
      [styles.conversationActive]: isCurrentChat,
    })}
    disabled={!sideUser}
    onClick={onClick}
    onKeyDown={onPressEnter}
  >
    {sideUser?.friendlyName ? (
      <>
        <span className={styles.conversationName}>{sideUser.friendlyName}</span>
        <TimerContainer
          isConversation
          isLiveChat={isLiveChat}
          timeLeft={timeLeft}
        />
      </>
    ) : (
      <span>
        {isLiveChat ? 'No active Live Chat' : 'No active Offline Messages'}
      </span>
    )}
  </button>
);

export default ConversationButton;
