import React from 'react';
import cn from 'classnames';
import { Tab, Tabs } from '@mui/material';

import { PNTabs } from 'constants/enums';
import { IListTabs } from 'types/componentTypes';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';

const ListTabs: React.FC<IListTabs> = ({
  TABS,
  currentTab,
  handleTabsChange,
}) => (
  <Tabs
    variant="scrollable"
    scrollButtons={false}
    value={currentTab}
    onChange={handleTabsChange}
    className={styles.tabs}
    sx={{
      '& .Mui-selected': {
        color: '#000000 !important',
      },
    }}
    classes={{
      indicator: cn(styles.tabIndicator,
        {
          [styles.tabIndicatorCustomer]: currentTab === PNTabs.CUSTOMER_LIST_TAB,
          [styles.tabIndicatorPriority]: currentTab === PNTabs.PRIORITY_ACTION_TAB,
        }),
    }}
  >
    {TABS.map((tab) => (
      <Tab
        key={tab.id}
        disableRipple
        value={tab.id}
        label={tab.name}
        className={cn(styles.tabsButton, { [styles.selectedTab]: currentTab === tab.id })}
      />
    ))}
  </Tabs>
);

export default ListTabs;
