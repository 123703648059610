/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useIsMobile } from 'src/hooks/useIsMobile';
import PsychicNotifications from 'components/PsychicNotifications';
import {
  selectActiveScreen,
  selectCurrentPCNTab,
  selectPsychicNoteConfigurableData,
  selectPsychicNotificationDetail,
  selectSendCustomerDetail,
} from 'selectors/psychicNotificationsSelectors';
import {
  updateClientNotes as _updateClientNotes,
  setNotesHistory as _setNotesHistory,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getPsychicAllNotificationsList as _getPsychicAllNotificationsList,
  setActiveScreen as _setActiveScreen,
  getClientNoteHistory as _getClientNoteHistory,
  setCurrentPCNTab as _setCurrentPCNTab,
  getReadingCustomersList as _getReadingCustomersList,
} from 'src/redux/actions/psychicNotifications';
import { setConfirmationScreen as _setConfirmationScreen } from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  IS_MOBILE_INTEGRATION, ONE_MINUTE, PSYCHIC_NOTE_TAB_ID,
} from 'constants/constants';
// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
import PsychicNotificationNotActivePopup from 'components/Popups/PsychicNotificationNotActivePopup';
import ListingViewContainer from 'components/PsychicNotifications/ListingView';
import { LocalStorage } from 'src/utils/storageHandler';
import ClientNotesContainer from 'components/PsychicNotifications/ClientNotes';
import { PNTabs } from 'constants/enums';
import { selectConfirmationScreen } from 'selectors/selectors';
import CreatePsychicNotificationFormContainer from 'containers/CreatePsychicNotificationFormContainer';

const PsychicNotificationsContainer = () => {
  const { isMobile } = useIsMobile();
  const [customerName, setCustomerName] = useState<string>('');
  const psychicNotification = useSelector(selectPsychicNotificationDetail);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail);
  // const currentUser = useSelector(selectCurrentUser);
  const activeScreen = useSelector(selectActiveScreen);
  const currentTab = useSelector(selectCurrentPCNTab);
  const isConfirmationScreenVisible = useSelector(selectConfirmationScreen);
  const {
    psychicNotificationRefreshEveryMins,
    psychicGamifyFeatureEnabled: isGamifyEnabled,
  } = useSelector(selectPsychicNoteConfigurableData);

  const updateClientNotes = useBindDispatch(_updateClientNotes);
  const setNotesHistory = useBindDispatch(_setNotesHistory);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const getPsychicAllNotificationsList = useBindDispatch(_getPsychicAllNotificationsList);
  const setActiveScreen = useBindDispatch(_setActiveScreen);
  const setConfirmationScreen = useBindDispatch(_setConfirmationScreen);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setCurrentPCNTab = useBindDispatch(_setCurrentPCNTab);
  const getReadingCustomersList = useBindDispatch(_getReadingCustomersList);

  const {
    isNotificationActive,
    notificationList,
    customerList,
    priorityActionList,
    notificationStatistics,
  } = psychicNotification;

  const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);
  const isAllNotesScreen = activeScreen === PSYCHIC_NOTE_TAB_ID.ALL_NOTE;
  const isBottomCTAVisible = activeScreen === PSYCHIC_NOTE_TAB_ID.ALL_NOTE && isMobile
                      && !(priorityActionList.length && currentTab === PNTabs.PRIORITY_ACTION_TAB);

  useEffect(() => {
    if (isNotificationActive && isMobileIntegration) {
      const refreshIntervalId = setInterval(async () => {
        getPsychicAllNotificationsList(false);
      }, (ONE_MINUTE * psychicNotificationRefreshEveryMins));

      return () => clearInterval(refreshIntervalId);
    }
  }, [
    getPsychicAllNotificationsList,
    isMobileIntegration,
    isNotificationActive,
    psychicNotificationRefreshEveryMins,
  ]);

  if (!isNotificationActive) {
    return <PsychicNotificationNotActivePopup />;
  }

  const handlePsychicNoteScreens = (e) => {
    e.preventDefault();
    let { id } = e.currentTarget;

    if (id === PSYCHIC_NOTE_TAB_ID.ALL_NOTE) {
      updateClientNotes([]);
      setNotesHistory({});
      setNotificationCustomerDetail(null);

      if (currentTab === PNTabs.ALL_NOTES_TAB) {
        getPsychicAllNotificationsList();
      }
    } else if (id === PSYCHIC_NOTE_TAB_ID.COMPOSE
      && sendCustomerDetail?.length === 1) {
      getClientNoteHistory(sendCustomerDetail[0]?.pCComID || 0);
      id = PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION;
    }

    if (currentTab === PNTabs.CUSTOMER_LIST_TAB) {
      getReadingCustomersList();
    }

    setActiveScreen(id);

    if (id === PSYCHIC_NOTE_TAB_ID.COMPOSE) {
      // addPsychicMixpanelEventButtonTapped({
      //   button_text: 'send a note',
      //   button_type: 'button',
      //   screen_title: 'psychic notifications',
      //   platform: 'psychic desktop app',
      //   ...getPsychicNotificationInfoMixpanel(currentUser),
      // });
    }
  };

  const handleReturnButton = (e, isPriorityTab = false) => {
    setConfirmationScreen(false);
    handlePsychicNoteScreens(e);

    if (isPriorityTab) {
      setCurrentPCNTab(PNTabs.PRIORITY_ACTION_TAB);
    } else if (currentTab === PNTabs.ALL_NOTES_TAB) {
      getPsychicAllNotificationsList();
    }
  };

  const handleComposeNewButton = () => {
    setConfirmationScreen(false);
  };

  return (
    <PsychicNotifications
      notificationStatistics={notificationStatistics}
      activeScreen={activeScreen}
      sendCustomerDetail={sendCustomerDetail}
      handlePsychicNoteScreens={handlePsychicNoteScreens}
      isMobile={isMobile}
      isGamifyEnabled={isGamifyEnabled}
      isBottomCTAVisible={isBottomCTAVisible}
      customerName={customerName}
      handleReturnButton={handleReturnButton}
      handleComposeNewButton={handleComposeNewButton}
      isConfirmationScreenVisible={isConfirmationScreenVisible}
      nextPriorityAction={priorityActionList[0]}
      isAllNotesScreen={isAllNotesScreen}
    >
      <CreatePsychicNotificationFormContainer
        key={PSYCHIC_NOTE_TAB_ID.COMPOSE}
        customerList={customerList}
        handlePsychicNoteScreens={handlePsychicNoteScreens}
        setCustomerName={setCustomerName}
      />
      <ClientNotesContainer
        handlePsychicNoteScreens={handlePsychicNoteScreens}
        nextPriorityAction={priorityActionList[0]}
        setCustomerName={setCustomerName}
        key={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
      />
      <ListingViewContainer
        key={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
        customerList={customerList}
        notificationList={notificationList}
        priorityActionList={priorityActionList}
        handlePsychicNoteScreens={handlePsychicNoteScreens}
      />
    </PsychicNotifications>
  );
};

export default PsychicNotificationsContainer;
