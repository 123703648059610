import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react';
import DeclineOfflineMessagePopUp from 'extracted-chat-components/src/components/DeclineOM';
import { useSelector } from 'react-redux';

import { selectChatId, selectCurrentUser } from 'selectors/selectors';
import { IAppState } from 'store';
import { setDeclineOMReason } from 'src/utils/commonUtil';
import { checkPhoneOrEmailInMessageWithoutHotlineNumber } from 'src/utils/messageHandler';
import {
  declineOfflineMessage as _declineOfflineMessage,
} from 'actions/offlineChatActions';
import {
  removePopUpNotification as _removePopUpNotification,
  showPopUpNotification as _showPopUpNotification,
} from 'actions/appActions';
import {
  DECLINE_MESSAGE_LENGTH,
} from 'constants/constants';
import SendSuccessfully from 'src/assets/png/declineOMsuccess.png';
import {
  Message,
} from 'constants/enums';
import { DECLINE_OM_OPTIONS } from 'constants/complexConstants';
import { DeclineReason } from 'types/objectTypes';
import useBindDispatch from 'src/hooks/useBindDispatch';

import 'react-dropdown/style.css';

const DeclineOfflineMessagePopUpContainer = () => {
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [activeNoteText, setActiveNoteText] = useState<boolean>(false);
  const [declineBlockFullHeight, setDeclineBlockFullHeight] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<DeclineReason | string>('');
  const [count, setCount] = useState<number>(DECLINE_MESSAGE_LENGTH);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const declineMessageSample = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);

  const targetWord = checkPhoneOrEmailInMessageWithoutHotlineNumber(message);

  const currentUser = useSelector(selectCurrentUser);

  const chatId = useSelector(selectChatId);
  const removePopUpNotification = useBindDispatch(_removePopUpNotification);
  const declineOfflineMessage = useBindDispatch(_declineOfflineMessage);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const isVisibleNotification = useSelector(
    (state: IAppState) => state.app.isNotificationPopUp,
  );

  useEffect(() => {
    if (selectValue?.value) {
      setDeclineOMReason(selectValue?.value, setReason);
    }
  }, [selectValue]);

  useEffect(() => {
    setErrorMessage(!!targetWord);
  }, [targetWord]);

  const handleSelect = (value) => {
    setDeclineBlockFullHeight(true);
    setSelectValue(value);
  };

  const filterMessage = useMemo(() => (message) => {
    if (!targetWord?.[0]) {
      return message;
    }

    const regex = new RegExp(`(${targetWord?.[0]})`, 'gi');

    return message
      .split(regex)
      .map((item) => (item === targetWord?.[0]
        ? `<span style="color: red">${item}</span>`
        : item))
      .join('');
  }, [targetWord]);

  useEffect(() => {
    const img = new Image();
    img.src = SendSuccessfully;
  }, []);

  const handleDeclineButton = async () => {
    setLoading(true);

    const data = {
      chatRequestId: chatId,
      canceledBy: Message.PSYCHIC,
      reason,
      note: message.replace(/\s{2,}/g, ' ').trim(),
    };

    const callbacks = [
      () => setIsSent(true),
      () => setMessage(''),
      () => setSelectValue(''),
      () => setLoading(false),
    ];

    declineOfflineMessage({ data, callbacks });
  };

  const handleCancelButton = useCallback(() => {
    setDeclineBlockFullHeight(false);
    setSelectValue('');
    showPopUpNotification(false);
    removePopUpNotification();
    setIsSent(false);
    setActiveNoteText(false);
    setMessage('');
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current && !modalRef.current.contains(event.target)
      ) {
        handleCancelButton();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isVisibleNotification, isSent, handleCancelButton]);

  const handlePaste = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value.substring(0, DECLINE_MESSAGE_LENGTH));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const charLeft = DECLINE_MESSAGE_LENGTH - e.target.value.length;

    if (charLeft < 0) {
      setCount(0);
    } else {
      setCount(charLeft);
    }

    setMessage(e.target.value.substring(0, DECLINE_MESSAGE_LENGTH));
  };

  const toggleActiveNoteText = () => setActiveNoteText((prev) => !prev);

  return (
    <DeclineOfflineMessagePopUp
      name={currentUser?.lineName || ''}
      modalRef={modalRef}
      isSent={isSent}
      isVisibleNotification={isVisibleNotification}
      options={DECLINE_OM_OPTIONS}
      handleSelect={handleSelect}
      selectValue={selectValue}
      handleCancelButton={handleCancelButton}
      toggleActiveNoteText={toggleActiveNoteText}
      handleChange={handleChange}
      count={count}
      declineMessageSample={declineMessageSample}
      reason={reason}
      filterMessage={filterMessage}
      activeNoteText={activeNoteText}
      setActiveNoteText={setActiveNoteText}
      onPaste={handlePaste}
      handleDeclineButton={handleDeclineButton}
      errorMessage={errorMessage}
      message={message}
      isLoading={isLoading}
      SendSuccessfully={SendSuccessfully}
      declineBlockFullHeight={declineBlockFullHeight}
      backdropRef={backdropRef}
    />
  );
};

export default DeclineOfflineMessagePopUpContainer;
