/* eslint-disable max-classes-per-file */

import {
  DRAFTED_MESSAGE,
  TOKEN_AUTH_STORAGE,
  TOKEN_TWILIO_STORAGE,
  CHAT_ID_STORAGE,
  PSYCHIC_ID_STORAGE,
  CUSTOMER_ID_STORAGE,
} from 'constants/constants';

const checkIsStorageAccessible = () => {
  try {
    // eslint-disable-next-line no-unused-expressions
    window.localStorage;

    return true;
  } catch (e) {
    return false;
  }
};

class LocalStorageImpl implements Storage {
  messageEvent: MessageEvent;

  prefix: string = ''

  constructor(messageEvent: MessageEvent, prefix: string) {
    this.messageEvent = messageEvent;
    this.prefix = prefix;
  }

  get length() {
    return Object.keys(this).length;
  }

  getItem(key: string) {
    return this[key];
  }

  key(index: number) {
    const keys = Object.keys(this);

    return keys[index];
  }

  setItem(key: string, value: string) {
    if (!this.messageEvent) return;

    const keyWithPrefix = `${this.prefix}${key}`;
    const data = { command: 'setItem', payload: { key: keyWithPrefix, value } };
    (<Window>(this.messageEvent).source)?.postMessage(data, this.messageEvent.origin);
    this[key] = value;
  }

  clear() {
    if (!this.messageEvent) return;

    const entries = Object.entries(this);
    const removedItems: Array<{key: string, value: string}> = [];
    entries.forEach(([key]) => {
      if (key !== 'length' && key !== 'messageEvent' && key !== 'prefix') {
        const keyWithPrefix = `${this.prefix}${key}`;
        removedItems.push({ key: keyWithPrefix, value: this[key] });
        delete this[key];
      }
    });
    const data = { command: 'clear', payload: removedItems };
    (<Window>(this.messageEvent).source)?.postMessage(data, this.messageEvent.origin);
  }

  removeItem(key: string) {
    if (!this.messageEvent) return;

    const keyWithPrefix = `${this.prefix}${key}`;

    const data = { command: 'removeItem', payload: { key: keyWithPrefix } };
    (<Window>(this.messageEvent).source)?.postMessage(data, this.messageEvent.origin);
    delete this[key];
  }
}

class LocalStorageWrapper {
  handler: Storage | undefined;

  constructor(handler?: Storage) {
    if (handler) this.handler = handler;
  }

  setHandler(handler: Storage) {
    this.handler = handler;
  }

  setUpStorage(storage, parent: MessageEvent) {
    if (checkIsStorageAccessible()) return;

    this.handler = new LocalStorageImpl(parent, storage.prefix);

    const storageItems: Array<{key: string, value: string}> = storage.items;

    if (storageItems && storageItems.length > 0) {
      storageItems.forEach(({ key, value }) => {
        const keyWithoutPrefix = key.replace(storage.prefix, '');

        if (this.handler) this.handler[keyWithoutPrefix] = value;
      });
    }
  }

  getItem(key: string) {
    return this.handler?.getItem(key);
  }

  getItems(itemKeys: Array<string>) {
    const items = itemKeys.reduce((accumulator, current) => {
      const item = this.handler?.getItem(current);
      // @ts-ignore
      accumulator.push(item);

      return accumulator;
    }, []);

    return items;
  }

  setItem(key: string, value: string) {
    this.handler?.setItem(key, value);
  }

  setItems(itemEntries: Array<{key: string, value: string}>) {
    itemEntries.forEach(({ key, value }) => {
      this.handler?.setItem(key, value);
    });
  }

  removeItem(key: string) {
    this.handler?.removeItem(key);
  }

  removeItems(itemKeys: Array<string>) {
    itemKeys.forEach((key) => {
      this.handler?.removeItem(key);
    });
  }

  clear() {
    this.handler?.clear();
  }
}

const storageImplementation = (): Storage | undefined => {
  if (checkIsStorageAccessible()) return localStorage;
};

export const LocalStorage = new LocalStorageWrapper(storageImplementation());

export const getStorageItem = (key: string): string | undefined | null => LocalStorage.getItem(key);

export const setStorageItem = (key: string, value: string|boolean) => {
  LocalStorage.setItem(key, value.toString());
};

export const removeStorageItem = (key: string) => {
  LocalStorage.removeItem(key);
};

export const removeLastMessages = (): void => {
  LocalStorage.removeItem(DRAFTED_MESSAGE);
};

export const clearSessionData = () => {
  LocalStorage.removeItems([
    TOKEN_AUTH_STORAGE,
    TOKEN_TWILIO_STORAGE,
    CHAT_ID_STORAGE,
    PSYCHIC_ID_STORAGE,
    CUSTOMER_ID_STORAGE,
  ]);
};

export const clearSessionSdk = () => {
  LocalStorage.removeItems([
    TOKEN_AUTH_STORAGE,
  ]);
};
