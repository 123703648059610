import { View } from 'constants/enums';
import * as types from 'actions/actionsTypes';

export const loginRequest = () => ({
  type: types.LOGIN_START,
});

export const loginSuccess = (payload) => ({
  type: types.LOGIN_START,
  payload,
});

export const setTwilioToken = (token: string) => ({
  type: types.SET_TWILIO_TOKEN,
  payload: token,
});

export const setAuthToken = (token: string) => ({
  type: types.SET_AUTH_TOKEN,
  payload: token,
});

export const receiveAuthData = (username: string, password: string, view: View) => ({
  type: types.RECEIVE_AUTH_DATA,
  payload: { username, password, view },
});

export const receiveSdkAuthData = (
  tokenAuth: string,
  tokenBearer: string,
  refreshToken: string,
  userId: string,
  sessionId: string,
) => ({
  type: types.RECEIVE_SDK_AUTH_DATA,
  payload: {
    tokenAuth,
    tokenBearer,
    refreshToken,
    userId,
    sessionId,
  },
});

export const setBearerToken = (token: string) => ({
  type: types.SET_BEARER_TOKEN,
  payload: token,
});

export const clearSession = () => ({
  type: types.CLEAR_SESSION,
});

export const logout = () => ({
  type: types.LOGOUT,
});
