import aries from 'src/assets/svg/zodiac/icon-aries.svg';
import taurus from 'src/assets/svg/zodiac/icon-taurus.svg';
import gemini from 'src/assets/svg/zodiac/icon-gemini.svg';
import cancer from 'src/assets/svg/zodiac/icon-cancer.svg';
import leo from 'src/assets/svg/zodiac/icon-leo.svg';
import virgo from 'src/assets/svg/zodiac/icon-virgo.svg';
import libra from 'src/assets/svg/zodiac/icon-libra.svg';
import scorpio from 'src/assets/svg/zodiac/icon-scorpio.svg';
import sagittarius from 'src/assets/svg/zodiac/icon-sagittarius.svg';
import capricorn from 'src/assets/svg/zodiac/icon-capricorn.svg';
import aquarius from 'src/assets/svg/zodiac/icon-aquarius.svg';
import pisces from 'src/assets/svg/zodiac/icon-pisces.svg';

export const zodiacMap = new Map<string, any>();
zodiacMap.set('aries', aries);
zodiacMap.set('taurus', taurus);
zodiacMap.set('gemini', gemini);
zodiacMap.set('cancer', cancer);
zodiacMap.set('leo', leo);
zodiacMap.set('virgo', virgo);
zodiacMap.set('libra', libra);
zodiacMap.set('scorpio', scorpio);
zodiacMap.set('sagittarius', sagittarius);
zodiacMap.set('capricorn', capricorn);
zodiacMap.set('aquarius', aquarius);
zodiacMap.set('pisces', pisces);
