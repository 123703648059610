import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DiagnosticPage } from 'extracted-chat-components';
import {
  InternetConnectionEvents,
  DiagnosticListType,
} from 'extracted-chat-components/enums';

import logoLoaderSun from 'assets/png/loaderSun.png';
import { IAppState } from 'store';
import {
  ActiveChatDetail,
  DiagnosticList,
  DiagnosticTokenList,
  DiagnosticStatusList,
} from 'types/componentTypes';
import {
  setIsDiagnosticPopupOpen as _setIsDiagnosticPopupOpen,
} from 'actions/diagnosticAction';
import {
  getDate,
  formatToUSTime,
  calculateDateDiffSeconds,
  formatTokenTimeStampToDate,
  formatDateTimeIn2Digit,
} from 'src/utils/dateHandler';
import { parseToken } from 'src/utils/authHandler';
import {
  selectAuthToken,
  selectChatId,
  selectInternetConnectionStatus,
  selectTwilioToken,
} from 'selectors/selectors';
import { selectIsDiagnosticPopupOpen } from 'selectors/diagnosticSelector';
import useBindDispatch from 'src/hooks/useBindDispatch';

const DiagnosticPopup = () => {
  const setIsDiagnosticPopupOpen = useBindDispatch(_setIsDiagnosticPopupOpen);
  const authToken = useSelector(selectAuthToken);
  const twilioToken = useSelector(selectTwilioToken);
  const isPopupOpen = useSelector(selectIsDiagnosticPopupOpen);
  const internetConnection = useSelector(selectInternetConnectionStatus);
  const currentChatId = useSelector(selectChatId);
  const customerRefIdEnc = useSelector(
    (state: IAppState) => state.chat.currentChat?.customerRefIdEnc,
  );
  const chatAcceptedDate = useSelector(
    (state: IAppState) => state.chat.currentChat?.acceptedDate,
  );

  const [diagnosticLists, setDiagnosticList] = useState<Array<DiagnosticList>>([]);
  const [twilioExpireDate, setTwilioExpireDate] = useState<string>('');
  const [authExpireDate, setAuthExpireDate] = useState<string>('');
  const [twilioCreateDate, setTwilioCreateDate] = useState<string>('');
  const [authCreateDate, setAuthCreateDate] = useState<string>('');

  let activeChatDetail: ActiveChatDetail = {
    chatStatus: 'None',
    currentChatId: 'N/A',
    customerRefIdEnc: 'N/A',
    chatAcceptedDate: 'N/A',
  };

  if (currentChatId && customerRefIdEnc && chatAcceptedDate) {
    activeChatDetail = {
      chatStatus: 'In Progress',
      currentChatId,
      customerRefIdEnc,
      chatAcceptedDate: `${getDate(chatAcceptedDate)} at ${formatToUSTime(chatAcceptedDate)}`,
    };
  }

  const decodeTokens = async () => {
    let authDetails: any;
    let twilioDetails: any;

    if (authToken !== '' && twilioToken !== '') {
      authDetails = await parseToken(authToken);
      twilioDetails = await parseToken(twilioToken);

      setAuthExpireDate(formatTokenTimeStampToDate(authDetails.exp));
      setAuthCreateDate(formatTokenTimeStampToDate(authDetails.iat));
      setTwilioExpireDate(formatTokenTimeStampToDate(twilioDetails.exp));
      setTwilioCreateDate(formatTokenTimeStampToDate(twilioDetails.iat));
    }
  };

  useEffect(() => {
    decodeTokens();
  }, [authToken, twilioToken]);

  const runDiagnosticHandler = () => {
    const authTokenDetail: DiagnosticTokenList = {
      label: 'Chat Access Token:',
      type: DiagnosticListType.TOKEN,
      createDate: formatDateTimeIn2Digit(authCreateDate),
      expireDate: formatDateTimeIn2Digit(authExpireDate),
      isValid: calculateDateDiffSeconds(
        new Date(authExpireDate),
        new Date(),
      ) > 0,
    };

    const twilioTokenDetail: DiagnosticTokenList = {
      label: 'Twilio Access Token:',
      type: DiagnosticListType.TOKEN,
      createDate: formatDateTimeIn2Digit(twilioCreateDate),
      expireDate: formatDateTimeIn2Digit(twilioExpireDate),
      isValid: calculateDateDiffSeconds(
        new Date(twilioExpireDate),
        new Date(),
      ) > 0,
    };

    const isInternetOnline = internetConnection === InternetConnectionEvents.ONLINE;
    const internetStatus: DiagnosticStatusList = {
      label: 'Internet PsychicStatus:',
      type: DiagnosticListType.STATUS,
      status: isInternetOnline ? 'Connected' : 'Not Connected',
      isValid: isInternetOnline,
    };

    setDiagnosticList([
      internetStatus,
      authTokenDetail,
      twilioTokenDetail,
    ]);
  };

  useEffect(() => {
    if (isPopupOpen) {
      runDiagnosticHandler();
    }
  }, [isPopupOpen]);

  const handlePopupCloseEvent = () => {
    setIsDiagnosticPopupOpen(false);
  };

  const checkIsAllDiagnosticOk = (
    diagnosticLists: Array<DiagnosticList>,
  ): boolean => diagnosticLists.every((
    diagnosticList: DiagnosticList,
  ) => diagnosticList.isValid);

  return (
    <DiagnosticPage
      isOpen={isPopupOpen}
      logoImage={logoLoaderSun}
      toggleModal={handlePopupCloseEvent}
      diagnosticDate={formatDateTimeIn2Digit(new Date(), true)}
      chatInfo={activeChatDetail}
      diagnosticLists={diagnosticLists}
      isDiagnosticAllOk={checkIsAllDiagnosticOk(diagnosticLists)}
      refreshHandler={runDiagnosticHandler}
    />
  );
};

export default DiagnosticPopup;
