import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { TOKEN_BEARER } from 'extracted-chat-components/constants';

import { AddFundsPayload } from 'types/objectTypes';
import {
  ConversationType,
  PopUpNotificationType,
  AutoReloadLocation,
  AutoReloadSourcePlatform,
} from 'constants/enums';
import {
  CONTENT_TYPE_JSON,
  TOKEN_AUTH_STORAGE,
} from 'constants/constants';
import store from 'store';
import {
  setLoadingState,
  setPopUpNotificationData,
  showPopUpNotification,
} from 'actions/appActions';
import {
  logout,
} from 'actions/authActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { validateBearer } from 'src/utils/authHandler';
import { logWithStyles } from 'src/utils/commonUtil';

const checkErrorStatus = (error) => {
  const status = error?.response?.status;

  logWithStyles(`The error status: ${status}`);
};

const instance = axios.create({
  baseURL: process.env.CHAT_API,
});

const instanceChat2 = axios.create({
  baseURL: process.env.CHAT2_API,
});

const psychicInstance = axios.create({
  baseURL: process.env.PSYCHIC_API,
});

const californiaPsychicsInstance = axios.create({
  baseURL: process.env.CALIFORNIA_PSYCHICS_API,
});

const mobileApiInstance = axios.create({
  baseURL: process.env.CALIFORNIA_PSYCHICS_API,
});

const chatInterceptorConfig = (config) => {
  const newConfig = config;
  const token = LocalStorage.getItem(TOKEN_AUTH_STORAGE);

  newConfig.headers.common.Authorization = token ? `Bearer ${token}` : null;
  newConfig.headers['X-Session-Guid'] = uuidv4();

  return newConfig;
};

psychicInstance.interceptors.request.use(async (config) => {
  const newConfig = config;
  const token = LocalStorage.getItem(TOKEN_BEARER);

  newConfig.headers.common.Authorization = token ? `Bearer ${token}` : null;

  return newConfig;
}, (error) => Promise.reject(error));

psychicInstance.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;

  checkErrorStatus(error);

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    const refreshedToken = await validateBearer('', true);

    originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;

    return psychicInstance(originalRequest);
  }

  return Promise.reject(error);
});

mobileApiInstance.interceptors.request.use(async (config) => {
  const newConfig = config;
  const token = LocalStorage.getItem(TOKEN_BEARER);
  newConfig.headers.common.Authorization = token
    ? `${token.length > 20 ? 'Bearer' : 'Token'} ${token}`
    : null;

  return newConfig;
}, (error) => Promise.reject(error));
mobileApiInstance.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  checkErrorStatus(error);

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshedToken = await validateBearer('', true);
    originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;

    return mobileApiInstance(originalRequest);
  }

  return Promise.reject(error);
});

instance.interceptors.request.use(chatInterceptorConfig, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(showPopUpNotification(true));
      store.dispatch(setPopUpNotificationData({
        title: 'Your session has expired.',
        notificationType: PopUpNotificationType.EXPIRED_TOKEN,
      }));
      store.dispatch(setLoadingState(false));
    }

    return Promise.reject(error);
  });

instanceChat2.interceptors.request.use(chatInterceptorConfig, (error) => Promise.reject(error));
instanceChat2.interceptors.response.use((response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(showPopUpNotification(true));
      store.dispatch(setPopUpNotificationData({
        title: 'Your session has expired.',
        notificationType: PopUpNotificationType.EXPIRED_TOKEN,
      }));
      store.dispatch(setLoadingState(false));
    }

    return Promise.reject(error);
  });

export const API = {
  App: {
    getVersionDetail: () => psychicInstance
      .get('psychic/version', {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getChatVersionDetail: () => californiaPsychicsInstance
      .get('chat/version', {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),
  },

  Chat: {
    createChatRequest: (payload: object) => instance
      .post('chats', payload)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    acceptChatRequest: (chatId: string, platform: string, timePassed?: number) => instance
      .put(`chats/${chatId}/reservation/accept`, {
        seconds: timePassed,
        platform,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    endChatRequest: (chatId: string) => instance
      .put(`chats/${chatId}/complete`, null, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    startChatRequest: (chatId: string) => instance
      .put(`chats/${chatId}/start`, null, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getChatById: (chatId: string) => instance
      .get(`chats/${chatId}`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getChatByIdLight: (chatId: string) => instance
      .get(`chats/${chatId}/light`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    setActiveChat: (chatId: string, view: string) => instance
      .put(`chats/${chatId}/active`, {
        participantType: view,
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    cancelChat: (chatId: string | number) => instance
      .put(`chats/${chatId}/cancel`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    sendOfflineMessage: (
      chatId: string,
      message: string,
    ) => instance
      .post(`chats/${chatId}/direct-messages`, { message }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    switchToPhone: (chatId: string) => instance
      .put(`chats/${chatId}/switch-to-phone`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    phoneCallback: ({ customerRefId, customerRefIdEnc, ...body }) => instance
      .post('chat/phone-callback', {
        customerIdEnc: customerRefIdEnc,
        customerId: customerRefId,
        ...body,
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    openDM: (chatId: string | number) => instance
      .put(`chats/${chatId}/direct-messages/open`)
      .then((res) => res.data)
      .catch((e) => {
        if (e?.response?.status === 409) return;

        throw e;
      }),

    validateSwitchToPhone: (chatId: string | number) => instance
      .put(`chats/${chatId}/switch-to-phone/validate`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    validatePhoneCallback: (data) => instance
      .post('chat/phone-callback/validate', data)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getTwilioToken: () => instance
      .post('twilio/token')
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    addFunds: (chatId, payload: AddFundsPayload) => instance
      .put(`chats/${chatId}/add-funds`, payload)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    validateUserMessage: (
      chatData: any,
      message: string,
    ) => instance
      .put('chats/message/mask', {
        chat: {
          ChatId: chatData.chatId,
          ExternalReferenceId: chatData.psychicRefId,
          CustomerRefIdEnc: chatData.customerRefIdEnc,
        },
        message,
        participantType: chatData.participantType,
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    declineMessage: ({
      chatRequestId, canceledBy, reason, note,
    }) => instance
      .post('/direct-messages/cancel', {
        chatRequestId, canceledBy, reason, note,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    updatePsychicScheduleStatus: (extId: string, payload) => instance
      .put(`psychics/${extId}/updatestatus`, {
        ...payload,
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    muteChatRequest: (chatId) => instance
      .post(`/chats/${chatId}/mute-chime`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),
  },

  Customer: {
    enterCallbackQueue: ({
      chatId,
      customerRefId,
      customerRefIdEnc,
      psychicId,
      phoneNumber,
      countryCallingCode,
      countryCode,
    }) => instance
      .post(`/chats/${chatId}/switch-to-phone-callback`, {
        customerIdEnc: customerRefIdEnc,
        customerId: customerRefId,
        psychicId,
        phoneNumber,
        countryCallingCode,
        countryCode,
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    validatePhoneNumber: ({
      phoneNumber,
      countryCallingCode,
      countryCode,
      customerRefIdEnc,
    }) => instance
      .post(`customers/${customerRefIdEnc}/phone/validate`, {
        phoneNumber,
        countryCallingCode,
        countryCode,
      }, { headers: { ...CONTENT_TYPE_JSON } })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    addPhoneNumber: ({
      phoneNumber,
      countryCallingCode,
      countryCode,
      customerRefIdEnc,
      isPrimaryNumber,
    }) => instance
      .post(`customers/${customerRefIdEnc}/phone/save`, {
        phoneNumber,
        countryCallingCode,
        countryCode,
        isPrimaryNumber,
      }, { headers: { ...CONTENT_TYPE_JSON } })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerSystemChannel: (customerRefIdEnc: string) => instance
      .get('system-channel', {
        params: { customerRefIdEnc },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerPhones: (customerRefIdEnc: string, psychicRefId) => instance
      .get(`customers/${customerRefIdEnc}/switchtophone/${psychicRefId}`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerById: (customerRefIdEnc: string) => instance
      .get(`customers/${customerRefIdEnc}`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => {
        if (res?.data.statusCode !== 200) {
          throw new Error(
            res.data?.data?.message
              ? res.data.data.message
              : 'User details not fetched!',
          );
        }

        return res.data;
      })
      .catch((e) => { throw e; }),

    getCustomerActiveChat: (customerId: string) => instance
      .get(`customers/${customerId}/chats?type=RealTime&active=true`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerCompletedExpiredChat: (customerId: string, extId: string) => instance
      .get(`customers/${customerId}/chats?psychicRefId=${extId}&statuses=completed,expired`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerJointChat: (
      customerId: string,
      extId: string,
    ) => instance
      .put(`customers/${customerId}/chats`, null, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { psychicRefId: extId, active: true },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getChatActiveCustomer: (
      customerId: string,
    ) => instance
      .get(`/customers/${customerId}/chats?active=true`)
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    topUpBalance:
    ({ custId, amount }: { amount: number; custId: string | number }) => californiaPsychicsInstance
      .post('Chat/Charge/Onetime', {
        custId,
        amount,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),

    autoReloadEnable: (
      customerIdEnc: string,
      autoReloadEnable: boolean,
      location: AutoReloadLocation,
      sourcePlatform: AutoReloadSourcePlatform,
    ) => instanceChat2
      .put(`/customer/${customerIdEnc}/auto-reload`, {
        autoReloadEnable,
        location,
        sourcePlatform,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCustomerNotificationList: (CustomerId: string) => mobileApiInstance
      .get('customers/psychicnotifications/recent', {
        headers: { ...CONTENT_TYPE_JSON },
        params: {
          CustomerId,
        },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getReadingPsychicList: (CustomerId: string) => mobileApiInstance
      .get('customers/psychicnotifications/psychiclist', {
        headers: { ...CONTENT_TYPE_JSON },
        params: {
          CustomerId,
        },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    sendNotesToPsychic: (payload) => mobileApiInstance
      .post('customers/psychicnotifications/send', {
        ...payload,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),

    updateCustomerNotification: (payload) => instanceChat2
      .post('customers/update-customer-notification', {
        ...payload,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),

    mutePsychic: (payload) => mobileApiInstance
      .post('customers/psychicnotifications/alerts/update', {
        ...payload,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),

    getCustomerInfo: (payload) => mobileApiInstance
      .post(`/communication/customers/${payload.customerRefId}/info`, {
        task: payload,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),
  },

  Psychic: {
    setConfigs: (data) => instance
      .put('reminders/set-config', data)
      .then((resp) => resp.data)
      .catch((e) => { throw e; }),

    getConfigs: () => instance
      .get('reminder/config') // TODO
      .then((resp) => resp.data)
      .catch((e) => { throw e; }),

    logout: (sessionId: string) => psychicInstance
      .post('/auth/logout', {}, {
        headers: {
          Authorization: `Token ${sessionId}`,
        },
      })
      .then((resp) => resp.data)
      .catch((e) => { throw e; }),

    getPsychicSystemChannel: (psychicRefId: string) => instance
      .get('system-channel', {
        params: { psychicRefId },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychic: (
      psychicRefId: string,
      customerId?: string,
    ) => instance
      .get(`psychics/${psychicRefId}`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { customerRefIdEnc: customerId },
      })
      .then((res) => {
        if (res?.data.statusCode !== 200) {
          throw new Error(
            res.data?.data?.message
              ? res.data.data.message
              : 'Psychic details not fetched!',
          );
        }

        return res.data;
      })
      .catch((e) => {
        if (e?.response?.data?.error?.code === '4004') {
          store.dispatch(logout());

          return;
        }

        throw e;
      }),

    getPsychicLite: (
      psychicRefId: string,
      customerId?: string,
    ) => instance
      .get(`psychics/${psychicRefId}/light`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { customerRefIdEnc: customerId },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicJointChats: (
      extId: string,
      customerRefIdEnc?: string,
    ) => instance
      .get(`psychics/${extId}/chats`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { customerRefIdEnc, active: true },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    fetchOfflineChats: (
      extId: string,
    ) => instance
      .get(`psychics/${extId}/chats`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { active: true, type: ConversationType.DIRECT_MESSAGE },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicActiveLiveChat: (extId: string) => instance
      .get(`psychics/${extId}/chats`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { active: true, type: ConversationType.LIVE_CHAT, size: 1 },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicActiveChats: (extId: string) => instance
      .get(`psychics/${extId}/chats`, {
        headers: { ...CONTENT_TYPE_JSON },
        params: { active: true },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicInfo: (ExtId: number) => psychicInstance
      .get('psychic/dashboard', {
        headers: { ...CONTENT_TYPE_JSON },
        params: {
          ExtId,
          ReturnCurrentStatus: true,
        },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    updatePsychicStatus: (extId: number, action: string, application: string) => psychicInstance
      .post('psychic/updatestatus', {
        extId,
        action,
        application,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getCallbackqueue: (extId: string) => psychicInstance
      .get(`/psychic/callbackqueue/${extId}`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getDashboardDetails: (extId: string) => psychicInstance
      .get(`/psychic/dashboard?extId=${extId}&returnCurrentStatus=false`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getSchedule: (extId: string, date: string) => psychicInstance
      .get(`/psychic/schedules?ExtId=${extId}&WeekStartDate=${date}&SchedulefrequencyType=daily&ScheduleType=All&ApplicationSource=318`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    addCallbackqueue: (extId: string, autDialIds: Array<any>) => psychicInstance
      .post('/psychic/addcallbackqueue/', {
        extId, outDialIds: autDialIds.join(','),
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    updateOfflineMessagesStatus: (extId: string, isOnline: boolean) => psychicInstance
      .post('messages/toggle', {
        extId,
        isMessagesEnabled: isOnline,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    checkPsychicCurrentSchedules: (extId: string) => psychicInstance
      .post('creatio/psychic/schedules/checkcurrentschedules', {}, {
        params: {
          ExtId: extId,
        },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    updatePsychicLogOffEarly: (extId: string, commentId: number) => psychicInstance
      .post('psychic/logoffearly', {}, {
        params: {
          ExtId: extId,
          CommentId: commentId,
        },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicNotesStats: (extId: string) => instanceChat2
      .get(`psychics/${extId}/notes-stats`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicAllNotificationsList: (
      extId: string,
      isGamifyEnabled = false,
    ) => instanceChat2
      .get(`psychics/${extId}/notifications${isGamifyEnabled ? '?isGamifyEnabled=true' : ''}`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicNotificationDetails: (extId, pcComId) => instanceChat2
      .get(`psychics/${extId}/notification/${pcComId}/history`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getPriorityActionsList: (extId) => instanceChat2
      .get(`psychics/${extId}/priority-actions`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    getReadingCustomersList: (extId: string) => instanceChat2
      .get(`psychics/${extId}/reading-customers`, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    updateNoteReadStatus: (extId: number, pcComId: number) => instanceChat2
      .put('psychics/read-note', {
        extId,
        pcComId,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    sendNotificationToCustomer: (payload) => instanceChat2
      .post('psychics/notification/send', {
        ...payload,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      }).then((res) => res.data)
      .catch((e) => { throw e; }),

    getNewsData: () => psychicInstance
      .get('/site/newscontent/13', {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),

    sendingBlockedContentToCS: (extId, psychicName, {
      messageBody,
      customerId,
    }) => instanceChat2
      .post('psychics/notification/sending-blocked-content', {
        extId, messageBody, customerId, psychicName,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      }).then((res) => res.data)
      .catch((e) => { throw e; }),

    getPsychicStatusAndInfo: (
      data: any,
    ) => californiaPsychicsInstance
      .post('psychic/getstatus', data, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => {
        if (res?.data?.psychics && res?.data?.psychics.length > 0) {
          return res.data.psychics[0];
        }

        return {};
      })
      .catch((e) => { throw e; }),

    updateClientLabels: (payload) => instanceChat2
      .put('psychics/update-client-labels', {
        ...payload,
      }, {
        headers: { ...CONTENT_TYPE_JSON },
      })
      .then((res) => res.data)
      .catch((e) => { throw e; }),
  },
};
