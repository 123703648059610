/* eslint-disable no-param-reassign */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import variables from 'common-styles/_variables.scss';

import textareaVariables from 'common-styles-textarea/_textareaVariables.scss';
import { Message, View } from 'constants/enums';
import { deepEqual } from 'src/utils/helpers';
import { ChatType } from 'types/objectTypes';

const onElementHeightChange = (element: HTMLElement | null, callback: Function) => {
  if (!element) return;

  let lastHeight = element.clientHeight;
  let newHeight: number;

  (function run() {
    newHeight = element.clientHeight;

    if (lastHeight !== newHeight) callback();

    lastHeight = newHeight;

    // @ts-ignore
    if (element.onElementHeightChangeTimer) clearTimeout(element.onElementHeightChangeTimer);

    // @ts-ignore
    element.onElementHeightChangeTimer = setTimeout(run, 200);
  }());
};

/**
 * This hook helps to menage resize of textarea and provides Telegram similar behavior
 * @constructor
 * @param messagesRef - element that contains ref to document element with messages
 * @param messageFormRef  - element that contains textarea element
 * @param isSwitchScreen - describe is screen was switch at Psychic's side. It is meat
 * that smb click at some conversation at the conversation list.
 * @param areaValue - current value of textarea
 */
export const useResizableTextarea = (
  messagesRef: React.RefObject<HTMLDivElement>,
  messageFormRef: React.RefObject<HTMLTextAreaElement>,
  isSwitchScreen: boolean,
  areaValue: string,
  view: View,
) => {
  const INPUT_BORDER_MEDIA_WIDTH = parseInt(variables.switchedMediaWidth, 10);
  const ROWS_AMOUNT = +textareaVariables.maxRowAmount;
  const [currentHeight, setCurrentHeight] = useState<number>(
    view === View.CUSTOMER
      ? +textareaVariables.customerTextareaHeight
      : +textareaVariables.psychicTextareaHeight,
  );
  const [currentDocWidth, setCurrentDocWidth] = useState<number>(document.body.clientWidth);

  const resizeTextareaElements = (
    lineHeight: number,
    area: HTMLTextAreaElement,
    areaDiv: HTMLDivElement,
    divForm: HTMLFormElement,
  ): number => {
    const styles = window.getComputedStyle(areaDiv);
    const textareaHeight = area.scrollHeight;
    const lastMessageElementPadding = parseFloat(styles.paddingTop)
      + parseFloat(styles.paddingBottom);
    const maxTextareaHeight = lineHeight * ROWS_AMOUNT;
    const height = Math.min(maxTextareaHeight, textareaHeight);
    divForm.style.height = `${height + lastMessageElementPadding}px`;
    areaDiv.style.height = `${height + lastMessageElementPadding}px`;
    area.style.height = `${height}px`;

    return height + lastMessageElementPadding;
  };

  const resizeBigScreen = (
    area: HTMLTextAreaElement,
    areaDiv: HTMLDivElement,
    divForm: HTMLFormElement,
  ): number => {
    if (view === View.CUSTOMER) {
      const lineHeight = +textareaVariables.customerBigScreenLineHeight;

      return resizeTextareaElements(lineHeight, area, areaDiv, divForm);
    }

    const lineHeight = +textareaVariables.psychicBigScreenLineHeight;

    return resizeTextareaElements(lineHeight, area, areaDiv, divForm);
  };

  const resizeSmallScreen = (
    area: HTMLTextAreaElement,
    areaDiv: HTMLDivElement,
    divForm: HTMLFormElement,
  ) => {
    if (view === View.CUSTOMER) {
      const lineHeight = +textareaVariables.customerSmallScreenLineHeight;

      return resizeTextareaElements(lineHeight, area, areaDiv, divForm);
    }

    const lineHeight = +textareaVariables.psychicSmallScreenLineHeight;

    return resizeTextareaElements(lineHeight, area, areaDiv, divForm);
  };

  /**
 * This use effect responsible for managing of resizing textarea.
 * Every time it is triggered if all relative DOM elements exist it's
 * get 0px height and then in terms of screen size apply one form resize methods,
 * that returns current height.
 * If height have not changed => @returns
 * Otherwise if user see bottom of messages textarea will move messages to top while resizing
 * and if client now not at the bottom => it will overlap messages
 */
  useEffect(() => {
    const area = messageFormRef.current as HTMLTextAreaElement;
    const messagesContainer = messagesRef.current;
    const isExit = !area || !messagesContainer || area.scrollHeight === 0;

    if (isExit) return;

    const areaDiv = area.parentElement as HTMLDivElement;
    const divForm = areaDiv.parentElement as HTMLFormElement;

    if (area && areaDiv) {
      area.style.cssText = 'height:0px';
      const isBigDoc = currentDocWidth > INPUT_BORDER_MEDIA_WIDTH;
      const messagesContainerParent = messagesContainer?.parentElement;

      if (!messagesContainerParent) return;

      const isBottom = messagesContainerParent.clientHeight
        === (messagesContainerParent.scrollHeight - messagesContainerParent.scrollTop);

      const height = (isBigDoc)
        ? resizeBigScreen(area, areaDiv, divForm)
        : resizeSmallScreen(area, areaDiv, divForm);

      if (currentHeight === height) return;

      if (isBottom) {
        messagesContainerParent.scrollTop = messagesContainerParent.scrollHeight;
      }

      setCurrentHeight(height);
    }
  }, [areaValue, messageFormRef.current]);
  /**
 * This useEffect sets up listener to the document on resizing
*/
  useEffect(() => {
    const RESIZE = 'resize';
    const handler = () => setCurrentDocWidth(document.body.clientWidth);
    window.addEventListener(RESIZE, handler);

    return () => window.removeEventListener(RESIZE, handler);
  }, []);
  /**
 * This useEffect apply appropriate styles after media resizing
 * @returns {void} if @param {React.RefObject<HTMLTextAreaElement>}
 * messageFormRef does not have current element
 * Then if screen width more then value, that taken from scss (common 600px)
 * will invoke method for big screen resizing otherwise for small
*/
  useEffect(() => {
    const area = messageFormRef.current! as HTMLTextAreaElement;

    if (!area) return;

    const areaDiv = area.parentElement as HTMLDivElement;
    const divForm = areaDiv.parentElement as HTMLFormElement;
    area.style.cssText = 'height:0px';
    const isBigDoc = currentDocWidth > INPUT_BORDER_MEDIA_WIDTH;

    if (isBigDoc) resizeBigScreen(area, areaDiv, divForm);
    else resizeSmallScreen(area, areaDiv, divForm);
  }, [currentDocWidth > INPUT_BORDER_MEDIA_WIDTH, isSwitchScreen]);
};

export const useScrollWithMessages = (
  messagesRefContainer: React.RefObject<HTMLDivElement>,
  scrollableRefMessageWrapper: React.RefObject<HTMLDivElement>,
  messages: Array<any>,
  currentChat: ChatType,
  currentView: View,
) => {
  const [prevLastMessage, setPrevLastMessage] = useState<any>(null);
  const [prevScrollHeight, setPrevScrollHeight] = useState<number>(0);
  const [prevClientHeight, setPrevClintHeight] = useState<number>(0);
  const [wasFirstLoad, setWasFirstLoad] = useState<boolean>(false);

  const isBottomIgnoreLastElementSize = (messageDiv: HTMLDivElement) => {
    const messageContainer = messageDiv.firstChild as HTMLDivElement;
    const lastMessageElement = messageContainer?.lastElementChild! as HTMLDivElement;
    const lastMessageElementHeight = lastMessageElement.offsetHeight;
    const styles = window.getComputedStyle(lastMessageElement);
    const lastMessageElementMargin = parseFloat(styles.marginTop)
      + parseFloat(styles.marginBottom);
    // eslint-disable-next-line prefer-destructuring
    const clientHeight = messageDiv.clientHeight;
    const lastElementHeight = Math.ceil(lastMessageElementHeight + lastMessageElementMargin);
    const computedClientHeight = Math.ceil(messageDiv.scrollHeight)
      - Math.floor(messageDiv.scrollTop)
      - Math.floor(lastElementHeight);
    const clientHeightDifference = computedClientHeight - clientHeight;
    const isNotSignificantDifference = clientHeightDifference < 10;

    return isNotSignificantDifference;
  };

  const scrollOnMessagesSendOrReceive = useCallback((messagesRef: HTMLDivElement) => {
    const isBottomNow = messagesRef.scrollHeight - messagesRef.scrollTop
      === messagesRef.clientHeight;
    const lastMessage = messages[messages.length - 1];

    if (deepEqual(lastMessage, prevLastMessage) || !wasFirstLoad) return;

    setPrevLastMessage(lastMessage);

    const messageParticipantType: string = (lastMessage?.attributes?.participantType || '').toLowerCase();
    const isTopNow = messagesRef.scrollTop === 0;

    const isMessageFromSideUser = messageParticipantType !== currentView.toLowerCase()
      && messageParticipantType !== Message.SYSTEM;

    if (isMessageFromSideUser && !isBottomNow && !isTopNow) {
      const isBottom = isBottomIgnoreLastElementSize(messagesRef);

      if (isBottom) {
        messagesRef.scrollTop = messagesRef.scrollHeight;

        return;
      }

      return;
    }

    messagesRef.scrollTop = messagesRef.scrollHeight;

    if (messagesRef.clientHeight !== prevClientHeight) {
      setPrevClintHeight(messagesRef.clientHeight);
    }
  }, [messages, wasFirstLoad, prevLastMessage]);

  onElementHeightChange(
    scrollableRefMessageWrapper.current,
    () => {
      const messagesRef = scrollableRefMessageWrapper.current as HTMLDivElement;

      if (messagesRef && messages?.length > 0) {
        const { clientHeight } = messagesRef;

        if (clientHeight !== prevClientHeight) {
          const delta = prevClientHeight - (messagesRef.scrollHeight - messagesRef.scrollTop);
          const isCloseBottom = Math.abs(delta) < 5;
          setPrevClintHeight(clientHeight);

          if (isCloseBottom) {
            messagesRef.scrollTop = messagesRef.scrollHeight;
          }
        }
      }
    },
  );

  const scrollOnMessagesLoad = (
    messagesRef: HTMLDivElement,
    messages,
  ) => {
    const isTopNow = messagesRef.scrollTop === 0;

    if (!wasFirstLoad) {
      messagesRef.scrollTop = messagesRef.scrollHeight;
      const lastMessage = messages[messages.length - 1];
      setPrevLastMessage(lastMessage);
      setPrevScrollHeight(messagesRef.scrollHeight);
      setWasFirstLoad(true);
      setTimeout(() => setPrevClintHeight(messagesRef.clientHeight));

      return;
    }

    if (isTopNow) {
      const previousPosition = messagesRef.scrollHeight - prevScrollHeight;

      messagesRef.scrollTop = previousPosition;
      setPrevScrollHeight(messagesRef.scrollHeight);
    }
  };

  useEffect(() => {
    setWasFirstLoad(false);
  }, [currentChat.chatId]);

  useEffect(() => {
    const childRef = messagesRefContainer.current as HTMLDivElement;
    const messagesRef = scrollableRefMessageWrapper.current as HTMLDivElement;

    if (messagesRef && childRef.firstElementChild && messages?.length) {
      setTimeout(() => {
        scrollOnMessagesLoad(messagesRef, messages);
      });
    }
  }, [
    messagesRefContainer,
    scrollableRefMessageWrapper,
    messages,
    wasFirstLoad,
    scrollOnMessagesLoad,
  ]);

  useEffect(() => {
    const messagesRef = scrollableRefMessageWrapper.current;

    if (messagesRef && messages?.length > 0) {
      scrollOnMessagesSendOrReceive(messagesRef);
    }
  }, [
    messagesRefContainer,
    scrollableRefMessageWrapper,
    messages,
    scrollOnMessagesSendOrReceive,
  ]);
};

export const useScrollListenerToLoadMessages = (
  currentChat: ChatType,
  loadExtraMessages: (messagesCount: number) => void,
  currentElementChild: HTMLDivElement | null,
) => {
  const currentElement = currentElementChild?.parentElement;
  useEffect(() => {
    const EVENT = 'scroll';
    const handler = (event: Event) => {
      const { currentChannel } = currentChat;
      const isMessagesSizeMoreThenScreen = currentElement
        && currentElement.scrollHeight > currentElement.clientHeight;
      const isTopOfScreen = currentElement?.scrollTop === 0;

      if (isMessagesSizeMoreThenScreen && isTopOfScreen && currentChannel) {
        event.preventDefault();
        loadExtraMessages(currentChat?.messages?.length);
      }
    };
    currentElement?.addEventListener(EVENT, handler);

    return () => currentElement?.removeEventListener(EVENT, handler);
  }, [currentChat.messages]);
};
