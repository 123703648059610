import React from 'react';
import cn from 'classnames';

import EmptyListBanner from 'components/Banners/EmptyListBanner';
import CompleteTrainingButton from 'components/Buttons/CompleteTrainingButton';
import StatsView from 'src/components/PsychicNotifications/StatsView';
import styles from 'components/PsychicNotifications/PsychicNotifications.module.scss';

const StatsViewContainer = ({
  isGamifyEnabled,
  notificationStatistics,
  sendCustomerDetail,
  handlePsychicNoteScreens,
  isMobile,
  isAllNotesTab,
}) => isAllNotesTab && (
  <div className={cn(styles.psychicNotificationWrapper, {
    [styles.withGamify]: isGamifyEnabled,
  })}
  >
    {
      notificationStatistics.totalNotificationSent ? (
        <>
          <StatsView
            notificationStatistics={notificationStatistics}
            sendCustomerDetail={sendCustomerDetail}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
            isMobile={isMobile}
            isGamifyEnabled={isGamifyEnabled}
          />
        </>
      ) : (
        <div>
          <EmptyListBanner />
          <CompleteTrainingButton />
        </div>
      )
    }
  </div>
);

export default StatsViewContainer;
