import { FirebaseConfigNames } from 'constants/enums';
import { importAndRetryChunk } from 'src/utils/commonUtil';
import { MAX_RETRY_VALUE } from 'constants/constants';

let firebase;
const databases: { psychicsDb?: any } = {};

const lazySetUp = async () => {
  if (!firebase) {
    const { default: loaded } = await importAndRetryChunk(
      () => import('firebase'),
      MAX_RETRY_VALUE,
      'Firebase loading failed',
    );

    firebase = loaded;
  }

  if (databases?.psychicsDb) {
    return databases;
  }

  const firebaseCaliforniaPsychicsConfig = firebase.initializeApp({
    apiKey: process.env.CALIFORNIA_PSYCHICS_API_KEY,
    authDomain: process.env.CALIFORNIA_PSYCHICS_AUTH_DOMAIN,
    databaseURL: process.env.CALIFORNIA_PSYCHICS_DATABASE_URL,
    projectId: process.env.CALIFORNIA_PSYCHICS_PROJECT_ID,
    storageBucket: process.env.CALIFORNIA_PSYCHICS_STORAGE_BUCKET,
    messagingSenderId: process.env.CALIFORNIA_PSYCHICS_MESSAGING_SENDER_ID,
    appId: process.env.CALIFORNIA_PSYCHICS_APP_ID,
    measurementId: process.env.CALIFORNIA_MEASUREMENT_ID,
  }, FirebaseConfigNames.CALIFORNIA_PSYCHICS);

  databases.psychicsDb = firebaseCaliforniaPsychicsConfig.firestore();

  return databases;
};

export default lazySetUp;
