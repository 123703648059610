import React from 'react';
import { TOKEN_TWILIO_STORAGE } from 'extracted-chat-components/constants';

import { LocalStorage } from 'src/utils/storageHandler';
import { parseToken } from 'src/utils/authHandler';
import twilioTokenCheckerWorker from 'src/workers/TwilioTokenCheckerWorker';
import { WebWorkerMessage } from 'constants/enums';
import { updateToken } from 'src/utils/twilio';

const twilioTokenWorker = new Worker(twilioTokenCheckerWorker);
const CHECK_PERIOD = process.env.TOKEN_CHAT_EXPIRATION_CHECK_PERIOD;
const GRACE_PERIOD = process.env.TOKEN_CHAT_EXPIRATION_GRACE_PERIOD;

const getIatTokenTime = async () => {
  const twilioToken: string = LocalStorage.getItem(TOKEN_TWILIO_STORAGE) as string;
  const result = await parseToken(twilioToken);

  return result?.iat;
};

const startWatching = async () => {
  const iatTime = await getIatTokenTime();

  twilioTokenWorker.postMessage({
    messageType: WebWorkerMessage.WATCH_TOKEN_LIFETIME,
    payload: {
      iatTime,
      tokenChatExpirationCheckPeriod: CHECK_PERIOD,
      tokenChatExpirationGracePeriod: GRACE_PERIOD,
    },
  });
};

const handleTokenAboutExpired = async (twilioClient) => {
  try {
    if (twilioClient.connectionState) {
      await updateToken(twilioClient);

      await startWatching();
    }
  } catch (e) {
    console.log(e);

    throw Error('Cannot update the twilio token');
  }
};

export const useTwilioCheckerWebworker = (
  twilioClient,
  descriptionFromError,
  handleRequestError,
) => {
  React.useEffect(() => {
    (async () => {
      try {
        if (twilioTokenWorker && twilioClient.connectionState) {
          await startWatching();

          twilioTokenWorker.onmessage = async (event) => {
            const { messageType } = event.data;

            switch (messageType) {
              case WebWorkerMessage.TOKEN_ABOUT_EXPIRED: {
                await handleTokenAboutExpired(twilioClient);

                break;
              }

              case WebWorkerMessage.CLEAR_INTERVAL: {
                startWatching();

                break;
              }

              default: break;
            }
          };
        }
      } catch (e) {
        const requestErrorPayload = {
          isInvalidToken: false,
          errorText: e?.message,
          description: descriptionFromError(e),
        };

        handleRequestError(requestErrorPayload);
      }
    })();
  }, [twilioClient]);
};
