import CryptoJS from 'crypto-js';
import {
  put,
  call,
  takeEvery,
  putResolve,
  select,
  take,
} from 'redux-saga/effects';
import { SESSION_ID } from 'extracted-chat-components/constants';

import {
  SdkEvents,
  View,
} from 'constants/enums';
import {
  setTwilioToken,
  setAuthToken,
  receiveAuthData,
  receiveSdkAuthData,
  setBearerToken,
} from 'actions/authActions';
import { setCustomerId, setExtId, setEncryptedPassword } from 'actions/chatActions';
import {
  LOGOUT,
  RECEIVE_AUTH_DATA,
  RECEIVE_SDK_AUTH_DATA,
  SET_VIEW,
} from 'actions/actionsTypes';
import {
  receiveCustomerAuthData,
  receivePsychicAuthData,
} from 'src/utils/authHandler';
import {
  checkDiffInTime,
  destroySessionSdk,
  handleRequestError,
  setLoadingState,
  setRequestErrorState,
} from 'actions/appActions';
import { API } from 'src/utils/api';
import { selectSdkCallbackStorage, selectView } from 'selectors/selectors';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  CUSTOMER_ID_STORAGE,
  PSYCHIC_ID_STORAGE,
  PASSWORD_STORAGE,
  TOKEN_AUTH_STORAGE,
  TOKEN_BEARER,
  TOKEN_TWILIO_STORAGE,
  REFRESH_TOKEN,
} from 'constants/constants';
import { SentryMethods } from 'src/utils/sentryMethods';
import { getActiveChatsForPsychic } from 'src/utils/chatsHandler';
import {
  callRetry,
  descriptionFromError,
} from 'src/utils/commonUtil';

function* handleTokens(
  chatToken: string,
  bearerToken: string,
  refreshToken: string,
  sessionId: string,
  view: string,
  isSdk: boolean = false,
) {
  yield put(setAuthToken(chatToken));
  yield put(setBearerToken(bearerToken));
  const itemEntries = [
    { key: TOKEN_AUTH_STORAGE, value: chatToken },
    { key: TOKEN_BEARER, value: bearerToken },
    { key: REFRESH_TOKEN, value: refreshToken },
    { key: SESSION_ID, value: sessionId },
  ];
  LocalStorage.setItems(itemEntries);

  if (isSdk || view === View.PSYCHIC) {
    const response = yield call(callRetry, API.Chat.getTwilioToken);
    const { twilioToken, serverDateTime } = response?.data;
    LocalStorage.setItem(TOKEN_TWILIO_STORAGE, twilioToken);

    yield put(checkDiffInTime(serverDateTime));
    yield put(setTwilioToken(twilioToken));
  }
}

function* receiveAuthTokenWorker({ payload }: ReturnType<typeof receiveAuthData>) {
  const { username, password, view } = payload;
  try {
    yield put(setLoadingState(true));
    const isCustomerView = view === View.CUSTOMER;

    if (isCustomerView) {
      const authData = yield call(receiveCustomerAuthData, username, password);
      const customerId = yield authData.userId;
      const { chatToken } = authData.meta;
      LocalStorage.setItem(CUSTOMER_ID_STORAGE, customerId);
      yield put(setCustomerId(customerId));
      yield call(handleTokens, chatToken, authData.bearerToken, '', '', View.CUSTOMER);
    } else {
      const authData = yield call(receivePsychicAuthData, username, password);
      const extId = yield authData.userName;
      const sessionId = yield authData.sessionId;
      const { chatToken, refreshToken } = authData.meta;
      const cipherPassword = CryptoJS.AES.encrypt(
        password,
        process.env.PSYCHIC_PASSWORD_SECRET,
      ).toString();
      LocalStorage.setItem(PSYCHIC_ID_STORAGE, extId);
      LocalStorage.setItem(PASSWORD_STORAGE, cipherPassword);
      yield put(setExtId(extId));
      yield put(setEncryptedPassword(cipherPassword));
      yield call(
        handleTokens,
        chatToken,
        authData.bearerToken,
        refreshToken,
        sessionId,
        View.PSYCHIC,
      );
    }
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
    yield put(setRequestErrorState(true, 'authError'));
    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* receiveSdkAuthTokenWorker({ payload }: ReturnType<typeof receiveSdkAuthData>) {
  const {
    tokenAuth,
    userId,
    tokenBearer,
    refreshToken,
    sessionId,
  } = payload;
  try {
    yield put(setLoadingState(true));
    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
    let view = yield select(selectView);

    if (!view) {
      yield take(SET_VIEW);
      view = yield select(selectView);
    }

    if (view.toLowerCase() === View.CUSTOMER.toLowerCase()) {
      LocalStorage.setItem(CUSTOMER_ID_STORAGE, userId);
      yield putResolve(setCustomerId(userId));
      yield call(handleTokens, tokenAuth, tokenBearer, refreshToken, '', View.CUSTOMER);
    } else {
      LocalStorage.setItem(PSYCHIC_ID_STORAGE, userId);
      LocalStorage.setItem(TOKEN_BEARER, tokenBearer);
      LocalStorage.setItem(SESSION_ID, sessionId);
      yield putResolve(setExtId(userId));
      yield call(handleTokens, tokenAuth, tokenBearer, refreshToken, sessionId, View.PSYCHIC, true);

      const sessionCallback = sdkCallbackStorage.get(SdkEvents.SET_SESSION);
      const activeChats = yield call(getActiveChatsForPsychic, userId);

      if (sessionCallback) {
        sessionCallback(activeChats);
      }
    }
  } catch (e) {
    console.log(e);

    const requestErrorPayload = {
      redirectPath: '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
    yield call(SentryMethods.captureException, e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* logoutWorker() {
  try {
    yield put(setLoadingState(true));
    const sessionId = LocalStorage.getItem(SESSION_ID) as string;

    yield call(API.Psychic.logout, sessionId);
    yield put(destroySessionSdk());
  } catch (e) {
    console.log(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* authSagasWatcher() {
  yield takeEvery(RECEIVE_AUTH_DATA, receiveAuthTokenWorker);
  yield takeEvery(RECEIVE_SDK_AUTH_DATA, receiveSdkAuthTokenWorker);
  yield takeEvery(LOGOUT, logoutWorker);
}
