import React from 'react';
import { Box, FormControlLabel } from '@mui/material';

import MuteSwitch from 'components/PsychicNotifications/CustomStyled/MuteSwitch';
import CustomTablePagination from 'components/Table/TablePagination/CustomTablePagination';
import ListFooterForPriorityAction from 'components/PsychicNotifications/ListingView//ListComponents/ListFooterForPriorityAction';
import { ReactComponent as InformationIcon } from 'src/assets/svg/notification-mute.svg';
import { PNMuteStatus, PNTabs } from 'constants/enums';
import { ROWS_PER_PAGE_OPTION } from 'constants/constants';
import { IListFooter } from 'types/componentTypes';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';

import MuteInformation from './MuteInformation';
import ListFooterForMobile from './ListFooterForMobile';

const ListFooter: React.FC<IListFooter> = ({
  page,
  count,
  showMuted,
  rowsPerPage,
  isNotificationTab,
  isTableBody,
  onChangePage,
  onChangeMuted,
  onChangeRowsPerPage,
  toggleMutedInfoHandler,
  isMuteInfoShown,
  isMobile,
  currentTab,
  tableRowsCount,
}) => {
  const handleOnChangeRowsPerPage = (e) => onChangeRowsPerPage(parseInt(e.target.value, 10));

  const renderContent = () => {
    if (currentTab !== PNTabs.PRIORITY_ACTION_TAB && !isTableBody) {
      return (
        <div className={styles.emptyText}>
          {isNotificationTab
            ? 'No Notifications have been sent.'
            : 'No recent readings in the last 90 days'}
        </div>
      );
    }

    if (currentTab === PNTabs.PRIORITY_ACTION_TAB) {
      return (
        <ListFooterForPriorityAction
          count={count}
          tableRowsCount={tableRowsCount}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      );
    }

    if (isMobile) {
      return (
        <ListFooterForMobile
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={handleOnChangeRowsPerPage}
          toggleMutedInfoHandler={toggleMutedInfoHandler}
          showMuted={showMuted}
          isMuteInfoShown={isMuteInfoShown}
          onChangeMuted={onChangeMuted}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTION}
        />
      );
    }

    const formLabelStyle = {
      px: 3,
      py: 1.5,
      top: 0,
      position: { md: 'absolute' },
    };

    return (
      <>
        <Box className={styles.listFooter}>
          <CustomTablePagination
            page={page}
            count={count}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTION}
            onChangePage={onChangePage}
            onChangeRowsPerPage={handleOnChangeRowsPerPage}
          />
          <div className={styles.muteButton}>
            <InformationIcon onClick={toggleMutedInfoHandler} />
            <FormControlLabel
              sx={formLabelStyle}
              control={<MuteSwitch checked={!showMuted} onChange={onChangeMuted} />}
              label={showMuted ? PNMuteStatus.SHOW : PNMuteStatus.HIDE}
              className={styles.toggleButton}
            />
          </div>
        </Box>
        <MuteInformation
          isMuteInfoShown={isMuteInfoShown}
          toggleMutedInfoHandler={toggleMutedInfoHandler}
        />
      </>
    );
  };

  return renderContent();
};

export default ListFooter;
