import React from 'react';

import InsufficientFundsPopup from './InsufficientFundsPopup';

const InsufficientFunds = ({
  isNotificationPopUp,
  chatId,
  fundsToAdd,
  customerBalance,
  sdkCallbackStorage,
  sideUser,
  destroySessionSdk,
  setIsLiveChatActive,
  deleteCurrentChat,
  removeChatId,
  clearExtId,
  setShowInitModal,
  showPopUpNotification,
  setPopUpNotificationData,
  setChatStatus,
  setLoadingState,
  setFunds,
  setInitChatNotification,
  setInitChatBanner,
  setActivity,
  setCustomerBalance,
  handleAddFundsError,
  addMixpanelEventAlertViewed,
  addMixpanelEventAlertTapped,
  popUpNotificationData,
}) => (
  <InsufficientFundsPopup
    isNotificationPopUp={isNotificationPopUp}
    chatId={chatId}
    fundsToAdd={fundsToAdd}
    customerBalance={customerBalance}
    sdkCallbackStorage={sdkCallbackStorage}
    sideUser={sideUser}
    destroySessionSdk={destroySessionSdk}
    setIsLiveChatActive={setIsLiveChatActive}
    deleteCurrentChat={deleteCurrentChat}
    removeChatId={removeChatId}
    clearExtId={clearExtId}
    setShowInitModal={setShowInitModal}
    showPopUpNotification={showPopUpNotification}
    setPopUpNotificationData={setPopUpNotificationData}
    setChatStatus={setChatStatus}
    setLoadingState={setLoadingState}
    setFunds={setFunds}
    setCustomerBalance={setCustomerBalance}
    setActivity={setActivity}
    handleAddFundsError={handleAddFundsError}
    addMixpanelEventAlertViewed={addMixpanelEventAlertViewed}
    addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
    popUpNotificationData={popUpNotificationData}
    setInitChatNotification={setInitChatNotification}
    setInitChatBanner={setInitChatBanner}
  />
);

export default InsufficientFunds;
