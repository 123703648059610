import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { PsychicStatusChannel, PsychicStatusCommand } from 'extracted-chat-components/enums';

import { 
  selectExtId, 
  selectUserAgent, 
  selectIsPsychicLoginOutsideOfScheduleEditMode 
} from 'selectors/selectors';
import {
  selectChatAndPhone,
  selectChatEnabled,
  selectChatOnly,
  selectIsOnCall,
  selectPhoneOnly,
} from 'selectors/statusToggleSelectors';
import {
  addPopUpNotification,
  setLoadingState,
  setRequestErrorState,
  showPopUpNotification,
} from 'actions/appActions';
import {
  earlyLogout,
  setBreak,
  setPsychicStatus,
  updateBreak,
  updateChatAndPhoneStatus,
  updateChatEnabled,
  updateChatStatus,
  updateOfflineMessagesAvailability,
  updateOfflineMessagesStatus,
  updatePhoneStatus,
  updatePsychicSchedule,
} from 'actions/statusToggleActions';
import { ErrorRequestType, PopUpNotificationType } from 'constants/enums';
import { API } from 'src/utils/api';
import * as types from 'actions/actionsTypes';
import NotEnabledForChatTitle from 'components/Titles/NotEnabledForChatTitle/NotEnabledForChatTitle';
import { endBreakSaga } from 'src/redux/sagas/statusToggle/endBreakSaga';
import { getPlatformForChatToggleApplication } from 'src/utils/commonUtil';
import StandbyTitle from 'components/Titles/StandbyTitle';
import { SentryMethods } from 'src/utils/sentryMethods';

function* handleChatAndPhoneOnlyStatusRequest(payload: boolean, reason: string) {
  const extId = yield select(selectExtId);
  const isChatEnabled = yield select(selectChatEnabled);
  const userAgent = yield select(selectUserAgent);

  try {
    yield put(setLoadingState(true, true));

    if (!isChatEnabled) {
      yield put(addPopUpNotification({
        isVisible: true,
        title: NotEnabledForChatTitle,
        notificationType: PopUpNotificationType.NOT_ENABLED_FOR_CHAT,
      }));
      yield put(setLoadingState(false));

      return;
    }

    const action = payload ? PsychicStatusCommand.SET_ONLINE : PsychicStatusCommand.SET_OFFLINE;
    const earlyLogoutReason = `${PsychicStatusCommand.SET_EARLY_OFFLINE}${reason}|${PsychicStatusChannel.PHONE_CHAT}`;
    const requestPayload = reason ? earlyLogoutReason : `${action}|${PsychicStatusChannel.PHONE_CHAT}`;
    const chatToggleApplication = getPlatformForChatToggleApplication(userAgent);

    const response = yield call(
      API.Psychic.updatePsychicStatus,
      extId,
      requestPayload,
      chatToggleApplication,
    );

    const isSuccess = response?.isSuccess;

    if (!isSuccess) throw new Error('Status set unsuccessfully');

    // fake update because it's not possible to detect phone status, as it is always busy
    // update on firebase events also disabled
    const isOnCall = yield select(selectIsOnCall);

    if (isOnCall) {
      yield put(updateChatAndPhoneStatus(payload));
      yield put(updateChatStatus(false));
      yield put(updatePhoneStatus(false));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handlePhoneOnlyStatusRequest(payload: boolean, reason: string) {
  const chatAndPhone = yield select(selectChatAndPhone);
  const extId = yield select(selectExtId);
  const chatOnly = yield select(selectChatOnly);
  const userAgent = yield select(selectUserAgent);
  const isOnCall = yield select(selectIsOnCall);
  const isOnStandby = isOnCall && payload;

  try {
    if (!isOnStandby) {
      yield put(setLoadingState(true, true));
    }

    const action = payload ? PsychicStatusCommand.SET_ONLINE : PsychicStatusCommand.SET_OFFLINE;
    const earlyLogoutReason = `${PsychicStatusCommand.SET_EARLY_OFFLINE}${reason}|${PsychicStatusChannel.PHONE}`;
    let requestPayload = reason ? earlyLogoutReason : `${action}|${PsychicStatusChannel.PHONE}`;
    const chatToggleApplication = getPlatformForChatToggleApplication(userAgent);
    let response: any = {};

    if (isOnCall) {
      yield put(addPopUpNotification({
        isVisible: true,
        title: StandbyTitle,
        notificationType: PopUpNotificationType.STANDBY_PSYCHIC,
      }));
      requestPayload = PsychicStatusCommand.SET_BREAK_00;
      yield put(setLoadingState(false));
    }

    if (!isOnStandby) {
      response = yield call(
        API.Psychic.updatePsychicStatus,
        extId,
        requestPayload,
        chatToggleApplication,
      );
    }

    if (payload) {
      if (chatAndPhone || chatOnly) {
        yield all([
          put(setPsychicStatus(false, PsychicStatusChannel.CHAT)),
          take(),
        ]);
      }
    }

    const isSuccess = response?.isSuccess;

    if (!isSuccess) throw new Error('Status set unsuccessfully');

    // fake update because it's not possible to detect phone status, as it is always busy
    // update on firebase events also disabled

    if (isOnCall) {
      yield put(updatePhoneStatus(payload));
      yield put(updateChatAndPhoneStatus(false));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleChatOnlyStatusRequest(payload: boolean) {
  const chatAndPhone = yield select(selectChatAndPhone);
  const phoneOnly = yield select(selectPhoneOnly);
  const extId = yield select(selectExtId);
  const isChatEnabled = yield select(selectChatEnabled);
  const userAgent = yield select(selectUserAgent);

  try {
    yield put(setLoadingState(true, true));

    if (!isChatEnabled) {
      yield put(addPopUpNotification({
        isVisible: true,
        title: NotEnabledForChatTitle,
        notificationType: PopUpNotificationType.NOT_ENABLED_FOR_CHAT,
      }));
      yield put(setLoadingState(false));

      return;
    }

    const action = payload ? PsychicStatusCommand.SET_ONLINE : PsychicStatusCommand.SET_OFFLINE;
    const requestPayload = `${action}|${PsychicStatusChannel.CHAT}`;
    const chatToggleApplication = getPlatformForChatToggleApplication(userAgent);

    const response = yield call(
      API.Psychic.updatePsychicStatus,
      extId,
      requestPayload,
      chatToggleApplication,
    );

    if (payload) {
      if (chatAndPhone || phoneOnly) {
        yield all([
          put(setPsychicStatus(false, PsychicStatusChannel.PHONE)),
          take(),
        ]);
      }
    }

    const isSuccess = response?.isSuccess;

    if (!isSuccess) throw new Error('Status set unsuccessfully');

    // fake update because it's not possible to detect phone status, as it is always busy
    // update on firebase events also disabled
    const isOnCall = yield select(selectIsOnCall);

    if (isSuccess) {
      yield put(updateChatStatus(payload));
    }

    if (isOnCall) {
      yield put(updateChatStatus(payload));
      yield put(updateChatAndPhoneStatus(false));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleOfflineMessagesStatusRequest(payload: boolean) {
  const extId = yield select(selectExtId);

  try {
    yield put(setLoadingState(true, true));

    const response = yield call(API.Psychic.updateOfflineMessagesStatus, extId, payload);
    const isSuccess = response?.isSuccess;

    if (!isSuccess) throw new Error('Status set unsuccessfully');
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleBreakRequest({ payload }: any) {
  const { command = null } = payload || {};
  const extId = yield select(selectExtId);
  const userAgent = yield select(selectUserAgent);

  try {
    yield put(setLoadingState(true, true));

    const action = `${command}|${PsychicStatusChannel.PHONE_CHAT}`;
    const chatToggleApplication = getPlatformForChatToggleApplication(userAgent);
    const response = yield call(
      API.Psychic.updatePsychicStatus,
      extId,
      action,
      chatToggleApplication,
    );

    const isSuccess = response?.isSuccess;

    if (!isSuccess) throw new Error('Status set unsuccessfully');
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleChangePsychicStatus({ payload }: any) {
  const {
    status, channel, command, reason,
  } = payload;

  switch (channel) {
    case PsychicStatusChannel.PHONE_CHAT: {
      yield call(handleChatAndPhoneOnlyStatusRequest, status, reason);

      break;
    }
    case PsychicStatusChannel.CHAT: {
      yield call(handleChatOnlyStatusRequest, status);

      break;
    }
    case PsychicStatusChannel.PHONE: {
      yield call(handlePhoneOnlyStatusRequest, status, reason);

      break;
    }
    case PsychicStatusChannel.OFFLINE_MESSAGES: {
      yield call(handleOfflineMessagesStatusRequest, status);

      break;
    }
    case PsychicStatusChannel.BREAK: {
      yield put(setBreak(command));

      break;
    }

    default: break;
  }
}

function* updatePsychicStatusWorker({ payload }: any) {
  const { status, channel, breakData = null } = payload || {};

  switch (channel) {
    case PsychicStatusChannel.PHONE_CHAT: {
      yield put(updateChatAndPhoneStatus(status));

      break;
    }
    case PsychicStatusChannel.CHAT: {
      yield put(updateChatStatus(status));

      break;
    }
    case PsychicStatusChannel.PHONE: {
      yield put(updatePhoneStatus(status));

      break;
    }
    case PsychicStatusChannel.CHAT_AVAILABILITY: {
      yield put(updateChatEnabled(status));

      break;
    }
    case PsychicStatusChannel.OFFLINE_MESSAGES: {
      yield put(updateOfflineMessagesStatus(status));

      break;
    }
    case PsychicStatusChannel.OFFLINE_MESSAGES_AVAILABILITY: {
      yield put(updateOfflineMessagesAvailability(status));

      break;
    }
    case PsychicStatusChannel.BREAK: {
      const { breakEndsTimestamp = 0, command = '', onBreakRequestPending = null } = breakData || {};

      yield put(updateBreak(breakEndsTimestamp, command, onBreakRequestPending));

      break;
    }

    default: break;
  }
}

export function* handlePsychicSchedule({
  payload,
}: ReturnType<typeof updatePsychicSchedule>) {
  try {
    const isEditMode = yield select(selectIsPsychicLoginOutsideOfScheduleEditMode);
    yield put(setLoadingState(true, true));
    const psychicId = yield select(selectExtId);
    yield call(API.Chat.updatePsychicScheduleStatus, psychicId, payload);

    if (isEditMode) {
      yield put(setLoadingState(false));
    }
  } catch (e) {
    console.error(e);
    yield put(setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT));
    yield put(showPopUpNotification(false));
    yield put(setLoadingState(false));

    SentryMethods.captureException(e);
  }
}

function* handleEarlyLogout({ payload }: ReturnType<typeof earlyLogout>) {
  const {
    isPsychicCurrentHourScheduled,
    psychicEarlyLogoutReason,
    status,
    channel,
  } = payload;
  try {
    yield put(setPsychicStatus(status, channel, '', psychicEarlyLogoutReason));

    if (isPsychicCurrentHourScheduled) {
      const extId = yield select(selectExtId);
      yield call(API.Psychic.updatePsychicLogOffEarly, extId, psychicEarlyLogoutReason);
    }
  } catch (e) {
    console.log(e);
  }
}

export function* statusToggleSagas() {
  yield takeEvery(types.SET_BREAK, handleBreakRequest);
  yield takeEvery(types.SET_PSYCHIC_STATUS, handleChangePsychicStatus);
  yield takeEvery(types.UPDATE_PSYCHIC_STATUS, updatePsychicStatusWorker);
  yield takeEvery(types.END_BREAK, endBreakSaga);
  yield takeEvery(types.EARLY_LOGOUT, handleEarlyLogout);
  yield takeEvery(types.UPDATE_PSYCHIC_SCHEDULE, handlePsychicSchedule);
}
