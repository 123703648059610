import {
  put,
  call,
  take,
  select,
  cancelled,
  putResolve,
  fork,
} from 'redux-saga/effects';

import {
  View,
  SdkEvents,
  ChannelEvents,
  ConversationType,
  Message,
} from 'constants/enums';
import {
  IS_SHOW_SWITCH_TO_PHONE,
  IS_SWITCH_TO_PHONE_BANNER,
} from 'constants/constants';
import * as chatActions from 'actions/chatActions';
import {
  selectCustomerId,
  selectView,
  selectCurrentChat,
  selectOfflineChats,
  selectSdkCallbackStorage,
  selectCurrentUser,
  selectSideUser,
} from 'selectors/selectors';
import { ChatType } from 'types/objectTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  addParticipantOfflineMessageBackgroundHandler,
  addParticipantOfflineMessageHandler,
  updateParticipantOfflineMessageBackgroundHandler,
  updateParticipantOfflineMessageHandler,
  setUserCancelOfflineChatHandler,
  updateParticipantMessageHandler,
} from 'actions/offlineChatActions';
import { DraftedMessages } from 'src/utils/draftedMessageHandler';
import {
  handleEndChatForCustomer,
  eventListener,
} from 'src/redux/sagas/chat/commonSaga';
import { loadMessagesWorker } from 'src/redux/sagas/chat/messagesSaga';
import { handleChatSystemMessages } from 'src/redux/sagas/chat/channels/handleChatSystemMessagesSaga';

function* findOfflineWithCustomerFromCurrentLiveChat(customerRefIdEnc: string) {
  const offlineChats = yield select(selectOfflineChats);

  return offlineChats.find((
    offlineChat,
  ) => offlineChat.sideUser?.customerRefIdEnc === customerRefIdEnc);
}

function* handleOfflineMessageForSdk(message) {
  if (!message) {
    return;
  }

  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
  const onOfflineMessageCallback = sdkCallbackStorage.get(SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED);
  const view: View = yield select(selectView);

  const { attributes } = message || {};
  const isSideUserMessageAndCbExists = onOfflineMessageCallback
    && attributes.participantType
    && attributes.requestType === ConversationType.DIRECT_MESSAGE
    && view.toLowerCase() !== attributes.participantType.toLowerCase();

  if (isSideUserMessageAndCbExists) {
    onOfflineMessageCallback({
      name: SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED,
      data: message,
    });
  }
}

function* updateParticipantMessageWorker(data: any, chatId?: string) {
  const currentChat: ChatType = yield select(selectCurrentChat);

  const isMessageInCurrentOfflineChat = chatId
    && currentChat.chatId === chatId
    && currentChat.attributes?.requestType === ConversationType.DIRECT_MESSAGE;

  if (isMessageInCurrentOfflineChat) {
    yield put(updateParticipantOfflineMessageHandler(data, chatId!));

    return;
  }

  const isMessageInCurrentLiveChat = currentChat.attributes?.requestType
    === ConversationType.LIVE_CHAT;

  if (isMessageInCurrentLiveChat) {
    yield put(updateParticipantMessageHandler(data));

    return;
  }

  const isMessageInNotCurrentLiveChat = chatId && currentChat.chatId !== chatId;

  if (isMessageInNotCurrentLiveChat) {
    yield put(updateParticipantOfflineMessageBackgroundHandler(data, chatId!));
  }
}

function* triggerPopUpIfNeeded(messageState: any, chatId?: string) {
  const { attributes } = messageState;

  if (attributes && chatId && attributes.userCanceled && attributes.canceled) {
    yield put(setUserCancelOfflineChatHandler(chatId));
  }
}

function* handleMessageUpdate(messageState: any, chatId?: string) {
  yield call(updateParticipantMessageWorker, messageState, chatId);
  yield call(triggerPopUpIfNeeded, messageState, chatId);
}

function* addParticipantMessageWorker(data: any, chatId?: string) {
  if (data?.author === Message.SYSTEM) {
    yield fork(handleChatSystemMessages, data);
  }

  const currentChat: ChatType = yield select(selectCurrentChat);
  const isMessageInLiveChat = currentChat.attributes?.requestType === ConversationType.LIVE_CHAT;
  const offline = yield call(
    findOfflineWithCustomerFromCurrentLiveChat,
    currentChat?.sideUser?.customerRefIdEnc,
  );

  if (isMessageInLiveChat) {
    yield putResolve(chatActions.addParticipantMessage(data));

    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
    const onMessageCallback = sdkCallbackStorage.get(SdkEvents.ON_MESSAGE_RECEIVED);

    if (onMessageCallback) {
      onMessageCallback({
        name: SdkEvents.ON_MESSAGE_RECEIVED,
        data,
      });
    }

    if (offline?.chatId) {
      yield put(addParticipantOfflineMessageBackgroundHandler(data, offline.chatId as string));
    }

    return;
  }

  const isMessageInCurrentOfflineChat = chatId
    && currentChat.chatId === chatId
    && !isMessageInLiveChat;

  if (isMessageInCurrentOfflineChat) {
    yield put(addParticipantOfflineMessageHandler(data, chatId!));
    yield call(handleOfflineMessageForSdk, data);

    return;
  }

  const isMessageInNotCurrentLiveChat = chatId && currentChat.chatId !== chatId;

  if (isMessageInNotCurrentLiveChat) {
    yield put(addParticipantOfflineMessageBackgroundHandler(data, chatId as string));
    yield call(handleOfflineMessageForSdk, data);
  }
}

export function* channelWatcher({
  payload,
}: ReturnType<typeof chatActions.configureChannelEvents>) {
  const { channelInstance, chatId } = payload;
  const channel = yield call(eventListener, channelInstance);
  const view = yield select(selectView);

  try {
    while (true) {
      const { payload, type } = yield take(channel);

      switch (type) {
        case ChannelEvents.MESSAGE_ADDED: {
          yield fork(addParticipantMessageWorker, payload.state, chatId);

          break;
        }
        case ChannelEvents.MESSAGE_UPDATED: {
          yield fork(handleMessageUpdate, payload?.message?.state, chatId);

          break;
        }
        case ChannelEvents.MEMBER_LEFT: {
          const customerId = yield select(selectCustomerId);

          const offline = yield call(
            findOfflineWithCustomerFromCurrentLiveChat,
            customerId,
          );

          if (offline?.chatId) {
            yield call(
              loadMessagesWorker,
              offline.currentChannel,
              ConversationType.DIRECT_MESSAGE,
              offline.chatId,
            );
          }

          if (view !== View.CUSTOMER) {
            return;
          }

          LocalStorage.removeItem(IS_SHOW_SWITCH_TO_PHONE);
          LocalStorage.removeItem(IS_SWITCH_TO_PHONE_BANNER);

          if (chatId) DraftedMessages.remove(chatId);

          const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
          const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

          if (onEndCallback) {
            onEndCallback({ name: SdkEvents.ON_END_CHAT });
          }

          yield call(handleEndChatForCustomer);

          break;
        }
        case ChannelEvents.TYPING_STARTED: {
          const { extId } = yield select(selectCurrentUser);
          const { friendlyName } = yield select(selectSideUser) || {};
          const isTheSameUser = payload?.state?.identity === `cp-advisor-${extId}`;

          if (isTheSameUser) {
            yield put(chatActions.setChatMinutesActive(0));
          }

          const typingMessage = isTheSameUser ? '' : `${friendlyName} is typing...`;

          yield put(chatActions.setTypingStartedMessage(typingMessage));

          break;
        }
        case ChannelEvents.TYPING_ENDED:
          yield put(chatActions.setTypingStartedMessage(null));

          break;
        default: break;
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
