import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import styles from 'components/Rows/EmptyRow/TableNoData.module.scss';

const TableNoData = () => (
  <TableRow>
    <TableCell colSpan={12} className={styles.row}>
      No data found on applied search.
    </TableCell>
  </TableRow>
);

export default TableNoData;
