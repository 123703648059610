import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Status } from 'constants/enums';
import {
  selectCurrentPsychicStatus,
  selectIsLiveChatActive,
} from 'selectors/selectors';

import styles from './Status.module.scss';

const setPsychicStatusForActiveChat = (
  status: string,
  setPsychicStatus: (status: string) => void,
): void => {
  switch (status) {
    case Status.PENDING: {
      setPsychicStatus(Status.CONNECTING);

      break;
    }
    case Status.BUSY: {
      setPsychicStatus(Status.CONNECTED);

      break;
    }
    case Status.CONNECTED: {
      setPsychicStatus(Status.CONNECTED);

      break;
    }
    default: break;
  }
};

const setPsychicStatusForInactiveChat = (
  status: string,
  setPsychicStatus: (status: string) => void,
): void => {
  switch (status) {
    case Status.AVAILABLE: {
      setPsychicStatus('');

      break;
    }
    case Status.BUSY: {
      setPsychicStatus(Status.BUSY);

      break;
    }
    case Status.OFFLINE: {
      setPsychicStatus(Status.OFFLINE);

      break;
    }
    default: setPsychicStatus('');
  }
};

const PsychicStatus = () => {
  const [psychicStatus, setPsychicStatus] = useState<string>(Status.CONNECTING);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const currentPsychicStatus = useSelector(selectCurrentPsychicStatus);

  useEffect(() => {
    if (!currentPsychicStatus) {
      return;
    }

    if (isLiveChatActive) {
      setPsychicStatusForActiveChat(currentPsychicStatus.toLowerCase(), setPsychicStatus);
    } else {
      setPsychicStatusForInactiveChat(currentPsychicStatus.toLowerCase(), setPsychicStatus);
    }
  }, [isLiveChatActive, currentPsychicStatus]);

  const statusClass = useMemo(() => (
    cn(styles.status, {
      [styles.statusEmpty]: psychicStatus === '',
      [styles.statusConnecting]: psychicStatus === Status.CONNECTING,
    })
  ), [psychicStatus]);

  return (
    <div className={styles.clientStatus}>
      <p className={statusClass}>{psychicStatus}</p>
    </div>
  );
};

export default PsychicStatus;
