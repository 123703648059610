import React from 'react';
import { PopUpNotification } from 'extracted-chat-components';

import {
  INACTIVE_CHAT_STATUSES,
  IS_SHOW_SWITCH_TO_PHONE,
  IS_SWITCH_TO_PHONE_BANNER,
} from 'constants/constants';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  SdkEvents,
  ErrorRequestType,
  View,
} from 'constants/enums';
import { API } from 'src/utils/api';
import { LocalStorage } from 'src/utils/storageHandler';
import { EndChatRequestPopUpProps } from 'types/componentTypes';
import { SentryMethods } from 'src/utils/sentryMethods';

const EndChatRequestPopUp: React.FC<EndChatRequestPopUpProps> = ({
  view,
  currentChat,
  isNotificationPopUp,
  popUpNotificationData,
  sdkCallbackStorage,
  setIsLiveChatActive,
  setChatMinutesActive,
  deleteCurrentChat,
  deleteNotifications,
  removePrevChatData,
  setLoadingState,
  showPopUpNotification,
  addMixpanelEventAlertTapped,
  setRequestErrorState,
  updateCallbackBanner,
  setShowInitModal,
  setInitChatBanner,
}) => {
  const handleRejectButton = () => {
    const user = currentChat.sideUser;

    addMixpanelEventAlertTapped({ alertType: 'end chat', alertSelection: 'cancel', user });
    showPopUpNotification(false);
  };

  const handleEndChat = (isCustomerView: boolean) => {
    setIsLiveChatActive(false);

    if (isCustomerView) {
      removePrevChatData();

      const onEndCallback = sdkCallbackStorage.get(SdkEvents.ON_END_CHAT);

      if (onEndCallback) {
        onEndCallback({ name: SdkEvents.ON_END_CHAT });
      }

      updateCallbackBanner();
    } else {
      deleteCurrentChat();
    }
  };

  const handleAcceptButton = async () => {
    const isCustomerView = view === View.CUSTOMER;
    const user = currentChat.sideUser;

    try {
      addMixpanelEventAlertTapped({ alertType: 'end chat', view: 'end chat', user });
      showPopUpNotification(false);
      setLoadingState(true);

      if (isCustomerView) {
        setChatMinutesActive(0);
        deleteNotifications();
        setShowInitModal(false);
        setInitChatBanner(false);
        LocalStorage.removeItem(IS_SHOW_SWITCH_TO_PHONE);
        LocalStorage.removeItem(IS_SWITCH_TO_PHONE_BANNER);
      }

      await API.Chat.endChatRequest(currentChat.chatId);

      handleEndChat(isCustomerView);
    } catch (e) {
      console.log(e);

      SentryMethods.captureException(e);
      setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT);

      if (e?.message === 'Network Error') {
        return;
      }

      const response = await API.Chat.getChatById(currentChat?.chatId);
      const chatStatus = response?.data?.chat?.status;

      if (chatStatus && INACTIVE_CHAT_STATUSES.includes(chatStatus)) {
        handleEndChat(isCustomerView);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const acceptButton = {
    text: 'End Chat',
    color: view === View.PSYCHIC ? ButtonColor.DANGER : ButtonColor.ORANGE,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  };

  const rejectButton = {
    text: 'Cancel',
    color: view === View.PSYCHIC ? ButtonColor.LIGHT_OUTLINE : ButtonColor.ONLY_TEXT,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: handleRejectButton,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default EndChatRequestPopUp;
