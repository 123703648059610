import { ConversationType, Message } from 'constants/enums';

export const getChatMessage = (body: string, currentChat, currentUser, view) => ({
  body,
  author: currentUser.friendlyName,
  to: currentChat.sideUser?.friendlyName,
  // @ts-ignore
  attributes: {
    participantType: currentUser.attributes?.participantType || view,
    requestType: currentChat.attributes?.requestType
      || ConversationType.LIVE_CHAT,
    messageType: Message.CLIENT,
  },
});
