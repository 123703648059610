import React from 'react';

import SystemMessage from './SystemMessage';
import TimestampMessage from './TimestampMessage';

export interface SystemWithTimestampMessagesProps {
  sid: string,
  systemMessage: string,
  timestampMessage: string,
  isPrevOffline: boolean,
  timestamp: number,
}

const SystemWithTimestampMessages: React.FC<SystemWithTimestampMessagesProps> = ({
  sid,
  systemMessage,
  timestampMessage,
  isPrevOffline,
  timestamp,
}) => (
  <>
    <SystemMessage
      key={sid}
      message={systemMessage}
      isPreviousOffline={isPrevOffline}
    />
    <TimestampMessage key={timestamp} message={timestampMessage} />
  </>
);

export default SystemWithTimestampMessages;
