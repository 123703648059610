import {
  put,
  select,
} from 'redux-saga/effects';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  PopUpNotificationType,
  SdkEvents,
} from 'constants/enums';
import { setVisibilityNotification } from 'actions/chatActions';
import {
  selectIsModeForShowingModals,
  selectRedirectState,
  selectSdkCallbackStorage,
} from 'selectors/selectors';
import {
  handlePsychicMissChat,
  setIsShownIncomingRequest,
  setPopUpNotificationData,
  showPopUpNotification,
} from 'actions/appActions';

export function* handlePsychicMissChatWorker({
  payload,
}: ReturnType<typeof handlePsychicMissChat>) {
  const { attributes, index } = payload;
  const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
  const redirectState = yield select(selectRedirectState);

  yield put(setIsShownIncomingRequest(false));
  const titleIntro = 'You missed the Chat because you didn\'t Accept within 90 seconds.';
  const title = attributes.logoutPsychic
    ? `${titleIntro} You have been logged off for Chat because you missed two Chat requests in a row.`
    : `${titleIntro} If you miss two Chats in a row, you will be logged off for Chat.`;

  const isNoModeOrCustomMode: boolean = yield select(selectIsModeForShowingModals);

  if (isNoModeOrCustomMode && !redirectState.isError) {
    yield put(showPopUpNotification(true));
    yield put(setPopUpNotificationData({
      title,
      notificationType: PopUpNotificationType.PSYCHIC_MISS_CHAT,
    }));
  }

  const ignoreCallback = sdkCallbackStorage.get(SdkEvents.ON_IGNORE_CHAT);
  const acceptCallback = sdkCallbackStorage.get(SdkEvents.ACCEPT_CHAT);

  if (ignoreCallback) {
    const data = {
      title,
      acceptButton: {
        text: 'OK',
        color: ButtonColor.WARNING,
        size: ButtonSize.S,
        shape: ButtonShape.ROUNDED,
      },
      isBigWidth: false,
    };
    ignoreCallback({ name: SdkEvents.ON_IGNORE_CHAT, data });

    if (acceptCallback) sdkCallbackStorage.delete(SdkEvents.ACCEPT_CHAT);
  }

  yield put(setVisibilityNotification(attributes, index));
}
