/* eslint-disable no-param-reassign */
import React from 'react';
import cn from 'classnames';

import {
  ConversationType,
  Message,
  View,
} from 'src/constants/enums';
import { MessagesInterface } from 'types/componentTypes';
import { MessageType } from 'types/objectTypes';
import { UserOfflineMessage, UserMessage } from 'components/Messages/Message';
import SystemMessageCases from 'components/Messages/Message/SystemMessageCases';
import styles from 'components/Messages/Messages.module.scss';

const Messages: React.FC<MessagesInterface> = ({
  messageContainer,
  scrollableMessageWrapper,
  messages,
  view,
  typingMessage,
  sideUser,
  currentUser,
  isPreviousOffline,
  getMessageNotifications,
  makeEmergencyScroll,
  getJoinedAndTimestampNotification,
  getEndChatWithTimestampNotification,
  getBillingMessage,
}) => {
  const typingStyles = cn(
    styles.currentlyTyping,
    view === View.CUSTOMER
      ? styles.currentlyTypingCustomer
      : styles.currentlyTypingPsychic,
    typingMessage
      ? styles.visible
      : styles.hidden,
  );
  /*
  * This variable is used instead of @typingMessage due to some IOS
  * devices treat 'absolute' position not in standard way and
  * @typingMessage variable invoke unnecessary scrollHeight movements
  * at some IOS devices
   */

  return (
    <div ref={scrollableMessageWrapper} className={styles.scrollableWrapper}>
      <div ref={messageContainer} className={styles.containerMessages}>
        <div className={typingStyles}>{typingMessage}</div>
        {currentUser.friendlyName
          && sideUser?.friendlyName
          && messages?.map((message: MessageType, i: number) => {
            const attributes = message?.attributes;

            if (!attributes) {
              return null;
            }

            if (attributes && attributes.canceledBy === 'psychic' && attributes.canceled) {
              const declineReason = messages[i + 1];

              if (declineReason && declineReason.attributes) {
                declineReason.attributes.declinedByPsychic = true;

                if (message.attributes) {
                  declineReason.attributes.canceledDate = message.attributes.canceledDate;
                }
              }

              messages[i + 1] = declineReason;
            }

            const { participantType } = attributes;
            const messageType: Message | undefined = attributes.messageType || Message.CLIENT;
            const uniqueKey = `${message.sid}-${i}`;

            switch (messageType) {
              case Message.CLIENT: {
                const isDirectMessage = attributes.requestType === ConversationType.DIRECT_MESSAGE;
                const messageAuthor: string = (participantType === view)
                  ? currentUser.friendlyName
                  : sideUser.friendlyName;

                const isPrevOffline = isPreviousOffline.current;

                if (isDirectMessage) {
                  const isLastMessageInChat = (i + 1 === messages.length);
                  isPreviousOffline.current = true;

                  return (
                    <UserOfflineMessage
                      key={uniqueKey}
                      message={message}
                      author={messageAuthor}
                      view={view}
                      isLastMessageInChat={isLastMessageInChat}
                      isPreviousOffline={isPrevOffline}
                      makeEmergencyScroll={makeEmergencyScroll}
                    />
                  );
                }

                isPreviousOffline.current = false;

                return (
                  <UserMessage
                    key={uniqueKey}
                    message={message}
                    author={messageAuthor}
                    view={view}
                    isPreviousOffline={isPrevOffline}
                  />
                );
              }
              case Message.SYSTEM: return (
                <SystemMessageCases
                  key={uniqueKey}
                  isPreviousOffline={isPreviousOffline}
                  attributes={attributes}
                  message={message}
                  view={view}
                  getBillingMessage={getBillingMessage}
                  getEndChatWithTimestampNotification={getEndChatWithTimestampNotification}
                  getJoinedAndTimestampNotification={getJoinedAndTimestampNotification}
                  getMessageNotifications={getMessageNotifications}
                />
              );
              default: break;
            }
          })}
      </div>
    </div>
  );
};

export default Messages;
