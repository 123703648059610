import {
  all,
  call,
  put,
  putResolve,
  select,
  take,
} from 'redux-saga/effects';

import {
  CUSTOMER_ID_STORAGE,
  DIFFERENCE_IN_TIME,
  IS_LOAD_MORE_BOTTON_CLICKED_KEY,
  IS_MOBILE_INTEGRATION,
  IS_PRIORITY_TOOLTIP_SEEN,
  NC_FLOW,
  PSYCHIC_ID_STORAGE,
} from 'constants/constants';
import {
  View,
  MixpanelUserTypes,
  TimeDuration,
} from 'constants/enums';
import {
  handleReceivedActivePsychicChats,
  setChatRequest,
  setTwilioClient,
  setPsychicSchedules,
  setPsychicCallbackQueue,
} from 'actions/chatActions';
import { getTwilioClient } from 'src/utils/twilio';
import {
  selectCurrentChat,
  selectCurrentUser,
  selectCustomerId,
  selectExtId,
  selectTwilioClient,
  selectView,
  selectUserAgent,
  selectIsHistoryMode,
} from 'selectors/selectors';
import {
  checkDiffInTime,
  getSystemChannel,
  handleRequestError,
  setLoadingState,
  setIsPsychicNotificationsEnable,
  setIsDeclineOMEnabled,
  setIsPsychicLoginOutsideOfSchedule,
  setPsychicLoginOutsideOfScheduleMsg,
  setPsychicLoginOutsideLastActivityId,
  setPsychicLoginOutsidePopupShow,
  setNotificationUnreadCount,
  setIsChatAutoReloadEnabled,
  setAutoReloadPromptLabels,
} from 'actions/appActions';
import {
  setPriorityActionMessage,
  setPsychicNoteConfigurableData,
  setPsychicNotificationsActive,
} from 'actions/psychicNotifications';
import { fetchPriorityActionList } from 'src/redux/sagas/psychicNotifications/psychicNotificationsSaga';
import { API } from 'src/utils/api';
import * as types from 'actions/actionsTypes';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  getPlatform,
  checkStartingChat,
  descriptionFromError,
  checkIsPsychicLoginInsideOfSchedule,
} from 'src/utils/commonUtil';
import { getSystemChannelWorker } from 'src/redux/sagas/app/getSystemChannelSaga';
import { setUpNotifications } from 'src/redux/sagas/app/setUpNotificationsSaga';
import { setUser } from 'src/redux/sagas/app/setUserSaga';
import MixpanelActions from 'src/utils/mixpanel';
import { SentryMethods } from 'src/utils/sentryMethods';
import MixpanelPsychicActions from 'src/utils/mixpanelPsychicActions';
import { getCurrentDate } from 'src/utils/dateHandler';

function* initCustomerApplicationWorker({
  customerId,
  currentUser,
  view,
  extId,
}) {
  LocalStorage.setItem(CUSTOMER_ID_STORAGE, customerId);

  const currentDifferenceInTime = LocalStorage.getItem(DIFFERENCE_IN_TIME) || 0;
  const pathname = yield select((state) => state.app.location);
  const isStartChat = yield call(checkStartingChat, pathname);
  const isHistoryMode = yield select(selectIsHistoryMode);

  if (!currentUser.friendlyName) {
    const [version, user] = yield all([
      call(API.App.getChatVersionDetail),
      call(API.Customer.getCustomerById, customerId),
      call(getSystemChannelWorker, getSystemChannel(view)),
    ]);
    yield put(checkDiffInTime(
      user?.data?.serverDateTime,
      currentDifferenceInTime,
    ));
    yield call(setUser, user, View.CUSTOMER);

    yield putResolve(setIsChatAutoReloadEnabled(version.chatAutoReloadEnabled));

    yield putResolve(
      setAutoReloadPromptLabels(JSON.parse(version?.autoReloadSystemMessages)),
    );
  }

  if (isStartChat && !isHistoryMode) {
    yield put(setChatRequest(customerId as string, extId as string));
  }
}

function* registerMixpanelUser(user) {
  const { extId, friendlyName, customerPrice } = user.data || {};
  const userAgent = yield select(selectUserAgent);

  if (!extId || !friendlyName || !customerPrice) {
    return;
  }

  MixpanelPsychicActions.register({
    extId,
    friendlyName,
    customerPrice,
  });

  MixpanelActions.register({
    extId,
    friendlyName,
    customerPrice,
    userType: MixpanelUserTypes.EXISTING_CUSTOMER,
    chatSource: getPlatform(userAgent, 'mixpanel'),
  });
}

function* initPsychicApplicationWorker({ extId, view }) {
  LocalStorage.setItem(PSYCHIC_ID_STORAGE, extId);
  sessionStorage.removeItem(IS_LOAD_MORE_BOTTON_CLICKED_KEY);
  sessionStorage.removeItem(IS_PRIORITY_TOOLTIP_SEEN);

  const currentDifferenceInTime = LocalStorage.getItem(DIFFERENCE_IN_TIME) || 0;

  const [version, user, chatsResponse] = yield all([
    call(API.App.getVersionDetail),
    call(API.Psychic.getPsychic, extId),
    call(API.Psychic.getPsychicActiveChats, extId),
    call(getSystemChannelWorker, getSystemChannel(view)),
    call(fetchPriorityActionList, { payload: { showLoader: false } }),
  ]);

  const {
    clientLastNoteLockedCycle,
    clientSendNoteLimit,
    psychicMaxNotificationsSend,
    psychicNotesSystemMessages,
    psychicNotificationRefreshEveryMins,
    psychicNotificationsEnabled,
    psychicGamifyFeatureEnabled,
    priorityClientsActionMessages,
  } = version;

  yield call(setUser, user, View.PSYCHIC);

  yield all([
    putResolve(setIsPsychicNotificationsEnable(psychicNotificationsEnabled)),
    putResolve(setIsDeclineOMEnabled(user.data.declineOMEnabled)),
    putResolve(setPsychicNotificationsActive(user.data.isClientNotificationsEnabled)),
    putResolve(setNotificationUnreadCount(user.data.unreadNotificationsCount)),
    putResolve(setPriorityActionMessage(JSON.parse(priorityClientsActionMessages))),
    putResolve(setPsychicNoteConfigurableData({
      clientLastNoteLockedCycle,
      clientSendNoteLimit,
      psychicMaxNotificationsSend,
      psychicGamifyFeatureEnabled,
      psychicNotificationRefreshEveryMins,
      psychicNotesSystemMessages: JSON.parse(psychicNotesSystemMessages),
    })),
  ]);

  yield all([
    call(registerMixpanelUser, user),
    putResolve(handleReceivedActivePsychicChats(chatsResponse.data.chats)),
    take([types.ACTIVE_CHATS_LOADED, types.EMIT_ERROR_NOTIFICATION]),
  ]);
  yield put(checkDiffInTime(user?.data?.serverDateTime, currentDifferenceInTime));
}

function getPsychicLoginOutsideOfScheduleMsgByActivityID(activityId) {
  let msg = '';

  switch (activityId) {
    case 13:
      msg = TimeDuration.SPECIAL_REQUEST;

      break;
    case 8:
      msg = TimeDuration.ONE_TO_TWO;

      break;
    case 9:
      msg = TimeDuration.THREE_TO_FOUR;

      break;
    case 10:
      msg = TimeDuration.FIVE_PLUS;

      break;
    default:
      msg = TimeDuration.UNTIL_COVERAGE_IMPORVES;

      break;
  }

  return msg;
}

export function* initApplicationWorker() {
  const view = yield select(selectView);

  try {
    const prevClient = yield select(selectTwilioClient);
    const customerId = yield select(selectCustomerId);
    const extId = yield select(selectExtId);
    const currentUser = yield select(selectCurrentUser);
    const currentChat = yield select(selectCurrentChat);
    const isHistoryMode = yield select(selectIsHistoryMode);
    const isShowLoader = view === View.PSYCHIC
      ? !currentUser.friendlyName
      : (currentChat.chatId && currentChat.status !== NC_FLOW);

    if (isShowLoader || isHistoryMode) {
      yield put(setLoadingState(true));
    }

    if (!prevClient.connectionState) {
      const client = yield call(getTwilioClient);
      yield put(setTwilioClient(client));
    }

    if (view === View.CUSTOMER) {
      yield call(
        initCustomerApplicationWorker,
        {
          customerId,
          currentUser,
          view,
          extId,
        },
      );
    }

    if (view === View.PSYCHIC) {
      const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

      if (!isMobileIntegration) {
        const psychicCallbackqueue = yield call(API.Psychic.getCallbackqueue, extId);
        const dashboardDetailsResult = yield call(API.Psychic.getDashboardDetails, extId);

        if (
          dashboardDetailsResult?.isSuccess
        && dashboardDetailsResult?.result?.psychicLatestActivityID
        ) {
          yield put(setPsychicLoginOutsideLastActivityId(
            dashboardDetailsResult.result.psychicLatestActivityID,
          ));
          yield put(setPsychicLoginOutsidePopupShow(false));
          const currentDate = getCurrentDate();
          const psychicSchedule = yield call(API.Psychic.getSchedule, extId, currentDate);

          if (psychicSchedule?.isSuccess) {
            const { result, totalTimeInMinute } = psychicSchedule;
            const schedules = result ? result[currentDate] : '';
            yield put(setPsychicSchedules({ schedules, totalTimeInMinute }));
            yield put(setIsPsychicLoginOutsideOfSchedule(
              !checkIsPsychicLoginInsideOfSchedule(schedules),
            ));
          } else {
            yield put(setIsPsychicLoginOutsideOfSchedule(true));
          }

          const scheduleMessage = getPsychicLoginOutsideOfScheduleMsgByActivityID(
            dashboardDetailsResult.result.psychicLatestActivityID,
          );

          if (psychicCallbackqueue?.isSuccess) {
            const { callBackQueueData } = psychicCallbackqueue;
            const outdialIDs: any = [];
            let endId = -1;
            for (let index = 0; index < callBackQueueData.length; index += 1) {
              const queueData: any = callBackQueueData[index];

              if (queueData.screened) {
                outdialIDs.push(queueData.outdialID);
                endId = index;
              }
            }
            const callbackQueue = callBackQueueData.map((queueData) => (
              { ...queueData, screened: 1 }
            ));
            yield put(setPsychicCallbackQueue(callbackQueue));

            if (dashboardDetailsResult.result.psychicLatestActivityID === 11) {
              if (endId === -1) {
                yield put(setPsychicLoginOutsideOfScheduleMsg(''));
              } else if (endId === 0) {
                yield put(setPsychicLoginOutsideOfScheduleMsg(`Until I clear my queue, caller ${endId + 1}`));
              } else {
                yield put(setPsychicLoginOutsideOfScheduleMsg(`Until I clear my queue, callers 1 - ${endId + 1}`));
              }
            } else {
              yield put(setPsychicLoginOutsideOfScheduleMsg(scheduleMessage));
            }
          } else if (dashboardDetailsResult.result.psychicLatestActivityID !== 11) {
            yield put(setPsychicLoginOutsideOfScheduleMsg(scheduleMessage));
          }
        } else if (psychicCallbackqueue?.isSuccess) {
          const { callBackQueueData } = psychicCallbackqueue;
          const callbackQueue = callBackQueueData.map((queueData) => (
            { ...queueData, screened: 1 }
          ));
          yield put(setPsychicCallbackQueue(callbackQueue));
        }
      }
    }

    if (view === View.PSYCHIC && !currentUser.friendlyName) {
      yield call(initPsychicApplicationWorker, { extId, view });
    }

    yield call(setUpNotifications);
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    const isPsychicView = view === View.PSYCHIC;
    const isInvalidToken = e?.message === 'Invalid Access Token';
    const requestErrorPayload = {
      redirectPath: isPsychicView ? '/login' : '',
      isInvalidToken,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}
