import {
  put,
  call,
  select,
} from 'redux-saga/effects';

import {
  View,
  Status,
  PopUpNotificationType,
  PopUpNotification,
  CustomerBusyReason,
} from 'constants/enums';
import {
  NC_FLOW,
  FUNDS_TO_ADD,
  CUSTOMER_BALANCE,
  CUSTOMER_IS_BUSY_ERROR_CODE,
  PSYCHIC_UNAVAILABLE_ERROR_CODE,
} from 'constants/constants';
import { API } from 'src/utils/api';
import {
  getPlatform,
  removeInlineNotificationFromLocalStorage,
  kountSessionIDHandler,
} from 'src/utils/commonUtil';
import { roundToWhole } from 'src/utils/helpers';
import {
  setIsLiveChatActive,
  setLoadingState,
  setPopUpNotificationData,
  showPopUpNotification,
  setShowInitModal,
  setIsVisibleHeaderBanner,
  handleRequestError,
  setIsCreateChatRequest,
  setIsChatInInitState,
} from 'actions/appActions';
import {
  setFunds,
  setChatRequest,
  sideUserRequest,
  setCurrentChat,
  setCurrentPsychicStatus,
  handleCreateChatErrors,
  setInitChatBanner,
  setCustomerBalance,
  setChatChannelAndLoadMessages,
} from 'actions/chatActions';
import {
  selectCurrentChat,
  selectSideUser,
  selectUserAgent,
  selectIsLoading,
  selectInitChatModalState,
  selectCurrentPsychicStatus,
  selectChannel,
} from 'selectors/selectors';
import { LocalStorage } from 'src/utils/storageHandler';
import { messages } from 'src/services/messages';
import { checkTwilioTokenLifetime } from 'src/redux/sagas/chat/commonSaga';
import { SentryMethods } from 'src/utils/sentryMethods';

function* handleCreatingChat({
  customerId,
  extId,
  deviceId,
  userAgent,
  isFinishCustomerActiveChats = false,
}) {
  const channel = select(selectChannel);
  const response = yield call(
    API.Chat.createChatRequest,
    {
      customerRefIdEnc: customerId as string,
      psychicRefId: extId as string,
      deviceId,
      sourcePlatform: getPlatform(userAgent),
      finishCustomerActiveChats: isFinishCustomerActiveChats,
      KountSessionID: yield call(kountSessionIDHandler),
    },
  );

  if (!response?.data) {
    return;
  }

  if (isFinishCustomerActiveChats) {
    yield call(API.Chat.startChatRequest, response.data.chat.chatId);
    yield put(setShowInitModal(true));
    yield put(setInitChatBanner(true));
  }

  if (!channel) {
    yield call(setChatChannelAndLoadMessages, response.data.chat.chatChannelSid);
  }

  const {
    chat,
    fundsToAdd,
    customerBalanceBeforeChat: customerBalance,
  } = response.data;

  if (fundsToAdd) {
    const funds: number | string = roundToWhole(fundsToAdd);

    LocalStorage.setItem(FUNDS_TO_ADD, funds as string);
    yield put(setFunds(+funds > 0 ? funds as string : ''));
  }

  if (customerBalance) {
    LocalStorage.setItem(CUSTOMER_BALANCE, customerBalance as string);
    yield put(setCustomerBalance(customerBalance));
  }

  return chat;
}

function* handlePsychicUnavailableError(sideUser) {
  yield put(setIsLiveChatActive(false));
  yield put(showPopUpNotification(true));
  yield put(setShowInitModal(false));
  yield put(setIsChatInInitState(false));
  yield put(setInitChatBanner(false));
  const psychicStatus = yield select(selectCurrentPsychicStatus);
  const title = (psychicStatus === 'onbreak') ? messages.psychicOnBreak : messages.notAvailablePsychic(sideUser?.friendlyName);
  yield put(setPopUpNotificationData({
    title,
    notificationType: PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
  }));
}

function* handleCustomerIsBusyErrors(reason, psychicName) {
  const isChatReason = reason === CustomerBusyReason.CHAT;

  yield put(showPopUpNotification(true));
  yield put(setPopUpNotificationData({
    title: isChatReason
      ? messages.activeChatCustomerSide(psychicName)
      : messages.activePhoneCallCustomerSide(psychicName),
    notificationType: isChatReason
      ? PopUpNotification.ACTIVE_CHAT
      : PopUpNotification.ACTIVE_PHONE_CALL,
  }));
}

export function* handleStartChatErrors({ payload }: ReturnType<any>) {
  const { data, error } = payload || {};

  if (!error) {
    return;
  }

  const sideUser = yield select(selectSideUser);
  const errorCode = error.code;

  switch (errorCode) {
    case PSYCHIC_UNAVAILABLE_ERROR_CODE: {
      yield call(handlePsychicUnavailableError, sideUser);

      break;
    }
    case CUSTOMER_IS_BUSY_ERROR_CODE: {
      const { lockReason, psychicName } = data || {};

      yield call(handleCustomerIsBusyErrors, lockReason, psychicName);

      break;
    }
    default: yield put(handleRequestError());
  }
}

export function* startChat({
  payload,
}: ReturnType<typeof setChatRequest>) {
  let isLoading = yield select(selectIsLoading);
  const isInitChatModal = yield select(selectInitChatModalState);
  const isCreateChatPending = yield select((state) => state.app.isCreateChatPending);
  yield call(checkTwilioTokenLifetime);

  try {
    if (!isLoading && !isInitChatModal) {
      yield put(setLoadingState(true));
      isLoading = true;
    }

    const userAgent = yield select(selectUserAgent);
    // eslint-disable-next-line no-underscore-dangle
    const deviceId = userAgent?._ua;
    let chat = yield select(selectCurrentChat);

    if (chat.status === NC_FLOW && chat.chatId) {
      yield call(
        handleCreatingChat, {
          ...payload,
          deviceId,
          userAgent,
        },
      );
    }

    if (!chat.chatId && !isCreateChatPending) {
      yield put(sideUserRequest(payload.extId as string, View.CUSTOMER, false, true));
      yield put(setIsVisibleHeaderBanner(false));
      yield put(setIsCreateChatRequest(true));
      removeInlineNotificationFromLocalStorage();

      chat = yield call(handleCreatingChat, {
        ...payload,
        deviceId,
        userAgent,
      });

      chat.attributes = { requestType: chat?.type };
      yield put(setCurrentChat(chat));
    }

    yield put(setIsLiveChatActive(true));
    yield put(setCurrentPsychicStatus(Status.CONNECTING));
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    yield put(handleCreateChatErrors(e.response?.data));
  } finally {
    yield put(setIsCreateChatRequest(false));

    if (isLoading) {
      yield put(setLoadingState(false));
    }
  }
}
