import React from 'react';
import cn from 'classnames';

import PsychicStatus from 'components/PsychicStatus';
import HeaderButton from 'components/Buttons/HeaderButton';
import { ClientHeaderProps } from 'types/componentTypes';
import { SWITCH_TO_PHONE_BANNER, PSYCHIC_NAME_STRING_LIMIT_ON_CLIENT_SIDE } from 'constants/constants';
import { truncateString } from 'src/utils/commonUtil';

import AutoReloadHeader from './AutoReloadHeader';
import styles from './ClientHeader.module.scss';

const ClientHeader: React.FC<ClientHeaderProps> = ({
  imgClient,
  name,
  isVisibleEndChat,
  isVisibleBtnPhone,
  isLiveChatActive,
  typeHeaderBanner,
  isVisibleHeaderBanner,
  isLostConnection,
  rate,
  baseRate,
  handleCallButton,
  handleEndChatButton,
  handleCloseChat,
  headerRef,
  isHistoryMode,
  isInitChatModal,
  isChatInInitState,
  fundsToAdd,
  isShowAutoReloadToggle,
}) => {
  const isPhoneBanner = typeHeaderBanner === SWITCH_TO_PHONE_BANNER
    && isVisibleHeaderBanner && !isLostConnection;
  const isDiscountedRate = baseRate !== rate;
  const clientClassnames = isDiscountedRate ? styles.clientWithDiscountedRate : styles.client;

  return (
    <header ref={headerRef} className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={clientClassnames}>
            <div className={styles.statusContainer}>
              <img
                alt=""
                className={styles.imgClient}
                src={imgClient}
              />
            </div>
            <div className={styles.rateContainer}>
              <h2>
                {
                  name.length > 8
                    ? truncateString(
                      name,
                      PSYCHIC_NAME_STRING_LIMIT_ON_CLIENT_SIDE,
                    )
                    : name
                }
              </h2>
              <p className={isDiscountedRate ? styles.baseRate : ''}>
                $
                {baseRate?.toFixed(2)}
                /min
              </p>
              <p className={isDiscountedRate ? styles.discountedRate : styles.hidden}>
                {isDiscountedRate && `$${rate.toFixed(2)}/min`}
              </p>
              {!isHistoryMode && <PsychicStatus />}
            </div>
          </div>
          {!isHistoryMode && (
            <div className={styles.btnHeaders}>
              <div className={styles.btnSwitchToPhone}>
                <div className={isPhoneBanner
                  ? styles.btnSwitchToPhoneNotification
                  : styles.btnNotificationHidden}
                />
                <HeaderButton
                  id="switchToPhoneHeaderBtn"
                  handleEndChatButton={handleCallButton}
                  isVisible={isVisibleBtnPhone}
                  isChatEnd={false}
                />
              </div>
              <HeaderButton
                id="endChatHeaderBtn"
                handleEndChatButton={handleEndChatButton}
                isVisible={isVisibleEndChat}
                isChatEnd
              />
            </div>
          )}
          {(!isLiveChatActive || isChatInInitState || isHistoryMode) && (
            <button
              type="button"
              onClick={handleCloseChat}
              className={cn(isInitChatModal && !isHistoryMode ? styles.cancelBtn : styles.closeBtn)}
            >
              {(isInitChatModal && !isHistoryMode)
                ? 'Cancel'
                : (<span className={styles.close} />)}
            </button>
          )}
        </div>
        { isShowAutoReloadToggle && <AutoReloadHeader fundsToAdd={fundsToAdd} /> }
      </div>
    </header>
  );
};

export default ClientHeader;
