import React from 'react';
import { InteractChatButton } from 'extracted-chat-components';
import { ButtonColor, ButtonSize } from 'extracted-chat-components/enums';

import TabButton from 'components/Buttons/TabButton';

import styles from './TabPanel.module.scss';

const TabPanel = ({
  tabs,
  onChangeTab,
  activeTab,
  withBadges,
  children,
  goToMyStatusHandler,
  checkIsTabDisabled = (tab) => !tab,
  disabled,
}) => (
  <div className={styles.pageWrapper}>
    <div
      className={styles.tabPanelWrapper}
    >
      <div
        aria-disabled={disabled}
        className={styles.tabPanelWrapperHeaderScroll}
      >
        {tabs.map((tab) => (
          <TabButton
            disabled={checkIsTabDisabled(tab)}
            key={tab.id}
            tabId={tab.id}
            isActiveTab={activeTab === tab.id}
            title={tab.title}
            onChangeTab={onChangeTab}
            iconPrefix={tab.iconPrefix}
            withBadges={withBadges}
          />
        ))}
      </div>
      {disabled && (
        <InteractChatButton
          size={ButtonSize.L}
          color={ButtonColor.LIGHT_OUTLINE}
          isActive
          text="Go to My status Page"
          onClick={goToMyStatusHandler}
          className={styles.redirectToStatusPage}
        />
      )}
    </div>
    {!children && <div />}
    {children?.length ? children.find((element) => element.key === activeTab) : children}
  </div>
);

export default TabPanel;
