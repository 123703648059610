import React from 'react';

import style from './DonutProgressBar.module.scss';

const DonutProgressBar = ({ progress, gradients }) => {
  const strokeWidth = 10;
  const radius = 60;
  const circumference = 2 * Math.PI * radius;

  const strokeDashoffset = (185 + ((circumference / 2) * (1 - progress / 100))) + 4;

  return (
    <svg className={style.progressRing} width="100" height="100">
      <defs>
        {gradients.map((gradient, index) => (
          <linearGradient id={`gradient${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
            {gradient.map((color, index) => (
              <stop offset={`${index * (100 / (gradient.length - 1))}%`} stopColor={color} />
            ))}
          </linearGradient>
        ))}
      </defs>
      <circle
        className={style.progressRingBackground}
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: 185,
        }}
        cx="73"
        cy="50"
      />
      <circle
        className={style.progressRingCircle}
        strokeWidth={strokeWidth}
        stroke="url(#gradient0)"
        strokeLinecap="round"
        fill="transparent"
        r={radius}
        cx="73"
        cy="50"
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset,
        }}
      />
    </svg>
  );
};

export default DonutProgressBar;
