import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import styles from 'components/Tooltips/StyledTooltip/StyledTooltip.module.scss';

const StyledTooltip = ({ title, children }) => (
  <Tooltip title={title} classes={{ tooltip: styles.customTooltip }}>
    {children}
  </Tooltip>
);

export default StyledTooltip;
