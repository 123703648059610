import React from 'react';
import Checkbox from '@mui/material/Checkbox';

import styles from 'components/PsychicNotifications/PsychicNotifications.module.scss';

const StyledCheckbox = (props) => (
  <Checkbox
    disableRipple
    color="default"
    checkedIcon={<span className={styles.checkedIcon} />}
    inputProps={{ 'aria-label': 'decorative checkbox' }}
    {...props}
  />
);

export default StyledCheckbox;
