import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  SdkEvents,
  View,
} from 'constants/enums';
import { goTo } from 'route-history';
import { LocalStorage } from 'src/utils/storageHandler';
import { ErrorNotificationProps } from 'types/componentTypes';
import { REDIRECT_PATH } from 'constants/constants';
import {
  getCookie,
  setCookie,
  logWithStyles,
  redirectUserToWebsiteOrNativeApp,
} from 'src/utils/commonUtil';

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  isNotificationPopUp,
  popUpNotificationData,
  isRequestError,
  view,
  sdkCallbackStorage,
  redirectState,
  destroySessionSdk,
  deleteCurrentChat,
  clearExtId,
  setShowInitModal,
  setIsLiveChatActive,
  showPopUpNotification,
  setRedirectState,
  setRequestErrorState,
  removePopUpNotification,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    LocalStorage.setItem(REDIRECT_PATH, redirectState?.path);
  }, [redirectState]);

  const isClientHub = pathname.includes('/clienthub');
  const isClose = isClientHub && popUpNotificationData?.reason;

  const updateAuthCookie = () => {
    try {
      const name = 'auth'
      const authCookie = getCookie(name);

      if (authCookie) {
        const authData = JSON.parse(decodeURIComponent(authCookie));
        authData.loggedIn = false;
        logWithStyles(`UPDATING LOGGED-IN ${authData.loggedIn} IN AUTH`);

        const newAuthData = JSON.stringify(authData);
        setCookie(name, newAuthData, '.californiapsychics.com');
      }
    } catch (err) {
      logWithStyles(`ERROR IN UPDATING AUTH COOKIE:- ${err.message}`);
    }
  }

  const handleRedirect = () => {
    const onLogoutCallback = sdkCallbackStorage.get(SdkEvents.ON_LOGOUT);
    const isCustomerView = view === View.CUSTOMER;

    if (redirectUserToWebsiteOrNativeApp()) {
      return;
    }

    if (isClientHub) {
      updateAuthCookie();
      window.parent.location.href = `${process.env.CALIFORNIA_PSYCHICS_API}/sign-in`;

      return;
    }

    if (onLogoutCallback) {
      destroySessionSdk(false);

      return;
    }

    if (isCustomerView) {
      goTo('/psychics?view=customer');

      return;
    }

    if (!isCustomerView && redirectState?.isRedirect) {
      goTo(redirectState?.path);
      setRedirectState(false, '');
    }
  };

  const handleAcceptButton = () => {
    showPopUpNotification(false);
    LocalStorage.setItem('isVisibleNotification', '');
    deleteCurrentChat();
    clearExtId();
    removePopUpNotification();
    setRequestErrorState(false, '');
    setShowInitModal(true);
    setIsLiveChatActive(false);
    handleRedirect();
  };

  const acceptButton = {
    text: isClose ? 'Close' : 'Okay',
    color: ButtonColor.WARNING,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  };

  return (
    <PopUpNotification
      isRequestError={isRequestError}
      isOpen={isNotificationPopUp}
      description={popUpNotificationData.description}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      isBigWidth={false}
    />
  );
};

export default ErrorNotification;
