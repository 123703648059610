import React from 'react';
import { Switch } from '@mui/material';

import styles from 'components/PsychicNotifications/PsychicNotifications.module.scss';

const MuteSwitch = (props) => (
  <Switch
    disableRipple
    classes={{
      thumb: styles.customSwitchThumb,
      track: styles.customSwitchTrack,
      checked: styles.customSwitchChecked,
      colorSecondary: styles.customSwitchColorSecondary,
    }}
    {...props}
  />
);

export default MuteSwitch;
