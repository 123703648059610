import React from 'react';

import { ReactComponent as PlusIcon } from 'src/assets/svg/plus-svgrepo-com.svg';
import { IMuteInformation } from 'types/componentTypes';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';

const MuteInformation: React.FC<IMuteInformation> = ({
  isMuteInfoShown,
  toggleMutedInfoHandler,
}) => {
  if (!isMuteInfoShown) {
    return null;
  }

  return (
    <div className={styles.showMutedInfoBottom}>
      <div className={styles.alignItem}>
        <PlusIcon onClick={toggleMutedInfoHandler} />
        <span className={styles.mutedIncludesPeopleAllNotificationsOff}>
          <span className={styles.textArrow} />
          “Muted” includes people with all notifications off.
        </span>
      </div>
    </div>
  );
};

export default MuteInformation;
