import {
  select, takeEvery, call, put,
} from 'redux-saga/effects';

import { API } from 'src/utils/api';
import { SentryMethods } from 'src/utils/sentryMethods';
import * as types from 'actions/actionsTypes';
import { setReminderOptions } from 'actions/reminderOptionsActions';
import { selectExtId } from 'selectors/selectors';

export function* setConfigsSaga({ payload }) {
  try {
    const extId = yield select(selectExtId);

    const {
      isAlertByPDAEnabled,
      isAlertByPRTAppEnabled,
      isAlertByEmailEnabled,
      isAlertBySMSEnabled,
      isDefaultReminderEnabled,
      phoneNumber,
      isRemindersEnabled = false,
      defaultReminderHours = 2,
    } = payload;

    const dataRequest = {
      ExternalReferenceId: +extId,
      IsAlertByPDAEnabled: isAlertByPDAEnabled,
      IsAlertByPRTAppEnabled: isAlertByPRTAppEnabled,
      IsAlertByEmailEnabled: isAlertByEmailEnabled,
      IsAlertBySMSEnabled: isAlertBySMSEnabled,
      IsDefaultReminderEnabled: isDefaultReminderEnabled,
      SMSAlertPhoneNumber: phoneNumber,
      IsRemindersEnabled: isRemindersEnabled,
      DefaultReminderHours: defaultReminderHours,
    };

    const response = yield call(API.Psychic.setConfigs, dataRequest);

    if (response.isSuccess) {
      yield put(setReminderOptions(payload));
    }
  } catch (e) {
    yield call(SentryMethods.captureException, e);
  }
}

export function* reminderSagaWatcher() {
  yield takeEvery(types.UPDATE_REMINDER_CONFIGS, setConfigsSaga);
}
