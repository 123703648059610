import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import LoaderContainer from 'components/Loader';
import { View } from 'constants/enums';
import { goTo } from 'route-history';
import { VIEW } from 'constants/constants';
import PopUpNotificationContainer from 'components/Popups/PopUpNotification/PopUpNotificationContainer';
import {
  selectAuthToken,
  selectSdkVisibilityMode,
  selectTwilioToken,
  selectView,
} from 'selectors/selectors';

const PrivateRouteSdk: React.FC<any> = ({ sideUser }) => {
  const { search } = useLocation();
  const { queryView, isDestroyed } = qs.parse(search);

  const view = useSelector(selectView);
  const twilioToken = useSelector(selectTwilioToken);
  const token = useSelector(selectAuthToken);
  const sdkVisibilityMode = useSelector(selectSdkVisibilityMode);

  if (isDestroyed && queryView === View.PSYCHIC) {
    goTo(`login?${VIEW}=${queryView.toLowerCase()}`);
  }

  useEffect(() => {
    const customerRedirectCondition = view === View.CUSTOMER && token;
    const psychicRedirectCondition = view === View.PSYCHIC
        && twilioToken && token;

    if (customerRedirectCondition || psychicRedirectCondition) {
      goTo(`conversations?${VIEW}=${view}`);
    }
  }, [token, view, twilioToken, sideUser, sdkVisibilityMode]);

  return (
    <>
      <PopUpNotificationContainer />
      <LoaderContainer withBackground />
    </>
  );
};

export default PrivateRouteSdk;
