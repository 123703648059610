import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{width: number, height: number}>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isMobileForClientHub, setIsMobileForClientHub] = useState<boolean>(false);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (width <= 654) {
      setIsMobileForClientHub(true);
    } else {
      setIsMobileForClientHub(false);
    }
  }, [width]);

  return { isMobile, isMobileForClientHub };
}
