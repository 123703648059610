import React from 'react';

import Toggle from 'components/Toggles/Toggle';
import { ToggleTypes } from 'constants/enums';
import { ITogglePanel } from 'types/componentTypes';
import BreakButton from 'components/Buttons/BreakButton';
import style from 'components/StatusBar/Statusbar.module.scss';

const TogglePanel: React.FC<ITogglePanel> = ({
  phoneStatus,
  onChangePhoneStatus,
  chatStatus,
  isPsychicAvailable,
  showBreakPopup,
  onChangeChatStatus,
  isPsychicLoginOutsideOfSchedule,
  psychicLoginOutsideOfScheduleMsg,
  psychicLoginOutsideLastActivityId,
  onClickEditOutsideSchedule,
  isOnCall,
  isOnChat,
}) => (
  <>
    <fieldset className={style.fieldSet}>
      <Toggle
        title="Phone"
        isChecked={phoneStatus}
        toggleType={ToggleTypes.ONLINE_OFFLINE}
        onChange={onChangePhoneStatus}
        isBusy={isOnCall}
      />
      <Toggle
        title="Chat"
        isChecked={chatStatus}
        toggleType={ToggleTypes.ONLINE_OFFLINE}
        onChange={onChangeChatStatus}
        isBusy={isOnChat}
      />
    </fieldset>

    {
      (isPsychicLoginOutsideOfSchedule && psychicLoginOutsideLastActivityId > 0
        && (phoneStatus || isOnCall))
      && (
        <div className={style.loginOutsideDivSchedule}>
          <p className={style.howLongText}>How Long do you plan to stay online?</p>
          <button
            type="button"
            className={style.loginOutSizeScheduleEditButton}
            onClick={onClickEditOutsideSchedule}
          >
            Edit
          </button>
          <button
            className={
              style.psychicLoginOutsideOfScheduleMsg
            }
            type="button"
            onClick={onClickEditOutsideSchedule}
          >
            {psychicLoginOutsideOfScheduleMsg}
          </button>
        </div>
      )
    }

    <BreakButton
      disabled={!isPsychicAvailable}
      onClick={showBreakPopup}
    />
  </>
);

export default TogglePanel;
