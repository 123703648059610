import React from 'react';
import { useSelector } from 'react-redux';

import { IWithAppointment } from 'types/componentTypes';
import { calcAppointmentTime } from 'src/utils/dateHandler';
import { selectSideUser } from 'selectors/selectors';

import style from './styles.module.scss';

const Appointment: React.FC<IWithAppointment> = ({ appointment, isPsychic = false }) => {
  const {
    customerName,
    appointmentTimeToDisplay,
    chatEndsDate,
    minutesTillAppointment,
  } = calcAppointmentTime(appointment);
  const userName = useSelector(selectSideUser);

  if (!isPsychic) {
    return (
      <div className={style.withAppointment}>
        {/* eslint-disable-next-line max-len */}
        Your Сhat will end automatically in {minutesTillAppointment} minutes because {userName?.friendlyName} has an upcoming appointment.
      </div>
    );
  }

  return (
    <div className={style.withAppointmentPsychic}>
      <span>
        You have an upcoming appointment with {customerName} at {appointmentTimeToDisplay}.
      </span>
      <span className={style.chatTime}>
        This chat will end automatically at {chatEndsDate}.
      </span>
    </div>
  );
};

export default Appointment;
