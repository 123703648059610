import React from 'react';
import cn from 'classnames';
import { TableSortLabel } from '@mui/material';

import { PNOrderType } from 'constants/enums';

import styles from './ClientListTable.module.scss';

const ClientTableHeader = ({
  order,
  orderBy,
  onSort,
}) => {
  const renderSortableHeadLabel = ({ columnId, label }) => {
    const isOrderByColumn = orderBy === columnId;

    return (
      <TableSortLabel
        hideSortIcon={!order}
        active={isOrderByColumn && !!order}
        direction={isOrderByColumn ? order : PNOrderType.ASCENDING}
        onClick={() => onSort(columnId)}
      >
        {label}
      </TableSortLabel>
    );
  };

  return (
    <thead>
      <tr className={styles.head}>
        <th className={styles.fixedColumn} aria-label="checkbox" />
        <th className={cn(styles.fixedColumn, styles.fixedName, styles.activeSort)}>
          {onSort
            ? renderSortableHeadLabel({ label: 'Name', columnId: 'displayname' }) : 'Name'}
        </th>
        <th className={cn(styles.fixedColumn, styles.fixedLine)}>|</th>
        <th className={cn(styles.scrollableColumnWrapper, styles.activeSort)}>
          {onSort
            ? renderSortableHeadLabel({ label: 'Last Reading', columnId: 'lastReadingDate' }) : 'Last Reading'}
        </th>
        <th className={styles.scrollableColumnWrapper}>{}</th>
        <th className={styles.scrollableColumnWrapper}>Labels</th>
      </tr>
    </thead>
  );
};

export default ClientTableHeader;
