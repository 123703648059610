import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ConversationType } from 'extracted-chat-components/enums';

import {
  selectCurrentChat,
  selectNotificationReactionUnreadCount,
  selectOfflineChats,
} from 'selectors/selectors';
import { CHAT_TABS_ID } from 'constants/constants';

import styles from './styles.module.scss';

const Badges = ({ className = '', tabId }) => {
  const [count, setCount] = useState(0);
  const offlineChats = useSelector(selectOfflineChats);
  const currentChat = useSelector(selectCurrentChat);
  const reactionUnreadCount = useSelector(selectNotificationReactionUnreadCount);

  useEffect(() => {
    if (tabId === CHAT_TABS_ID.OFFLINE_MESSAGE) {
      setCount(offlineChats.length);
    }

    if (tabId === CHAT_TABS_ID.LIVE_CHAT) {
      if (currentChat.type === ConversationType.LIVE_CHAT) {
        setCount(1);
      } else {
        setCount(0);
      }
    }

    if (tabId === CHAT_TABS_ID.PSYCHIC_NOTIFICATION) {
      setCount(reactionUnreadCount);
    }
  }, [offlineChats, currentChat, tabId, reactionUnreadCount]);

  return (
    <div className={cn(styles.liveChatBadge, {
      [className]: className,
    })}
    >
      {count}
    </div>
  );
};

export default Badges;
