import { ChatRequest, NotificationType, PopUpNotificationType } from 'constants/enums';
import { messages } from 'src/services/messages';

export const addPopupNotificationUtils = (
  type,
  equalsChatId,
  addPopUpNotification,
  friendlyName,
) => {
  switch (type) {
    case ChatRequest.CHAT_REQUEST_EXPIRED: {
      if (!equalsChatId) {
        return;
      }

      addPopUpNotification({
        isVisible: true,
        title: messages.notAvailablePsychic(friendlyName),
        notificationType: PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
      });

      break;
    }
    case NotificationType.CHAT_ENDED_INSUFFICIENT_FUNDS: {
      addPopUpNotification({
        isVisible: true,
        title: messages.lowFundsPopupNotification(),
        notificationType: PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS,
      });

      break;
    }
    default: break;
  }
};
