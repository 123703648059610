import React from 'react';

import styles from 'src/components/ClientHubWeb/HeaderButton/DropDown/SortButtonDropDown.module.scss';

const SortButtonDropDown = ({
  options, isVisible, onClick,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    (
      <div className={styles.wrapper}>
        {
          options.map((option) => (
            <button
              className={styles.option}
              type="button"
              key={option.name}
              onClick={() => onClick(option)}
            >
              {option.name}
            </button>
          ))
        }
      </div>
    )
  );
};

export default SortButtonDropDown;
