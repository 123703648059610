import React, { useCallback } from 'react';
// import { useSelector } from 'react-redux';
import cn from 'classnames';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import StyledTooltip from 'components/Tooltips/StyledTooltip/StyledTooltip';
import StyledCheckbox from 'components/PsychicNotifications/CustomStyled/StyledCheckbox';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
// import { selectCurrentUser } from 'selectors/selectors';
import {
  formatForPsychicNote,
  formatToUSDateOnly,
  formatToUSShortMonth,
  formatToUSTime,
} from 'src/utils/dateHandler';
import { PNNotificationStatus } from 'src/constants/enums';
import { INotificationList } from 'src/types/componentTypes';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
} from 'actions/psychicNotifications';
import { PNCustomerListType } from 'types/objectTypes';
// import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
import { ReactComponent as RightArrow } from 'src/assets/svg/arrow-right-icon.svg';
import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import IndicatorButton from 'components/Buttons/IndicatorButton';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import { getDraftedNote } from 'src/utils/commonUtil';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';

const NotificationListForTable: React.FC<INotificationList> = ({
  selected,
  rowIndex,
  onSelectRow,
  notification,
  selectedLength,
  handlePsychicNoteScreens,
}) => {
  const {
    customerId,
    displayname,
    firstName,
    lastname,
    zodiacSign,
    zodiacSignId,
    lastReadingDateUTC,
    lastReadingType,
    messageBody,
    pCComID,
    isRead,
    sentDateUTC,
    notificationStatus,
    notificationStatusId,
    sendBy,
    isPsychicNotificationsEnabled,
    isPsychicNotificationsEmailEnabled,
    isClientAccessToPsychicNotes,
    customerType,
  } = notification;

  const customer: PNCustomerListType = {
    customerId,
    callrecId: 0,
    displayname,
    firstName,
    lastname,
    notificationStatus,
    readingType: 'Message',
    customerType,
    lastReadingDate: lastReadingDateUTC,
    zodiacSign,
    zodiacSignId,
    pCComID,
    isRead,
    notificationStatusId,
    customerTypeId: 0,
    isPsychicNotificationsEnabled,
    isPsychicNotificationsEmailEnabled,
    isClientAccessToPsychicNotes,
  };
  const isCustomerMuted = notificationStatus === PNNotificationStatus.MUTED;
  const zodiacImage = zodiacMap.get(zodiacSign.toLocaleLowerCase());
  const shortSentDate = formatForPsychicNote(sentDateUTC);
  const longSentDate = `${formatToUSShortMonth(sentDateUTC)} | ${formatToUSTime(sentDateUTC)}`;
  // const currentUser = useSelector(selectCurrentUser);
  const isDraftSaved = !!getDraftedNote(customerId);

  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);

  const handleOnSelect = useCallback(() => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'select notification',
    //   button_type: 'checkbox',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
    onSelectRow(pCComID, 1);

    if (selected) {
      setNotificationCustomerDetail(null);
    } else if (!selectedLength) {
      setNotificationCustomerDetail([customer]);
    }
  }, [selected, selectedLength]);

  const handleOnClick = (e) => {
    getClientNoteHistory(pCComID);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail([customer]);
  };

  const lastReadingShortDate = `${formatToUSDateOnly(lastReadingDateUTC)}  ${formatToUSTime(lastReadingDateUTC)}`;
  const lastReadingLongDate = `${formatToUSShortMonth(lastReadingDateUTC)} | ${formatToUSTime(lastReadingDateUTC)}`;

  const tableRowClasses = cn(
    styles.tableRow,
    {
      [styles.mutedRow]: isCustomerMuted,
      [styles.selectedRow]: selected,
      [styles.oddBackground]: rowIndex % 2 !== 0,
    },
  );

  return (
    <TableRow
      hover
      selected={selected}
      className={tableRowClasses}
    >
      <TableCell padding="checkbox">
        <StyledCheckbox
          checked={selected}
          disabled={isCustomerMuted}
          onClick={handleOnSelect}
        />
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2" noWrap align="left">
          <button
            type="button"
            className={styles.customerName}
            id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
            onClick={handleOnClick}
          >
            {displayname}
          </button>
        </Typography>
        <ClientTypeLable clientType={customerType} />
        {isCustomerMuted && <span className={styles.mutedTag}>Muted</span>}
        {isDraftSaved && <span className={styles.draftTag}>Draft</span>}
      </TableCell>

      <TableCell
        align="center"
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        <StyledTooltip title={zodiacSign}>
          <img src={zodiacImage} alt={zodiacSign} className={styles.zodiacImage} />
        </StyledTooltip>
      </TableCell>

      <TableCell
        align="center"
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        <StyledTooltip title={longSentDate}>
          <span>{shortSentDate}</span>
        </StyledTooltip>
      </TableCell>

      <TableCell
        align="center"
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        {!isRead && <IndicatorButton />}
      </TableCell>

      <TableCell
        align="left"
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        <div className={cn(styles.fullMessageLine, styles.messageLine)}>
          {`${sendBy}: ${messageBody}`}
        </div>
      </TableCell>
      <TableCell
        align="left"
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        <StyledTooltip title={lastReadingLongDate}>
          <span>{`${lastReadingType}- ${lastReadingShortDate}`}</span>
        </StyledTooltip>
      </TableCell>
      <TableCell
        id={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
        onClick={handleOnClick}
      >
        <RightArrow />
      </TableCell>
    </TableRow>
  );
};

export default NotificationListForTable;
