/* eslint-disable max-len */
import { IAppState } from 'store';

export const selectPsychicNotificationDetail = (state: IAppState) => state.psychicNotification;

export const selectIsNotificationActive = (state: IAppState) => state.psychicNotification.isNotificationActive;

export const selectSendCustomerDetail = (state: IAppState) => state.psychicNotification.sendCustomerDetail;

export const selectNotesHistory = (state: IAppState) => state.psychicNotification.notesHistory;

export const selectNotesHistoryList = (state: IAppState) => state.psychicNotification.notesHistoryList;

export const selectActiveScreen = (state: IAppState) => state.psychicNotification.activeScreen;

export const selectPsychicNoteConfigurableData = (state: IAppState) => state.psychicNotification.psychicNoteConfigurableData;

export const selectAllPsychicNotificationMute = (state: IAppState) => state.psychicNotification.isAllPsychicNotificationMute;

export const selectPriorityActionCount = (state: IAppState) => state.psychicNotification.priorityActionCount;

export const selectCurrentPCNTab = (state: IAppState) => state.psychicNotification.currentPCNTab;

export const selectPriorityActionList = (state: IAppState) => state.psychicNotification.priorityActionList;

export const selectNotificationStatistics = (state: IAppState) => state.psychicNotification.notificationStatistics;

export const selectPriorityActionMessages = (state: IAppState) => state.psychicNotification.priorityActionMessages;

export const selectClientNotesDraftToast = (state: IAppState) => state.psychicNotification.clientNotesDraftToast;

export const selectClientLabelsList = (state: IAppState) => state.psychicNotification.clientLabelsList;
