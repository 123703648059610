import React, { useEffect, useState } from 'react';

import {
  setFunds as _setFunds,
  setShowAutoReloadHeader as _setShowAutoReloadHeader,
} from 'actions/chatActions';
import { FUNDS_TO_ADD } from 'constants/constants';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { LocalStorage } from 'src/utils/storageHandler';

import styles from './ClientHeader.module.scss';

const AutoReloadHeader = ({ fundsToAdd }) => {
  const [reloadTimer, setAutoReloadTimer] = useState<number>(45);

  const funds = parseFloat(fundsToAdd).toFixed(2);

  const setFunds = useBindDispatch(_setFunds);
  const setShowAutoReloadHeader = useBindDispatch(_setShowAutoReloadHeader);

  useEffect(() => {
    const reloadTimerInterval = setInterval(() => {
      const timer = reloadTimer - 1;
      setAutoReloadTimer(timer);

      if (timer <= 0) {
        setShowAutoReloadHeader(false);

        setFunds('');
        LocalStorage.removeItem(FUNDS_TO_ADD);

        clearInterval(reloadTimerInterval);
      }
    }, 1000);

    return () => {
      clearInterval(reloadTimerInterval);
    };
  });

  return (
    <div id="autoReloadHeaderContainer" className={styles.autoReloadHeaderContainer}>
      <span id="autoReloadHeaderText" className={styles.autoReloadHeaderText}>
        ${funds} Auto Reload in: {reloadTimer} seconds
      </span>
    </div>
  );
};

export default AutoReloadHeader;
