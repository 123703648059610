import React from 'react';
import range from 'lodash/range';
import cn from 'classnames';

import { MAX_PEOPLE_IN_QUEUE } from 'constants/constants';
import SvgIcon from 'components/SvgIcon';
import { CallbackBannerProps } from 'types/componentTypes';

import styles from './CallbackBanner.module.scss';

const CallbackBanner: React.FC<CallbackBannerProps> = ({
  placeInQueue,
  peopleCount,
  mins,
}) => {
  const getPlaceMessage = () => {
    if (!placeInQueue || placeInQueue > peopleCount) {
      return `In queue - <b>${peopleCount} people</b>`;
    }

    switch (placeInQueue) {
      case 1: return '<b>1st</b> place';
      case 2: return '<b>2nd</b> place';
      case 3: return '<b>3rd</b> place';
      default: return `<b>${placeInQueue}th</b> place`;
    }
  };

  const getPersonSVG = (item) => {
    const isQueue = item + 1 <= peopleCount;
    const isPlacedInQueue = isQueue && (
      item + 1 === placeInQueue
    );

    return (
      <SvgIcon
        id="person"
        key={`person-icon-${item}`}
        color={isPlacedInQueue ? '#000' : '#fff'}
        className={cn(styles.icon, {
          [styles.iconInQueue]: isPlacedInQueue,
          [styles.iconFill]: isQueue && !isPlacedInQueue,
        })}
      />
    );
  };

  return (
    <div className={styles.callbackBanner}>
      <div className={styles.peopleInfoWrapper}>
        <div className={styles.peopleInfo}>
          <div className={styles.icons}>
            { range(MAX_PEOPLE_IN_QUEUE).map((item) => getPersonSVG(item)) }
          </div>
          <span
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: getPlaceMessage(),
            }}
          />
        </div>
      </div>
      <div className={styles.waitTimeInfo}>
        <span className={styles.waitTimeInfoText}>Est. wait <b>{mins} mins</b></span>
        <span className={styles.text}>upon psychics return</span>
      </div>
    </div>
  );
};

export default CallbackBanner;
