import React from 'react';
import cn from 'classnames';

import styles from './ClientBlockInfoPopup.module.scss';

const ClientBlockInfoPopup = ({ systemMessages, isCustomer }) => {
  if (!systemMessages.length || !systemMessages[0]) {
    return null;
  }

  return (
    <div className={cn(styles.blockMessage, {
      [styles.isOneMessage]: systemMessages.length === 1 && isCustomer,
      [styles.isOneMessagePsychic]: !isCustomer,
    })}
    >
      {systemMessages && systemMessages.map((systemMessage) => (
        <span className={cn(styles.message, { [styles.messageCustomer]: isCustomer })}>
          {systemMessage}
        </span>
      ))}
    </div>
  );
};

export default ClientBlockInfoPopup;
