import { useEffect, useState } from 'react';

import { DESKTOP_PLATFORM, MAX_RETRY_VALUE } from 'constants/constants';
import { importAndRetryChunk } from 'src/utils/commonUtil';

export const useLazyEmojiMart = (platform) => {
  const [isEmojiMartLoaded, setIsEmojiMartLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!isEmojiMartLoaded && platform === DESKTOP_PLATFORM) {
        await importAndRetryChunk(
          () => import('emoji-mart'),
          MAX_RETRY_VALUE,
          'Emoji loading failed',
        );

        setIsEmojiMartLoaded(true);
      }
    })();
  }, [platform]);
};

export const useLazyLoader = () => {
  useEffect(() => {
    (async () => {
      await importAndRetryChunk(
        () => import('src/assets/gif/loader.webm'),
        MAX_RETRY_VALUE,
        'Loader loading failed',
      );
    })();
  }, []);
};
