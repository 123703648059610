import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Router } from 'react-router';

import { LATEST_NOTIFICATION_INDEX } from 'constants/constants';
import store from 'store';
import routeHistory from 'route-history';
import App from 'containers/App';
import 'src/polifills';

const path = (/#!(\/.*)$/.exec(routeHistory.location.hash) || [])[1];
const persistor = persistStore(store);

if (path) {
  routeHistory.replace(path);
}

window.version = process.env.VERSION;

window.suspendSystemNotification = () => {
  const latestIndex = Number(localStorage.getItem(LATEST_NOTIFICATION_INDEX));
  localStorage.setItem(LATEST_NOTIFICATION_INDEX, (latestIndex - 1).toString());
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render((
  <Router history={routeHistory}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
), document.getElementById('root'));
