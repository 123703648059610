import {
  call,
  put,
  putResolve,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { NotificationsText, View } from 'constants/enums';
import * as chatActions from 'actions/chatActions';
import * as types from 'actions/actionsTypes';
import {
  setSideUser,
  sideUserRequest,
  updateCallbackBanner,
} from 'actions/chatActions';
import {
  selectCustomerId,
  selectExtId,
  selectIsModeForShowingModals,
  selectSideUser,
} from 'selectors/selectors';
import {
  handleRequestError,
  setIsShownIncomingRequest,
  setLoadingState,
} from 'actions/appActions';
import { API } from 'src/utils/api';
import { descriptionFromError } from 'src/utils/commonUtil';
import { handleSetSessionByLinkError } from 'src/redux/sagas/chat/createChatByLinkSaga';
import { SentryMethods } from 'src/utils/sentryMethods';

export function* setCallbackBanner(
  payload?: ReturnType<typeof updateCallbackBanner>,
) {
  try {
    const sideUser = yield select(selectSideUser);
    const psychicId = yield select(selectExtId);

    const getPsychicStatusAndInfoRequest = {
      ExtIds: psychicId,
      SkipOkToDisplay: true,
      ...(payload?.payload && {
        CustId: payload.payload,
      }),
    };

    const { peopleInQueue, estimatedWaitTime, customerPlaceInQueue } = yield call(
      API.Psychic.getPsychicStatusAndInfo,
      getPsychicStatusAndInfoRequest,
    );

    yield put(setSideUser({
      ...sideUser,
      peopleInQueue,
      estimatedWaitTime,
      customerPlaceInQueue,
    }));
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);
  }
}

export function* setSideUserWorker({ payload }: ReturnType<typeof sideUserRequest>) {
  const {
    id,
    view,
    isLightPsychicRequest,
    isForce,
  } = payload;
  const isNoModeOrCustomMode: boolean = yield select(selectIsModeForShowingModals);
  const customerId: string = yield select(selectCustomerId);
  const sideUser = yield select(selectSideUser);

  try {
    if (!id) {
      throw new Error(NotificationsText.USER_WAS_N0T_FOUND);
    }

    let user;
    const notEnoughSideUserInfo = !sideUser?.extId || !sideUser?.customerPrice;

    if (view === View.CUSTOMER && (isForce || notEnoughSideUserInfo)) {
      const sideUser = yield call(
        isLightPsychicRequest
          ? API.Psychic.getPsychicLite
          : API.Psychic.getPsychic,
        id,
        customerId,
      );
      const {
        appointmentTimestamp = null,
        customerName = null,
      } = sideUser?.data?.upcomingAppointment || {};

      if (appointmentTimestamp && customerName) {
        yield put(chatActions.setAppointment({ appointmentTimestamp, customerName }));
      }

      user = sideUser?.data;
      user.attributes = { participantType: View.PSYCHIC };
      user.friendlyName = user?.lineName;

      yield put(setSideUser({
        ...user,
      }));
      yield call(setCallbackBanner);
    }

    if (view === View.PSYCHIC) {
      const sideUser = yield call(API.Customer.getCustomerById, String(id));
      user = sideUser?.data;
      user.attributes = { participantType: View.CUSTOMER };
      user.friendlyName = user?.firstName;

      yield put(setSideUser(user));
    }
  } catch (e) {
    console.log(e);
    yield call(SentryMethods.captureException, e);

    const isSetSessionError = yield call(handleSetSessionByLinkError, e?.message);

    if (isSetSessionError) {
      return;
    }

    const isPsychicView = view === View.PSYCHIC;

    yield put(setLoadingState(false));

    if (isNoModeOrCustomMode && isPsychicView) yield putResolve(setIsShownIncomingRequest(false));

    const requestErrorPayload = {
      redirectPath: isPsychicView ? '/conversations?view=psychic' : '',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  }
}

export function* setSideUserSagaWatcher() {
  yield takeEvery(types.UPDATE_CALLBACK_BANNER, setCallbackBanner);
}
