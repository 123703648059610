import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Clock } from 'assets/svg/icon-clock.svg';
import TimerContainer from 'components/Timer/TimerContainer';
import HeaderButton from 'components/Buttons/HeaderButton';
import { onEndChat as _onEndChat } from 'actions/chatActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { selectCurrentChat } from 'selectors/selectors';

import style from '../ChatHeader/SecondaryHeader.module.scss';

const LiveChatHeader = ({
  friendlyName,
  clientStatus,
  isLiveChatActive,
  isSwitchedConversations,
  headerRef,
}) => {
  const onEndChat = useBindDispatch(_onEndChat);
  const currentChat = useSelector(selectCurrentChat);

  const onEndChatHandler = () => {
    onEndChat({ flag: true, friendlyName: currentChat?.sideUser?.friendlyName });
  };

  if (!isSwitchedConversations) {
    return null;
  }

  return (
    <div className={style.pathToConversations} ref={headerRef}>
      <div className={style.pathToConversationsLiveClient}>
        <span title={friendlyName} className={style.pathToConversationsLiveClientName}>
          {friendlyName}
        </span>
        <span title={clientStatus} className={style.pathToConversationsLiveClientStatus}>
          {clientStatus}
        </span>
      </div>
      <div className={style.pathToConversationsLiveTime}>
        <Clock className={style.pathToConversationsLiveImage} />
        <TimerContainer isConversation={false} isLiveChat />
      </div>
      {isLiveChatActive && (
        <div className={style.pathToConversationsLiveEndChat}>
          <HeaderButton
            handleEndChatButton={onEndChatHandler}
            isVisible
            isChatEnd
          />
        </div>
      )}
    </div>
  );
};

export default LiveChatHeader;
