import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  take,
} from 'redux-saga/effects';

import {
  DIFFERENCE_IN_TIME, REDIRECT_PATH,
  HEADER_BANNER_DESCRIPTION_STORAGE,
  HEADER_BANNER_SUBTITLE_STORAGE,
  HEADER_BANNER_TITLE_STORAGE,
  HEADER_BANNER_TYPE_STORAGE,
  IS_VISIBLE_HEADER_BANNER_STORAGE,
  HEADER_BANNER_REASON_STORAGE,
  INLINE_NOTIFICATION,
  INLINE_NOTIFICATION_INDEX,
} from 'constants/constants';
import {
  BootState,
  PopUpNotificationType,
  SdkEvents,
  Status,
  View,
} from 'constants/enums';
import {
  handleReceivedActivePsychicChats,
  joinChannelRequest,
  setCurrentChat,
  setCustomerId,
  setExtId,
  setInitChatBanner,
  setTwilioClient,
  sideUserRequest,
} from 'actions/chatActions';
import { getTwilioClient } from 'src/utils/twilio';
import { selectIsHistoryMode, selectSdkCallbackStorage } from 'selectors/selectors';
import {
  addPopUpNotification,
  getSystemChannel,
  handleRequestError,
  restartChat,
  setDiffInTime,
  setHeaderBannerContent,
  setInlineNotification,
  setIsLiveChatActive,
  setIsVisibleHeaderBanner,
  setLoadingState,
  setRedirectState,
  setRequestErrorState,
  setShowInitModal,
  setView,
  setIsDeclineOMEnabled,
  showPopUpNotification,
  setIsPsychicNotificationsEnable,
  setNotificationUnreadCount,
  setIsChatAutoReloadEnabled,
  setAutoReloadPromptLabels,
} from 'actions/appActions';
import {

  setPriorityActionMessage,
  setPsychicNoteConfigurableData,
  setPsychicNotificationsActive,
} from 'actions/psychicNotifications';
import { API } from 'src/utils/api';
import * as types from 'actions/actionsTypes';
import {
  setAuthToken,
  setBearerToken,
  setTwilioToken,
} from 'actions/authActions';
import { LocalStorage } from 'src/utils/storageHandler';
import { descriptionFromError } from 'src/utils/commonUtil';
import { checkCustomerIsBusyNotification } from 'src/utils/helpers';
import { getActiveChatsForPsychic } from 'src/utils/chatsHandler';
import { getSystemChannelWorker } from 'src/redux/sagas/app/getSystemChannelSaga';
import { setUser } from 'src/redux/sagas/app/setUserSaga';
import { handleTwilioToken } from 'src/redux/sagas/app/handleTwilioTokenSaga';
import { setUpNotifications } from 'src/redux/sagas/app/setUpNotificationsSaga';
import { SentryMethods } from 'src/utils/sentryMethods';
import { fetchPriorityActionList } from 'src/redux/sagas/psychicNotifications/psychicNotificationsSaga';

function* restoreHeaderNotifications() {
  const [
    title,
    subtitle,
    description,
    reason,
    type,
    isVisibleHeaderNotification,
  ] = LocalStorage.getItems([
    HEADER_BANNER_TITLE_STORAGE,
    HEADER_BANNER_SUBTITLE_STORAGE,
    HEADER_BANNER_DESCRIPTION_STORAGE,
    HEADER_BANNER_REASON_STORAGE,
    HEADER_BANNER_TYPE_STORAGE,
    IS_VISIBLE_HEADER_BANNER_STORAGE,
  ]);

  if (!!type && !!isVisibleHeaderNotification) {
    yield put(setHeaderBannerContent({
      title,
      subtitle,
      description,
      reason,
      type,
    }));
    yield put(setIsVisibleHeaderBanner(!!isVisibleHeaderNotification));
  }
}

function* restoreNotification() {
  const [
    title,
    description,
    notificationType,
    reason = '',
    isVisible,
  ] = LocalStorage.getItems([
    'title',
    'description',
    'notificationType',
    'reason',
    'isVisibleNotification',
  ]);
  const isTextNotification = title || description;
  const isSendMessageConfirmation = notificationType
    === PopUpNotificationType.SEND_MESSAGE_CONFIRMATION
    || notificationType === PopUpNotificationType.CLIENT_LABEL_OVERLAY;

  if (checkCustomerIsBusyNotification(notificationType)) {
    yield put(setShowInitModal(true));
    yield put(setInitChatBanner(true));
  }

  if (notificationType && isTextNotification && !isSendMessageConfirmation) {
    yield put(addPopUpNotification({
      title,
      description,
      reason,
      notificationType,
      isVisible: !!isVisible,
    }));
  }
}

function* restoreInlineNotification() {
  const [title, index] = LocalStorage.getItems([
    INLINE_NOTIFICATION,
    INLINE_NOTIFICATION_INDEX,
  ]);

  if (title && index) {
    yield put(setInlineNotification(title, Number(index)));
  }
}

function* restartChatPsychic(payload) {
  try {
    const {
      view,
      chatId,
      tokenAuth,
      tokenTwilio,
      psychicId,
    } = payload;

    if (!psychicId) {
      return;
    }

    const client = yield call(getTwilioClient);
    yield put(setTwilioClient(client));

    const [version, currentUser, responseChatRequest] = yield all([
      call(API.App.getVersionDetail),
      call(API.Psychic.getPsychic, psychicId),
      call(API.Psychic.getPsychicActiveChats, psychicId),
      call(getSystemChannelWorker, getSystemChannel(view)),
      call(fetchPriorityActionList, { payload: { showLoader: false } }),
    ]);

    const {
      clientLastNoteLockedCycle,
      clientSendNoteLimit,
      psychicMaxNotificationsSend,
      psychicNotesSystemMessages,
      psychicNotificationRefreshEveryMins,
      psychicNotificationsEnabled,
      psychicGamifyFeatureEnabled,
      priorityClientsActionMessages,
    } = version;

    yield call(setUser, currentUser, View.PSYCHIC);
    yield all([
      putResolve(setIsPsychicNotificationsEnable(psychicNotificationsEnabled)),
      putResolve(setIsDeclineOMEnabled(currentUser.data.declineOMEnabled)),
      putResolve(setPsychicNotificationsActive(currentUser.data.isClientNotificationsEnabled)),
      putResolve(setNotificationUnreadCount(currentUser.data.unreadNotificationsCount)),
      putResolve(setPriorityActionMessage(JSON.parse(priorityClientsActionMessages))),
      putResolve(setPsychicNoteConfigurableData({
        clientLastNoteLockedCycle,
        clientSendNoteLimit,
        psychicMaxNotificationsSend,
        psychicGamifyFeatureEnabled,
        psychicNotificationRefreshEveryMins,
        psychicNotesSystemMessages: JSON.parse(psychicNotesSystemMessages),
      })),
    ]);
    yield put(handleReceivedActivePsychicChats(responseChatRequest?.data.chats, chatId));
    yield take([types.ACTIVE_CHATS_LOADED, types.EMIT_ERROR_NOTIFICATION]);
    yield put(setAuthToken(tokenAuth));
    yield put(setTwilioToken(tokenTwilio));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* restartChatCustomer({
  chatId,
  tokenAuth,
  tokenTwilio,
  bearerToken,
  psychicId,
  customerId,
  view,
}) {
  if (!customerId) {
    return;
  }

  yield put(setAuthToken(tokenAuth));
  yield put(setCustomerId(customerId));
  yield put(setShowInitModal(false));
  yield put(setInitChatBanner(false));

  let authTokenTwilio = tokenTwilio;

  if (!tokenTwilio) {
    authTokenTwilio = yield call(handleTwilioToken);
  }

  const client = yield call(getTwilioClient);
  yield putResolve(setTwilioClient(client));
  const [version, user] = yield all([
    call(API.App.getChatVersionDetail),
    call(API.Customer.getCustomerById, customerId),
    call(getSystemChannelWorker, getSystemChannel(view)),
    put(sideUserRequest(psychicId, View.CUSTOMER)),
  ]);

  yield all([
    call(setUser, user, View.CUSTOMER),
    call(setUpNotifications),
  ]);

  const popUpNotificationType = LocalStorage.getItem('notificationType') as string;

  if (checkCustomerIsBusyNotification(popUpNotificationType)) {
    return;
  }

  const responseActiveChats = yield call(API.Customer.getCustomerActiveChat, customerId);
  let chat = responseActiveChats?.data?.chats[0];

  if (!chat?.chatId && chatId) {
    const response = yield call(API.Chat.getChatById, chatId);
    chat = response?.data?.chat;
  }

  if (chat?.chatId) {
    const viewMode = view === View.PSYCHIC ? View.PSYCHIC : View.CUSTOMER;

    chat.attributes = { requestType: chat?.type };

    switch (chat?.status) {
      case Status.EXPIRED:
      case Status.COMPLETED: {
        yield put(joinChannelRequest(chat.chatChannelSid));
        yield setIsLiveChatActive(false);

        break;
      }
      case Status.PREPARING: {
        yield call(API.Chat.startChatRequest, chat?.chatId);
        yield put(setCurrentChat(chat));

        const isHistoryMode = yield select(selectIsHistoryMode);

        if (!isHistoryMode) {
          yield put(setIsLiveChatActive(true));
        }

        break;
      }
      default: {
        yield put(setIsLiveChatActive(true));
        yield put(setCurrentChat(chat));
      }
    }

    yield put(setView(viewMode));
  } else {
    yield put(setLoadingState(false));
  }

  yield put(setTwilioToken(authTokenTwilio));
  yield put(setBearerToken(bearerToken));

  yield put(setIsChatAutoReloadEnabled(version.chatAutoReloadEnabled));

  yield putResolve(
    setAutoReloadPromptLabels(JSON.parse(version?.autoReloadSystemMessages)),
  );
}

export function* restartChatWorker({ payload }: ReturnType<typeof restartChat>) {
  const { psychicId, view } = payload;

  try {
    const sdkCallbackStorage = yield select(selectSdkCallbackStorage);
    const viewMode = view?.toLowerCase() === View.PSYCHIC.toLowerCase()
      ? View.PSYCHIC
      : View.CUSTOMER;
    const notificationType = LocalStorage.getItem('notificationType');
    const isVisiblePopUpNotification = LocalStorage.getItem('isVisibleNotification');
    const diffTime = LocalStorage.getItem(DIFFERENCE_IN_TIME);
    const redirectPath = LocalStorage.getItem(REDIRECT_PATH);

    if (redirectPath) {
      yield put(setRedirectState(true, redirectPath));
    }

    if (notificationType === 'request-error' && !!isVisiblePopUpNotification) {
      yield put(setRequestErrorState(true, PopUpNotificationType.REQUEST_ERROR));
    }

    yield put(setDiffInTime(Number(diffTime)));
    yield put(showPopUpNotification(!!isVisiblePopUpNotification));
    yield fork(restoreInlineNotification);

    // eslint-disable-next-line no-param-reassign
    payload.view = viewMode;
    yield put(setLoadingState(true));
    yield put(setView(viewMode));
    yield put(setExtId(psychicId));
    const bootCb = sdkCallbackStorage.get(SdkEvents.BOOT);

    if (viewMode === View.PSYCHIC) {
      yield call(restartChatPsychic, payload);

      if (bootCb) {
        const activeChats = yield call(getActiveChatsForPsychic, psychicId);

        bootCb({
          bootStatus: BootState.STATEFUL,
          activeChats,
        });
        sdkCallbackStorage.delete(SdkEvents.BOOT);
      }
    }

    if (viewMode === View.CUSTOMER) {
      yield call(restartChatCustomer, payload);

      if (bootCb) {
        bootCb({ bootStatus: BootState.STATEFUL });
        sdkCallbackStorage.delete(SdkEvents.BOOT);
      }
    }

    yield call(restoreHeaderNotifications);
    yield call(restoreNotification);
  } catch (e) {
    console.log(e);

    yield call(SentryMethods.captureException, e);
    const isPsychicView = view === View.PSYCHIC.toLowerCase();
    const isInvalidToken = e?.message === 'Invalid Access Token';
    const requestErrorPayload = {
      redirectPath: isPsychicView ? '/login' : '',
      isInvalidToken,
      errorText: e?.message,
      description: descriptionFromError(e),
    };

    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}
