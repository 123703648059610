import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import LowFunds from 'components/Notifications/InlineNotification/LowFunds/LowFunds';
import { formatToUSTime, getDateAndTime } from 'src/utils/dateHandler';
import {
  ChatCompletedReason,
  Notification,
  NotificationType,
  View,
} from 'constants/enums';
import { IAppState } from 'store';
import Messages from 'components/Messages/Messages';
import { MessagesContainerInterface } from 'types/componentTypes';
import { roundToWhole } from 'src/utils/helpers';
import { SystemMessage, TimestampMessage } from 'components/Messages/Message';
import SystemWithTimestampMessages from 'components/Messages/Message/SystemWithTimestampMessages';
import {
  selectCurrentChat,
  selectCurrentUser,
  selectSideUser,
  selectView,
  selectInlineNotification,
} from 'selectors/selectors';
import { MessageType, TwilioUser } from 'types/objectTypes';
import { messages as notifications } from 'src/services/messages';
import { IFundsNotification } from 'models/models';
import { LocalStorage } from 'src/utils/storageHandler';
import { INLINE_NOTIFICATION_INDEX } from 'constants/constants';
import styles from 'components/Messages/Messages.module.scss';

const getInlineLowFundsNotification = (
  isCustomerView: boolean,
  funds: string | number,
  time: string,
  mins: number,
): string => (isCustomerView
  ? notifications.lowFundsInlineInChatCustomerSide(funds, mins, time)
  : notifications.lowFundsInlineInChatPsychicSide(mins, time));

const getInfoForFundsNotifications = (
  attributes: IFundsNotification,
  view: View | string,
  timestamp: string | Date,
) => {
  if (!attributes) {
    return {};
  }

  const funds: number | string = roundToWhole(attributes.addedFunds || attributes.fundsToAdd);
  const isCustomerView = view === View.CUSTOMER;
  const time = formatToUSTime(timestamp);
  const isLowFundsCase = attributes.reason === Notification.LOW_FUNDS;

  return {
    funds,
    isCustomerView,
    time,
    isLowFundsCase,
  };
};

const getTimestamp = (notificationType: string = '', timestamp: any): string => {
  if (!timestamp) {
    return '';
  }

  const { date, time } = getDateAndTime(timestamp);

  switch (notificationType) {
    case NotificationType.CHAT_STARTED: {
      return `Live Chat started ${date} at ${time}`;
    }
    case NotificationType.CHAT_ENDED: {
      return `Live Chat ended ${date} at ${time}`;
    }
    default: return '';
  }
};

const getCompletionReason = (completionReason: string): string => {
  switch (completionReason) {
    case ChatCompletedReason.CHAT_TASK_CANCELLED_LOW_FUNDS:
      return 'CHAT ENDED';
    case ChatCompletedReason.SWITCH_TO_PHONE:
    case NotificationType.CUSTOMER_FINISHED_CHAT: {
      return 'Chat ended by customer';
    }
    case ChatCompletedReason.UPCOMING_APPOINTMENT:
    case NotificationType.PSYCHIC_FINISHED_CHAT:
      return 'Chat ended by psychic';
    case NotificationType.INACTIVE_CHAT_ENDED:
      return 'Chat ended due to inactivity';
    case NotificationType.CHAT_ENDED_LOW_FUNDS:
    case NotificationType.CHAT_ENDED_INSUFFICIENT_FUNDS:
      return 'Chat ended due to low funds';
    default: return '';
  }
};

const getJoinedNotification = (
  notificationType: string,
  timestamp: any,
  sideUser: TwilioUser,
  view: View | string,
): string | object => {
  const joinedMessage = `${sideUser?.friendlyName} joined the chat`;
  const timestampMessage = getTimestamp(NotificationType.CHAT_STARTED, timestamp);

  switch (notificationType) {
    case NotificationType.CUSTOMER_JOINED: {
      if (view === View.CUSTOMER) {
        return '';
      }

      return `${sideUser?.friendlyName} joined the chat`;
    }
    case NotificationType.PSYCHIC_JOINED: {
      if (view === View.PSYCHIC) {
        return '';
      }

      return [
        joinedMessage,
        timestampMessage,
      ];
    }
    default: return '';
  }
};

const getCompletionReasonWithTimestamp = ({
  completionReason,
  notificationType,
  timestamp,
}) => ([
  getCompletionReason(completionReason),
  getTimestamp(notificationType, timestamp),
]);

const MessagesContainer: React.FC<MessagesContainerInterface> = ({
  messageContainer,
  messages,
  scrollableMessageWrapper,
  makeEmergencyScroll,
}) => {
  const view = useSelector(selectView) as View;
  const sideUser = useSelector(selectSideUser);
  const typingMessage = useSelector((state: IAppState) => state.chat.typingMessage);
  const currentChat = useSelector(selectCurrentChat);
  const currentUser = useSelector(selectCurrentUser);
  const inlineNotification = useSelector(selectInlineNotification);

  const isPreviousOffline = useRef<boolean>(false);

  useEffect(() => {
    isPreviousOffline.current = false;
  }, [currentChat.chatId]);

  const getMessageNotifications = useCallback((
    notificationType: string = '',
    message: MessageType,
    attributes?: any,
    isPrevOffline?: boolean,
  ): string | ReactElement => {
    const { timestamp, index } = message || {};

    switch (notificationType) {
      case NotificationType.BILLING_STARTED: {
        const time = formatToUSTime(timestamp);

        return `Billing started ${time}`;
      }
      case NotificationType.CHAT_FUNDS_ADDED: {
        const {
          funds,
          isCustomerView,
          time,
          isLowFundsCase,
        } = getInfoForFundsNotifications(attributes, view, timestamp);

        return isLowFundsCase
          ? getInlineLowFundsNotification(
            isCustomerView,
            funds,
            time,
            Math.floor(+funds / (sideUser?.customerPrice || sideUser?.basePrice)),
          )
          : notifications.addFundsInsufficientCase(funds as string);
      }
      case NotificationType.ADD_FUNDS_REQUEST: {
        const {
          funds,
          isCustomerView,
          time,
          isLowFundsCase,
        } = getInfoForFundsNotifications(attributes, view, timestamp);
        const inlineMessageIndex = inlineNotification.messageIndex
          || LocalStorage.getItem(INLINE_NOTIFICATION_INDEX);
        const isLowFunds = isLowFundsCase && isCustomerView && Number(inlineMessageIndex) === index;
        const message = isLowFundsCase
          ? notifications.lowFundsSystemNotification(time)
          : notifications.insufficientFundsMessage();

        return (
          <>
            <SystemMessage
              key={index}
              message={message}
              isPreviousOffline={isPrevOffline}
            />
            {isLowFunds && <LowFunds funds={funds} />}
          </>
        );
      }
      case NotificationType.AUTO_RELOAD_FUNDS: {
        const isCustomerView = view === View.CUSTOMER;

        return isCustomerView ? `($${attributes?.fundsToAdd}) Reload Funds in: 45 seconds` : '';
      }
      default: return '';
    }
  }, [inlineNotification]);

  const getJoinedAndTimestampNotification = (
    attributes: any,
    isPrevOffline: boolean,
    sid: string,
  ): any => {
    if (attributes?.notificationType && attributes.notificationType.includes('joined')) {
      const timestamp = getTimestamp(NotificationType.CHAT_STARTED, attributes.timestamp);

      if (timestamp && view === View.PSYCHIC) {
        return <TimestampMessage key={sid} message={timestamp} />;
      }

      const message = getJoinedNotification(
        attributes.notificationType,
        attributes.timestamp,
        sideUser,
        view,
      );

      if (!message) {
        return false;
      }

      return typeof message === 'object'
        ? (
          <div className={styles.multipleMessageContainer}>
            <SystemWithTimestampMessages
              sid={sid}
              systemMessage={message[0]}
              timestampMessage={message[1]}
              isPrevOffline={isPrevOffline}
              timestamp={attributes.timestamp}
            />
          </div>
        )
        : (
          <SystemMessage
            key={sid}
            message={message}
            isPreviousOffline={isPrevOffline}
          />
        );
    }
  };

  const getEndChatWithTimestampNotification = useCallback((
    attributes: any,
    isPrevOffline: boolean,
    sid: string,
  ): any => {
    if (!attributes) {
      return;
    }

    const message = getCompletionReasonWithTimestamp({
      notificationType: attributes.notificationType,
      timestamp: attributes.timestamp,
      completionReason: attributes.completionReason,
    });

    return (
      <div className={styles.multipleMessageContainer}>
        <SystemWithTimestampMessages
          sid={sid}
          systemMessage={message[0]}
          timestampMessage={message[1]}
          isPrevOffline={isPrevOffline}
          timestamp={attributes.timestamp}
        />
      </div>
    );
  }, []);

  const getBillingMessage = useCallback((message, isPrevOffline: boolean) => {
    const messageNotification = getMessageNotifications(
      NotificationType.BILLING_STARTED,
      message,
    );

    return (
      <SystemMessage
        key={message?.timestamp}
        message={messageNotification}
        isPreviousOffline={isPrevOffline}
      />
    );
  }, []);

  // this useEffect handles typing indicator in case there are no messages
  useEffect(() => {
    const messagesElement: HTMLDivElement | null = messageContainer.current;
    const wrapperElement: HTMLDivElement | null = scrollableMessageWrapper.current;

    if (!messagesElement || !wrapperElement) {
      return;
    }

    messagesElement.style.height = !messages?.length
      || messagesElement.scrollHeight <= wrapperElement.clientHeight ? '100%' : '';
  }, [
    scrollableMessageWrapper,
    messageContainer,
    messages,
  ]);

  return (
    <Messages
      currentUser={currentUser}
      sideUser={sideUser}
      view={view}
      messageContainer={messageContainer}
      scrollableMessageWrapper={scrollableMessageWrapper}
      messages={messages}
      isPreviousOffline={isPreviousOffline}
      typingMessage={typingMessage}
      getMessageNotifications={getMessageNotifications}
      makeEmergencyScroll={makeEmergencyScroll}
      getJoinedAndTimestampNotification={getJoinedAndTimestampNotification}
      getEndChatWithTimestampNotification={getEndChatWithTimestampNotification}
      getBillingMessage={getBillingMessage}
    />
  );
};

export default MessagesContainer;
