import { API } from 'src/utils/api';
import {
  ClientEvents,
  View,
  Status,
  TwilioError,
} from 'src/constants/enums';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  FIRST_TWILIO_SYSTEM_NOTIFICATION,
  LATEST_NOTIFICATION_INDEX,
  MAX_RETRY_VALUE,
  TOKEN_AUTH_STORAGE,
  TOKEN_TWILIO_STORAGE,
} from 'constants/constants';
import store from 'store';
import { setTwilioConnectingStatus } from 'actions/appActions';
import { setTwilioToken } from 'actions/authActions';
import {
  callRetry,
  importAndRetryChunk,
  validateTwilioToken,
  logWithStyles,
} from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';

let Chat;

export const twilioTokenErrors = [
  'Unable to connect: Invalid Access Token',
  'Unable to connect: Invalid Access Token signature',
  'Unable to connect: Access Token expired or expiration date invalid',
];

export const styles = [
  'color: #ba1b1d',
  'display: block',
  'text-align: center',
  'text-transform: uppercase',
  'font-size: 12px',
  'font-weight: bold',
].join(';');

type RestoreTwilioClientPayload = {
  prevClient: any,
  newTwilioClient: any,
  viewMode: string | View,
  channelSid: string,
  missedNotificationsAmount?: number,
}

interface TwilioClientCreatorProps {
  twilioClient: any,
  channelSid: any,
  viewMode: string,
  createTimer?: null | undefined | Function,
  restoreTwilioClient: (payload: RestoreTwilioClientPayload) => void,
  missedNotificationsAmount?: number,
}

export const updateToken = async (client) => {
  const response = await callRetry(API.Chat.getTwilioToken);
  const tokenTwilio = response?.data?.twilioToken;

  await client.updateToken(tokenTwilio as string);
  LocalStorage.setItem(TOKEN_TWILIO_STORAGE, tokenTwilio as string);
  store.dispatch(setTwilioToken(tokenTwilio));
};

const addEventListenersToClient = (client: any) => {
  try {
    client.on(ClientEvents.TOKEN_ABOUT_TO_EXPIRE, async () => {
      console.log('%c Twilio Token About to Expire, Updating new token', styles);
      await updateToken(client);
    });

    client.on(ClientEvents.TOKEN_EXPIRED, async () => {
      await updateToken(client);
    });

    client.on(ClientEvents.CONNECTION_ERROR, (error) => {
      console.log(error);
    });

    client.on(ClientEvents.CONNECTION_STATE_CHANGED, (connectionInfo) => {
      store.dispatch(setTwilioConnectingStatus(connectionInfo));
      console.log(`%c Twilio connection status: ${connectionInfo}`, styles);
    });
  } catch (e: any) {
    SentryMethods.captureMessage(TwilioError.CANNOT_GET_CLIENT);
  }
};

const lazyLoadingTwilioChat = async () => {
  if (!Chat) {
    const { Client } = await importAndRetryChunk(
      () => import('@twilio/conversations'),
      MAX_RETRY_VALUE,
      'Twilio-chat loading failed',
    );

    Chat = Client;
  }
};

const getTwilioToken = async () => {
  try {
    let tokenTwilio: string = LocalStorage.getItem(TOKEN_TWILIO_STORAGE) || '';
    const authToken: string = LocalStorage.getItem(TOKEN_AUTH_STORAGE) || '';
    const isNotExpired = await validateTwilioToken(tokenTwilio);

    if (!isNotExpired && !!authToken) {
      logWithStyles('the twilio token is invalid');

      const response = await callRetry(API.Chat.getTwilioToken);

      logWithStyles('the new twilio token has been created');

      tokenTwilio = response?.data?.twilioToken;

      LocalStorage.setItem(TOKEN_TWILIO_STORAGE, tokenTwilio as string);
      store.dispatch(setTwilioToken(tokenTwilio));
    }

    return tokenTwilio;
  } catch (e) {
    throw Error(TwilioError.CANNOT_GET_TOKEN);
  }
};

export const getTwilioClient = async () => {
  try {
    await lazyLoadingTwilioChat();

    const tokenTwilio = await getTwilioToken();
    const client = await new Chat(tokenTwilio);

    addEventListenersToClient(client);

    return client;
  } catch (e: any) {
    SentryMethods.captureMessage(TwilioError.CANNOT_GET_CLIENT);
  }
};

export const createNewTwilioClient = async ({
  twilioClient,
  channelSid,
  viewMode,
  restoreTwilioClient,
  missedNotificationsAmount,
}: TwilioClientCreatorProps) => {
  try {
    await twilioClient.shutdown();

    console.log('%c TWILIO CONNECTION SHUT DOWN', styles);

    const client = await getTwilioClient();

    restoreTwilioClient({
      prevClient: twilioClient,
      newTwilioClient: client,
      viewMode,
      channelSid,
      missedNotificationsAmount,
    });
  } catch (e: any) {
    SentryMethods.captureMessage(TwilioError.CANNOT_GET_CLIENT);

    throw e;
  }
};

export const checkMissedNotifFromSystemChannel = async (systemChannel, twilioClient) => {
  if (twilioClient.connectionState !== Status.CONNECTED) {
    return false;
  }

  if (!systemChannel?.sid) return false;

  const latestStoreNotificationIndex = Number(LocalStorage.getItem(LATEST_NOTIFICATION_INDEX));

  if (!latestStoreNotificationIndex) return false;

  const systemChannelNotifications = (
    await systemChannel.getMessages(FIRST_TWILIO_SYSTEM_NOTIFICATION)
  ).items;

  const difference = systemChannelNotifications[0]?.state?.index - latestStoreNotificationIndex;

  return difference;
};
