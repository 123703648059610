import React from 'react';
import cn from 'classnames';

import styles from './commonStyles.module.scss';

const ARStatusBar = ({ status, rate, basePrice }) => {
  const isDiscountedRate = (basePrice !== rate);

  return (
    <div className={styles.statusWrapper}>
      <span className={styles.status}>
        {status}
      </span>
      <span className={styles.statusRate}>
        <p className={styles.statusDiscountedRate}>
          {isDiscountedRate && `$${rate.toFixed(2)}/min`}
        </p>
        <p className={cn(styles.statusRate, {
          [styles.baseRate]: isDiscountedRate,
        })}
        >
          {`$${basePrice?.toFixed(2)}/min`}
        </p>
      </span>
    </div>
  );
};

export default ARStatusBar;
