import React from 'react';
import { useSelector } from 'react-redux';
import { PopUpNotification } from 'extracted-chat-components';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  ErrorRequestType,
  MixpanelEvents,
} from 'constants/enums';
import {
  FUNDS_TO_ADD,
  MIN_TIME_CHAT_IS_ACTIVE,
} from 'constants/constants';
import { selectUserAgent } from 'selectors/selectors';
import { API } from 'src/utils/api';
import { NotHaveEnoughFundsProps } from 'types/componentTypes';
import MixpanelActions from 'src/utils/mixpanel';
import { SentryMethods } from 'src/utils/sentryMethods';
import { LocalStorage } from 'src/utils/storageHandler';
import { setInlineNotification as _setInlineNotification } from 'actions/appActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  getPlatform,
  handleSwitchToPhoneNotifications,
  removeInlineNotificationFromLocalStorage,
  kountSessionIDHandler,
  getGA4Properties,
} from 'src/utils/commonUtil';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';

const NotHaveEnoughFunds: React.FC<NotHaveEnoughFundsProps> = ({
  isNotificationPopUp,
  popUpNotificationData,
  sideUser,
  chatId,
  setLoadingState,
  showPopUpNotification,
  setPopUpNotificationData,
  handleAddFundsError,
  removePopUpNotification,
  setActivity,
  addMixpanelEventAlertTapped,
  addPopUpNotification,
  setRequestErrorState,
}) => {
  const fundsToAddForCall = LocalStorage.getItem(FUNDS_TO_ADD);
  const funds = fundsToAddForCall || sideUser?.customerPrice * MIN_TIME_CHAT_IS_ACTIVE;
  const setInlineNotification = useBindDispatch(_setInlineNotification);

  const userAgent = useSelector(selectUserAgent);

  const showCallPopUpAfterPayment = async () => {
    try {
      handleSwitchToPhoneNotifications(
        {
          addPopUpNotification,
          sideUser,
        },
      );
      setActivity();
    } catch (e) {
      setRequestErrorState(true, ErrorRequestType.ALERT_ABOVE_INPUT);
    } finally {
      setLoadingState(false);
    }
  };

  const handleCloseNotification = (isAcceptButton = false) => {
    if (popUpNotificationData.reason === 'switchToPhone' && isAcceptButton) {
      showCallPopUpAfterPayment();
    } else {
      showPopUpNotification(false);
      removePopUpNotification();
      setActivity();
      setLoadingState(false);
    }
  };

  const handleAcceptButton = async () => {
    try {
      const kountSessionID = await kountSessionIDHandler();
      MixpanelActions.track(MixpanelEvents.ALERT_TAPPED, {
        alert_type: 'insufficient funds',
        alert_selection: 'add $x to continue',
        alert_option: `${funds}`,
        screen_name: 'chat window',
        screen_title: 'chat window',
        ...getPsychicInfoMixpanel(sideUser),
      });
      setLoadingState(true);

      // in case chat end mode use add funds request without chat id for s2p
      const sourcePlatform = getPlatform(userAgent);
      const addFundsPayload = {
        sourcePlatform,
        fundsToAdd: funds as string,
        KountSessionID: kountSessionID,
        GA4Parameters: getGA4Properties(),
      };
      await API.Chat.addFunds(chatId, addFundsPayload);

      handleCloseNotification(true);
      LocalStorage.removeItem(FUNDS_TO_ADD);
      removeInlineNotificationFromLocalStorage();
      setInlineNotification('', null);
    } catch (e) {
      SentryMethods.captureException(e);
      handleAddFundsError(e, (data) => {
        setPopUpNotificationData(data);
        showPopUpNotification(true);
      });
      setLoadingState(false);
    }
  };

  const handleCancelButton = () => {
    LocalStorage.removeItem(FUNDS_TO_ADD);
    addMixpanelEventAlertTapped({
      alertType: 'insufficient funds',
      alertSelection: 'cancel',
    });
    handleCloseNotification();
  };

  const acceptButton = {
    text: `Add $${funds} to continue`,
    color: ButtonColor.WARNING,
    size: ButtonSize.S,
    shape: ButtonShape.ROUNDED,
    handler: handleAcceptButton,
  };

  const rejectButton = {
    text: 'Cancel',
    color: ButtonColor.ONLY_TEXT,
    size: ButtonSize.XS,
    shape: ButtonShape.ROUNDED,
    handler: handleCancelButton,
  };

  return (
    <PopUpNotification
      isOpen={isNotificationPopUp}
      title={popUpNotificationData.title}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isBigWidth={false}
    />
  );
};

export default NotHaveEnoughFunds;
