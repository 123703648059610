import React from 'react';
import cn from 'classnames';

import styles from './IndicatorButton.module.scss';

const IndicatorButton = ({ className = '' }) => (
  <div className={cn(styles.indicatorWrapper, { [className]: !!className })}>
    <span className={styles.indicator}>New</span>
  </div>
);

export default IndicatorButton;
