import Bowser from 'bowser';

import { View } from 'constants/enums';
import * as types from 'src/redux/actions/actionsTypes';
import {
  PopUpNotificationData,
  RequestErrorData,
  SdkConfiguration,
  CallBackQueueData,
  ToastBannerData,
} from 'types/objectTypes';

export const setCommand = (command: string) => ({
  type: types.SET_COMMAND,
  payload: { command },
});

export const setDataFromSdk = (command: string, dataFromSdk: object) => ({
  type: types.SET_DATA_FROM_SDK,
  payload: { command, dataFromSdk },
});

export const setBootDataFromSdk = (sdkConfiguration: SdkConfiguration) => ({
  type: types.SET_BOOTED_DATA_FROM_SDK,
  payload: { sdkConfiguration },
});

export const setSdkVisibilityMode = (payload: string) => ({
  type: types.SET_SDK_VISIBILITY_MODE,
  payload,
});

export const setConversationSdk = (dataFromSdk: any) => ({
  type: types.SET_CONVERSATION_SDK,
  payload: dataFromSdk,
});

export const setSessionSdk = (dataFromSdk: any) => ({
  type: types.SET_SESSION_SDK,
  payload: dataFromSdk,
});

export const destroySessionSdk = (isShouldEnd?: boolean) => ({
  type: types.DESTROY_SESSION_SDK,
  payload: isShouldEnd,
});

export const showLogoutPrompt = () => ({
  type: types.SHOW_LOGOUT_PROMPT,
});

export const clearDataFromSdk = () => ({
  type: types.CLEAR_DATA_FROM_SDK,
});

export const removeCommand = () => ({
  type: types.REMOVE_COMMAND,
});

export const setIsSwitchedConversations = (value: boolean) => ({
  type: types.SET_SWITCHED_CONVERSATION,
  payload: value,
});

export const setView = (view: View) => ({
  type: types.SET_VIEW,
  payload: view,
});

export const showPopUpNotification = (payload) => ({
  type: types.SHOW_POPUP_NOTIFICATION,
  payload,
});

export const setIsVisibleReminderPopup = (payload) => ({
  type: types.SET_IS_VISIBLE_REMINDER_POPUP,
  payload,
});

export const setPopUpNotificationData = (
  payload: PopUpNotificationData | PopUpNotificationData[],
) => ({
  type: types.SET_POPUP_NOTIFICATION_DATA,
  payload,
});

export const setShowInitModal = (payload) => ({
  type: types.SHOW_INIT_CHAT_MODAL,
  payload,
});

export const setIsLiveChatActive = (isLiveChatActive: boolean) => ({
  type: types.SET_IS_LIVE_CHAT_ACTIVE,
  payload: isLiveChatActive,
});

export const setIsLiveChatCanceled = (isLiveChatCanceled: boolean) => ({
  type: types.SET_IS_LIVE_CHAT_CANCELED,
  payload: isLiveChatCanceled,
});

export const setIsShownIncomingRequest = (payload: boolean) => ({
  type: types.SET_IS_SHOWN_INCOMING_REQUEST,
  payload,
});

export const setLoadingState = (condition: boolean, isThinking?: boolean) => ({
  type: types.SET_IS_LOADING,
  payload: {
    condition,
    isThinking,
  },
});

export const setBackdropState = (isBackdrop: boolean) => ({
  type: types.SET_IS_BACKDROP,
  payload: isBackdrop,
});

export const setUserAgent = (userAgent: Bowser.Parser.Parser) => ({
  type: types.SET_USER_AGENT,
  payload: userAgent,
});

export const setIsPhoneReading = (payload: boolean) => ({
  type: types.SET_IS_PHONE_READING,
  payload,
});

export const restartChat = (payload) => ({
  type: types.RESET_CHAT,
  payload,
});

export const hideEmojiPicker = (payload: boolean) => ({
  type: types.HIDE_EMOJI_PICKER,
  payload,
});

export const emitErrorNotification = (payload?: any) => ({
  type: types.EMIT_ERROR_NOTIFICATION,
  payload,
});

export const setIsVisibleHeaderBanner = (isVisible) => ({
  type: types.SET_IS_VISIBLE_HEADER_BANNER,
  payload: isVisible,
});

export const setHeaderBannerContent = (content: {
  type?: string,
  title?: string,
  reason?: string,
  description?: string
} | any) => ({
  type: types.SET_HEADER_BANNER_CONTENT,
  payload: content,
});

export const handlePsychicMissChat = (attributes: any, index: number) => ({
  type: types.HANDLE_PSYCHIC_MISS_CHAT,
  payload: { attributes, index },
});

export const clearApp = () => ({
  type: types.CLEAR_APP,
});

export const addPopUpNotification = (payload: PopUpNotificationData) => ({
  type: types.ADD_POP_UP_NOTIFICATION,
  payload,
});

export const removePopUpNotification = () => ({
  type: types.REMOVE_POP_UP_NOTIFICATION,
});

export const addSdkCallback = (key: string, value: Function) => ({
  type: types.ADD_SDK_CALLBACK,
  payload: { key, value },
});

export const removeSdkCallback = (key: string) => ({
  type: types.REMOVE_SDK_CALLBACK,
  payload: { key },
});

export const setDiffInTime = (diff: number) => ({
  type: types.SET_DIFF_IN_TIME,
  payload: diff,
});

export const handleRequestError = (payload?: RequestErrorData) => ({
  type: types.HANDLE_REQUEST_ERROR,
  payload,
});

export const setIsCreateChatRequest = (payload: boolean) => ({
  type: types.SET_IS_PENDING_CREATE_CHAT,
  payload,
});

export const setRequestErrorState = (isError: boolean, typeErrorNotification: string) => ({
  type: types.REQUEST_ERROR,
  payload: {
    isError,
    typeErrorNotification,
  },
});

export const checkDiffInTime = (
  date: string,
  currentDifferenceInTime?: string | number,
) => ({
  type: types.CHECK_DIFF_IN_TIME,
  payload: { date, currentDifferenceInTime },
});

export const setRedirectState = (isRedirect: boolean, path: string) => ({
  type: types.SET_REDIRECT_STATE,
  payload: { isRedirect, path },
});

export const setTwilioConnectingStatus = (connectingStatus: string) => ({
  type: types.SET_TWILIO_CONNECTING_STATUS,
  payload: connectingStatus,
});

export const setInternetConnectingStatus = (connectingStatus: string) => ({
  type: types.SET_INTERNET_CONNECTING_STATUS,
  payload: connectingStatus,
});

export const getSystemChannel = (view: View | string, missedNotificationsAmount?: number) => ({
  type: types.GET_SYSTEM_CHANNEL,
  payload: {
    view,
    missedNotificationsAmount,
  },
});

export const setupNotifications = (missedNotificationsAmount?: number) => ({
  type: types.SETUP_NOTIFICATIONS,
  payload: { missedNotificationsAmount },
});

export const providePhoneNumber = (phoneNumber: string) => ({
  type: types.SWITCH_TO_PHONE_SDK,
  phoneNumber,
});

export const restoreTwilioClient = (payload) => ({
  type: types.RESTORE_TWILIO_CLIENT,
  payload,
});

export const setErrorType = (type, payload?) => ({
  type,
  payload,
});

export const setInlineNotification = (type, index) => ({
  type: types.SET_INLINE_NOTIFICATION,
  payload: {
    type,
    messageIndex: index,
  },
});

export const setHistoryModeState = (payload: boolean) => ({
  type: types.SET_HISTORY_MODE_STATE,
  payload,
});

export const setIsPsychicNotificationsEnable = (isPsychicNotificationsEnable: boolean) => ({
  type: types.SET_PSYCHIC_NOTIFICATIONS_ENABLE,
  payload: isPsychicNotificationsEnable,
});

export const setIsDeclineOMEnabled = (
  isDeclineOMEnabled: boolean,
) => ({
  type: types.SET_PSYCHIC_DECLINE_OM_ENABLE,
  payload: isDeclineOMEnabled,
});

export const setActiveTab = (activeTab: string) => ({
  type: types.SET_ACTIVE_TAB,
  payload: activeTab,
});

export const setActivePage = (activePage: string) => ({
  type: types.SET_ACTIVE_PAGE,
  payload: activePage,
});

export const checkNotificationReactionUnreadCount = () => ({
  type: types.CHECK_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT,
});

export const setNotificationUnreadCount = (unreadCount: number) => ({
  type: types.SET_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT,
  payload: unreadCount,
});

export const setConfirmationScreen = (isConfirmationScreen: boolean) => ({
  type: types.SET_CONFIRMATION_SCREEN,
  payload: isConfirmationScreen,
});

export const setIsElectronApp = (isElectronApp: boolean) => ({
  type: types.SET_IS_ELECTRON_APP,
  payload: isElectronApp,
});

export const setDashboardPsychicNews = (dashboardPsychicNews: String) => ({
  type: types.SET_DASHBOARD_PSYCHIC_NEWS,
  payload: dashboardPsychicNews,
});

export const getDashboardPsychicNews = () => ({
  type: types.GET_DASHBOARD_PSYCHIC_NEWS,
});

export const setPsychicPhoneStatus = (status: boolean, channel: number) => ({
  type: types.SET_PSYCHIC_PHONE_STATUS,
  payload: {
    status,
    channel,
  },
});

export const setCallbackQueue = (payload: Array<CallBackQueueData>) => ({
  type: types.SET_CALLBACK_QUEUE,
  payload,
});

export const setIsPsychicLoginOutsideOfSchedule = (isPsychicLoginOutsideOfSchedule: boolean) => ({
  type: types.SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE,
  payload: isPsychicLoginOutsideOfSchedule,
});

export const setIsPsychicLoginOutsideOfScheduleEditMode = (
  isPsychicLoginOutsideOfScheduleEditMode: boolean,
) => ({
  type: types.SET_IS_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_EDIT_MODE,
  payload: isPsychicLoginOutsideOfScheduleEditMode,
});

export const setPsychicLoginOutsideOfScheduleMsg = (psychicLoginOutsideOfScheduleMsg: string) => ({
  type: types.SET_PSYCHIC_LOGIN_OUTSIDE_OF_SCHEDULE_MSG,
  payload: psychicLoginOutsideOfScheduleMsg,
});

export const setPsychicLoginOutsideLastActivityId = (
  psychicLoginOutsideLastActivityId: number,
) => ({
  type: types.SET_PSYCHIC_LOGIN_OUTSIDE_LAST_ACTIVITY,
  payload: psychicLoginOutsideLastActivityId,
});

export const setPsychicLoginOutsidePopupShow = (psychicLoginOutsidePopupShow: boolean) => ({
  type: types.SET_PSYCHIC_LOGIN_OUTSIDE_POPUP_SHOW,
  payload: psychicLoginOutsidePopupShow,
});

export const setActiveMobilePage = (activeMobilePage: string) => ({
  type: types.SET_ACTIVE_MOBILE_PAGE,
  payload: activeMobilePage,
});

export const setIsChatInInitState = (payload: boolean) => ({
  type: types.SET_IS_CHAT_IN_INIT_STATE,
  payload,
});

export const setIsChatAutoReloadEnabled = (isChatAutoReloadEnabled: boolean) => ({
  type: types.SET_IS_CHAT_AUTO_RELOAD,
  payload: isChatAutoReloadEnabled,
});

export const setAutoReloadPromptLabels = (payload: any) => ({
  type: types.SET_AUTO_RELOAD_PROMPT_LABELS,
  payload,
});

export const setToastBannerData = (payload: ToastBannerData) => ({
  type: types.SET_TOAST_BANNER_DATA,
  payload,
});

export const removeToastBannerData = () => ({
  type: types.REMOVE_TOAST_BANNER_DATA,
});

export const setLocation = (payload) => ({
  type: types.SET_LOCATION,
  payload,
});
