import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import {
  ChatRequest, NotificationType, Status, View,
} from 'constants/enums';
import {
  selectCurrentChat,
  selectIsChatInInitState,
  selectInitChatModalState,
  selectMessages,
  selectNotifications,
} from 'selectors/selectors';

import style from './BillingNotification.module.scss';

const BillingNotification = () => {
  const messages = useSelector(selectMessages);
  const currentChat = useSelector(selectCurrentChat);
  const notificationsChat = useSelector(selectNotifications);
  const isChatInInitState = useSelector(selectIsChatInInitState);
  const isInitChatModal = useSelector(selectInitChatModalState);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [notification, setNotification] = useState<any>(null);
  const { sideUser } = currentChat;

  const isChatPopup = !isChatInInitState && isInitChatModal;
  let isStartChat;

  useEffect(() => {
    if (isChatPopup) {
      const startChatAlert = {
        id: 'initChat',
        title: '',
        type: 'start-chat-notification',
        description: 'While we’re connecting, you can send your first message.',
      };

      setNotification(startChatAlert);
    } else if (notification && notification.id === 'initChat') {
      setIsVisible(false);
      setNotification(null);
    }
  }, [sideUser, isChatPopup, isInitChatModal, isStartChat]);

  const getArrMessagesCustomer = () => messages.filter((
    message,
  ) => message?.attributes?.participantType === View.CUSTOMER);

  const getArrMessagesEndChat = () => messages.filter((
    message,
  ) => message?.attributes?.notificationType === NotificationType.CHAT_ENDED);

  const getArrMessagesExpired = () => notificationsChat.filter((
    ntf,
  ) => ntf?.attributes?.type === ChatRequest.CHAT_REQUEST_EXPIRED);

  useEffect(() => {
    setIsVisible(!!notification);
  }, [notification]);

  useEffect(() => {
    if (currentChat?.status === Status.STARTED) {
      setIsVisible(false);
      setNotification(null);
    }
  }, [currentChat]);

  useEffect(() => {
    if (messages?.length) {
      const arrMessagesCustomer = getArrMessagesCustomer();
      const arrMessagesEndChat = getArrMessagesEndChat();
      const arrExpiredNtf = getArrMessagesExpired();

      if (arrMessagesCustomer?.length && arrMessagesEndChat?.length) {
        isStartChat = arrMessagesCustomer[arrMessagesCustomer.length - 1].timestamp
          > arrMessagesEndChat[arrMessagesEndChat.length - 1].timestamp;
      }

      if (arrMessagesCustomer?.length && !arrMessagesEndChat?.length) {
        setNotification(null);
      }

      let isExpired = false;

      if (arrExpiredNtf?.length && arrMessagesCustomer?.length) {
        isExpired = arrExpiredNtf[arrExpiredNtf.length - 1]?.timestamp
          > arrMessagesCustomer[arrMessagesCustomer.length - 1]?.timestamp;
      }

      if (isStartChat && !isExpired) {
        setNotification(null);
      }
    }
  }, [messages, currentChat]);

  const wrapper = cn(isVisible || (isChatPopup && isStartChat) ? style.wrapper : style.hidden, {
    [style.contentWrapper]: isChatPopup,
  });
  const styleNotification = cn(wrapper, notification && style.lastNotificationPadding);
  const styleDescription = cn(isChatPopup ? style.contentDescription
    : style.notificationDescription);

  return (
    <div className={styleNotification}>
      {notification && (
        <div key={notification.id} className={style.notification}>
          <h4>
            {notification.title}
          </h4>
          <p className={styleDescription}>
            {notification.description}
          </p>
        </div>
      )}
    </div>
  );
};

export default BillingNotification;
