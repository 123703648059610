import * as types from 'actions/actionsTypes';

export const receiveSwitchToPhoneInfo = () => ({
  type: types.RECEIVE_SWITCH_TO_PHONE_INFO,
});

export const setCustomerPhoneNumbers = (payload: any[]) => ({
  type: types.SET_CUSTOMER_PHONE_NUMBERS,
  payload,
});

export const setSwitchToPhoneAppointments = (payload) => ({
  type: types.SET_CALLBACK_APPOINTMENT_INFO,
  payload,
});

export const validateCustomerPhone = (payload) => ({
  type: types.VALIDATE_PHONE_NUMBER,
  payload,
});

export const setCustomerPhoneValidated = (payload) => ({
  type: types.SET_CUSTOMER_PHONE_VALIDATED,
  payload,
});

export const setIsPhoneNumber = (payload) => ({
  type: types.SET_IS_PHONE_NUMBER,
  payload,
});

export const saveCustomerPhone = (payload) => ({
  type: types.SAVE_CUSTOMER_PHONE,
  payload,
});

export const setCustomerPhoneIsSaved = (payload) => ({
  type: types.SET_CUSTOMER_PHONE_SAVED,
  payload,
});

export const switchToPhone = (payload) => ({
  type: types.SWITCH_TO_PHONE,
  payload,
});
