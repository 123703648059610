import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import ClientLabelsOverlay from 'components/Popups/ClientLabelsOverlay';
import PsychicEarlyLogin from 'components/Popups/PsychicEarlyLogin';
import StandByPopup from 'components/Popups/StandByPopup';
import ExpiredToken from 'components/Popups/ExpiredToken';
import BeforeAppointmentPopUp from 'components/Popups/BeforeAppointmentPopUp';
import DueToAppointment from 'components/Popups/DueToAppointment';
import NotEnabledForChat from 'components/Popups/NotEnabledForChat';
import TakeBreak from 'components/Popups/TakeBreak';
import ActiveChat from 'components/Popups/ActiveChat';
import ActivePhoneCall from 'components/Popups/ActivePhoneCall';
import LogoutPopup from 'components/Popups/LogoutPopup';
import PsychicEarlyLogout from 'components/Popups/PsychicEarlyLogout';
import EndChatRequestPopUp from 'components/Popups/EndChatRequestPopUp';
import CustomerFinishedChatPopUp from 'components/Popups/FinishedChatPopUp';
import MakeCall from 'components/Popups/MakeCall';
import DueToInactivity from 'components/Popups/DueToInactivity';
import LackOfCharacters from 'components/Popups/LackOfCharacters';
import SendMessageConfirmation from 'components/Popups/SendMessageConfirmation';
import NotAvailablePsychic from 'components/Popups/NotAvailablePsychic';
import NotHaveEnoughFunds from 'components/Popups/NotHaveEnoughFunds';
import PaymentFailed from 'components/Popups/PaymentFailed';
import InsufficientFundsPopupContainer from 'components/Popups/InsufficientFunds';
import PsychicMissChat from 'components/Popups/PsychicMissChat';
import CustomerCancelOfflineChat from 'components/Popups/CustomerCancelOfflineChat';
import ErrorNotification from 'components/Popups/ErrorNotification';
import PaymentFailedDueToReachingLimit from 'components/Popups/PaymentFailedDueToReachingLimit';
import DeclineOfflineMessage from 'components/Popups/DeclineOfflineMessage';
import AllPsychicMuted from 'components/Popups/AllPsychicMuted';
import {
  MixpanelEvents,
  MixpanelMessages,
  PopUpNotification,
  PopUpNotificationType,
  View,
} from 'constants/enums';
import {
  removePopUpNotification as _removePopUpNotification,
  setIsLiveChatActive as _setIsLiveChatActive,
  setLoadingState as _setLoadingState,
  showPopUpNotification as _showPopUpNotification,
  setRequestErrorState as _setRequestErrorState,
  setPopUpNotificationData as _setPopUpNotificationData,
  setShowInitModal as _setShowInitModal,
  addPopUpNotification as _addPopUpNotification,
  setIsSwitchedConversations as _setIsSwitchedConversations,
  clearApp as _clearApp,
  destroySessionSdk as _destroySessionSdk,
  setRedirectState as _setRedirectState,
} from 'actions/appActions';
import { IAppState } from 'store';
import { logout as _logout } from 'actions/authActions';
import {
  deleteCurrentChat as _deleteCurrentChat,
  deleteNotifications as _deleteNotifications,
  removeChatId as _removeChatId,
  setChatMinutesActive as _setChatMinutesActive,
  deleteCurrentPsychic as _deleteCurrentPsychic,
  clearExtId as _clearExtId,
  removePrevChatData as _removePrevChatData,
  setChatStatus as _setChatStatus,
  setFunds as _setFunds,
  setCustomerBalance as _setCustomerBalance,
  clearChatEvents as _clearChatEvents,
  clearChat as _clearChat,
  deleteSystemChannel as _deleteSystemChannel,
  setActiveChat as _setActiveChat,
  updateCallbackBanner as _updateCallbackBanner,
  setInitChatBanner as _setInitChatBanner,
  setInitChatNotification as _setInitChatNotification,
} from 'actions/chatActions';
import {
  validateCustomerPhone as _validateCustomerPhone,
  saveCustomerPhone as _saveCustomerPhone,
  switchToPhone as _switchToPhone,
  setIsPhoneNumber as _setIsPhoneNumber,
} from 'actions/switchToPhoneActions';
import { earlyLogout as _earlyLogout } from 'actions/statusToggleActions';
import { LocalStorage } from 'src/utils/storageHandler';
import MixpanelActions from 'src/utils/mixpanel';
import { getPsychicInfoMixpanel, getMixpanelScreenName } from 'src/utils/helpers';
import {
  selectChatId,
  selectCurrentChat,
  selectIsLiveChatActive,
  selectPopNotification,
  selectRedirectState,
  selectSdkCallbackStorage,
  selectSideUser,
  selectView,
  selectUserAgent,
  selectIsHistoryMode,
  selectIsChatEnd,
  selectIsPsychicLoginOutsideOfSchedule,
} from 'selectors/selectors';
import {
  selectSwitchToPhoneAppointment,
  selectIsPhoneValidated,
  selectIsNumberSaved,
  selectCustomerPhoneNumbers,
  selectCountryCodes,
  selectIsPhoneNumber,
} from 'selectors/switchToPhoneSelectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  PopUpNotificationData,
  AlertTapped,
  AlertViewed,
} from 'types/objectTypes';
import { removeCurrentOfflineChatHandler } from 'actions/offlineChatActions';
import { handlePaymentError } from 'src/utils/payments';

const notifications = [
  PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
  PopUpNotificationType.EXPIRED_TOKEN,
  PopUpNotificationType.PAYMENT_FAILED,
  PopUpNotificationType.REQUEST_ERROR,
  PopUpNotification.PAYMENT_FAILED_DUE_TO_REACHING_LIMIT,
  PopUpNotification.ACTIVE_CHAT,
  PopUpNotification.ACTIVE_PHONE_CALL,
];

const getNotification = (popUpNotificationData) => (Array.isArray(popUpNotificationData)
  ? popUpNotificationData[0]
  : popUpNotificationData);

const getAlertType = (type, reason) => {
  switch (type) {
    case PopUpNotificationType.SWITCH_TO_PHONE: {
      return 'add/select number and callback';
    }
    case PopUpNotificationType.DUE_TO_INACTIVITY: {
      return 'chat inactivity three minute warning';
    }
    case PopUpNotificationType.END_CHAT_REQUEST: {
      return 'end chat';
    }
    case PopUpNotificationType.INSUFFICIENT_FUNDS: {
      return 'add funds to continue';
    }
    case PopUpNotificationType.NOT_AVAILABLE_PSYCHIC: {
      return reason
        ? `${MixpanelMessages.PSYCHIC_UNAVAILABLE} / ${reason}`
        : MixpanelMessages.PSYCHIC_UNAVAILABLE;
    }
    case PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS: {
      return 'insufficient funds';
    }
    case PopUpNotificationType.PAYMENT_FAILED: {
      return 'unable to process payment';
    }
    case PopUpNotificationType.BEFORE_APPOINTMENT: {
      return 'psychic upcoming appointment';
    }
    default:
      return '';
  }
};

const addMixpanelEventAlertViewed = ({
  alertType,
  view,
  screenName,
}: AlertViewed) => {
  if (view === View.PSYCHIC) {
    return;
  }

  MixpanelActions.track(MixpanelEvents.ALERT_VIEWED, {
    alert_type: alertType,
    screen_name: getMixpanelScreenName(screenName),
    screen_title: 'chat window',
  });
};

export const addMixpanelEventAlertTapped = ({
  alertType,
  view,
  alertSelection,
  user,
  screenName,
}: AlertTapped) => {
  if (view === View.PSYCHIC) {
    return;
  }

  MixpanelActions.track(MixpanelEvents.ALERT_TAPPED, {
    alert_type: alertType,
    alert_selection: alertSelection,
    screen_name: screenName || 'chat window',
    screen_title: 'chat window',
    ...getPsychicInfoMixpanel(user),
  });
};

const PopUpNotificationContainer = () => {
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const removeChatId = useBindDispatch(_removeChatId);
  const setLoadingState = useBindDispatch(_setLoadingState);
  const deleteNotifications = useBindDispatch(_deleteNotifications);
  const setIsLiveChatActive = useBindDispatch(_setIsLiveChatActive);
  const setChatMinutesActive = useBindDispatch(_setChatMinutesActive);
  const setActiveChat = useBindDispatch(_setActiveChat);
  const removePopUpNotification = useBindDispatch(_removePopUpNotification);
  const setRequestErrorState = useBindDispatch(_setRequestErrorState);
  const deleteCurrentChat = useBindDispatch(_deleteCurrentChat);
  const removePrevChatData = useBindDispatch(_removePrevChatData);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const deleteCurrentPsychic = useBindDispatch(_deleteCurrentPsychic);
  const clearExtId = useBindDispatch(_clearExtId);
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const deleteSystemChannel = useBindDispatch(_deleteSystemChannel);
  const destroySessionSdk = useBindDispatch(_destroySessionSdk);
  const setChatStatus = useBindDispatch(_setChatStatus);
  const setFunds = useBindDispatch(_setFunds);
  const setCustomerBalance = useBindDispatch(_setCustomerBalance);
  const setIsSwitchedConversations = useBindDispatch(_setIsSwitchedConversations);
  const clearApp = useBindDispatch(_clearApp);
  const clearChatEvents = useBindDispatch(_clearChatEvents);
  const clearChat = useBindDispatch(_clearChat);
  const setRedirectState = useBindDispatch(_setRedirectState);
  const logout = useBindDispatch(_logout);
  const earlyLogout = useBindDispatch(_earlyLogout);
  const addPhoneNumber = useBindDispatch(_saveCustomerPhone);
  const validateCustomerPhone = useBindDispatch(_validateCustomerPhone);
  const switchToPhone = useBindDispatch(_switchToPhone);
  const updateCallbackBanner = useBindDispatch(_updateCallbackBanner);
  const setIsPhoneNumber = useBindDispatch(_setIsPhoneNumber);
  const setInitChatBanner = useBindDispatch(_setInitChatBanner);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);

  const isPhoneSaved = useSelector(selectIsNumberSaved);
  const isPhoneValid = useSelector(selectIsPhoneValidated);
  const appointmentInfo = useSelector(selectSwitchToPhoneAppointment);
  const customerPhoneNumbers = useSelector(selectCustomerPhoneNumbers);
  const redirectState = useSelector(selectRedirectState);
  const fundsToAdd = useSelector((state: IAppState) => state.chat.fundsToAdd);
  const customerBalance = useSelector((state: IAppState) => state.chat.customerBalance);
  const sideUser = useSelector(selectSideUser);
  const popUpNotificationData = useSelector(selectPopNotification);
  const view = useSelector(selectView);
  const countryCodes = useSelector(selectCountryCodes);
  const sdkCallbackStorage = useSelector(selectSdkCallbackStorage);
  const isNotificationPopUp = useSelector((state: IAppState) => state.app.isNotificationPopUp);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const chatId = useSelector(selectChatId) || localStorage.getItem('chatId');
  const isChatEnd = useSelector(selectIsChatEnd);
  const isRequestError = useSelector((state: IAppState) => state.app.errorRequest.isError);
  const isCreateChatPending = useSelector((state: IAppState) => state.app.isCreateChatPending);
  const currentChat = useSelector(selectCurrentChat);
  const userAgent = useSelector(selectUserAgent);
  const isHistoryMode = useSelector(selectIsHistoryMode);
  const isPsychicLoginOutsideOfSchedule = useSelector(selectIsPsychicLoginOutsideOfSchedule);
  const isPhoneNumber = useSelector(selectIsPhoneNumber);

  const [psychicOutOfScheduleLogin, setPsychicOutOfScheduleLogin] = useState<any>(false);
  const [notification, setNotification] = useState<any>(popUpNotificationData);

  useEffect(() => {
    const notification = getNotification(popUpNotificationData);

    if (isNotificationPopUp && !!notification?.notificationType) {
      const alertType = getAlertType(notification?.notificationType, notification?.reason);

      if (alertType) {
        addMixpanelEventAlertViewed({
          alertType,
          view,
          screenName: notification?.notificationType,
        });
      }
    }

    if (isNotificationPopUp
      && notification?.notificationType === PopUpNotificationType.PSYCHIC_LOGIN_OUTSIDE_SCHEDULE
      && isPsychicLoginOutsideOfSchedule
    ) {
      setPsychicOutOfScheduleLogin(isPsychicLoginOutsideOfSchedule);
    }
  }, [popUpNotificationData, isNotificationPopUp, view]);

  useEffect(() => {
    setPsychicOutOfScheduleLogin(isPsychicLoginOutsideOfSchedule);
  }, [isPsychicLoginOutsideOfSchedule]);
  useEffect(() => {
    setPsychicOutOfScheduleLogin(isPsychicLoginOutsideOfSchedule);
  }, [isPsychicLoginOutsideOfSchedule]);

  useEffect(() => {
    if (view === View.PSYCHIC) {
      return;
    }

    const notification = getNotification(popUpNotificationData);
    const notificationType = notification?.notificationType;
    const currentNotification = !!notification?.notificationType
      && !notifications.includes(notificationType);

    if ((notificationType === PopUpNotificationType.INSUFFICIENT_FUNDS
      || notificationType === PopUpNotificationType.SWITCH_TO_PHONE)
      && !isLiveChatActive) {
      return;
    }

    if (currentNotification && (!isChatEnd && !isLiveChatActive) && !isCreateChatPending) {
      removePopUpNotification();
      showPopUpNotification(false);
    }
  }, [isLiveChatActive, popUpNotificationData, view, isCreateChatPending, isChatEnd]);

  const handleCallButton = (phoneData) => {
    switchToPhone({ addMixpanelEventAlertTapped, sideUser, phoneData });
  };

  const handleCancelCallButton = () => {
    showPopUpNotification(false);
    addMixpanelEventAlertTapped({
      alertType: 'add/select number and callback',
      view: 'chat window',
      alertSelection: !isChatEnd ? 'Stay in chat' : 'Cancel',
      user: sideUser,
      screenName: isHistoryMode ? 'chat history mode' : 'switch to phone',
    });
  };

  const setActivity = useCallback(() => {
    setActiveChat();
  }, [chatId]);

  const handleAddFundsError = useCallback((e, callback) => {
    handlePaymentError(e, callback);
  }, []);

  useEffect(() => {
    const notification = getNotification(popUpNotificationData);

    if (!!notification?.title || !!notification?.description) {
      setNotification(notification);

      const itemEntries = [
        { key: 'title', value: notification.title || '' },
        { key: 'description', value: notification.description || '' },
        { key: 'notificationType', value: notification.notificationType || '' },
        { key: 'reason', value: notification.reason || '' },
      ];

      LocalStorage.setItems(itemEntries);
    }
  }, [popUpNotificationData]);

  switch (notification?.notificationType) {
    case PopUpNotification.ACTIVE_PHONE_CALL: {
      return (
        <ActivePhoneCall
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          sdkCallbackStorage={sdkCallbackStorage}
          destroySessionSdk={destroySessionSdk}
          clearApp={clearApp}
          clearChat={clearChat}
          clearChatEvents={clearChatEvents}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotification.ACTIVE_CHAT: {
      return (
        <ActiveChat
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={popUpNotificationData}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.END_CHAT_REQUEST: {
      return (
        <EndChatRequestPopUp
          view={view}
          setRequestErrorState={setRequestErrorState}
          removePrevChatData={removePrevChatData}
          setChatMinutesActive={setChatMinutesActive}
          setLoadingState={setLoadingState}
          setIsLiveChatActive={setIsLiveChatActive}
          deleteCurrentChat={deleteCurrentChat}
          deleteNotifications={deleteNotifications}
          showPopUpNotification={showPopUpNotification}
          sdkCallbackStorage={sdkCallbackStorage}
          currentChat={currentChat}
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={notification}
          addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
          updateCallbackBanner={updateCallbackBanner}
          setShowInitModal={setShowInitModal}
          setInitChatBanner={setInitChatBanner}
        />
      );
    }
    case PopUpNotificationType.CHAT_TASK_CANCELLED_LOW_FUNDS:
    case PopUpNotificationType.INACTIVE_CHAT_ENDED:
    case PopUpNotificationType.LOW_FUNDS_CHAT_ENDED:
    case PopUpNotificationType.CUSTOMER_FINISHED_CHAT:
    case PopUpNotificationType.PSYCHIC_FINISHED_CHAT: {
      return (
        <CustomerFinishedChatPopUp
          popUpNotificationData={notification}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
          deleteCurrentChat={deleteCurrentChat}
          setIsSwitchedConversations={setIsSwitchedConversations}
        />
      );
    }
    case PopUpNotificationType.SWITCH_TO_PHONE: {
      return (
        <MakeCall
          isChatEnd={!isLiveChatActive}
          isPhoneValid={isPhoneValid}
          isPhoneSaved={isPhoneSaved}
          isPhoneNumber={isPhoneNumber}
          phoneNumbers={customerPhoneNumbers}
          appointmentInfo={appointmentInfo}
          setIsPhoneNumber={setIsPhoneNumber}
          addPhoneNumber={addPhoneNumber}
          validatePhoneNumber={validateCustomerPhone}
          handleCallButton={handleCallButton}
          psychicName={sideUser?.friendlyName}
          countryCodes={countryCodes}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
          popUpNotificationData={notification}
          addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
          handleCancelCallButton={handleCancelCallButton}
        />
      );
    }
    case PopUpNotificationType.DUE_TO_INACTIVITY: {
      return (
        <DueToInactivity
          currentChat={currentChat}
          showPopUpNotification={showPopUpNotification}
          removeChatId={removeChatId}
          setLoadingState={setLoadingState}
          setIsLiveChatActive={setIsLiveChatActive}
          setPopUpNotificationData={setPopUpNotificationData}
          removePopUpNotification={removePopUpNotification}
          setRequestErrorState={setRequestErrorState}
          deleteNotifications={deleteNotifications}
          isNotificationPopUp={isNotificationPopUp}
          setActivity={setActivity}
          addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
          popUpNotificationData={popUpNotificationData}
        />
      );
    }
    case PopUpNotificationType.DUE_TO_APPOINTMENT: {
      return (
        <DueToAppointment
          popUpNotificationData={notification}
          showPopUpNotification={showPopUpNotification}
          deleteCurrentChat={deleteCurrentChat}
          setIsSwitchedConversations={setIsSwitchedConversations}
          isNotificationPopUp={isNotificationPopUp}
        />
      );
    }
    case PopUpNotificationType.LACK_OF_CHARACTERS: {
      return (
        <LackOfCharacters
          popUpNotificationData={notification}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.SEND_MESSAGE_CONFIRMATION: {
      return (
        <SendMessageConfirmation
          popUpNotificationData={notification}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.NOT_AVAILABLE_PSYCHIC: {
      return (
        <NotAvailablePsychic
          sdkCallbackStorage={sdkCallbackStorage}
          deleteCurrentChat={deleteCurrentChat}
          deleteCurrentPsychic={deleteCurrentPsychic}
          clearExtId={clearExtId}
          setShowInitModal={setShowInitModal}
          deleteSystemChannel={deleteSystemChannel}
          showPopUpNotification={showPopUpNotification}
          removePopUpNotification={removePopUpNotification}
          destroySessionSdk={destroySessionSdk}
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={notification}
        />
      );
    }
    case PopUpNotificationType.NOT_HAVE_ENOUGH_FUNDS: {
      return (
        <NotHaveEnoughFunds
          isNotificationPopUp={isNotificationPopUp}
          userAgent={userAgent}
          sideUser={sideUser}
          chatId={chatId}
          setActivity={setActivity}
          handleAddFundsError={handleAddFundsError}
          showPopUpNotification={showPopUpNotification}
          addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
          setLoadingState={setLoadingState}
          setPopUpNotificationData={setPopUpNotificationData}
          removePopUpNotification={removePopUpNotification}
          setChatMinutesActive={setChatMinutesActive}
          addPopUpNotification={addPopUpNotification}
          setRequestErrorState={setRequestErrorState}
          popUpNotificationData={notification}
        />
      );
    }
    case PopUpNotificationType.PAYMENT_FAILED: {
      return (
        <PaymentFailed
          showPopUpNotification={showPopUpNotification}
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={notification}
        />
      );
    }
    case PopUpNotificationType.INSUFFICIENT_FUNDS: {
      return (
        <InsufficientFundsPopupContainer
          isNotificationPopUp={isNotificationPopUp}
          chatId={chatId}
          fundsToAdd={fundsToAdd}
          customerBalance={customerBalance}
          sdkCallbackStorage={sdkCallbackStorage}
          sideUser={sideUser}
          destroySessionSdk={destroySessionSdk}
          setIsLiveChatActive={setIsLiveChatActive}
          deleteCurrentChat={deleteCurrentChat}
          removeChatId={removeChatId}
          clearExtId={clearExtId}
          setShowInitModal={setShowInitModal}
          showPopUpNotification={showPopUpNotification}
          setPopUpNotificationData={setPopUpNotificationData}
          setChatStatus={setChatStatus}
          setLoadingState={setLoadingState}
          setFunds={setFunds}
          setCustomerBalance={setCustomerBalance}
          setActivity={setActivity}
          handleAddFundsError={handleAddFundsError}
          addMixpanelEventAlertViewed={addMixpanelEventAlertViewed}
          addMixpanelEventAlertTapped={addMixpanelEventAlertTapped}
          popUpNotificationData={notification}
          setInitChatNotification={setInitChatNotification}
          setInitChatBanner={setInitChatBanner}
        />
      );
    }
    case PopUpNotificationType.PSYCHIC_MISS_CHAT: {
      return (
        <PsychicMissChat
          popUpNotificationData={notification}
          view={view}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.CUSTOMER_CANCEL_OM: {
      return (
        <CustomerCancelOfflineChat
          popUpNotificationData={notification}
          chatId={chatId}
          isNotificationPopUp={isNotificationPopUp}
          removeCurrentOfflineChatHandler={removeCurrentOfflineChatHandler}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.EXPIRED_TOKEN: {
      return (
        <ExpiredToken
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          sdkCallbackStorage={sdkCallbackStorage}
          destroySessionSdk={destroySessionSdk}
          clearApp={clearApp}
          clearChat={clearChat}
          clearChatEvents={clearChatEvents}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.REQUEST_ERROR: {
      return (
        <ErrorNotification
          deleteCurrentChat={deleteCurrentChat}
          clearExtId={clearExtId}
          setShowInitModal={setShowInitModal}
          setIsLiveChatActive={setIsLiveChatActive}
          showPopUpNotification={showPopUpNotification}
          destroySessionSdk={destroySessionSdk}
          setRedirectState={setRedirectState}
          removePopUpNotification={removePopUpNotification}
          setRequestErrorState={setRequestErrorState}
          isRequestError={isRequestError}
          isNotificationPopUp={isNotificationPopUp}
          view={view}
          sdkCallbackStorage={sdkCallbackStorage}
          redirectState={redirectState}
          popUpNotificationData={popUpNotificationData}
        />
      );
    }
    case PopUpNotification.PAYMENT_FAILED_DUE_TO_REACHING_LIMIT: {
      return (
        <PaymentFailedDueToReachingLimit
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
          popUpNotificationData={popUpNotificationData as PopUpNotificationData}
        />
      );
    }
    case PopUpNotificationType.BEFORE_APPOINTMENT: {
      return (
        <BeforeAppointmentPopUp
          popUpNotificationData={popUpNotificationData}
          showPopUpNotification={showPopUpNotification}
          isNotificationPopUp={isNotificationPopUp}
        />
      );
    }
    case PopUpNotificationType.NOT_ENABLED_FOR_CHAT: {
      return (
        <NotEnabledForChat
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.TAKE_A_BREAK: {
      return <TakeBreak popUpNotificationData={popUpNotificationData} />;
    }
    case PopUpNotificationType.LOGOUT: {
      return (
        <LogoutPopup
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
          logout={logout}
        />
      );
    }
    case PopUpNotificationType.STANDBY_PSYCHIC: {
      return (
        <StandByPopup
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.PSYCHIC_EARLY_LOGOUT: {
      return (
        <PsychicEarlyLogout
          popUpNotificationData={popUpNotificationData}
          isNotificationPopUp={isNotificationPopUp}
          showPopUpNotification={showPopUpNotification}
          logout={earlyLogout}
        />
      );
    }
    case PopUpNotificationType.DECLINE_OFFLINE_MESSAGE: {
      return (
        <DeclineOfflineMessage
          removePopUpNotification={removePopUpNotification}
          setPopUpNotificationData={setPopUpNotificationData}
          showPopUpNotification={showPopUpNotification}
        />
      );
    }
    case PopUpNotificationType.PSYCHIC_LOGIN_OUTSIDE_SCHEDULE: {
      return (
        <PsychicEarlyLogin
          isOpen={psychicOutOfScheduleLogin}
          setTrigger={setPsychicOutOfScheduleLogin}
          reason={notification?.reason}
        />
      );
    }
    case PopUpNotificationType.ALL_PSYCHIC_MUTED: {
      return (
        <AllPsychicMuted
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={popUpNotificationData}
          isRequestError={isRequestError}
          showPopUpNotification={showPopUpNotification}
          removePopUpNotification={removePopUpNotification}
        />
      );
    }
    case PopUpNotificationType.CLIENT_LABEL_OVERLAY: {
      return (
        <ClientLabelsOverlay
          isNotificationPopUp={isNotificationPopUp}
          popUpNotificationData={popUpNotificationData}
          showPopUpNotification={showPopUpNotification}
          removePopUpNotification={removePopUpNotification}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default PopUpNotificationContainer;
