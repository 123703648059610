import React, { useCallback, useMemo, useState } from 'react';

import { getFramedUrl, getRandomValue } from 'src/utils/commonUtil';
import { LOGIN_CALENDER_IFRAME_KEY, LOGOUT_CALENDER_IFRAME_KEY } from 'constants/constants';

import Iframe from './Iframe';

const Calendar = ({
  extId,
  password,
}) => {
  const [isIframeLoginUrl, setIframeLoginUrl] = useState<boolean>(false);
  const [iframeKey, setIframeKey] = useState<string>(LOGOUT_CALENDER_IFRAME_KEY);

  const linkToCalendar = useMemo(() => {
    let parameters = {};

    if (isIframeLoginUrl) {
      parameters = {
        LoginParameters: getRandomValue(1, 100),
        username: extId,
        password,
        showbanner: 0,
        pageid: 'calendar',
      };
    } else {
      parameters = {
        LoginParameters: getRandomValue(1, 100),
        logout: 1,
      };
    }

    return getFramedUrl(`${process.env.PAT_URL}/#/misc/pat-pages/login`, parameters);
  }, [isIframeLoginUrl, extId, password]);

  const onLoad = useCallback(() => {
    if (!isIframeLoginUrl) {
      setIframeKey(LOGIN_CALENDER_IFRAME_KEY);
      setIframeLoginUrl(true);
    }
  }, [isIframeLoginUrl]);

  return (
    <Iframe
      key={iframeKey}
      linkToCalendar={linkToCalendar}
      onLoad={onLoad}
    />
  );
};

export default Calendar;
