import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import useBindDispatch from 'src/hooks/useBindDispatch';
import { formatToUSDateOnly, formatToUSShortMonth, formatToUSTime } from 'src/utils/dateHandler';
import { PNNotificationStatus } from 'constants/enums';
import StyledCheckbox from 'components/PsychicNotifications/CustomStyled/StyledCheckbox';
import { selectPsychicNoteConfigurableData, selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import { CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST } from 'constants/constants';
import { truncateString } from 'src/utils/commonUtil';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import StyledTooltip from 'components/Tooltips/StyledTooltip/StyledTooltip';
import LabelTags from 'components/Tags/LabelTags';
import { ICustomerList } from 'types/componentTypes';

import styles from './ClientListTable.module.scss';

const ClientListForMobile: React.FC<ICustomerList> = ({
  customer,
  selected,
  onSelectRow,
  selectedLength,
  labelTag,
  index,
  handelOnClickedLabel,
}) => {
  const {
    displayname,
    customerId,
    customerType,
    readingType,
    zodiacSign,
    lastReadingDate,
    notificationStatus,
  } = customer;
  const isCustomerMuted = notificationStatus === PNNotificationStatus.MUTED;
  const readingDate = formatToUSDateOnly(lastReadingDate);
  const readingLongDate = `${formatToUSShortMonth(lastReadingDate)} | ${formatToUSTime(lastReadingDate)}`;
  const zodiacImage = zodiacMap.get(zodiacSign.toLocaleLowerCase());
  const { psychicMaxNotificationsSend } = useSelector(selectPsychicNoteConfigurableData);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail);

  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const isEditButton = !selectedLength.length;

  const handleOnSelect = useCallback(() => {
    const customerDetails = sendCustomerDetail || [];

    if (selected) {
      onSelectRow(customerId);
      const index = customerDetails.indexOf(customer);
      customerDetails.splice(index, 1);
      setNotificationCustomerDetail(customerDetails);
    } else if (selectedLength.length < psychicMaxNotificationsSend) {
      setNotificationCustomerDetail([customer, ...customerDetails]);
      onSelectRow(customerId, psychicMaxNotificationsSend);
    }
  }, [selected, selectedLength]);

  const tableRowClasses = cn(styles.tableRow,
    styles.tableRow, {
      [styles.mutedRow]: isCustomerMuted,
      [styles.selectedRow]: selected,
      [styles.firstRow]: index === 0,
    });

  return (
    <tr className={tableRowClasses}>
      <td className={styles.fixedColumn}>
        <StyledCheckbox
          checked={selected}
          disabled={isCustomerMuted}
          onClick={handleOnSelect}
          style={isCustomerMuted ? {
            transform: 'scale(1.1)',
            color: '#66c8d287',
          } : {
            transform: 'scale(1.1)',
          }}
        />
        {isCustomerMuted && <span className={styles.mutedTag}>Muted</span>}
      </td>
      <td className={cn(styles.fixedColumn, styles.fixedName)}>
        <span className={styles.customerName}>
          {truncateString(displayname, CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST)}<br />
          <ClientTypeLable clientType={customerType} />
        </span>
      </td>
      <td
        className={cn(styles.fixedLine, index === 0 ? styles.firstRow
          : styles.line)}
        aria-label="line"
      />
      <td className={styles.scrollableColumnWrapper}>
        <StyledTooltip title={readingLongDate}>
          <span>{readingDate}</span>
        </StyledTooltip>
        <br /> {readingType}
      </td>
      <td className={styles.scrollableColumnWrapper}>
        <StyledTooltip title={zodiacSign}>
          <img src={zodiacImage} alt={zodiacSign} className={styles.zodiacImage} />
        </StyledTooltip>
        <br /><span className={styles.zodiacSignName}>{zodiacSign}</span>
      </td>
      <td className={styles.scrollableColumnWrapper} aria-label="labels">
        <LabelTags
          isEditButton={isEditButton}
          handelOnClickedLabel={() => handelOnClickedLabel()}
          labels={labelTag}
        />
      </td>
    </tr>
  );
};

export default ClientListForMobile;
