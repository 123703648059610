/* eslint-disable @typescript-eslint/indent */
import { useSelector } from 'react-redux';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { Box } from '@mui/material';
import ContentEditable from 'react-contenteditable';

import {
  NOTIFICATION_DRAFT_KEY,
  MESSAGE_TEMPLATES,
  CUSTOMER_DETAILS_DRAFT_KEY,
  MINIMUM_MESSAGE_NOTIFICATION_LENGTH,
  PHONE_REGEX,
} from 'src/constants/constants';
import { FlaggedPopupText, PNNotificationStatus } from 'constants/enums';
import { ICreatePsychicNotificationForm } from 'src/types/componentTypes';
import MessageTemplate from 'components/Forms/CreatePsychicNotificationForm/MessageTemplate';
import CustomTag from 'components/Tags/CustomTag/CustomTag';
import CustomSelect from 'components/Selects/CustomSelect/CustomSelect';
import {
  useTable,
  applySortFilter,
  checkMutedAvailable,
  getComparator,
} from 'src/hooks/useTable';
import {
  setCookie,
  deleteCookie,
  // addPsychicMixpanelEventButtonTapped,
} from 'src/utils/commonUtil';
import SendNotificationButton from 'components/Buttons/SendNotificationButton';
import DeleteDraftButton from 'components/Buttons/DeleteDraftButton';
import BackButton from 'components/Buttons/SaveDraftButton';
import FlaggedNotificationPopup from 'components/Popups/FlaggedNotificationPopup';
import TextBoxWithChips from 'components/Forms/CreatePsychicNotificationForm/TextBoxWithChips';
import styles from 'components/Forms/CreatePsychicNotificationForm/CreatePsychicNotificationForm.module.scss';
// import { selectCurrentUser } from 'selectors/selectors';
// import { getPsychicNotificationInfoMixpanel } from 'src/utils/helpers';
import { selectPsychicNoteConfigurableData, selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { checkPhoneOrEmailInMessageWithoutHotlineNumber } from 'src/utils/messageHandler';
import { useIsMobile } from 'src/hooks/useIsMobile';

const CreatePsychicNotificationForm: React.FC<ICreatePsychicNotificationForm> = ({
  messageBody,
  customerList,
  flagPopupInfo,
  setMessageBody,
  draftMsg,
  handleSendButton,
  handleSaveDraftButton,
  handleDeleteDraftButton,
  handleChangeNotificationText,
}) => {
  const [isSelectVisible, setSelectVisible] = useState<boolean>(false);
  const [filterCustomer, setFilterCustomer] = useState<string>('');
  const [isLimitReach, setIsLimitReach] = useState<boolean>(false);
  const inputRef = useRef(null);
  const { isMobile } = useIsMobile();

  const targetWord = checkPhoneOrEmailInMessageWithoutHotlineNumber(messageBody);

  // const currentUser = useSelector(selectCurrentUser);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail) || [];
  const { psychicMaxNotificationsSend } = useSelector(selectPsychicNoteConfigurableData);

  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  const { order } = useTable();
  const isCustomerLimitReach = sendCustomerDetail?.length >= psychicMaxNotificationsSend;

  useEffect(() => {
    const stringifyMessage = messageBody;

    if (sendCustomerDetail) {
      setCookie(NOTIFICATION_DRAFT_KEY, stringifyMessage);
    } else if (messageBody?.length >= MINIMUM_MESSAGE_NOTIFICATION_LENGTH) {
      setCookie(NOTIFICATION_DRAFT_KEY, stringifyMessage);
    } else if (messageBody?.length < draftMsg?.length
      || messageBody?.length < MINIMUM_MESSAGE_NOTIFICATION_LENGTH) {
      deleteCookie(NOTIFICATION_DRAFT_KEY);
    }

    if (isLimitReach && !sendCustomerDetail) {
      setIsLimitReach(false);
    }

    setMessageBody(messageBody.substring(0, 300));
  }, [messageBody, sendCustomerDetail]);

  useEffect(() => {
    if (sendCustomerDetail?.length > psychicMaxNotificationsSend) {
      setIsLimitReach(true);
    }
  }, [psychicMaxNotificationsSend, sendCustomerDetail]);

  const options = applySortFilter({
    tableData: customerList,
    comparator: getComparator(order, 'lastReadingDate'),
    filterCustomerName: filterCustomer.trim(),
    showMuted: checkMutedAvailable({
      tableData: customerList,
      filterCustomerName: filterCustomer.trim(),
    }),
  });

  const handleTemplateSelection = (templateContent) => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: mixPanelText,
    //   button_type: 'response template',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
    setMessageBody(`${messageBody} ${templateContent}`.trim());
  };

  const onDeleteHandler = (index) => {
    const customerDetails = sendCustomerDetail || [];
    customerDetails.splice(index, 1);
    setNotificationCustomerDetail(customerDetails);

    if (isLimitReach) {
      setIsLimitReach(false);
    }
  };

  const handleOptionSelected = (customer) => {
    if (customer.notificationStatus === PNNotificationStatus.ACTIVE) {
      const isSelected = sendCustomerDetail?.includes(customer);
      const customerDetails = sendCustomerDetail || [];

      if (isSelected) {
        const index = customerDetails.indexOf(customer);
        onDeleteHandler(index);
      } else {
        customerDetails.push(customer);
        setNotificationCustomerDetail(customerDetails);
        setSelectVisible(false);
      }

      if (isCustomerLimitReach && !isSelected) {
        setIsLimitReach(true);
      }

      setCookie(CUSTOMER_DETAILS_DRAFT_KEY, JSON.stringify(sendCustomerDetail));
      setFilterCustomer('');
    }
  };

  const filterMessage = useMemo(() => (message) => {
    if (!targetWord?.[0]) {
      return message;
    }

    const regexPhone = new RegExp(`(${PHONE_REGEX.source})`, 'gi');

    return message.replace(regexPhone, (match) => (targetWord.includes(match)
      ? `<span style="background-color: #fbe586">${match}</span>`
      : match
    ));
  }, [targetWord]);

  const selectedCount = useMemo(() => !isSelectVisible && sendCustomerDetail
  && (sendCustomerDetail?.length > 3)
  && sendCustomerDetail?.length - 3,
  [isSelectVisible, sendCustomerDetail.length]);

  const maxIterations = selectedCount ? 3 : sendCustomerDetail.length;

  const handleOnFocus = () => {
    inputRef?.current?.focus();
  };

  const onTextEntered = () => {
    handleOnFocus();
    setSelectVisible(!isSelectVisible);
  };

  const handleOnHide = () => {
    if (isSelectVisible && isLimitReach) {
      setSelectVisible(false);
    }
  };

  const trimSpaces = (string) => string
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');

  const changeHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      return false;
    }

    if (e && e.target && typeof e.target.value === 'string') {
      const newValue = e.target.value;
      const replacedValue = newValue.replace('<br>', '');
      setFilterCustomer(trimSpaces(replacedValue));
    }
  };

  const checkIsClientMaxLimitReachedAndSendNote = () => {
    if (!isLimitReach) {
      handleSendButton();
    }
  };

  return (
    <div
      className={styles.wrapper}
      onClick={handleOnHide}
      role="presentation"
    >
      <BackButton
        title="All Notes"
        onClick={handleSaveDraftButton}
      />
      <Box className={styles.box}>
        <div className={styles.boxHeader}>
          <div className={styles.searchBarWrapper}>
            <span>To:</span>
            <div>
              <div
                role="presentation"
                className={cn(styles.searchBar, {
                  [styles.searchBarActive]: isSelectVisible,
                })}
                onClick={handleOnFocus}
              >
                <div className={styles.customTagWrapper}>{
                  sendCustomerDetail
               && sendCustomerDetail.slice(0, maxIterations).map((tagDetails, index) => (
                 <CustomTag
                   customClass={styles.customTag}
                   isTagged={!!tagDetails}
                   index={index}
                   tagDetails={tagDetails}
                   onDelete={onDeleteHandler}
                 />
               ))
                }
                  <TextBoxWithChips
                    selectedCount={selectedCount}
                    inputRef={inputRef}
                    filterCustomer={filterCustomer}
                    changeHandler={changeHandler}
                    onTextEntered={onTextEntered}
                    isLimitReach={isLimitReach}
                    isMobile={!isMobile}
                  />
                </div>
                <TextBoxWithChips
                  selectedCount={selectedCount}
                  inputRef={inputRef}
                  filterCustomer={filterCustomer}
                  changeHandler={changeHandler}
                  onTextEntered={onTextEntered}
                  isLimitReach={isLimitReach}
                  isMobile={isMobile}
                />
              </div>
              <FlaggedNotificationPopup
                className={styles.limitFlag}
                isVisible={isLimitReach}
                popupInfo={FlaggedPopupText.CUSTOMER_LIMIT_REACHED}
              />
            </div>
            <CustomSelect
              isSelectVisible={isSelectVisible && !isLimitReach}
              options={options}
              filterCustomer={filterCustomer}
              onSelect={handleOptionSelected}
            />
          </div>
        </div>
        <div className={styles.messageBodyContainer}>
          <FlaggedNotificationPopup
            className={styles.flaggedPopop}
            isVisible={!!flagPopupInfo}
            popupInfo={flagPopupInfo}
          />
          <span className={styles.boxText}>
            {`${300 - messageBody?.length || 0}/300`}
          </span>
          {!messageBody
          && (
            <div className={styles.placeholder}>
              <span>Start your message here…</span>
            </div>
          )}
          <ContentEditable
            className={styles.messageBody}
            html={filterMessage(messageBody)}
            onChange={handleChangeNotificationText}
          />
        </div>
        <div className={styles.messageTemplate}>
          {MESSAGE_TEMPLATES.map((template) => (
            <MessageTemplate
              key={template.keyId}
              template={template.value}
              handleTemplateSelection={handleTemplateSelection}
              mixPanelText={template.mixPanelText}
            />
          ))}
        </div>
        <SendNotificationButton
          onClick={checkIsClientMaxLimitReachedAndSendNote}
          isValidMessage={flagPopupInfo === FlaggedPopupText.PHONE_AND_EMAIL_VALIDATION}
        />
      </Box>
      <DeleteDraftButton onClick={handleDeleteDraftButton} />
    </div>
  );
};

export default CreatePsychicNotificationForm;
