import React from 'react';

import { IframeInterface } from 'src/types/componentTypes';

import styles from './Iframe.scss';

const Iframe: React.FC<IframeInterface> = ({
  onLoad,
  linkToCalendar,
}) => (
  <div className={styles.iframeWrapper}>
    <iframe
      onLoad={onLoad}
      className={styles.iframe}
      title="Manage Schedule"
      id="iframeCalender"
      src={linkToCalendar}
      height="100%"
      width="100%"
    >
      No Frame
    </iframe>
  </div>
);

export default Iframe;
