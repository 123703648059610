import React from 'react';
import SwitchToPhone from 'extracted-chat-components/src/components/SwitchToPhone/SwitchToPhone';

import { MakeCallProps } from 'types/componentTypes';
import { ButtonColor, ButtonSize } from 'constants/enums';

const MakeCall: React.FC<MakeCallProps> = ({
  isChatEnd,
  isPhoneValid,
  isPhoneSaved,
  phoneNumbers,
  isPhoneNumber,
  addPhoneNumber,
  appointmentInfo,
  isNotificationPopUp,
  popUpNotificationData,
  setIsPhoneNumber,
  psychicName,
  handleCancelCallButton,
  handleCallButton,
  countryCodes,
  validatePhoneNumber,
  addMixpanelEventAlertTapped,
}) => {
  const { description } = popUpNotificationData;
  const { untilStart, untilEnd } = appointmentInfo;

  const isCallbackQueue = untilStart && untilStart <= 5;
  const isCancelButton = isCallbackQueue || isChatEnd;

  const acceptButton = {
    text: isCallbackQueue ? 'Enter Callback Queue' : 'Start Call',
    size: isCallbackQueue ? ButtonSize.M : ButtonSize.SM,
    color: ButtonColor.WARNING,
    handler: handleCallButton,
  };

  const rejectButton = {
    text: isCancelButton ? 'Cancel' : 'Stay in Chat',
    color: isCancelButton ? ButtonColor.ONLY_TEXT : ButtonColor.GREEN_OUTLINE,
    size: ButtonSize.SM,
    handler: handleCancelCallButton,
  };

  const addPhoneNumberWrapper = (payload) => addPhoneNumber({
    payload, addMixpanelEventAlertTapped,
  });

  return (
    <>
      <SwitchToPhone
        isPhoneSaved={isPhoneSaved}
        isPhoneValid={isPhoneValid}
        phoneNumbers={phoneNumbers}
        isPhoneNumber={isPhoneValid ? isPhoneNumber : true}
        untilStart={untilStart}
        untilEnd={untilEnd}
        addPhoneNumber={addPhoneNumberWrapper}
        setIsPhoneNumber={setIsPhoneNumber}
        validatePhoneNumber={validatePhoneNumber}
        countryCodes={countryCodes}
        isOpen={isNotificationPopUp}
        psychicName={psychicName}
        description={description}
        acceptButton={acceptButton}
        rejectButton={rejectButton}
        isBigWidth={false}
        isChatEnd={isChatEnd}
      />
    </>
  );
};

export default MakeCall;
