import { DeclineSample, DeclineSampleLabels } from 'constants/enums';

export const DECLINE_OM_OPTIONS = [
  {
    value: DeclineSample.NEED_MORE_INFO_VALUE,
    label: DeclineSampleLabels.NEED_MORE_INFO_LABEL,
  },
  {
    value: DeclineSample.NO_REPLY_NEEDED_VALUE,
    label: DeclineSampleLabels.NO_REPLY_NEEDED_LABEL,
  },
  {
    value: DeclineSample.OM_ABOUT_RESTRICTED_VALUE,
    label: DeclineSampleLabels.OM_ABOUT_RESTRICTED_LABEL,
  },
  {
    value: DeclineSample.COULD_NOT_PSYCHICALLY_CONNECT_VALUE,
    label: DeclineSampleLabels.COULD_NOT_PSYCHICALLY_CONNECT_LABEL,
  },
];
