import React, { useMemo } from 'react';
import cn from 'classnames';
import { MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

import { ICustomTablePagination } from 'types/componentTypes';
import { ReactComponent as DropDownIcon } from 'src/assets/svg/drop-down-Icon.svg';
import { addPsychicMixpanelEventButtonTapped } from 'src/utils/commonUtil';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';
import { selectCurrentUser } from 'selectors/selectors';
import styles from 'components/Table/TablePagination/CustomTablePagination.scss';

const CustomTablePagination: React.FC<ICustomTablePagination> = ({
  page,
  count,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
}) => {
  const endRowNumber = useMemo(() => {
    const rowNumber = page * rowsPerPage + rowsPerPage;

    return rowNumber > count ? count : rowNumber;
  }, [count, page, rowsPerPage]);

  const isNextButtonDisable = useMemo(() => {
    if ((count / rowsPerPage) < (page + 1)) {
      return true;
    }

    if (count % rowsPerPage === 0 && (count / rowsPerPage) < (page + 2)) {
      return true;
    }

    return false;
  }, [count, rowsPerPage, page]);

  const nextButtonClass = cn(styles.nextButton, { [styles.disableButton]: isNextButtonDisable });
  const prevButtonClass = cn(styles.prevButton, { [styles.disableButton]: page === 0 });
  const currentUser = useSelector(selectCurrentUser);

  const nextButtonHandler = () => {
    onChangePage(page + 1);
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: 'next',
    //   button_type: 'button',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicNotificationInfoMixpanel(currentUser),
    // });
  };

  const prevButtonHandler = () => {
    onChangePage(page - 1);
    addPsychicMixpanelEventButtonTapped({
      button_text: 'previous',
      button_type: 'button',
      screen_title: 'psychic notifications',
      platform: 'psychic desktop app',
      ...getPsychicInfoMixpanel(currentUser),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropDownWrapper}>
        <span>Rows per page: </span>
        <Select
          defaultValue={rowsPerPage}
          IconComponent={DropDownIcon}
          value={rowsPerPage}
          className={styles.dropDownBorder}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          classes={{
            selectMenu: styles.dropDown,
            icon: styles.dropDownIcon,
          }}
          MenuProps={{
            classes: {
              paper: styles.dropDownMenu,
              list: styles.dropDownList,
            },
          }}
          onChange={onChangeRowsPerPage}
        >
          {
            rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))
          }
        </Select>
      </div>
      <div className={styles.rowsCount}>
        {`Showing ${endRowNumber} of ${count}`}
      </div>
      <div className={styles.buttonWrapper}>
        <button
          type="button"
          onClick={prevButtonHandler}
          className={prevButtonClass}
          disabled={!page}
        >
          Previous
        </button>
        <button
          type="button"
          onClick={nextButtonHandler}
          className={nextButtonClass}
          disabled={isNextButtonDisable}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomTablePagination;
