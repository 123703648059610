import { put, call, select } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import { ChannelEvents } from 'constants/enums';
import { setIsLiveChatActive } from 'actions/appActions';
import * as chatActions from 'actions/chatActions';
import { validateTwilioToken } from 'src/utils/commonUtil';
import { LocalStorage } from 'src/utils/storageHandler';
import { TOKEN_TWILIO_STORAGE } from 'constants/constants';
import { selectTwilioClient } from 'selectors/selectors';
import { updateToken } from 'src/utils/twilio';

export function* handleEndChatForCustomer() {
  yield put(chatActions.removePrevChatData());
  yield put(chatActions.setTypingStartedMessage(''));
  yield put(setIsLiveChatActive(false));
  yield put(chatActions.deleteNotifications());
  yield put(chatActions.removeChatId());
  yield put(chatActions.setChatMinutesActive(0));
  yield put(chatActions.setAppointment({ appointmentTimestamp: null, customerName: null }));
}

export async function unsubscribeFromChannelEvents(channel) {
  if (channel) {
    await channel.removeAllListeners();
  }
}

export const eventListener = async (channel) => eventChannel((emit) => {
  const handler = (data) => {
    emit(data);
  };

  if (channel) {
    channel.on(ChannelEvents.MESSAGE_ADDED, (payload) => handler({
      type: ChannelEvents.MESSAGE_ADDED, payload,
    }));
    channel.on(ChannelEvents.MESSAGE_UPDATED, (payload) => handler({
      type: ChannelEvents.MESSAGE_UPDATED, payload,
    }));
    channel.on(ChannelEvents.MEMBER_LEFT, (payload) => handler({
      type: ChannelEvents.MEMBER_LEFT, payload,
    }));
    channel.on(ChannelEvents.TYPING_STARTED, (payload) => handler({
      type: ChannelEvents.TYPING_STARTED, payload,
    }));
    channel.on(ChannelEvents.TYPING_ENDED, (payload) => handler({
      type: ChannelEvents.TYPING_ENDED, payload,
    }));
    channel.on(ChannelEvents.DISCONNECT, () => {
      emit(END);
    });
  }

  return () => { };
});

export function* checkTwilioTokenLifetime() {
  const twilioToken = LocalStorage.getItem(TOKEN_TWILIO_STORAGE);

  if (twilioToken) {
    const isValid = yield call(validateTwilioToken, twilioToken);

    if (!isValid) {
      const client = yield select(selectTwilioClient);

      yield call(updateToken, client);
    }
  }
}
