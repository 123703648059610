import React from 'react';

import ClientHeaderContainer from 'components/Header/ClientHeader/ClientHeaderContainer';
import PopUpNotificationContainer from 'components/Popups/PopUpNotification/PopUpNotificationContainer';
import LoaderContainer from 'components/Loader';
import Plain from 'components/Plain';
import ToastBanner from 'components/ToastBanners';

import styles from './ClientView.module.scss';

const ClientView = ({
  sideUser,
  isVisibleChatComponents,
  requestType,
}) => (
  <div className={styles.container}>
    <LoaderContainer isPsychic={sideUser} withBackground />
    <PopUpNotificationContainer />
    <ToastBanner />
    {(sideUser && isVisibleChatComponents) && (
      <>
        <ClientHeaderContainer />
        <Plain requestType={requestType} />
      </>
    )}
  </div>
);

export default ClientView;
