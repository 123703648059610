import { useEffect } from 'react';
import { PsychicStatusChannel, Status, View } from 'extracted-chat-components/enums';
import { useSelector } from 'react-redux';

import { FirebaseEvents, CollectionNames } from 'constants/enums';
import FirestoreSetUp from 'src/firestore';
import {
  updateIsOnCall as _updateIsOnCall,
  updateIsOnChat as _updateIsOnChat,
} from 'actions/statusToggleActions';
import {
  updateCallbackBanner as _updateCallbackBanner,
} from 'actions/chatActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { selectIsTogglesInit } from 'selectors/statusToggleSelectors';
import { selectAuthToken, selectView } from 'selectors/selectors';

const updatePsychicStatuses = ({
  updatePsychicStatus,
  isMessageEnabled,
  isChatEnabled,
  onBreakDuration,
  onBreakRequestTimeUTC,
  onBreakRequestPending,
  chatStatus,
  phoneStatus,
  messageStatus,
  updateIsOnCall,
  updateIsOnChat,
  isTogglesInit,
}) => {
  let breakData = {};

  if (onBreakDuration) {
    const breakStarted = onBreakRequestTimeUTC || 0;
    const breakEndsTimestamp = breakStarted + onBreakDuration * 60000;
    breakData = {
      breakEndsTimestamp,
      command: onBreakDuration,
      onBreakRequestPending,
    };
  }

  updatePsychicStatus(true, PsychicStatusChannel.BREAK, breakData);
  updatePsychicStatus(
    isMessageEnabled,
    PsychicStatusChannel.OFFLINE_MESSAGES_AVAILABILITY,
  );

  updatePsychicStatus(isChatEnabled, PsychicStatusChannel.CHAT_AVAILABILITY);

  const chatAndPhone = chatStatus !== Status.OFFLINE
    && phoneStatus !== Status.OFFLINE;
  const chatEnabled = chatStatus !== Status.OFFLINE;
  const phoneEnabled = phoneStatus !== Status.OFFLINE;
  const messagesEnabled = messageStatus === Status.ENABLED;
  const isOnCall = phoneStatus === Status.BUSY && chatStatus !== Status.BUSY;
  const isOnChat = chatStatus === Status.BUSY;

  updatePsychicStatus(messagesEnabled, PsychicStatusChannel.OFFLINE_MESSAGES);
  updateIsOnCall(isOnCall);
  updateIsOnChat(isOnChat);

  // don't update because it's not possible to detect phone status, as it is always busy
  // statuses updated after the endpoint call
  if (!isOnCall || !isTogglesInit) {
    updatePsychicStatus(chatAndPhone, PsychicStatusChannel.PHONE_CHAT);
    updatePsychicStatus(chatEnabled, PsychicStatusChannel.CHAT);
    updatePsychicStatus(phoneEnabled, PsychicStatusChannel.PHONE);
  }
};

export const usePsychicStatuses = (
  setPsychicChatStatus: (chatStatus: string) => void,
  setPsychicPhoneStatus: (phoneStatus: string) => void,
  psychicId: string,
  updatePsychicStatus?: (status: boolean, channel: number, breakData?: object) => void,
) => {
  const updateIsOnCall = useBindDispatch(_updateIsOnCall);
  const updateIsOnChat = useBindDispatch(_updateIsOnChat);
  const updateCallbackBanner = useBindDispatch(_updateCallbackBanner);
  const isTogglesInit = useSelector(selectIsTogglesInit);
  const view = useSelector(selectView);
  const tokenAuth = useSelector(selectAuthToken);

  useEffect(() => {
    if (!psychicId) {
      return;
    }

    FirestoreSetUp()
      .then((Firestore) => {
        Firestore.psychicsDb.collection(CollectionNames.ADVISORS)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              const { doc } = change;
              const psychic = {
                id: doc.id,
                data: doc.data(),
              };

              if (psychic.id === `cp-${psychicId}` && psychic.data) {
                console.debug('Firestore event', JSON.stringify(psychic.data, null, 2));
                const {
                  chatStatus,
                  phoneStatus,
                } = psychic.data;

                const statusUpdated = change.type === FirebaseEvents.ADDED
                  || change.type === FirebaseEvents.MODIFIED;

                if (statusUpdated) {
                  setPsychicChatStatus(chatStatus);
                  setPsychicPhoneStatus(phoneStatus);

                  if (View.CUSTOMER === view && psychicId && tokenAuth) {
                    updateCallbackBanner();
                  }

                  if (updatePsychicStatus) {
                    updatePsychicStatuses({
                      ...psychic.data,
                      updatePsychicStatus,
                      updateIsOnCall,
                      updateIsOnChat,
                      isTogglesInit,
                    });
                  }
                }
              }
            });
          });
      });
  }, [psychicId]);
};
