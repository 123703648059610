import React from 'react';
import cn from 'classnames';

import SvgIcon from 'components/SvgIcon';
import StatusBadge from 'components/Badges/StatusBadge';
import Badges from 'components/Badges';
import { CHAT_TABS_ID } from 'constants/constants';

import styles from './TabButton.module.scss';

const TabButton = ({
  disabled,
  onChangeTab,
  isActiveTab,
  tabId,
  title,
  iconPrefix,
  classNames = '',
  withBadges = true,
}) => (
  <button
    type="button"
    id={tabId}
    disabled={disabled}
    className={cn(styles.tabButton,
      {
        [styles.tabButtonActive]: isActiveTab,
        [classNames]: classNames,
        [styles.psychichNotificationButtonHide]: tabId === CHAT_TABS_ID.PSYCHIC_NOTIFICATION,
      })}
    onClick={onChangeTab}
  >
    <SvgIcon
      id={iconPrefix}
    />
    <span className={styles.tabButtonTitle}>{title}</span>
    {withBadges && (
      <div className={styles.tabButtonBadges}>
        {tabId === CHAT_TABS_ID.MY_STATUS ? <StatusBadge /> : (
          <Badges
            tabId={tabId}
            className={cn({ [styles.warnBadge]: tabId === CHAT_TABS_ID.PSYCHIC_NOTIFICATION })}
          />
        )}
      </div>
    )}
  </button>
);

export default TabButton;
