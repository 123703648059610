import React from 'react';
import { MixpanelEvents } from 'extracted-chat-components/enums';

import Envelope from 'src/assets/png/Envelope.png';
import HeaderButton from 'src/components/ClientHubWeb/HeaderButton/buttons';
import MixpanelActions from 'src/utils/mixpanel';

import styles from './EmptyNoteScreen.module.scss';

const EmptyNoteScreen = ({ customerMessageFAQUrl }) => {
  const handleLearnAboutButton = () => {
    MixpanelActions.track(MixpanelEvents.CTA_TAPPED, {
      cta_location: 'inbox',
      cta_position: 'psychic notes empty state',
      cta_content: 'learn about psychic notes',
      cta_type: 'button',
    });
  };

  return (
    <div className={styles.gradientBackground}>
      <div className={styles.gradientInnerDiv}>
        <img
          src={Envelope}
          alt="Envelope"
        />
        <p className={styles.p1}>When you have new notes, you’ll see them here.</p>
        <p className={styles.p2}>
          If you’d like to send a note to a psychic
          you’ve recently read with, tap the ‘Send a Note’ button above.
        </p>
        <div className={styles.separator} />
        <a
          href={customerMessageFAQUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnAnchorTag}
          onClick={handleLearnAboutButton}
          aria-label="back"
        >
          <HeaderButton
            text="Learn About Psychic Notes"
            classesName={styles.btnLast}
          />
        </a>
      </div>
    </div>
  );
};

export default EmptyNoteScreen;
