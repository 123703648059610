import {
  put,
  select,
  delay,
} from 'redux-saga/effects';

import {
  View,
  PopUpNotificationType,
} from 'constants/enums';
import {
  UPCOMING_APPOINTMENT_REMINDER_TIME,
} from 'constants/constants';
import {
  setPopUpNotificationData,
  showPopUpNotification,
} from 'actions/appActions';
import { setAppointment } from 'actions/chatActions';
import {
  selectView,
  selectSideUser,
  selectIsLiveChatActive,
  selectInitChatModalState,
} from 'selectors/selectors';

export function* handleAppointment({ payload }: ReturnType<typeof setAppointment>) {
  try {
    const view = yield select(selectView);
    const { appointmentTimestamp } = payload;

    if (view === View.PSYCHIC || !appointmentTimestamp) return;

    const now = new Date(Date.now());
    // @ts-ignore
    const timeToDefer = new Date(+appointmentTimestamp) - now;
    const beforeFiveMinutesDelay = timeToDefer - UPCOMING_APPOINTMENT_REMINDER_TIME;

    if (beforeFiveMinutesDelay > 0) yield delay(beforeFiveMinutesDelay);

    const isLiveChatActive = yield select(selectIsLiveChatActive);
    const isInitChatModal = yield select(selectInitChatModalState);

    if (isLiveChatActive && !isInitChatModal) {
      const { friendlyName } = yield select(selectSideUser);
      const title = `Your Chat will end automatically in 3 minutes because ${friendlyName}
      has an upcoming appointment.\n\nWe apologize for any inconvenience`;

      yield put(showPopUpNotification(true));
      yield put(setPopUpNotificationData({
        title,
        isTextNewLine: true,
        notificationType: PopUpNotificationType.BEFORE_APPOINTMENT,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}
