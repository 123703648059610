import {
  PNConfigurableData,
  PNCustomerListArray, PNNotesHistory, PNSendNotification,
} from 'src/types/objectTypes';
import {
  GET_READING_CUSTOMERS_LIST,
  SET_READING_CUSTOMERS_LIST,
  UPDATE_REACTION_READ_STATUS,
  SEND_NOTIFICATION_TO_CUSTOMER,
  SEND_BLOCKED_CONTENT_TO_CS,
  GET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  SET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  SET_NOTIFICATION_CUSTOMER_DETAIL,
  SET_PSYCHIC_NOTIFICATIONS_ACTIVE,
  CLEAR_PSYCHIC_NOTIFICATION,
  GET_CLIENT_NOTE_HISTORY,
  SET_NOTES_HISTORY,
  SET_ACTIVE_SCREEN,
  SET_PSYCHIC_NOTE_CONFIGURABLE_DATA,
  SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC,
  UPDATE_CLIENT_NOTES,
  SET_PSYCHIC_NOTES_STATS,
  UPDATE_CUSTOMER_NOTIFICATION,
  SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER,
  UPDATE_NOTIFICATION_STATUS,
  SET_TODAY_CUSTOMER_NOTE_COUNT,
  SET_ALL_PSYCHIC_NOTIFICATION_MUTE,
  UNMUTE_ALL_PSYCHIC_NOTIFICATION,
  SET_CURRENT_PCN_TAB,
  SET_PRIORITY_ACTION_LIST,
  SET_PRIORITY_ACTION_COUNT,
  GET_PRIORITY_ACTION_LIST,
  GET_PRIORITY_ACTION_COUNT,
  SET_PRIORITY_ACTION_MESSAGES,
  SET_CLIENT_NOTES_DRAFT_TOAST,
  UPDATE_CLIENT_LABELS,
  SET_CLIENT_LABELS_LIST,
} from 'src/redux/actions/actionsTypes';

export const getPsychicAllNotificationsList = (
  showLoader: boolean = true,
) => ({
  type: GET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  payload: { showLoader },
});

export const getReadingCustomersList = (
  showLoader: boolean = true,
) => ({
  type: GET_READING_CUSTOMERS_LIST,
  payload: { showLoader },
});

export const setPsychicNotificationsActive = (
  payload: boolean,
) => ({
  type: SET_PSYCHIC_NOTIFICATIONS_ACTIVE,
  payload,
});

export const setPsychicNotesStats = (
  payload: any,
) => ({
  type: SET_PSYCHIC_NOTES_STATS,
  payload,
});

export const setPsychicAllNotificationsList = (
  payload: any,
) => ({
  type: SET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  payload,
});

export const setReadingCustomersList = (
  payload: PNCustomerListArray,
) => ({
  type: SET_READING_CUSTOMERS_LIST,
  payload,
});

export const sendNotificationToCustomer = (
  noteDetails: PNSendNotification,
  pcComId: number = 0,
) => ({
  type: SEND_NOTIFICATION_TO_CUSTOMER,
  payload: { noteDetails, pcComId },
});

export const sendBlockedContentToCs = (
  messageBody: string,
  customerId: string | number,
) => ({
  type: SEND_BLOCKED_CONTENT_TO_CS,
  payload: {
    messageBody,
    customerId,
  },
});

export const setNotificationCustomerDetail = (
  payload: any,
) => ({
  type: SET_NOTIFICATION_CUSTOMER_DETAIL,
  payload,
});

export const updateNoteReadStatus = (pcComId: number) => ({
  type: UPDATE_REACTION_READ_STATUS,
  pcComId,
});

export const clearPsychicNotification = () => ({
  type: CLEAR_PSYCHIC_NOTIFICATION,
});

export const getClientNoteHistory = (pcComId: number, showLoader: boolean = true) => ({
  type: GET_CLIENT_NOTE_HISTORY,
  payload: { pcComId, showLoader },
});

export const setNotesHistory = (payload: PNNotesHistory) => ({
  type: SET_NOTES_HISTORY,
  payload,
});

export const setActiveScreen = (payload: string) => ({
  type: SET_ACTIVE_SCREEN,
  payload,
});

export const setPsychicNoteConfigurableData = (payload: PNConfigurableData) => ({
  type: SET_PSYCHIC_NOTE_CONFIGURABLE_DATA,
  payload,
});

export const setUpPsychicNotesByLinkForPsychic = (payload) => ({
  type: SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC,
  payload,
});

export const setUpPsychicNotesByLinkForCustomer = (payload) => ({
  type: SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER,
  payload,
});

export const updateClientNotes = (payload) => ({
  type: UPDATE_CLIENT_NOTES,
  payload,
});

export const updateCustomerNotification = (payload) => ({
  type: UPDATE_CUSTOMER_NOTIFICATION,
  payload,
});

export const updateNotificationStatus = (payload) => ({
  type: UPDATE_NOTIFICATION_STATUS,
  payload,
});

export const updateTodayCustomerNotesCount = (payload) => ({
  type: SET_TODAY_CUSTOMER_NOTE_COUNT,
  payload,
});

export const setAllPsychicNotificationMute = (isAllPsychicMute: boolean) => ({
  type: SET_ALL_PSYCHIC_NOTIFICATION_MUTE,
  payload: isAllPsychicMute,
});

export const unMuteAllPsychicNotification = (customerEncId: string, unMuteAllPsychic: boolean) => ({
  type: UNMUTE_ALL_PSYCHIC_NOTIFICATION,
  payload: { customerEncId, unMuteAllPsychic },
});

export const setCurrentPCNTab = (payload: string) => ({
  type: SET_CURRENT_PCN_TAB,
  payload,
});

export const setPriorityActionList = (payload) => ({
  type: SET_PRIORITY_ACTION_LIST,
  payload,
});

export const setPriorityActionCount = (payload) => ({
  type: SET_PRIORITY_ACTION_COUNT,
  payload,
});

export const getPriorityActionList = (
  showLoader: boolean = true,
) => ({
  type: GET_PRIORITY_ACTION_LIST,
  payload: { showLoader },
});

export const getPriorityActionCount = (
  showLoader: boolean = true,
) => ({
  type: GET_PRIORITY_ACTION_COUNT,
  payload: { showLoader },
});

export const setPriorityActionMessage = (payload) => ({
  type: SET_PRIORITY_ACTION_MESSAGES,
  payload,
});

export const setClientNotesDraftToast = (isToastVisible: boolean, draftedCutomerName: string = '') => ({
  type: SET_CLIENT_NOTES_DRAFT_TOAST,
  payload: {
    isToastVisible,
    draftedCutomerName,
  },
});

export const updateClientLabelsList = (clientLabels, customer, callback) => ({
  type: UPDATE_CLIENT_LABELS,
  payload: {
    clientLabels,
    customer,
    callback,
  },
});
export const setClientLabelsList = (payload) => ({
  type: SET_CLIENT_LABELS_LIST,
  payload,
});
