import React, { useState } from 'react';
import { ButtonSize } from 'extracted-chat-components/enums';
import { PsychicSchedulePopupNotification } from 'extracted-chat-components';

import { ButtonColor } from 'constants/enums';

const PsychicEarlyLogout = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
  logout,
}) => {
  const [
    isSomethingCameUpButtonSelected,
    setIsSomethingCameUpButtonSelected,
  ] = useState<boolean>(false);
  const [isPersonalButtonSelected, setIsPersonalButtonSelected] = useState<boolean>(false);
  const { title = 'Logout', description = null, callback } = popUpNotificationData || {};

  const handleLogout = () => {
    const payload = {
      isPsychicCurrentHourScheduled: true,
      psychicEarlyLogoutReason: isSomethingCameUpButtonSelected ? 14 : 15,
      status: callback.args[0],
      channel: callback.args[1],
    };
    showPopUpNotification(false);
    logout(payload);
    setIsPersonalButtonSelected(false);
    setIsSomethingCameUpButtonSelected(false);
  };

  const handleCancelLogout = () => {
    showPopUpNotification(false);
    setIsPersonalButtonSelected(false);
    setIsSomethingCameUpButtonSelected(false);
  };

  const somethingCameButton = {
    text: 'Something came up - be back shortly',
    color: ButtonColor.SYSTEM,
    size: ButtonSize.L,
    handler: setIsSomethingCameUpButtonSelected,
  };
  const personalButton = {
    text: 'Personal - I\'m done for the day',
    size: ButtonSize.L,
    color: ButtonColor.SYSTEM,
    handler: setIsPersonalButtonSelected,
  };
  const acceptButton = {
    text: 'Log Out',
    color: ButtonColor.ORANGE,
    size: ButtonSize.S,
    handler: handleLogout,
  };
  const rejectButton = {
    text: 'Cancel',
    size: ButtonSize.S,
    handler: handleCancelLogout,
  };

  return (
    <PsychicSchedulePopupNotification
      isOpen={isNotificationPopUp}
      toggleModal={rejectButton.handler}
      title={title}
      description={description}
      somethingCameButton={somethingCameButton}
      personalButton={personalButton}
      acceptButton={acceptButton}
      rejectButton={rejectButton}
      isPersonalButtonSelected={isPersonalButtonSelected}
      isSomethingCameUpButtonSelected={isSomethingCameUpButtonSelected}
    />
  );
};

export default PsychicEarlyLogout;
