import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InternetConnectionEvents } from 'extracted-chat-components/enums';

import {
  ChatInitMessages,
  MixpanelEvents,
  NotificationType,
  View,
} from 'constants/enums';
import {
  setHeaderBannerContent as _setHeaderBannerContent,
  setIsPhoneReading as _setIsPhoneReading,
  setIsVisibleHeaderBanner as _setIsVisibleHeaderBanner,
  setIsLiveChatActive as _setIsLiveChatActive,
  setShowInitModal as _setShowInitModal,
  setIsChatInInitState as _setIsChatInInitState,
  setHistoryModeState as _setHistoryModeState,
  setToastBannerData as _setToastBannerData,
} from 'actions/appActions';
import HeaderNotification from 'components/Notifications/HeaderNotification/HeaderNotification';
import { IAppState } from 'store';
import {
  deleteNotifications as _deleteNotifications,
  removeChatId as _removeChatId,
  setInitChatBanner as _setInitChatBanner,
  setInitChatNotification as _setInitChatNotification,
} from 'actions/chatActions';
import {
  DIFFERENCE_IN_TIME,
  HEADER_BANNER_DESCRIPTION_STORAGE,
  HEADER_BANNER_REASON_STORAGE,
  HEADER_BANNER_SUBTITLE_STORAGE,
  HEADER_BANNER_TITLE_STORAGE,
  HEADER_BANNER_TYPE_STORAGE,
  IS_SHOW_SWITCH_TO_PHONE,
  IS_SWITCH_TO_PHONE_BANNER,
  IS_VISIBLE_HEADER_BANNER_STORAGE,
  OFFLINE_MESSAGE_BANNER,
  SWITCH_TO_PHONE_BANNER,
  SWITCH_TO_PHONE_VISIBLE,
} from 'constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';
import MixpanelActions from 'src/utils/mixpanel';
import {
  selectAutoReloadPromptsLabels,
  selectCurrentChat,
  selectInitChatNotification,
  selectInternetConnectionStatus,
  selectIsHistoryMode,
  selectIsInitChatBanner,
  selectIsLiveChatActive,
  selectNotifications,
  selectView,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { setMixPanelEventsChatCompleted, setMixpanelPromptDeliveredEvent } from 'src/utils/setMixPanelEvents';
import { checkIsChatInInitState } from 'src/utils/helpers';
import { setChatInitNotification, simplyfyJsonArrayKeys } from 'src/utils/commonUtil';

const phoneNotification = {
  type: SWITCH_TO_PHONE_BANNER,
  title: '',
  description: {
    firstSentence: 'Sometimes you just need to hear a human voice.',
    secondSentence: 'Tap “Switch to Phone” to turn this Chat into a phone call.',
  },
};

const HeaderNotificationContainer = () => {
  const setIsPhoneReading = useBindDispatch(_setIsPhoneReading);
  const setIsVisibleHeaderBanner = useBindDispatch(_setIsVisibleHeaderBanner);
  const setHeaderBannerContent = useBindDispatch(_setHeaderBannerContent);
  const setIsLiveChatActive = useBindDispatch(_setIsLiveChatActive);
  const removeChatId = useBindDispatch(_removeChatId);
  const deleteNotifications = useBindDispatch(_deleteNotifications);
  const setInitChatBanner = useBindDispatch(_setInitChatBanner);
  const setInitChatNotification = useBindDispatch(_setInitChatNotification);
  const setIsChatInInitState = useBindDispatch(_setIsChatInInitState);
  const setShowInitModal = useBindDispatch(_setShowInitModal);
  const setHistoryModeState = useBindDispatch(_setHistoryModeState);
  const setToastBannerData = useBindDispatch(_setToastBannerData);

  const currentChat = useSelector(selectCurrentChat);
  const isPhoneReading = useSelector((state: IAppState) => state.app.isPhoneReading);
  const notifications = useSelector(selectNotifications);
  const isInitChatModal = useSelector((state: IAppState) => state.app.isInitChatModal);
  const isLiveChatActive = useSelector(selectIsLiveChatActive);
  const isVisible = useSelector((state: IAppState) => state.app.isVisibleHeaderBanner);
  const headerBannerContent = useSelector((state: IAppState) => state.app.headerBannerContent);
  const internetConnectingStatus = useSelector(selectInternetConnectionStatus);
  const view = useSelector(selectView) as View;
  const isHistoryMode: boolean = useSelector(selectIsHistoryMode);
  const isInitChatBanner = useSelector(selectIsInitChatBanner);
  const initChatNotification = useSelector(selectInitChatNotification);
  const autoReloadPromptsLabels = useSelector(selectAutoReloadPromptsLabels);

  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    const isVisible = LocalStorage.getItem('isVisibleHeaderBanner');

    if (view === View.CUSTOMER) {
      setIsVisibleHeaderBanner(!!isVisible);
    }
  }, []);

  useEffect(() => {
    if (isLiveChatActive && headerBannerContent?.type === OFFLINE_MESSAGE_BANNER) {
      setHeaderBannerContent(null);
      setIsVisibleHeaderBanner(false);
    }

    if (isLiveChatActive || !headerBannerContent) {
      return;
    }

    if (!isLiveChatActive) {
      LocalStorage.setItem(SWITCH_TO_PHONE_VISIBLE, '');
    }

    if (headerBannerContent?.type === SWITCH_TO_PHONE_BANNER) {
      setHeaderBannerContent(null);
      setIsVisibleHeaderBanner(false);
    }
  }, [isInitChatModal, isLiveChatActive, headerBannerContent]);

  useEffect(() => {
    if (isInitChatModal) {
      setInitChatBanner(true);

      if (initChatNotification === ChatInitMessages.RESERVING_PSYCHIC) {
        setIsChatInInitState(true);
      }
    }
  }, [isInitChatModal, initChatNotification, setInitChatBanner]);

  const setHeaderBannerDataToLocalStorage = (payload) => {
    const itemEntries = [
      { key: HEADER_BANNER_TITLE_STORAGE, value: payload?.title },
      { key: HEADER_BANNER_SUBTITLE_STORAGE, value: payload?.subtitle },
      { key: HEADER_BANNER_DESCRIPTION_STORAGE, value: payload?.description },
      { key: HEADER_BANNER_TYPE_STORAGE, value: payload?.type },
      { key: HEADER_BANNER_REASON_STORAGE, value: payload?.reason },
      { key: IS_VISIBLE_HEADER_BANNER_STORAGE, value: payload?.isVisible },
    ];
    LocalStorage.setItems(itemEntries);
  };

  useEffect(() => {
    const isCallHeaderBanner = headerBannerContent?.type === SWITCH_TO_PHONE_BANNER;
    const isShow = !!headerBannerContent && !!headerBannerContent?.description;

    if (!isShow && isVisible && !isCallHeaderBanner) {
      setIsVisibleHeaderBanner(false);
    }

    if (isCallHeaderBanner) {
      setHeaderBannerDataToLocalStorage({
        isVisible: isVisible || '',
        title: '',
        description: '',
        subtitle: '',
        type: headerBannerContent?.type,
      });

      return;
    }

    if (headerBannerContent) {
      setHeaderBannerDataToLocalStorage({
        isVisible: isVisible || '',
        title: headerBannerContent?.title || '',
        subtitle: headerBannerContent?.subtitle || '',
        description: headerBannerContent?.description || '',
        reason: headerBannerContent?.reason || '',
        type: headerBannerContent?.type,
      });
    }
  }, [headerBannerContent, isVisible]);

  useEffect(() => {
    if (currentChat.chatId && view === View.CUSTOMER) {
      const diffTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME));
      const currentTime = new Date();
      const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffTime);

      setDate(new Date(correctedTime));
    }
  }, [currentChat.chatId, view]);

  useEffect(() => {
    const isShowSwitchToPhone = LocalStorage.getItem(IS_SHOW_SWITCH_TO_PHONE);
    const isSwitchToPhoneBanner = LocalStorage.getItem(IS_SWITCH_TO_PHONE_BANNER);

    if (!isSwitchToPhoneBanner && isPhoneReading && isLiveChatActive) {
      setMixpanelPromptDeliveredEvent(
        'sometimes you just need to hear a human voice',
        'switch to phone from chat banner',
      );
    }

    if (isShowSwitchToPhone) {
      return;
    }

    if (isPhoneReading && isLiveChatActive) {
      LocalStorage.setItem(IS_SWITCH_TO_PHONE_BANNER, 'tracked');
      setHeaderBannerContent(phoneNotification);
      setIsVisibleHeaderBanner(true);
    }
  }, [isPhoneReading, isLiveChatActive]);

  useEffect(() => {
    if (!headerBannerContent) {
      return;
    }

    const isOffline = internetConnectingStatus === InternetConnectionEvents.OFFLINE;

    setIsVisibleHeaderBanner(isOffline || headerBannerContent);
  }, [internetConnectingStatus, headerBannerContent]);

  useEffect(() => {
    const chatEndedMessage = notifications.find((notification) => (
      notification?.attributes?.type === NotificationType.CHAT_ENDED_MESSAGE
      && date && date < notification.timestamp
    ));

    if (chatEndedMessage) {
      const reason = chatEndedMessage?.attributes?.reason;

      setMixPanelEventsChatCompleted(reason, MixpanelEvents.PROMPT_DELIVERED);
      setHeaderBannerContent({
        type: chatEndedMessage?.attributes?.type,
        title: '',
        description: chatEndedMessage?.attributes?.notificationBody,
        reason,
      });
      setIsVisibleHeaderBanner(true);
      setDate(null);
      setIsPhoneReading(false);
    }

    if (isLiveChatActive && chatEndedMessage) {
      deleteNotifications();
      removeChatId();
      setIsLiveChatActive(false);
    }
  }, [notifications, isLiveChatActive]);

  useEffect(() => {
    if (notifications?.length > 0) {
      const lastNotification = notifications[notifications.length - 1];
      const chatNotification = lastNotification.attributes.type
      || lastNotification.attributes.notificationType;
      const isChatInInitState = checkIsChatInInitState(chatNotification);

      const {
        autoreloadChatToastText2,
      } = simplyfyJsonArrayKeys(autoReloadPromptsLabels);

      const {
        addedFunds,
      } = lastNotification?.attributes;

      if (isChatInInitState) {
        setChatInitNotification(
          setInitChatBanner,
          setInitChatNotification,
          setIsChatInInitState,
          chatNotification,
          setShowInitModal,
          setIsLiveChatActive,
          setHistoryModeState,
          isHistoryMode,
          isLiveChatActive,
          setToastBannerData,
          autoreloadChatToastText2?.replace('xx.xx', addedFunds?.toFixed(2)),
        );
      }
    }
  }, [notifications]);

  const handleOnClick = () => {
    const typeHeaderBanner = LocalStorage.getItem(HEADER_BANNER_TYPE_STORAGE);
    const isCallNotification = typeHeaderBanner === SWITCH_TO_PHONE_BANNER;

    if (isCallNotification) {
      LocalStorage.setItem(IS_SHOW_SWITCH_TO_PHONE, 'shown');
      LocalStorage.setItem(SWITCH_TO_PHONE_VISIBLE, '');
    }

    if (headerBannerContent?.type === SWITCH_TO_PHONE_BANNER) {
      MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
        button_text: 'x close',
        button_type: 'x close icon',
        screen_name: 'switch to phone from chat banner',
        screen_title: 'chat window',
      });
    }

    setMixPanelEventsChatCompleted(headerBannerContent?.reason, MixpanelEvents.BUTTON_TAPPED);
    setIsVisibleHeaderBanner(false);
    setHeaderBannerContent(null);

    LocalStorage.removeItems([
      HEADER_BANNER_TITLE_STORAGE,
      HEADER_BANNER_SUBTITLE_STORAGE,
      HEADER_BANNER_DESCRIPTION_STORAGE,
      HEADER_BANNER_TYPE_STORAGE,
      IS_VISIBLE_HEADER_BANNER_STORAGE,
    ]);
  };

  return (
    <HeaderNotification
      view={view}
      handleOnClick={handleOnClick}
      isVisible={isVisible}
      notification={headerBannerContent}
      isHistoryMode={isHistoryMode}
      isInitChatModal={isInitChatModal}
      isInitChatBanner={isInitChatBanner}
    />
  );
};

export default HeaderNotificationContainer;
