import React from 'react';
import cn from 'classnames';

import { getPsychicIcon } from 'src/utils/commonUtil';

import PsychicListItem from './PsychicListItem';
import styles from './PsychicList.module.scss';

const PsychicList = ({ isVisible, customerList, handlePsychicNoteScreens }) => {
  if (!isVisible) {
    return null;
  }

  if (!customerList.length) {
    return (
      <div className={cn(styles.wrapper, styles.empty)}>
        <span className={styles.heading}>
          There are no Psychics able to receive notes at this time.
        </span>
        <div className={styles.divider} />
        <span className={styles.description}>
          You can send notes to Psychics you have had a
          reading with in the past 90 days that are currently offline or busy.
        </span>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.listHeading}>
        Offline & busy Psychics you’ve read with in the past 90 days:
      </span>
      <div className={styles.psychicList}>
        {customerList && customerList.map((customer) => (
          <PsychicListItem
            psychicName={customer.psychic.lineName}
            chatStatus={customer.psychic.chatStatus}
            phoneStatus={customer.psychic.lineStatus}
            key={customer.pcComId}
            icon={getPsychicIcon(customer, 4)}
            pcComId={customer.pcComId}
            psychic={customer}
            handlePsychicNoteScreens={handlePsychicNoteScreens}
          />
        ))}
      </div>
    </div>
  );
};

export default PsychicList;
