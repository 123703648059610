import { ConversationType } from 'extracted-chat-components/enums';

import { ChatType } from 'types/objectTypes';

const modifyCurrentChat = (
  currentChat: ChatType,
  parameter: string,
  payload: any,
) => ({
  ...currentChat,
  attributes: {
    ...currentChat.attributes,
    requestType: ConversationType.DIRECT_MESSAGE,
    [parameter]: payload[parameter],
  },
});

export const modifyChats = (
  offlineChats: Array<ChatType>,
  parameter: string,
  payload: any,
) => offlineChats.map((chat: ChatType) => {
  const newChat = chat;

  if (newChat.chatId === payload.chatId) {
    newChat.attributes[parameter] = payload[parameter];
  }

  return newChat;
});

export const returnChatsWithModifyingParameter = (
  chats: any,
  parameter: string,
  payload: any,
) => {
  const newCurrentChat = modifyCurrentChat(chats.currentChat, parameter, payload);

  const newOfflineChats = modifyChats(chats.offlineChats, parameter, payload);

  return { newCurrentChat, newOfflineChats };
};
