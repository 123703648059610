import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getTimeFromTimestamp } from 'src/utils/dateHandler';
import { selectDiffInTime } from 'selectors/selectors';

export const useBreakTimer = (breakEndTime, delayedBreak) => {
  const [sec, setSec] = React.useState<number>(0);
  const [mins, setMins] = React.useState<number>(0);

  const diffInTime = useSelector(selectDiffInTime);

  React.useEffect(() => {
    const ends = delayedBreak ? breakEndTime : breakEndTime + (diffInTime * 1000) - Date.now();
    const [seconds, minutes] = getTimeFromTimestamp(ends);
    setSec(seconds);
    setMins(minutes);
  }, [setMins, setSec, breakEndTime, diffInTime]);

  const stopTimer = (timerId) => {
    setSec(0);
    clearInterval(timerId);

    return 0;
  };

  useEffect(() => {
    let timerId;
    const startTimer = () => (setInterval(
      () => {
        setSec((prevSec) => {
          const next = prevSec - 1;

          if (next < 0) {
            if (mins === 0 && next <= 0) {
              stopTimer(timerId);
            }

            if (mins > 0) {
              setMins(mins - 1);
            }

            return 59;
          }

          return next;
        });
      },
      1000,
    ));

    if (!delayedBreak) timerId = startTimer();

    return () => {
      clearInterval(timerId);
    };
  }, [delayedBreak, mins, setSec, setMins]);

  return { sec, min: mins };
};
