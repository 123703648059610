import React, { useCallback } from 'react';
import cn from 'classnames';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import StyledCheckbox from 'components/PsychicNotifications/CustomStyled/StyledCheckbox';
import StyledTooltip from 'components/Tooltips/StyledTooltip/StyledTooltip';
import { zodiacMap } from 'src/utils/zodiacImagesUtil';
import {
  formatToUSTime,
  formatToUSDateOnly,
  formatToUSShortMonth,
} from 'src/utils/dateHandler';
import { PNNotificationStatus } from 'src/constants/enums';
import { ICustomerList } from 'src/types/componentTypes';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import ClientTypeLable from 'components/Labels/ClientTypeLabel';
import { selectPsychicNoteConfigurableData, selectSendCustomerDetail } from 'selectors/psychicNotificationsSelectors';
import { truncateString } from 'src/utils/commonUtil';
import { CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST } from 'constants/constants';
import styles from 'components/PsychicNotifications/ListingView/ListingViewContainer.module.scss';
import LabelTags from 'components/Tags/LabelTags';

const CustomerListForTable: React.FC<ICustomerList> = ({
  customer,
  selected,
  onSelectRow,
  selectedLength,
  labelTag,
  handelOnClickedLabel,
}) => {
  const {
    displayname,
    customerId,
    customerType,
    readingType,
    zodiacSign,
    lastReadingDate,
    notificationStatus,
  } = customer;
  const isCustomerMuted = notificationStatus === PNNotificationStatus.MUTED;
  const readingDate = formatToUSDateOnly(lastReadingDate);
  const readingLongDate = `${formatToUSShortMonth(lastReadingDate)} | ${formatToUSTime(lastReadingDate)}`;
  const zodiacImage = zodiacMap.get(zodiacSign.toLocaleLowerCase());
  const { psychicMaxNotificationsSend } = useSelector(selectPsychicNoteConfigurableData);
  const sendCustomerDetail = useSelector(selectSendCustomerDetail);

  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);

  const handleOnSelect = useCallback(() => {
    const customerDetails = sendCustomerDetail || [];

    if (selected) {
      onSelectRow(customerId);
      const index = customerDetails.indexOf(customer);
      customerDetails.splice(index, 1);
      setNotificationCustomerDetail(customerDetails);
    } else if (selectedLength.length < psychicMaxNotificationsSend) {
      setNotificationCustomerDetail([customer, ...customerDetails]);
      onSelectRow(customerId, psychicMaxNotificationsSend);
    }
  }, [selected, selectedLength]);

  const tableRowClasses = cn(styles.tableRow, {
    [styles.mutedRow]: isCustomerMuted,
    [styles.selectedRow]: selected,
  });

  const isEditButton = !selectedLength.length;

  return (
    <TableRow
      hover
      align="left"
      selected={selected}
      className={tableRowClasses}
    >
      <TableCell padding="checkbox">
        <StyledCheckbox checked={selected} disabled={isCustomerMuted} onClick={handleOnSelect} />
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle2" noWrap>
          <span className={styles.customerName}>
            {truncateString(
              displayname,
              CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST,
            )}
          </span>
        </Typography>
        {isCustomerMuted && <span className={styles.mutedTag}>Muted</span>}
      </TableCell>

      <TableCell align="center">
        <div className={styles.customerLable}>
          <ClientTypeLable clientType={customerType} />
        </div>
      </TableCell>

      <TableCell align="center">
        <StyledTooltip title={readingLongDate}>
          <span>{readingDate}</span>
        </StyledTooltip>
      </TableCell>

      <TableCell align="center">
        {readingType}
      </TableCell>

      <TableCell align="center">
        <StyledTooltip title={zodiacSign}>
          <img src={zodiacImage} alt={zodiacSign} className={styles.zodiacImage} />
        </StyledTooltip>
      </TableCell>
      <TableCell align="left">
        <LabelTags
          isEditButton={isEditButton}
          handelOnClickedLabel={handelOnClickedLabel}
          labels={labelTag}
        />
      </TableCell>
    </TableRow>
  );
};

export default CustomerListForTable;
