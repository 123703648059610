import React from 'react';

import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import styles from 'components/Buttons/ReturnToNotificationsButton/ReturnToNotificationsButton.module.scss';

const ReturnToNotificationsButton = ({ onClick }) => (
  <button
    className={styles.returnButton}
    type="button"
    id={PSYCHIC_NOTE_TAB_ID.ALL_NOTE}
    onClick={onClick}
  >
    Return to All Notes
  </button>
);

export default ReturnToNotificationsButton;
