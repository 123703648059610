import { View } from 'constants/enums';
import * as types from 'src/redux/actions/actionsTypes';
import {
  ChatType,
  MessageType,
  TwilioUser,
  ScheduleData,
  CallBackQueueData,
  AutoReloadPayload,
} from 'types/objectTypes';
import { acceptChatPayloadInterface } from 'types/componentTypes';

export const sendMessage = (message: MessageType, channelSid: string) => ({
  type: types.SEND_MESSAGE,
  payload: { message, channelSid },
});

export const setCurrentChannelSid = (sid: string) => ({
  type: types.SET_CURRENT_CHANNEL_SID,
  payload: sid,
});

export const setIsOfflineChatStart = (
  currentChat: ChatType,
  offlineChats: Array<ChatType>,
) => ({
  type: types.SET_IS_OFFLINE_CHAT_START,
  payload: { currentChat, offlineChats },
});

export const getSystemChannelSucceeded = (payload) => ({
  type: types.SYSTEM_CHANNEL_SUCCEEDED,
  payload,
});

export const joinChannelRequest = (chatChannelSid: string) => ({
  type: types.JOIN_CHANNEL_REQUEST,
  payload: { chatChannelSid },
});

export const configureChannelEvents = (channelInstance, chatId?: string) => ({
  type: types.CONFIGURE_CHANNEL_EVENTS,
  payload: { channelInstance, chatId },
});

export const configureSystemChannelEvents = (payload) => ({
  type: types.CONFIGURE_SYSTEM_CHANNEL_EVENTS,
  payload,
});

export const addParticipantMessage = (payload) => ({
  type: types.ADD_PARTICIPANT_MESSAGE,
  payload,
});

export const addParticipantOfflineMessage = (
  currentChat: ChatType,
  offlineChats: Array<ChatType>,
) => ({
  type: types.ADD_PARTICIPANT_OFFLINE_MESSAGE,
  payload: { currentChat, offlineChats },
});

export const addParticipantOfflineMessageBackground = (offlineChats: Array<ChatType>) => ({
  type: types.ADD_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND,
  payload: { offlineChats },
});

export const updateParticipantMessage = (currentChat: ChatType) => ({
  type: types.UPDATE_PARTICIPANT_MESSAGE,
  payload: { currentChat },
});

export const updateParticipantOfflineMessage = (
  currentChat: ChatType,
  offlineChats: Array<ChatType>,
) => ({
  type: types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE,
  payload: { currentChat, offlineChats },
});

export const updateParticipantOfflineMessageBackground = (offlineChats: Array<ChatType>) => ({
  type: types.UPDATE_PARTICIPANT_OFFLINE_MESSAGE_BACKGROUND,
  payload: { offlineChats },
});

export const addSystemNotification = (payload) => ({
  type: types.ADD_SYSTEM_NOTIFICATION,
  payload,
});

export const addSystemNotifications = (payload: Array<any>) => ({
  type: types.ADD_SYSTEM_NOTIFICATIONS,
  payload,
});

export const joinChannelSuccess = (payload) => ({
  type: types.JOIN_CHANNEL_SUCCESS,
  payload,
});

export const joinAcceptedChatSuccess = () => ({
  type: types.JOIN_ACCEPTED_CHAT_SUCCESS,
});

export const setCustomerId = (id: string | number) => ({
  type: types.SET_CUSTOMER_ID,
  payload: id,
});

export const setExtId = (id: string | number | undefined) => ({
  type: types.SET_EXT_ID,
  payload: id,
});

export const setEncryptedPassword = (encryptedPassword: string | number | undefined) => ({
  type: types.SET_ENCRYPTED_PASSWORD,
  payload: encryptedPassword,
});

export const setTwilioUser = (user) => ({
  type: types.SET_TWILIO_USER,
  payload: user,
});

export const handleCreateChatRequest = (payload) => ({
  type: types.HANDLE_CREATE_CHAT_REQUEST,
  payload,
});

export const cleanAfterIgnoreChatRequest = () => ({
  type: types.CLEAN_AFTER_IGNORE_CHAT_REQUEST,
});

export const setCountryCodes = (payload: Array<any>) => ({
  type: types.SET_COUNTRY_CODES,
  payload,
});

export const acceptCustomerChatRequest = (payload: acceptChatPayloadInterface) => ({
  type: types.ACCEPT_CHAT_REQUEST,
  payload,
});

export const joinAcceptedChat = (chat: ChatType | any, isIncompleteChat?: boolean) => ({
  type: types.JOIN_ACCEPTED_CHAT,
  payload: { chat, isIncompleteChat },
});

export const setMessages = (messages: Array<any>) => ({
  type: types.SET_MESSAGES,
  payload: messages,
});

export const setMessagesOffline = (offlineChats: Array<ChatType>) => ({
  type: types.SET_MESSAGES_OFFLINE,
  payload: { offlineChats },
});

export const setMessagesBefore = (messages: Array<any>) => ({
  type: types.SET_MESSAGES_BEFORE,
  payload: messages,
});

export const setMessagesBeforeOffline = (
  currentChat: ChatType,
  offlineChats: Array<ChatType>,
) => ({
  type: types.SET_MESSAGES_BEFORE_OFFLINE,
  payload: { currentChat, offlineChats },
});

export const setChatId = (id: any) => ({
  type: types.SET_CHAT_ID,
  payload: id,
});

export const setCurrentUser = (user: TwilioUser) => ({
  type: types.SET_CURRENT_USER,
  payload: user,
});

export const setSideUser = (user: TwilioUser) => ({
  type: types.SET_SIDE_USER,
  payload: user,
});

export const setChannelSid = (payload) => ({
  type: types.SET_CHANNEL_SID,
  payload,
});

export const installClientListeners = (payload) => ({
  type: types.INSTALL_CLIENT_LISTENERS,
  payload,
});

export const deleteSideUser = () => ({
  type: types.DELETE_SIDE_USER,
});

export const deleteCurrentChat = () => ({
  type: types.DELETE_CURRENT_CHAT,
});

export const updateCallbackBanner = (customerRefIdEnc?: string) => ({
  type: types.UPDATE_CALLBACK_BANNER,
  payload: customerRefIdEnc,
});

export const deleteCurrentChannel = () => ({
  type: types.DELETE_CURRENT_CHANNEL,
});

export const deleteCustomerId = () => ({
  type: types.DELETE_CUSTOMER_ID,
});

export const initApplication = (token?: string) => ({
  type: types.INIT_APPLICATION,
  payload: token,
});

export const deleteNotifications = () => ({
  type: types.DELETE_NOTIFICATIONS,
});

export const setCurrentChat = (payload) => ({
  type: types.CHAT_FETCH_SUCCEEDED,
  payload,
});

export const setOfflineCurrentChat = (payload) => ({
  type: types.SET_OFFLINE_CURRENT_CHAT,
  payload,
});

export const receiveNewOfflineChat = (chatId: string) => ({
  type: types.RECEIVE_NEW_OFFLINE_CHAT,
  payload: chatId,
});

export const setChatRequest = (
  customerId: string | number,
  extId: string | number,
  isFinishCustomerActiveChats?: boolean,
) => ({
  type: types.CHAT_FETCH_REQUESTED,
  payload: { customerId, extId, isFinishCustomerActiveChats },
});

export const setTwilioClient = (client: any) => ({
  type: types.SET_TWILIO_CLIENT,
  payload: client,
});

export const currentPsychicRequest = (
  psychicRefId: string | number,
  customerId: string | number,
) => ({
  type: types.CURRENT_PSYCHIC_REQUEST,
  payload: { psychicRefId, customerId },
});

export const setCurrentPsychic = (payload) => ({
  type: types.SET_CURRENT_PSYCHIC,
  payload,
});

export const setCurrentPsychicStatus = (payload) => ({
  type: types.SET_CURRENT_PSYCHIC_STATUS,
  payload,
});

export const loadExtraMessages = (messageCount: number) => ({
  type: types.LOAD_EXTRA_MESSAGES,
  payload: messageCount,
});

export const setTypingStartedMessage = (payload) => ({
  type: types.SET_TYPING_STARTED_MESSAGE,
  payload,
});

export const removeChatId = () => ({
  type: types.REMOVE_CHAT_ID,
});

export const startChatByLinkRequest = (payload) => ({
  type: types.START_CHAT_BY_LINK,
  payload,
});

export const setUpPsychicByLink = (payload) => ({
  type: types.SET_UP_PSYCHIC_BY_LINK,
  payload,
});

export const deleteSystemChannel = () => ({
  type: types.DELETE_SYSTEM_CHANNEL,
});

export const deleteCurrentPsychic = () => ({
  type: types.DELETE_CURRENT_PSYCHIC,
});

export const clearExtId = () => ({
  type: types.CLEAR_EXT_ID,
});

export const setChatMinutesActive = (minutes: number) => ({
  type: types.SET_CHAT_MINUTES_ACTIVE,
  payload: minutes,
});

export const handleReceivedOfflineChats = (offlineChats: Array<ChatType>) => ({
  type: types.HANDLE_RECEIVED_OFFLINE_CHATS,
  payload: offlineChats,
});

export const handleReceivedActivePsychicChats = (
  chats: Array<ChatType>,
  currentChatId?: string,
) => ({
  type: types.HANDLE_RECEIVED_ACTIVE_PSYCHIC_CHATS,
  payload: { chats, chatId: currentChatId },
});

export const checkIfMissedChat = (payload) => ({
  type: types.CHECK_IF_CHAT_MISSED,
  payload,
});

export const setAppointment = (payload) => ({
  type: types.SET_APPOINTMENT,
  payload,
});

export const setOfflineChats = (chats: Array<ChatType>) => ({
  type: types.SET_OFFLINE_CHATS,
  payload: chats,
});

export const notifyActiveChatsLoaded = () => ({
  type: types.ACTIVE_CHATS_LOADED,
});

export const notifyOfflineChatsLoaded = () => ({
  type: types.OFFLINE_CHATS_LOADED,
});

export const addOfflineChat = (chat: ChatType) => ({
  type: types.ADD_OFFLINE_CHAT,
  payload: chat,
});

export const removeCurrentOfflineChat = (offlineChats: Array<ChatType>) => ({
  type: types.REMOVE_CURRENT_OFFLINE_CHAT,
  payload: { offlineChats },
});

export const removeOfflineChat = (offlineChats: Array<ChatType>) => ({
  type: types.REMOVE_OFFLINE_CHAT,
  payload: { offlineChats },
});

export const closeOfflineChat = (currentChat: ChatType, offlineChats: Array<ChatType>) => ({
  type: types.CLOSE_OFFLINE_CHAT,
  payload: { currentChat, offlineChats },
});

export const sideUserRequest = (
  id: string,
  view: View,
  isLightPsychicRequest: boolean = false,
  isForce: boolean = false,
) => ({
  type: types.SIDE_USER_REQUEST,
  payload: {
    id, view, isLightPsychicRequest, isForce,
  },
});

export const setChatStatus = (status: string) => ({
  type: types.SET_CHAT_STATUS,
  payload: status,
});

export const changeMaxDuration = (maxDuration: string) => ({
  type: types.CHANGE_MAX_DURATION,
  payload: maxDuration,
});

export const setUserCancelOfflineChat = (offlineChats: Array<ChatType>) => ({
  type: types.SET_USER_CANCEL_OFFLINE_CHAT,
  payload: { offlineChats },
});

export const setUserCancelCurrentOfflineChat = (
  currentChat: ChatType,
  offlineChats: Array<ChatType>,
) => ({
  type: types.SET_USER_CANCEL_CURRENT_OFFLINE_CHAT,
  payload: { currentChat, offlineChats },
});

export const setVisibilityNotification = (
  attributes: any,
  index: number,
) => ({
  type: types.SET_VISIBILITY_NOTIFICATION,
  payload: { attributes, index },
});

export const setCurrentPsychicPhoneStatus = (phoneStatus: string) => ({
  type: types.SET_CURRENT_PSYCHIC_PHONE_STATUS,
  payload: phoneStatus,
});

export const clearChat = () => ({
  type: types.CLEAR_CHAT,
});

export const clearChatEvents = () => ({
  type: types.CLEAR_CHAT_EVENTS,
});

export const removePrevChatData = () => ({
  type: types.REMOVE_PREV_CHAT_DATE,
});

export const setFunds = (funds: string) => ({
  type: types.SET_FUNDS,
  payload: funds,
});

export const setCustomerBalance = (customerBalance: string) => ({
  type: types.SET_CUSTOMER_BALANCE,
  payload: customerBalance,
});

export const restoreActiveChat = () => ({
  type: types.RESTORE_ACTIVE_CHAT,
});

export const handleCreateChatErrors = ({ data, error }) => ({
  type: types.HANDLE_START_CHAT_ERROR,
  payload: { data, error },
});

export const setPhoneStatusWaitingState = (isWaiting: boolean) => ({
  type: types.SET_PHONE_STATUS_WAITING_STATE,
  payload: isWaiting,
});

export const setIsAcceptChat = (isAcceptChat: boolean) => ({
  type: types.SET_IS_ACCEPT_CHAT,
  payload: isAcceptChat,
});

export const sendMessageLocal = (payload) => ({
  type: types.SEND_MESSAGE_LOCAL,
  payload,
});

export const setActiveChat = () => ({
  type: types.SET_ACTIVE_CHAT,
});

export const onEndChat = (payload) => ({
  type: types.END_CHAT_REQUEST,
  payload,
});

export const muteChimeRequest = () => ({
  type: types.MUTE_CHIME_REQUEST,
});

export const setPsychicSchedules = (payload: ScheduleData) => ({
  type: types.SET_PSYCHIC_SCHEDULES,
  payload,
});

export const setPsychicCallbackQueue = (payload: Array<CallBackQueueData>) => ({
  type: types.SET_PSYCHIC_CALLBACK_QUEUE,
  payload,
});

export const setInitChatNotification = (payload: string) => ({
  type: types.SET_INIT_CHAT_NOTIFICATION,
  payload,
});

export const setInitChatBanner = (payload: boolean) => ({
  type: types.SET_INIT_CHAT_BANNER,
  payload,
});

export const setAutoReload = (payload: AutoReloadPayload) => ({
  type: types.SET_AUTO_RELOAD,
  payload,
});

export const setEndChatDueToLowFundState = (isChatEnded: boolean) => ({
  type: types.SET_END_CHAT_DUE_TO_LOW_FUND,
  payload: isChatEnded,
});

export const setShowAutoReloadHeader = (isShowAutoReload: boolean) => ({
  type: types.SHOW_AUTO_RELOAD_HEADER,
  payload: isShowAutoReload,
});

export const setCustomerEnableAutoRecharge = (enableAutoRecharge: boolean) => ({
  type: types.SET_CUSTOMER_ENABLE_AUTO_RECHARGE,
  payload: enableAutoRecharge,
});

export const setChatById = (chatId: string) => ({
  type: types.SET_CHAT_BY_ID,
  payload: chatId,
});

export const setChatChannelAndLoadMessages = (chatChannelSid: string) => ({
  type: types.SET_CHAT_CHANNEL_AND_LOAD_MESSAGE,
  payload: chatChannelSid,
});
