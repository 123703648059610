import { DIFFERENCE_IN_TIME, TWO_MINUTES, WEEKDAY } from 'constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';

export const createDateAsUTC = (date: Date) => new Date(Date.UTC(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  date.getHours(),
  date.getMinutes(),
  date.getSeconds(),
));

export const convertDateToUTC = (date: Date) => new Date(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds(),
);

export const calculateTimeDifference = (firstDate: Date, secondDate: Date): number => {
  const expiresDate = convertDateToUTC(firstDate);
  const dateNow = convertDateToUTC(secondDate);
  const intDifference = expiresDate.getTime() - dateNow.getTime();

  return intDifference;
};

export const formatToUSTime = (date: Date | string): string => {
  if (!date) return '';

  const normalizeDate = new Date(date);
  const options: object = { hour: 'numeric', minute: 'numeric' };

  return Intl.DateTimeFormat('en-US', options).format(normalizeDate);
};

export const formatDate = (date) => {
  const options: object = {
    month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };

  return Intl.DateTimeFormat('en-US', options).format(date);
};

export const getDate = (date: Date | string): string => {
  if (!date) return '';

  const normalizeDate = new Date(date);
  const options: object = { year: 'numeric', month: 'short', day: 'numeric' };

  return Intl.DateTimeFormat('en-US', options).format(normalizeDate);
};

export const calculateDateDiffSeconds = (firstDate: Date, secondDate: Date) => {
  const firstUTCDate = convertDateToUTC(firstDate);
  const secondUTCDate = convertDateToUTC(secondDate);
  const diff = new Date(firstUTCDate.getTime() - secondUTCDate.getTime());

  return diff.getTime() / 1000;
};

export const formatToUSMonthDay = (date: Date | string): string => {
  const normalizeDate = new Date(date);

  if (normalizeDate) return `${normalizeDate.getMonth() + 1}/${normalizeDate.getDate()}`;

  return '';
};

export const formatToUSDateOnly = (date: Date | string): string => {
  const normalizeDate = new Date(date);

  if (normalizeDate) {
    const numericDate = normalizeDate.getDate();
    const month = normalizeDate.getMonth() + 1;
    const fullYear = String(normalizeDate.getFullYear());

    return `${month}/${numericDate}/${fullYear.slice(2)}`;
  }

  return '';
};

export const formatForPsychicNote = (date: Date) => {
  const currentDate = new Date();
  const normalizeDate = new Date(date);

  if (normalizeDate) {
    if (currentDate.getFullYear() === normalizeDate.getFullYear()
   && currentDate.getMonth() === normalizeDate.getMonth()
   && currentDate.getDate() === normalizeDate.getDate()) {
      return formatToUSTime(date);
    }

    return formatToUSDateOnly(date);
  }

  return '';
};

export const formatDateForClientsNote = (date, isCustomer = false) => {
  const currentDate = new Date();
  const normalizeDate = new Date(date);

  if (!normalizeDate) {
    return '';
  }

  let differenceValue = (currentDate.getTime() - normalizeDate.getTime()) / 1000;
  const isSameDay = normalizeDate.getDay() === currentDate.getDay();

  if (isCustomer) {
    differenceValue = (convertDateToUTC(currentDate).getTime() - normalizeDate.getTime()) / 1000;
  }

  if (differenceValue < 60) {
    return 'Just Now';
  }

  if ((differenceValue / 60) < 60) {
    differenceValue /= 60;

    return `${Math.abs(Math.round(differenceValue))} min ago`;
  }

  if ((differenceValue / 3600) < 24) {
    return `${Math.abs(Math.round(differenceValue / 3600))} hour ago`;
  }

  const totalDays = Math.ceil(currentDate.getTime() / (1000 * 3600 * 24)
   - normalizeDate.getTime() / (1000 * 3600 * 24));

  if (totalDays <= 7 && !isSameDay) {
    return `${WEEKDAY[normalizeDate.getDay()]} ${formatToUSTime(date)}`;
  }

  return formatToUSDateOnly(date);
};

export const formatDateForClientsNoteForCustomer = (date, isNoteList = false) => {
  const currentDate = new Date();
  const normalizeDate = new Date(date);

  if (!normalizeDate) {
    return '';
  }

  let differenceValue = (currentDate.getTime() - normalizeDate.getTime()) / 1000;

  if (isNoteList) {
    differenceValue = (convertDateToUTC(currentDate).getTime() - normalizeDate.getTime()) / 1000;
  }

  if (differenceValue < 60) {
    return 'Just Now';
  }

  if ((differenceValue / 60) <= 60) {
    differenceValue /= 60;
    const timeValue = Math.abs(Math.round(differenceValue));

    return timeValue > 1 ? `${timeValue} mins ago` : `${timeValue} min ago`;
  }

  if ((differenceValue / 3600) < 24) {
    const timeValue = Math.abs(Math.round(differenceValue / 3600));

    return timeValue <= 1 ? `${timeValue} hr ago` : `${timeValue} hrs ago`;
  }

  const totalDays = Math.ceil(currentDate.getTime() / (1000 * 3600 * 24)
   - normalizeDate.getTime() / (1000 * 3600 * 24));

  if (totalDays < 7) {
    return `${WEEKDAY[normalizeDate.getDay()]} ${formatToUSTime(date)}`;
  }

  return `${formatToUSDateOnly(date)} ${!isNoteList ? formatToUSTime(date) : ''}`;
};

export const isToday = (date: Date) => {
  const currentDate = new Date();
  const normalizeDate = new Date(date);

  if (normalizeDate) {
    if (currentDate.getFullYear() === normalizeDate.getFullYear()
    && currentDate.getMonth() === normalizeDate.getMonth()
    && currentDate.getDate() === normalizeDate.getDate()) {
      return true;
    }
  }

  return false;
};

export const formatToUSShortMonth = (date: Date | string): string => {
  const normalizeDate = new Date(date);
  const month = normalizeDate.toLocaleString('en', { month: 'short' });

  if (normalizeDate) return `${month} ${normalizeDate.getDate()}, ${normalizeDate.getFullYear()}`;

  return '';
};

const getSeconds = (date): number => {
  if (date) {
    return date.getHours() * 3600
      + date.getMinutes() * 60 + date.getSeconds();
  }

  return 0;
};

export const getTimeActiveChat = (date): number => {
  const diffTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME));
  const currentTime = new Date();
  const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffTime);
  const currentSeconds = getSeconds(new Date(correctedTime));
  const seconds = getSeconds(new Date(date));
  const timeActiveChat = currentSeconds - seconds;

  return timeActiveChat > 0 ? timeActiveChat : 0;
};

export const getDiffTime = (date: Date | string): number => {
  const timestamp = new Date(date).getTime();

  return (Date.now() - timestamp) / 1000;
};

export const getTimeRemaining = (date: string): { hours: number, minutes: number } | undefined => {
  const diffTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME));
  const currentTime = new Date();
  const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffTime);
  const intDifference = calculateTimeDifference(new Date(date), new Date(correctedTime));

  if (intDifference < 0) return undefined;

  const ONE_HOUR = (60 * 60 * 1000);
  const ONE_MINUET = (60 * 1000);
  const preHour = Math.floor(intDifference / ONE_HOUR);
  const hours = preHour || 0;
  const minutes = Math.floor((intDifference - (preHour * ONE_HOUR)) / ONE_MINUET);
  const response = { hours, minutes };

  return response;
};

export const formatTokenTimeStampToDate = (timestamp: number): string => {
  if (!timestamp) { return ''; }

  const timestampToDate = new Date(timestamp * 1000);

  return `${getDate(timestampToDate)} ${formatToUSTime(timestampToDate)}`;
};

export const formatDateTimeIn2Digit = (
  date: Date | string,
  isSecond: boolean = false,
): string => {
  if (!date) return '';

  const normalizeDate = new Date(date);
  const option: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  if (isSecond) {
    option.second = 'numeric';
  }

  const twoDigitFormatter = new Intl.DateTimeFormat('en-US', option);
  twoDigitFormatter.format(normalizeDate);

  return twoDigitFormatter.formatToParts(normalizeDate).map(({ type, value }) => {
    switch (type) {
      case 'literal': {
        if (value.charCodeAt(0) === 47) return '.';

        if (value.charCodeAt(0) === 44) return ' ';

        if (value.charCodeAt(0) === 32) return '';

        return value;
      }
      case 'dayPeriod': return value === 'AM' ? 'A' : 'P';
      default: return value;
    }
  }).join('');
};

export const twoDigitDateConversion = (timestamp: string | number) => {
  const twoDigitFormat = { hour: 'numeric', minute: '2-digit', hour12: true };
  const date = new Date(timestamp);

  return date.toLocaleString('en-US', twoDigitFormat);
};

export const getTimeFromTimestamp = (ends) => {
  const secondsLeft = Math.trunc(ends / 1000);
  const minutes = Math.trunc(secondsLeft / 60);
  const seconds = minutes ? secondsLeft % 60 : secondsLeft;

  return [seconds, minutes];
};

export const correctNow = () => {
  const diffTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME)) || 0;

  return Date.now() - diffTime * 1000;
};

export const getDateAndTime = (timestamp: any): { date: string; time: string } => {
  const date = getDate(timestamp);
  const time = formatToUSTime(timestamp);

  return { date, time };
};

export const getAvailableTime = (time) => {
  if (time <= 10.98 && time >= 9) {
    return {
      availableTime: 8,
    };
  }

  if (time < 9 && time >= 5) {
    return {
      availableTime: 4,
    };
  }

  if (time < 5 && time >= 2) {
    return {
      availableTime: 1,
    };
  }

  if (time > 10.98) {
    return {
      availableTime: 10,
    };
  }

  return {
    availableTime: 0,
  };
};

export const getAvailableTimeDescription = (time, availableTime) => {
  const visibleHours = time?.hours === 1 ? `${time?.hours} hour` : `${time?.hours} hours`;
  const visibleMinutes = time?.minutes && (time?.minutes === 1 ? `${time?.minutes} minute` : `${time?.minutes} minutes`);

  return {
    description: `${visibleHours} ${visibleMinutes || ''}`,
    title: availableTime === 1 ? 'You can set a reminder for 1 hour' : `You can set a reminder for up to ${availableTime} hours`,
  };
};

export const getHours = (time) => {
  const minToHr = time?.minutes / 60;
  const hour: number = minToHr + time?.hours;

  return hour;
};

export const buildingTimeRemainingLine = (
  chat,
  currentChatId,
  removeCurrentOfflineChatHandler,
  removeOfflineChatHandler,
) => {
  const commonRemaining = '0m remaining';
  const time = getTimeRemaining(chat!.directMessageExpiresAt!);

  if (!time) return commonRemaining;

  const { hours, minutes } = time;

  if (hours === 0 && minutes === 0) {
    if (chat!.chatId === currentChatId) {
      removeCurrentOfflineChatHandler(chat!.chatId);
    } else {
      removeOfflineChatHandler(chat!.chatId!);
    }
  }

  const visibleHours = (hours > 0) ? `${hours}h` : '';

  return `${visibleHours} ${minutes}m remaining`;
};

export const calcRemainingTime = (
  chat,
  currentChatId,
  removeCurrentOfflineChatHandler,
  removeOfflineChatHandler,
) => {
  if (chat?.directMessageExpiresAt && chat?.chatId) {
    return buildingTimeRemainingLine(
      chat,
      currentChatId,
      removeCurrentOfflineChatHandler,
      removeOfflineChatHandler,
    );
  }

  return '0m remaining';
};

export const calcAppointmentTime = (appointment) => {
  const { customerName, appointmentTimestamp } = appointment;
  const appointmentTimeToDisplay = twoDigitDateConversion(Number(appointmentTimestamp));
  const chatEndsTimestamp = Number(appointmentTimestamp) - TWO_MINUTES;
  const chatEndsDate = twoDigitDateConversion(Number(chatEndsTimestamp));
  const now = new Date(Date.now());
  // @ts-ignore
  const msTillAppointment = new Date(+appointmentTimestamp) - now;
  const minutesTillAppointment = Math.trunc(msTillAppointment / 60000);

  return {
    customerName,
    appointmentTimeToDisplay,
    chatEndsDate,
    minutesTillAppointment,
  };
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return `${month}/${day}/${year}`;
};

export const getDateFromString = (date) => Number(date.replace('/Date', '').replace('/', '').replace('(', '').replace(')', '')
  .replace('-0000', ''));

export const checkIsCustomerHaveRecentReading = (lastReadingDateUTC) => {
  const normalizeDate = new Date(lastReadingDateUTC);

  if (!lastReadingDateUTC || !normalizeDate?.getTime()) {
    return true;
  }

  const currentDate = new Date();
  const timeDifference = convertDateToUTC(currentDate).getTime() - normalizeDate.getTime();
  const daysDifference = 90 * (1000 * 3600 * 24);

  return daysDifference >= timeDifference;
};
