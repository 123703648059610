import { useEffect } from 'react';

import {
  CHAT_ID_STORAGE,
  CUSTOMER_ID_STORAGE,
  PSYCHIC_ID_STORAGE,
  TOKEN_AUTH_STORAGE,
  TOKEN_TWILIO_STORAGE,
} from 'constants/constants';
import {
  BootState,
  Events,
  Message,
  SdkChatEvent,
  SdkEvents,
  View,
} from 'constants/enums';
import {
  onSendMessage,
  getSdkCallbacks,
  setOnChatEventCallback,
  handleImmediateChatInvents,
} from 'src/sdk/service/sdk.service';
import { formatToUSTime } from 'src/utils/dateHandler';
import { LocalStorage } from 'src/utils/storageHandler';
import { SdkConfiguration } from 'types/objectTypes';
import { updateUserData } from 'src/sdk/hooks/updateUserData';

export const useGetMessages = (
  sdkCallbackStorage: Map<string, Function>,
  command: string | undefined,
  messages: Array<any>,
  removeCommand,
) => {
  useEffect(() => {
    if (command === SdkEvents.GET_MESSAGES && sdkCallbackStorage.get(command)) {
      // @ts-ignore
      sdkCallbackStorage.get(command)(messages);
      sdkCallbackStorage.delete(command);
      removeCommand();
    }
  }, [sdkCallbackStorage.get(SdkEvents.GET_MESSAGES)]);
};

export const useSendMessage = (
  sdkCallbackStorage: Map<string, Function>,
  clearDataFromSdk: Function | undefined,
  dataFromSdk: any,
  messages: Array<any>,
  command: string | undefined,
) => {
  useEffect(() => {
    if (command === SdkEvents.SEND_MESSAGE
      && sdkCallbackStorage.get(command)
      && dataFromSdk
      && clearDataFromSdk) {
      const message = {
        value: dataFromSdk.message,
        name: 'Me',
        sendTime: formatToUSTime(new Date()),
        type: Message.CLIENT,
      };
      messages.push(message);
      // @ts-ignore
      sdkCallbackStorage.get(command)(message);
      onSendMessage(dataFromSdk.message, sdkCallbackStorage);
      clearDataFromSdk();
      sdkCallbackStorage.delete(SdkEvents.SEND_MESSAGE);
    }
  }, [dataFromSdk]);
};

const setUpOnChatEventCallbacks = (
  addSdkCallback: Function,
  callbacks: any,
) => {
  addSdkCallback(SdkEvents.ON_TOKEN_EXPIRED, callbacks.onTokenExpiredCb);
  addSdkCallback(SdkEvents.ON_RECEIVE_CHAT, callbacks.onReceiveChatCb);
  addSdkCallback(SdkEvents.ON_END_CHAT, callbacks.onEndChatCb);
  addSdkCallback(SdkEvents.ON_IGNORE_CHAT, callbacks.onIgnoreChatCb);
  addSdkCallback(SdkEvents.ON_ACCEPT_CHAT, callbacks.onAcceptChatCb);
  addSdkCallback(SdkEvents.ON_MESSAGE_RECEIVED, callbacks.onMessageReceivedCb);
  addSdkCallback(SdkEvents.ON_OFFLINE_MESSAGE_RECEIVED, callbacks.onOfflineMessageReceived);
  addSdkCallback(SdkEvents.ON_OFFLINE_MESSAGE_UPDATED, callbacks.onOfflineMessageUpdated);
  addSdkCallback(SdkEvents.ON_LOGOUT, callbacks.onLogoutCb);
  addSdkCallback(SdkEvents.ON_ERROR, callbacks.onErrorCb);
  addSdkCallback(SdkChatEvent.ON_SWITCH_TO_PHONE, callbacks.onSwitchToPhoneCb);
  addSdkCallback(SdkEvents.ON_MUTE_CHAT_REQUEST, callbacks.onMuteChatRequestCb);
};

export const useSdkCases = (
  sdkCallbackStorage: Map<string, Function>,
  sagaMethods: {
    setBootDataFromSdk: Function,
    setDataFromSdk: Function,
    setCommand: Function,
    addSdkCallback: Function,
    openDiagnosticsModel: Function,
  },
) => {
  useEffect(() => {
    const handleSdkEvents = async (event: MessageEvent) => {
      if (event.origin !== 'http://localhost:8080' && event.origin !== 'http://localhost:8080/') {
        const callbacks = getSdkCallbacks(event);
        const { command, payload } = event.data;

        switch (event.data.command) {
          case SdkEvents.BOOT:
            LocalStorage.setUpStorage(payload.storage, event);

            sagaMethods.setDataFromSdk(command, payload.settings);
            sagaMethods.setBootDataFromSdk(payload);
            sagaMethods.addSdkCallback(command, callbacks.bootCb);

            setUpOnChatEventCallbacks(sagaMethods.addSdkCallback, callbacks);

            break;
          case SdkEvents.ON_SEND_MESSAGE:
            if (!sdkCallbackStorage.get(command)) {
              sagaMethods.addSdkCallback(command, callbacks.onSendMessageCb);
            }

            sagaMethods.setCommand(command);

            break;
          case SdkEvents.ON_HIDE:
          case SdkEvents.ON_SHOW:
            if (!sdkCallbackStorage.get(command)) {
              sagaMethods.addSdkCallback(command, callbacks.onChangeVisibilityCb);
            }

            sagaMethods.setCommand(command);

            break;
          case SdkEvents.GET_MESSAGES:
            sagaMethods.addSdkCallback(command, callbacks.getMessagesCb);
            sagaMethods.setCommand(command);

            break;
          case SdkEvents.SHOW:
          case SdkEvents.HIDE:
            sagaMethods.setCommand(command);

            break;
          case SdkEvents.SEND_MESSAGE:
            sagaMethods.addSdkCallback(command, callbacks.sendMessageCb);
            sagaMethods.setDataFromSdk(command, payload);

            break;
          case SdkEvents.ON_CHAT_EVENT:
            setOnChatEventCallback(event, sagaMethods.addSdkCallback);
            handleImmediateChatInvents(event);
            sagaMethods.setCommand(command);

            break;
          case SdkEvents.SET_PHANTOM:
            sagaMethods.setCommand(command);

            break;
          case SdkEvents.ON_PSYCHICS_PRESENCE:
            sagaMethods.addSdkCallback(command, callbacks.onPsychicsPresenceCb);

            break;
          case SdkEvents.SET_CONVERSATION: {
            sagaMethods.addSdkCallback(command, callbacks.setConversationCb);
            sagaMethods.setDataFromSdk(command, payload);

            break;
          }
          case SdkEvents.SET_SESSION: {
            sagaMethods.addSdkCallback(command, callbacks.setSessionCb);
            sagaMethods.setDataFromSdk(command, payload);

            break;
          }
          case SdkEvents.LOGOUT: {
            sagaMethods.addSdkCallback(command, callbacks.logoutCb);
            sagaMethods.setCommand(command);

            break;
          }
          case SdkEvents.RUN_DIAGNOSTIC: {
            sagaMethods.openDiagnosticsModel();

            break;
          }
          case SdkEvents.ON_LOGOUT: {
            sagaMethods.addSdkCallback(command, callbacks.onLogoutCb);
            sagaMethods.setCommand(command);

            break;
          }
          case SdkEvents.ON_ERROR: {
            sagaMethods.addSdkCallback(command, callbacks.onErrorCb);
            sagaMethods.setCommand(command);

            break;
          }
          case SdkEvents.SHUT_DOWN: {
            break;
          }
          case SdkEvents.GET_PSYCHICS_PRESENCE: {
            sagaMethods.setCommand(command);

            break;
          }
          default: break;
        }
      }
    };
    window.addEventListener(Events.MESSAGE, handleSdkEvents);

    return () => window.removeEventListener(Events.MESSAGE, handleSdkEvents);
  }, []);
};

const bootPsychicApplication = (
  sagaMethods,
  mandatoryRefreshCondition,
  bootCb,
  sdkCallbackStorage,
) => {
  const [
    tokenAuthFromStorage,
    tokenTwilioFromStorage,
    chatId,
    psychicId,
  ] = LocalStorage.getItems([
    TOKEN_AUTH_STORAGE,
    TOKEN_TWILIO_STORAGE,
    CHAT_ID_STORAGE,
    PSYCHIC_ID_STORAGE,
  ]);

  if (mandatoryRefreshCondition) {
    sagaMethods.restartChat({
      chatId,
      tokenAuth: tokenAuthFromStorage,
      tokenTwilio: tokenTwilioFromStorage,
      view: View.PSYCHIC,
      psychicId,
    });
  } else {
    sagaMethods.setView(View.PSYCHIC);

    if (bootCb) {
      bootCb({ bootStatus: BootState.STATELESS });
      sdkCallbackStorage.delete(SdkEvents.BOOT);
    }
  }
};

const bootCustomerApplication = (
  sagaMethods,
  mandatoryRefreshCondition,
  bootCb,
  sdkCallbackStorage,
) => {
  const [
    tokenAuthFromStorage,
    tokenTwilioFromStorage,
    chatId,
    psychicId,
    customerId,
  ] = LocalStorage.getItems([
    TOKEN_AUTH_STORAGE,
    TOKEN_TWILIO_STORAGE,
    CHAT_ID_STORAGE,
    PSYCHIC_ID_STORAGE,
    CUSTOMER_ID_STORAGE,
  ]);

  const isCustomerNeedRefreshed = mandatoryRefreshCondition && customerId;

  if (isCustomerNeedRefreshed) {
    sagaMethods.restartChat({
      chatId,
      tokenAuth: tokenAuthFromStorage,
      tokenTwilio: tokenTwilioFromStorage,
      view: View.CUSTOMER,
      psychicId,
      customerId,
    });
  } else {
    sagaMethods.setView(View.CUSTOMER);

    if (bootCb) {
      bootCb({ bootStatus: BootState.STATELESS });
      sdkCallbackStorage.delete(SdkEvents.BOOT);
    }
  }
};

const bootApplication = (
  dataFromSdk: SdkConfiguration['settings'],
  sagaMethods,
  sdkCallbackStorage,
) => {
  const view = dataFromSdk?.presentationMode?.view;

  updateUserData(sagaMethods, dataFromSdk);

  const bootCb = sdkCallbackStorage.get(SdkEvents.BOOT);
  const [
    tokenAuthFromStorage,
    tokenTwilioFromStorage,
    psychicId,
  ] = LocalStorage.getItems([
    TOKEN_AUTH_STORAGE,
    TOKEN_TWILIO_STORAGE,
    PSYCHIC_ID_STORAGE,
  ]);

  const mandatoryRefreshCondition = tokenAuthFromStorage
    && tokenTwilioFromStorage
    && psychicId;

  switch (view?.toLowerCase()) {
    case View.PSYCHIC.toLowerCase(): {
      bootPsychicApplication(
        sagaMethods,
        mandatoryRefreshCondition,
        bootCb,
        sdkCallbackStorage,
      );

      break;
    }
    case View.CUSTOMER.toLowerCase(): {
      bootCustomerApplication(
        sagaMethods,
        mandatoryRefreshCondition,
        bootCb,
        sdkCallbackStorage,
      );

      break;
    }
    default: {
      bootCb({ bootStatus: BootState.INVALID });
      sdkCallbackStorage.delete(SdkEvents.BOOT);
    }
  }
};

export const useSdkConfigurationListener = (
  sdkFullData: {
    command: string,
    dataFromSdk: any,
    sdkCallbackStorage: Map<string, Function>,
  },
  sagaMethods: {
    setConversationSdk: Function,
    setSessionSdk: Function,
    setView: Function,
    destroySessionSdk: Function,
    showLogoutPrompt: Function,
    restartChat: Function,
    setAuthToken: Function,
    setCustomerId: Function,
    setExtId: Function,
    setCommand: Function,
  },
) => {
  useEffect(() => {
    const {
      command,
      dataFromSdk,
      sdkCallbackStorage,
    } = sdkFullData || {};

    switch (command) {
      case SdkEvents.SET_CONVERSATION: {
        sagaMethods.setConversationSdk(dataFromSdk);

        break;
      }
      case SdkEvents.BOOT: {
        bootApplication(
          dataFromSdk,
          sagaMethods,
          sdkCallbackStorage,
        );

        break;
      }
      case SdkEvents.SET_SESSION: {
        sagaMethods.setSessionSdk(dataFromSdk);

        break;
      }
      case SdkEvents.LOGOUT: {
        sagaMethods.showLogoutPrompt();
        sagaMethods.setCommand('');

        break;
      }

      default: break;
    }
  }, [sdkFullData.command, sdkFullData.dataFromSdk]);
};
