import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectPsychicNoteConfigurableData } from 'selectors/psychicNotificationsSelectors';
import { getDiffTime } from 'src/utils/dateHandler';
import NoteMessage from 'components/PsychicNotifications/ClientNotes/NoteMessage';
import { selectView } from 'selectors/selectors';
import { getReadReceiptTextAndFlag } from 'src/utils/listingViewUtils';

import styles from './ClientNotes.module.scss';

const NotesList = ({
  notesHistoryList,
  handleNewUnreadMessage,
  handleOnSelect,
}) => {
  const {
    clientLastNoteLockedCycle,
    clientSendNoteLimit,
  } = useSelector(selectPsychicNoteConfigurableData);
  const view = useSelector(selectView);
  const isPsychicView = view === 'Psychic';

  let customerNotesCount = 0;
  let unreadMessageFound = false;

  useEffect(() => {
    if (unreadMessageFound) {
      handleNewUnreadMessage(true);
    }
  }, [handleNewUnreadMessage, unreadMessageFound]);

  const {
    isReadReceiptVisible,
    readReceiptText,
  } = getReadReceiptTextAndFlag(notesHistoryList, isPsychicView);

  return (
    <>
      {notesHistoryList?.map((message) => {
        const createDateSentDiff = getDiffTime(message.createdDateUTC);
        const isWithInCycle = createDateSentDiff / 3600 <= clientLastNoteLockedCycle;

        if (!message.isRead) {
          unreadMessageFound = true;
        }

        if (message.sendBy === 'Customer' && isWithInCycle) {
          customerNotesCount += 1;
        }

        return (
          <NoteMessage
            key={isPsychicView ? message.pcComHistoryId
              : message.pCComHistoryId}
            message={message}
            view={view}
            customerNotesCount={customerNotesCount}
            clientSendNoteLimit={clientSendNoteLimit}
            handleOnSelect={handleOnSelect}
            isPsychicView={isPsychicView}
          />
        );
      })}
      {isReadReceiptVisible && <span className={styles.readReceipt}>{readReceiptText}</span>}
    </>
  );
};

export default NotesList;
