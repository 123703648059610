import React from 'react';
import { ButtonSize, ButtonColor } from 'extracted-chat-components/enums';
import Modal from 'react-modal';
import InteractChatButton from 'extracted-chat-components/src/components/InteractChatButton';

import standbyCloseIcon from 'src/assets/png/standbyCloseIcon.png';
import PhoneGear from 'src/assets/png/PhoneGear.png';

import style from './styles.module.scss';

const StandByPopup = ({
  isNotificationPopUp,
  popUpNotificationData,
  showPopUpNotification,
}) => {
  const { title = 'StandBy', description = null } = popUpNotificationData || {};
  const onClickHandler = () => {
    showPopUpNotification(false);
  };

  return (
    <Modal
      isOpen={isNotificationPopUp}
      className={style.modal}
      overlayClassName={style.overlay}
      ariaHideApp={false}
    >
      <div className={style.wrapper}>
        <img
          className={style.closePopup}
          src={standbyCloseIcon}
          alt="standbyCloseIcon"
          role="presentation"
          onClick={onClickHandler}
        />
        <img
          src={PhoneGear}
          alt="PhoneGear"
        />
        <span className={style.title}>{title}</span>
        <p className={style.description}>{description}</p>
        <div className={style.containerModalButtons}>
          <div className={style.containerModalButtonsAccept}>
            <InteractChatButton
              className={style.acceptButton}
              isActive
              text="OK"
              onClick={onClickHandler}
              size={ButtonSize.S}
              color={ButtonColor.WARNING}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default StandByPopup;
