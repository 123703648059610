/* eslint-disable @typescript-eslint/no-use-before-define */
import * as Sentry from '@sentry/react';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import { Severity } from '@sentry/react';
import { CaptureContext, Extras } from '@sentry/types';

import {
  RECEIVE_AUTH_DATA,
  RECEIVE_SDK_AUTH_DATA,
  RESET_CHAT, RESTART_CHAT,
  SET_AUTH_TOKEN,
  SET_BOOTED_DATA_FROM_SDK,
  SET_DATA_FROM_SDK,
  SET_SESSION_SDK,
  SET_TWILIO_TOKEN,
} from 'actions/actionsTypes';
import {
  EXTRA_RESPONSE,
  MAX_BREADCRUMBS,
  NORMALIZE_DEPTH,
  SENTRY_POPUP_TAG,
} from 'constants/constants';
import { twilioTokenErrors } from 'src/utils/twilio';
import {
  BreadcrumbCategory,
  Environment,
  NotificationsText,
  TwilioError,
} from 'constants/enums';
import { wait } from 'src/utils/commonUtil';

const isProd = process.env.NODE_ENV === Environment.PRODUCTION;

const allowedActionsForSentry = [
  SET_DATA_FROM_SDK,
  SET_BOOTED_DATA_FROM_SDK,
  SET_SESSION_SDK,
  RESET_CHAT,
  RESTART_CHAT,
  RECEIVE_AUTH_DATA,
  RECEIVE_SDK_AUTH_DATA,
  RESET_CHAT,
  SET_TWILIO_TOKEN,
  SET_AUTH_TOKEN,
];

const removeFirestoreInfo = (breadcrumb) => {
  const isInclude = breadcrumb?.data?.url.includes('firestore');

  return isInclude ? null : breadcrumb;
};

const filterReduxLoggerLogs = (breadcrumb) => {
  const breadcrumbFirstArgument = breadcrumb?.data?.arguments[0];
  const isReduxLogs = breadcrumbFirstArgument.includes('action')
    || breadcrumbFirstArgument.includes('state');

  if (isReduxLogs) {
    return null;
  }

  return breadcrumb;
};

const removeEventFromBlockList = async (blockList) => {
  await wait(process.env.SENTRY_EVENTS_PERIOD);
  blockList?.shift();
};

export const sendEventWithDelay = (event) => {
  let isSentEvent = false;

  event?.exception?.values?.forEach(({
    value,
  }) => {
    const isInclude = SentryMethods.currentEvents.includes(value);

    if (isInclude) {
      return null;
    }

    SentryMethods.currentEvents.push(value);
    removeEventFromBlockList(SentryMethods.currentEvents);

    isSentEvent = true;
  });

  return isSentEvent ? event : null;
};

export const handleSentryEvents = (event) => {
  const isSentEvent = !event?.exception?.values?.find(({
    value,
  }) => twilioTokenErrors.includes(value));

  return isSentEvent ? sendEventWithDelay(event) : null;
};

export const handleBreadcrumb = (breadcrumb) => {
  if (breadcrumb.category === BreadcrumbCategory.UI_CLICK) {
    return null;
  }

  if (breadcrumb.category === BreadcrumbCategory.XHR) {
    return removeFirestoreInfo(breadcrumb);
  }

  if (breadcrumb.category === BreadcrumbCategory.CONSOLE) {
    return filterReduxLoggerLogs(breadcrumb);
  }

  return breadcrumb;
};

export const filterFieldsFromState = (state) => {
  const { chat } = state;
  const { currentChat } = chat;
  const {
    chatId = '',
    customerRefIdEnc = '',
    psychicRefId = '',
    chatChannelSid = '',
  } = currentChat;

  return {
    chat: {
      chatId,
      customerRefIdEnc,
      psychicRefId,
      chatChannelSid,
    },
    systemChannelSid: chat.systemChannel?.sid || '',
  };
};

export const filterActions = (action) => (allowedActionsForSentry.includes(action.type)
  ? action
  : null);

const integrations = [
  new OfflineIntegration(),
];

const getEnumTextByValue = (valueOperator: string, enm: any): string => {
  let operator;

  Object.entries(enm).forEach((keyVal) => {
    if (keyVal[1] === valueOperator) [operator] = [...keyVal];
  });

  return operator;
};

export const SentryMethods = {
  currentEvents: [] as any,

  init: () => {
    if (isProd) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        beforeBreadcrumb: handleBreadcrumb,
        beforeSend: handleSentryEvents,
        integrations,
        environment: process.env.SENTRY_ENVIRONMENT,
        maxBreadcrumbs: MAX_BREADCRUMBS,
        normalizeDepth: NORMALIZE_DEPTH,
      });
    }
  },

  setSentryContext: (userInfo: object) => {
    if (isProd) Sentry.setContext('user', userInfo);
  },

  captureException: (error: any) => {
    if (!isProd) {
      return;
    }

    const sentryTag = error?.response?.status !== 401 || !error.message
      ? getEnumTextByValue(NotificationsText.TECHNICAL_DIFFICULTY, NotificationsText)
      : getEnumTextByValue(NotificationsText.SESSION_EXPIRED, NotificationsText);

    switch (error.message) {
      case NotificationsText.SET_SESSION_ERROR:
        Sentry.setTag(
          SENTRY_POPUP_TAG,
          getEnumTextByValue(error.message, NotificationsText),
        );

        break;
      case NotificationsText.USER_WAS_N0T_FOUND:
        Sentry.setTag(
          SENTRY_POPUP_TAG,
          getEnumTextByValue(error.message, NotificationsText),
        );

        break;
      case TwilioError.CANNOT_GET_TOKEN:
        Sentry.setTag(
          SENTRY_POPUP_TAG,
          getEnumTextByValue(error.message, NotificationsText),
        );

        break;

      default:
        Sentry.setTag(SENTRY_POPUP_TAG, sentryTag);

        break;
    }

    if (error.response) {
      Sentry.withScope((scope) => {
        scope.setExtra(EXTRA_RESPONSE, error.response?.data);
        Sentry.captureException(error);
      });
    } else {
      Sentry.captureException(error);
    }
  },

  captureMessage: (message: string, context?: CaptureContext) => {
    if (isProd) Sentry.captureMessage(message, context);
  },

  captureDebugMessage: (message: string, extra?: Extras) => {
    if (isProd) {
      Sentry.captureMessage(message, {
        level: Severity.Debug,
        extra,
      });
    } else {
      console.debug(message, extra);
    }
  },

  captureWarnMessage: (message: string, extra?: Extras) => {
    if (isProd) {
      Sentry.captureMessage(message, {
        level: Severity.Warning,
        extra,
      });
    } else {
      console.warn(message, extra);
    }
  },

  setUser: (user) => {
    if (isProd) Sentry.setUser(user);
  },

  configureScope: Sentry.configureScope,

  withScope: Sentry.withScope,
};
