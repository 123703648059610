import React from 'react';
import ContentEditable from 'react-contenteditable';

import styles from 'components/Forms/CreatePsychicNotificationForm/CreatePsychicNotificationForm.module.scss';

const TextBoxWithChips = ({
  selectedCount,
  inputRef,
  filterCustomer,
  changeHandler,
  onTextEntered,
  isLimitReach,
  isMobile,
}) => isMobile && (
  <div
    className={styles.searchBarContainer}
    onClick={onTextEntered}
    role="presentation"
  >
    {selectedCount && (
      <span className={styles.searchBarClientCout}>{`+${selectedCount}`}</span>
    )}
    { !isLimitReach && (
      <>
        <div>
          <ContentEditable
            className={styles.searchBarEditor}
            innerRef={inputRef}
            html={filterCustomer}
            onChange={changeHandler}
            onKeyPress={changeHandler}
          />
        </div>
        <button
          className={styles.searchBarAddButton}
          type="button"
          onClick={onTextEntered}
        >Add...
        </button>
      </>
    )}
  </div>
);

export default TextBoxWithChips;
