import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { InteractChatButton } from 'extracted-chat-components';
import { useSelector } from 'react-redux';
import { PopUpNotificationType } from 'extracted-chat-components/enums';

import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  ConversationType,
  View,
} from 'constants/enums';
// import ProvideETAButton from 'components/Buttons/ProvideETAButton/ProvideETAButton';
// import ReminderPopupContainer from 'components/Popups/ReminderPopup/ReminderPopupContainer';
// import { AvailableTime } from 'types/objectTypes';
import { getTimeRemaining } from 'src/utils/dateHandler';
import { selectIsDeclineOMEnabled, selectView } from 'selectors/selectors';
// import { IAppState } from 'store';
import {
  addPopUpNotification as _addPopUpNotification,
  // setIsVisibleReminderPopup as _setIsVisibleReminderPopup,
} from 'actions/appActions';
import {
  readOfflineMessageHandler as _readOfflineMessageHandler,
} from 'actions/offlineChatActions';
import useBindDispatch from 'src/hooks/useBindDispatch';

import styles from './styles.module.scss';

const DeclineOMHeaderButtonContent = () => (
  <div className={styles.declineOMButton}>
    <p>Decline Offline Message</p>
    <p>and ask for more info</p>
  </div>
);

const ChatOptionButtonGroup = ({
  currentChat,
  isLiveChatActive,
}) => {
  // const [availableTimeReminder, setAvailableTimeReminder] = useState<AvailableTime>();
  const view = useSelector(selectView) as View;
  const isDeclineOMEnabled = useSelector(selectIsDeclineOMEnabled);
  const readOfflineMessageHandler = useBindDispatch(_readOfflineMessageHandler);

  // const isShowReminderPopup =
  // useSelector((state: IAppState) => state.app.isVisibleReminderPopup);

  // const setIsVisibleReminderPopup = useBindDispatch(_setIsVisibleReminderPopup);
  const addPopUpNotification = useBindDispatch(_addPopUpNotification);

  const isPsychic = view === View.PSYCHIC;
  const isOfflineChat = currentChat.attributes?.requestType === ConversationType.DIRECT_MESSAGE;
  const isClosedOfflineChat = currentChat.attributes?.isClosed
    || currentChat.attributes?.isUserCanceled;

  const isCurrentOfflineChatNotStarted = !isLiveChatActive
    && currentChat.attributes?.requestType === ConversationType.DIRECT_MESSAGE
    && !currentChat.attributes?.isStarted
    && !currentChat.attributes?.isUserCanceled;

  const isLiveChatEnded = useMemo(() => !isLiveChatActive && !isOfflineChat,
    [isOfflineChat, isLiveChatActive]);

  const isCurrentOfflineChatWasAnsweredOrCanceled = useMemo(() => !isLiveChatActive
      && isOfflineChat
      && isClosedOfflineChat,
  [isClosedOfflineChat, isLiveChatActive, isOfflineChat]);

  // useEffect(() => {
  // const time = getTimeRemaining(currentChat?.directMessageExpiresAt!);
  // setAvailableTimeReminder(time);
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentChat?.directMessageExpiresAt) {
        // const time = getTimeRemaining(currentChat!.directMessageExpiresAt);
        // setAvailableTimeReminder(time);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [getTimeRemaining, currentChat]);

  const onAnswerOfflineMessageHandler = useCallback(() => {
    readOfflineMessageHandler(currentChat.chatId);
  }, [currentChat]);

  // const onToggleVisibleReminderPopup = useCallback(() => {
  //   setIsVisibleReminderPopup(!isShowReminderPopup);
  // }, [isShowReminderPopup]);

  const showDeclineOMPopUp = () => {
    addPopUpNotification({
      isVisible: true,
      title: 'decline OM',
      notificationType: PopUpNotificationType.DECLINE_OFFLINE_MESSAGE,
    });
  };

  if (!isPsychic
    || !isCurrentOfflineChatNotStarted
    || !(isLiveChatEnded || !isCurrentOfflineChatWasAnsweredOrCanceled)
  ) {
    return null;
  }

  if (isLiveChatEnded || isCurrentOfflineChatWasAnsweredOrCanceled) {
    return (
      <div className={styles.messageFormWrapper}>
        <div className={styles.messageFormWrapperButton} />
      </div>
    );
  }

  return (
    <div className={styles.optionsButtonWrapper}>
      <InteractChatButton
        text="Answer Offline Message"
        onClick={onAnswerOfflineMessageHandler}
        isActive
        color={ButtonColor.WARNING}
        size={ButtonSize.L}
        shape={ButtonShape.ROUNDED}
      />
      {isDeclineOMEnabled && (
        <>
          <p>Or</p>
          <InteractChatButton
            text={<DeclineOMHeaderButtonContent />}
            onClick={showDeclineOMPopUp}
            isActive
            color={ButtonColor.LIGHT_OUTLINE}
            size={ButtonSize.L}
            shape={ButtonShape.ROUNDED}
          />
        </>
      )}
      {/* TODO OA-371 Uncomment all of this Component if Reminder will be implemented */}
      {/* <ProvideETAButton */}
      {/*  onClick={onToggleVisibleReminderPopup} */}
      {/*  availableHours={availableTimeReminder?.hours} */}
      {/* /> */}
      {/* <ReminderPopupContainer */}
      {/*  isOpen={isShowReminderPopup} */}
      {/*  time={availableTimeReminder} */}
      {/*  onClose={onToggleVisibleReminderPopup} */}
      {/* /> */}
    </div>
  );
};

export default ChatOptionButtonGroup;
