import React from 'react';
import cn from 'classnames';

import ErrorIcon from 'src/assets/svg/error_Icon.svg';

import styles from './FlaggedClientNotesPopup.module.scss';

const FlaggedClientNotesPopup = ({ isVisible, popupInfo, className = '' }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={cn(styles.validationMessageWrapper, className)}>
      <img src={ErrorIcon} alt="errorIcon" />
      <span className={styles.validationMessage}>
        {popupInfo}
      </span>
    </div>
  );
};

export default FlaggedClientNotesPopup;
