import React from 'react';

import BackdropContainer from 'components/Backdrop';
import LoaderContainer from 'components/Loader/LoaderContainer';
import PopUpNotificationContainer from 'components/Popups/PopUpNotification/PopUpNotificationContainer';

import styles from './ClientHub.module.scss';

const ClientHub = ({ currentPage, children }) => (
  <div className={styles.wrapper}>
    <LoaderContainer withBackground />
    <BackdropContainer withBackground />
    <PopUpNotificationContainer />
    { children && children?.find((element) => element.key === currentPage) }
  </div>
);

export default ClientHub;
