import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PopUpNotificationType } from 'constants/enums';
import { IAppState } from 'store';
import { LoaderInterface } from 'types/componentTypes';
import { importAndRetryChunk } from 'src/utils/commonUtil';
import { MAX_RETRY_VALUE } from 'constants/constants';
import { selectIsLoading } from 'selectors/selectors';

let Loader: React.FC<LoaderInterface>;

const LoaderContainer = ({
  withBackground = false,
  isPsychic = true,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const isThinking = useSelector((state: IAppState) => state.app.isThinking);
  const notificationType = useSelector(
    (state: IAppState) => state.app.popUpNotificationData.notificationType,
  );
  const isError = useSelector((state: IAppState) => state.app.errorRequest.isError);

  const [isVisible, setIsVisible] = useState<boolean>(isLoading);
  const [isSideUser, setIsSideUser] = useState<boolean>(isPsychic);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    let isUnmounted = false;

    (async () => {
      const { default: loaded } = await importAndRetryChunk(
        () => import('src/components/Loader/Loader'),
        MAX_RETRY_VALUE,
        'Loader loading failed',
      );
      Loader = loaded;

      if (!isUnmounted) setIsLoaded(true);
    })();

    return () => { isUnmounted = true; };
  }, [setIsLoaded]);

  useEffect(() => {
    setIsVisible(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const notifications = [
      PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
      PopUpNotificationType.EXPIRED_TOKEN,
    ];
    const isNotification = notifications.includes(notificationType as PopUpNotificationType);

    setIsSideUser(!isPsychic && !isNotification && !isError);
  }, [isPsychic, notificationType, isError]);

  if ((!isLoaded && !Loader) || !isVisible) {
    return null;
  }

  return (
    <Loader
      isVisible={isVisible || isSideUser}
      isThinking={isThinking}
      withBackground={withBackground}
    />
  );
};

export default LoaderContainer;
