import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  MixpanelEvents, MixpanelUserTypes, PNOrderType, View,
} from 'constants/enums';
import {
  setCurrentUser as _setCurrentUser,
  deleteSideUser as _deleteSideUser,
} from 'actions/chatActions';
import {
  setNotesHistory as _setNotesHistory,
  updateClientNotes as _updateClientNotes,
  getReadingCustomersList as _getReadingCustomersList,
  getPsychicAllNotificationsList as _getPsychicAllNotificationsList,
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
} from 'actions/psychicNotifications';
import useBindDispatch from 'src/hooks/useBindDispatch';
import ClientHub from 'components/ClientHubWeb/ClientHub';
import { ONE_MINUTE, PSYCHIC_NOTE_TAB_ID, IS_MOBILE_INTEGRATION } from 'constants/constants';
import {
  selectCurrentUser, selectCustomerId, selectUserAgent, selectView,
} from 'selectors/selectors';
import { selectPsychicNoteConfigurableData, selectPsychicNotificationDetail } from 'selectors/psychicNotificationsSelectors';
import { applySortFilterForList, getComparator, useTable } from 'src/hooks/useTable';
import { getPlatform, simplyfyJsonArrayKeys } from 'src/utils/commonUtil';
import { API } from 'src/utils/api';
import PsychicNoteList from 'components/ClientHubWeb/PsychicNoteList';
import HeaderButtons from 'components/ClientHubWeb/HeaderButton';
import ClientNotesContainer from 'components/PsychicNotifications/ClientNotes';
import { useObtainCountryCodes } from 'src/hooks/useObtainCountryCodes';
import { LocalStorage } from 'src/utils/storageHandler';
import MixpanelActions from 'src/utils/mixpanel';

const ClientHubContainer = () => {
  const [currentPage, setCurrentPage] = useState<string>(PSYCHIC_NOTE_TAB_ID.ALL_NOTE);
  const [sortButtonName, setButtonName] = useState<string>('Newest');
  const [isHotJarLoaded, setHotJarScriptLoad] = useState<boolean>(false);

  const getPsychicAllNotificationsList = useBindDispatch(_getPsychicAllNotificationsList);
  const getReadingCustomersList = useBindDispatch(_getReadingCustomersList);
  const updateClientNotes = useBindDispatch(_updateClientNotes);
  const setNotesHistory = useBindDispatch(_setNotesHistory);
  const setCurrentUser = useBindDispatch(_setCurrentUser);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const deleteSideUser = useBindDispatch(_deleteSideUser);
  useObtainCountryCodes(View.CUSTOMER);

  const customerRefId = useSelector(selectCustomerId);
  const currentUser = useSelector(selectCurrentUser);
  const userAgent = useSelector(selectUserAgent);
  const { notificationList, customerList } = useSelector(selectPsychicNotificationDetail);
  const {
    psychicNotificationRefreshEveryMins,
    psychicNotesSystemMessages,
  } = useSelector(selectPsychicNoteConfigurableData);

  const viewMode = useSelector(selectView) as View;
  const isCustomer = viewMode === View.CUSTOMER;
  useEffect(() => {
    const isHotJarEnabled = process.env.IS_ENABLE_HOT_JAR;
    const hotJarID = process.env.HOT_JAR_ID;
    const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

    if (!isHotJarLoaded && isHotJarEnabled && isCustomer && !isMobileIntegration) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
      (function(h,o,t,j,a,r) {
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotJarID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
      document.body.appendChild(script);
      setHotJarScriptLoad(true);
    }
  }, [isHotJarLoaded]);

  useEffect(() => {
    (async () => {
      if (customerRefId && !Object.keys(currentUser).length) {
        const user = await API.Customer.getCustomerById(customerRefId as string);

        if (user) {
          const currentUserData = user.data;
          currentUserData.attributes = { participantType: View.CUSTOMER };
          currentUserData.friendlyName = currentUserData?.firstName;
          setCurrentUser(currentUserData);
        }
      }
    })();
  }, [customerRefId]);

  useEffect(() => {
    getPsychicAllNotificationsList();
    getReadingCustomersList();
    MixpanelActions.registerClientHub({
      userType: MixpanelUserTypes.EXISTING_CUSTOMER,
      chatSource: getPlatform(userAgent, 'mixpanel'),
    });
  }, []);

  useEffect(() => {
    const refreshIntervalId = setInterval(async () => {
      getPsychicAllNotificationsList(false);
      getReadingCustomersList(false);
    }, (ONE_MINUTE * psychicNotificationRefreshEveryMins));

    return () => clearInterval(refreshIntervalId);
  }, [psychicNotificationRefreshEveryMins]);

  const {
    order,
    orderBy,
    setOrderBy,
    setOrder,
  } = useTable({
    defaultOrderBy: 'sentDateUTC',
    defaultRowsPerPage: notificationList?.length,
    defaultOrder: PNOrderType.DESCENDING,
  });

  const tableData = applySortFilterForList({
    listData: notificationList,
    comparator: getComparator(order, orderBy),
  });

  const {
    customerMessageFaqUrl: customerMessageFAQUrl,
  } = simplyfyJsonArrayKeys(psychicNotesSystemMessages);

  const handlePsychicNoteScreens = (e) => {
    e.preventDefault();
    const { id } = e.currentTarget;

    if (id === PSYCHIC_NOTE_TAB_ID.ALL_NOTE) {
      getPsychicAllNotificationsList();
      getReadingCustomersList();
      updateClientNotes([]);
      setNotesHistory({
        customerNotesCount: 0,
        customerLastSendDateUTC: '',
        isClientAccessToPsychicNotes: true,
      });
      setNotificationCustomerDetail(null);
      deleteSideUser();
    }

    setCurrentPage(id);
  };

  const handleOnSort = (option) => {
    const dropdownValue = option.name
      .replace(/(Newest to Oldest|Oldest to Newest)/i, (match, p1) => {
        if (p1 === 'Newest to Oldest') {
          return 'newest first';
        }

        if (p1 === 'Oldest to Newest') {
          return 'oldest first';
        }

        return match;
      })
      .toLowerCase();

    MixpanelActions.track(MixpanelEvents.DROPDOWN_SELECTED, {
      dropdown_name: 'psychic notes sort',
      dropdown_value: dropdownValue,
      screen_title: 'inbox',
    });

    setOrder(option.order);
    setOrderBy(option.id);
    setButtonName(option.lable || option.name);
  };

  return (
    <ClientHub currentPage={currentPage}>
      <div key={PSYCHIC_NOTE_TAB_ID.ALL_NOTE} style={{ height: '100%' }}>
        <HeaderButtons
          customerList={customerList}
          handleOnSort={handleOnSort}
          handlePsychicNoteScreens={handlePsychicNoteScreens}
          customerMessageFAQUrl={customerMessageFAQUrl}
          notificationList={tableData}
          sortButtonName={sortButtonName}
        />
        <PsychicNoteList
          notificationList={tableData}
          handlePsychicNoteScreens={handlePsychicNoteScreens}
          customerMessageFAQUrl={customerMessageFAQUrl}
        />
      </div>
      <ClientNotesContainer
        handlePsychicNoteScreens={handlePsychicNoteScreens}
        key={PSYCHIC_NOTE_TAB_ID.CLIENT_CONVERSATION}
      />
    </ClientHub>
  );
};

export default ClientHubContainer;
