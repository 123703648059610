import React from 'react';

import styles from 'components/Buttons/ComposeNewButton/ComposeNewButton.module.scss';

const ComposeNewButton = ({ onClick }) => (
  <button
    className={styles.composeButton}
    type="button"
    onClick={onClick}
  >
    Compose New
  </button>
);
export default ComposeNewButton;
