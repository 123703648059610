import * as types from 'actions/actionsTypes';
import {
  InitAuthStateType,
  ReducerAction,
} from 'types/objectTypes';

const initialState: InitAuthStateType = {
  twilioToken: '',
  authToken: '',
  bearerToken: '',
};

const authReducer = (
  state: InitAuthStateType = initialState, { type, payload }: ReducerAction,
) => {
  switch (type) {
    case types.LOGIN_START:
    case types.LOGIN_SUCCESS: {
      return { ...state, user: payload };
    }
    case types.SET_TWILIO_TOKEN: {
      return { ...state, twilioToken: payload };
    }
    case types.SET_AUTH_TOKEN: {
      return { ...state, authToken: payload };
    }
    case types.SET_BEARER_TOKEN: {
      return { ...state, bearerToken: payload };
    }
    case types.CLEAR_SESSION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default authReducer;
