import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { API } from 'src/utils/api';
import {
  selectAuthToken,
  selectChatId,
} from 'selectors/selectors';
import {
  CHAT_CHANNEL_SID, CHAT_ID, CUSTOMER_ID, EXT_ID, TOKEN,
} from 'constants/constants';
import { LocalStorage } from 'src/utils/storageHandler';

const Logout = () => {
  const token = useSelector(selectAuthToken);
  const chatId = useSelector(selectChatId);

  const [isRemoved, setIsRemoved] = useState(false);

  if (chatId && token) {
    API.Chat.endChatRequest(chatId)
      .then(() => { setIsRemoved(true); })
      .catch(() => { setIsRemoved(true); });
  } else if (!isRemoved) {
    setIsRemoved(true);
  }

  if (isRemoved) {
    LocalStorage.removeItems([
      TOKEN,
      EXT_ID,
      CHAT_ID,
      CUSTOMER_ID,
      CHAT_CHANNEL_SID,
    ]);

    return <Redirect to="/" />;
  }

  return null;
};

export default Logout;
