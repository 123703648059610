import * as types from 'actions/actionsTypes';
import {
  ReducerAction,
  StatusToggleType,
} from 'types/objectTypes';

const initialState: StatusToggleType = {
  psychicStatusControl: {
    isInit: false,
    chatAndPhone: false,
    phoneOnly: false,
    chatOnly: false,
    isChatEnabled: false,
    offlineMessages: false,
    isDMEnabled: false,
    isOnCall: false,
    isOnChat: false,
  },
  onBreak: {
    command: '',
    breakEnds: 0,
  },
};

const statusToggleReducer = (
  state: StatusToggleType = initialState,
  { type, payload }: ReducerAction,
): StatusToggleType => {
  switch (type) {
    case types.UPDATE_CHAT_AND_PHONE_STATUS: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isInit: true,
          chatAndPhone: payload,
        },
      };
    }
    case types.UPDATE_PHONE_ONLY_STATUS: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isInit: true,
          phoneOnly: payload,
        },
      };
    }
    case types.UPDATE_CHAT_ONLY: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isInit: true,
          chatOnly: payload,
        },
      };
    }
    case types.UPDATE_CHAT_ENABLED: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isChatEnabled: payload,
        },
      };
    }
    case types.UPDATE_OFFLINE_MESSAGES_STATUS: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          offlineMessages: payload,
        },
      };
    }
    case types.UPDATE_OFFLINE_MESSAGES_AVAILABILITY: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isDMEnabled: payload,
        },
      };
    }
    case types.UPDATE_BREAK: {
      return {
        ...state,
        onBreak: {
          ...state.onBreak,
          breakEnds: payload.breakEnds,
          isDelayed: payload.isDelayed,
          command: payload.command,
        },
      };
    }
    case types.UPDATE_IS_ON_CALL: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isOnCall: payload,
        },
      };
    }
    case types.UPDATE_IS_ON_CHAT: {
      return {
        ...state,
        psychicStatusControl: {
          ...state.psychicStatusControl,
          isOnChat: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default statusToggleReducer;
