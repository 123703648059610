import { legacy_createStore as createStore, Store, compose } from 'redux';
import * as Sentry from '@sentry/react';

import rootReducer from 'src/store/rootReducer';
import { IAction, IAppState } from 'src/store/models';
import enhancer, { sagaMiddleware } from 'src/store/enhancer';
import rootSaga from 'src/redux/sagas/sagas';
import { filterActions, filterFieldsFromState } from 'src/utils/sentryMethods';

export type TAppStore = Store<IAppState>

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: filterFieldsFromState,
  actionTransformer: filterActions,
});

const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  compose(enhancer, sentryReduxEnhancer),
);

sagaMiddleware.run(rootSaga);

export default store;
