import React, { useCallback } from 'react';
import cn from 'classnames';
import { uniqueId } from 'lodash';

import { ToggleTypes } from 'constants/enums';
import { IToggle } from 'types/componentTypes';
import style from 'components/Toggles/Toggle.module.scss';

const Toggle: React.FC<IToggle> = ({
  title,
  isChecked,
  toggleType,
  onChange,
  isBusy = false,
}) => {
  const inputId = uniqueId();
  const isOnlineToggleType = toggleType === ToggleTypes.ONLINE_OFFLINE;
  const sliderStatus = {
    on: isBusy ? 'Busy' : 'Online',
    off: isBusy ? 'Busy' : 'Offline',
  };
  const sliderTitleType = {
    on: isOnlineToggleType ? sliderStatus.on : 'Enabled',
    off: isOnlineToggleType ? sliderStatus.off : 'Disabled',
  };
  const onlineCn = cn(isBusy ? style.busy : style.online);
  const sliderTitle = isChecked ? sliderTitleType.on : sliderTitleType.off;
  const sliderCn = cn(
    isOnlineToggleType ? style.slider : style.sliderEnabledDisabled,
    isChecked ? onlineCn : style.offline,
  );
  const sliderInner = cn(
    style.sliderInnerContent,
    isChecked ? style.textOnline : style.textOffline,
  );

  const onToggle = useCallback((value) => {
    onChange(!value);
  }, [onChange]);

  return (
    <div className={style.toggleContainer}>
      <div
        className={cn(
          style.toggle,
          { [style.offlineToggleTitle]: !isOnlineToggleType },
        )}
      >
        {title}
      </div>
      <div className={style.sliderContainer}>
        <label htmlFor={inputId} className={style.toggleButton}>
          <input
            checked={isChecked}
            type="checkbox"
            id={inputId}
            onChange={() => onToggle(isChecked)}
          />
          <span className={sliderCn}>
            <div className={sliderInner}>{sliderTitle}</div>
          </span>
        </label>
      </div>
    </div>
  );
};

export default React.memo(Toggle);
