import mixpanel from 'mixpanel-browser';

import { CHAT_PLATFORM } from 'constants/constants';
import { Environment } from 'constants/enums';
import { getCustomerIdentity, deepLowerCase } from 'src/utils/commonUtil';

const isProd = process.env.NODE_ENV === Environment.PRODUCTION;
const MixpanelActions = {
  init: () => {
    if (isProd) mixpanel.init(process.env.MIXPANEL_TOKEN);
  },

  register: async ({
    extId,
    chatSource,
    userType,
    friendlyName,
    customerPrice,
  }) => {
    if (isProd) {
      mixpanel.register(deepLowerCase({
        psychic_id: extId,
        psychic_name: friendlyName,
        psychic_rate: customerPrice,
        customerId: await getCustomerIdentity(),
        chat_platform: CHAT_PLATFORM,
        chat_source: chatSource,
        user_type: userType,
      }));
    }
  },

  registerClientHub: async ({
    chatSource,
    userType,
  }) => {
    if (isProd) {
      mixpanel.register(deepLowerCase({
        customerId: await getCustomerIdentity(),
        chat_platform: CHAT_PLATFORM,
        chat_source: chatSource,
        user_type: userType,
      }));
    }
  },

  identify: (id) => {
    if (isProd) mixpanel.identify(id);
  },

  alias: (id) => {
    if (isProd) mixpanel.alias(id);
  },

  track: async (name, props = {}, distinctId = undefined) => {
    const lowerCaseProps = deepLowerCase(props);

    if (isProd) {
      mixpanel.track(name, {
        ...lowerCaseProps,
        distinct_id: distinctId || await getCustomerIdentity(),
      });
    }
  },

  people: {
    set: (props) => {
      if (isProd) mixpanel.people.set(props);
    },
  },
};

export default MixpanelActions;
