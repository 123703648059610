export const tapAndHold = (elementRef, callback, HoldDuration, isActive) => {
  let timerId;

  const onMouseDown = () => {
    timerId = setTimeout(() => {
      if (elementRef && isActive) {
        callback();
      }
    }, HoldDuration);
  };

  const clearTimer = () => timerId && clearInterval(timerId);

  elementRef.addEventListener('mousedown', onMouseDown);
  elementRef.addEventListener('touchstart', onMouseDown);
  elementRef.addEventListener('mouseup', clearTimer);
  elementRef.addEventListener('mouseout', clearTimer);
  elementRef.addEventListener('touchend', clearTimer);

  return () => {
    elementRef.removeEventListener('mousedown', onMouseDown);
    elementRef.removeEventListener('touchstart', onMouseDown);
    elementRef.removeEventListener('mouseup', clearTimer);
    elementRef.removeEventListener('mouseout', clearTimer);
    elementRef.removeEventListener('touchend', clearTimer);
  };
};
