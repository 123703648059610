import React from 'react';
import { Card } from '@mui/material';

import { IConfirmationScreenPopupContainer } from 'types/componentTypes';
import NoteSentPopup from 'components/Popups/ConfirmationScreenPopup/NoteSentPopup';
import ConfirmationScreenPopup from 'components/Popups/ConfirmationScreenPopup/ConfirmationScreenPopup';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setNotificationCustomerDetail as _setNotificationCustomerDetail,
  getClientNoteHistory as _getClientNoteHistory,
  setCurrentPCNTab as _setCurrentPCNTab,
  updateClientNotes as _updateClientNotes,
} from 'actions/psychicNotifications';
import { PNTabs } from 'constants/enums';
import styles from 'components/Popups/ConfirmationScreenPopup/ConfirmationScreenPopup.module.scss';

const ConfirmationScreenPopupContainer: React.FC<IConfirmationScreenPopupContainer> = ({
  isConfirmationScreenVisible,
  handleReturnButton,
  handleComposeNewButton,
  handlePsychicNoteScreens,
  customerName,
  nextPriorityAction,
}) => {
  const getClientNoteHistory = useBindDispatch(_getClientNoteHistory);
  const setNotificationCustomerDetail = useBindDispatch(_setNotificationCustomerDetail);
  const setCurrentPCNTab = useBindDispatch(_setCurrentPCNTab);
  const updateClientNotes = useBindDispatch(_updateClientNotes);

  const client = {
    ...nextPriorityAction,
    lastReadingDate: nextPriorityAction?.readingDateUTC,
    displayname: nextPriorityAction?.customerDisplayName,
    customerType: nextPriorityAction?.clientType,
  };

  const handleSendNoteToPriorityClient = (e) => {
    const pCComID = nextPriorityAction && nextPriorityAction?.pCComID;
    updateClientNotes([]);
    getClientNoteHistory(pCComID);
    handlePsychicNoteScreens(e);
    setNotificationCustomerDetail([client]);
    handleComposeNewButton(e);
    setCurrentPCNTab(PNTabs.PRIORITY_ACTION_TAB);
  };

  if (!isConfirmationScreenVisible) {
    return null;
  }

  const handleOnClickOutside = (e) => {
    if (!nextPriorityAction) {
      handleComposeNewButton(e);
    }
  };

  return (
    <Card className={styles.container} onClick={handleOnClickOutside}>
      {!!nextPriorityAction ? (
        <NoteSentPopup
          handleReturnButton={handleReturnButton}
          handleComposeNewButton={handleComposeNewButton}
          handleSendNoteToPriorityClient={handleSendNoteToPriorityClient}
          nextPriorityAction={nextPriorityAction}
          customerName={customerName}
        />
      ) : (
        <ConfirmationScreenPopup
          handleReturnButton={handleReturnButton}
          handleComposeNewButton={handleComposeNewButton}
          customerName={customerName}
        />
      )}
    </Card>
  );
};

export default ConfirmationScreenPopupContainer;
