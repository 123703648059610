import React from 'react';
import cn from 'classnames';

import { reactionsMap } from 'src/utils/reactionImagesUtils';
import styles from 'components/PsychicNotifications/StatsView/NotificationStatistics.module.scss';

const ReactionCount = ({ reaction, isGamifyEnabled }) => {
  const icon = reactionsMap.get(reaction.reactionType.toLocaleLowerCase());

  return (
    <div
      className={styles.reactionsWrapper}
      key={reaction.reactionType}
    >
      <img
        src={icon}
        className={cn(styles.reactionsIcon, { [styles.reactIcon]: isGamifyEnabled })}
        alt={reaction.reactionType}
      />
      <span className={cn({ [styles.badge]: isGamifyEnabled })}>
        {reaction.reactionCount || 0}
      </span>
    </div>
  );
};

export default ReactionCount;
