import { all, spawn } from 'redux-saga/effects';

import * as chatSagas from 'src/redux/sagas/chat/chatSaga';
import * as offlineChatSagas from 'src/redux/sagas/offlineChat/offlineChatSaga';
import * as authSagas from 'src/redux/sagas/authSaga';
import * as appSagas from 'src/redux/sagas/app/appSaga';
import * as sdkSagas from 'src/redux/sagas/sdkSaga';
import { reminderSagaWatcher } from 'src/redux/sagas/reminderSaga';
import { setSideUserSagaWatcher } from 'src/redux/sagas/app/setSideUserSaga';
import { statusToggleSagas } from 'src/redux/sagas/statusToggle';
import { switchToPhoneSagaWatcher } from 'src/redux/sagas/switchToPhoneSaga';
import { psychicNotificationsSagaWatcher } from 'src/redux/sagas/psychicNotifications/psychicNotificationsSaga';

export default function* rootSaga() {
  yield all([
    ...Object.values(chatSagas),
    ...Object.values(offlineChatSagas),
    ...Object.values(authSagas),
    ...Object.values(appSagas),
    statusToggleSagas,
    reminderSagaWatcher,
    switchToPhoneSagaWatcher,
    psychicNotificationsSagaWatcher,
    setSideUserSagaWatcher,
    ...Object.values(sdkSagas),
  ].map(spawn));
}
