import { PopUpNotificationType, Status } from 'constants/enums';

export * from 'extracted-chat-components/constants';

export const REDIRECT_PATH = 'redirectPath';
export const CHAT_PLATFORM = 'chat2.0';
export const EXTRA_RESPONSE = 'Response';
export const MAX_BREADCRUMBS = 50;
export const NORMALIZE_DEPTH = 5;
export const URL_TO_NATIVE_APP = 'urlToNativeApp';
export const IS_MOBILE_INTEGRATION = 'isMobileIntegration';
export const IS_GAMIFY_ANIMATION_SEEN = 'isGamifyAnimationSeen';
export const IS_PRIORITY_TOOLTIP_SEEN = 'isPriorityTooltipSeen';
export const CHAT_PLATFORM_QUERY = 'platform';
export const ERROR_DUE_TO_LOW_FUNDS = 1001;
export const NC_FLOW = 'nc-flow';
export const FIFTEEN_HOURS_IN_SECONDS = 54000;
export const DEBUG_MODE = 'debugMode';
export const MAX_RETRY_VALUE = 2;
export const THROTTLE_KEYPRESS_DELAY = 10000;
export const THROTTLE_INACTIVITY_DELAY = 5000;
export const INITIAL_CHAT_TIMER_VALUE = 6000;
export const SENTRY_POPUP_TAG = 'popup';
export const LATEST_NOTIFICATION_INDEX = 'latestNotificationIndex';
export const FIRST_TWILIO_SYSTEM_NOTIFICATION = 1;
export const FIVE_MINUTES = 300000;
export const UPCOMING_APPOINTMENT_REMINDER_TIME = FIVE_MINUTES;
export const TWO_MINUTES = 120000;
export const TEN_SECONDS = 10000;
export const THIRTY_SECONDS_IN_MILLIS = 30000;
export const PSYCHIC_PHONE_STATUS = 'psychicPhoneStatus';
export const MAX_PEOPLE_IN_QUEUE = 6;
export const ONE_MINUTE = TWO_MINUTES / 2;
export const CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT = 12;
export const CLIENT_NOTES_CUSTOMER_NAME_STRING_LIMIT_ON_NOTES_LIST = 10;
export const PSYCHIC_NAME_STRING_LIMIT_ON_CLIENT_SIDE = 7;
export const IS_CHAT_END_DUE_TO_LOW_FUNDS = 'isChatEndDueToLowFunds';
export const SHOW_AUTO_RELOAD_HEADER = 'showAutoReloadHeader';
export const CHAT_REDIRECTION_POPUP_TIMEOUT = 10000;

export const PSYCHIC_UNAVAILABLE_ERROR_CODE = 101;
export const CUSTOMER_IS_BUSY_ERROR_CODE = 105;
export const SCREEN_VIEWED_MIXPANEL = {
  screen_name: 'chat connecting screen',
  screen_title: 'chat connecting screen',
};
export const MINIMUM_CHAT_DURATION = 5;
export const LOW_FUNDS_TIME = 2;
export const INLINE_NOTIFICATION = 'inlineNotification';
export const INLINE_NOTIFICATION_INDEX = 'inlineNotificationIndex';
export const INLINE_NOTIFICATION_TIMESTAMP = 'inlineNotificationTimestamp';
export const MOBILE_MAX_WIDTH = 740;
export const SUPPORT_PHONE_NUMBER = '+1 (800) 230-1300';
export const NOTIFICATION_DRAFT_KEY = 'draftedNotification';
export const CUSTOMER_DETAILS_DRAFT_KEY = 'draftedCustomerDetails';
export const CLIENT_NOTES_DRAFT_KEY = 'draftedClientNote';
export const DRAFTED_CLIENT_NAME_KEY = 'draftedClientName';
export const PRIORITY_ACTION_COMPLETED_KEY = 'isPsycCompPrioAct';
export const REFRESH_UNREAD_REACTION_INTERVAL = FIVE_MINUTES / 10;
export const EMAIL_REGEX = /[\w._%-]+[\s._-]{0,2}@[\s._-]{0,2}[\w._-]+[\s._-]{0,2}\.[\s._-]{0,2}[\w]{2,}/;
export const PHONE_REGEX = /(\+)?([\s_()+-{}]?\d[\s_()+-.]?){10,}/;
export const ANCHOR_REGEX = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/;
export const ROWS_PER_PAGE_OPTION = [1, 5, 10, 25];
export const MINIMUM_MESSAGE_NOTIFICATION_LENGTH = 10;
export const PASSWORD_STORAGE = 'password';
export const LISTING_VIEW_TABLE_HEAD = {
  NOTIFICATION_LIST: [
    { columnId: 'displayname', label: 'Name', align: 'left' },
    { columnId: 'zodiacSign', label: 'Zodiac', align: 'center' },
    { columnId: 'sentDateUTC', label: 'Time/Date', align: 'center' },
    { columnId: 'newButton', label: '', align: 'center' },
    { columnId: 'messageBody', label: 'Communication History', align: 'left' },
    { columnId: 'lastReadingDateUTC', label: 'Last Reading', align: 'center' },
    { columnId: 'rightArrow', label: '', align: 'center' },
  ],
  CUSTOMER_LIST: [
    { columnId: 'displayname', label: 'Name', align: 'left' },
    { columnId: 'customerType', label: 'Identifier', align: 'center' },
    { columnId: 'lastReadingDate', label: 'Last Reading', align: 'center' },
    { columnId: 'readingType', label: 'Reading Type', align: 'center' },
    { columnId: 'zodiacSign', label: 'Zodiac', align: 'center' },
    { columnID: 'labels', label: 'Labels', align: 'left' },
  ],
  CUSTOMER_LIST_MOBILE: [
    { columnId: 'displayname', label: 'Name', align: 'left' },
    { columnId: 'lastReadingDate', label: 'Last Reading', align: 'center' },
    { columnId: 'zodiacSign', label: ' ', align: 'center' },
  ],
  PRIORITY_ACTION_LIST: [
    { columnId: 'clientType', label: 'Client Type', align: 'center' },
    { columnId: 'lastReadingDate', label: 'Last Reading', align: 'left' },
    { columnId: 'actionItem', label: 'Action Item', align: 'left' },
    { columnId: 'empty', label: '', align: 'center' },
  ],
};
export const MESSAGE_TEMPLATES = [
  {
    keyId: 'message-template-1',
    value: 'Thank you for allowing me to read for you. I hope you\'ve found the answers you were seeking. If you have additional questions on the topic we discussed, I would be happy to connect with you again to continue our previous session.',
    mixPanelText: 'template 1 - thank you for the reading',
  },
  {
    keyId: 'message-template-2',
    value: 'My guides have recently given me more information I would like to share with you.',
    mixPanelText: 'template 2 - my schedule has changed recently',
  },
  {
    keyId: 'message-template-3',
    value: 'I wanted to personally let you know that I will be away and unable to connect with you for a few days. As a preferred client, I will ensure my schedule is updated for you upon my return.',
    mixPanelText: 'template 3 - i’ll be online tomorrow',
  },
  {
    keyId: 'message-template-4',
    value: 'Unfortunately, there has been a change in my schedule. I will make sure to update my bio with more information as soon as possible.',
    mixPanelText: 'template 4 - I was thinking about you recently',
  },
];

export const LOGIN_FIELDS_NAME = {
  EMAIL: 'email',
  PASSWORD: 'password',
  VIEW: 'view',
};

export const PSYCHICS_OPTIONS = [
  { name: 'Rika', id: '5376' },
  { name: 'Kelli', id: '5130' },
  { name: 'William', id: '5131' },
  { name: 'Luciana', id: '5719' },
  { name: 'Madeline', id: '5019' },
  { name: 'Raziel', id: '5027' },
  { name: 'Frederica', id: '6532' },
  { name: 'Yemaya', id: '5143' },
  { name: 'Zana', id: '6463' },
  { name: 'Zariya', id: '6009' },
  { name: 'Dolly', id: '6767' },
  { name: 'Davina', id: '5881' },
  { name: 'Devyn', id: '5301' },
  { name: 'Dezi', id: '5227' },
  { name: 'Vali', id: '5062' },
];

export const PAGE_ID = {
  CHATS_PAGE: 'Chats, OM & Notifications',
  DASHBOARD_PAGE: 'Dashboard',
  SETTINGS_PAGE: 'Edit Settings',
  REPORT_ISSUE: 'Report an Issue',
  MANAGE_SCHEDULES: 'Manage Schedules',
  PSYCHIC_INBOX: 'Psychic Inbox',
};

export const CHAT_TABS_ID = {
  MY_STATUS: 'myStatus',
  LIVE_CHAT: 'liveChat',
  OFFLINE_MESSAGE: 'offlineMessage',
  PSYCHIC_NOTIFICATION: 'psychicNotifications',
};

export const MOBILE_PAGE_ID = {
  CHATS_PAGE: 'Chats, OM & Notifications',
  PSYCHIC_INBOX: 'Psychic Inbox',
  EMPTY_PAGE: 'Empty page',
};

export const CHAT_TABS = [
  {
    id: CHAT_TABS_ID.MY_STATUS,
    title: 'My Status',
    iconPrefix: 'handStatusIcon',
  },
  {
    id: CHAT_TABS_ID.LIVE_CHAT,
    title: 'Live Chat',
    iconPrefix: 'liveChatIcon',
  },
  {
    id: CHAT_TABS_ID.OFFLINE_MESSAGE,
    title: 'Offline Message',
    iconPrefix: 'offlineMessageIcon',
  },
];

export const SETTING_TABS_ID = {
  // SETTINGS: 'Settings',
  RUN_DIAGNOSTIC: 'Run Diagnostic',
};

export const SETTING_TABS = [
  // {
  //   id: SETTING_TABS_ID.SETTINGS,
  //   title: 'Psychic Notification Options',
  //   iconPrefix: 'notificationIcon',
  // },
  {
    id: SETTING_TABS_ID.RUN_DIAGNOSTIC,
    title: 'Run Diagnostic',
    iconPrefix: 'runDiagnosticIcon',
  },
];

export const HOTLINE_NUMBER = [
  '18002738255',
  '8002738255',
  '8774773646',
  '8883757767',
  '6308199505',
  '2127141141',
  '8002467743',
  '8882347243',
  '8664887386',
  '8008435678',
  '8006564673',
  '8004483000',
  '8007997233',
  '8008528336',
  '8004424673',
  '8002738255',
  '800273988',
  '0014074310205',
  '8007857667',
  '8007733428',
  '118002738255',
  '18002738255',
  '18774773646',
  '18883757767',
  '16308199505',
  '12127141141',
  '18002467743',
  '18882347243',
  '18664887386',
  '18008435678',
  '18006564673',
  '18004483000',
  '18007997233',
  '18008528336',
  '18004424673',
  '18002738255',
  '1800273988',
  '10014074310205',
  '18007857667',
  '18007733428',
];
export const DECLINE_MESSAGE_LENGTH = 150;
export const OPTIONS_FOR_REMINDER = [
  { value: 1, label: '1 hr', id: 1 },
  { value: undefined, label: '', id: 2 },
  { value: undefined, label: '', id: 3 },
  { value: 4, label: '4 hrs', id: 4 },
  { value: undefined, label: '', id: 5 },
  { value: undefined, label: '', id: 6 },
  { value: undefined, label: '', id: 7 },
  { value: 8, label: '8 hrs', id: 8 },
  { value: undefined, label: '', id: 9 },
  { value: 10, label: '10 hrs', id: 10 },
];

export const POPUP_NOTIFICATION_EXCEPTION = new Set([
  PopUpNotificationType.NOT_AVAILABLE_PSYCHIC,
  PopUpNotificationType.DUE_TO_INACTIVITY,
]);

export const LOGIN_CALENDER_IFRAME_KEY = 'iframeCalenderLogin';
export const LOGOUT_CALENDER_IFRAME_KEY = 'iframeCalenderLogout';
export const INACTIVE_CHAT_STATUSES = [
  Status.EXPIRED,
  Status.COMPLETED,
  Status.CANCELLED,
  Status.INSUFFICIENT_FUNDS,
];

export const PSYCHIC_NOTE_TAB_ID = {
  ALL_NOTE: 'allNote',
  COMPOSE: 'compose',
  CLIENT_CONVERSATION: 'clientConversation',
};
export const WEEKDAY = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
export const TIP_BANNER_MESSAGES = ['Relax & Breathe', 'Meditate on your questions', 'Focus on your question'];
export const PSYCHIC_LIST_SORT_OPTIONS = [{
  name: 'Newest to Oldest',
  lable: 'Newest',
  id: 'sentDateUTC',
  order: 'desc',
},
{
  name: 'Oldest to Newest',
  lable: 'Oldest',
  id: 'sentDateUTC',
  order: 'asc',
},
{
  name: 'A - Z',
  id: 'lineName',
  order: 'asc',
},
{
  name: 'Z - A',
  id: 'lineName',
  order: 'desc',
},
{
  name: 'Unread',
  id: 'isRead',
  order: 'asc',
}];

export const PYSCHIC_OPTED_OUT_MESSAGE = 'Psychic PsychicName is opting out of notes at this time';
export const DEFAULT_MAX_CLIENTS_LIMIT = 5;
export const PRIORITY_ACTION_WIDGET_TEXT = 'You have XX Priority Actions';
export const PriorityActionLabel = {
  DONE: 'You’ve followed up with all your \nPriority Actions!',
  EMPTY: 'You don’t have any Priority Actions, \nplease check back later.',
  ALMOST_DONE: 'You’ve only got XX notes to go!',
  CTA: 'Load All [XX] Priority Actions',
};
export const PRIORITY_ACTION_CHARACTER_LIMIT = 105;
export const CLIENT_HUB_DEFAULT_TAB_KEY = 'clientHubDefaultKey';
export const IS_LOAD_MORE_BOTTON_CLICKED_KEY = 'isLoadMoreBottonClicked';
export const ChatErrors = [
  PSYCHIC_UNAVAILABLE_ERROR_CODE,
  CUSTOMER_IS_BUSY_ERROR_CODE,
];
