import React from 'react';
import cn from 'classnames';

import styles from './HeaderButton.module.scss';

const HeaderButton = ({
  text,
  classesName = '',
  icon = '',
  isLeft = true,
  isVisible = true,
  onClick = () => {},
  onBlur = () => {},
  buttonRef,
  children = <></>,
}) => {
  if (isVisible) {
    return (
      <div className={styles.container}>
        <button
          type="button"
          className={cn(styles.wrapper, classesName)}
          onClick={onClick}
          ref={buttonRef}
          onBlur={onBlur}
        >
          {isLeft && <div>{icon}</div>}
          {text}
          {!isLeft && <div>{icon}</div>}
        </button>
        {children}
      </div>
    );
  }

  return null;
};

export default HeaderButton;
