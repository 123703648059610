import { useEffect } from 'react';

import { ChatRequest, InternetConnectionEvents } from 'constants/enums';
import { CHAT_REMAINING_TIME, DIFFERENCE_IN_TIME } from 'constants/constants';
import { calculateDateDiffSeconds } from 'src/utils/dateHandler';
import { LocalStorage } from 'src/utils/storageHandler';
import { findLastElementIndex } from 'src/utils/helpers';

const commonChatRequest = (action: Function, notifications, internetConnectingStatus) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (internetConnectingStatus === InternetConnectionEvents.OFFLINE) {
      return;
    }

    if (notifications?.length > 0) {
      const lastNotification = notifications[notifications.length - 1];
      action(lastNotification.attributes, lastNotification.timestamp);
    }
  }, [notifications, internetConnectingStatus]);
};

export const useChatCreatedRequest = (
  action: Function,
  notifications,
  internetConnectingStatus,
) => {
  commonChatRequest((attributes) => {
    if (!attributes.expiresDate) return;

    const diffTime = Number(LocalStorage.getItem(DIFFERENCE_IN_TIME));
    const currentTime = new Date();
    const correctedTime = currentTime.setSeconds(currentTime.getSeconds() - diffTime);

    const timeDifference: number = calculateDateDiffSeconds(
      new Date(attributes.expiresDate), new Date(correctedTime),
    );

    if (!timeDifference) return;

    const intTimeDifference = (CHAT_REMAINING_TIME > timeDifference)
      ? Math.round(timeDifference)
      : CHAT_REMAINING_TIME;

    if (attributes.type === ChatRequest.CHAT_REQUEST_CREATED
      && intTimeDifference > 0) {
      action(attributes, intTimeDifference);
    }
  }, notifications, internetConnectingStatus);
};

const checkIsLastChatInteractionWasMissing = (action: Function, notifications: Array<any>) => {
  const missChatNotificationIndex = findLastElementIndex(
    notifications,
    (notification) => notification?.attributes?.type === ChatRequest.CHAT_REQUEST_EXPIRED,
  );
  const acceptChatNotificationIndex = findLastElementIndex(
    notifications,
    (notification) => notification?.attributes?.type === ChatRequest.CHAT_REQUEST_ACCEPTED,
  );

  if (missChatNotificationIndex < 0 || missChatNotificationIndex < 0) return;

  const missChatNotification = notifications[missChatNotificationIndex];

  if (missChatNotification.attributes?.isSeen) return;

  if (acceptChatNotificationIndex !== undefined) {
    const isMissedChatRequest = (acceptChatNotificationIndex - missChatNotificationIndex) < 0;

    if (isMissedChatRequest) {
      action(missChatNotification.attributes, missChatNotification.index);
    }
  } else {
    action(missChatNotification.attributes, missChatNotification.index);
  }
};

export const useMissChatRequest = (action: Function, notifications: Array<any>) => {
  useEffect(() => {
    if (notifications?.length <= 0) return;

    const lastNotification = notifications[notifications.length - 1];

    if (lastNotification.attributes?.type === ChatRequest.CHAT_REQUEST_EXPIRED) {
      if (lastNotification.attributes?.isSeen) return;

      action(lastNotification.attributes, lastNotification.index);
    } else {
      checkIsLastChatInteractionWasMissing(action, notifications);
    }
  }, [notifications]);
};

export const useRestoreNotification = (isVisibleNotification: boolean) => {
  useEffect(() => {
    const isVisible = isVisibleNotification || '';

    if (!isVisible) {
      LocalStorage.removeItems([
        'notificationType',
        'description',
        'title',
      ]);
    }

    LocalStorage.setItem('isVisibleNotification', isVisible as string);
  }, [isVisibleNotification]);
};
