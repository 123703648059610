import * as types from 'actions/actionsTypes';

/**
 * @param store - Application store
 * @param actionTypes - array of actions we are going to filter out when connection is down.
 */

const bannedActions = [
  types.SET_PSYCHIC_STATUS,
];

export const offlineMiddleware = (store) => (next) => (action) => {
  const isInternetConnectionOffline = store.getState().app.internetConnectingStatus === 'offline';
  const middlewareCondition = isInternetConnectionOffline && bannedActions.includes(action.type);

  if (middlewareCondition) return;

  next(action);
};
