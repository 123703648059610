import React from 'react';
import cn from 'classnames';

import { IEmptyStateBanner } from 'types/componentTypes';

import styles from './EmptyStateBanner.module.scss';

const EmptyStateBanner: React.FC<IEmptyStateBanner> = ({
  labelHead = '',
  labelText = '',
  emoji = '',
  classNames = {},
}) => {
  const defaultClassNames = {
    container: '',
    emoji: '',
    label: '',
  };

  const combinedClassNames = { ...defaultClassNames, ...classNames };

  return (
    <div className={cn(styles.emptySate, combinedClassNames?.container)}>
      {emoji
      && (<span className={cn(styles.emptySateEmoji, combinedClassNames.emoji)}>{emoji}</span>)}
      <div className={styles.emptySateWrapper}>
        {labelHead && (<span className={styles.emptySateHead}>{labelHead}</span>)}
        {labelText
      && (
        <span
          className={cn(styles.emptySateLabel, combinedClassNames.label)}
        >
          {labelText}
        </span>
      )}
      </div>
    </div>
  );
};

export default EmptyStateBanner;
