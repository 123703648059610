import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from 'reducers/app';
import chatReducer from 'reducers/chat';
import authReducer from 'reducers/auth';
import diagnosticReducer from 'reducers/diagnostic';
import statusToggleReducer from 'reducers/statusToggle';
import switchToPhoneReducer from 'reducers/switchToPhone';
import psychicNotificationsReducer from 'reducers/psychicNotifications';
import { IAppState } from 'src/store/models';
import reminderOptionsReducer from 'reducers/reminderOptionsReducer';

const persistConfig = {
  key: 'switchToPhone',
  storage,
};

const persistedSwitchToPhoneReducer = persistReducer(persistConfig, switchToPhoneReducer);

const rootReducer = combineReducers<IAppState>({
  router: routerReducer,
  app: appReducer,
  chat: chatReducer,
  auth: authReducer,
  statusToggle: statusToggleReducer,
  switchToPhone: persistedSwitchToPhoneReducer,
  diagnostic: diagnosticReducer,
  psychicNotification: psychicNotificationsReducer,
  reminderOptions: reminderOptionsReducer,
});

export default rootReducer;
