import { InternetConnectionEvents } from 'extracted-chat-components/enums';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import InternetCheckerWorker from 'src/workers/InternetCheckerWorker';
import {
  checkMissedNotifFromSystemChannel,
  createNewTwilioClient,
  styles,
} from 'src/utils/twilio';
import { InternetCheckerMessage } from 'constants/enums';
import { SentryMethods } from 'src/utils/sentryMethods';
import { descriptionFromError } from 'src/utils/commonUtil';
import { IAppState } from 'store';
import useBindDispatch from 'src/hooks/useBindDispatch';
import {
  setInternetConnectingStatus as _setInternetConnectingStatus,
  restoreTwilioClient as _restoreTwilioClient,
} from 'actions/appActions';

const internetCheckerWorker = new Worker(InternetCheckerWorker);
const attemptsLimit = Number(process.env.TWILIO_CHANNEL_CHECK_ATTEMPTS);

export const useInternetChecker = (
  twilioClient,
  viewMode,
  handleRequestError,
) => {
  const [missedNotificationsAmount, setMissedNotificationsAmount] = useState<number>(0);
  const internetConnectingStatus = useSelector(
    (state: IAppState) => state.app.internetConnectingStatus,
  );
  const systemChannel = useSelector((state: IAppState) => state.chat.systemChannel);
  const channelSid = useSelector((state: IAppState) => state.chat.currentChat.chatChannelSid);
  const setInternetConnectingStatus = useBindDispatch(_setInternetConnectingStatus);
  const isCheckRef = useRef<boolean>(false);
  const restoreTwilioClient = useBindDispatch(_restoreTwilioClient);

  const createNotificationCheckerWithInterval = (
    _systemChannel,
    twilioClient,
    attempts = 1,
  ) => {
    const attemptsInterval = Number(process.env.TWILIO_CHANNEL_CHECK_INTERVAL) || 2000;

    const timeoutId = setTimeout(async () => {
      const isMiss = await checkMissedNotifFromSystemChannel(_systemChannel, twilioClient);

      if (!isMiss) {
        clearTimeout(timeoutId);
        isCheckRef.current = false;

        return;
      }

      console.log(`%c TRYING TO AWAIT MISSED NOTIFICATION...#${attempts}`, styles);

      if (attempts < attemptsLimit) {
        createNotificationCheckerWithInterval(
          _systemChannel,
          twilioClient,
          attempts + 1,
        );
      } else {
        setMissedNotificationsAmount(isMiss);
      }
    }, attemptsInterval);
  };

  const checkMissNotification = async (callback, systemChannel, twilioClient) => {
    const result = await checkMissedNotifFromSystemChannel(systemChannel, twilioClient);

    if (!result) {
      return;
    }

    isCheckRef.current = true;

    callback(systemChannel, twilioClient);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!missedNotificationsAmount) {
          return;
        }

        console.log(`%c Failed to awake system channel after ${attemptsLimit} attempts. Restarting twilio client!`, styles);

        internetCheckerWorker.postMessage({
          type: InternetCheckerMessage.NOTIFICATION_OUT_OF_DATE,
        });

        SentryMethods.captureMessage('Current notifications are expired!');

        const twilioClientCreatorParams = {
          restoreTwilioClient,
          twilioClient,
          channelSid,
          viewMode,
          missedNotificationsAmount,
        };

        await createNewTwilioClient.call(null, twilioClientCreatorParams);
        setMissedNotificationsAmount(0);

        isCheckRef.current = false;
      } catch (e) {
        const requestErrorPayload = {
          redirectPath: '',
          isInvalidToken: false,
          errorText: e?.message,
          description: descriptionFromError(e),
        };

        handleRequestError(requestErrorPayload);
      }
    })();
  }, [missedNotificationsAmount]);

  useEffect(() => {
    internetCheckerWorker.postMessage({
      type: InternetCheckerMessage.START_INTERVAL,
      payload: {
        interval: process.env.INTERNET_HEALTH_CHECK_INTERVAL,
        url: process.env.INTERNET_HEALTH_CHECK_URL,
      },
    });

    internetCheckerWorker.onmessage = async (event) => {
      const { type } = event.data || {};

      switch (type) {
        case InternetCheckerMessage.REQUEST_NOTIFICATIONS: {
          if (isCheckRef.current) {
            return;
          }

          await checkMissNotification(
            createNotificationCheckerWithInterval,
            systemChannel,
            twilioClient,
          );

          break;
        }

        case InternetCheckerMessage.CONNECTION_ALIVE: {
          if (internetConnectingStatus === InternetConnectionEvents.OFFLINE) {
            setInternetConnectingStatus(InternetConnectionEvents.ONLINE);
          }

          break;
        }

        case InternetCheckerMessage.CONNECTION_DOWN: {
          if (internetConnectingStatus === InternetConnectionEvents.ONLINE) {
            setInternetConnectingStatus(InternetConnectionEvents.OFFLINE);
          }

          break;
        }
        default:
          break;
      }
    };

    return () => {
      internetCheckerWorker.postMessage({
        type: InternetCheckerMessage.CLEAR_INTERVAL,
      });
    };
  }, [systemChannel, internetConnectingStatus, twilioClient]);
};
