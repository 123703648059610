import { IAppState } from 'store';

export const selectStatusToggle = (state: IAppState) => state.statusToggle.psychicStatusControl;

export const selectStatusBreak = (state: IAppState) => state.statusToggle.onBreak;

export const selectIsDelayed = (state: IAppState) => state.statusToggle.onBreak.isDelayed;

export const selectChatAndPhone = (state: IAppState) => selectStatusToggle(state).chatAndPhone;

export const selectChatEnabled = (state: IAppState) => selectStatusToggle(state).isChatEnabled;

export const selectPhoneOnly = (state: IAppState) => selectStatusToggle(state).phoneOnly;

export const selectChatOnly = (state: IAppState) => selectStatusToggle(state).chatOnly;

export const selectIsOnCall = (state: IAppState) => selectStatusToggle(state).isOnCall;

export const selectIsTogglesInit = (state: IAppState) => selectStatusToggle(state).isInit;

export const selectBreakCommand = (state: IAppState) => selectStatusBreak(state).command;
