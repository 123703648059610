import React from 'react';
import { useSelector } from 'react-redux';
import { MixpanelEvents } from 'extracted-chat-components/enums';

import { selectCustomerId } from 'selectors/selectors';
import {
  unMuteAllPsychicNotification as _unMuteAllPsychicNotification,
} from 'actions/psychicNotifications';
import useBindDispatch from 'src/hooks/useBindDispatch';
import MixpanelActions from 'src/utils/mixpanel';

import style from './PsychicNoteList.module.scss';

const GlobalMutePopup = () => {
  const customerEndId = useSelector(selectCustomerId);
  const unMuteAllPsychicNotification = useBindDispatch(_unMuteAllPsychicNotification);

  const onUnmuteHandler = () => {
    MixpanelActions.track(MixpanelEvents.BUTTON_TAPPED, {
      button_text: 'unmute notes',
      button_type: 'button',
      screen_name: 'inbox',
      screen_title: 'inbox',
    });
    unMuteAllPsychicNotification(customerEndId as string, true);
  };

  return (
    <div className={style.globalMuteSwitch}>
      <h3 className={style.globalMuteText}>
        You have <span className={style.globalMuteTag}>muted</span> all future notes.
      </h3>
      <button
        type="button"
        onClick={onUnmuteHandler}
        className={style.globalMuteButton}
      >
        Unmute Notes
      </button>
    </div>
  );
};

export default GlobalMutePopup;
